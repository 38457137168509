export class PaymentMethod {
    Image: string;
    PaymentMethod: string;
    KeyboardEnabled: boolean;
    PMEnabled: boolean;
    IdPM;
} 

export class PaymentMethodR {
    modPag: PaymentMethodRPM[] = [];
    esito: boolean;
    errorMessage: string;
    errorCode: any;
    executionTime: any;
    idnegozio: number;
}

export class PaymentMethodRPM {
    IdmodPag: number;
    Descr: string;
    IdstatoRiga: number;
    Idattr: number;
    DataIns: any;
    DataAgg: any;
    Tipo: String;
    Percentuale: number;
    CodiceEsterno?: any;
    Borsellino: number;
    Idsconto: number;
}

export class PaymentMethodScontrino {
    PaymentMethodScontrino () { }
    descrizione;
    importo;
    mod_pag;
    idmod_pag;
}