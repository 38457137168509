import { primaryKey, headerCode, inputType, required, length, order, pattern, editable, Describer, GceEntity, relation, visible } from './gce-management';
import { GceInvoicePerson } from './gce-invoice-person';
import { GceClinic } from './gce-clinic';

export class GcePresence extends GceEntity {
    @headerCode('GCE.GCEPRESENCE.IDFATTURAZIONE')
    @inputType('select')
    @relation("GceInvoicePerson", {columnRel:'idfatturazione', columnDescr:['descrizione', 'piva']})
    @order(1)
    idfatturazione: number;

    @headerCode('GCE.GCEPRESENCE.COD_CLINICA')
    @inputType('select')
    @length(3)
    //@relation("GceClinic", {columnRel:'code_c', columnDescr:['legalentity', 'nome']})
    @relation("GceClinic", {columnRel:'code_c', columnDescr:['nome']})
    @order(3)
    cod_clinica: string;

    @headerCode('GCE.GCEPRESENCE.DATA')
    @inputType('date')
    @order(4)
    data: Date | string;

    @headerCode('GCE.GCEPRESENCE.DISPONIBILITA_DALLE')
    @inputType('text')
    @length(255)
    @order(5)
    disponibilita_dalle: string;

    @headerCode('GCE.GCEPRESENCE.DISPONIBILITA_ALLE')
    @inputType('text')
    @length(255)
    @order(6)
    disponibilita_alle: string;

    @headerCode('GCE.GCEPRESENCE.ORE_DISPONIBILITA')
    @inputType('number_round')
    @order(7)
    ore_disponibilita: number;

    @headerCode('GCE.GCEPRESENCE.APPUNTAMENTI')
    @inputType('number')
    @order(8)
    appuntamenti: number;

    @headerCode('GCE.GCEPRESENCE.ORE_APPUNTAMENTI')
    @inputType('number_round')
    @order(9)
    ore_appuntamenti: number;

    @headerCode('GCE.GCEPRESENCE.PRESENZA')
    @inputType('number')
    @order(10)
    presenza_calc: number;

    @headerCode('GCE.GCEPRESENCE.PRESENZA')
    @inputType('number')
    @visible(false)
    @order(11)
    presenza_forzata_rec?: number;

    @headerCode('GCE.GCEPRESENCE.HRPRESENZA')
    @inputType('number')
    @order(12)
    presenza_forzata_hr?: number;

    @headerCode('GCE.GCEPRESENCE.NOTE')
    @inputType('text')
    @length(255)
    @order(13)
    note?: string;

    person?:GceInvoicePerson;
    clinic?:GceClinic;

    constructor(idfatturazione?: number, cod_clinica?: string, data?: Date | string, disponibilita_dalle?: string, disponibilita_alle?: string, ore_disponibilita?: number, appuntamenti?: number, ore_appuntamenti?: number, presenza_calc?: number, presenza_forzata_rec?:number, presenza_forzata_hr?:number, note?: string){
        super();
        this.idfatturazione = idfatturazione || 0;
        this.cod_clinica = cod_clinica || '';
        this.data = data || new Date();
        this.disponibilita_dalle = disponibilita_dalle || '';
        this.disponibilita_alle = disponibilita_alle || '';
        this.ore_disponibilita = ore_disponibilita || 0;
        this.appuntamenti = appuntamenti || 0;
        this.ore_appuntamenti = ore_appuntamenti || 0;
        this.presenza_calc = presenza_calc || 0;
        this.presenza_forzata_rec = presenza_forzata_rec || 0;
        this.presenza_forzata_hr = presenza_forzata_hr || 0;
        this.note = note || '';
    }

    static getStructure():Array<any>{
        let structure:any[] = Describer.getStructure(new GcePresence());
        structure.push(...this.getActions());
        return structure;
    }

    protected static getActions():any[]{
        let actions:any[] = [];

        let actionOpenCalendar = {
            action: undefined,
            callback: 'openCalendar',
            callbackOnForm: false,
            columnDef: 'actionOpenCalendar',
            disabled: false,
            header: 'GCE.GCEPRESENCE.BUTTONS.MONTHMEDIC',
            dontShowHeader: true,
            length: 256,
            lookUp: {},
            primaryKey: false,
            reference: null,
            required: false,
            requiredOnNew: false,
            show: true,
            type: 'custom-action',
            values: null,
            visible: true,
            editable: false,
            visibleOnToggle: true,
            order: 2
        }
        actions.push(actionOpenCalendar);

        return actions;
    }
}
