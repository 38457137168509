import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-call-api',
  templateUrl: './call-api.component.html',
  styleUrls: ['./call-api.component.css']
})
export class CallApiComponent implements OnInit {
  response: string;
  constructor(private oAuthService: OAuthService) { }

  ngOnInit() {
   // let header = new Headers({ 'Authorization': this.oAuthService.getAuthorizationHeaderValue() });
   // let options = new RequestOptions({ headers: header });

   // console.log("options for http headers %o",options);
   // this.http.get("http://localhost:56313/it/api/values", options)
   //   .subscribe(response => this.response = response.text());
  }
}
