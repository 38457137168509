import { Component, OnInit, Input, OnChanges, AfterViewInit, Output, EventEmitter, ViewChild, ElementRef, SimpleChange  } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/range';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/observable/fromEvent';

import { DialogToggleComponent } from '../dialog-toggle/dialog-toggle.component';
import { MatDialog, MatListOption } from '@angular/material';
import { PreferencesService, PreferencesTable } from "../../services/preferences.service";
import { AlertDialogService } from '../../services/alert-dialog.service';
import { Confirm } from "../../dialogs/confirm-dialog/confirm-dialog.component";

import * as moment from 'moment';

export interface ICustomButton {
  labelCode?:string, 
  img?:string, 
  matTooltipCode?:string,
  class?:string,
  clickFunction:()=>{} 
}

export interface IFormHeaderSearch {
  filter?:string;
  fromDate?:Date;
  toDate?:Date;
  limit?: any;
  event?:FormHeaderSearchEventsEnum;
}

export const enum FormHeaderSearchEventsEnum{
  CLICK = 0,
  INPUT_CHANGE = 1,
  FROM_CHANGE = 2,
  TO_CHANGE = 3,
  LIMIT_CHANGE = 4
}

@Component({
  selector: 'app-form-header',
  templateUrl: './form-header.component.html',
  styleUrls: ['./form-header.component.css']
})
export class FormHeaderComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() entity:string;
  @Input() rowsLenght:number;
  @Input() viewSearch:boolean = true;
  @Input() fields:any[];
  @Input() newRow:boolean= false;
  @Input() editing:boolean = false;
  @Input() expandSearch:boolean = true;
  @Input() viewNew:boolean = true;
  @Input() selectFilter:boolean = false;
  @Input() selectFilterValues:any[];
  @Input() selectFilterValue:any;
  @Input() disableNew:boolean = false;
  @Input() viewEdit:boolean = true;
  @Input() disableEdit:boolean = false;
  @Input() viewToggle:boolean = true;
  @Input() viewFromDate:boolean = false;
  @Input() viewToDate:boolean = false;
  @Input() viewDelete:boolean = false;
  @Input() disableDelete:boolean = false;
  @Input() viewDeleteAll:boolean = false;
  @Input() disableDeleteAll:boolean = false;
  @Input() selectedRowsForDeleteLength:number;
  @Input() viewLimitRows:boolean =  false;
  @Input() limitRowsElements:{value:any, description:string}[];
  @Input() limitRowsPlaceholder:string;
  @Input() limit:any;
  @Input() viewRefresh:boolean = false;
  @Input() viewBack:boolean = false;
  @Input() viewClear:boolean = true;
  @Input() backPath:string;
  @Input() viewOrderBy:boolean = false;
  @Input() orderByElements:{field:string; description:string}[];
  @Input() orderByPlaceholder:string;
  @Input() orderBy:string;
  @Input() orderDirection:string;
  @Input() viewTitle:boolean = false;
  @Input() title: string;
  @Input() customButtons: ICustomButton[] = undefined;
  @Input() fromDateValue:string;
  @Input() toDateValue:string;

  @Output() edit:EventEmitter<any> = new EventEmitter<any>();
  @Output() new:EventEmitter<any> = new EventEmitter<any>();
  @Output() search:EventEmitter<IFormHeaderSearch> = new EventEmitter<IFormHeaderSearch>();
  @Output() toggle:EventEmitter<any> = new EventEmitter<any>();
  @Output() delete:EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteAll:EventEmitter<any> = new EventEmitter<any>();
  @Output() refresh:EventEmitter<any> = new EventEmitter<any>();
  @Output() changeFilter:EventEmitter<any> = new EventEmitter<any>();
  @Output() orderByRefresh:EventEmitter<{field:string, direction:string}> = new EventEmitter<{field:string, direction:string}>();
  @ViewChild('filter', {static:false}) filter: ElementRef;

  focusTextSearch:boolean = false;
  focusFromDate:boolean = false;
  focusToDate:boolean = false;


  fromDate:Date;
  toDate:Date;

  constructor(private dialog: MatDialog,
              private alertDialog: AlertDialogService,
              private prefService: PreferencesService) {
  }

  ngOnInit() {
    if(this.filter){
      this.filter.nativeElement.value="";
    }
    
  }

  ngAfterViewInit(){
    // console.log("form-header %o, %o, %o", this.viewSearch, this.filter, this.expandSearch);
    this.init();
  }

  onChangeSelect(e)
  {
    console.log("ngOnChanges %o", e.value);
    this.changeFilter.emit({filter: e.value});
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    console.log("ngOnChanges %o", changes);
    this.prefService.getTablePreferences(this.entity).subscribe((preferencesTable:PreferencesTable)=>{
      // console.log("pref init %o", preferencesTable);
      if(preferencesTable.columnsVisible.length>0){
        this.toggle.emit(preferencesTable.columnsVisible);
      }
    });

    if(changes['limit']){

    }

    if(changes['customButtons']){
      console.log("customButtons %o", this.customButtons);
      this.customButtons.forEach(btn=>{
        if(!btn.class){
          btn.class = '';
        }
      })
    }

    if(changes['fromDateValue']){
      this.fromDate = moment(this.fromDateValue).toDate();
      console.log(changes['fromDateValue']);
    }
    if(changes['toDateValue']){
      this.toDate = moment(this.toDateValue).toDate();
      console.log(changes['toDateValue']);
    }
  }

  init(){

    if(this.viewSearch){
      if(this.filter){
        Observable.fromEvent(this.filter.nativeElement, 'keyup')
          .debounceTime(500)
          .distinctUntilChanged()
          .subscribe(() => {
            const filterValue:string = this.filter.nativeElement.value.toLowerCase().trim();
            // console.log("filterValue %o", filterValue);
            //if(filterValue.length>1){
              this.search.emit({filter: filterValue, fromDate: this.fromDate, toDate:this.toDate, limit: this.limit, event: FormHeaderSearchEventsEnum.INPUT_CHANGE});
            //}
          });
      }
    }

  }

  onClear() {
    this.filter.nativeElement.value="";
    this.fromDate = undefined;
    this.toDate = undefined;
    this.onSearch();
  }

  onEdit(){
    this.edit.emit();
  }
  onNew(){
    this.new.emit();
  }
  onSearch(){
    const filterValue = this.filter.nativeElement.value.toLowerCase();
    this.search.emit({filter: filterValue, fromDate: this.fromDate, toDate:this.toDate, limit: this.limit, event: FormHeaderSearchEventsEnum.CLICK});
  }

  onToggle(){
    let dialogRef= this.dialog.open(DialogToggleComponent, {
      width: '350px',
      data: this.fields,
      height:'500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;
      let selectedFields = result.selected.map((item: MatListOption) => {
        //let field = item._getHostElement().attributes.getNamedItem('ng-reflect-value').value;
        let field:any = item.value;
        return field;
      });
      // console.log("selectedFields %o %o", selectedFields, this.entity);
      this.prefService.getTablePreferences(this.entity).subscribe((preferencesTable:PreferencesTable)=>{
        // console.log("pref %o", preferencesTable);
        preferencesTable.columnsVisible = selectedFields;
        this.prefService.saveTablePreferences(preferencesTable).subscribe(response=>{
          // console.log("saveTablePreferences %o", response);
        });
      });
      // this.prefService.load().subscribe((preferences:Preferences)=>{
      //   if(!preferences.preferencesTables){
      //     preferences.preferencesTables = [];
      //   }
      //   let preft = preferences.preferencesTables.find(item => item.tableName === this.entity);
      //   if(!preft){
      //     preft = new PreferencesTable();
      //     preft.tableName = this.entity;
      //     preferences.preferencesTables.push(preft);
      //   }
      //   preft.columnsVisible = selectedFields;
      //
      //   this.prefService.save(preferences).subscribe(result=>{
      //     console.log(result);
      //   });
      // });
      this.toggle.emit(selectedFields);
    });
  }

  onDelete() {
    const confirm:Confirm = {
      title: "FORM.CONFIRMDIALOG.DELETE.SELECTEDROWS.TITLE",
      message: "FORM.CONFIRMDIALOG.DELETE.SELECTEDROWS.MESSAGE",
      buttonOkText: "FORM.CONFIRMDIALOG.DELETE.BUTTONOKTEXT",
      buttonCancelText: "FORM.CONFIRMDIALOG.DELETE.BUTTONCANCELTEXT",
      buttonOkStyle: {'background-color':'#268B26'},
      buttonCancelStyle: {'background-color':'#AA3939'},
      withTranslate: true
    };
    this.alertDialog.confirm(confirm).subscribe(result=>{
      if(result){
        this.delete.emit();
      }
    });

  }

  onDeleteAll() {
    const confirm:Confirm = {
      title: "FORM.CONFIRMDIALOG.DELETE.ALLROWS.TITLE",
      message: "FORM.CONFIRMDIALOG.DELETE.ALLROWS.MESSAGE",
      buttonOkText: "FORM.CONFIRMDIALOG.DELETE.BUTTONOKTEXT",
      buttonCancelText: "FORM.CONFIRMDIALOG.DELETE.BUTTONCANCELTEXT",
      buttonOkStyle: {'background-color':'#268B26'},
      buttonCancelStyle: {'background-color':'#AA3939'},
      withTranslate: true
    };
    this.alertDialog.confirm(confirm).subscribe(result=>{
      if(result){
        this.deleteAll.emit();
      }
    });
  }

  fromDateChange(event) {
    console.log("fromDate %o", event);
    const filterValue = this.filter.nativeElement.value.toLowerCase();
    this.search.emit({filter: filterValue, fromDate: event.value, toDate:this.toDate, limit: this.limit, event: FormHeaderSearchEventsEnum.FROM_CHANGE});
  }

  toDateChange(event){
    console.log("toDate %o", event);
    const filterValue = this.filter.nativeElement.value.toLowerCase();
    this.search.emit({filter: filterValue, fromDate: this.fromDate, toDate:event.value, limit: this.limit, event: FormHeaderSearchEventsEnum.TO_CHANGE});
  }

  onChangeLimit(event){
    console.log("form header onChangeLimit %o", event);
    const filterValue = this.filter.nativeElement.value.toLowerCase();
    this.search.emit({filter: filterValue, fromDate: this.fromDate, toDate:this.toDate, limit: event.value, event: FormHeaderSearchEventsEnum.LIMIT_CHANGE});
  }

  onRefresh(){
    this.refresh.emit();
  }

  onOrderBy(event:any){
    console.log("form header onOrderBy %o", {orderBy: this.orderBy, direction:this.orderDirection});
    this.orderByRefresh.emit({field: event.value, direction:this.orderDirection});
  }

  toggleOrderDirection(){
    if(this.orderDirection==='asc'){
      this.orderDirection = 'desc';
    }else{
      this.orderDirection = 'asc';
    }
    console.log("form header toggleOrderDirection %o", {orderBy: this.orderBy, direction:this.orderDirection});
    this.orderByRefresh.emit({field: this.orderBy, direction:this.orderDirection});
  }

}
