import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'groupby'})
export class GroupbyPipe implements PipeTransform {
  transform(collection: Array<any>, property: any): Array<any> {
    console.log(collection, property);
    // prevents the application from breaking if the array of objects doesn't exist yet
    if(!collection) {
      return null;
    }

    const groupedCollection = collection.reduce((previousValue, currentValue) => {
      console.log(property)
      if(!previousValue[currentValue[property]]) {
        console.log('pippomio');
        previousValue[currentValue[property]] = [currentValue];
      } else {
        previousValue[currentValue[property]].push(currentValue);
      }

      console.log(previousValue)

      return previousValue;
    }, {});

    // this will return an array of objects, each object containing a group of objects
    console.log(groupedCollection);
    console.log(Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key]})));
    return Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));
  }
}
