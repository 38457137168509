'use strict';

import { PaymentMethod } from '../global/model/payments/payment-method';
import { CompletePaymentTicket } from '../global/model/payments/complete-payment';

//export const preServices = "http://public.services.webtic.it/evostore_WAPI2";
export const preServices = "https://webapi.evolove.it/webapi";
export const ArticoliNegozio = preServices + "/api/getArticoliNegozio";
export const AddArticoloToCheckOut = preServices + "/api/addArticoloToCheckOut";
export const RemoveArticoloToCheckOut = preServices + "/api/removeArticoloToCheckOut";
export const GetPaymentMethods = preServices + "/api/getPaymentsMode";
export const PayAndGenerateTicket = preServices + "/api/EmettiScontrinoNegozio";

export const keyboardButtons = [
    {
      buttons: [
        { 'value': 'C', 'disabled': false, 'action': true },
        { 'value': '←', 'disabled': false, 'action': true },
        { 'value': 'AC', 'disabled': true, 'action': true },
        { 'value': '/', 'disabled': true, 'action': true }
      ],
    },
    {
      buttons: [
        { 'value': '7', 'disabled': false, 'action': false },
        { 'value': '8', 'disabled': false, 'action': false },
        { 'value': '9', 'disabled': false, 'action': false },
        { 'value': 'X', 'disabled': true, 'action': true }
      ],
    },
    {
      buttons: [
        { 'value': '4', 'disabled': false, 'action': false },
        { 'value': '5', 'disabled': false, 'action': false },
        { 'value': '6', 'disabled': false, 'action': false },
        { 'value': '–', 'disabled': false, 'action': true }
      ],
    },
    {
      buttons: [
        { 'value': '1', 'disabled': false, 'action': false },
        { 'value': '2', 'disabled': false, 'action': false },
        { 'value': '3', 'disabled': false, 'action': false },
        { 'value': '+', 'disabled': false, 'action': true }
      ],
    },
    {
      buttons: [
        { 'value': '0', 'disabled': false, 'action': false },
        { 'value': '.', 'disabled': true, 'action': false },
        { 'value': '=', 'disabled': false, 'action': true }
      ],
    }
  ];

export function getNodeImage (idnegozio, idtastiera, idnodo) {
    return preServices + '/Handlers/handlerNodiTastiera.ashx?idnegozio=' + idnegozio + '&idtastiera=' + idtastiera + '&idnodo=' + idnodo + '&lob_size=SMALL&i=png-s';
}

export function getArticleImage (idnegozio, idarticolo, small_image) {
    return preServices + '/Handlers/handlerArticoli.ashx?idnegozio=' + idnegozio + '&idarticolo=' + idarticolo + '&lob_size=' + (small_image ? 'SMALL' : 'LARGE') + '&i=png-' + (small_image ? 's' : 'l');
}

export const limitBasketQuantity = 999999;
export const prePath = "/webpos";

export const oAuthClientId = "EvolutionTic";
export const oAuthScope = "openid profile email roles WebApi";
export const oAuthIssuer = "https://identitywinticplus.webtic.it";
export const applicationLanguages = [
    {
        'default': true,
        'name': 'Italiano',
        'available': true,
        'locale': 'it'
    },
    {
        'default': false,
        'name': 'English',
        'available': true,
        'locale': 'en'
    },
    {
        'default': false,
        'name': 'Español',
        'available': false,
        'locale': 'es'
    },
    {
        'default': false,
        'name': 'Français',
        'available': false,
        'locale': 'fr'
    },
    {
        'default': false,
        'name': 'Deutsch',
        'available': false,
        'locale': 'de'
    },
    {
        'default': false,
        'name': '中文',
        'available': false,
        'locale': 'ch'
    },
    {
        'default': false,
        'name': 'Suomenkielinen',
        'available': false,
        'locale': 'fi'
    },
    {
        'default': false,
        'name': 'Pоссиянин',
        'available': false,
        'locale': 'ru'
    },
];

export function getLanguages () {
    return applicationLanguages;
}

export const ArticoliData = {
    "idnegozio": 750,
    "web_box": "WEB_POS_01",
    "web_operator": "utente pos",
    "sessionId": "GLS_SESSION_ID_125",
    "trackid": 100,
    "GUID": ""
};

export const AddToCartData = {
    "idnegozio": 750,
    "web_box": "WEB_POS_01",
    "web_operator": "utente pos",
    "sessionId": "GLS_SESSION_ID_125",
    "trackid": 100,
    "GUID": "",
    "listaCheckOut": [],
	"book": true,
	"addToBooked": true,
	"cliente": {
		"enabled": false
	}
}
