import { Component, OnInit, Input, Output, ElementRef, ViewChild, AfterViewInit, EventEmitter, OnChanges } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent} from '@angular/material';

@Component({
  selector: 'app-form-field-list',
  templateUrl: './form-field-list.component.html',
  styleUrls: ['./form-field-list.component.css']
})
export class FormFieldListComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() elements: any[];
  @Input() filteredElements: any[];
  @Input() sortBy: string;
  @Input() viewField: string;
  @Input() fieldId: string;
  @Input() defaultAnotherSourceImg: string;
  @Input() placeholder: string;
  @Input() elementsVisible: number;
  @Input() syncronizeData:boolean = false;
  @Input() icon:string = undefined;
  @Input() withoutIcon:boolean = false;
  @Input() withoutImage:boolean = false;
  @Input() customImage:string = 'assets/img/film/field-list/cast-star.png';
  @Input() disabled:boolean = false;
  @Input() allowAddNew:boolean = true;
  @Input() imgField:string = undefined;
  // @Input() useFunctionForLoadImage:boolean = false;
  // @Input() functionForLoadImage:Function;
  // @Input() sizeForLoadImage:PosterImageSize = undefined;
  @Output() filter: EventEmitter<any> = new EventEmitter<any>();
  @Output() added:EventEmitter<any> = new EventEmitter<any>();
  @Output() elementAdded:EventEmitter<any> = new EventEmitter<any>();
  @Output() removed:EventEmitter<any> = new EventEmitter<any>();
  @Output() elementRemoved:EventEmitter<any> = new EventEmitter<any>();
  @Output() syncronize: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('inputFilter', {static:false}) inputFilter: ElementRef;
  reverse:boolean = false;
  sort:boolean = true;
  maxHeight: number;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor() { }

  ngOnInit() {
    this.sort = !!this.sortBy;
    // console.log(this.elements);
  }

  ngOnChanges(){
    if(this.elements){
      this.maxHeight = (this.elementsVisible * 56) - (this.elements.length > this.elementsVisible ? 4 : 0);
    }else{
      this.maxHeight = (this.elementsVisible * 56);
    }

  }

  ngAfterViewInit(){
    if(this.inputFilter) {
      Observable.fromEvent(this.inputFilter.nativeElement, 'keyup')
          .debounceTime(500)
          .distinctUntilChanged()
          .subscribe((e:KeyboardEvent ) => {
            const code = e.keyCode || e.which;
            const value = e.target['value'];

            //console.log(e.target['value']);

            if(code === 13) {
              if((value || '').trim()){
                 let item = undefined;
                 if(this.filteredElements){
                   item = this.filteredElements.find(i => i[this.viewField].toLowerCase() === value.toLowerCase());
                 }
                 if(item){
                   this.addElement(item);
                 } else {
                   if(this.allowAddNew){
                     item = {};
                     item[this.fieldId] = value;
                     item[this.viewField] = value;
                     this.addElement(item);
                   }
                 }
               }
              this.inputFilter.nativeElement.value = '';

              return;
            }

            if(code < 37 || code > 40){
             this.filter.emit(value);
            }
          });
    }
  }

  removeElement(item){
    // console.log(item, this.fieldId, this.elements);
    //let index = this.elements.findIndex(i => i[this.fieldId] === item[this.fieldId]);
    let index = this.elements.indexOf(item);
    this.elements.splice(index, 1);
    this.elementRemoved.emit(item);
    this.removed.emit(this.elements);
  }

  private addElement(item) {
    if(!this.elements){
      this.elements = [];
    }
    if( item[this.fieldId] ){
      if(this.elements.findIndex(i => i[this.fieldId] === item[this.fieldId]) === -1){
        this.elements.push(item);
        this.elementAdded.emit({element:item, elements:this.elements});
        this.added.emit(this.elements);
      }
    }else if( item['ExternalRifList'] ){
      if(this.elements.filter(e=>e['ExternalRifList']).findIndex(i => i['ExternalRifList'][0]['ExternalCode'] === item['ExternalRifList'][0]['ExternalCode']) === -1){
        this.elements.push(item);
        this.elementAdded.emit({element:item, elements:this.elements});
        this.added.emit(this.elements);
      }
    }
  }

  selected(event: MatAutocompleteSelectedEvent){
    const value = event.option.value;
    let item = undefined;
    if(value[this.fieldId]) {
      item = this.filteredElements.find(i => i[this.fieldId] === value[this.fieldId]);
    } else if( value['ExternalRifList'] ) {
      item = this.filteredElements.filter(e=>e['ExternalRifList']).find(i => {
        return i['ExternalRifList'][0]['ExternalCode'] === value['ExternalRifList'][0]['ExternalCode'];
      });
    }
    if(item){
      this.addElement(item);
    }
    this.inputFilter.nativeElement.value = '';
    this.inputFilter.nativeElement.blur();

  }
}
