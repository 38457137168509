import { Directive, ElementRef, Renderer2, Input, AfterViewInit, DoCheck } from '@angular/core';

export interface IConditionalFormatting {
  formulas: {
    field: string,
    rule: string,
    styles?: {
      style: string, value: string
    }[],
    classes?: string[]
  }[];
}

@Directive({
  selector: '[conditionalFormatting]'
})
export class ConditionalFormattingDirective implements AfterViewInit, DoCheck {
  @Input('conditionalFormattingValue') conditionalFormattingValue: IConditionalFormatting;
  @Input('conditionalFormattingRow') conditionalFormattingRow: any;

  constructor(public el: ElementRef, public renderer: Renderer2) {
    // el.nativeElement.style.backgroundColor = 'yellow';

    //this.renderer.setStyle(el.nativeElement, this.conditionalValue.format.style, this.conditionalValue.format.value);
  }

  ngAfterViewInit(): void {
    // console.log("ConditionalFormattingDirective %o", this.conditionalFormattingValue);
    if (this.conditionalFormattingValue) {
      this.conditionalFormattingValue.formulas.forEach(formula => {
        let expression: string = `${this.conditionalFormattingRow[formula.field]} ${formula.rule}`;

        if (eval(expression)) {
          if (formula.styles) {
            formula.styles.forEach(format => {
              this.renderer.setStyle(this.el.nativeElement, format.style, format.value);
            });
          }
          if (formula.classes) {
            formula.classes.forEach(cls => {
              this.renderer.addClass(this.el.nativeElement, cls);
            });
          }
        }
      });
    }
  }

  ngDoCheck(){
    //console.log("ConditionalFormattingDirective %o", this.conditionalFormattingValue);
    if (this.conditionalFormattingValue) {
      this.conditionalFormattingValue.formulas.forEach(formula => {
        let expression: string = `${this.conditionalFormattingRow[formula.field]} ${formula.rule}`;

        if (eval(expression)) {
          if (formula.styles) {
            formula.styles.forEach(format => {
              this.renderer.setStyle(this.el.nativeElement, format.style, format.value);
            });
          }
          if (formula.classes) {
            formula.classes.forEach(cls => {
              this.renderer.addClass(this.el.nativeElement, cls);
            });
          }
        }
      });
    }
  }
}
