import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MatDialog } from '@angular/material';
import { DialogLoginRequiredComponent } from "../dialogs/dialog-login-required/dialog-login-required.component";
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from "rxjs/Observable";

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private router: Router, private oAuthService: OAuthService, public dialog: MatDialog) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (this.oAuthService.hasValidIdToken()) {
      return true;
    }
    //console.log("CanActivate login!");

    const dialogRef = this.dialog.open(DialogLoginRequiredComponent, {
      height: '175px'
    });
    //this.router.navigate(['/home']);
    return false;
  }

  /* begin methods for store brench */
  public getToken(): string {
    if (!this.isAuthenticated()) return "";
    let x: any = this.oAuthService.getAccessToken();
    return x;
  }

  /* begin methods for store brench */
  public getGUID(): string {
    if (!this.isAuthenticated()) return "";
    let x: any = this.oAuthService.getIdentityClaims();
    // return x.sub;
    let username = 'no def';
    if (x.given_name != null && x.family_name != null) {
      if (x.email != null)
        username = x.email;
      else
        username = x.given_name + ' ' + x.family_name;
    }
    else {
      if (x.name != null)
        username = x.name;
    }
    return username;
  }

  public getUserMail(): string {
    if (!this.isAuthenticated()) return "";
    let x: any = this.oAuthService.getIdentityClaims();
    return x.email;
  }

  public getDomain(): string {
    if (!this.isAuthenticated()) return "";
    let x: any = this.oAuthService.getIdentityClaims();
    return x.domain;
  }

  public getDescDomain(): string {
    if (!this.isAuthenticated()) return "";
    let x: any = this.oAuthService.getIdentityClaims();
    return x.desc_domain;
  }

  public getProfile(): string {
    if (!this.isAuthenticated()) return "";
    let x: any = this.oAuthService.getIdentityClaims();
    return x.profile;
  }

  public getSID(): string {
    if (!this.isAuthenticated()) return "";
    let x: any = this.oAuthService.getIdentityClaims();
    return x.sid;
  }

  public getTest() { return this.oAuthService.getIdentityClaims(); }

  public getUser(): object {
    if (!this.isAuthenticated()) return null;
    let x: any = this.oAuthService.getIdentityClaims();
    // console.log('claims at GETUSER %o',x);
    let email = x.email;
    let nome = x.given_name;
    let cognome = x.family_name;
    if (!(nome)) {
      nome = x.name;
    }

    if (!(cognome)) {
      cognome = '';
    }

    return {
      nome: this.ucfirst(nome),
      cognome: this.ucfirst(cognome),
      nomeCompleto: this.ucfirst(nome) + " " + this.ucfirst(cognome),
      email: email,
      ruolo: this.ucfirst(x.desc_profile),
      guid: x.sid,
      clinica: x.desc_domain
    };
  }

  private ucfirst(str) {
    str += ''
    var f = str.charAt(0)
      .toUpperCase()
    return f + str.substr(1).toLowerCase();
  }

  public isAuthenticated(): boolean {
    return this.oAuthService.hasValidIdToken();
  }

  public doLogoff() {
    this.oAuthService.postLogoutRedirectUri = window.location.origin + "/login";
    this.oAuthService.logOut();
  }
  /* end methods for store brench */
}
