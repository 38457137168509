import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { HttpClient } from '@angular/common/http';

import { AuthGuardService } from './../../../services/auth-guard.service';
import { TableService } from '../services/table.service';

@Component({
  selector: 'backorder-dialog',
  templateUrl: './backorder.dialog.html',
  styleUrls: ['./backorder.dialog.scss']
})
export class BackOrderDialogComponent implements OnInit {

  res;

  constructor(
    public _authService: AuthGuardService,
    public dialogRef: MatDialogRef<BackOrderDialogComponent>,
    private _http: HttpClient,
    private _tableService: TableService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.res = data.res;
  }

    ngOnInit() {
    }

    close (a) {
      this.dialogRef.close(a);
    }

}