import { primaryKey, headerCode, inputType, required, length, order, pattern, editable, Describer, GceEntity } from './gce-management';

export class GceClinic extends GceEntity {
    @headerCode('GCE.GCECLINIC.CODE_N')
    @inputType('number')
    @order(1)
    code_n: number;

    @headerCode('GCE.GCECLINIC.CODE_C')
    @inputType('text')
    @length(255)
    @order(2)
    code_c: string;

    @headerCode('GCE.GCECLINIC.LEGALENTITY')
    @inputType('text')
    @length(255)
    @order(3)
    legalentity: string;

    @headerCode('GCE.GCECLINIC.NOME')
    @inputType('text')
    @length(255)
    @order(4)
    nome: string;

    @headerCode('GCE.GCECLINIC.INDIRIZZO')
    @inputType('text')
    @length(255)
    @order(5)
    indirizzo: string;

    @headerCode('GCE.GCECLINIC.SEDE_LEGALE')
    @inputType('text')
    @length(255)
    @order(6)
    sede_legale: string;

    @headerCode('GCE.GCECLINIC.PARTITA_IVA')
    @inputType('text')
    @length(255)
    @order(7)
    partita_iva: string;

    @headerCode('GCE.GCECLINIC.AMMNISTRATORE')
    @inputType('text')
    @length(255)
    @order(8)
    ammnistratore: string;

    @headerCode('GCE.GCECLINIC.AREA_MANAGER')
    @inputType('text')
    @length(255)
    @order(9)
    area_manager: string;

    @headerCode('GCE.GCECLINIC.DISTRETTO')
    @inputType('text')
    @length(255)
    @order(10)
    distretto: string;

    @headerCode('GCE.GCECLINIC.RIUNITI')
    @inputType('number')
    @order(11)
    riuniti: number;

    @headerCode('GCE.GCECLINIC.DIRETTORE_SANITARIO')
    @inputType('text')
    @length(255)
    @order(12)
    direttore_sanitario: string;

    @headerCode('GCE.GCECLINIC.CLUSTER')
    @inputType('number')
    @order(13)
    cluster: number;

    @headerCode('GCE.GCECLINIC.REGIONE')
    @inputType('text')
    @length(255)
    @order(14)
    regione: string;

    @headerCode('GCE.GCECLINIC.MESE_APERTURA')
    @inputType('date')
    @order(15)
    mese_apertura: Date | string;

    @headerCode('GCE.GCECLINIC.DATA_APERTURA')
    @inputType('date')
    @order(16)
    data_apertura: Date | string;

    @headerCode('GCE.GCECLINIC.BRAND')
    @inputType('text')
    @length(255)
    @order(17)
    brand: string;

    @headerCode('GCE.GCECLINIC.VINTAGE')
    @inputType('number')
    @order(18)
    vintage: number;

    @headerCode('GCE.GCECLINIC.PROVINCIA')
    @inputType('text')
    @length(255)
    @order(19)
    provincia: string;

    @headerCode('GCE.GCECLINIC.MALL_STREET')
    @inputType('text')
    @length(255)
    @order(20)
    mall_street: string;

    @headerCode('GCE.GCECLINIC.LATITUDINE')
    @inputType('text')
    @length(255)
    @order(21)
    latitudine: string;

    @headerCode('GCE.GCECLINIC.LONGITUDINE')
    @inputType('text')
    @length(255)
    @order(22)
    longitudine: string;

    @headerCode('GCE.GCECLINIC.LOGIN_AREAMANAGER')
    @inputType('text')
    @length(255)
    @order(23)
    login_areamanager: string;

    @headerCode('GCE.GCECLINIC.DATA_ACQUISIZIONE')
    @inputType('date')
    @order(24)
    data_acquisizione: Date | string;

    @headerCode('GCE.GCECLINIC.EMAIL_CLINIC_MANAGER')
    @inputType('text')
    @length(255)
    @order(25)
    email_clinic_manager: string;

    @headerCode('GCE.GCECLINIC.LOGIN_DISTRICTMANAGER')
    @inputType('text')
    @length(255)
    @order(26)
    login_districtmanager: string;

    @headerCode('GCE.GCECLINIC.COMPANY_NAVISION')
    @inputType('text')
    @length(255)
    @order(27)
    company_navision:string;

    constructor();
    constructor(code_n: number, code_c: string, legalentity: string, nome: string, indirizzo: string, sede_legale: string, partita_iva: string, ammnistratore: string, area_manager: string, distretto: string, riuniti: number, direttore_sanitario: string, cluster: number, regione: string, mese_apertura: Date | string, data_apertura: Date | string, brand: string, vintage: number, provincia: string, mall_street: string, latitudine: string, longitudine: string, login_areamanager: string, data_acquisizione: Date | string, email_clinic_manager: string, login_districtmanager: string, company_navision:string);
    constructor(code_n?: number, code_c?: string, legalentity?: string, nome?: string, indirizzo?: string, sede_legale?: string, partita_iva?: string, ammnistratore?: string, area_manager?: string, distretto?: string, riuniti?: number, direttore_sanitario?: string, cluster?: number, regione?: string, mese_apertura?: Date | string, data_apertura?: Date | string, brand?: string, vintage?: number, provincia?: string, mall_street?: string, latitudine?: string, longitudine?: string, login_areamanager?: string, data_acquisizione?: Date | string, email_clinic_manager?: string, login_districtmanager?: string, company_navision?:string){
        super();
        this.code_n = code_n || 0;
        this.code_c = code_c || '';
        this.legalentity = legalentity || '';
        this.nome = nome || '';
        this.indirizzo = indirizzo || '';
        this.sede_legale = sede_legale || '';
        this.partita_iva = partita_iva || '';
        this.ammnistratore = ammnistratore || '';
        this.area_manager = area_manager || '';
        this.distretto = distretto || '';
        this.riuniti = riuniti || 0;
        this.direttore_sanitario = direttore_sanitario || '';
        this.cluster = cluster || 0;
        this.regione = regione || '';
        this.mese_apertura = mese_apertura || new Date();
        this.data_apertura = data_apertura || new Date();
        this.brand = brand || '';
        this.vintage = vintage || 0;
        this.provincia = provincia || '';
        this.mall_street = mall_street || '';
        this.latitudine = latitudine || '';
        this.longitudine = longitudine || '';
        this.login_areamanager = login_areamanager || '';
        this.data_acquisizione = data_acquisizione || new Date();
        this.email_clinic_manager = email_clinic_manager || '';
        this.login_districtmanager = login_districtmanager || '';
        this.company_navision = company_navision || '';
    }

    static getStructure():Array<any>{
        let structure:any[] = Describer.getStructure(new GceClinic());
        structure.push(...this.getActions());
        return structure;
    }

    protected static getActions():any[]{
        let actions:any[] = [];
        return actions;
    }
}