import { primaryKey, visible, headerCode, inputType, relation, length, order, currency, pattern, editable, Describer, GceEntity, roles } from '../gce-management/gce-management';

export class SsoMonLog extends GceEntity {

    @order(2)
    @headerCode('SSOMON.ID')
    @inputType('number')
    @length(14)
    id: number;

    @order(1)
    @headerCode('SSOMON.MESSAGE')
    @inputType('string')
    @length(1024)
    message: string;

    @order(5)
    @headerCode('SSOMON.CLIENTID')
    @inputType('string')
    @length(1024)
    clientId: string;

    @order(7)
    @headerCode('SSOMON.IP')
    @inputType('string')
    @length(18)
    ip: string;

    @order(8)
    @headerCode('SSOMON.DEVICEOS')
    @inputType('string')
    @length(64)
    deviceOs: string;

    @order(9)
    @headerCode('SSOMON.DEVICENAME')
    @inputType('string')
    @length(64)
    deviceName: string;

    @order(4)
    @headerCode('SSOMON.USER')
    @inputType('string')
    @length(256)
    usr: string;

    @order(6)
    @headerCode('SSOMON.TIMESTAMP')
    @inputType('datetime')
    timeStamp: Date;

    @order(3)
    @headerCode('SSOMON.LEVEL')
    @inputType('string')
    @length(128)
    level: string;

    constructor();
    constructor(id: number, message: string, clientId: string, usr: string, level: string, timeStamp: Date, ip: string,deviceOs:string,deviceName:string);
    constructor(id?: number, message?: string, clientId?: string, usr?: string, level?: string, timeStamp?: Date, ip?: string,deviceOs?:string,deviceName?:string) {
        super();

        this.id = id;
        this.message = message || '';
        this.usr = usr || '';
        this.clientId = clientId || '';
        this.level = level || '';
        this.timeStamp = timeStamp || new Date();
        this.ip = ip || '';
        this.deviceOs= deviceOs || '';
        this.deviceName= deviceName || '';
    }

    static getStructure(): Array<any> {
        let structure: any[] = Describer.getStructure(new SsoMonLog());
        structure.push(...this.getActions());
        return structure;
    }


    protected static getActions(): any[] {
        let actions: any[] = [];
        return actions;
    }
}