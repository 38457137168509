import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs/Rx';
import { WebsocketService } from './websocket.service';

export interface Message {
  data: {};
  event:string;
}

@Injectable()
export class DevicemanagerService {

  loading: boolean;
  public machineName: string;
  public activePrinter:boolean;
  public activePrinterId:string;
  public activePos:boolean;
  public activePosId:string;
  public messages: Subject<Message>;

  constructor(wsService: WebsocketService) {
    //console.log('map socket');
    this.messages = <Subject<Message>>wsService
      .connect(environment['deviceManagerServer'])
      .map((response: MessageEvent): Message => {
        //console.log('data from websocket %o',response);
        let data = JSON.parse(response.data);
        return {
          data: data,
          event:data.event
        }
      });
  }
}
