import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {
  transform(value:object): any {
    // console.log("addresspipe %o", value);
    // areaCode1
    // areaCode2
    // city
    // nation
    // otherStreetNameInfo
    // postalCode
    // streetName
    // streetNumber
    if(!value){
      return null;
    }
    if(!value['streetName']){
      return null;
    }

    let retVal:string = "";

    retVal += value['streetName'];
    if(value['streetNumber']) {
      retVal += `, ${value['streetNumber']}`;
    }
    if(value['postalCode']) {
      retVal += `, ${value['postalCode']}`;
    }
    if(value['city']) {
      retVal += ` ${value['city']}`;
    }
    if(value['areaCode2']) {
      retVal += `, ${value['areaCode2']}`;
    }
    if(value['nation']) {
      retVal += `, ${value['nation']}`;
    }
  //  console.log("addrespipe retval %o", retVal);
    return retVal;
  }

}
