import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GceManagementService } from '../../../services/gce-management.service';
import { DatatableComponent } from '../../../shared/datatable/datatable.component';
import { FormHeaderComponent, ICustomButton } from '../../../shared/form-header/form-header.component';
import { FormComponent } from '../../../shared/form/form.component';
import { LoaderService } from '../../../loader/loader.service';
import { GcePerformanceCalculationDetail } from '../../../entities/gce-management/gce-performance-calculation-detail';
import { GcePerformanceCalculationDetracts } from '../../../entities/gce-management/gce-performance-calculation-detracts';
import { GceInvoice } from '../../../entities/gce-management/gce-invoice';
import { GceLookUpOptions } from '../../../entities/gce-management/gce-management';
import { GceInvoicePerson } from '../../../entities/gce-management/gce-invoice-person';
import { IConditionalFormatting } from '../../../directives/conditional-formatting.directive';
import { AlertDialogService } from '../../../services/alert-dialog.service';
import { Confirm } from "../../../dialogs/confirm-dialog/confirm-dialog.component";
import * as moment from 'moment';
import * as $ from 'jquery';

@Component({
  selector: 'app-gce-dialog-performance-calculation-detail',
  templateUrl: './gce-dialog-performance-calculation-detail.component.html',
  styleUrls: ['./gce-dialog-performance-calculation-detail.component.css']
})
export class GceDialogPerformanceCalculationDetailComponent implements OnInit {
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild('formHeader', { static: false }) formHeader: FormHeaderComponent;
  @ViewChild('formData', { static: false }) formData: FormComponent;

  columns: any[] = [];
  columnsD: any[] = [];
  limitRows: number = 10;
  heightMainList: number = 400;
  openRightPanel: boolean = false;
  currentRow: any;
  keyColumn: any = {};
  isDetail: boolean = false;
  isDetract: boolean = false;
  errs: number = 0;
  details: GcePerformanceCalculationDetail[];
  detailsBkp: GcePerformanceCalculationDetail[];
  role: any;
  isAdmin: boolean = false;
  detracts: GcePerformanceCalculationDetracts[];

  invoice: GceInvoice;

  conditionalFormattingConfig: IConditionalFormatting;

  customButtons: ICustomButton[] = [];
  t1: number = 0;
  t2: number = 0;
  t3: number = 0;
  t4: number = 0;
  t5: number = 0;
  t6: number = 0;
  t7: number = 0;

  t11_1: number = null;
  t11_2: number = null;
  t11_3: number = null;
  t12_1: number = null;
  t12_2: number = null;
  t12_3: number = null;
  t13_t: number = null;

  t14_1: number = null;
  t14_2: number = null;
  t14_3: number = null;
  t15_1: number = null;
  t15_2: number = null;
  t15_3: number = null;
  t16_1: number = null;
  t16_2: number = null;
  t16_3: number = null;
  t17_1: number = null;
  t17_2: number = null;
  t17_3: number = null;
  t18_1: number = null;
  t18_2: number = null;
  t18_3: number = null;
  t18A_2: number = null;
  t18A_3: number = null;
  t19_1: number = null;
  t19_2: number = null;
  t19_3: number = null;

  t20_3: number = null;
  t21_3: number = null;
  t110_t: number = null;



  constructor(public dialogRef: MatDialogRef<GceDialogPerformanceCalculationDetailComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private gceService: GceManagementService, private loaderService: LoaderService, private alertDialog: AlertDialogService,) {
    this.invoice = data.invoice;
    this.role = data.role;
    let tc = 0;
    let hrm = false;
    if (this.role != null && (this.role.toLowerCase() == 'amministratore' || this.role.toLowerCase() == 'hr medici')) {
      this.isAdmin = true;
    }
    console.log('INVOICE FOR DETAIL %o', data.invoice);
    this.conditionalFormattingConfig = {
      formulas: [{
        field: 'flg_riga_valida',
        rule: '== null',
        classes: ['row-danger']
      }, {
        field: 'touched',
        rule: '== true',
        classes: ['row-touched']
      }]
    };

    //round values
    if (!(this.invoice.importo_gettone_calc == null))
      this.invoice.importo_gettone_calc = Math.round(this.invoice.importo_gettone_calc * 100) / 100 || 0;
    if (!(this.invoice.importo_gettone_manuale == null))
      this.invoice.importo_gettone_manuale = Math.round(this.invoice.importo_gettone_manuale * 100) / 100 || 0;
    if (!(this.invoice.importo_direzione_calc == null))
      this.invoice.importo_direzione_calc = Math.round(this.invoice.importo_direzione_calc * 100) / 100 || 0;
    if (!(this.invoice.importo_direzione_manuale == null))
      this.invoice.importo_direzione_manuale = Math.round(this.invoice.importo_direzione_manuale * 100) / 100 || 0;
    if (!(this.invoice.importo_fisso_extra_calc == null))
      this.invoice.importo_fisso_extra_calc = Math.round(this.invoice.importo_fisso_extra_calc * 100) / 100 || 0;
    if (!(this.invoice.importo_prestazioni_calc == null))
      this.invoice.importo_prestazioni_calc = Math.round(this.invoice.importo_prestazioni_calc * 100) / 100 || 0;

    if (!(this.invoice.importo_integr_minimo_calc == null))
      this.invoice.importo_integr_minimo_calc = Math.round(this.invoice.importo_integr_minimo_calc * 100) / 100 || 0;
    if (!(this.invoice.importo_prestazioni_manuale == null))
      this.invoice.importo_prestazioni_manuale = Math.round(this.invoice.importo_prestazioni_manuale * 100) / 100 || 0;
    if (!(this.invoice.importo_riporto_mese_succ == null))
      this.invoice.importo_riporto_mese_succ = Math.round(this.invoice.importo_riporto_mese_succ * 100) / 100 || 0;

    if (!(this.invoice.importo_riporto_mese_prec == null))
      this.invoice.importo_riporto_mese_prec = Math.round(this.invoice.importo_riporto_mese_prec * 100) / 100 || 0;
    if (!(this.invoice.tot_fattura_prestazione == null))
      this.invoice.tot_fattura_prestazione = Math.round(this.invoice.tot_fattura_prestazione * 100) / 100 || 0;
    if (!(this.invoice.importo_prest_mediche_manuale == null))
      this.invoice.importo_prest_mediche_manuale = Math.round(this.invoice.importo_prest_mediche_manuale * 100) / 100 || 0;
    if (!(this.invoice.importo_prest_anastesista_manuale == null))
      this.invoice.importo_prest_anastesista_manuale = Math.round(this.invoice.importo_prest_anastesista_manuale * 100) / 100 || 0;

    if (!(this.invoice.importo_gettone_manuale_hr == null))
      this.invoice.importo_gettone_manuale_hr = Math.round(this.invoice.importo_gettone_manuale_hr * 100) / 100 || 0;
    if (!(this.invoice.importo_direzione_manuale_hr == null))
      this.invoice.importo_direzione_manuale_hr = Math.round(this.invoice.importo_direzione_manuale_hr * 100) / 100 || 0;
    if (!(this.invoice.importo_prestazioni_manuale_hr == null))
      this.invoice.importo_prestazioni_manuale_hr = Math.round(this.invoice.importo_prestazioni_manuale_hr * 100) / 100 || 0;

    if (!(this.invoice.importo_direzione__area_manuale_hr == null))
      this.invoice.importo_direzione__area_manuale_hr = Math.round(this.invoice.importo_direzione__area_manuale_hr * 100) / 100 || 0;
    if (!(this.invoice.importo_gettone_unitario_hr == null))
      this.invoice.importo_gettone_unitario_hr = Math.round(this.invoice.importo_gettone_unitario_hr * 100) / 100 || 0;
    if (!(this.invoice.importo_prest_mediche_manuale_hr == null))
      this.invoice.importo_prest_mediche_manuale_hr = Math.round(this.invoice.importo_prest_mediche_manuale_hr * 100) / 100 || 0;
    if (!(this.invoice.importo_prest_anastesista_manuale_hr == null))
      this.invoice.importo_prest_anastesista_manuale_hr = Math.round(this.invoice.importo_prest_anastesista_manuale_hr * 100) / 100 || 0;

    if (this.invoice.nota_riporto_mese_succ != null) {

      this.invoice.nota_riporto_mese_succ = this.invoice.nota_riporto_mese_succ.replace(/&#10;/g, '\n').replace(/&#9;/g, '\t').replace(/&#8364;/g, '€').replace(/&#34;/g, '"').replace(/&#232;/g, 'e\'').replace(/&#242;/g, 'o\'').replace(/&#224;/g, 'a\'').replace(/&#249;/g, 'u\'').replace(/&#236;/g, 'i\'');

    }

    if (this.invoice.note != null) {

      this.invoice.note = this.invoice.note.replace(/&#10;/g, '\n').replace(/&#9;/g, '\t').replace(/&#8364;/g, '€').replace(/&#34;/g, '"').replace(/&#232;/g, 'e\'').replace(/&#242;/g, 'o\'').replace(/&#224;/g, 'a\'').replace(/&#249;/g, 'u\'').replace(/&#236;/g, 'i\'');

    }

    if (this.invoice.note_hr != null) {

      this.invoice.note_hr = this.invoice.note_hr.replace(/&#10;/g, '\n').replace(/&#9;/g, '\t').replace(/&#8364;/g, '€').replace(/&#34;/g, '"').replace(/&#232;/g, 'e\'').replace(/&#242;/g, 'o\'').replace(/&#224;/g, 'a\'').replace(/&#249;/g, 'u\'').replace(/&#236;/g, 'i\'');

    }

    this.details = data.details;
    this.detracts = data.detracts;
    for (let a = 0; a < this.detracts.length; a = a + 1) {
      if (this.detracts[a]['importo_addebito_medico'] != null) {
        this.detracts[a]['importo_addebito_medico'] = Math.round(this.detracts[a]['importo_addebito_medico'] * 100) / 100;
      }
      if (this.detracts[a]['perc_medico'] != null) {
        this.detracts[a]['perc_medico'] = Math.round(this.detracts[a]['perc_medico'] * 100) / 100;
      }
      if (this.detracts[a]['importo_ordine'] != null) {
        this.detracts[a]['importo_ordine'] = Math.round(this.detracts[a]['importo_ordine'] * 100) / 100;
      }
    }
    //console.log('details %o', data.details);
    this.detailsBkp = [...this.details];

    if (this.role != null && (this.role.toLowerCase() == 'amministratore' || this.role.toLowerCase() == 'hr medici')) {
      this.customButtons.push({
        matTooltipCode: 'GCE.GCEINVOICEPERSON.BUTTONS.EXPORTTOEXCEL.REPORTIINTERNOTOOLTIP',
        labelCode: 'GCE.GCEINVOICEPERSON.BUTTONS.EXPORTTOEXCEL.REPORTIINTERNOTOOLTIP',
        img: 'assets/img/excel.png',
        class: 'custom-button',
        clickFunction: this.exportToExcel.bind(this)
      });
    }

    // if (this.role.toLowerCase().indexOf("amministratore") >= 0 || this.role.toLowerCase().indexOf("hr medici") >= 0) {

    this.customButtons.push({
      matTooltipCode: 'GCE.GCEINVOICEPERSON.BUTTONS.EXPORTTOEXCEL.REPORTIMEDICOTOOLTIP',
      labelCode: 'GCE.GCEINVOICEPERSON.BUTTONS.EXPORTTOEXCEL.REPORTIMEDICOTOOLTIP',
      img: 'assets/img/email2.png',
      class: 'custom-button',
      clickFunction: this.exportToExcelDef.bind(this)
    });
    //}

    console.log('invoice %o', this.invoice);
    if (this.invoice.gg_disponibilta_calc > 0)
      this.t1 = this.invoice.gg_disponibilta_calc;
    if (this.invoice.gg_agenda_calc > 0)
      this.t2 = this.invoice.gg_agenda_calc;
    if (this.invoice.gg_gettone_calc > 0)
      this.t3 = this.invoice.gg_gettone_calc;
    //GETTONI MAN
    if (this.invoice.gg_gettone_manuale != null)
      this.t3 = this.invoice.gg_gettone_manuale;
    //GETTONI HR
    if (this.invoice.gg_gettone_manuale_hr != null)
      this.t3 = this.invoice.gg_gettone_manuale_hr;
    console.log('t3 calcolato', this.t3);
    if (this.invoice.importo_gettone_unitario != null && this.invoice.gg_gettone_calc != null)
      this.t14_1 = this.invoice.importo_gettone_unitario * this.invoice.gg_gettone_calc;
    if (this.invoice.importo_gettone_unitario != null && this.invoice.gg_gettone_manuale != null) {
      this.t14_2 = this.invoice.importo_gettone_unitario * this.invoice.gg_gettone_manuale;
    }
    if (this.invoice.importo_gettone_unitario != null && this.invoice.gg_gettone_manuale_hr != null) {
      this.t14_3 = this.invoice.importo_gettone_unitario * this.invoice.gg_gettone_manuale_hr;
    }
    if (this.invoice.gg_gettone_calc == 0 || this.invoice.importo_gettone_unitario == 0)
      this.t14_1 = 0;
    if (this.invoice.gg_gettone_manuale == 0 || this.invoice.importo_gettone_unitario == 0)
      this.t14_2 = 0;
    if (this.invoice.gg_gettone_manuale_hr == 0 || this.invoice.importo_gettone_unitario == 0)
      this.t14_3 = 0;
    if (this.invoice.importo_fisso_extra_calc > 0)
      this.t15_1 = this.invoice.importo_fisso_extra_calc;

    if (this.invoice.importo_integr_minimo_calc > 0)
      this.t19_1 = this.invoice.importo_integr_minimo_calc;
    /*
        if (this.invoice.importo_prestazioni_calc > 0)
          this.t16_1 = this.invoice.importo_prestazioni_calc;
        if (this.invoice.importo_prestazioni_calc < 0)
          this.t16_1 = this.invoice.importo_prestazioni_calc;
    */
    if (this.invoice.importo_prestazioni_manuale > 0)
      this.t16_2 = this.invoice.importo_prestazioni_manuale;
    if (this.invoice.importo_prestazioni_manuale < 0)
      this.t16_2 = this.invoice.importo_prestazioni_manuale;

    if (this.invoice.importo_prest_mediche_manuale > 0)
      this.t16_2 = this.t16_2 + this.invoice.importo_prest_mediche_manuale;
    if (this.invoice.importo_prest_mediche_manuale < 0)
      this.t16_2 = this.t16_2 + this.invoice.importo_prest_mediche_manuale;
    if (this.invoice.importo_prest_anastesista_manuale > 0)
      this.t16_2 = this.t16_2 + this.invoice.importo_prest_anastesista_manuale;
    if (this.invoice.importo_prest_anastesista_manuale < 0)
      this.t16_2 = this.t16_2 + this.invoice.importo_prest_anastesista_manuale;


    if (this.invoice.importo_prestazioni_manuale_hr > 0)
      this.t16_3 = this.invoice.importo_prestazioni_manuale_hr;
    if (this.invoice.importo_prestazioni_manuale_hr < 0)
      this.t16_3 = this.invoice.importo_prestazioni_manuale_hr;

    if (this.invoice.importo_prest_mediche_manuale_hr > 0)
      this.t16_3 = this.t16_3 + this.invoice.importo_prest_mediche_manuale_hr;
    if (this.invoice.importo_prest_mediche_manuale_hr < 0)
      this.t16_3 = this.t16_3 + this.invoice.importo_prest_mediche_manuale_hr;
    if (this.invoice.importo_prest_anastesista_manuale_hr > 0)
      this.t16_3 = this.t16_3 + this.invoice.importo_prest_anastesista_manuale_hr;
    if (this.invoice.importo_prest_anastesista_manuale_hr < 0)
      this.t16_3 = this.t16_3 + this.invoice.importo_prest_anastesista_manuale_hr;


    if (this.invoice.importo_direzione_calc > 0)
      this.t11_1 = this.invoice.importo_direzione_calc;
    if (this.invoice.importo_direzione_manuale > 0)
      this.t11_2 = this.invoice.importo_direzione_manuale;
    if (this.invoice.importo_direzione_manuale_hr > 0)
      this.t11_3 = this.invoice.importo_direzione_manuale_hr;

    if (this.invoice.importo_direzione__area_calc > 0)
      this.t12_1 = this.invoice.importo_direzione__area_calc;
    if (this.invoice.importo_direzione__area_manuale > 0)
      this.t12_2 = this.invoice.importo_direzione__area_manuale;
    if (this.invoice.importo_direzione__area_manuale_hr > 0)
      this.t12_3 = this.invoice.importo_direzione__area_manuale_hr;

    let d11 = null;
    if (!(this.t11_1 == null))
      d11 = this.t11_1;
    if (!(this.t11_2 == null))
      d11 = this.t11_2;
    if (!(this.t11_3 == null))
      d11 = this.t11_3;

    let d12 = null;

    if (!(this.t12_1 == null))
      d12 = this.t12_1;
    if (!(this.t12_2 == null)) {
      d12 = this.t12_2;

    }
    if (!(this.t12_3 == null)) {
      d12 = this.t12_3;

    }
    this.t13_t = d11 + d12;

    for (let a = 0; a < this.details.length; a = a + 1) {
      console.log('DT %o imp %o', this.details[a], this.details[a]['importo_prest_base_calc_medico']);
      if (this.details[a]['importo_prest_base_calc_medico'] > 0) {
        //this.t4 = this.t4 + this.details[a]['importo_prest_base_calc_medico'];
        this.t5 = this.t5 + this.details[a]['importo_prest_base_calc_medico'];
      }

      /*
            if (this.details[a]['compenso_medico'] > 0) {
              this.t16_1 = this.t16_1 + this.details[a]['compenso_medico'];
            }
      
      
            if (this.details[a]['compenso_medico'] < 0) {
              this.t17_1 = this.t17_1 + this.details[a]['compenso_medico'];
            }
      */

      if (this.details[a]['compenso_medico'] > 0 || this.details[a]['compenso_medico'] < 0) {
        this.t16_1 = this.t16_1 + this.details[a]['compenso_medico'];
      }

      if (this.details[a]['importo_prest_lordo'] > 0) {
        //this.t4 = this.t4 + this.details[a]['importo_prest_base_calc_medico'];
        this.t4 = this.t4 + this.details[a]['importo_prest_lordo'];
      }

      if (this.details[a]['importo_prest_base_calc_medico'] < 0) {
        this.t6 = this.t6 + this.details[a]['importo_prest_base_calc_medico'];
        //Warning

      }
      // if (this.details[a]['fisso_medico'] > 0)
      //   this.t15_1 = this.t15_1 + this.details[a]['fisso_medico'];
      //this.t17_1 = this.t17_1 + this.details[a]['riduzione_rifacimento'];
    }
    //this.t5 = this.t4 - tc;
    //this.t5 = this.t4 - this.t16_1;
    if (this.invoice.importo_addebiti_lab_calc > 0 && (!(this.t18_1 == 0) || this.invoice.importo_addebiti_lab_calc > this.t18_1)) {
      this.t18_1 = this.invoice.importo_addebiti_lab_calc;
    }
    let dinvisaling = 0;
    if (this.invoice.importo_detrazioni_invisalign_manuale > 0 || this.invoice.importo_detrazioni_invisalign_manuale < 0) {
      this.t18A_2 = this.invoice.importo_detrazioni_invisalign_manuale;
      if (this.t18A_2 > 0)
        this.t18A_2 = this.t18A_2 * -1;
      dinvisaling = this.t18A_2;
    }

    /*
    if (this.invoice.importo_addebiti_lab_manuale > 0 && (!(this.t17_2 == 0) || this.invoice.importo_addebiti_lab_manuale > this.t17_2))
    {
      this.t17_2 = this.invoice.importo_addebiti_lab_manuale;
    }
    */
    if ((this.invoice.importo_addebiti_lab_manuale_hr > 0 || this.invoice.importo_addebiti_lab_manuale_hr == 0) && (!(this.t18_3 == 0) || this.invoice.importo_addebiti_lab_manuale_hr > this.t18_3)) {
      this.t18_3 = this.invoice.importo_addebiti_lab_manuale_hr;
    }

    if (this.invoice.importo_detrazioni_invisalign_manuale_hr > 0 || this.invoice.importo_detrazioni_invisalign_manuale_hr == 0 || this.invoice.importo_detrazioni_invisalign_manuale_hr < 0) {
      this.t18A_3 = this.invoice.importo_detrazioni_invisalign_manuale_hr;
      if (this.t18A_3 > 0)
        this.t18A_3 = this.t18A_3 * -1;
      dinvisaling = this.t18A_3;
    }

    if (this.t18_1 > 0)
      this.t18_1 = this.t18_1 * -1;
    if (this.t18_2 > 0)
      this.t18_2 = this.t18_2 * -1;
    if (this.t18_3 > 0)
      this.t18_3 = this.t18_3 * -1;

    let p14 = null;
    if (!(this.t14_1 == null))
      p14 = this.t14_1;
    if (!(this.t14_2 == null))
      p14 = this.t14_2;
    if (!(this.t14_3 == null))
      p14 = this.t14_3;

    let p15 = null;

    if (!(this.t15_1 == null))
      p15 = this.t15_1;
    if (!(this.t15_2 == null)) {
      p15 = this.t15_2;

    }
    if (!(this.t15_3 == null)) {
      p15 = this.t15_3;

    }
    let p16 = null;
    if (!(this.t16_1 == null))
      p16 = this.t16_1;
    if (!(this.t16_2 == null)) {
      p16 = this.t16_2;
      hrm = true;
    }
    if (!(this.t16_3 == null)) {
      p16 = this.t16_3;
      hrm = true;
    }
    let p17 = null;
    if (!(this.t17_1 == null))
      p17 = this.t17_1;
    if (!(this.t17_2 == null))
      p17 = this.t17_2;
    if (!(this.t17_3 == null))
      p17 = this.t17_3;

    let p18 = null;
    if (!(this.t18_1 == null))
      p18 = this.t18_1;
    if (!(this.t18_2 == null))
      p18 = this.t18_2;
    if (!(this.t18_3 == null))
      p18 = this.t18_3;

    let p19 = null;
    if (!(this.t19_1 == null))
      p19 = this.t19_1;
    if (!(this.t19_2 == null))
      p19 = this.t19_2;
    if (!(this.t19_3 == null))
      p19 = this.t19_3;

    if (hrm == false)
      this.t110_t = p14 + p15 + p16 + p17 + p18 + p19 + dinvisaling;
    else
      this.t110_t = p14 + p15 + p16 + p18 + p19 + dinvisaling;

    for (let a = 0; a < this.detracts.length; a = a + 1) {

      if (!this.detracts[a].flg_riga_valida == true) {
        this.errs++;
      }
      if (this.detracts[a].importo_ordine > 0)
        this.t7 = this.t7 + this.detracts[a].importo_ordine;
    }
    //importi riporti
    if (this.invoice.importo_riporto_mese_prec > 0 || this.invoice.importo_riporto_mese_prec < 0) {
      this.t20_3 = this.invoice.importo_riporto_mese_prec;
      this.t110_t = this.t110_t + this.t20_3;
    }
    if (this.invoice.importo_riporto_mese_succ > 0 || this.invoice.importo_riporto_mese_succ < 0)
      this.t21_3 = this.invoice.importo_riporto_mese_succ;

  }

  toggleDetail() {
    /*
    console.log('toggleDetail');
    if (this.isDetail == true)
      this.isDetail = false;
    else
      this.isDetail = true;
    */
    const pickerA = document.getElementById('f1');
    const pr = document.getElementById('pr');
    pr.scrollBy(0, pickerA.offsetTop);
  }

  toggleUpA() {
    console.log('Toggle UP');
    const pickerA = document.getElementById('f1');
    const pr = document.getElementById('pr');
    pr.scrollBy(0, -pickerA.offsetTop);
  }

  toggleUpB() {
    console.log('Toggle UP');
    const pickerA = document.getElementById('f2');
    const pr = document.getElementById('pr');
    pr.scrollBy(0, -pickerA.offsetTop);
  }

  toggleDetract() {
    //window.scrollBy(0, -50);
    /*
    if (this.isDetract == true)
      this.isDetract = false;
    else
    {
      this.isDetract = true;
      const picker = document.querySelector('.myo');
      console.log('toggleDetract %o',picker);
      //pickerLeft.scrollBy({ : -330, behavior: 'smooth' });
      //const picker = document.querySelector('.screenListContainer');
      //picker.scrollBy({ left: -50, behavior: 'smooth' });
      //$(document).scrollTop();
      //window.scrollTop();
      picker.scrollTo(0,picker.scrollHeight + 100);
    }
    */
    const pickerB = document.getElementById('f2');
    const pr = document.getElementById('pr');
    pr.scrollBy(0, pickerB.offsetTop);

  }

  ngOnInit() {
    this.columns = GcePerformanceCalculationDetail.getStructure();
    this.columnsD = GcePerformanceCalculationDetracts.getStructure();
    this.gceService.loadEDentMedics().subscribe(mdev => {
      this.gceService.dataRepoEvMedics = mdev;
      this.gceService.loadMedics().subscribe(md => {
        this.gceService.dataRepoMedics = md;
        this.columns.forEach(column => {
          if (column.primaryKey) {
            this.keyColumn = column;
          }
          //console.log("GceDialogClinicAssociationComponent->ngOnInit column: %o", column.reference);
          if (column.reference) {
            let lookUpOptions: GceLookUpOptions;
            if (column.reference.entity === "GceInvoicePerson") {
              lookUpOptions = this.gceService.getLookUpAndOptions([this.invoice.person], column.reference);
            } else if (column.reference.entity === "GceClinic") {
              lookUpOptions = this.gceService.getLookUpAndOptions(this.gceService.dataRepoClinics, column.reference);
            }
            else if (column.reference.entity == "GceMedics2") {

              let opt = [];
              for (let o = 0; o < mdev.length; o++) {
                //console.log('PUSH %o', { key: md[o].codice_xdent, value: md[o].codice_xdent + ' - ' + md[o].medico_portale });
                //opt.push({ key: md[o].idfatturazione, value: md[o].idfatturazione + ' - ' + md[o].descrizione });
                opt.push({ key: mdev[o].OperatoreId, value: mdev[o].OperatoreId + ' - ' + mdev[o].OperatoreDescrizione });
              }

              lookUpOptions = { lookUp: {}, options: opt };
            }
            if (lookUpOptions) {
              column.lookUp = lookUpOptions.lookUp;
              column.options = lookUpOptions.options;
            }
          }
        });
        this.columnsD.forEach(column => {
          if (column.primaryKey) {
            this.keyColumn = column;
          }
          //console.log("GceDialogClinicAssociationComponent->ngOnInit column: %o", column.reference);
          if (column.reference) {
            let lookUpOptions: GceLookUpOptions;
            if (column.reference.entity === "GceInvoicePerson") {
              lookUpOptions = this.gceService.getLookUpAndOptions([this.invoice.person], column.reference);
            } else if (column.reference.entity === "GceClinic") {
              lookUpOptions = this.gceService.getLookUpAndOptions(this.gceService.dataRepoClinics, column.reference);
            }
            else if (column.reference.entity == "GceMedics2") {

              let opt = [];
              for (let o = 0; o < mdev.length; o++) {
                //console.log('PUSH %o', { key: md[o].codice_xdent, value: md[o].codice_xdent + ' - ' + md[o].medico_portale });
                //opt.push({ key: md[o].idfatturazione, value: md[o].idfatturazione + ' - ' + md[o].descrizione });
                opt.push({ key: mdev[o].OperatoreId, value: mdev[o].OperatoreId + ' - ' + mdev[o].OperatoreDescrizione });
              }

              lookUpOptions = { lookUp: {}, options: opt };
            }
            if (lookUpOptions) {
              column.lookUp = lookUpOptions.lookUp;
              column.options = lookUpOptions.options;
            }
          }
        });
      });
    });
    console.log('DATAS %o D %o', this.columns, this.columnsD);
  }

  sv(row: any) {
    console.log('SV %o', row);

    const confirm: Confirm = {
      title: "GCE.GCEINVOICE.DIALOG.CONFIRMDETRACT.TITLE",
      message: "GCE.GCEINVOICE.DIALOG.CONFIRMDETRACT.MESSAGE",
      buttonOkText: "GCE.GCEINVOICE.DIALOG.CONFIRMINVOICE.BUTTON.OK",
      buttonCancelText: "GCE.GCEINVOICE.DIALOG.CONFIRMINVOICE.BUTTON.CANCEL",
      buttonOkStyle: { 'background-color': '#268B26' },
      buttonCancelStyle: { 'background-color': '#AA3939' },
      withTranslate: true
      // messageParams: [{ key: 'INVOICE_NUMBER', value: invoice.id }]
    };
    if (row['num_prescrizione'] > 0 && row['nome_medico'] != null && row['nome_medico'].length > 0) {
      this.alertDialog.confirm(confirm).subscribe(result => {
        if (result) {

          this.gceService.updateDetract(row).subscribe((d) => {
            /*
            this.alertDialog.open({
              message: 'GCE.GCEINVOICE.DIALOG.CONFIRMEDDETRACT.MESSAGE',
              isError: false,
              withTranslate: true
            });
            */
          }, err => {

            this.alertDialog.open({
              message: 'GCE.GCEINVOICE.DIALOG.CONFIRMEDDETRACT.ERROR.MESSAGE',
              isError: true,
              withTranslate: true
            });

          });
        }
      });
    }
    else {
      //prescrizione, medico, nome medico sono contenuti obbligatori per la modifica
      window.alert("E' necessario compilare prescrizione e medico per poter correggere l'anomalia sulla detrazione.");
    }
  }

  onCustomActionClick(event) {
    console.log("onCustomActionClick %o", event);
    if (this[event.field.callback]) {
      this[event.field.callback](event.row);
    }
  }

  onSearch(searchFilter: any) {
    console.log("onSearch %o", searchFilter);
    this.details = this.detailsBkp.filter(item => {
      for (let key in item) {
        if (item[key] != null && item[key] != undefined && (item[key].toString().toLowerCase().indexOf(searchFilter.filter) > 0 || item[key].toString().toLowerCase().indexOf(searchFilter.filter) == 0))
          return true;
      }
      return false;
    });
    this.table.offset = 0;
  }

  onChangeRowP(row) {
    console.log("onChangeRow Details %o", row);
    this.currentRow = row;
    this.openRightPanel = true;
  }

  onChangeRowM(row) {
    console.log("onChangeRow Detracts %o", row);
    this.currentRow = row;
    this.openRightPanel = true;
  }

  onCloseForm(event) {
    console.log("onCloseForm %o", event);
    this.clear();
  }

  clear() {
    this.openRightPanel = false;
  }

  close() {
    this.dialogRef.close();
  }

  exportToExcel() {
    console.log('export to excel %o details %o detracts %o', this.invoice, this.details, this.detracts);
    let t1 = null;
    if (!(this.t1 == null))
      t1 = Math.round(this.t1 * 100) / 100;
    let t2 = null;
    if (!(this.t2 == null))
      t2 = Math.round(this.t2 * 100) / 100;
    let t3 = null;
    if (!(this.t3 == null))
      t3 = Math.round(this.t3 * 100) / 100;
    let t4 = null;
    if (!(this.t4 == null))
      t4 = Math.round(this.t4 * 100) / 100;
    let t5 = null;
    if (!(this.t5 == null))
      t5 = Math.round(this.t5 * 100) / 100;
    let t6 = null;
    if (!(this.t6 == null))
      t6 = Math.round(this.t6 * 100) / 100;
    let t7 = null;
    if (!(this.t7 == null))
      t7 = Math.round(this.t7 * 100) / 100;
    let t11_1 = null;
    if (!(this.t11_1 == null))
      t11_1 = Math.round(this.t11_1 * 100) / 100;
    let t11_2 = null;
    if (!(this.t11_2 == null))
      t11_2 = Math.round(this.t11_2 * 100) / 100;
    let t11_3 = null;
    if (!(this.t11_3 == null))
      t11_3 = Math.round(this.t11_3 * 100) / 100;
    let t12_1 = null;
    if (!(this.t12_1 == null))
      t12_1 = Math.round(this.t12_1 * 100) / 100;
    let t12_2 = null;
    if (!(this.t12_2 == null))
      t12_2 = Math.round(this.t12_2 * 100) / 100;
    let t12_3 = null;
    if (!(this.t12_3 == null))
      t12_3 = Math.round(this.t12_3 * 100) / 100;

    let t13_t = null;
    if (!(this.t13_t == null))
      t13_t = Math.round(this.t13_t * 100) / 100;
    let t14_1 = null;
    if (!(this.t14_1 == null))
      t14_1 = Math.round(this.t14_1 * 100) / 100;
    let t14_2 = null;
    if (!(this.t14_2 == null))
      t14_2 = Math.round(this.t14_2 * 100) / 100;
    let t14_3 = null;
    if (!(this.t14_3 == null))
      t14_3 = Math.round(this.t14_3 * 100) / 100;
    let t15_1 = null;
    if (!(this.t15_1 == null))
      t15_1 = Math.round(this.t15_1 * 100) / 100;
    let t15_2 = null;
    if (!(this.t15_2 == null))
      t15_2 = Math.round(this.t15_2 * 100) / 100;
    let t15_3 = null;
    if (!(this.t15_3 == null))
      t15_3 = Math.round(this.t15_3 * 100) / 100;
    let t16_1 = null;
    if (!(this.t16_1 == null))
      t16_1 = Math.round(this.t16_1 * 100) / 100;
    let t16_2 = null;
    if (!(this.t16_2 == null))
      t16_2 = Math.round(this.t16_2 * 100) / 100;
    let t16_3 = null;
    if (!(this.t16_3 == null))
      t16_3 = Math.round(this.t16_3 * 100) / 100;
    let t17_1 = null;
    if (!(this.t17_1 == null))
      t17_1 = Math.round(this.t17_1 * 100) / 100;
    let t17_2 = null;
    if (!(this.t17_2 == null))
      t17_2 = Math.round(this.t17_2 * 100) / 100;
    let t17_3 = null;
    if (!(this.t17_3 == null))
      t17_3 = Math.round(this.t17_3 * 100) / 100;
    let t18_1 = null;
    if (!(this.t18_1 == null))
      t18_1 = Math.round(this.t18_1 * 100) / 100;
    let t18_2 = null;
    if (!(this.t18_2 == null))
      t18_2 = Math.round(this.t18_2 * 100) / 100;
    let t18_3 = null;
    if (!(this.t18_3 == null))
      t18_3 = Math.round(this.t18_3 * 100) / 100;
    let t18A_2 = null;
    if (!(this.t18A_2 == null))
      t18A_2 = Math.round(this.t18A_2 * 100) / 100;
    let t18A_3 = null;
    if (!(this.t18A_3 == null))
      t18A_3 = Math.round(this.t18A_3 * 100) / 100;
    let t19_1 = null;
    if (!(this.t19_1 == null))
      t19_1 = Math.round(this.t19_1 * 100) / 100;
    let t19_2 = null;
    if (!(this.t19_2 == null))
      t19_2 = Math.round(this.t19_2 * 100) / 100;
    let t19_3 = null;
    if (!(this.t19_3 == null))
      t19_3 = Math.round(this.t19_3 * 100) / 100;
    let t110_t = null;
    if (!(this.t110_t == null))
      t110_t = Math.round(this.t110_t * 100) / 100;
    let t20_3 = null;
    if (!(this.t20_3 == null))
      t20_3 = Math.round(this.t20_3 * 100) / 100;
    let t21_3 = null;
    if (!(this.t21_3 == null))
      t21_3 = Math.round(this.t21_3 * 100) / 100;
    this.gceService.exportPerformanceCalculationDetailToExcel(this.invoice,
      this.details,
      this.detracts,
      t1,
      t2,
      t3,
      t4,
      t5,
      t6,
      t7,
      t11_1,
      t11_2,
      t11_3,
      t12_1,
      t12_2,
      t12_3,
      t13_t,
      t14_1,
      t14_2,
      t14_3,
      t15_1,
      t15_2,
      t15_3,
      t16_1,
      t16_2,
      t16_3,
      t17_1,
      t17_2,
      t17_3,
      t18_1,
      t18_2,
      t18_3,
      t18A_2,
      t18A_3,
      t19_1,
      t19_2,
      t19_3,
      t110_t,
      t20_3,
      t21_3
    );
    // this.gceService.exportToExcelInvoicePerson(this.personal, fileName);
  }
  exportToExcelDef() {
    console.log('export to excel %o details %o detracts %o', this.invoice, this.details, this.detracts);
    let t1 = null;
    if (!(this.t1 == null))
      t1 = Math.round(this.t1 * 100) / 100;
    let t2 = null;
    if (!(this.t2 == null))
      t2 = Math.round(this.t2 * 100) / 100;
    let t3 = null;
    if (!(this.t3 == null))
      t3 = Math.round(this.t3 * 100) / 100;
    let t4 = null;
    if (!(this.t4 == null))
      t4 = Math.round(this.t4 * 100) / 100;
    let t5 = null;
    if (!(this.t5 == null))
      t5 = Math.round(this.t5 * 100) / 100;
    let t6 = null;
    if (!(this.t6 == null))
      t6 = Math.round(this.t6 * 100) / 100;
    let t7 = null;
    if (!(this.t7 == null))
      t7 = Math.round(this.t7 * 100) / 100;
    let t11_1 = null;
    if (!(this.t11_1 == null))
      t11_1 = Math.round(this.t11_1 * 100) / 100;
    let t11_2 = null;
    if (!(this.t11_2 == null))
      t11_2 = Math.round(this.t11_2 * 100) / 100;
    let t11_3 = null;
    if (!(this.t11_3 == null))
      t11_3 = Math.round(this.t11_3 * 100) / 100;
    let t12_1 = null;
    if (!(this.t12_1 == null))
      t12_1 = Math.round(this.t12_1 * 100) / 100;
    let t12_2 = null;
    if (!(this.t12_2 == null))
      t12_2 = Math.round(this.t12_2 * 100) / 100;
    let t12_3 = null;
    if (!(this.t12_3 == null))
      t12_3 = Math.round(this.t12_3 * 100) / 100;
    let t13_t = null;
    if (!(this.t13_t == null))
      t13_t = Math.round(this.t13_t * 100) / 100;
    let t14_1 = null;
    if (!(this.t14_1 == null))
      t14_1 = Math.round(this.t14_1 * 100) / 100;
    let t14_2 = null;
    if (!(this.t14_2 == null))
      t14_2 = Math.round(this.t14_2 * 100) / 100;
    let t14_3 = null;
    if (!(this.t14_3 == null))
      t14_3 = Math.round(this.t14_3 * 100) / 100;
    let t15_1 = null;
    if (!(this.t15_1 == null))
      t15_1 = Math.round(this.t15_1 * 100) / 100;
    let t15_2 = null;
    if (!(this.t15_2 == null))
      t15_2 = Math.round(this.t15_2 * 100) / 100;
    let t15_3 = null;
    if (!(this.t15_3 == null))
      t15_3 = Math.round(this.t15_3 * 100) / 100;
    let t16_1 = null;
    if (!(this.t16_1 == null))
      t16_1 = Math.round(this.t16_1 * 100) / 100;
    let t16_2 = null;
    if (!(this.t16_2 == null))
      t16_2 = Math.round(this.t16_2 * 100) / 100;
    let t16_3 = null;
    if (!(this.t16_3 == null))
      t16_3 = Math.round(this.t16_3 * 100) / 100;
    let t17_1 = null;
    if (!(this.t17_1 == null))
      t17_1 = Math.round(this.t17_1 * 100) / 100;
    let t17_2 = null;
    if (!(this.t17_2 == null))
      t17_2 = Math.round(this.t17_2 * 100) / 100;
    let t17_3 = null;
    if (!(this.t17_3 == null))
      t17_3 = Math.round(this.t17_3 * 100) / 100;
    let t18_1 = null;
    if (!(this.t18_1 == null))
      t18_1 = Math.round(this.t18_1 * 100) / 100;
    let t18_2 = null;
    if (!(this.t18_2 == null))
      t18_2 = Math.round(this.t18_2 * 100) / 100;
    let t18_3 = null;
    if (!(this.t18_3 == null))
      t18_3 = Math.round(this.t18_3 * 100) / 100;
    let t18A_2 = null;
    if (!(this.t18A_2 == null))
      t18A_2 = Math.round(this.t18A_2 * 100) / 100;
    let t18A_3 = null;
    if (!(this.t18A_3 == null))
      t18A_3 = Math.round(this.t18A_3 * 100) / 100;
    let t19_1 = null;
    if (!(this.t19_1 == null))
      t19_1 = Math.round(this.t19_1 * 100) / 100;
    let t19_2 = null;
    if (!(this.t19_2 == null))
      t19_2 = Math.round(this.t19_2 * 100) / 100;
    let t19_3 = null;
    if (!(this.t19_3 == null))
      t19_3 = Math.round(this.t19_3 * 100) / 100;

    let t110_t = null;
    if (!(this.t110_t == null))
      t110_t = Math.round(this.t110_t * 100) / 100;

    let t20_3 = null;
    if (!(this.t20_3 == null))
      t20_3 = Math.round(this.t20_3 * 100) / 100;
    let t21_3 = null;
    if (!(this.t21_3 == null))
      t21_3 = Math.round(this.t21_3 * 100) / 100;
    if (this.invoice.flg_doc_emesso == true || this.role.toLowerCase().indexOf("amministratore") >= 0 || this.role.toLowerCase().indexOf("hr medici") >= 0) {
      this.gceService.exportPerformanceCalculationDetailDefToExcel(this.invoice,
        this.details,
        this.detracts,
        t1,
        t2,
        t3,
        t4,
        t5,
        t6,
        t7,
        t11_1,
        t11_2,
        t11_3,
        t12_1,
        t12_2,
        t12_3,
        t13_t,
        t14_1,
        t14_2,
        t14_3,
        t15_1,
        t15_2,
        t15_3,
        t16_1,
        t16_2,
        t16_3,
        t17_1,
        t17_2,
        t17_3,
        t18_1,
        t18_2,
        t18_3,
        t18A_2,
        t18A_3,
        t19_1,
        t19_2,
        t19_3,
        t110_t,
        t20_3,
        t21_3,
        true
      );
    }
    else {
      window.alert('Il report di dettaglio definitivo è generato solo dopo aver confermato la liquidazione.');
    }
  }
}
