import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AuthGuardService } from './../../../services/auth-guard.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import * as moment from 'moment';

@Component({
  selector: 'dialog-order-detail-download',
  templateUrl: './order-detail-download.dialog.html',
  styleUrls: ['./order-detail-download.dialog.scss']
})
export class OrderDetailDownloadDialogComponent implements OnInit {

  n;
  clinica;
  _columns;
  idCategoriaFornitore;
  temp;
  selected = [];
  loading = false;
  loadingIndicator = false;
  msgs;
  req;
  key;
  search;
  allProducts;
  url;
  editing = {};
  prods = [];
  localRows = [];
  isEditing;
  isBollaEntrata;
  dimW = 0;
  idTipoDocumentoUscita = 0;
  originalOrder;

  constructor(
    public _authService: AuthGuardService,
    public dialogRef: MatDialogRef<OrderDetailDownloadDialogComponent>,
    private _http: HttpClient,
    private _router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log('open OrderDetailDownloadDialogComponent with data %o', data);

    let col = data['dati']['tableMap'].columns;
    this.originalOrder = data['originalOrder'];
    this._columns = [];
    this.isEditing = data['isEditing'];
    this.dimW = data['w'];
    this.idTipoDocumentoUscita = data['idTipoDocumentoUscita'];
    /*
    this._columns.push({
      prop: "__Check",
      name: "",
      flexGrow: 2,
      format: "",
      type: "",
      editable: false,
      search: false
    });
    */
    for (let k of Object.keys(col)) {
      let c = col[k];
      if (c.visible) {
        if (k == 'NumRiga') {
          this._columns.push({
            prop: k,
            name: c.caption,
            flexGrow: 1,
            format: c.format,
            type: c.type,
            editable: false,
            search: true
          });
        }
        //manage specific client columns - first version
        if (k == 'Categoria' || k == 'BarCode' || k == 'Articolo' || k == 'Qta') {
          if (k == 'Qta')
            this._columns.push({
              prop: k,
              name: c.caption,
              flexGrow: 1,
              format: c.format,
              type: c.type,
              editable: true,
              search: true
            });
          else
            this._columns.push({
              prop: k,
              name: c.caption,
              flexGrow: 2,
              format: c.format,
              type: c.type,
              editable: false,
              search: true
            });
        }
      }
    };
    console.log('COLUMN %o ORIGINAL ORDER %o', this._columns, this.originalOrder);

    this.localRows = data['dati']['dettaglioOrdine'];

  }


  getCellClass({ row, column, value }): any {
    var t = this;
    return {
      'selectedClass': row.GiaSelezionato != undefined && row.GiaSelezionato
    };
  }

  selP(e, r, rowIndex) {
    r.QtaAggiunta = 0;
    r.BarCodeAggiunta = r.Barcode;

    r.GiaSelezionato = (e.target.checked);

  }

  focus() {
    $(function () {
      $('#txtQ').select();
      $('#txtQ').focus();
    });
  }

  removeOthers(name) {
    this.editing = [{}];
    this.editing[name] = true;
  }

  updated = false;
  updateValue(event, cell, rowIndex, row) {
    console.log('update value event %o cell %o rowIndex %o row %o', event, cell, rowIndex, row);
    if (event.target.value == undefined || isNaN(event.target.value)) return;
    if (event.target.value == "") event.target.value = "0";
    this.editing[rowIndex + '-' + cell] = false;
    if (+event.target.value < 0) {
      event.target.value = 0;
    }
    this.updated = true;
    for (let r of this.localRows)
      if (r == row) {
        r[cell] = +event.target.value;
        break;
      }
    this.localRows = [...this.localRows];

    this.addIt(row);
  }

  addIt(row) {
    let ind = 0;
    /*
    for (let r of this._tableService.rows)
      if (r.Idarticolo == row['Idarticolo']) {
        delete this._tableService.rows[ind];
        this._tableService.rows = this._tableService.rows.filter(function(e) { return e }); 
        break;
      } else ind++;
    */
    for (let r of this.localRows)
      if (r == row) {
        r.QtaAggiunta = 0;
        r.BarCodeAggiunta = r.Barcode;
        r['GiaSelezionato'] = true;
        //this._tableService.rows.push(r);
        break;
      }
  }

  isCurrency(t, f) {
    return ((t == 'Currency') && f != '' && f !== undefined);
  }

  updateFilter(event) {
    let columnName = event.currentTarget.id;
    const val = event.target.value.toLowerCase();
    const filteredData = this.temp.filter(function (d) {
      if (d[columnName] === undefined || typeof d[columnName] === "number") return true;
      return d[columnName].toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.localRows = filteredData;
  }

  ngOnInit() {
  }

  onSelect({ selected }) {

  }

  focusLente(id) {
    $(function () {
      $('#' + id).select();
      $('#' + id).focus();
    });
  }

  onSort(ev) {
    /*return;
    if (ev['column']['prop'] != '__Check') return;
    this.rows = [...this.rows.sort(function(x, y) {
      // true values first
      return (x.GiaSelezionato === y.GiaSelezionato)? 0 : x.GiaSelezionato ? -1 : 1;
      // false values first
      // return (x === y)? 0 : x? 1 : -1;
    })];
    console.log("sorted");*/
  }


  calculateWidthColumn(c, cs): number {
    //calculate sectors
    if (this.dimW == 0)
      return 100;

    let tot = 0;
    for (let a = 0; a < cs.length; a = a + 1) {
      tot = tot + cs[a].flexGrow;
    }
    let sp = (this.dimW  - 30)/ tot;
    //console.log('calculateWidthColumn sp %o dt %o',sp * c.flexGrow,this.dimW );
    
    return Math.round(sp * c.flexGrow);
  }

  hasClass(who, asc) {
    if (!asc && document.getElementsByClassName('sort-desc').length == 0) return true;
    if (asc && document.getElementsByClassName('sort-asc').length == 0) return true;
    if (!asc)
      for (let x of Array.prototype.slice.call(document.getElementsByClassName('sort-desc')))
        try {
          return !(who == x.children[0].children[0].children[0].children[1].children[1].innerHTML.trim());
        }
        catch (e) {
          return true;
        }
    if (asc)
      for (let x of Array.prototype.slice.call(document.getElementsByClassName('sort-asc')))
        try {
          return !(who == x.children[0].children[0].children[0].children[1].children[1].innerHTML.trim());
        }
        catch (e) {
          return true;
        }
    return true;
  }


  close() {
    this.dialogRef.close(false);
  }

  redirectTo(uri:string){
    this._router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this._router.navigate([uri]));
 }

  downL() {
    let str = 'Sei sicuro di voler scaricare l\' intera bolla di entrata ' + this.data['originalOrder']['Iddoc'] + '?';
    console.log('download order rows %o', this.localRows);
    if (window.confirm(str)) {
      let rif = '';
      if (!(this.originalOrder == null))
      {
        rif = this.originalOrder['IdDocPrec'];
      }
      this.url = environment['EmettiBollaUscitaTotOrdine'];
      this.req = {
        idnegozio: environment['idNegozio'],
        GUID: this._authService.getGUID(),
        DomainId: this._authService.getDomain(),
        ProfileId: this._authService.getProfile(),
        idDoc: this.data['originalOrder'].Iddoc,
        IdTipologiaDocumento: this.idTipoDocumentoUscita,
        idMitt: this.data['originalOrder'].Idmitt,
        idDest: this.data['originalOrder'].Iddest,
        Riferimento:rif,
        DataDocumento: moment(new Date()).format('YYYY-MM-DD'),
        articoli: []
      };
      for (let a = 0; a < this.localRows.length; a = a + 1) {
        this.req.articoli.push(
          {
            IdUdm: this.localRows[a]['Idudm'],
            Idarticolo: this.localRows[a]['Idarticolo'],
            ScalaUDM: this.localRows[a]['ScalaUdm'],
            Idvend:this.localRows[a]['Idvend'],
            Qta: 0,
            NumConfezioni: this.localRows[a]['Qta']
          }
        );
      }
      console.log('DATA %o and rows %o', this.data, this.localRows);
      console.log('call %o with %o', this.url, this.req);
      this._http.post(
        this.url,
        this.req
      )
        .subscribe((res) => {
          console.log('RESULT %o', res);
          if (res['esito'] == true) {
            window.alert('Operazione eseguita con successo');
          }
          else {
            window.alert('ERRORE nell\'operazione: ' + res['errorMessage']);

          }
          //rebuild orders
         
         // this._router.navigate(['/pm/store/action/exit-docs-entry']);
         this.redirectTo('/pm/store/action/exit-docs-entry');
        });

    }
    this.dialogRef.close();
  }

}