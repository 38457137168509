import {
	Component, EventEmitter, OnInit, Input,
	OnChanges, SimpleChange, ViewChild, Inject, ElementRef, NgZone
} from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatChipInputEvent } from '@angular/material';
import { ENTER } from '@angular/cdk/keycodes';
import { } from 'google-maps';
import { MapsAPILoader } from '@agm/core';

const COMMA = 188;

@Component({
	selector: 'app-dialog-custom-field',
	templateUrl: './dialog-custom-field.component.html',
	styleUrls: ['./dialog-custom-field.component.css']
})
export class DialogCustomFieldComponent implements OnInit {
	form: any;
	isArray: boolean = false;
	title: string = "";
	column: {} = null;
	dataStructure: Object[];
	separatorKeysCodes = [ENTER, COMMA];
	editable: boolean = true;

	gmSearchControl: FormControl;
	private componentForm = {
		street_number: 'short_name',
		route: 'long_name',
		locality: 'long_name',
		administrative_area_level_1: 'short_name',
		administrative_area_level_2: 'short_name',
		country: 'long_name',
		postal_code: 'short_name'
	};

	private componentFormMapper = {
		street_number: 'streetNumber',
		route: 'streetName',
		locality: 'city',
		administrative_area_level_1: 'areaCode1',
		administrative_area_level_2: 'areaCode2',
		country: 'nation',
		postal_code: 'postalCode'
	}

	@ViewChild('dynamicform', {static:false}) dynamicform;
	@ViewChild("gmSearch", {static:false}) gmSearchElementRef: ElementRef;

	constructor(public dialogRef: MatDialogRef<DialogCustomFieldComponent>, private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any,
		private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) {
		// console.log('data in constructor %o',data);
		if (data) {
			this.dataStructure = data.column.subColumns;
			this.column = data.column;
			this.title = data.column.header;
			this.data = data.row;
			if (this.column['subType'] === 'array') {
				this.form = data.form.controls[this.column['columnDef']];
			} else {
				this.form = data.form.controls[this.column['columnDef']];
			}
			//console.log("dialog custom field: %o", this.form);
			this.editable = data.editable;
			this.init();
		}

	  this.dialogRef.beforeClose().subscribe(()=>{
	    // console.log("close : %o", this.form.controls);
			if (!this.editable) {
				Object.keys(this.form.controls).forEach(key => {
					this.form.controls[key].enable();
				});
			}
	    this.dialogRef.close();

	  });
	}

	init() {
		// console.log("form %o column %o", this.form, this.column);
		// if(this.column['subType']==='array'){
		// 	this.form = this.data.$$form;
		// }else{
		// 	this.form = this.data.$$form.controls[this.column['columnDef']];
		// }
		//console.log(this.editable);
		if (!this.editable) {
			Object.keys(this.form.controls).forEach(key => {
				this.form.controls[key].disable();
			});
		}
	}

	ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
		this.init();
	}

	ngOnInit() {
		if (this.column['subType'] === 'address') {
			this.gmSearchControl = new FormControl();
			this.mapsAPILoader.load().then(() => {
				// console.log("maspApiLoader %o" , a);
				if (this.gmSearchElementRef) {
					let autocomplete = new google.maps.places.Autocomplete(this.gmSearchElementRef.nativeElement, {
						types: ["address"]
					});
					/*autocomplete.setComponentRestrictions(
						{'country': ['it']});*/
					autocomplete.addListener("place_changed", () => {
						this.ngZone.run(() => {
							let place: google.maps.places.PlaceResult = autocomplete.getPlace();

							if (place.geometry === undefined || place.geometry === null) {
								return;
							}
							this.dynamicform.nativeElement.querySelector('form').reset();
							for (var i = 0; i < place.address_components.length; i++) {
								var addressType = place.address_components[i].types[0];
								if (this.componentForm[addressType]) {
									var val = place.address_components[i][this.componentForm[addressType]];
									this.form.controls[this.componentFormMapper[addressType]].patchValue(val);
								}
							}
						});
					});
				}
			});
		}
	}

	submit() {
		let form = null;
		if (this.column['subType'] === 'array') {
			form = this.form.controls[this.column['columnDef']];
		} else {
			form = this.form;
		}
		if (form.valid) {
			this.dialogRef.close({
				form: this.form
			});
		} else {
			alert("Check the input fields!");
		}
	}

	cancel() {
		this.dialogRef.close();
	}

	get formArray(): FormArray {
		// console.log("formArray: %o", this.form);
		return this.form as FormArray;
	}

	addElement() {
		let group: any = {};
		this.dataStructure.map(item => {
			//console.log('item[columnDef] %o data %o',item['columnDef'],this.data);
			let theRules = [];
			if (item['type'] === 'action') return;
			if (!item['visible']) return;
			if (item['required']) theRules.push(Validators.required);
			if (item['length']) theRules.push(Validators.maxLength(item['length']));
			if (item['pattern']) theRules.push(Validators.pattern(item['pattern']));
			let theValue :any;
			if (item['columnDef'] != null && this.data[item['columnDef']] != null)
				theValue = this.data[item['columnDef']];
			if (theValue === null || theValue === undefined) theValue = "";
			if (item['type'] === 'date') theValue = new Date(theValue);
			else if (item['type'] === 'array' && theValue === "") theValue = [];
			else if (item['type'] === 'boolean' && theValue === "") theValue = false;
			else if (item['type'] === 'select' && theValue === "") theValue = null;
			group[item['columnDef']] = new FormControl(
				{
					value: theValue,
					disabled: item['disabled']
				}, theRules
			);
		});
		this.formArray.push(this.fb.group(group));
	}

	removeElement(index) {
		this.formArray.removeAt(index);
	}
}
