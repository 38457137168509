import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { HttpClient } from '@angular/common/http';

import { AuthGuardService } from './../../../services/auth-guard.service';

@Component({
  selector: 'yes-no-dialog',
  templateUrl: './yes-no.dialog.html'
})
export class YesNoDialogComponentS implements OnInit {

  title;
  desc;
  options;

  constructor(
    public _authService: AuthGuardService,
    public dialogRef: MatDialogRef<YesNoDialogComponentS>,
    private _http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.stato == 'conferma') {
      this.options = [
        {
          caption: 'annulla',
          action: 'false'
        },
        {
          caption: 'sì',
          action: 'true'
        }
      ];
      this.title = "Conferma";
      this.desc = "Sei sicuro di voler eliminare " + ((data.oggetto == undefined ? "l'ordine" : data.oggetto)) + "? L'operazione è <b>irreversibile</b>.";
    } else {
      this.options = data.options;
      this.desc = data.desc;
      this.title = data.title;
    }
  }

    ngOnInit() {
    }

    close (a) {
      this.dialogRef.close(a);
    }

}