import { Component, OnInit, ViewChildren, ViewChild, ElementRef, QueryList, AfterViewInit, Renderer2, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ControllersService, ActionsService } from '../services';
import { webTicAction } from '../app.model';
import { LoaderService } from '../loader';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-page-management',
  templateUrl: './page-management.component.html',
  styleUrls: ['./page-management.component.css']
})
export class PageManagementComponent implements OnInit, AfterViewInit, OnDestroy {
  actions: webTicAction[] = [];
  dataEntry: Object | null;
  action: webTicAction = null;
  isOrderMgmt: boolean = false;
  isConcession: boolean = false;
  isMessagesManagement: boolean = false;
  isForecast: boolean = false;
  isManagementForecast: boolean = false;
  iscrtg: boolean = false;
  isSuppliers: boolean = false;
  isGCE: boolean = false;
  isGMA: boolean = false;
  isDBASE: boolean = false;
  isAff: boolean = false;
  isIncentive: boolean = false;
  currentController: any;
  showb: boolean = false;
  isMonitorSSO: boolean = false;
  @ViewChild("actionsScroller", { static: false }) actionsScroller: ElementRef;

  private sub: any;

  constructor(private activeRoute: ActivatedRoute,
    private route: Router,
    private controllersService: ControllersService,
    private actionsService: ActionsService,
    private loaderService: LoaderService,
    private renderer: Renderer2) {
    this.renderer.addClass(document.body, 'withoutoverflow');
    // console.log('withoutoverflow');
  }

  @ViewChildren('allChips') allChips: QueryList<any>;

  ngAfterViewInit() {
    this.allChips.changes.subscribe(t => {
      if (t['_results'].length > 0) {
        //this.loaderService.hide();
      }
    });
  }

  ngOnInit() {
    console.log('INIT PAGE MANAGEMENT');

    this.allChips = new QueryList<any>();

    //fv1
    this.activeRoute.params.subscribe(parameters => {
      console.log("initPM params %o", parameters['controller']);
      this.controllersService.currentController = this.currentController = parameters['controller'];
      if (!this.controllersService.currentController || this.controllersService.currentController == null || this.controllersService.currentController == {}) {

        this.controllersService.currentController = this.activeRoute.snapshot.queryParams.controller;
        console.log("initPM2 params %o", this.controllersService.currentController);
      }
      //wait 2 second
      //this.showb = true;
      //setTimeout(() => {  

      this.initChips();
      this.showb = false;
      this.route.events.subscribe((event) => {
        if (event.constructor === NavigationEnd) {
          this.checkForPreSelectedEntity();
        }
      });
      // }, 2000);

    });
  }

  ngDoCheck() {

    if (this.actions == null || this.actions.length == 0) {
      //console.log('check DoCheck %o currentcontroller %o', this.actions, this.currentController);
      this.isOrderMgmt = false;
      this.isConcession = false;
      this.isForecast = false;
      this.isManagementForecast = false;
      this.iscrtg = false;
      this.isMessagesManagement = false;
      this.isGCE = false;
      this.dataEntry = undefined;
      this.isGMA = false;
      this.isAff = false;
      this.isDBASE = false;
      this.isIncentive = false;
      this.isMonitorSSO = false;
      if (this.currentController) {
        if (this.currentController.startsWith("store")) {

          this.isOrderMgmt = true;
        } else if (this.currentController.startsWith("concession")) {

          this.isConcession = true;
        } else if (this.currentController.startsWith("forecast")) {

          this.isForecast = true;
        } else if (this.currentController.startsWith("crtg")) {

          this.iscrtg = true;
        } else if (this.currentController.startsWith("messages-mngmt")) {

          this.isMessagesManagement = true;
        }
        else if (this.currentController.startsWith("suppliers")) {

          this.isSuppliers = true;
        } else if (this.currentController.startsWith("mngfrcst")) {

          this.isManagementForecast = true;
        } else if (this.currentController.startsWith("mail_affittuari")) {

          this.isAff = true;
        }
        else if (this.currentController.startsWith("sso-mon")) {

          this.isMonitorSSO = true;
        }
      }

      else {
        console.log('critical DoCheck %o currentcontroller %o', this.actions, this.controllersService);
        this.controllersService.currentController.subscribe(cr => {
          console.log('A critical DoCheck %o', cr);
          /*
          this.actionsService.getActions(cr).subscribe(ass => {
            console.log('B critical DoCheck %o', ass);
            this.actions = ass;

            if (this.currentController.startsWith("incentive")) {
              this.isIncentive = true;
            }
            if (this.currentController.startsWith("gce")) {
              this.isGCE = true;
            } else if (this.currentController.startsWith("gma")) {
              this.isGMA = true;
            } else if (this.currentController.startsWith("datibase")) {
              this.isDBASE = true;
            }

          });
          */
        });
      }

    }
  }

  checkForPreSelectedEntity() {
    let entity = window.location.hash;
    let self = this;
    if (entity && entity.length > 2) {
      entity = entity.slice(2);
    } else {
      self.dataEntry = null;
      self.action = null;
      self.actionsService.currentAction = null;
      return;
    };
    const entityName = entity.split("/")[0];

    let filter = this.actions.filter(action => {
      if (+entityName) {
        //id
        return action.id == +entityName;
      } else {
        //path
        return action.path.startsWith(entityName);
      }
    })

    if (filter.length > 0) {
      let action = filter[0];
      if (action.isTableManagement) {
        if (!action.isSingleTableManagement) {
          self.dataEntry = {
            resource: action.entity,
            title: action.labelCode,
            structureAction: action
          };
        } else {
          self.dataEntry = {
            tablesName: action.entity,
            title: action.labelCode,
            structureAction: action
          }
        }
      } else {
        self.dataEntry = {
          resource: action.entity,
          room: action.id,
          title: action.labelCode,
          structureAction: action
        }
      }
      self.action = action;
      self.actionsService.currentAction = self.action.id;
    }
  }

  initChips() {
    //this.sub = this.activeRoute.params.subscribe(params => {
    // this.activeRoute.params.subscribe(parameters => {
    console.log("initChips() %o", this.activeRoute);
    // this.controllersService.currentController = parameters['controller'];

    this.controllersService.currentController.subscribe(currentController => {
      //this.currentController = currentController;

      this.isOrderMgmt = false;
      this.isConcession = false;
      this.isForecast = false;
      this.isManagementForecast = false;
      this.iscrtg = false;
      this.isMessagesManagement = false;
      this.isGCE = false;
      this.dataEntry = undefined;
      this.isGMA = false;
      this.isAff = false;
      this.isDBASE = false;
      this.isIncentive = false;
      if (this.currentController) {
        if (this.currentController.startsWith("store")) {
          this.loaderService.hide();
          this.isOrderMgmt = true;
        } else if (this.currentController.startsWith("concession")) {
          this.loaderService.hide();
          this.isConcession = true;
        } else if (this.currentController.startsWith("forecast")) {
          this.loaderService.hide();
          this.isForecast = true;
        } else if (this.currentController.startsWith("crtg")) {
          this.loaderService.hide();
          this.iscrtg = true;
        } else if (this.currentController.startsWith("messages-mngmt")) {
          this.loaderService.hide();
          this.isMessagesManagement = true;
        }
        else if (this.currentController.startsWith("suppliers")) {
          this.loaderService.hide();
          this.isSuppliers = true;
        } else if (this.currentController.startsWith("mngfrcst")) {
          this.loaderService.hide();
          this.isManagementForecast = true;
        } else if (this.currentController.startsWith("mail_affittuari")) {
          this.loaderService.hide();
          this.isAff = true;
        } else if (this.currentController.startsWith("sso-mon")) {
          this.loaderService.hide();
          this.isMonitorSSO = true;
        } else {
          console.log("this.currentController %o cc %o", this.currentController, currentController);
          if (currentController != null && currentController.path != null) {
            this.actionsService.getActions(currentController).subscribe(actions => {
              this.actions = actions;
              console.log(currentController);

              let idxAction = 0;
              if (this.currentController.startsWith("incentive")) {
                this.isIncentive = true;
                idxAction = -1;
              }
              if (this.currentController.startsWith("gce")) {
                this.isGCE = true;
                idxAction = -1;
              } else if (this.currentController.startsWith("gma")) {
                this.isGMA = true;
                idxAction = -1;
              } else if (this.currentController.startsWith("datibase")) {
                this.isDBASE = true;
                idxAction = -1;
              }

              if (idxAction >= 0) {
                this.onSelectChip(this.actions[idxAction]);
                this.checkForPreSelectedEntity();
              }
              this.loaderService.hide();
            });
          }
        }
      }
    });
    //});
  }

  onSelectChip(action: webTicAction) {
    window.location.hash = "/" + action.path;
  }

  goBack() {
    window.history.back();
  }

  setMatChipStyle(action: webTicAction) {
    let self = this;
    let active: boolean = false;
    if (self.action != null) {
      if (action.id === self.action.id) {
        active = true;
      }
    }

    let styles = {
      'border-style': 'solid',
      'border-color': action.hcolor,
      'background': active ? '' : '#fff',
      'color': active ? '' : '#000',

    };
    return styles;
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'withoutoverflow');
  }

  scrollLeft() {
    //console.log("scrollLeft %o", this.actionsScroller);
    this.actionsScroller.nativeElement.scrollBy({ left: -484, behavior: 'smooth' });
  }

  scrollRight() {
    //console.log("scrollRight %o", this.actionsScroller);
    this.actionsScroller.nativeElement.scrollBy({ left: 484, behavior: 'smooth' });
  }

  verifyAccessToController(controller: string): boolean {
    let r: boolean;
    this.controllersService.controllers.subscribe(controllers => {
      r = controllers.some(c => c.path === controller);
      // r = true;
      // console.log("verifyAccessToController %o", r);
    });
    return r;
  }


  wheel(event) {
    // console.log("wheel %o", event.deltaY);
    if (event.deltaY >= 1) {
      this.scrollRight();
    } else if (event.deltaY < 0) {
      this.scrollLeft();
    }
  }
}
