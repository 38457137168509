import { trigger, state, animate, transition, style } from '@angular/animations';

export const fadeOutAnimation =
trigger('fadeOut', [
    transition('void => *', [
      style({opacity:0}), //style only for transition transition (after transiton it removes)
      animate(500, style({opacity:1})) // the new state of the transition(after transiton it removes)
    ]),
    transition('* => void', [
      animate(500, style({opacity:0})) // the new state of the transition(after transiton it removes)
    ])
  ]);