import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IncentiveService } from '../../services/incentive.service';
import { AuthGuardService } from '../../services';
import { LoaderService } from '../../loader/loader.service';
import { MatTableDataSource } from '@angular/material/table';
import { AlertDialogService } from '../../services/alert-dialog.service';
import { MatDialog } from '@angular/material';
import * as moment from 'moment';
import { AfterViewInit, Component, ViewChild, OnInit } from '@angular/core';
import { GceManagementService } from '../../services/gce-management.service';
import { ExcelService } from '../../services/excel.service';
import { MatSort, MatPaginator } from '@angular/material';
const dateFormatDate = "YYYY-MM-DD";
import { FormHeaderComponent, ICustomButton, IFormHeaderSearch, FormHeaderSearchEventsEnum } from '../../shared/form-header/form-header.component';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Rx';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Observer, merge } from 'rxjs';
import { TargetImportComponent } from '../dialog/target-import.component';
import { Target2ImportComponent } from '../dialog/target2-import.component';
import { Target3ImportComponent } from '../dialog/target3-import.component';

import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-target',
  templateUrl: './target.component.html',
  styleUrls: ['./target.component.css', '../incentive.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])]
})

export class TargetComponent implements OnInit {

  displayedColumns = ['CodClinica', 'NomeClinica', 'area_manager', 'district_manager', 'Mese', 'ForecastFatturato', 'TgFatturato', 'TgProduzione', 'TgCheckUp', 'ObiettivoRecensioniOnline', 'ObiettivoSurveyFineTrattamento', 'Malus', 'ModificaDt', 'openAction'];
  displayedColumnsSlave = ['IdProfilo', 'Login', 'IdOperatore', 'TgFatturatoOperatore', 'delete'];

  dataSource: any;
  dataSourceSlave: any;
  columns: any[] = [];
  detracts: any;
  detractsBck: any;
  limitRows: number;
  heightMainList: number;
  currentRow: any;
  editing: boolean = false;
  keyColumn: any = {};
  customButtons: ICustomButton[] = [];
  role: string = '';
  dm: string = '';
  isAdmin: boolean = false;
  isAM: boolean = false;
  isDM: boolean = false;
  isCM: boolean = false;
  isViewer: boolean = true;
  fromDate: any;
  toDate: any;
  showb: boolean;
  usr: any;
  kwSearch: any;
  expandedElement: any;
  oldSelectedElement: any;
  expandedElement2: any;
  isReadOnly: boolean = false;

  ELEMENT_DATA: any[];
  @ViewChild(MatSort, null) sort: MatSort;
  @ViewChild(MatPaginator, null) paginator: MatPaginator;

  constructor(
    private incentiveService: IncentiveService,
    private auth: AuthGuardService,
    private http: HttpClient,
    private excelService: ExcelService,
    private gceService: GceManagementService,
    private dialog: MatDialog) {
    this.fromDate = new Date();
    this.toDate = new Date();
    //this.dataSourceSlave = new MatTableDataSource();
    this.dataSource = new MatTableDataSource();
  }



  onSearch(e) {
    this.dataSource.filter = e.trim().toLowerCase();
  }

  clickD(row) {
    console.log('row %o', row);
  }

  del(element2) {
    console.log('delete %o', element2);
    if (!(this.isAdmin == true)) {
      window.alert('Operazione non consentita.')
      return false;
    }

    if (window.confirm("Sei sicuro di voler cancellare la riga relativa all'utente " + element2.Login + " clinica " + element2.CodClinica + "?")) {
      let py = { RigaId: element2.RigaId };
      this.incentiveService.deleteTarget(py).subscribe(
        x => {
          window.alert('Operazione effettuata. Ricarica la pagina per visualizzare le modifiche.')
        }
      );

    }
  }



  clickPP(element) {


    this.expandedElement = this.expandedElement === element ? null : element;

    if (this.expandedElement === element) {
      console.log('UGUALI');
    }
    else {
      console.log('DIVERSI');
    }

    if (this.oldSelectedElement != null && this.oldSelectedElement != element)
      this.oldSelectedElement.selected = false;

    if (element.selected == false) {
      if (this.expandedElement != null)
        this.expandedElement.selected = true;
      element.selected = true;
      element.touched = true;
    }
    else {
      element.selected = false;
      if (this.expandedElement != null)
        this.expandedElement.selected = false;

    }
    this.oldSelectedElement = element;

  }

  init(from, to) {
    this.showb = true;
    let py = {
      from: from,
      to: to,
      desc_domain: this.dm,
      profile: this.role,
      usr: this.usr
    };

    console.log('payload %o fromDate %o toDate %o service %o', py, this.fromDate, this.toDate, this.gceService);
    this.http.post(`${environment.gceAPI}/incentive_all_target/`, py, { responseType: 'json' }).subscribe(res => {
      //  this.http.post(`http://localhost:57526/api/incentive_all_target/`, py, { responseType: 'json' }).subscribe(res => {
      this.showb = false;
      //this.allData = res;

      let myDt = [];
      res['incentive_all_target_s'].forEach(element => {
        var toPush = element['incentive_target_clinica'];
        toPush.touched = false;
        toPush.persons = element['incentive_target_operatores'];
        //myDt.push(element['incentive_target_clinica']);
        myDt.push(toPush);

      });
      console.log('response for getTarget %o', myDt);
      //this.dataSource = new MatTableDataSource(myDt);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.data = myDt;
      var close_date = new Date(this.incentiveService.dataRepoGceEnvironment.ultima_chiusura_incentive);

      if (this.fromDate.getMonth() >= close_date.getMonth() && this.fromDate.getFullYear() == close_date.getFullYear()) {
        this.isReadOnly = false;
      }
      else {
        this.isReadOnly = true;
      }
      this.dataSource.filterPredicate = (data: any, filterValue: string) => {
        console.log('DATA FOR FILTER %o %o', data, filterValue);
        if (!(data.CodClinica == null) && data.CodClinica.trim().toLowerCase().indexOf(filterValue) >= 0)
          return true;
        else {
          if (!(data.NomeClinica == null) && data.NomeClinica.trim().toLowerCase().indexOf(filterValue) >= 0)
            return true;
          else {
            if (!(data.area_manager == null) && data.area_manager.trim().toLowerCase().indexOf(filterValue) >= 0)
              return true;
            else {
              if (!(data.district_manager == null) && data.district_manager.trim().toLowerCase().indexOf(filterValue) >= 0)
                return true;
              else
                return false;
            }
          }
        }
      };

    }, err => {
      console.log('response for getTarget %o', err);
      this.showb = false;
      window.alert('Errore nel recupero delle informazioni dal DB. ' + err['message']);
    });
  }

  initMonth() {


    //if (this.isReadOnly == false) {

    if (this.isAdmin == true) {
      var ms = this.fromDate.toLocaleString('default', { month: 'long' });
      if (window.confirm("Sei sicuro di voler inizializzare il mese di " + ms + "? Le informazioni delle cliniche che hanno già dei dati relativi ai target operatori non verranno modificate.")) {
        this.showb = true;
        let py = {
          from: moment(this.fromDate).startOf('month').format('YYYYMMDD'),
          to: moment(this.fromDate).endOf('month').format('YYYYMMDD'),
          desc_domain: null
        };
        this.http.post(`${environment.gceAPI}/refresh_clinic_structure/`, py, { responseType: 'json' }).subscribe(res => {
          this.http.post(`${environment.gceAPI}/insert_ex_tutor/`, py, { responseType: 'json' }).subscribe(res2 => {
            //  this.http.post(`http://localhost:57526/api/init_target_all/`, py, { responseType: 'json' }).subscribe(res => {
            // this.init(moment(this.fromDate).startOf('month').format('YYYYMMDD'), moment(this.fromDate).endOf('month').format('YYYYMMDD'));
            window.alert('Inizializzazione del mese terminata.');
            console.log('response for refresh_clinic_structure %o insert_ex_tutor %o', res, res2);
          }, err => {
            console.log('response for insert_ex_tutor %o', err);
            this.showb = false;
            window.alert('Errore nella inizializzazione del mese sul DB. ' + err['message']);
          });
        }, err => {
          console.log('response for refresh_clinic_structure %o', err);
          this.showb = false;
          window.alert('Errore nella inizializzazione del mese sul DB. ' + err['message']);
        });
      }
    }
    else {
      window.alert('Profilo non abilitato alla inizializzazione del mese');
    }

    //  }
    //  else {
    //    window.alert('Il mese è chiuso, non è possibile salvare modifiche');
    //  }
  }

  resetMonth() {
    //window.alert('Profilo non abilitato al reset del mese');


    //if (this.isReadOnly == false) {

    if (this.isAdmin == true) {
      var ms = this.fromDate.toLocaleString('default', { month: 'long' });
      if (window.confirm("Sei sicuro di voler resettare il mese di " + ms + "? Cancellerai tutti gli avanzamenti inseriti.")) {
        this.showb = true;
        let py = {
          from: moment(this.fromDate).startOf('month').format('YYYYMMDD'),
          to: moment(this.fromDate).endOf('month').format('YYYYMMDD'),
          desc_domain: null
        };

        this.http.post(`${environment.gceAPI}/refresh_target_all/`, py, { responseType: 'json' }).subscribe(res => {
          //this.http.post(`http://localhost:57526/api/reset_target_all/`, py, { responseType: 'json' }).subscribe(res => {
          this.init(moment(this.fromDate).startOf('month').format('YYYYMMDD'), moment(this.fromDate).endOf('month').format('YYYYMMDD'));
          window.alert('Reset del mese terminato.');
          console.log('response for init_target_all %o', res);
        }, err => {
          console.log('response for  init_target_all %o', err);
          this.showb = false;
          window.alert('Errore nel reset del mese sul DB. ' + err['message']);
        });
      }
    }
    else {
      window.alert('Profilo non abilitato al reset del mese');
    }
    //}
    //else {
    //  window.alert('Il mese è chiuso, non è possibile salvare modifiche');
    // }

  }

  updateValueTgF(e, element) {
    if (this.isViewer == true) {
      window.alert('Il tuo profilo non permette questa funzione.');
    }
    else {
      console.log('update target fatturato Clinica %o %o', e, element);
      let ntot = 0;
      element.persons.forEach(p => {
        ntot = ntot + p['TgFatturatoOperatore'];
      });
    }
    /*
    if (element.persons != null) {
      element['TgFatturato'] = 0;
      element.persons.forEach(p => {
        element['TgFatturato'] = element['TgFatturato'] + p['TgFatturatoOperatore'];
      });
    }
    */
  }

  updateValueTgFM(e, element) {
    if (this.isViewer == true) {
      window.alert('Il tuo profilo non permette questa funzione.');
    }
    else {
      console.log('update target fatturato Persona %o %o', e, element);
      //get old value
      if (e == 0) {
        if (element.persons != null) {
          element.persons.forEach(p => {
            p['TgFatturatoOperatore'] = 0;
          });
        }
      }
      else {
        if (e > 0) {
          var oldValue = 0;
          var howmany = 0;
          if (element.persons != null) {
            element.persons.forEach(p => {
              howmany++;
              if (p['TgFatturatoOperatore'] > 0)
                oldValue = oldValue + p['TgFatturatoOperatore'];
              else
                p['TgFatturatoOperatore'] = 0;
            });
          }

          var delta = Math.round((e - oldValue) / howmany);
          console.log('update target fatturato Master e %o oldValue %o delta %o element %o', e, oldValue, delta, element);
          var howmany2 = 0;
          var total2 = 0;
          if (element.persons != null) {
            element.persons.forEach(p => {
              howmany2++;
              if (howmany == howmany2) {
                p['TgFatturatoOperatore'] = e - total2;
              }
              else {
                p['TgFatturatoOperatore'] = p['TgFatturatoOperatore'] + delta;
                total2 = total2 + p['TgFatturatoOperatore'];
              }
            });
          }
        }
      }
    }
  }

  export() {
    console.log('export %o', this.dataSource.data);
    if (this.isViewer == true) {
      window.alert('Il tuo profilo non permette questa funzione.');
    }
    else {
      let datasMaster: any[] = [];
      let datasSlave: any[] = [];
      this.dataSource.data.forEach(
        e => {
          let tp = e;
          e.persons.forEach(p => {
            datasSlave.push(p);
          });
          e.persons = null;
          datasMaster.push(e);
        });
      this.excelService.exportAsExcelFile2Sections(datasMaster, datasSlave, 'incentive_target_' + this.fromDate.toLocaleString('default', { month: 'long' }));
    }
  }


  import() {
    //open dialog
    console.log('IMPORT UNO');
    if (this.isViewer == true) {
      window.alert('Il tuo profilo non permette questa funzione.');
    }
    else {
      let dialogRef = this.dialog.open(TargetImportComponent, {
        width: "50%",
        height: "auto",
        disableClose: true,
        data: { role: this.role, usr: this.usr }
      });
      dialogRef.afterClosed().subscribe(r => {
        this.init(moment(this.fromDate).startOf('month').format('YYYYMMDD'), moment(this.fromDate).endOf('month').format('YYYYMMDD'));
      });
    }
  }

  import2() {
    //open dialog
    console.log('IMPORT DUE');
    if (this.isViewer == true) {
      window.alert('Il tuo profilo non permette questa funzione.');
    }
    else {
      let dialogRef = this.dialog.open(Target2ImportComponent, {
        width: "50%",
        height: "auto",
        disableClose: true,
        data: { role: this.role, usr: this.usr, fromDate: this.fromDate }
      });
      dialogRef.afterClosed().subscribe(r => {
        this.init(moment(this.fromDate).startOf('month').format('YYYYMMDD'), moment(this.fromDate).endOf('month').format('YYYYMMDD'));
      });
    }
  }

  import3() {
    //open dialog
    console.log('IMPORT TRE');
    if (this.isViewer == true) {
      window.alert('Il tuo profilo non permette questa funzione.');
    }
    else {
      let dialogRef = this.dialog.open(Target3ImportComponent, {
        width: "50%",
        height: "auto",
        disableClose: true,
        data: { role: this.role, usr: this.usr }
      });
      dialogRef.afterClosed().subscribe(r => {
        this.init(moment(this.fromDate).startOf('month').format('YYYYMMDD'), moment(this.fromDate).endOf('month').format('YYYYMMDD'));
      });
    }
  }

  save() {
    //console.log('save mod on o% %o',this.expandedElement, this.expandedElement2);
    //if (this.isReadOnly == false) {
    //check for target distribution
    if (this.isViewer == true) {
      window.alert('Il tuo profilo non permette la modifica dei dati.');
    }
    else {
      let balance;
      var ms = "";
      var c = 0;
      balance = true;
      this.dataSource.data.forEach(
        e => {
          if (e.touched == true) {
            console.log('save mod on o% %o', this.expandedElement, this.expandedElement2);
            ms = ms + " " + e.CodClinica;
            c++;
            let ntot = 0;
            e.persons.forEach(p => {
              ntot = ntot + p['TgFatturatoOperatore'];
            });
            if (ntot > e['TgFatturato'] || ntot < e['TgFatturato'])
              balance = false;
          }
        }
      );
      if (balance == false) {
        window.alert('Attenzione: la somma dei target personali non corrisponde al target di clinica.');
        return
      }
      if (c > 0) {
        if (window.confirm("Sei sicuro di voler confermare i dati relativi alle cliniche" + ms + "?")) {
          this.showb = true;
          this.dataSource.data.forEach(
            e => {
              if (e.touched == true) {
                console.log('save element %o', e);
                //save record in 
                //this.http.post(`http://localhost:57526/api/update_target/`, e, { responseType: 'json' }).subscribe(res => {
                this.http.post(`${environment.gceAPI}/update_target/`, e, { responseType: 'json' }).subscribe(res => {
                  this.showb = false;
                }, err => {
                  console.log('response for  saveTarget %o', err);
                  this.showb = false;
                  window.alert('Errore nel salvataggio dei dati sul DB. ' + err['message']);
                });
              }
            }
          );
        }
      }
      else {
        window.alert('Nessuna modifica da salvare.');
      }
      //}
      //else {
      //  window.alert('Il mese è chiuso, non è possibile salvare modifiche');
      //}
    }
  }

  fromDateChange(e) {
    //console.log('fromDateChange %o %o %o', e,this.fromDate,moment(this.fromDate));
    this.init(moment(this.fromDate).startOf('month').format('YYYYMMDD'), moment(this.fromDate).endOf('month').format('YYYYMMDD'));
  }

  ngOnInit() {
    //the Date from/to are first day of currentMonth and last day of currentMonth
    console.log('TARGET -> DATE TO CHECK %o', this.incentiveService.dataRepoGceEnvironment);

    let usr = this.auth.getUser();
    console.log('USR read %o', usr);
    if (usr != null) {
      this.role = usr['ruolo'];
      this.dm = usr['clinica'];
      if (usr['nomeCompleto'] != null && usr['nomeCompleto'].length > 0) {
        this.usr = usr['nomeCompleto'];
      }
      else {
        if (usr['email'] != null && usr['email'].length > 0) {
          this.usr = usr['email'];
        }
      }
    }

    if (this.role != null) {
      if (this.role.toLowerCase().indexOf('amministratore') == 0 || this.role.toLowerCase().indexOf('hr medici') == 0 || this.role.toLowerCase().indexOf('direzione') == 0) {
        this.isAdmin = true;
        this.isAM = false;
        this.isCM = false;
        this.isDM = false;
        this.isViewer = false;
      }
      else if (this.role.toLowerCase().indexOf('area') == 0 && this.role.toLowerCase().indexOf('manager') > 0) {
        this.isAdmin = false;
        this.isAM = true;
        this.isCM = false;
        this.isDM = false;
        this.isViewer = false;
      }
      else if (this.role.toLowerCase().indexOf('district') == 0 && this.role.toLowerCase().indexOf('manager') > 0) {
        this.isAdmin = false;
        this.isAM = false;
        this.isCM = false;
        this.isDM = true;
        this.isViewer = false;
      }
      else if (this.role.toLowerCase().indexOf('clinic') == 0 && this.role.toLowerCase().indexOf('manager') > 0) {
        this.isAdmin = false;
        this.isAM = false;
        this.isCM = true;
        this.isDM = false;
        this.isViewer = false;
      }
      else {
        this.isAdmin = false;
        this.isAM = false;
        this.isCM = false;
        this.isDM = false;
        this.isViewer = true;
      }
    }
    else {
      this.isAdmin = false;
      this.isAM = false;
      this.isCM = false;
      this.isDM = false;
      this.isViewer = false;
    }
    this.init(moment().startOf('month').format('YYYYMMDD'), moment().endOf('month').format('YYYYMMDD'));
  }

}
