import { primaryKey, headerCode, inputType, required, length, order, pattern, editable, Describer, visible, GceEntity, relation, currency } from './gce-management';
import { GceInvoicePerson } from './gce-invoice-person';
import { GceClinic } from './gce-clinic';

/**
 * GceInvoice -> table: medici_fatturazione_clinica
 */
export class GceInvoicePersonCompensation extends GceEntity {
    @headerCode('GCE.GCEINVOICEPERSONCOMPENSATION.IDFATTURAZIONE')
    @inputType('hidden')
    @order(1)
    idfatturazione: number;

    @headerCode('GCE.GCEINVOICEPERSONCOMPENSATION.COD_CLINICA')
    @inputType('select')
    @length(255)
    //@relation("GceClinic", {columnRel:'code_c', columnDescr:['legalentity', 'nome']})
    @relation("GceClinic", {columnRel:'code_c', columnDescr:['nome']})
    @required()
    @order(2)
    cod_clinica: string;

    @headerCode('GCE.GCEINVOICEPERSONCOMPENSATION.DATA_INZIO')
    @inputType('date')
    @order(3)
    data_inzio: Date | string;

    @headerCode('GCE.GCEINVOICEPERSONCOMPENSATION.DATA_FINE')
    @inputType('date')
    @order(4)
    data_fine: Date | string;

    @headerCode('GCE.GCEINVOICEPERSONCOMPENSATION.GETTONE_GIORNALIERO')
    @inputType('number')
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(5)
    gettone_giornaliero: number;

    @headerCode('GCE.GCEINVOICEPERSONCOMPENSATION.DIREZIONE_SANITARIA')
    @inputType('number')
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(6)
    direzione_sanitaria: number;

    @headerCode('GCE.GCEINVOICEPERSONCOMPENSATION.COMPENSO_FISSO')
    @inputType('number')
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(7)
    compenso_fisso: number;

    @headerCode('GCE.GCEINVOICEPERSONCOMPENSATION.MINIMO_GARANTITO')
    @inputType('number')
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(8)
    minimo_garantito: number;

    @headerCode('GCE.GCEINVOICEPERSONCOMPENSATION.DIREZIONE_AREA_CLINICA')
    @inputType('number')
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(9)
    direzione_area_clinica: number;

    @headerCode('GCE.GCEINVOICEPERSONCOMPENSATION.PERIODO_DS')
    //@inputType('number')
    @inputType('hidden')
    @order(10)
    periodo_ds: number;

    @headerCode('GCE.GCEINVOICEPERSONCOMPENSATION.MESE_DS')
    //@inputType('number')
    @inputType('hidden')
    @order(11)
    mese_ds: number;

    @headerCode('GCE.GCEINVOICEPERSONCOMPENSATION.PERIODO_CF')
    @inputType('number')
    @order(12)
    periodo_cf: number;

    @headerCode('GCE.GCEINVOICEPERSONCOMPENSATION.MESE_CF')
    @inputType('number')
    @order(13)
    mese_cf: number;

    @headerCode('GCE.GCEINVOICEPERSONCOMPENSATION.PERIODO_DAC')
    //@inputType('number')
    @inputType('hidden')
    @order(14)
    periodo_dac: number;

    @headerCode('GCE.GCEINVOICEPERSONCOMPENSATION.MESE_DAC')
    //@inputType('number')
    @inputType('hidden')
    @order(15)
    mese_dac: number;

    @headerCode('GCE.GCEINVOICEPERSONCOMPENSATION.PCT_FINANZIAMENTO')
    @inputType('percentage')
    @pattern('000.00')
    @order(16)
    pct_finanziamento: number;

    @headerCode('GCE.GCEINVOICEPERSON.PAGAM_DIREZIONI_NON_MENSILE')
    @inputType('boolean')
    @editable(true)
    @order(17)
    flg_pagam_direzioni_non_mensile: boolean;

    @headerCode('GCE.GCEINVOICEPERSON.FLAG_MESI_PAG_DIREZ')
    @inputType('mont')
    @editable(true)
    @order(18)
    mesi_pagam_direzioni: string;

    @headerCode('GCE.GCEINVOICEPERSON.PAGAM_DIREZIONI_AREA_NON_MENSILE')
    @inputType('boolean')
    @editable(true)
    @order(17)
    flg_pagam_dir_area_non_mensile: boolean;

    @headerCode('GCE.GCEINVOICEPERSON.FLAG_MESI_PAG_DIR_AREA')
    @inputType('mont2')
    @editable(true)
    @order(18)
    mesi_pagam_dir_area: string;


    
    constructor();
    constructor(idfatturazione: number, cod_clinica: string, data_inzio: Date | string, data_fine: Date | string, gettone_giornaliero: number, direzione_sanitaria: number, compenso_fisso: number, minimo_garantito: number, direzione_area_clinica: number, periodo_ds: number, mese_ds: number, periodo_cf: number, mese_cf: number, periodo_dac: number, mese_dac: number, pct_finanziamento: number , flg_pagam_direzioni_non_mensile :boolean, mesi_pagam_direzioni: string, flg_pagam_dir_area_non_mensile: boolean, mesi_pagam_dir_area: string);
    constructor(idfatturazione?: number, cod_clinica?: string, data_inzio?: Date | string, data_fine?: Date | string, gettone_giornaliero?: number, direzione_sanitaria?: number, compenso_fisso?: number, minimo_garantito?: number, direzione_area_clinica?: number, periodo_ds?: number, mese_ds?: number, periodo_cf?: number, mese_cf?: number, periodo_dac?: number, mese_dac?: number, pct_finanziamento?: number ,
        flg_pagam_direzioni_non_mensile? :boolean, mesi_pagam_direzioni?: string, flg_pagam_dir_area_non_mensile?: boolean, mesi_pagam_dir_area?: string){
        super();
        this.idfatturazione = idfatturazione;
        this.cod_clinica = cod_clinica;
        this.data_inzio = data_inzio;
        this.data_fine = data_fine;
        this.gettone_giornaliero = gettone_giornaliero;
        this.direzione_sanitaria = direzione_sanitaria;
        this.compenso_fisso = compenso_fisso;
        this.minimo_garantito = minimo_garantito;
        this.direzione_area_clinica = direzione_area_clinica;
        this.periodo_ds = periodo_ds;
        this.mese_ds = mese_ds;
        this.periodo_cf = periodo_cf;
        this.mese_cf = mese_cf;
        this.periodo_dac = periodo_dac;
        this.mese_dac = mese_dac;
        this.pct_finanziamento = pct_finanziamento;
        this.flg_pagam_direzioni_non_mensile = flg_pagam_direzioni_non_mensile;
        this.mesi_pagam_direzioni = mesi_pagam_direzioni;
        this.flg_pagam_dir_area_non_mensile=flg_pagam_dir_area_non_mensile;
        this.mesi_pagam_dir_area=mesi_pagam_dir_area;
    }

    static getStructure():Array<any>{
        let structure:any[] = Describer.getStructure(new GceInvoicePersonCompensation());
        structure.push(...this.getActions());
        return structure;
    }
    protected static getActions():any[]{
        let actions:any[] = [];
        return actions;
    }

    static parse(data:any):GceInvoicePersonCompensation{
        let gipc = new GceInvoicePersonCompensation(
            data['IDFATTURAZIONE'],
            data['COD_CLINICA'],
            data['DATA_INZIO'],
            data['DATA_FINE'],
            data['GETTONE_GIORNALIERO'],
            data['DIREZIONE_SANITARIA'],
            data['COMPENSO_FISSO'],
            data['MINIMO_GARANTITO'],
            data['DIREZIONE_AREA_CLINICA'],
            data['PERIODO_DS'],
            data['MESE_DS'],
            data['PERIODO_CF'],
            data['MESE_CF'],
            data['PERIODO_DAC'],
            data['MESE_DAC'],
            data['PCT_FINANZIAMENTO'],
            data['FLG_PAGAM_DIREZIONI_NON_MENSILE'],
            data['MESI_PAGAM_DIREZIONI'],
            data['FLG_PAGAM_DIR_AREA_NON_MENSILE'],
            data['MESI_PAGAM_DIR_AREA']
        );
        return gipc;
    }
    
}
