import { Injectable, EventEmitter} from '@angular/core';
import { Observable, Subject } from 'rxjs/Rx';
import { WebsocketConcessionService } from './ws.service';
import { CartService } from '../../services/cart.service';

const WS_URL = 'ws://127.0.0.1:12017/devicemanager';

@Injectable()
export class PosConcessionService {
    public messages: Subject<any>;
    public addToPrintQueue: Subject<any>;
    paymentSuccess:EventEmitter<any> = new EventEmitter<any>();

	constructor(
        wsService1: WebsocketConcessionService
    ) {
		this.messages = <Subject<any>>wsService1
			.connect(WS_URL, 1)
			.map((response: MessageEvent) => {
                let data = JSON.parse(response.data);
                console.log(response);
				return data;
            });

        this.messages.subscribe((msg) => {
            let err = 0; let errDef = false;
            if (msg.event == 'Pay') {
                this.paymentSuccess.emit(msg);
            }
        });
    }

    guid () {
        function s4() {
          return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    payWithCreditCard (amount) {
        amount = parseInt("" + (amount * 100));
        let payload = {
            "event":"Pay",
            "data": {
               "Id": this.getId(),
               "ClientId": "STELLAR2",
               "Status": {
                  "addInfo": {
                     "Amount": amount
                  }
               }
            }
         };
        this.messages.next(payload);
    }
    
    getId (): string {
        var text = "";
        var possible = "abcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < 6; i++)
          text += possible.charAt(Math.floor(Math.random() * possible.length));
      
        return text;
    }

    printFiscalReceipt(parFiscalReceipts, parPrinterId, parLayoutId) {
        var data = { event: "Print", data: { Id: this.getId(), Printer: { Id: parPrinterId }, Layout: { Id: parLayoutId }, DocumentType: 5, FiscalReceipt: parFiscalReceipts }};
        this.addToPrintQueue.next(data);
    }

    getPaymentSuccess() {
        return this.paymentSuccess;
    }
}