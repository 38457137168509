import { Component, OnInit, NgModule, Injectable, Input, Inject, Renderer2, ElementRef } from '@angular/core';
import { 
  MatButtonModule,
  MatCardModule,
  MatMenuModule,
  MatToolbarModule,
  MatIconModule,
  MatInputModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatExpansionModule,
  MatSelectModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatChipsModule,
  MatListModule,
  MatSidenavModule,
  MatTabsModule,
  MatProgressBarModule
} from '@angular/material';
import { TastieraArticoli } from '../global/model/articoli/articoli.model';
import { Basket, BasketItem } from '../global/model/basket/basket.model';
import { BasketService } from '../global/services/basket.service'
import { fadeOutAnimation } from '../global/animations/fade-outanimation'

import * as jquery from 'jquery';

declare var $: any;
declare var $$: any;

@Injectable()
@Component({
  selector: 'cash-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.css'],
  animations: [ fadeOutAnimation ]
})
export class BasketComponent implements OnInit {

  constructor(public _basketService: BasketService) {
    
  }

  ngOnInit() {
    $(function () {
      // Start resize management
      $.resizeDiv = function () {
        $('#basket').height(function(index, height) {
          //return window.innerHeight - 94 - $(this).offset().top; 
          //return window.innerHeight - 80 - $(this).offset().top; 
          return window.innerHeight - 155 - $(this).offset().top + 88; 
        });
        $('cash-register').height(function(index, height) { 
          //return window.innerHeight - 35 - $(this).offset().top; 
          return window.innerHeight - 35 - 75 - $(this).offset().top; 
        });
      }
      $.resizeDiv();
      $(window).resize(function(){
        $.resizeDiv();
      });
      // End resize
    });
  }

  viewTransDetails() {
    this._basketService.showTDetails = true;
    this._basketService.prePayment = true;
  }

  toggleSelected (si: BasketItem) {
    if (si.Item == this._basketService.selectedItem && si.Id == this._basketService.selectedBasketItem.Id) {
      this._basketService.selectedItem = undefined;
      this._basketService.updateQuantity = 0;
      this._basketService.txtQuantity = 0;
      this._basketService.overwrite = false;
    }
    else {
      this._basketService.overwrite = true;
      this._basketService.selectMyItem(si);
    }
  }

  deleteItem (item: any): void {
    this._basketService.removeFromBasket(item);
  }
}

@NgModule({
  imports: [
    MatButtonModule,
    MatCardModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatExpansionModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatChipsModule,
    MatListModule,
    MatSidenavModule,
    MatTabsModule,
    MatProgressBarModule
  ],
  exports: [
    
  ],
  declarations: [
    
  ],
  providers: [ 
    BasketService
  ],
})
export class BasketModule { }