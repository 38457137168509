import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { LoaderService } from '../loader';
import * as MyGlobals from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NotificationsService } from 'angular2-notifications';
import { AuthGuardService } from './auth-guard.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()

export class MessageMngtService {
  backup;
  messaggesRows;

  constructor(
    public _http: HttpClient,
    public _auth: AuthGuardService
  ) {
    this.getAllMessages().subscribe((res) => {
      this.messaggesRows = res;
    });
  }

  activeMessage(id: any) {
    for (let a = 0; a < this.messaggesRows.length; a = a + 1) {
      if (this.messaggesRows[a].ID_MESSAGE == id)
        this.messaggesRows[a].ISACTIVE = 1;
    }
  }

  disactiveMessage(id: any) {
    for (let a = 0; a < this.messaggesRows.length; a = a + 1) {
      if (this.messaggesRows[a].ID_MESSAGE == id)
        this.messaggesRows[a].ISACTIVE = 0;
    }
  }

  getAllMessages() {
    return this._http.get(
      MyGlobals.environment['GetAllMessages']
    ).map((res) => {
      return (res);
    });

  }

}