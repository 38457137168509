import { Component, NgModule, ViewContainerRef } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { MatDialogModule, MatButtonModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { TranslateModule, TranslateCompiler, TranslateService } from '@ngx-translate/core';
import * as mySettings from '../../global/settings';
import * as Us from '../../global/model/user-preferences/user-preferences.model';

@Component({
  selector: 'languageDialog',
  templateUrl: './language.dialog.html',
  styleUrls: ['./language.dialog.scss'],
})
export class LanguageDialog {
  selected;
  mainLanguage;
  mainLanguageName;
  _translate;
  user;
  
  constructor(
    public dialogRef2: MatDialogRef<any>
  ) {
      this.mainLanguage = localStorage.getItem('locale');
      this.selected = undefined;
      this.mainLanguageName = this.getName();
  }

  getName(name = this.mainLanguage): string {
    // if (this.user == undefined )
    for (let l of mySettings.getLanguages())
        if (l.locale == name)
            return l.name;
  }

  closePopup(): void {
    this.dialogRef2.close();
  }

  getLanguages(available: boolean = undefined) {
    let x = [];
    if (available == undefined) return mySettings.getLanguages();
    for (let l of mySettings.getLanguages())
        if (l.available == available)
            x.push(l);
    
    return x;
  }

  selectLanguage(lang) {
    if (!lang.available) return;
    if (this.selected == undefined || this.selected != lang.locale)
        this.selected = lang.locale;
    else if (this.selected == lang.locale)
        this.selected = undefined;
    this.mainLanguageName = this.getName();
  }

  confirmLanguage() {
    let users:Us.User[];
    users = JSON.parse(localStorage.getItem('users'));
    for (let u of users)
      if (u.operatorId == this.user) {
        u.mainLanguage = this.selected;
        localStorage.setItem('users', JSON.stringify(users));
        break;
      }
    localStorage.setItem('locale', this.selected);
    this._translate.use(this.selected);
    this.mainLanguage = this.selected;
    this.closePopup();
  }
}