export class AddArticoloResponse {
    esitone: boolean;
    idnegozio: number;
    listaArticoliChecked?: AddArticoloResponseArticolo[];
    esito: boolean;
    errorMessage: string;
    errorCode: string;
    executionTime: string;
}

export class EmettiScontrinoResponse extends AddArticoloResponse {
    idDocumento;
    trackid;
    email_cliente;
    idordine;
}

export class AddArticoloResponseArticolo {
    idarticolo: number;
    articolo: string;
    qtaRichiesta: number;
    qtaDisponibile: number;
    isDisponibile: boolean;
    scarico: string;
}

export class listaArticoliScontrino {
    idarticolo;
    codice_articolo;
    articolo;
    idudm;
    scala_udm;
    qta;
    idprezzo;
    percSconto;
    variantiArticolo: VarianteArticolo[];
} 

export class VarianteArticolo {
    idarticolo;
    codice_articolo;
    articolo;
    idudm;
    scala_udm;
    qta;
    idprezzo;
    percSconto;
}