import { primaryKey, headerCode, inputType, required, length, order, pattern, editable, Describer, visible, GceEntity, relation } from './gce-management';
import { GceInvoicePerson } from './gce-invoice-person';
import { GceClinic } from './gce-clinic';

/**
 * GceInvoice -> table: medici_fatturazione_clinica
 */
export class GceInvoicePersonClinic extends GceEntity {
    @headerCode('GCE.GCEINVOICEPERSONCLINIC.IDFATTURAZIONE')
    @inputType('hidden')
    @order(1)
    idfatturazione: number;

    @headerCode('GCE.GCEINVOICEPERSONCLINIC.COD_CLINICA')
    @inputType('select')
    @length(255)
    //@relation("GceClinic", {columnRel:'code_c', columnDescr:['legalentity', 'nome']})
    @relation("GceClinic", {columnRel:'code_c', columnDescr:['nome']})
    @order(2)
    cod_clinica: string;

    @headerCode('GCE.GCEINVOICEPERSONCLINIC.DATA_INIZIO')
    @inputType('date')
    @order(3)
    data_inizio: Date | string;

    @headerCode('GCE.GCEINVOICEPERSONCLINIC.DATA_FINE')
    @inputType('date')
    @order(4)
    data_fine: Date | string;

    @headerCode('GCE.GCEINVOICEPERSONCLINIC.DATA_INZIO_GESTIONE')
    @inputType('date')
    @order(5)
    data_inzio_gestione: Date | string;

    @headerCode('GCE.GCEINVOICEPERSONCLINIC.DATA_FINE_GESTIONE')
    @inputType('date')
    @order(6)
    data_fine_gestione: Date | string;

    @headerCode('GCE.GCEINVOICEPERSONCLINIC.FLG_COMPILAZIONE_PRESENZE')
    @inputType('boolean')
    @order(15)
    flg_compilazione_presenze: boolean;

    constructor();
    constructor(idfatturazione: number, cod_clinica: string, data_inizio: Date | string, data_fine: Date | string, data_inzio_gestione: Date | string, data_fine_gestione: Date | string, flg_compilazione_presenze: boolean);
    constructor(idfatturazione?: number, cod_clinica?: string, data_inizio?: Date | string, data_fine?: Date | string, data_inzio_gestione?: Date | string, data_fine_gestione?: Date | string, flg_compilazione_presenze?: boolean){
        super();
        this.idfatturazione = idfatturazione;
        this.cod_clinica = cod_clinica;
        this.data_inizio = data_inizio;
        this.data_fine = data_fine;
        this.data_inzio_gestione = data_inzio_gestione;
        this.data_fine_gestione = data_fine_gestione;
        this.flg_compilazione_presenze = flg_compilazione_presenze;
    }

    static getStructure():Array<any>{
        let structure:any[] = Describer.getStructure(new GceInvoicePersonClinic());
        structure.push(...this.getActions());
        return structure;
    }

    protected static getActions():any[]{
        let actions:any[] = [];
        return actions;
    }
}
