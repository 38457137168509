import { primaryKey, editableOnNew, headerCode, inputType, required, length, order, pattern, editable, Describer, visible, GceEntity, textAlign } from './gce-management';

/**
 * GceFiscalReg
 */
export class GceProfiles extends GceEntity {
    @primaryKey()
    @headerCode('GCE.GCEPROFILES.ROWID')
    @inputType('number')
    @editable(false)
    @editableOnNew(false)
    @order(1)
    rowid: number;

    @headerCode('GCE.GCEPROFILES.DESCRIZIONE')
    @inputType('text')
    @required()
    @editable(true)
    @length(255)
    @order(2)
    descrizione: string;


    constructor();
    constructor(rowid: number, descrizione: string);
    constructor(rowid?: number, descrizione?: string){
        super();
        this.rowid = rowid || 0;
        this.descrizione = descrizione || '';
    }

    static getStructure(): Array<any> {
        let structure: any[] = Describer.getStructure(new GceProfiles());
        structure.push(...this.getActions());
        return structure;
    }
    protected static getActions(): any[] {
        let actions: any[] = [];
        return actions;
    }
}