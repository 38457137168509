import { Injectable } from '@angular/core';
import { ApiAction, ApiProviderWinticplusService } from './api-provider-winticplus.service';
import { environment } from '../../environments/environment';
import { DataRepositoryService } from './data-repository.service';
import { Observable } from 'rxjs/Rx';
import { GmaStockLeaf } from '../entities/gma-management/gma-stock-leaf';
import { GmaStockCategoria } from '../entities/gma-management/gma-stock-categoria';
import { Observer, merge } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { AffittuariMailMensile } from '../entities/im-aff/affittuari-mail-mensile';
import { FlatTreeControl } from '@angular/cdk/tree';
import { CollectionViewer, SelectionChange } from '@angular/cdk/collections';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';


const actionGetAllDatas = `${environment.GetMailAffittuariMese}`;


@Injectable({
  providedIn: 'root'
})
export class ImaffManagementService {
  dataRepos: AffittuariMailMensile[];

  constructor(private apiService: ApiProviderWinticplusService, private dataRepo: DataRepositoryService, private _http: HttpClient) { }


  getAll(): Observable<any> {

    return Observable.create((observer: Observer<any>) => {
      let req = {

      };
      this._http.get(actionGetAllDatas).subscribe((data: any) => {
        console.log("ImaffManagementService.getAll ret %o", data);
        observer.next(data);
      }, err => {
        console.log("ImaffManagementService.getAll Error %o", err);
        observer.error(err);
      });
    });
  }

}