import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lengthColumns'
})
export class LengthColumnsPipe implements PipeTransform {

  transform(columns: any[], length: number=0): any[] {
    let newColumns:any[]=[];
    if(length>0){
      newColumns = columns.slice(0, length);
    }else{
      newColumns = columns;
    }
    return newColumns;
  }

}
