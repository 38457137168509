import { Component, OnInit, NgModule, ViewContainerRef } from '@angular/core';
import * as myGlobals from './global/settings';
import { HttpClient } from '@angular/common/http';
import { TastieraArticoli } from './global/model/articoli/articoli.model';
import { Basket } from './global/model/basket/basket.model';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { enterInOutAnimation } from './global/animations/enter.animation'
import { BasketService } from './global/services/basket.service'
import { WebsocketService } from './global/services/ws.service'
import { PosService } from './global/services/pos.service'
import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Variant2Dialog } from './global/dialogs/variants2.dialog'
import { LayoutDialog } from './global/dialogs/layout.dialog'
import { SettingsDialog } from './global/dialogs/settings.dialog'
import { YesNoService } from './global/dialogs/yesno.service';
import { TranslateService } from '@ngx-translate/core';
import { PauseService } from './global/services/pause.service';
import { PaymentMethod } from './global/model/payments/payment-method';
import { Payments } from './global/model/payments/payments';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import * as io from 'socket.io-client';
import * as Us from './global/model/user-preferences/user-preferences.model';
declare var $: any;

import { CurrencyPipe } from '@angular/common';
import { AuthGuardService } from '../services';
import { PreferencesService } from '../services/preferences.service';
import { LoaderService } from '../loader';

@Component({
  selector: 'app-concession',
  templateUrl: './concession.component.html',
  styleUrls: ['./concession.component.scss'],
  providers: [
    LoaderService,
    WebsocketService,
    PosService
  ],
  animations: [
    enterInOutAnimation
  ]
})
export class ConcessionComponent implements OnInit {
  ngOnInit() {
    /*this.subscription = this.payService.getPaymentSuccess().subscribe(res => {
      if (res) {
        this.completePayment();
      }
    }); RIPRISTINAAAAAA */
  }

  subscription:any;

  tastiere: TastieraArticoli.Nodi [];
  ta: any | TastieraArticoli.RootObject;
  icons;
  iconsVers;
  coeff: number;
  margin: number;
  screenNodes: boolean;
  tastiere_sel: TastieraArticoli.Nodi [];
  screenNodesName: string;
  nomeTastiera: string;
  basket: any;
  _subscription: any;
  dialogRef: MatDialogRef<any>;
  dialogRef2: MatDialogRef<any>;
  dialogRef3: MatDialogRef<any>;
  variantTest;
  selectedTheme: number;
  result: any;
  inPause;
  search: boolean;
  searchOption: number;
  searchTerm:string = "";
  searchForm: FormGroup = new FormGroup({
    searchf: new FormControl({ value: this.searchTerm })
  });
  showSearchTab: boolean = false;
  obj: any[];
  searchLabel: any;
  buttgroupsPP;
  buttgroups;
  txtQuantity: string;
  txtQuantityNumber: number;
  firstTxt: boolean = true;
  txtToPay: number;
  showResto: boolean = false;
  txtResto: number;
  enabledFloat: boolean = false;
  paymentMethods: PaymentMethod[];
  socket;

  constructor (
    private http: HttpClient,
    public _loaderService: LoaderService,
    private localStorageService: PreferencesService,
    public _basketService: BasketService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private dialogsService: YesNoService,
    public viewContainerRef: ViewContainerRef,
    public _translate: TranslateService,
    public _pauseService: PauseService,
    private payService: PosService,
    public authGuardService: AuthGuardService
  ) {
    this.ta = undefined;
    this.margin = 0;
    this.coeff = 1;
    this.tastiere = [];
    this.screenNodes = false;
    this.tastiere_sel = [];
    this.screenNodesName = "";
    this.iconsVers = "v3";
    this.nomeTastiera = "";
    this.initializeVariantsObject();
    this.inPause = _pauseService.isInPause();
    this.icons = [
      { src: "arrow_up", name: "ecr.ecr-home.1200", disabled: true, visible: true, action: "resetKeyboard" },
      { src: "storni_resi", name: "ecr.ecr-home.1201", disabled: false, visible: true, action: "blank" },
      { src: "annulla", name: "ecr.ecr-home.1202", disabled: this._basketService.basket.Items.length == 0, visible: true, action: "openPopupConfirm" },
      { src: "pausa", name: "ecr.ecr-home.1203", disabled: false, visible: true, action: "callPause" },
      { src: "servizi", name: "ecr.ecr-home.1204", disabled: false, visible: true, action: "blank" },
      { src: "icn_settings", name: "ecr.ecr-home.1227", disabled: false, visible: true, action: "openSettingsPopup" },
      { src: "cassetto", name: "ecr.ecr-home.1205", disabled: false, visible: true, action: "blank" },
      { src: "iconizza", name: "ecr.ecr-home.1206", visible: false, action: "blank" },
      { src: "cerca", name: "ecr.ecr-home.1207", disabled: false, visible: true, action: "openSearch" },
      { src: "carte", name: "ecr.ecr-home.1208", visible: true, action: "blank" },
      { src: "customize", name: "ecr.ecr-home.1209", visible: true, action: "openPopupLayout" },
      { src: "esci", name: "ecr.ecr-home.1210", disabled: false, visible: true, action: "openPopupConfirmLogout" },
    ];
    this.search = false;
    this.searchOption = 1;
    this.buttgroups = myGlobals.keyboardButtons;
    this.buttgroupsPP = myGlobals.keyboardButtons;

    this.buttgroups[2].buttons[3].disabled = false;
    this.buttgroups[3].buttons[3].disabled = false;
    this.buttgroupsPP[2].buttons[3].disabled = true;
    this.buttgroupsPP[3].buttons[3].disabled = true;
    this.buttgroupsPP[4].buttons.splice(1, 0, { 'value': '00', 'disabled': false, 'action': false });
    this.buttgroupsPP[4].buttons[1].disabled = false;
    this.buttgroupsPP[4].buttons[2].disabled = false;

    this.getMessages();

    if (!this.authGuardService.isAuthenticated()) {
      this._loaderService.hide();
      //this.prepareForLogout();
    }
    else {
      let users: Us.User[];
      if (this.localStorageService.get<Us.User[]>("users") != null) {
        users = this.localStorageService.get<Us.User[]>("users");
        for (let u of users) {
          if (u.operatorId == this.authGuardService.getUser()['guid']) {
            this.selectedTheme = (u.layout == undefined) ? 2 : +u.layout;
            if (u.pause != null && u.pause.finishedAt == null) {
              u.pause.finishedAt = new Date().getTime();
              break;
            }
          }
        }
      }

      if (this.localStorageService.get<PaymentMethod[]>("paymentMethods") == null || this.localStorageService.get<PaymentMethod[]>("paymentMethods").length == 0 || this.localStorageService.get<TastieraArticoli.RootObject>("ta") == null || this.localStorageService.get<TastieraArticoli.Nodi []>("tastiere") == null) {
        this._loaderService.show();
        this.getEnvironment();
        this.getTastiera();
        this.getPMs();
      }
      else {
        this._loaderService.hide();
        this.ta = this.localStorageService.get<TastieraArticoli.RootObject>("ta");
        this.tastiere = this.localStorageService.get<TastieraArticoli.Nodi[]>("tastiere");
        this.paymentMethods = this.localStorageService.get<PaymentMethod[]>("paymentMethods");
      }

      if (users == undefined) return;
      for (let u of users) {
        if (u.operatorId == this.authGuardService.getUser()['guid']) {
          if (u.pause != null && u.pause.whereAmI != 'home') {
            for (let ta of this.tastiere) {
              console.log(ta.nodo+" == "+u.pause.whereAmI);
              if (ta.nodo == u.pause.whereAmI) {
                this.performClickAction(ta);
                break;
              }
            }
            if (u.pause.basket != null && u.pause.basket.Items.length > 0) {
              this._basketService.basket = u.pause.basket;
              this._basketService.selectMyItem(this._basketService.basket.Items[0]);
              u.pause = null;
              // FIX ERROR LOCALSTORAGE
              localStorage.setItem('users', JSON.stringify(users));
            }
          }
        }
      }

      this._translate.get('ecr.ecr-home.1217').subscribe((res: string) => {
        this.searchLabel = {
          title: res,
          header: ""
        };
      });
    }

    $(function () {
      $.h = $(window).height();

      $('#h-cleft').resize(function(){
        var w = $(window).width() - $('#h-cleft').width() - 89;
        if (w < 250) w = 250;
        console.log("resizing... from " + $('#h-cright').width() + " to " + w);
        $("#h-cright").width(w);

        $.h = $(window).height();
      });

      // test
      $(window).resize(function () {
        if (document.getElementById('h-ckeyboard') != null)
          $('#h-ckeyboard').css('height', 'calc(' + document.getElementById('h-cright').offsetHeight + 'px - 136px)');
      });

    });
  }

  blank () { }

  pressButton (val: string) {
    if (isNaN(val as any)) {
      if (this.firstTxt) return;
      if (val == 'C' || val == 'AC') {
        this.txtQuantity = this._basketService.payments.ToPay + "";
        this.txtQuantityNumber = +this.txtQuantity;
        this.enabledFloat = false;
        this.buttgroupsPP[4].buttons[2].disabled = false;
        this.txtResto = 0;
        this.showResto = false;
        this.firstTxt = true;
        return;
      }
      if (val == '←') {
        if (this.txtQuantity.length == 1) return this.pressButton('C');
        this.txtQuantity = this.txtQuantity.substring(0, this.txtQuantity.length - 1);
        this.txtQuantityNumber = +this.txtQuantity;
      }
      if (val == '.') {
        this.enabledFloat = true;
        this.buttgroupsPP[4].buttons[2].disabled = true;
      }
    } else {
      let num = +val;
      if (this.firstTxt) {
        this.txtQuantity = val;
        this.txtQuantityNumber = num;
        this.firstTxt = false;
        return;
      }

      if (this.enabledFloat) {
        if (this.txtQuantity.indexOf('.') == -1)
          this.txtQuantity = this.txtQuantity + "." + val;
        else {
          var pos = this.txtQuantity.indexOf ('.');
          var str = this.txtQuantity.substr (pos + 1, 2);
          if (str.length == 2) {
            this.enabledFloat = false;
            this.buttgroupsPP[4].buttons[2].disabled = false;
            this.txtQuantity = val;
          }
          else
            this.txtQuantity = this.txtQuantity + val;
        }
      }
      else
        this.txtQuantity += val;
      console.log(this.txtQuantity + " vs " + this.txtQuantityNumber);
      this.txtQuantityNumber = +this.txtQuantity;
    }

    let x: number = this._basketService.payments.ToPay - this.txtQuantityNumber;
    this.txtToPay = x < 0 ? 0 : x;
    this.showResto = (x < 0);
     (x < 0) ? Math.abs(x) : 0;
    let lastPP: PaymentMethod = this._basketService.prePaymentCards;
    if (lastPP.KeyboardEnabled && x < 0)
      this._basketService.lastCharge = Math.abs(x);
    else
      this._basketService.lastCharge = 0;
    this.txtResto = Math.abs(x);

    this.firstTxt = false;
  }

  convertNumber (num) {
    let value = new CurrencyPipe(navigator.language).transform(num, 'EUR', true, '1.2-2');
    let firstDigit = value.match(/\d/);
    let symbol = value.slice(0, firstDigit.index);
    let amount = value.slice(firstDigit.index);
    return symbol + " " + amount;
  }

  selectPaymentMethod(pm: PaymentMethod) {
    this.firstTxt = true;
    this.showResto = false;
    this.txtQuantity = "" + this._basketService.payments.ToPay;
    this.txtQuantityNumber = this._basketService.payments.ToPay;
    this.txtToPay = +this.txtQuantity;
    this._basketService.prePaymentCards = pm;
    this._basketService.payments.Completed = undefined;

    let pmKeyboardEnabled = ["CARTA DI CREDITO", "CARTA DI DEBITO", "BANCOMAT", "CREDIT_CARD"]
    if (pmKeyboardEnabled.indexOf(pm.PaymentMethod) !== -1) {
      this.payWithCC(this.txtToPay * 100);
    }
  }

  payWithCC(amount: number) {
    this.payService.payWithCreditCard(amount);
  }

  retryPayment() {
    this._basketService.payments.Completed = undefined;
    this.payService.payWithCreditCard(this.txtToPay * 100);
  }

  getMessages() {
    let observable = new Observable(observer => {
      this.socket = io("ws://127.0.0.1:12017/pointsofsale");
      this.socket.on('Pay', (data) => {
        observer.next(data);
      });

      this.payService.messages.subscribe(msg => {
        //this.messages.push(msg.toString());
        console.log("received.... %o", msg)
      });

      return () => {
        console.log("returned smthg");
        this.socket.disconnect();
      };
    })
    return observable;
  }

  completePayment () {
    console.log("You paid: " + this.txtQuantityNumber + "/" + this._basketService.payments.ToPay + ".");
    let x: number = this.txtQuantityNumber;
    if (x > this._basketService.payments.ToPay) x = this._basketService.payments.ToPay;
    console.log(" > To pay: " + (this._basketService.payments.ToPay - this.txtQuantityNumber));
    this._basketService.addToPayments(x);
    this._basketService.prePaymentCards = undefined;

    this.checkPayment();
  }

  checkPayment() {
    if (this._basketService.payments.ToPay == 0) {
      this._basketService.lastTotal = this._basketService.payments.Total;
      let copy: Payments = $.extend(true, [], this._basketService.payments);
      let copyb: Basket = $.extend(true, [], this._basketService.basket);
      this.cancelPrePayment();
      this._basketService.emptyCart();
      this.resetKeyboard();
      localStorage.setItem('sessionNumber', this._basketService.generateSessionNumber());
      let conf;
      this._translate.get(this._basketService.Pay(copy, copyb) ? 'ecr.ecr-pp.2012' : 'ecr.ecr-pp.2014').subscribe((res: string) => { conf = res; });
      this.openSnackBar(conf);
      // Print the receipt
      this.printReceipt(copy, copyb);
    }
  }

  printReceipt(copy, copyb) {
    let FiscalReceipts = {
      DocumentType: 5,
      Print: "Stampa questa stringa prima dell'header",
      Display: "Visualizza sul display questa stringa prima dell'header",
      printRecItems: [],
      printRecSubtotal: true,
      printRecTotals: []
    };
    for (let i of copyb.Items)
      FiscalReceipts.printRecItems.push(
        {
          Description: i.Item.articolo,
          unitPrice: i.UnitPrice,
          Quantity: i.Quantity,
          Department: 1
        }
      );
    for (let p of copy.Payments)
      FiscalReceipts.printRecTotals.push(
        {
          Description: p.PaymentMethod.PaymentMethod,
          Payment: p.Total,
          PaymentType: p.PaymentMethod.IdPM,
          Index: 0
        }
      );
    this.payService.printFiscalReceipt(FiscalReceipts, '14f5b48d-3e88-402f-9a17-6a6e7be8850c', 'a60e8a01-e795-4a26-a90d-7eeba1eda59a');
  }

  getSelectedPaymentMethodImage () {
    if (this._basketService.prePaymentCards == undefined) return this.paymentMethods[2].Image;
    return this._basketService.prePaymentCards.Image;
  }

  openSearch() {
    if (this.search)
      this.searchForm.controls["searchf"].setValue('');
    this.search = !this.search;

    if (this.search)
      document.getElementById('h-ckeyboard').style.height = (document.getElementById('h-cright').clientHeight - 71 - document.getElementById('h-cicons').clientHeight - 50) + "px";
    else
      document.getElementById('h-ckeyboard').style.height = (document.getElementById('h-cright').clientHeight - document.getElementById('h-cicons').clientHeight - 40) + "px";
  }

  checkIfClickable(): void {
    if (this.nomeTastiera != '') this.searchOption = 2;
    return;
  }

  searchProd(prod: string = undefined) {
    if (prod == undefined) prod = this.searchForm.controls["searchf"].value;
    console.log(this.ta);
    this.showSearchTab = prod.trim().length >= 1;
    if (prod.trim().length < 1 && this.searchOption != 3) return;
    console.log("you typed: " + prod);
    let re = new RegExp(prod.toUpperCase(), 'gi');
    this.obj = [];
    if (this.searchOption == 1) {
      // globally search
      for (let x of this.ta.TastieraArticoli.Nodi) { // catergories
        if (x.articolo != null && x.articolo.varianti.length > 0) {
          if (x.articolo.articolo.toUpperCase().match(re) !== null)
          this.obj.push(x);
        }
        else
          for (let node of x.Nodi) { // nodes x category
            if (node.articolo.articolo.toUpperCase().match(re) !== null)
            this.obj.push(node);
          }
      }
    } else if (this.searchOption == 3) {
      // variants search
      for (let x of this.ta.TastieraArticoli.Nodi)
        if (x.articolo != null && x.articolo.varianti.length > 0 && x.articolo.articolo.toUpperCase().match(re) !== null)
        this.obj.push(x);
        else
          for (let node of x.Nodi)
            if (node.articolo != undefined && node.articolo.articolo != undefined && node.articolo.articolo.varianti != undefined && node.articolo.articolo.varianti.length > 0)
              if (node.articolo.articolo.toUpperCase().match(re) !== null)
                this.obj.push(node);
    }

    let rl; this._translate.get(this.obj.length == 1 ? 'ecr.ecr-home.1225' : 'ecr.ecr-home.1226').subscribe((res: string) => (rl = res));
    this._translate.get('ecr.ecr-home.1218', { prod: prod, numris: this.obj.length, resultlabel: rl }).subscribe((res: string) => (this.searchLabel.header = res));

    //console.log(this.obj);
  }

  XXX2() {

  }

  callPause() {
    let conf; this._translate.get('ecr.ecr-home.1212').subscribe((res: string) => { conf = res; });
    let text; this._translate.get('ecr.ecr-h.1006').subscribe((res: string) => { text = res; });

    this.dialogsService
      .confirm(conf, text, false)
      .subscribe(res => {
        if (res != undefined && res) {
          this.navigateToPause();
        }
      });
  }

  navigateToPause() {
    let users:Us.User[] = this.localStorageService.get<Us.User[]>("users");
    for (let u of users)
      if (u.operatorId == this.authGuardService.getUser()['guid']) {
        console.log(u.operatorId + " was on pause.");
        u.pause = new Us.Pause();
        u.pause.startedAt = new Date().getTime();
        u.pause.whereAmI = this.nomeTastiera != '' ? this.nomeTastiera : 'home';
        u.pause.basket = this._basketService.basket;
        localStorage.setItem('users', JSON.stringify(users));
        localStorage.setItem('sessionNumber', this._basketService.sessionNumber);
        this.authGuardService.doLogoff();
      }
  }

  emptyCart() {
    this._basketService.prePaymentMessage = false;
    this._basketService.emptyCart();
    let stringa;
    this._translate.get('ecr.ecr-home.1211').subscribe((res: string) => { stringa = res.toString(); });
    this.openSnackBar(stringa);
    this.checkDisabledButtons();
  }

  initializeVariantsObject (): void {
    this.variantTest = {
      defined: false,
      idArticle: 0,
      variantTitle: "",
      minVariant: 0,
      maxVariant: 0,
      categories: []
    };
  }

  performClickAction(tastiera: TastieraArticoli.TastieraArticoli): void {
    if (tastiera.Nodi.length > 0)
      this.openKeyboard(tastiera.idnodo);
    else if (tastiera.articolo.varianti != null && tastiera.articolo.varianti.length > 0) {
      if (!this.variantTest.defined || this.variantTest.idArticle != tastiera.idarticolo) {
        if (this.variantTest.idArticle != tastiera.idarticolo)
          this.initializeVariantsObject();
        this.buildVariantsKeyboard(tastiera);
      } else
        this.openPopup();
    }
    else {
      // WSWS
      if (this._basketService.addOrRemoveItemCartWS(tastiera.articolo, 1)) {
        this.addToBasket(tastiera.articolo);
        this._basketService.payments.Total = this._basketService.basket.Total;
        this._basketService.payments.ToPay = this._basketService.basket.Total;
      }
    }

    this.checkDisabledButtons();
  }

  checkDisabledButtons() {
    this.icons[2].disabled = (this._basketService.basket.Items.length == 0);
  }

  buildVariantsKeyboard (tastiera: TastieraArticoli.TastieraArticoli) {
    let ida: number = 0, idn: number = 0;
    this.variantTest.variantTitle = tastiera.nodo;
    this.variantTest.maxVariant = tastiera.articolo.maxVarianti;
    this.variantTest.minVariant = tastiera.articolo.minVarianti;
    this.variantTest.idMenuArticle = tastiera.idarticolo;
    this.variantTest.VariantItem = tastiera;
    for (let variant of tastiera.articolo.varianti) {
      let index: number;
      let indexCat: number = 0;
      for (let tas of this.ta.TastieraArticoli.Nodi) {
        index = -1;
        if (tas.nodo == variant.categoria) {
          for (let ite of tas.Nodi) {
            ++index;
            if (ite.idarticolo == variant.idarticolo) {
              idn = ite.idnodo;
              ida = variant.idarticolo;
              break;
            }
          }

          if (index > -1) break;
        }
        indexCat++;
      }

      let found: number = -1;
      for (let i = 0; i < this.variantTest.categories.length; i ++) {
        if (this.variantTest.categories[i].categoryName == variant.categoria) {
          found = i;
          break;
        }
      }

      if (found > -1) {
        this.variantTest.categories[found].items.push({
          selectedQuantity: 0,
          name: variant.nome,
          blockedCategory: false,
          background: this.getBackground(variant)
          //background: this.ta.TastieraArticoli.Nodi[indexCat] == undefined ? this.getBackground() : this.getBackground(this.ta.TastieraArticoli.Nodi[indexCat].Nodi[index])
        });
      }
      else
        this.pushVariantCategory(variant, index, indexCat);
    }

    this.variantTest.idArticle = ida;
    this.variantTest.defined = true;
    this.openPopup();
  }

  getBackground (variant) {
    if (!variant.image_enabled)
      return "url(assets/img/misc/bg_box_cassa.jpg) center center no-repeat";
    else
      return 'url(' + this.getArticleImage (this.ta.idnegozio, variant.idarticolo, variant.small_image) + ') no-repeat center black';
  }

  pushVariantCategory (v, index, indexCat) {
    this.variantTest.categories.push({
      categoryName: v.categoria,
      idSelectedArticle: 0,
      blockedCategory: false,
      items: [
        {
          selectedQuantity: 0,
          name: v.nome,
          background: this.getBackground(v)
        }
      ]
    });
  }

  openPopup() {
    this.dialogRef = this.dialog.open(Variant2Dialog);
    this.dialogRef.componentInstance.variants = this.variantTest;
    this.dialogRef.componentInstance.test = this.buildTestVariants();
    this.dialogRef.componentInstance._translate = this._translate;
    this.dialogRef.afterClosed().subscribe(result => {
      this.dialogRef = null;
    });
    this.dialogRef.componentInstance.bs = this._basketService;
  }

  openPopupLayout() {
    this.dialogRef2 = this.dialog.open(LayoutDialog);
    this.dialogRef2.componentInstance.selected = this.selectedTheme;
    this.dialogRef2.componentInstance.user = this.authGuardService.getUser();
    this.dialogRef2.afterClosed().subscribe(result => {
      this.dialogRef2 = null;
      if (this.localStorageService.get<Us.User[]>("users") != null) {
        let users = this.localStorageService.get<Us.User[]>("users");
        for (let u of users) {
          if (u.operatorId == this.authGuardService.getUser()['guid']) {
            this.selectedTheme = +u.layout;
          }
        }
      }
    });
  }

  openSettingsPopup() {
    this.dialogRef3 = this.dialog.open(SettingsDialog);
    this.dialogRef3.componentInstance._translate = this._translate;
    this.dialogRef3.afterClosed().subscribe(result => {
      this.dialogRef3 = null;
    });
    this.dialogRef3.componentInstance.bs = this._basketService;
  }

  public openPopupConfirm() {
    let conf; this._translate.get('ecr.ecr-home.1212').subscribe((res: string) => { conf = res; });
    let text; this._translate.get('ecr.ecr-home.1213').subscribe((res: string) => { text = res; });

    this.dialogsService
      .confirm(conf, text, false)
      .subscribe(res => {
        if (res != undefined && res) {
          this.emptyCart();
        }
      });
  }

  public openPopupConfirmLogout() {
    let conf; this._translate.get('ecr.ecr-home.1212').subscribe((res: string) => { conf = res; });
    let text; this._translate.get('ecr.ecr-home.1214').subscribe((res: string) => { text = res; });

    if (this._basketService.basket.Items.length > 0)
      this.dialogsService
        .confirm(conf, text, false)
        .subscribe(res => {
          if (res != undefined && res)
            this.prepareForLogout();
        });
    else
        this.prepareForLogout();
  }

  prepareForLogout():void {
    let users:Us.User[] = this.localStorageService.get<Us.User[]>("users");
    for (let u of users)
      if (u.operatorId == this.authGuardService.getUser()['guid']) {
        if (u.pause != null) {
          u.pause = null;
          localStorage.setItem('users', JSON.stringify(users));
          break;
        }
      }
    this.logoff();
  }

  buildTestVariants() {
    let test = [];
    for (let category of this.variantTest.categories)
      test.push({
        category: category.categoryName,
        tot: 0,
        itemSelected: []
      });
    return test;
  }

  openSnackBar(msg: string = "") {
    this.snackBar.open(msg, "", {
      duration: 1000
    });
  }

  logoff () {
    this.authGuardService.doLogoff();
  }

  activateBackButton () {
    this.icons[0].disabled = false;
  }

  deactivateBackButton () {
    this.icons[0].disabled = true;
  }

  getEnvironment() {
    this.http.post(
      myGlobals.ArticoliNegozio,
      myGlobals.ArticoliData
    )
    .map((res) => {
      this.ta = res;
      this.getTastiera();
    })
    .subscribe(val => []);
  }

  getPMs () {
    this.http.post(
      myGlobals.GetPaymentMethods,
      myGlobals.ArticoliData
    )
    .map((res) => {
      let r:any = res;

      let temp;
      let pmKeyboardEnabled = ["CONTANTI", "CARTA DI CREDITO", "CARTA DI DEBITO", "BANCOMAT", "CREDIT_CARD"];
      for (let obj of r.modPag) {
        let x:PaymentMethod = new PaymentMethod();
        x.IdPM = obj.IdmodPag;
        x.PaymentMethod = obj.Descr;
        x.KeyboardEnabled = (pmKeyboardEnabled.indexOf(obj.Descr) !== -1);
        x.PMEnabled = (pmKeyboardEnabled.indexOf(obj.Descr) !== -1);
        if (obj.Descr == "CONTANTI")
          x.Image = "icn_contanti.png";
        else if (obj.Descr == "BANCOMAT" || obj.Descr == "CARTA DI DEBITO")
          x.Image = "icn_carta_di_debito.png";
        else if (obj.Descr == "CARTA DI CREDITO")
          x.Image = "icn_carta_di_credito.png";
        else if (obj.Descr == "BORSELLINO" || obj.Descr == "MONETICA")
          x.Image = "icn_borsellino.png";
        else if (obj.Descr == "BUONI PASTO")
          x.Image = "icn_buoni_pasto.png";
        else if (obj.Descr == "COUPON" || obj.Descr == "GIFT")
          x.Image = "icn_gift_card.png";
        else
          x.Image = "icn_payment.png";
        let ind = 0;
        if (this.paymentMethods == undefined) {
          this.paymentMethods = new PaymentMethod()[ind];
        } else {
          ind = this.paymentMethods.length;
        }
        if (this.paymentMethods == undefined) this.paymentMethods = [];
        if (obj.Descr != "CONTANTI")
          this.paymentMethods[ind] = x;
        else
          this.paymentMethods.splice(0, 0, x);
      }
      this.localStorageService.set("paymentMethods", this.paymentMethods);
    })
    .subscribe(val => []);
  }

  getTastiera () {
    if (this.ta != undefined) {
      for (let i = 0; i < this.ta.TastieraArticoli.Nodi.length; i ++)
        this.tastiere.push (this.ta.TastieraArticoli.Nodi [i]);

      this.tastiere.sort (function (a, b) { return (a.top > b.top) ? 1 : ((b.top > a.top) ? -1 : 0); });
      // this._loaderService.toggleLoader(); ????????????

      this.localStorageService.set("ta", this.ta);
      this.localStorageService.set("tastiere", this.tastiere);

      this._loaderService.hide();
    }
  }

  getNodeImage (idnegozio, idtastiera, idnodo) {
    return myGlobals.getNodeImage(idnegozio, idtastiera, idnodo);
  }

  getArticleImage (idnegozio, idarticolo, small_image) {
    return myGlobals.getArticleImage(idnegozio, idarticolo, small_image);
  }

  toHumanColor (num) {
    num >>>= 0;
    var b = num & 0xFF,
        g = (num & 0xFF00) >>> 8,
        r = (num & 0xFF0000) >>> 16,
        a = ((num & 0xFF000000) >>> 24) / 255;

    return "rgba(" + [r, g, b, a].join(",") + ")";
  }

  buildCSS (tastiera: TastieraArticoli.Nodi, justdim: boolean = false) {
    // to return just for the external div
    if (justdim)
      return {
        'width': parseInt ((+tastiera.width / this.coeff) + "") + 'px',
        'height': parseInt ((+tastiera.height / this.coeff) + "") + 'px',
        'top': tastiera.top + "px",
        'left': tastiera.left + "px"
      };
    // to return just for the internal div
    return {
        'color': this.toHumanColor (tastiera.forecolor),
        'background': tastiera.image_enabled == 0 ? this.toHumanColor (tastiera.backcolor) : this.toHumanColor (tastiera.backcolor) + ' url(' + this.getNodeImage (this.ta.idnegozio, this.ta.idtastiera, tastiera.idnodo) + ') no-repeat center',
        'background-size': tastiera.image_enabled == 0 ? '' : parseInt ((+tastiera.image_width - 15 / this.coeff) + "") + 'px ' + parseInt ((+tastiera.image_height - 15 / this.coeff) + "") + 'px',
        'font-family': tastiera.font_name,
        'font-size': tastiera.font_size + 'px',
        'font-weight': tastiera.font_bold == 1 ? 'bold' : 'normal',
    };
  }

  buildCSSSearch (tastiera: TastieraArticoli.Nodi, justdim: boolean = false) {
    if (this.showSearchTab) {
      tastiera.width = tastiera.height = 250;
    }
    // to return just for the external div
    if (justdim)
      return {
        'width': '250px',
        'height': '250px'
      };
    // to return just for the internal div
    return {
        'color': this.toHumanColor (tastiera.forecolor),
        'background': tastiera.image_enabled == 0 ? this.toHumanColor (tastiera.backcolor) : this.toHumanColor (tastiera.backcolor) + ' url(' + this.getNodeImage (this.ta.idnegozio, this.ta.idtastiera, tastiera.idnodo) + ') no-repeat center',
        'background-size': tastiera.image_enabled == 0 ? '' : parseInt ((+tastiera.image_width - 15 / this.coeff) + "") + 'px ' + parseInt ((+tastiera.image_height - 15 / this.coeff) + "") + 'px',
        'font-family': tastiera.font_name,
        'font-size': tastiera.font_size + 'px',
        'font-weight': tastiera.font_bold == 1 ? 'bold' : 'normal',
    };
  }

  buildCSS2ndTheme(tastiera: TastieraArticoli.Nodi, sclass) {
    if (this.showSearchTab) {
      tastiera.width = tastiera.height = 250;
    }
    if (sclass == 'img') {
      let w = parseInt ("" + (((tastiera.height > tastiera.width ? tastiera.width : tastiera.height) / 2)));
      return {
        'width': w + 'px',
        'height': w + 'px',
        'border-radius': '75px',
        'margin': '11px 0px 0px ' + parseInt("" + ((tastiera.width / 2) - (w / 2) - 5)) + 'px',
        'border': '4px solid #FF8000'
      }
    }
    else if (sclass == 'inside' && tastiera.width <= 199)
      return {
        'margin-top': '-20px'
      };
    else if (sclass == 'div') {
      let mt = '';
      if (tastiera.articolo != null && tastiera.width <= 200)
        if (tastiera.height < 180)
          mt = '-4px';
        else
          mt = '9px';
      else
        if (tastiera.width > 199)
          if (tastiera.articolo != null && tastiera.articolo.varianti != null && tastiera.articolo.varianti.length > 0)
            mt = (parseInt("" + ((100 * tastiera.height) / 190)) + 20) + 'px';
          else
            mt = parseInt("" + ((100 * tastiera.height) / 190)) + 'px';
        else
          mt = parseInt ("" + (tastiera.height / 2)) + 'px';

      return {
        'margin-top': mt,
        'width': tastiera.articolo != null ? tastiera.width - 5 + 'px' : tastiera.width + 'px',
        'font-weight': 'bold'
      };
    }
    else if (sclass == 'card') {
      let h = '';
      if (tastiera.articolo == null)
        h = tastiera.height - 5 + 'px';
      else
        if (tastiera.articolo != null && tastiera.articolo.varianti != null && tastiera.articolo.varianti.length != 0 && tastiera.height > 190)
          h = parseInt ("" + ((tastiera.height * 235) / 240)) + 'px';
        else
          if (tastiera.width > 199)
            h = parseInt ("" + (tastiera.height - 20 * (tastiera.height / 190))) + 'px';
          else
            h = parseInt ("" + ((122 * tastiera.height) / 240)) + 'px';

      return {
        'height': h,
        'margin-top': tastiera.articolo == null || tastiera.width > 199 ? '0px' : parseInt("" + (tastiera.height / 2)) + 'px',
        'border-radius': '17px',
        'background': '#191919',
        'z-index': '99',
        'width': tastiera.width - 5 + 'px'
      };
    }
    else if (sclass == 'p')
      return tastiera.articolo == null ?
      {
        'font-size': '18px',
        'margin-top': tastiera.height / 4 + 2 * (tastiera.height / 240) + 'px'
      }
      :
      {
        'font-size': '12px',
        'margin-top': '48px',
      }
    else if (sclass == 'price')
      return tastiera.articolo != null ?
      {
        'transform': 'rotate(0deg)',
        'position': 'absolute',
        'margin-top': tastiera.height < 180 ? '-57px' : '-62px',
        'margin-left': '0px',
        'font-size': tastiera.height < 180 ? '12px' : '18px',
        'width': tastiera.width - 5 + 'px'
      }
      :
      {
        'transform': 'rotate(-90deg)',
        'position': 'absolute',
        'margin-top': '-145px',
        'margin-left': '195px',
        'font-size': '18px'
      };
  }

  buildCSS3rdTheme (tastiera: TastieraArticoli.Nodi, type) {

  }

  oldIconsDisabled: boolean[] = [];
  callPrePayment() {
    for (let i of this.icons) {
      this.oldIconsDisabled[i.src] = i.disabled;
      if (i.src != "esci" && i.src != "arrow_up")
        i.disabled = true;
      else
        i.disabled = false;
    }
  }

  cancelPrePayment() {
    this._basketService.prePayment = false;
    let ind: number = 0;
    for (let i of this.icons)
      i.disabled = this.oldIconsDisabled[i.src];
  }

  getImagePP(art: TastieraArticoli.Articolo) {
    if (art.idarticolo == null || art.image_enabled == 0)
      return 'assets/img/misc/bg_box_cassa.jpg';
    else
      return myGlobals.getArticleImage(this.ta.idnegozio, art.idarticolo, 'SMALL');
  }

  getImageL2 (tastiera: TastieraArticoli.Nodi) {
    if (tastiera.image_enabled == 0)
      return 'assets/img/misc/bg_box_cassa.jpg';
    else
      return this.getNodeImage (this.ta.idnegozio, this.ta.idtastiera, tastiera.idnodo);
  }

  resetKeyboard () {
    if (this._basketService.prePayment) {
      this._basketService.payments.Completed = undefined;
      this.cancelPrePayment();
    }
    else {
      this.tastiere = this.tastiere_sel;
      this.tastiere_sel = [];
      this.nomeTastiera = "";
      this.deactivateBackButton();
      if (this.searchOption == 2) {
        this.searchOption = 1;
        this.searchForm.controls["searchf"].setValue('');
      }
    }
  }

  openKeyboard (idnodo) {
    for (let i = 0; i < this.tastiere.length; i ++)
      if (this.tastiere[i].idnodo == idnodo) {
        this.tastiere_sel = this.tastiere;
        this.nomeTastiera = this.tastiere[i].nodo;
        this.tastiere = this.tastiere[i].Nodi;
        this.screenNodesName = this.tastiere[i].nodo;
      }

    this.activateBackButton();
    this.screenNodes = true;
  }

  addToBasket (item: TastieraArticoli.Articolo) {
    if (this._basketService.updateQuantity < 2) {
      this._basketService.addtoBasket(item);
      let addedString;
      this._translate.get("ecr.ecr-home.1215", { 'article': item.articolo }).subscribe((res: string) => { addedString = res; });
      this.openSnackBar(addedString);
    } else {
      this._basketService.addtoBasket(item, this._basketService.updateQuantity);
      let addedString2;
      this._translate.get("ecr.ecr-home.1216", { 'article': item.articolo, 'quantity': this._basketService.updateQuantity }).subscribe((res: string) => { addedString2 = res; });
      this.openSnackBar(addedString2);
      this._basketService.updateQuantity = 0;
    }
  }
}

@NgModule({
  imports: [
    BrowserAnimationsModule
  ],
  exports: [

  ],
  declarations: [

  ],
  providers: [
    LoaderService
  ],
})
export class HomeModule { }
