import { Injectable } from '@angular/core';
import { ApiAction, ApiProviderWinticplusService } from './api-provider-winticplus.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { GceLookUpOptions, GceAnagraphic, GceClinic, GceInvoicePersonClinic, GceInvoicePerson, GcePresence, GceInvoicePersonCompensation, GceInvoice, GceEnvironment, GceMedicType, GceFiscalReg, GceProfiles, GceProvCodes } from '../entities/gce-management';
import { Observer, observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { DataRepositoryService } from './data-repository.service';
import { GcePerformanceCalculationDetail } from '../entities/gce-management/gce-performance-calculation-detail';
import { GcePerformanceCalculationDetracts } from '../entities/gce-management/gce-performance-calculation-detracts';
import { ExcelService } from './excel.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { TranslateModule, TranslateCompiler, TranslateService } from '@ngx-translate/core';
import { CommonUtilsService } from './common-utils.service';
import { AffittuariMailMensile } from '../entities/im-aff/affittuari-mail-mensile';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

const actionGetEnvironment = new ApiAction(`${environment.gceAPI}/fmc_ambiente/`, (data) => { return new GceEnvironment(data[0].idunico, data[0].mese_lavoro, data[0].ultima_chiusura_incentive); }, 'get');

const actionGetInvoicePerson = new ApiAction(`${environment.gceAPI}/fmc_medici_fatturazione/`, (data) => { return data }, 'get');
const actionInsertInvoicePerson = new ApiAction(`${environment.gceAPI}/fmc_medici_fatturazione/`, (data) => { return data }, 'post');
const actionUpdateInvoicePerson = new ApiAction(`${environment.gceAPI}/fmc_medici_fatturazione/`, (data) => { return data }, 'put');
const actionDeleteInvoicePerson = new ApiAction(`${environment.gceAPI}/fmc_medici_fatturazione/`, (data) => { return data }, 'delete');

const actionGetAnagraphic = new ApiAction(`${environment.gceAPI}/fmc_medici_invio_anagrafica/`, (data) => { return data }, 'get');
const actionInsertAnagraphic = new ApiAction(`${environment.gceAPI}/fmc_medici_invio_anagrafica/`, (data) => { return data }, 'post');
const actionUpdateAnagraphic = new ApiAction(`${environment.gceAPI}/fmc_medici_invio_anagrafica/`, (data) => { return data }, 'put');
const actionDeleteAnagraphic = new ApiAction(`${environment.gceAPI}/fmc_medici_invio_anagrafica/`, (data) => { return data }, 'delete');

const actionGetClinic = new ApiAction(`${environment.gceAPI}/fmc_cliniche/`, (data) => { return data }, 'get');
const actionInsertClinic = new ApiAction(`${environment.gceAPI}/fmc_cliniche/`, (data) => { return data }, 'post');
const actionUpdateClinic = new ApiAction(`${environment.gceAPI}/fmc_cliniche/`, (data) => { return data }, 'put');
const actionDeleteClinic = new ApiAction(`${environment.gceAPI}/fmc_cliniche/`, (data) => { return data }, 'delete');

const actionUpdateDetract = new ApiAction(`${environment.gceAPI}/fmc_addebiti_laboratorio/`, (data) => { return data }, 'put');

const actionGetPresence = new ApiAction(`${environment.gceAPI}/fmc_medici_presenze/`, (data) => { return data }, 'get');
const actionGetPresenceFromTo = new ApiAction(`${environment.gceAPI}/fmc_medici_presenze_from_to/`, (data) => { return data }, 'get');
const actionGetPresenceFromToGeneric = new ApiAction(`${environment.gceAPI}/fmc_medici_presenze_from_to_generic/`, (data) => { return data }, 'post');
const actionInsertPresence = new ApiAction(`${environment.gceAPI}/fmc_medici_presenze/`, (data) => { return data }, 'post');
const actionUpdatePresence = new ApiAction(`${environment.gceAPI}/fmc_medici_presenze/`, (data) => { return data }, 'put');
const actionDeletePresence = new ApiAction(`${environment.gceAPI}/fmc_medici_presenze/`, (data) => { return data }, 'delete');

const actionGetInvoicePersonClinic = new ApiAction(`${environment.gceAPI}/fmc_medici_fatturazione_clinica/`, (data) => { return data }, 'get');
const actionGetPersonByClinic = new ApiAction(`${environment.gceAPI}/fmc_medici_fatturazione_clinica_by_clinic/`, (data) => { return data }, 'get');
const actionInsertInvoicePersonClinic = new ApiAction(`${environment.gceAPI}/fmc_medici_fatturazione_clinica/`, (data) => { return data }, 'post');
const actionUpdateInvoicePersonClinic = new ApiAction(`${environment.gceAPI}/fmc_medici_fatturazione_clinica/`, (data) => { return data }, 'put');
const actionDeleteInvoicePersonClinic = new ApiAction(`${environment.gceAPI}/fmc_medici_fatturazione_clinica/`, (data) => { return data }, 'delete');
const actionDeleteInvoicePersonClinicSingle = new ApiAction(`${environment.gceAPI}/fmc_medici_fatturazione_clinica_remove_single/`, (data) => { return data }, 'delete');

const actionGetInvoicePersonCompensation = new ApiAction(`${environment.gceAPI}/fmc_medici_fatturazione_compensi/`, (data) => { return data.map(d => { return GceInvoicePersonCompensation.parse(d) }) }, 'get');
const actionInsertInvoicePersonCompensation = new ApiAction(`${environment.gceAPI}/fmc_medici_fatturazione_compensi/`, (data) => { return GceInvoicePersonCompensation.parse(data) }, 'post');
const actionUpdateInvoicePersonCompensation = new ApiAction(`${environment.gceAPI}/fmc_medici_fatturazione_compensi/`, (data) => { return GceInvoicePersonCompensation.parse(data) }, 'put');
const actionDeleteInvoicePersonCompensation = new ApiAction(`${environment.gceAPI}/fmc_medici_fatturazione_compensi/`, (data) => { return GceInvoicePersonCompensation.parse(data) }, 'delete');
const actionDeleteInvoicePersonCompensationSingle = new ApiAction(`${environment.gceAPI}/fmc_medici_fatturazione_compensi_remove_single/`, (data) => { return GceInvoicePersonCompensation.parse(data) }, 'delete');

const actionGetInvoice = new ApiAction(`${environment.gceAPI}/fmc_fatturazione_mese/`, (data) => { return data }, 'get');
const actionInsertInvoice = new ApiAction(`${environment.gceAPI}/fmc_fatturazione_mese/`, (data) => { return data }, 'post');
const actionUpdateInvoice = new ApiAction(`${environment.gceAPI}/fmc_fatturazione_mese/`, (data) => { return data }, 'put');
const actionDeleteInvoice = new ApiAction(`${environment.gceAPI}/fmc_fatturazione_mese/`, (data) => { return data }, 'delete');
const actionGetInvoiceFromToGeneric = new ApiAction(`${environment.gceAPI}/fmc_fatturazione_mese_from_to_generic/`, (data) => { return data }, 'post');

const actionGetMedicType = new ApiAction(`${environment.gceAPI}/fmc_tipimedico/`, (data) => { return data }, 'get');
const actionInsertMedicType = new ApiAction(`${environment.gceAPI}/fmc_tipimedico/`, (data) => { return data }, 'post');
const actionUpdateMedicType = new ApiAction(`${environment.gceAPI}/fmc_tipimedico/`, (data) => { return data }, 'put');
const actionDeleteMedicType = new ApiAction(`${environment.gceAPI}/fmc_tipimedico/`, (data) => { return data }, 'delete');

const actionConfirmInvoice = new ApiAction(`${environment.gceAPI}/stored/fmc_confermaFattura/`, (data) => { return data }, 'post');
const actionAnnInvoice = new ApiAction(`${environment.gceAPI}/stored/fmc_annullo_ordini_nav/`, (data) => { return data }, 'post');
const actionCalculateBilling = new ApiAction(`${environment.gceAPI}/stored/fmc_calcolomese/`, (data) => { return data }, 'post');
const actionCalculateBillingDoctor = new ApiAction(`${environment.gceAPI}/stored/fmc_calcolomesed/`, (data) => { return data }, 'post');
const actionCheckPerson = new ApiAction(`${environment.gceAPI}/stored/fmc_medicianagrafica/`, (data) => { return data }, 'post');
const actionSendMail = new ApiAction(`${environment.gceAPI}/fmc_fatturazione_mese/fmc_fatturazione_mese_send_mail/`, (data) => { return data }, 'post');
//const actionGetPerformanceCalculationDetailGeneric = new ApiAction(`${environment.gceAPI}/fmc_dettaglio_calcolo_prestazioni_generic/`, (data) => { return data }, 'post');
const actionGetPerformanceCalculationDetailGeneric = `${environment.gceAPI}/fmc_dettaglio_calcolo_prestazioni_generic/`;
const actionGetPerformanceCalculationDetractsGeneric = `${environment.gceAPI}/fmc_addebiti_laboratorio_generic/`;
const actionGetPerformanceCalculationDetractsGenericAll = `${environment.gceAPI}/fmc_addebiti_laboratorio_generic_all/`;


@Injectable({
  providedIn: 'root'
})
export class GceManagementService {
  _translate: TranslateService;

  constructor(private apiService: ApiProviderWinticplusService, private http: HttpClient, private dataRepo: DataRepositoryService,
    private excelService: ExcelService, public translate: TranslateService) {
    this._translate = translate;
  }

  dataRepoClinics: GceClinic[] = [];
  dataRepoMedics: any[] = [];
  dataRepoEvMedics: any[] = [];
  dataRepoInvoicePersons: GceInvoicePerson[] = [];
  dataRepoGceEnvironment: GceEnvironment = null;
  dataRepoMedicTypes: GceMedicType[] = [];
  dataRepoFiscalReg: GceFiscalReg[] = [];
  dataRepoProfiles: GceProfiles[] = [];
  dataRepoProvs: GceProvCodes[] = [];

  getEnvironment(): Observable<GceEnvironment[]> {
    return Observable.create((observer: Observer<GceEnvironment[]>) => {
      this.apiService.makeAPICall(actionGetEnvironment).subscribe((data) => {
        console.log("GceManagementService.getEnvironment %o", data);
        this.dataRepoGceEnvironment = data;
        observer.next(data);
      }, err => {
        console.log("GceManagementService.getEnvironment %o", err);
        observer.error(err);
      });
    });
  }

  getAllAnagraphics(): Observable<GceAnagraphic[]> {
    return Observable.create((observer: Observer<GceAnagraphic[]>) => {
      this.apiService.makeAPICall(actionGetAnagraphic).subscribe((data) => {
        observer.next(data);
      }, err => {
        console.log("GceManagementService.getAllAnagraphics %o", err);
        observer.error(err);
      });
    });
  }

  getAnagraphic(piva: string): Observable<GceAnagraphic[]> {
    return Observable.create((observer: Observer<GceAnagraphic>) => {
      this.apiService.makeAPICall(actionGetAnagraphic, { urlParams: piva }).subscribe((data) => {
        observer.next(data);
      }, err => {
        console.log("GceManagementService.getPerson %o ", err);
        observer.error(err);
      })
    });
  }

  sendEmail(obj: any): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      this.apiService.makeAPICall(actionSendMail, obj).subscribe((data) => {
        observer.next(data);
      }, err => {
        console.log("GceManagementService.sendEmail %o ", err);
        observer.error(err);
      })
    });
  }

  insertAnagraphic(person: GceAnagraphic): Observable<GceAnagraphic> {
    return Observable.create((observer: Observer<GceAnagraphic>) => {
      this.apiService.makeAPICall(actionInsertAnagraphic, person).subscribe((data) => {
        console.log("Angraphic inserted: %o", person);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.insertAngraphic %o ", err);
        observer.error(err);
      })
    });
  }

  updateAnagraphic(Angraphic: GceAnagraphic): Observable<GceAnagraphic> {
    return Observable.create((observer: Observer<GceAnagraphic>) => {
      let query = {
        urlParams: Angraphic.mail,
        content: Angraphic
      };
      this.apiService.makeAPICall(actionUpdateAnagraphic, query).subscribe((data) => {
        console.log("Angraphic updated %o", data);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.updateAngraphic %o ", err);
        observer.error(err);
      });
    });
  }

  updateDetract(detract: GcePerformanceCalculationDetracts): Observable<GceAnagraphic> {
    return Observable.create((observer: Observer<GcePerformanceCalculationDetracts>) => {
      let query = {
        urlParams: detract.IDVEND,
        content: detract
      };
      this.apiService.makeAPICall(actionUpdateDetract, query).subscribe((data) => {
        console.log("Detract updated %o", data);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.updateDetract %o ", err);
        observer.error(err);
      });
    });
  }


  deleteAnagraphic(mail: string): Observable<GceAnagraphic> {
    return Observable.create((observer: Observer<GceAnagraphic>) => {
      this.apiService.makeAPICall(actionDeleteAnagraphic, { urlParams: mail }).subscribe((data) => {
        console.log("Angraphic deleted %o", data);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.deleteAngraphic %o ", err);
        observer.error(err);
      })
    });
  }

  getAllInvoicePersons(): Observable<GceInvoicePerson[]> {
    return Observable.create((observer: Observer<GceInvoicePerson[]>) => {
      // `/${idController}/${user['profile']}`
      this.apiService.makeAPICall(actionGetInvoicePerson, { queryParams: `?desc_domain=${encodeURIComponent(this.dataRepo.user.desc_domain)}` }).subscribe((data) => {
        observer.next(data);
      }, err => {
        console.log("GceManagementService.getAllInvoicePersons %o", err);
        observer.error(err);
      });
    });
  }

  getInvoicePerson(id: number): Observable<GceInvoicePerson> {
    return Observable.create((observer: Observer<GceInvoicePerson>) => {
      this.apiService.makeAPICall(actionGetInvoicePerson, { urlParams: id }).subscribe((data) => {
        observer.next(data);
      }, err => {
        console.log("GceManagementService.getInvoicePerson %o ", err);
        observer.error(err);
      })
    });
  }

  //lo prende dal data repository
  getInvoicePersonById(id: number): GceInvoicePerson {
    return this.dataRepoInvoicePersons.find((drip: GceInvoicePerson) => drip.idfatturazione === id);
  }

  insertInvoicePerson(invoicePerson: GceInvoicePerson): Observable<GceInvoicePerson> {
    return Observable.create((observer: Observer<GceInvoicePerson>) => {
      this.apiService.makeAPICall(actionInsertInvoicePerson, invoicePerson).subscribe((data) => {
        console.log("Invoice inserted: %o", invoicePerson);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.insertInvoicePerson %o ", err);
        observer.error(err);
      })
    });
  }

  updateInvoicePerson(invoicePerson: GceInvoicePerson): Observable<GceInvoicePerson> {
    return Observable.create((observer: Observer<GceInvoicePerson>) => {
      let query = {
        urlParams: invoicePerson.idfatturazione,
        content: invoicePerson
      };
      this.apiService.makeAPICall(actionUpdateInvoicePerson, query).subscribe((data) => {
        console.log("Invoice updated %o", data);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.updateInvoicePerson %o ", err);
        observer.error(err);
      });
    });
  }

  deleteInvoicePerson(idfatturazione: number): Observable<GceInvoicePerson> {
    return Observable.create((observer: Observer<GceInvoicePerson>) => {
      this.apiService.makeAPICall(actionDeleteInvoicePerson, { urlParams: idfatturazione }).subscribe((data) => {
        console.log("Invoice deleted %o", data);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.deleteInvoicePerson %o ", err);
        observer.error(err);
      })
    });
  }

  searchInvoicePerson(filter: string): Observable<GceInvoicePerson[]> {
    return Observable.create(observer => {
      if (filter) {
        let filterValues = filter.split(" ").filter(a => !!a);
        let filteredPersons = this.dataRepoInvoicePersons.filter((person: GceInvoicePerson) => {
          return person.descrizione.split(" ").filter(descr => {
            return filterValues.filter(fv => descr.toLowerCase().indexOf(fv.toLowerCase()) != -1).length > 0;
          }).length >= filterValues.length;
        });
        observer.next(filteredPersons);
      } else {
        observer.next([]);
      }
    });
  }

  postMedicFile(fileToUpload: File): Observable<any> {
    const endpoint = `${environment.gceAPI}/fileupload/`;
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    console.log('form data %o',formData);
    return this.http.post(endpoint, formData, {});

  }


  updateMedicFile(urlFile: any, idMedic: any): Observable<Object> {
    console.log('updateMedicFile %o %o', urlFile, idMedic);
    let req = {
      urlFile:urlFile,
      idMedic: idMedic
    };

    return this.http.post(`${environment.gceAPI}/update_medic_file/`, req, { responseType: 'json' });

  }

  getPersonDescription(id: any, ids: any[]) {
    for (var a = 0; a < ids.length; a = a + 1) {
      if (id == ids[a].idfatturazione)
        return (ids[a].descrizione);
    }
    console.log('no find person of %o', id);
    return '';
  }

  getClinicDescription(id: any, ids: any[]) {
    for (var a = 0; a < ids.length; a = a + 1) {
      if (id == ids[a].code_c)
        return (ids[a].code_c + ' ' + ids[a].nome);
    }
  }

  countClinic(id: any, cl: any) {
    let ret = 0;
    for (var a = 0; a < cl.length; a = a + 1) {
      if (id == cl[a].idfatturazione)
        ret++;
    }
    return ret;
  }

  exportToExcelInvoicePerson(persons: GceInvoicePerson[], clinics: any[], clinicspersons: GceInvoicePersonClinic[], compensationspersons: GceInvoicePersonCompensation[], fileName: string) {
    console.log('EXPORT FOR EXCEL persons %o cl %o, clinics %o compensations %o', persons, clinics, clinicspersons, compensationspersons);
    let personalForExcel: any[] = persons.map((person: GceInvoicePerson) => {
      let hasclinic = false;
      let c = this.countClinic(person.idfatturazione, clinicspersons);
      if (c > 0) {
        hasclinic = true;
      }
      let ret = {
        idfatturazione: person.idfatturazione,
        hasclinicassociated: hasclinic,
        descrizione: person.descrizione,
        email: person.email,
        data_nascita: person.data_nascita,
        codice_fiscale: person.codice_fiscale,
        piva: person.piva,
        fornitore_nav: person.fornitore_nav,
        codice_xdent: person.codice_xdent,
        medico_portale: person.medico_portale,
        piva_portale: person.piva_portale,
        cfisc_portale: person.cfisc_portale,
        regime_portale: person.regime_portale,
        capitale_portale: person.capitale_portale,
        nome_nav: person.nome_nav,
        piva_nav: person.piva_nav,
        cfisc_nav: person.cfisc_nav,
        tipo_medico_id: person.tipo_medico,
        tipo_medico: person.medicType ? person.medicType.descrizione : '',
        id_profilo_xdent: person.id_profilo_xdent,
        indirizzo: person.indirizzo,
        citta: person.citta,
        cap: person.cap,
        provincia: person.provincia,
        annullato: person.annullato,
        errore: person.errore,
        desc_errore: person.desc_errore,
        capitale_sociale_invio_portale: person.capitale_sociale_invio_portale,
        regime_fiscale_invio_portale: person.regime_fiscale_invio_portale,
        dataora_invio_portale: person.dataora_invio_portale,
        esito_invio_portale: person.esito_invio_portale,
        messaggio_invio_portale: person.messaggio_invio_portale,
        flg_invio_dati_portale: person.flg_invio_dati_portale,
        cessato: person.cessato,
        iscrizione_albo_medici: person.iscrizione_albo_medici,
        iscrizione_albo_medici_prov: person.iscrizione_albo_medici_prov,
        iscrizione_albo_odontoiatri: person.iscrizione_albo_odontoiatri,
        iscrizione_albo_odontoiatri_prov: person.iscrizione_albo_odontoiatri_prov,
        data_laurea: person.data_laurea,
        scadenza_blsd: person.scadenza_blsd,
        scadenza_radioprotezione: person.scadenza_radioprotezione,
        scadenza_rc_professionale: person.scadenza_rc_professionale,
        massimale_rc_professionale: person.massimale_rc_professionale,
        codice_iban: person.codice_iban,
        indirizzo_pec: person.indirizzo_pec,
        telefono: person.telefono,
        flg_nofattura_portale: person.flg_nofattura_portale
      };
      return ret;
    });

    let clinicspersonsForExcel: any[] = clinicspersons.map((clinicsperson: any) => {
      let ret = {
        idfatturazione: this.getPersonDescription(clinicsperson.idfatturazione, persons),
        cod_clinica: this.getClinicDescription(clinicsperson.cod_clinica, clinics),
        data_inizio: clinicsperson.data_inizio,
        data_fine: clinicsperson.data_fine,
        data_inzio_gestione: clinicsperson.data_inzio_gestione,
        data_fine_gestione: clinicsperson.data_fine_gestione,
        flg_compilazione_presenze: clinicsperson.flg_compilazione_presenze
      };
      return ret;
    });

    let cp = clinicspersonsForExcel;
    clinicspersonsForExcel = [];
    for (let a = 0; a < cp.length; a = a + 1) {
      if (cp[a].idfatturazione != null && cp[a].idfatturazione.length > 0)
        clinicspersonsForExcel.push(cp[a]);
    }

    let compensationspersonsForExcel: any[] = compensationspersons.map((compensationsperson: any) => {
      let ret = {
        idfatturazione: this.getPersonDescription(compensationsperson.IDFATTURAZIONE, persons),
        cod_clinica: this.getClinicDescription(compensationsperson.COD_CLINICA, clinics),
        data_inzio: compensationsperson.DATA_INZIO,
        data_fine: compensationsperson.DATA_FINE,
        gettone_giornaliero: compensationsperson.GETTONE_GIORNALIERO,
        direzione_sanitaria: compensationsperson.DIREZIONE_SANITARIA,
        compenso_fisso: compensationsperson.COMPENSO_FISSO,
        minimo_garantito: compensationsperson.MINIMO_GARANTITO,
        direzione_area_clinica: compensationsperson.DIREZIONE_AREA_CLINICA,
        periodo_ds: compensationsperson.PERIODO_DS,
        mese_ds: compensationsperson.MESE_DS,
        periodo_cf: compensationsperson.PERIODO_CF,
        mese_cf: compensationsperson.MESE_CF,
        periodo_dac: compensationsperson.PERIODO_DAC,
        mese_dac: compensationsperson.MESE_DAC,
        pct_finanziamento: compensationsperson.PCT_FINANZIAMENTO
      };
      return ret;
    });

    let cp2 = compensationspersonsForExcel;
    compensationspersonsForExcel = [];
    for (let a = 0; a < cp2.length; a = a + 1) {
      if (cp2[a].idfatturazione != null && cp2[a].idfatturazione.length > 0)
        compensationspersonsForExcel.push(cp2[a]);
    }

    this.excelService.exportAsExcelFileSections(personalForExcel, clinicspersonsForExcel, compensationspersonsForExcel, fileName);
  }

  getAllClinics(): Observable<GceClinic[]> {
    return Observable.create((observer: Observer<GceClinic[]>) => {
      this.apiService.makeAPICall(actionGetClinic).subscribe((data) => {
        observer.next(data);
      }, err => {
        console.log("GceManagementService.getAllClinics %o", err);
        observer.error(err);
      });
    });
  }

  getClinic(code_n: number): Observable<GceClinic> {
    return Observable.create((observer: Observer<GceClinic>) => {
      this.apiService.makeAPICall(actionGetAnagraphic, { urlParams: code_n }).subscribe((data) => {
        observer.next(data);
      }, err => {
        console.log("GceManagementService.getClinic %o ", err);
        observer.error(err);
      })
    });
  }

  //lo prende dal data repository
  getClinicByCode(code_c: string): GceClinic {
    return this.dataRepoClinics.find((drc: GceClinic) => drc.code_c === code_c);
  }

  insertClinic(person: GceClinic): Observable<GceClinic> {
    return Observable.create((observer: Observer<GceClinic>) => {
      this.apiService.makeAPICall(actionInsertClinic, person).subscribe((data) => {
        console.log("Clinic inserted: %o", person);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.insertClinic %o ", err);
        observer.error(err);
      })
    });
  }

  updateClinic(clinic: GceClinic): Observable<GceClinic> {
    return Observable.create((observer: Observer<GceClinic>) => {
      let query = {
        urlParams: clinic.code_n,
        content: clinic
      };
      this.apiService.makeAPICall(actionUpdateClinic, query).subscribe((data) => {
        console.log("Clinic updated %o", data);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.updateClinic %o ", err);
        observer.error(err);
      });
    });
  }

  deleteClinic(code_n: number): Observable<boolean> {
    return Observable.create((observer: Observer<GceClinic>) => {
      this.apiService.makeAPICall(actionDeleteClinic, { urlParams: code_n }).subscribe((data) => {
        console.log("Clinic deleted %o", data);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.deleteClinic %o ", err);
        observer.error(err);
      })
    });
  }

  getAllPresences(): Observable<GcePresence[]> {
    return Observable.create((observer: Observer<GcePresence[]>) => {
      this.apiService.makeAPICall(actionGetPresence).subscribe((data) => {
        observer.next(data);
      }, err => {
        console.log("GceManagementService.getAllPresences %o", err);
        observer.error(err);
      });
    });
  }

  getPresences(id: number): Observable<GcePresence[]> {
    return Observable.create((observer: Observer<GcePresence[]>) => {
      this.apiService.makeAPICall(actionGetPresence, { urlParams: id }).subscribe((data) => {
        observer.next(data);
      }, err => {
        console.log("GceManagementService.getPresences %o ", err);
        observer.error(err);
      })
    });
  }

  getPresencesFromToGeneric(from?: string, to?: string): Observable<GcePresence[]> {
    if (from) {
      from = moment(from).format('YYYYMMDD');
    } else {
      from = moment().startOf('month').format('YYYYMMDD');
    }
    if (to) {
      to = moment(to).format('YYYYMMDD');
    } else {
      to = moment().format('YYYYMMDD');
    }
    return Observable.create((observer: Observer<GcePresence[]>) => {
      this.apiService.makeAPICall(actionGetPresenceFromToGeneric, { from: from, to: to, desc_domain: this.dataRepo.user.desc_domain }).subscribe((data) => {
        let presences: GcePresence[] = <GcePresence[]>data;
        presences.forEach(presence => {
          presence.person = this.getInvoicePersonById(presence.idfatturazione);
          presence.clinic = this.getClinicByCode(presence.cod_clinica);
        });
        observer.next(presences);
      }, err => {
        console.log("GceManagementService.getPresences %o ", err);
        observer.error(err);
      })
    });
  }

  getPresencesFromTo(id: number, dm: string, role: string, from?: string, to?: string): Observable<GcePresence[]> {
    let params: string[] = [];
    params.push(id.toString());
    if (!!from) {
      params.push(from);
    }
    if (!!to) {
      params.push(to);
    }
    let urlParams: string = params.join("/");
    console.log("getPresences %o from clinic %o", urlParams, dm.substring(0, 3));
    return Observable.create((observer: Observer<GcePresence[]>) => {
      this.apiService.makeAPICall(actionGetPresenceFromTo, { urlParams: urlParams }).subscribe((data) => {
        //filter with role and dm
        let dml = dm.substring(0, 3);
        let data2 = [];
        if (role.toLowerCase() == 'amministratore' || role.toLowerCase() == 'hr medici') {
          data2 = data;
        }
        else {
          for (let a = 0; a < data.length; a = a + 1) {
            if (data[a]['cod_clinica'] == dml)
              data2.push(data[a]);
          }
        }
        observer.next(data2);
      }, err => {
        console.log("GceManagementService.getPresences %o ", err);
        observer.error(err);
      })
    });
  }

  insertPresence(presence: GcePresence): Observable<GcePresence> {
    return Observable.create((observer: Observer<GcePresence>) => {
      this.apiService.makeAPICall(actionInsertPresence, presence).subscribe((data) => {

        observer.next(data);
      }, err => {
        console.log("GceManagementService.insertPresence %o ", err);
        observer.error(err);
      })
    });
  }

  updatePresence(presence: GcePresence): Observable<GcePresence> {
    return Observable.create((observer: Observer<GcePresence>) => {
      let query = {
        urlParams: presence.idfatturazione,
        content: presence
      };
      this.apiService.makeAPICall(actionUpdatePresence, query).subscribe((data) => {
        console.log("Presence updated %o", data);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.updatePresence %o ", err);
        observer.error(err);
      });
    });
  }


  deletePresence(presence: GcePresence): Observable<boolean> {
    throw new Error("not implemented");
    // return Observable.create((observer:Observer<GcePresence>)=>{
    //   this.apiService.makeAPICall(actionDeletePresence, { urlParams: idfatturazione }).subscribe((data)=>{
    //     console.log("Presence deleted %o", data);
    //     observer.next(data);
    //   }, err=>{
    //     console.log("GceManagementService.deletePresence %o ", err);
    //     observer.error(err);
    //   })
    // });
  }

  getAllPersonClinics(): Observable<GceInvoicePersonClinic[]> {
    return Observable.create((observer: Observer<GceInvoicePersonClinic[]>) => {
      this.apiService.makeAPICall(actionGetInvoicePersonClinic).subscribe((data) => {
        observer.next(data);
      }, err => {
        console.log("GceManagementService.getPersonClinics %o", err);
        observer.error(err);
      });
    });
  }

  getPersonClinics(id: number): Observable<GceInvoicePersonClinic[]> {
    return Observable.create((observer: Observer<GceInvoicePersonClinic[]>) => {
      this.apiService.makeAPICall(actionGetInvoicePersonClinic, { urlParams: id }).subscribe((data) => {
        observer.next(data);
      }, err => {
        console.log("GceManagementService.getPersonClinic %o ", err);
        observer.error(err);
      })
    });
  }

  getPersonByClinic(cod_clinica: string): Observable<GceInvoicePersonClinic[]> {
    return Observable.create((observer: Observer<GceInvoicePersonClinic[]>) => {
      this.apiService.makeAPICall(actionGetPersonByClinic, { urlParams: cod_clinica }).subscribe((data) => {
        observer.next(data);
      }, err => {
        console.log("GceManagementService.getPersonByClinic %o ", err);
        observer.error(err);
      })
    });
  }

  insertPersonClinic(personClinic: GceInvoicePersonClinic): Observable<GceInvoicePersonClinic> {
    console.log("Person Clinic To be inserted: %o", personClinic);
    return Observable.create((observer: Observer<GceInvoicePersonClinic>) => {
      this.apiService.makeAPICall(actionInsertInvoicePersonClinic, personClinic).subscribe((data) => {
        console.log("Person Clinic inserted: %o", data);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.insertPersonClinic %o ", err);
        observer.error(err);
      })
    });
  }

  updatePersonClinic(personClinic: GceInvoicePersonClinic): Observable<GceInvoicePersonClinic> {
    return Observable.create((observer: Observer<GceInvoicePersonClinic>) => {
      let query = {
        urlParams: personClinic.idfatturazione,
        content: personClinic
      };
      this.apiService.makeAPICall(actionUpdateInvoicePersonClinic, query).subscribe((data) => {
        console.log("Person Clinic updated %o", data);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.updatePersonClinic %o ", err);
        observer.error(err);
      });
    });
  }

  deletePersonClinicAll(idfatturazione: number): Observable<boolean> {
    return Observable.create((observer: Observer<GceInvoicePersonClinic>) => {
      this.apiService.makeAPICall(actionDeleteInvoicePersonClinic, { urlParams: idfatturazione }).subscribe((data) => {
        console.log("Person Clinic deleted %o", data);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.deletePersonClinic %o ", err);
        observer.error(err);
      })
    });
  }

  deletePersonClinic(idfatturazione: number, codClinica: string): Observable<GceInvoicePersonClinic> {
    return Observable.create((observer: Observer<GceInvoicePersonClinic>) => {
      this.apiService.makeAPICall(actionDeleteInvoicePersonClinicSingle, { urlParams: `${idfatturazione}/${codClinica}` }).subscribe((data) => {
        console.log("Person Clinic deleted %o", data);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.deletePersonClinic %o ", err);
        observer.error(err);
      })
    });
  }

  loadClinicsForPerson(person: GceInvoicePerson, dm: string, role: string): Observable<{ personClinics: GceInvoicePersonClinic[], clinics: GceClinic[] }> {
    return Observable.create(observer => {
      this.getPersonClinics(person.idfatturazione).subscribe((pcls: GceInvoicePersonClinic[]) => {
        console.log('pcls after getPersonClinics %o and dataRepoClinics %o', pcls, this.dataRepoClinics);
        let personClinics: GceInvoicePersonClinic[] = pcls;

        let clinics: GceClinic[] = this.dataRepoClinics.filter((clinic: GceClinic) => {
          return pcls.filter((personClinic: GceInvoicePersonClinic) => {
            return personClinic.cod_clinica === clinic.code_c;
          }).length > 0;
        });

        let dml = dm.substring(0, 3);
        let personClinics2 = [];
        let clinics2 = [];
        console.log(' CLINICS AFTER %o dml %o dm %o', clinics, dml, dm);
        if (!(role.toLowerCase().indexOf('amministratore') == 0 || role.toLowerCase() == 'hr medici')) {
          for (let a = 0; a < personClinics.length; a = a + 1) {
            if (personClinics[a]['cod_clinica'] == dml)
              personClinics2.push(personClinics[a]);
          }
          for (let a = 0; a < clinics.length; a = a + 1) {
            if (clinics[a]['code_c'] == dml)
              clinics2.push(clinics[a]);
          }
          personClinics = personClinics2;
          clinics = clinics2;
        }

        console.log('PERSONCLINICS %o 2 %o CLINICS %o 2 %o', personClinics, clinics, personClinics2, clinics2);
        observer.next({ personClinics, clinics });
      }, err => {
        observer.error(err);
      });
    });
  }

  getPersonCompensations(id: number): Observable<GceInvoicePersonCompensation[]> {
    return Observable.create((observer: Observer<GceInvoicePersonCompensation[]>) => {
      this.apiService.makeAPICall(actionGetInvoicePersonCompensation, { urlParams: id }).subscribe((data) => {
        observer.next(data);
      }, err => {
        console.log("GceManagementService.getPersonCompensation %o ", err);
        observer.error(err);
      })
    });
  }

  insertPersonCompensation(personCompensation: GceInvoicePersonCompensation): Observable<GceInvoicePersonCompensation> {
    return Observable.create((observer: Observer<GceInvoicePersonCompensation>) => {
      this.apiService.makeAPICall(actionInsertInvoicePersonCompensation, personCompensation).subscribe((data) => {
        console.log("Person Compensation inserted: %o", data);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.insertPersonCompensation %o ", err);
        observer.error(err);
      })
    });
  }

  updatePersonCompensation(personCompensation: GceInvoicePersonCompensation): Observable<GceInvoicePersonCompensation> {
    return Observable.create((observer: Observer<GceInvoicePersonCompensation>) => {
      let query = {
        urlParams: personCompensation.idfatturazione,
        content: personCompensation
      };
      this.apiService.makeAPICall(actionUpdateInvoicePersonCompensation, query).subscribe((data) => {
        console.log("Person Compensation updated %o", data);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.updatePersonCompensation %o ", err);
        observer.error(err);
      });
    });
  }

  deletePersonCompensationAll(idfatturazione: number): Observable<boolean> {
    return Observable.create((observer: Observer<GceInvoicePersonCompensation>) => {
      this.apiService.makeAPICall(actionDeleteInvoicePersonCompensation, { urlParams: idfatturazione }).subscribe((data) => {
        console.log("Person Compensation deleted %o", data);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.deletePersonCompensationAll %o ", err);
        observer.error(err);
      })
    });
  }

  deletePersonCompensation(idfatturazione: number, codClinica: string, dataInizio: any): Observable<GceInvoicePersonCompensation> {
    return Observable.create((observer: Observer<GceInvoicePersonCompensation>) => {
      var dt = dataInizio.substring(0, 10);
      console.log("onDelete urlParams:%o - %o - %o", idfatturazione, codClinica, dt);
      this.apiService.makeAPICall(actionDeleteInvoicePersonCompensationSingle, { urlParams: `${idfatturazione}/${codClinica}/${dt}` }).subscribe((data) => {
        console.log("Person Compensation deleted %o", data);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.deletePersonCompensation %o ", err);
        observer.error(err);
      })
    });
  }

  loadDataForInvoicePerson(): Observable<{ persons: GceInvoicePerson[], medicTypes: GceMedicType[] }> {
    return Observable.create(observer => {
      let response1 = this.http.get(`${environment.gceAPI}/fmc_tipimedico/`, { responseType: 'json' });
      let response2 = this.http.get(`${environment.gceAPI}/fmc_profili/`, { responseType: 'json' });
      let response3 = this.http.get(`${environment.gceAPI}/fmc_medici_fatturazione?desc_domain=${encodeURIComponent(this.dataRepo.user.desc_domain)}`, { responseType: 'json' });

      Observable.forkJoin([response1, response2, response3]).subscribe(dataResult => {
        this.dataRepoMedicTypes = <GceMedicType[]>dataResult[0];
        this.dataRepoProfiles = <GceProfiles[]>dataResult[1];
        this.dataRepoFiscalReg = [];
        this.dataRepoProvs = <GceProvCodes[]>environment['provs'];
        let a = new GceFiscalReg();
        a.descrizione = 'Ordinario';
        a.tipo_regime = 'RF01';
        let b = new GceFiscalReg();
        b.descrizione = 'Contribuente minimi';
        b.tipo_regime = 'RF02';
        let c = new GceFiscalReg();
        c.descrizione = 'Forfettario';
        c.tipo_regime = 'RF19';
        this.dataRepoFiscalReg.push(a);
        this.dataRepoFiscalReg.push(b);
        this.dataRepoFiscalReg.push(c);
        let persons: GceInvoicePerson[] = <GceInvoicePerson[]>((<any[]>dataResult[2]).filter(data => !!data));
        persons.forEach(person => {
          person.medicType = this.getMedicTypeById(person.tipo_medico);
        });
        console.log(persons);
        observer.next({ persons: persons, medicTypes: this.dataRepoMedicTypes, fiscalRegs: this.dataRepoFiscalReg, profileS: this.dataRepoProfiles, provS: this.dataRepoProvs });
      }, err => {
        observer.error(err);
      });

    });
  }


  subLoadDataForInvoicePerson(): Observable<{ personsclinics: GceInvoicePersonClinic[], personscompensations: GceInvoicePersonCompensation[] }> {
    return Observable.create(observer => {
      let response1 = this.http.get(`${environment.gceAPI}/fmc_medici_fatturazione_clinica/`, { responseType: 'json' });
      let response2 = this.http.get(`${environment.gceAPI}/fmc_medici_fatturazione_compensi/`, { responseType: 'json' });

      Observable.forkJoin([response1, response2]).subscribe(dataResult => {
        observer.next({ personsclinics: <GceInvoicePersonClinic[]>dataResult[0], personscompensations: <GceInvoicePersonCompensation[]>dataResult[1] });
      }, err => {
        observer.error(err);
      });

    });
  }

  loadDataForBadge(from?: string, to?: string): Observable<{ clinics: GceClinic[], persons: GceInvoicePerson[], presences: GcePresence[] }> {
    let params: any[] = [];

    if (from) {
      from = moment(from).format('YYYYMMDD');
    } else {
      from = moment().startOf('month').format('YYYYMMDD');
    }
    if (to) {
      to = moment(to).format('YYYYMMDD');
    } else {
      to = moment().format('YYYYMMDD');
    }

    return Observable.create(observer => {
      let response1 = this.http.get(`${environment.gceAPI}/fmc_cliniche/`, { responseType: 'json' });
      let response2 = this.http.get(`${environment.gceAPI}/fmc_medici_fatturazione?desc_domain=${encodeURIComponent(this.dataRepo.user.desc_domain)}`, { responseType: 'json' });
      let response3 = this.http.post(`${environment.gceAPI}/fmc_medici_presenze_from_to_generic/`, { from: from, to: to, desc_domain: this.dataRepo.user.desc_domain }, { responseType: 'json' });

      Observable.forkJoin([response1, response2, response3]).subscribe(dataResult => {
        this.dataRepoClinics = <GceClinic[]>dataResult[0];
        this.dataRepoInvoicePersons = <GceInvoicePerson[]>((<any[]>dataResult[1]).filter(data => !!data));
        let presences: GcePresence[] = <GcePresence[]>dataResult[2];
        presences.forEach(presence => {
          presence.person = this.getInvoicePersonById(presence.idfatturazione);
          presence.clinic = this.getClinicByCode(presence.cod_clinica);
        });
        observer.next({ clinics: this.dataRepoClinics, persons: this.dataRepoInvoicePersons, presences: presences });
      }, err => {
        observer.error(err);
      });
    });
  }

  loadMedics(): any {
    return this.http.get(`${environment.gceAPI}/fmc_medici_fatturazione?desc_domain=${encodeURIComponent(this.dataRepo.user.desc_domain)}`, { responseType: 'json' });
  }

  loadEDentMedics(): any {
    return this.http.get(`${environment.gceAPI}/operatori_evodent?desc_domain=${encodeURIComponent(this.dataRepo.user.desc_domain)}`, { responseType: 'json' });
  }

  loadClinics(): any {
    return this.http.get(`${environment.gceAPI}/fmc_cliniche/`, { responseType: 'json' });
  }

  getInvoicesFromToGeneric(from?: string, to?: string): Observable<GceInvoice[]> {
    if (from) {
      from = moment(from).format('YYYYMMDD');
    } else {
      from = moment().startOf('month').format('YYYYMMDD');
    }
    if (to) {
      to = moment(to).format('YYYYMMDD');
    } else {
      to = moment().format('YYYYMMDD');
    }
    return Observable.create((observer: Observer<GceInvoice[]>) => {
      this.apiService.makeAPICall(actionGetInvoiceFromToGeneric, { from: from, to: to, desc_domain: this.dataRepo.user.desc_domain }).subscribe((data) => {
        let invoices: GceInvoice[] = <GceInvoice[]>data;
        invoices.forEach(invoice => {
          invoice.person = this.getInvoicePersonById(invoice.idfatturazione);
          invoice.clinic = this.getClinicByCode(invoice.cod_clinica);
        });
        observer.next(invoices);
      }, err => {
        console.log("GceManagementService.getInvoicesFromToGeneric %o ", err);
        observer.error(err);
      })
    });
  }

  updateInvoice(invoice: GceInvoice): Observable<GceInvoice> {
    return Observable.create((observer: Observer<GceInvoice>) => {
      let query = {
        urlParams: invoice.id,
        content: invoice
      };
      this.apiService.makeAPICall(actionUpdateInvoice, query).subscribe((data) => {
        console.log("Invoice updated %o", data);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.updateInvoice %o", err);
        observer.error(err);
      });
    });
  }

  removeInvoice(invoice: GceInvoice): Observable<GceInvoice> {
    return Observable.create((observer: Observer<GceInvoice>) => {
      let query = {
        urlParams: invoice.id
      };
      this.apiService.makeAPICall(actionDeleteInvoice, query).subscribe((data) => {
        console.log("Invoice removed %o", data);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.removeInvoice %o", err);
        observer.error(err);
      });
    });
  }



  insertInvoice(invoice: GceInvoice): Observable<GceInvoice> {
    return Observable.create((observer: Observer<GceInvoice>) => {
      let query = {
        content: invoice
      };
      this.apiService.makeAPICall(actionInsertInvoice, query).subscribe((data) => {
        console.log("Invoice inserted %o", data);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.insertInvoice %o", err);
        observer.error(err);
      });
    });
  }

  confirmInvoice(invoice: GceInvoice): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      let query = {
        urlParams: `${invoice.id}/${invoice.cod_clinica}`
      };
      this.apiService.makeAPICall(actionConfirmInvoice, query).subscribe((data) => {
        console.log("Invoice confirmed %o", data);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.confirmInvoice %o", err);
        observer.error(err);
      });
    });
  }

  deleteInvoice(invoice: GceInvoice): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      let query = {
        urlParams: `${invoice.id}`
      };
      this.apiService.makeAPICall(actionAnnInvoice, query).subscribe((data) => {
        console.log("Invoice deleted %o", data);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.deleteInvoice %o", err);
        observer.error(err);
      });
    });
  }

  confirmInvoiceById(id: any): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      let query = {
        urlParams: `${id}/0`
      };
      this.apiService.makeAPICall(actionConfirmInvoice, query).subscribe((data) => {
        console.log("Invoice confirmed %o", data);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.confirmInvoice %o", err);
        observer.error(err);
      });
    });
  }

  calculateBilling(clinicCode: string, month: string): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      let query = {
        urlParams: clinicCode + '/' + month
      };
      this.apiService.makeAPICall(actionCalculateBilling, query).subscribe((data) => {
        console.log("Billing Calculated %o", data);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.calculateBilling %o", err);
        observer.error(err);
      });
    });
  }

  calculateBillingDoctor(clinicCode: string, idfatt: number, month: string): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      let query = {
        urlParams: clinicCode + '/' + idfatt + '/' + month
      };
      this.apiService.makeAPICall(actionCalculateBillingDoctor, query).subscribe((data) => {
        console.log("Billing Doctor Calculated %o", data);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.calculateBilling %o", err);
        observer.error(err);
      });
    });
  }

  checkPerson(person: GceInvoicePerson): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      let query = {
        urlParams: `${person.idfatturazione}`
      };
      this.apiService.makeAPICall(actionCheckPerson, query).subscribe((data) => {
        console.log("Person Checked %o", data);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.checkPerson %o", err);
        observer.error(err);
      });
    });
  }

  loadDataForAffittuari(from?: string, to?: string, role?: string, dm?: string): Observable<{ clinics: GceClinic[], mails: AffittuariMailMensile[] }> {

    if (from) {
      from = moment(from).format('YYYYMMDD');
    } else {
      from = moment().startOf('month').format('YYYYMMDD');
    }
    if (to) {
      to = moment(to).format('YYYYMMDD');
    } else {
      to = moment().format('YYYYMMDD');
    }
    console.log('DM for dataAffittuari %o', dm);
    let dmf = dm.substr(0, 3);

    let response1 = this.http.get(`${environment.gceAPI}/fmc_cliniche/`, { responseType: 'json' });
    let payload = {
      ruolo: role,
      idDomain: dm,
      sub: '',
      cod_clinica: dmf,
      from: from,
      to: to
    };
    console.log('load data affittuari payload %o', payload);
    let response2 = this.http.post(`${environment.gceAPI}/affittuari_mail_mensili_filtered/`, payload, { responseType: 'json' });

    return Observable.create(observer => {
      Observable.forkJoin([response1, response2]).subscribe(dataResult => {
        console.log('response mail %o', dataResult[1]);
        this.dataRepoClinics = <GceClinic[]>dataResult[0];

        let mails: AffittuariMailMensile[] = <AffittuariMailMensile[]>dataResult[1];
        mails.forEach(m => {

          m.clinica = m.cod_clinica + ' ' + this.getClinicByCode(m.cod_clinica).nome.trim();
        });
        console.log('RET OK %o %o', this.dataRepoClinics, mails);
        observer.next({ clinics: this.dataRepoClinics, mails: mails });
      }, err => {
        console.log('RET KO %o', err);
        observer.error(err);
      });
    });
  }

  updateMail(mail: any, clinica: any): Observable<Object> {
    console.log('updateMail %o %o', mail, clinica);
    let req = {
      email: mail,
      cod_clinica: clinica
    };

    return this.http.post(`${environment.gceAPI}/affittuari_mail_mensili_update/`, req, { responseType: 'json' });

  }

  sendEmailForAffittuari(from?: string, to?: string): Observable<{ mails: AffittuariMailMensile[] }> {
    console.log('send Email affittuari from %o to %o', from, to);
    if (from) {
      from = moment(from).format('YYYYMMDD');
    } else {
      from = moment().startOf('month').format('YYYYMMDD');
    }
    if (to) {
      to = moment(to).format('YYYYMMDD');
    } else {
      to = moment().format('YYYYMMDD');
    }

    let response1 = this.http.post(`${environment.gceAPI}/affittuari_mail_mensili_send_from_to/`, { from: from, to: to, desc_domain: '' }, { responseType: 'json' });

    //let response1 = this.http.post(`http://localhost:57526/api/affittuari_mail_mensili_send_from_to/`, { from: from, to: to, desc_domain: '' }, { responseType: 'json' });

    return Observable.create(observer => {
      Observable.forkJoin([response1]).subscribe(dataResult => {
        console.log('response mail %o', dataResult[0]);
        let mails: AffittuariMailMensile[] = <AffittuariMailMensile[]>dataResult[0];
        mails.forEach(m => {
          m.clinica = m.cod_clinica + ' ' + this.getClinicByCode(m.cod_clinica).nome.trim();
        });
        console.log('RET OK %o ', mails);
        observer.next({ mails: mails });
      }, err => {
        console.log('RET KO %o', err);
        observer.error(err);
      });
    });
  }



  loadDataForInvoices(from?: string, to?: string): Observable<{ clinics: GceClinic[], persons: GceInvoicePerson[], invoices: GceInvoice[] }> {
    if (from) {
      from = moment(from).format('YYYYMMDD');
    } else {
      from = moment().startOf('month').format('YYYYMMDD');
    }
    if (to) {
      to = moment(to).format('YYYYMMDD');
    } else {
      to = moment().format('YYYYMMDD');
    }

    return Observable.create(observer => {
      let response1 = this.http.get(`${environment.gceAPI}/fmc_cliniche/`, { responseType: 'json' });
      let response2 = this.http.get(`${environment.gceAPI}/fmc_medici_fatturazione?desc_domain=${encodeURIComponent(this.dataRepo.user.desc_domain)}`, { responseType: 'json' });
      let response3 = this.http.post(`${environment.gceAPI}/fmc_fatturazione_mese_from_to_generic/`, { from: from, to: to, desc_domain: this.dataRepo.user.desc_domain }, { responseType: 'json' });

      Observable.forkJoin([response1, response2, response3]).subscribe(dataResult => {
        this.dataRepoClinics = <GceClinic[]>dataResult[0];
        this.dataRepoInvoicePersons = <GceInvoicePerson[]>((<any[]>dataResult[1]).filter(data => !!data));
        let invoices: GceInvoice[] = <GceInvoice[]>dataResult[2];
        invoices.forEach(invoice => {
          invoice.person = this.getInvoicePersonById(invoice.idfatturazione);
          invoice.clinic = this.getClinicByCode(invoice.cod_clinica);
        });
        observer.next({ clinics: this.dataRepoClinics, persons: this.dataRepoInvoicePersons, invoices: invoices });
      }, err => {
        observer.error(err);
      });
    });
  }

  getDetractsGeneric(from?: string, to?: string): Observable<Object> {
    if (from) {
      from = moment(from).format('YYYYMMDD');
    } else {
      from = moment().startOf('month').format('YYYYMMDD');
    }
    if (to) {
      to = moment(to).format('YYYYMMDD');
    } else {
      to = moment().format('YYYYMMDD');
    }
    return this.http.post(`${environment.gceAPI}/fmc_addebiti_laboratorio_riconciliation/`, { from: from, to: to, desc_domain: this.dataRepo.user.desc_domain }, { responseType: 'json' });
  }

  getLookUpAndOptions(data: any[], reference: any): GceLookUpOptions {
    let lookUp: {} = {};
    let options = data.map(item => {
      let values = reference['value'].map(v => {
        return item[v];
      }).join(' - ');

      lookUp[item[reference['keyColumn']]] = `${item[reference['keyColumn']]} - ${values}`;

      return {
        key: item[reference['keyColumn']],
        value: `${item[reference['keyColumn']]} - ${values}`
      };
    });

    //console.log("getLookUpAndOptions lookUp %o options %o", lookUp, options);

    return {
      lookUp,
      options
    }
  }

  getAllMedicTypes(): Observable<GceMedicType[]> {
    return Observable.create((observer: Observer<GceMedicType[]>) => {
      this.apiService.makeAPICall(actionGetMedicType).subscribe((data) => {
        observer.next(data);
      }, err => {
        console.log("GceManagementService.getAllMedicTypes %o", err);
        observer.error(err);
      });
    });
  }

  getMedicType(tipo_medico: number): Observable<GceMedicType> {
    return Observable.create((observer: Observer<GceMedicType>) => {
      this.apiService.makeAPICall(actionGetAnagraphic, { urlParams: tipo_medico }).subscribe((data) => {
        observer.next(data);
      }, err => {
        console.log("GceManagementService.getMedicType %o ", err);
        observer.error(err);
      })
    });
  }

  //lo prende dal data repository
  getMedicTypeById(tipo_medico: number): GceMedicType {
    return this.dataRepoMedicTypes.find((drmt: GceMedicType) => drmt.tipo_medico === tipo_medico);
  }

  insertMedicType(person: GceMedicType): Observable<GceMedicType> {
    return Observable.create((observer: Observer<GceMedicType>) => {
      this.apiService.makeAPICall(actionInsertMedicType, person).subscribe((data) => {
        console.log("MedicType inserted: %o", person);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.insertMedicType %o ", err);
        observer.error(err);
      })
    });
  }

  updateMedicType(medicType: GceMedicType): Observable<GceMedicType> {
    return Observable.create((observer: Observer<GceMedicType>) => {
      let query = {
        urlParams: medicType.tipo_medico,
        content: medicType
      };
      this.apiService.makeAPICall(actionUpdateMedicType, query).subscribe((data) => {
        console.log("MedicType updated %o", data);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.updateMedicType %o ", err);
        observer.error(err);
      });
    });
  }

  deleteMedicType(tipo_medico: number): Observable<GceMedicType> {
    return Observable.create((observer: Observer<GceMedicType>) => {
      this.apiService.makeAPICall(actionDeleteMedicType, { urlParams: tipo_medico }).subscribe((data) => {
        console.log("MedicType deleted %o", data);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.deleteMedicType %o ", err);
        observer.error(err);
      })
    });
  }

  getPerformanceCalculationDetailGeneric(idfatturazione: number, mese: Date | string, cod_clinica: string): any {

    return Observable.create(observer => {
      let query = {
        idfatturazione: idfatturazione,
        mese: mese,
        cod_clinica: cod_clinica
      };

      let response1 = this.http.post(actionGetPerformanceCalculationDetailGeneric, query);
      let response2 = this.http.post(actionGetPerformanceCalculationDetractsGeneric, query);
      let response3 = this.http.post(actionGetPerformanceCalculationDetractsGenericAll, query);

      Observable.forkJoin([response1, response2, response3]).subscribe(dataResult => {
        let ret = {};
        ret['details'] = dataResult[0];
        ret['detracts'] = dataResult[1];
        ret['detractsall'] = dataResult[2];
        console.log("GceManagementService.getPerformanceCalculationDetailGeneric from %o to %o", dataResult, ret);
        observer.next(ret);
      }, err => {
        observer.error(err);
      });
    });

    /*
    return Observable.create((observer: Observer<GcePerformanceCalculationDetail[]>) => {
      let query = {
        idfatturazione: idfatturazione,
        mese: mese,
        cod_clinica: cod_clinica
      };
      this.apiService.makeAPICall(actionGetPerformanceCalculationDetailGeneric, query).subscribe((data) => {
        console.log("GceManagementService.getPerformanceCalculationDetailGeneric %o", data);
        observer.next(data);
      }, err => {
        console.log("GceManagementService.getPerformanceCalculationDetailGeneric %o ", err);
        observer.error(err);
      })
    });
    */
  }

  exportPerformanceCalculationDetailToExcel(invoice: GceInvoice,
    pcds: GcePerformanceCalculationDetail[],
    dtrcs: GcePerformanceCalculationDetracts[],
    t1: number,
    t2: number,
    t3: number,
    t4: number,
    t5: number,
    t6: number,
    t7: number,
    t11_1: number,
    t11_2: number,
    t11_3: number,
    t12_1: number,
    t12_2: number,
    t12_3: number,
    t13_t: number,
    t14_1: number,
    t14_2: number,
    t14_3: number,
    t15_1: number,
    t15_2: number,
    t15_3: number,
    t16_1: number,
    t16_2: number,
    t16_3: number,
    t17_1: number,
    t17_2: number,
    t17_3: number,
    t18_1: number,
    t18_2: number,
    t18_3: number,
    t18A_2: number,
    t18A_3: number,
    t19_1: number,
    t19_2: number,
    t19_3: number,
    t110_t: number,
    t20_3: number,
    t21_3: number
  ) {
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const fmt = "##,##,##0.00 €";
    let totalPrestBase: number = 0;
    let totalCompenso: number = 0;

    let fileName = `reportCompleto_${invoice.person.descrizione}-${moment(invoice.mese).format('MMMMYY')}-${invoice.clinic.nome}`;
    console.log('FILENAME Components name %o clinic %o', invoice.person.descrizione, invoice.clinic.nome);
    console.log('export N with %o %o %o', t11_1, t11_2, t11_3);
    /*
    if (fileName.length > 20) {
      let name = invoice.person.descrizione.split(' ');
      fileName = `${name[0]}-${moment(invoice.ts_calcolo).format('MMMMYY')}-${invoice.clinic.nome}`;
    }
    */
    //TABELLA
    //let fisso = pcds.filter(pcd=>!!pcd.fisso_medico)[0].fisso_medico;
    let workDays = Array.from(new Set(pcds.map(pcd => pcd.data_produzione))).length;
    console.log(workDays);

    let wsTable: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([[`DR. ${invoice.person.descrizione} - ${moment(invoice.mese).format('MMMMYY')} - ${invoice.clinic.nome}`]]);
    let totPs = totalPrestBase > 0 ? Intl.NumberFormat('it-IT', { style: "currency", currency: "EUR" }).format(totalPrestBase) : '0,00€';
    let totC = totalCompenso > 0 ? Intl.NumberFormat('it-IT', { style: "currency", currency: "EUR" }).format(totalCompenso) : '0,00€';

    wsTable['A3'] = { t: 's', v: this._translate.instant('GCE.GCEINVOICE.TITOLO') };
    wsTable['B3'] = { t: 's', v: this._translate.instant('GCE.GCEINVOICE.VALORE') };
    wsTable['A5'] = { t: 's', v: this._translate.instant('GCE.REPORT.T1') };
    if (!(t1 == null))
      wsTable['B5'] = { t: 'n', v: t1 };
    wsTable['A6'] = { t: 's', v: this._translate.instant('GCE.REPORT.T2') };
    if (!(t2 == null))
      wsTable['B6'] = { t: 'n', v: t2 };
    if (invoice.flg_gettone == true) {
      wsTable['A7'] = { t: 's', v: this._translate.instant('GCE.REPORT.T3') };
      if (!(t3 == null))
        wsTable['B7'] = { t: 'n', v: t3 };
    }
    /*
  wsTable['A8'] = { t: 's', v: this._translate.instant('GCE.REPORT.T4') };
  if (!(t4 == null))
    wsTable['B8'] = { t: 'n', v: t4, z: fmt };
  */
    wsTable['A9'] = { t: 's', v: this._translate.instant('GCE.REPORT.T5') };
    if (!(t5 == null))
      wsTable['B9'] = { t: 'n', v: t5, z: fmt };
    wsTable['A10'] = { t: 's', v: this._translate.instant('GCE.REPORT.T6') };
    if (!(t6 == null))
      wsTable['B10'] = { t: 'n', v: t6, z: fmt };
    wsTable['A11'] = { t: 's', v: this._translate.instant('GCE.REPORT.T7') };
    if (!(t7 == null))
      wsTable['B11'] = { t: 'n', v: t7, z: fmt };

    wsTable['A14'] = { t: 's', v: this._translate.instant('GCE.GCEINVOICE.COMPENSO') };
    wsTable['B14'] = { t: 's', v: this._translate.instant('GCE.GCEINVOICE.AUTOMATICO') };
    wsTable['C14'] = { t: 's', v: this._translate.instant('GCE.GCEINVOICE.CLINICA') };
    wsTable['D14'] = { t: 's', v: this._translate.instant('GCE.GCEINVOICE.HR') };

    wsTable['A16'] = { t: 's', v: this._translate.instant('GCE.REPORT.T11') };
    if (!(t11_1 == null))
      wsTable['B16'] = { t: 'n', v: t11_1, z: fmt };
    if (!(t11_2 == null))
      wsTable['C16'] = { t: 'n', v: t11_2, z: fmt };
    if (!(t11_3 == null))
      wsTable['D16'] = { t: 'n', v: t11_3, z: fmt };

    wsTable['A17'] = { t: 's', v: this._translate.instant('GCE.REPORT.T12') };
    if (!(t12_1 == null))
      wsTable['B17'] = { t: 'n', v: t12_1, z: fmt };
    if (!(t12_2 == null))
      wsTable['C17'] = { t: 'n', v: t12_2, z: fmt };
    if (!(t12_3 == null))
      wsTable['D17'] = { t: 'n', v: t12_3, z: fmt };

    wsTable['A19'] = { t: 's', v: this._translate.instant('GCE.REPORT.T13') };

    wsTable['E19'] = { t: 'n', v: t13_t, z: fmt };

    if (invoice.flg_gettone == true) {
      wsTable['A20'] = { t: 's', v: this._translate.instant('GCE.REPORT.T14') };
      if (!(t14_1 == null))
        wsTable['B20'] = { t: 'n', v: t14_1, z: fmt };
      if (!(t14_2 == null))
        wsTable['C20'] = { t: 'n', v: t14_2, z: fmt };
      if (!(t14_3 == null))
        wsTable['D20'] = { t: 'n', v: t14_3, z: fmt };
    }

    wsTable['A21'] = { t: 's', v: this._translate.instant('GCE.REPORT.T15') };
    if (!(t15_1 == null))
      wsTable['B21'] = { t: 'n', v: t15_1, z: fmt };
    if (!(t15_2 == null))
      wsTable['C21'] = { t: 'n', v: t15_2, z: fmt };
    if (!(t15_3 == null))
      wsTable['D21'] = { t: 'n', v: t15_3, z: fmt };

    wsTable['A22'] = { t: 's', v: this._translate.instant('GCE.REPORT.T16') };
    if (!(t16_1 == null))
      wsTable['B22'] = { t: 'n', v: t16_1, z: fmt };
    if (!(t16_2 == null))
      wsTable['C22'] = { t: 'n', v: t16_2, z: fmt };
    if (!(t16_3 == null))
      wsTable['D22'] = { t: 'n', v: t16_3, z: fmt };
    /*
        wsTable['A23'] = { t: 's', v: this._translate.instant('GCE.REPORT.T17') };
        if (!(t17_1 == null))
          wsTable['B23'] = { t: 'n', v: t17_1, z: fmt };
        if (!(t17_2 == null))
          wsTable['C23'] = { t: 'n', v: t17_2, z: fmt };
        if (!(t17_3 == null))
          wsTable['D23'] = { t: 'n', v: t17_3, z: fmt };
    */
    wsTable['A24'] = { t: 's', v: this._translate.instant('GCE.REPORT.T18') };
    if (!(t18_1 == null))
      wsTable['B24'] = { t: 'n', v: t18_1, z: fmt };
    if (!(t18_2 == null))
      wsTable['C24'] = { t: 'n', v: t18_2, z: fmt };
    if (!(t18_3 == null))
      wsTable['D24'] = { t: 'n', v: t18_3, z: fmt };

    wsTable['A25'] = { t: 's', v: this._translate.instant('GCE.REPORT.T18A') };

    if (!(t18A_2 == null))
      wsTable['C25'] = { t: 'n', v: t18A_2, z: fmt };
    if (!(t18A_3 == null))
      wsTable['D25'] = { t: 'n', v: t18A_3, z: fmt };

    wsTable['A26'] = { t: 's', v: this._translate.instant('GCE.REPORT.T19') };
    if (!(t19_1 == null))
      wsTable['B26'] = { t: 'n', v: t19_1, z: fmt };
    if (!(t19_2 == null))
      wsTable['C26'] = { t: 'n', v: t19_2, z: fmt };
    if (!(t19_3 == null))
      wsTable['D26'] = { t: 'n', v: t19_3, z: fmt };

    if (t20_3 > 0 || t20_3 < 0) {
      wsTable['A27'] = { t: 's', v: this._translate.instant('GCE.GCEINVOICE.IMPORTO_RIPORTO_MESE_PREC') };
      wsTable['D27'] = { t: 'n', v: t20_3, z: fmt };

    }

    if (t21_3 > 0 || t21_3 < 0) {
      wsTable['A28'] = { t: 's', v: this._translate.instant('GCE.GCEINVOICE.IMPORTO_RIPORTO_MESE_SUCC') };
      wsTable['D28'] = { t: 'n', v: t21_3, z: fmt };

    }

    wsTable['A29'] = { t: 's', v: this._translate.instant('GCE.REPORT.T110') };
    if (!(t110_t == null))
      wsTable['E29'] = { t: 'n', v: t110_t, z: fmt };

    wsTable['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: 40, c: 10 } });

    XLSX.utils.book_append_sheet(wb, wsTable, 'Tabella Riassuntiva');

    let detailsForExcel: any[] = pcds.map((pcd: GcePerformanceCalculationDetail) => {
      totalPrestBase += pcd.importo_prest_base_calc_medico > 0 ? pcd.importo_prest_base_calc_medico : 0;
      totalCompenso += pcd.compenso_medico > 0 ? pcd.compenso_medico : 0;

      let ret = {
        // 'Data' : moment(pcd.data_produzione).format('DD/MM/YYYY'),
        // "Nominativo": pcd.codpaziente,
        // "Prestazione": pcd.descrizione_prestazione,
        // "Listino": "Listino",
        // "Dente": pcd.dente ? `${pcd.dente}` : '*',
        // "Genere": pcd.descrizione_genere,
        // "Operatore": invoice.person.descrizione,
        // "Imp. Scontato": pcd.importo_prest_base_calc_medico ? pcd.importo_prest_base_calc_medico : 0,
        // "%": pcd.perc_medico ,
        // "Da Liquidare": pcd.compenso_medico,
        'ID FATTURA': pcd.idfatturazione,
        'DATA': moment(pcd.mese).format('MM/YYYY'),
        'CODICE CLINICA': pcd.cod_clinica,
        'ID CLINICA': pcd.id_clinica,
        'OPERATORE': pcd.operatore,
        'CODICE PROFILO': pcd.cod_profilo,
        'FLAG CALCOLO': pcd.flg_calcolo,
        'DATA PRODUZIONE': moment(pcd.data_produzione).format('DD/MM/YYYY'),
        'CODICE GENERE CALCOLO': pcd.codgenere_calcolo,
        'DESCRIZIONE GENERE': pcd.descrizione_genere,
        'CODICE PRESTAZIONE': pcd.codprestazione,
        'DESCRIZIONE PRESTAZIONE': pcd.descrizione_prestazione,
        'DENTE': pcd.dente ? `${pcd.dente}` : '*',
        'TIPO CALCOLO': pcd.tipo_calcolo,
        'COMPENSO FISSO MEDICO': pcd.fisso_medico,
        'PAZIENTE': pcd.codpaziente,
        'NUMERO PREVENTIVO': pcd.num_preventivo,
        'RIDUZIONE RIFACIMENTO': pcd.riduzione_rifacimento,
        // 'IMPORTO PRODUZIONE': pcd.importo_produzione > 0 ? Intl.NumberFormat('it-IT', { style: "currency", currency: "EUR" }).format(pcd.importo_produzione) : '0,00€',
        //'IMPORTO PRODUZIONE': pcd.importo_produzione,
        'IMPORTO PRODUZIONE NETTO': pcd.importo_prest_base_calc_medico_nettofin,
        // 'IMPORTO PRESTAZIONE BASE': pcd.importo_prest_base_calc_medico > 0 ? Intl.NumberFormat('it-IT', { style: "currency", currency: "EUR" }).format(pcd.importo_prest_base_calc_medico) : '0,00€',
        //'IMPORTO PRESTAZIONE BASE': pcd.importo_prest_base_calc_medico,
        'PERCENTUALE MEDICO': pcd.perc_medico,
        //'COMPENSO MEDICO': pcd.compenso_medico > 0 ? Intl.NumberFormat('it-IT', { style: "currency", currency: "EUR" }).format(pcd.compenso_medico) : '0,00€',
        'COMPENSO MEDICO': pcd.compenso_medico,
        'NUMERO PRESTAZIONE': pcd.n_prestazioni,
        'CODICE FASE': pcd.codfase,
        'CODICE PREVIDENZA': pcd.codprev,
        'CODICE PDC': pcd.codpdc,
        'NUMERO FASI': pcd.n_fasi,
      };
      return ret;
    });

    console.log('TOTAL ' + Intl.NumberFormat('it-IT', { style: "currency", currency: "EUR" }).format(totalPrestBase));
    const wsDetail = XLSX.utils.json_to_sheet(detailsForExcel);

    /*
    let rows = detailsForExcel.length;
    
    let cellT = XLSX.utils.encode_cell({ r: rows + 2, c: 19 });
    wsDetail[cellT] = { t: 'n', f: `SUM(T2:T${rows + 1})` };

    let cellV = XLSX.utils.encode_cell({ r: rows + 2, c: 21 });
    wsDetail[cellV] = { t: 'n', f: `SUM(V2:V${rows + 1})` };

    wsDetail['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: rows + 30, c: 26 } });
    */

    XLSX.utils.book_append_sheet(wb, wsDetail, "Dettaglio Compensi");

    let detractsForExcel: any[] = dtrcs.map((dtrc: GcePerformanceCalculationDetracts) => {

      let ret = {
        'DATA CARICO': moment(dtrc.data_carico).format('DD/MM/YYYY'),
        'DATA ORDINE EVOLUTION': moment(dtrc.data_ordine_evolution).format('DD/MM/YYYY'),
        'PAZIENTE': dtrc.idpaziente,
        'IMPORTO ORDINE': dtrc.importo_ordine,
        'IMPORTO ADDEBITO MEDICO': dtrc.importo_addebito_medico,
        'PERCENTUALE ADDEBITO MEDICO': dtrc.perc_medico,
        'NUMERO ORDINE EVOLUTION': dtrc.num_ordine_evolution,
        'RIFERIMENTO ORDINE': dtrc.rif_ordine,
        'NUMERO PRESCRIZIONE': dtrc.num_prescrizione,
        'MEDICO PRESCRIZIONE': dtrc.medico_prescrizione,
        'ARTICOLO EVOLUTION': dtrc.des_articolo_evolution,
        'NOME MEDICO': dtrc.nome_medico,
        'ERRORE PRESCRIZIONE': dtrc.errore_prescrizione,
        'ESITO CALCOLO': dtrc.flg_riga_valida,
        'QT. ORDINE': dtrc.qta_ordine
      };
      return ret;
    });

    const wsDetracts = XLSX.utils.json_to_sheet(detractsForExcel);

    XLSX.utils.book_append_sheet(wb, wsDetracts, "Dettaglio Detrazioni");

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //save file
    const data: Blob = new Blob([wbout], { type: EXCEL_TYPE });
    //let fileName = `Dott. ${invoice.person.descrizione} - ${moment(invoice.ts_calcolo).format('MMMMYY')} -  ${invoice.clinic.nome}`;

    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  exportPerformanceCalculationDetailDefToExcel(invoice: GceInvoice,
    pcds: GcePerformanceCalculationDetail[],
    dtrcs: GcePerformanceCalculationDetracts[],
    t1: number,
    t2: number,
    t3: number,
    t4: number,
    t5: number,
    t6: number,
    t7: number,
    t11_1: number,
    t11_2: number,
    t11_3: number,
    t12_1: number,
    t12_2: number,
    t12_3: number,
    t13_t: number,
    t14_1: number,
    t14_2: number,
    t14_3: number,
    t15_1: number,
    t15_2: number,
    t15_3: number,
    t16_1: number,
    t16_2: number,
    t16_3: number,
    t17_1: number,
    t17_2: number,
    t17_3: number,
    t18_1: number,
    t18_2: number,
    t18_3: number,
    t18A_2: number,
    t18A_3: number,
    t19_1: number,
    t19_2: number,
    t19_3: number,
    t110_t: number,
    t20_3: number,
    t21_3: number,
    toFile: boolean
  ) {
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const fmt = "##,##,##0.00 €";
    let totalPrestBase: number = 0;
    let totalCompenso: number = 0;
    console.log('export invoice %o', invoice);
    let fileName = '';
    if (!(invoice.person == null) && !(invoice.person.descrizione == null) && invoice.person.descrizione.length > 0 && !(invoice.clinic == null) && !(invoice.clinic.nome == null) && invoice.clinic.nome.length > 0) {
      fileName = `reportMedico_${invoice.person.descrizione}-${moment(invoice.mese).format('MMMMYY')}-${invoice.clinic.nome}`;
      console.log('FILENAME Components invoice %o name %o clinic %o', invoice, invoice.person.descrizione, invoice.clinic.nome);
    }
    else {
      fileName = `reportMedico-${moment(invoice.mese).format('MMMMYY')}`;
    }
    console.log('export with %o %o %o', t11_1, t11_2, t11_3);
    /*
    if (fileName.length > 20) {
      let name = invoice.person.descrizione.split(' ');
      fileName = `${name[0]}-${moment(invoice.ts_calcolo).format('MMMMYY')}-${invoice.clinic.nome}`;
    }
    */
    //TABELLA
    //let fisso = pcds.filter(pcd=>!!pcd.fisso_medico)[0].fisso_medico;
    let workDays = Array.from(new Set(pcds.map(pcd => pcd.data_produzione))).length;
    console.log(workDays);

    let wsTable: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([[`DR. ${invoice.person.descrizione} - ${moment(invoice.mese).format('MMMMYY')} - ${invoice.clinic.nome}`]]);

    let totPs = totalPrestBase > 0 ? Intl.NumberFormat('it-IT', { style: "currency", currency: "EUR" }).format(totalPrestBase) : '0,00€';
    let totC = totalCompenso > 0 ? Intl.NumberFormat('it-IT', { style: "currency", currency: "EUR" }).format(totalCompenso) : '0,00€';

    wsTable['A3'] = { t: 's', v: this._translate.instant('GCE.GCEINVOICE.TITOLO') };
    wsTable['B3'] = { t: 's', v: this._translate.instant('GCE.GCEINVOICE.VALORE') };
    //wsTable['A5'] = { t: 's', v: this._translate.instant('GCE.REPORT.T1') };
    //wsTable['B5'] = { t: 'n', v: t1 };
    //wsTable['A6'] = { t: 's', v: this._translate.instant('GCE.REPORT.T2') };
    //wsTable['B6'] = { t: 'n', v: t2 };

    if (invoice.flg_gettone == true) {
      wsTable['A5'] = { t: 's', v: this._translate.instant('GCE.REPORT.T3') };
      if (!(t3 == null))
        wsTable['B5'] = { t: 'n', v: t3 };
    }


    //wsTable['A8'] = { t: 's', v: this._translate.instant('GCE.REPORT.T4') };
    //wsTable['B8'] = { t: 'n', v: t4, z: fmt };
    wsTable['A7'] = { t: 's', v: this._translate.instant('GCE.REPORT.T5') };
    if (!(t5 == null))
      wsTable['B7'] = { t: 'n', v: t5, z: fmt };
    wsTable['A8'] = { t: 's', v: this._translate.instant('GCE.REPORT.T6') };
    if (!(t6 == null))
      wsTable['B8'] = { t: 'n', v: t6, z: fmt };
    wsTable['A10'] = { t: 's', v: this._translate.instant('GCE.REPORT.T7') };
    if (!(t7 == null))
      wsTable['B10'] = { t: 'n', v: t7, z: fmt };

    wsTable['A12'] = { t: 's', v: this._translate.instant('GCE.GCEINVOICE.COMPENSO') };
    //wsTable['B12'] = { t: 's', v: this._translate.instant('GCE.GCEINVOICE.AUTOMATICO') };
    //wsTable['C12'] = { t: 's', v: this._translate.instant('GCE.GCEINVOICE.CLINICA') };
    wsTable['D12'] = { t: 's', v: this._translate.instant('GCE.GCEINVOICE.REVIEWHR') };
    wsTable['E12'] = { t: 's', v: this._translate.instant('GCE.GCEINVOICE.FATTURA') };

    let t11_4 = t11_1;
    let d1 = 0;
    if (t11_2 == 0 || t11_2 > 0 || t11_2 < 0) {
      t11_4 = t11_2;
    }
    if (t11_3 == 0 || t11_3 > 0 || t11_3 < 0) {
      t11_4 = t11_3;
      d1 = t11_3 - t11_1;
    }
    wsTable['A14'] = { t: 's', v: this._translate.instant('GCE.REPORT.T11') };
    //wsTable['B16'] = { t: 'n', v: t11_1, z: fmt };
    //wsTable['C16'] = { t: 'n', v: t11_2, z: fmt };
    //wsTable['D16'] = { t: 'n', v: t11_3, z: fmt };
    if (!(t11_4 == null))
      wsTable['C14'] = { t: 'n', v: t11_4, z: fmt };
    if (d1 > 0 || d1 < 0)
      wsTable['D14'] = { t: 'n', v: d1, z: fmt };

    let t12_4 = t12_1;
    let d2 = 0;
    if (t12_2 == 0 || t12_2 > 0 || t12_2 < 0)
      t12_4 = t12_2;
    if (t12_3 == 0 || t12_3 > 0 || t12_3 < 0) {
      t12_4 = t12_3;
      d2 = t12_3 - t12_1;
    }
    wsTable['A15'] = { t: 's', v: this._translate.instant('GCE.REPORT.T12') };
    //wsTable['B17'] = { t: 'n', v: t12_1, z: fmt };
    //wsTable['C17'] = { t: 'n', v: t12_2, z: fmt };
    //wsTable['D17'] = { t: 'n', v: t12_3, z: fmt };
    if (!(t12_4 == null))
      wsTable['C15'] = { t: 'n', v: t12_4, z: fmt };
    if (d2 > 0 || d2 < 0)
      wsTable['D15'] = { t: 'n', v: d2, z: fmt };

    wsTable['A17'] = { t: 's', v: this._translate.instant('GCE.REPORT.T13') };
    //wsTable['B19'] = { t: 'n', v: t13_1, z: fmt };
    //wsTable['C19'] = { t: 'n', v: t13_2, z: fmt };
    //wsTable['D19'] = { t: 'n', v: t13_3, z: fmt };
    if (!(t13_t == null))
      wsTable['E17'] = { t: 'n', v: t13_t, z: fmt };

    /*
    if (invoice.flg_gettone == true) {
    wsTable['A20'] = { t: 's', v: this._translate.instant('GCE.REPORT.T14') };
    if (!(t14_1 == null))
      wsTable['B20'] = { t: 'n', v: t14_1, z: fmt };
    if (!(t14_2 == null))
      wsTable['C20'] = { t: 'n', v: t14_2, z: fmt };
    if (!(t14_3 == null))
      wsTable['D20'] = { t: 'n', v: t14_3, z: fmt };
  }
    */
    if (invoice.flg_gettone == true) {
      wsTable['A18'] = { t: 's', v: this._translate.instant('GCE.REPORT.T14') };

      let t14_4 = t14_1;
      let d4 = 0;
      if (t14_2 == 0 || t14_2 > 0 || t14_2 < 0)
        t14_4 = t14_2;
      if (t14_3 == 0 || t14_3 > 0 || t14_3 < 0) {
        t14_4 = t14_3;
        d4 = t14_3 - t14_1;
      }

      if (!(t14_4 == null))
        wsTable['C18'] = { t: 'n', v: t14_4, z: fmt };
      if (d4 > 0 || d4 < 0)
        wsTable['D18'] = { t: 'n', v: d4, z: fmt };

      /*
      if (!(t14_1 == null))
         wsTable['B18'] = { t: 'n', v: t14_1, z: fmt };
       if (!(t14_2 == null))
         wsTable['C18'] = { t: 'n', v: t14_2, z: fmt };
       if (!(t14_3 == null))
         wsTable['D18'] = { t: 'n', v: t14_3, z: fmt };
     */
    }

    let t15_4 = t15_1;
    let d5 = 0;
    if (t15_2 == 0 || t15_2 > 0 || t15_2 < 0)
      t15_4 = t15_2;
    if (t15_3 == 0 || t15_3 > 0 || t15_3 < 0) {
      t15_4 = t15_3;
      d5 = t15_3 - t15_1;
    }
    wsTable['A19'] = { t: 's', v: this._translate.instant('GCE.REPORT.T15') };
    //wsTable['B21'] = { t: 'n', v: t15_1, z: fmt };
    //wsTable['C21'] = { t: 'n', v: t15_2, z: fmt };
    //wsTable['D21'] = { t: 'n', v: t15_3, z: fmt };
    if (!(t15_4 == null))
      wsTable['C19'] = { t: 'n', v: t15_4, z: fmt };
    if (d5 > 0 || d5 < 0)
      wsTable['D19'] = { t: 'n', v: d5, z: fmt };

    let t17_4 = t17_1;
    let d7 = 0;
    if (t17_2 == 0 || t17_2 > 0 || t17_2 < 0)
      t17_4 = t17_2;
    if (t17_3 == 0 || t17_3 > 0 || t17_3 < 0) {
      t17_4 = t17_3;
      d7 = t17_3 - t17_1;
    }
    // --> wsTable['A21'] = { t: 's', v: this._translate.instant('GCE.REPORT.T17') };

    /*
        if (!(t17_4 == null))
          wsTable['C21'] = { t: 'n', v: t17_4, z: fmt };
        if (d7 > 0 || d7 < 0)
          wsTable['D21'] = { t: 'n', v: d7, z: fmt };
    */
    let t16_4 = t16_1;
    let d6 = 0;
    if (t16_2 == 0 || t16_2 > 0 || t16_2 < 0)
      t16_4 = t16_2;
    if (t16_3 == 0 || t16_3 > 0 || t16_3 < 0) {
      t16_4 = t16_3;
      d6 = t16_3 - t16_1;
    }

    if (t17_1 < 0 || t17_1 > 0) {
      d6 = d6 - t17_1;
    }
    console.log('D6 %o D7 %o', d6, t16_1);
    wsTable['A20'] = { t: 's', v: this._translate.instant('GCE.REPORT.T16') };
    //wsTable['B22'] = { t: 'n', v: t16_1, z: fmt };
    //wsTable['C22'] = { t: 'n', v: t16_2, z: fmt };
    //wsTable['D22'] = { t: 'n', v: t16_3, z: fmt };
    if (!(t16_4 == null))
      wsTable['C20'] = { t: 'n', v: t16_4, z: fmt };
    if (d6 > 0 || d6 < 0)
      wsTable['D20'] = { t: 'n', v: d6, z: fmt };



    let t18_4 = t18_1;
    let d8 = 0;
    if (t18_2 == 0 || t18_2 > 0 || t18_2 < 0)
      t18_4 = t18_2;
    if (t18_3 == 0 || t18_3 > 0 || t18_3 < 0) {
      t18_4 = t18_3;
      d8 = t18_3 - t18_1;
    }
    wsTable['A22'] = { t: 's', v: this._translate.instant('GCE.REPORT.T18') };
    //wsTable['B24'] = { t: 'n', v: t18_1, z: fmt };
    //wsTable['C24'] = { t: 'n', v: t18_2, z: fmt };
    //wsTable['D24'] = { t: 'n', v: t18_3, z: fmt };
    if (!(t18_4 == null))
      wsTable['C22'] = { t: 'n', v: t18_4, z: fmt };
    if (d8 > 0 || d8 < 0)
      wsTable['D22'] = { t: 'n', v: d8, z: fmt };

    let t18A_4 = null;
    let d8A = null;
    if (t18A_2 == 0 || t18A_2 > 0 || t18A_2 < 0)
      t18A_4 = t18A_2;
    if (t18A_3 == 0 || t18A_3 > 0 || t18A_3 < 0) {
      t18A_4 = t18A_3;
    }
    wsTable['A23'] = { t: 's', v: this._translate.instant('GCE.REPORT.T18A') };
    //wsTable['B24'] = { t: 'n', v: t18_1, z: fmt };
    //wsTable['C24'] = { t: 'n', v: t18_2, z: fmt };
    //wsTable['D24'] = { t: 'n', v: t18_3, z: fmt };
    if (!(t18A_4 == null))
      wsTable['C23'] = { t: 'n', v: t18A_4, z: fmt };
    if (d8A > 0 || d8A < 0)
      wsTable['D23'] = { t: 'n', v: d8A, z: fmt };

    let t19_4 = t19_1;
    let d9 = 0;
    if (t19_2 == 0 || t19_2 > 0 || t19_2 < 0)
      t19_4 = t19_2;
    if (t19_3 == 0 || t19_3 > 0 || t19_3 < 0) {
      t19_4 = t19_3;
      d9 = t19_3 - t19_1;
    }
    wsTable['A24'] = { t: 's', v: this._translate.instant('GCE.REPORT.T19') };
    //wsTable['B25'] = { t: 'n', v: t19_1, z: fmt };
    //wsTable['C25'] = { t: 'n', v: t19_2, z: fmt };
    //wsTable['D25'] = { t: 'n', v: t19_3, z: fmt };
    if (!(t19_4 == null))
      wsTable['C24'] = { t: 'n', v: t19_4, z: fmt };
    if (d9 > 0 || d9 < 0)
      wsTable['D24'] = { t: 'n', v: d9, z: fmt };

    wsTable['A27'] = { t: 's', v: this._translate.instant('GCE.REPORT.T110') };
    //wsTable['B26'] = { t: 'n', v: t110_1, z: fmt };
    //wsTable['C26'] = { t: 'n', v: t110_2, z: fmt };
    //wsTable['D26'] = { t: 'n', v: t110_3, z: fmt };
    if (!(t110_t == null))
      wsTable['E27'] = { t: 'n', v: t110_t, z: fmt };

    if (t20_3 > 0 || t20_3 < 0) {
      wsTable['A25'] = { t: 's', v: this._translate.instant('GCE.GCEINVOICE.IMPORTO_RIPORTO_MESE_PREC') };
      wsTable['D25'] = { t: 'n', v: t20_3, z: fmt };

    }

    if (t21_3 > 0 || t21_3 < 0) {
      wsTable['A26'] = { t: 's', v: this._translate.instant('GCE.GCEINVOICE.IMPORTO_RIPORTO_MESE_SUCC') };
      wsTable['D26'] = { t: 'n', v: t21_3, z: fmt };

    }

    wsTable['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: 40, c: 10 } });

    //note_hr
    if (invoice.note_hr != null && invoice.note_hr.length > 0) {
      wsTable['A29'] = { t: 's', v: this._translate.instant('GCE.REPORT.NOTE_HR') };
      wsTable['D29'] = { t: 's', v: invoice.note_hr };
    }

    XLSX.utils.book_append_sheet(wb, wsTable, 'Tabella Riassuntiva');
    if (pcds != null && pcds.length > 0) {
      let detailsForExcel: any[] = pcds.map((pcd: GcePerformanceCalculationDetail) => {
        totalPrestBase += pcd.importo_prest_base_calc_medico > 0 ? pcd.importo_prest_base_calc_medico : 0;
        totalCompenso += pcd.compenso_medico > 0 ? pcd.compenso_medico : 0;

        let ret = {
          'DATA PRODUZIONE': moment(pcd.data_produzione).format('DD/MM/YYYY'),
          'DESCRIZIONE GENERE': pcd.descrizione_genere,
          'DESCRIZIONE PRESTAZIONE': pcd.descrizione_prestazione,
          'DENTE': pcd.dente ? `${pcd.dente}` : '*',
          'PAZIENTE': pcd.codpaziente,
          //'IMPORTO PRESTAZIONE BASE': pcd.importo_prest_base_calc_medico,
          //'IMPORTO PRODUZIONE': pcd.importo_produzione,
          'IMPORTO PRODUZIONE NETTO': pcd.importo_prest_base_calc_medico_nettofin,
          'PERCENTUALE MEDICO': Math.round(pcd.perc_medico * 100) / 100,
          'COMPENSO MEDICO': Math.round(pcd.compenso_medico * 100) / 100
        };
        return ret;
      });

      console.log('TOTAL ' + Intl.NumberFormat('it-IT', { style: "currency", currency: "EUR" }).format(totalPrestBase));
      const wsDetail = XLSX.utils.json_to_sheet(detailsForExcel);

      XLSX.utils.book_append_sheet(wb, wsDetail, "Dettaglio Compensi");
    }
    if (dtrcs != null && dtrcs.length > 0) {
      let detractsForExcel: any[] = dtrcs.map((dtrc: GcePerformanceCalculationDetracts) => {

        let ret = {
          'DATA CARICO': moment(dtrc.data_carico).format('DD/MM/YYYY'),
          'DATA ORDINE EVOLUTION': moment(dtrc.data_ordine_evolution).format('DD/MM/YYYY'),
          'PAZIENTE': dtrc.idpaziente,
          'ARTICOLO EVOLUTION': dtrc.des_articolo_evolution,
          'IMPORTO ORDINE': Math.round(dtrc.importo_ordine * 100) / 100,
          'PERCENTUALE ADDEBITO MEDICO': Math.round(dtrc.perc_medico * 100) / 100,
          'IMPORTO ADDEBITO MEDICO': Math.round(dtrc.importo_addebito_medico * 100) / 100,
          'NUMERO ORDINE EVOLUTION': dtrc.num_ordine_evolution,
          'RIFERIMENTO ORDINE': dtrc.rif_ordine,
          'NUMERO PRESCRIZIONE': dtrc.num_prescrizione,
          'QT. ORDINE': dtrc.qta_ordine
        };
        return ret;
      });

      const wsDetracts = XLSX.utils.json_to_sheet(detractsForExcel);

      XLSX.utils.book_append_sheet(wb, wsDetracts, "Dettaglio Detrazioni");
    }
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //save file
    const data: Blob = new Blob([wbout], { type: EXCEL_TYPE });
    //let fileName = `Dott. ${invoice.person.descrizione} - ${moment(invoice.ts_calcolo).format('MMMMYY')} -  ${invoice.clinic.nome}`;
    if (toFile == true)
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    else
      return (data);
  }

  getDetracts(dt: any, detracts: any) {
    console.log('getDetracts dt %o detracts %o', dt, detracts);
    return 10;
  }

  simpleExportInvoiceDetailToExcel(inv: any, details: any, detracts: any) {

    //console.log('simpleExportInvoiceDetailToExcel for inv %o details %o', inv, details);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    let fileName = `reportPrestazioni_${inv.person.descrizione}-${moment(inv.mese).format('MMMMYY')}-${inv.clinic.nome}`;

    let detailForExcel: any[] = details.map((bdg: GcePerformanceCalculationDetail) => {

      let ret = {
        'DATA': moment(bdg.data_produzione).format('DD/MM/YYYY'),
        'PAZIENTE': bdg.codpaziente,
        'DESCRIZIONE PRESTAZIONE': bdg.descrizione_prestazione,
        'DENTE': bdg.dente,
        'GENERE': bdg.descrizione_genere,
        'OPERATORE': inv.person.descrizione,
        'IMPORTO': Math.round(bdg.importo_prest_base_calc_medico_nettofin * 100) / 100,
        'PERCENTUALE': bdg.perc_medico,
        'COMPENSO': Math.round(bdg.compenso_medico * 100) / 100,
        //'Costi Lab.': 0//this.getDetracts(bdg,detracts)
      };
      return ret;
    });

    const wsDetracts = XLSX.utils.json_to_sheet(detailForExcel);

    XLSX.utils.book_append_sheet(wb, wsDetracts, "Dettaglio Prestazioni");

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //save file
    const data: Blob = new Blob([wbout], { type: EXCEL_TYPE });
    //let fileName = `Dott. ${invoice.person.descrizione} - ${moment(invoice.ts_calcolo).format('MMMMYY')} -  ${invoice.clinic.nome}`;

    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  exportBadgeToExcel(badge: any) {
    console.log('PRESENCES for excel %o', badge);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    let fileName = `export_presenze`;

    let badgeForExcel: any[] = badge.map((bdg: GcePresence) => {

      let ret = {
        'ID MEDICO': bdg.idfatturazione,
        'MEDICO': bdg.person.descrizione,
        'CLINICA': bdg.cod_clinica + ' ' + bdg.clinic.nome,
        'DATA': bdg.data,
        'DISPONIBIL. DALLE': bdg.disponibilita_dalle,
        'DISPONIBIL. ALLE': bdg.disponibilita_alle,
        'ORE DISPONIBIL.': bdg.ore_disponibilita,
        'APPUNTAMENTI': bdg.appuntamenti,
        'ORE APPUNTAMENTI': Math.round(bdg.ore_appuntamenti * 100) / 100,
        'PRESENZA CALCOLATA': bdg.presenza_calc,
        'PRESENZA HR': bdg.presenza_forzata_hr,
        'NOTE': bdg.note,
      };
      return ret;
    });

    const wsDetracts = XLSX.utils.json_to_sheet(badgeForExcel);

    XLSX.utils.book_append_sheet(wb, wsDetracts, "Dettaglio Presenze");

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //save file
    const data: Blob = new Blob([wbout], { type: EXCEL_TYPE });
    //let fileName = `Dott. ${invoice.person.descrizione} - ${moment(invoice.ts_calcolo).format('MMMMYY')} -  ${invoice.clinic.nome}`;

    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  exportAllToExcel(invoices: any) {
    console.log('INVOICES for excel %o', invoices);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    let fileName = `liquidazioni_medici_${moment(invoices[0].mese).format('MMMMYY')}`;

    let badgeForExcel: any[] = [];
    for (let a = 0; a < invoices.length; a++) {
      let dt = '';
      if (invoices[a].flg_doc_emesso == true)
        dt = moment(invoices[a].ts_doc_emesso).format('DD-MM-YYYY HH:mm:ss');
      if (invoices[a].importo_fattura_prestazioni > 0) {
        let ret = {
          'ID': invoices[a].id,
          'CODICE CLINICA': invoices[a].cod_clinica,
          'CLINICA': invoices[a].cod_clinica + ' ' + invoices[a].clinic.nome,
          'SOCIETA': invoices[a].clinic.legalentity,
          'COMPANY NAVISION': invoices[a].clinic.company_navision,
          'FORNITORE NAVISION': invoices[a].person.fornitore_nav,
          'MEDICO': invoices[a].person.medico_portale,
          'PRESTAZIONI': Math.round(invoices[a].importo_fattura_prestazioni * 100) / 100,
          'DIREZIONE SANITARIA': 0,
          'DIREZIONE MEDICO SCIENTIFICA DI AREA': 0,
          'REGIME IVA': invoices[a].person.regime_portale,
          'DOCUMENTO EMESSO': invoices[a].flg_doc_emesso,
          'DATA EMISSIONE': dt,
        };
        badgeForExcel.push(ret);
      }
      if (invoices[a].importo_fattura_direzione_sanitaria > 0) {
        let ret = {
          'ID': invoices[a].id,
          'CODICE CLINICA': invoices[a].cod_clinica,
          'CLINICA': invoices[a].cod_clinica + ' ' + invoices[a].clinic.nome,
          'SOCIETA': invoices[a].clinic.legalentity,
          'COMPANY NAVISION': invoices[a].clinic.company_navision,
          'FORNITORE NAVISION': invoices[a].person.fornitore_nav,
          'MEDICO': invoices[a].person.descrizione,
          'PRESTAZIONI': 0,
          'DIREZIONE SANITARIA': Math.round(invoices[a].importo_fattura_direzione_sanitaria * 100) / 100,
          'DIREZIONE MEDICO SCIENTIFICA DI AREA': 0,
          'REGIME IVA': invoices[a].person.regime_portale,
          'DOCUMENTO EMESSO': invoices[a].flg_doc_emesso,
          'DATA EMISSIONE': dt,
        };
        badgeForExcel.push(ret);
      }
      if (invoices[a].importo_fattura_direzione_area_clinica > 0) {
        let ret = {
          'ID': invoices[a].id,
          'CODICE CLINICA': invoices[a].cod_clinica,
          'CLINICA': invoices[a].cod_clinica + ' ' + invoices[a].clinic.nome,
          'SOCIETA': invoices[a].clinic.legalentity,
          'COMPANY NAVISION': invoices[a].clinic.company_navision,
          'FORNITORE NAVISION': invoices[a].person.fornitore_nav,
          'MEDICO': invoices[a].person.medico_portale,
          'PRESTAZIONI': 0,
          'DIREZIONE SANITARIA': 0,
          'DIREZIONE MEDICO SCIENTIFICA DI AREA': Math.round(invoices[a].importo_fattura_direzione_area_clinica * 100) / 100,
          'REGIME IVA': invoices[a].person.regime_portale,
          'DOCUMENTO EMESSO': invoices[a].flg_doc_emesso,
          'DATA EMISSIONE': dt,
        };
        badgeForExcel.push(ret);
      }

    }

    /*
        = invoices.map((bdg: GceInvoice) => {
          let dt = '';
          if (bdg.flg_doc_emesso == true)
            dt = moment(bdg.ts_doc_emesso).format('DD-MM-YYYY HH:mm:ss');
          let ret = {
            'ID': bdg.id,
            'CODICE CLINICA': bdg.cod_clinica,
            'CLINICA': bdg.cod_clinica + ' ' + bdg.clinic.nome,
            'SOCIETA': bdg.clinic.legalentity,
            'COMPANY NAVISION':bdg.clinic.company_navision,
            'FORNITORE NAVISION':bdg.person.fornitore_nav,
            'MEDICO': bdg.person.medico_portale,
            'PRESTAZIONI': Math.round(bdg.importo_fattura_prestazioni * 100) / 100,
            'DIREZIONE SANITARIA': Math.round(bdg.importo_fattura_direzione_sanitaria * 100) / 100,
            'DIREZIONE MEDICO SCIENTIFICA DI AREA': Math.round(bdg.importo_fattura_direzione_area_clinica * 100) / 100,
            'REGIME IVA':bdg.person.regime_portale,
            'DOCUMENTO EMESSO': bdg.flg_doc_emesso,
            'DATA EMISSIONE': dt,
          };
          return ret;
        });
    */
    const wsDetracts = XLSX.utils.json_to_sheet(badgeForExcel);

    XLSX.utils.book_append_sheet(wb, wsDetracts, "medici fatturazione confermato");

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //save file
    const data: Blob = new Blob([wbout], { type: EXCEL_TYPE });
    //let fileName = `Dott. ${invoice.person.descrizione} - ${moment(invoice.ts_calcolo).format('MMMMYY')} -  ${invoice.clinic.nome}`;

    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);

  }

  exportMailsAff(mails: any) {
    console.log('Export Mail Affitturari for excel %o', mails);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    let fileName = `mail_affittuari_${moment(mails[0].mese).format('MMMMYY')}`;

    let badgeForExcel: any[] = mails.map((bdg: AffittuariMailMensile) => {
      let dt = '';
      if (bdg.flg_invio_effettuato == true)
        dt = moment(bdg.dataora_invio).format('DD-MM-YYYY HH:mm:ss');
      let ret = {
        'CODICE CLINICA': bdg.cod_clinica,
        'CLINICA': bdg.clinica,
        'IMPORTO PRODUZIONE': Math.round(bdg.importo_produzione * 100) / 100,
        'IMPORTO FATTURATO': Math.round(bdg.importo_fatturato * 100) / 100,
        'GIRO AFFARI': Math.round(bdg.giro_affari * 100) / 100,
        'MAIL AFFITTUARIO': bdg.mail_affittuario,
        'DATA ULTIMO AGGIORNAMENTO IMPORTI': moment(bdg.dataora_aggiornamento_importi).format('DD-MM-YYYY HH:mm:ss'),
        'INVIO EFFETTUATO': bdg.flg_invio_effettuato,
        'DATA ORA INVIO': dt
      };
      return ret;
    });

    const wsDetracts = XLSX.utils.json_to_sheet(badgeForExcel);
    var wscols = [
      { wch: 20 },
      { wch: 50 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 30 },
      { wch: 40 },
      { wch: 25 },
      { wch: 20 }
    ];
    wsDetracts['!cols'] = wscols;

    XLSX.utils.book_append_sheet(wb, wsDetracts, "dati mese giro affari cliniche");

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //save file
    const data: Blob = new Blob([wbout], { type: EXCEL_TYPE });
    //let fileName = `Dott. ${invoice.person.descrizione} - ${moment(invoice.ts_calcolo).format('MMMMYY')} -  ${invoice.clinic.nome}`;

    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
}
