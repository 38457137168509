import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as MySettings from '../../../../environments/environment';
import { AuthGuardService } from './../../../services/auth-guard.service';
import { TableService } from '../services/table.service';
import { LoaderService } from './../../../loader';
import { StoreFormatPipe } from './../../../pipes/store-format.pipe'
import * as $ from 'jquery';

@Component({
  selector: 'dialog-add-product',
  templateUrl: './add-product.dialog.html',
  styleUrls: ['./add-product.dialog.scss']
})
export class AddProductStoreDialogComponent implements OnInit {
  dataS;
  n;
  clinica;
  _columns;
  idCategoriaFornitore;
  temp;
  selected = [];
  rows;
  loading = false;
  loadingIndicator = false;
  msgs;
  req;
  key;
  search;
  allProducts;
  url;
  editing = {};
  prods = [];
  allArticles: boolean = false;
  act;
  ableGiacenze: boolean = true;

  constructor(
    public _authService: AuthGuardService,
    public dialogRef: MatDialogRef<AddProductStoreDialogComponent>,
    private _http: HttpClient,
    private _tableService: TableService,
    private _loaderRogges: LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dataS = data;
    this._tableService.hasStarted = false;
    this.act = data['act'];
    if (data.Idfornitore === undefined) {
      this.req = {
        idnegozio: MySettings.environment['idNegozio'],
        GUID: this._authService.getGUID(),
        DomainId: this._authService.getDomain(),
        ProfileId: this._authService.getProfile(),
        DomainContext: MySettings.environment.MyContext,
        withAllArticles: this.allArticles,
        "DataInventario": "2018-05-07T12:00:01",
        "pagingParameterModel":
        {
          "enabled": false,
          "pageNumber": 1,
          "pageSize": 30
        }
      };
      this.key = 'articoliInventario';
      this.url = MySettings.environment['GetArticoliInventario'];
    } else {


      this.req = {
        idnegozio: MySettings.environment['idNegozio'],
        GUID: this._authService.getGUID(),
        DomainId: this._authService.getDomain(),
        ProfileId: this._authService.getProfile(),
        IdCategoriaFornitore: data.idCategoriaFornitore,
        DomainContext: MySettings.environment.MyContext,
      };
      if (data.Idfornitore != "cross") this.req['Idfornitore'] = data.Idfornitore;
      this.key = (this.data.bolleUscita) ? 'articoliFiliale' : 'articoliFornitore';
      this.url = (this.data.bolleUscita) ? MySettings.environment['GetArticoliFornitoriBollaUscita'] : data.Idfornitore == "cross" ? MySettings.environment.GetArticoliOrdineCross : MySettings.environment['GetArticoliFornitori'];
    }
    this.fetch((data) => {
      this._tableService.hasStarted = true;
      if (this.data.dati != undefined && this.data.dati.length > 0) {
        this._tableService.rows = this.data.dati;
        for (let d of this.data.dati)
          for (let x of data)
            if (d.Idarticolo == x.Idarticolo) {
              x.QtaContata = +d.QtaContata;
              x.GiaSelezionato = true;
              break;
            }
      }

      //MasterSlave: "S"
      this.rows = [];
      for (let r of data) {
        //console.log('act %o', this.act);
        if (this.act == 'flDetail') {
          if (!(r['MasterSlave'] == 'S')) {
            this.rows.push(r);
          }
        }
        else
          if (this.act == 'exit-docs-entry') {
            if (!(r['MasterSlave'] == 'S') && !(r['MasterSlave'] == 'M')) {
              this.rows.push(r);
            }
          }
          else {
            this.rows.push(r);
          }

      }
      this.temp = [...this.rows];
      //this.rows = [...data]; // Refresh the data
      this._columns = [...this._columns];
      //this.loading = false;
      //this.loadingIndicator = false;

    });

    this.msgs = MySettings.environment['tableMessages'];

    this.backup = (this.data.dati != undefined) ? this.data.dati.slice() : [];
  }
  backup;

  getAllProducts() {
    console.log('----------------------------------------   getAllProducts');
    this.allArticles = true;
    this.req = {
      idnegozio: MySettings.environment['idNegozio'],
      GUID: this._authService.getGUID(),
      DomainId: this._authService.getDomain(),
      ProfileId: this._authService.getProfile(),
      DomainContext: MySettings.environment.MyContext,
      withAllArticles: this.allArticles,
      "DataInventario": "2018-05-07T12:00:01",
      "pagingParameterModel":
      {
        "enabled": false,
        "pageNumber": 1,
        "pageSize": 30
      }
    };
    //if (this.data.Idfornitore != "cross") this.req['Idfornitore'] = this.data.Idfornitore;
    //this.key = (this.data.bolleUscita) ? 'articoliFiliale' : 'articoliFornitore';
    //this.url = (this.data.bolleUscita) ? MySettings.environment['GetArticoliFornitoriBollaUscita'] : this.data.Idfornitore == "cross" ? MySettings.environment.GetArticoliOrdineCross : MySettings.environment['GetArticoliFornitori'];
    this.fetch((data) => {
      this.ableGiacenze = false;
      this._tableService.hasStarted = true;
      if (this.data.dati != undefined && this.data.dati.length > 0) {
        this._tableService.rows = this.data.dati;
        for (let d of this.data.dati)
          for (let x of data)
            if (d.Idarticolo == x.Idarticolo) {
              x.QtaContata = +d.QtaContata;
              x.GiaSelezionato = true;
              break;
            }
      }
      this.temp = [...data];

      this.rows = [...data]; // Refresh the data
      this._columns = [...this._columns];
      //this.loading = false;
      //this.loadingIndicator = false;

    });
  }

  getPartialProducts() {
    console.log('----------------------------------------   getPartialProducts');
    this.allArticles = false;
    this.req = {
      idnegozio: MySettings.environment['idNegozio'],
      GUID: this._authService.getGUID(),
      DomainId: this._authService.getDomain(),
      ProfileId: this._authService.getProfile(),
      DomainContext: MySettings.environment.MyContext,
      withAllArticles: this.allArticles,
      "DataInventario": "2018-05-07T12:00:01",
      "pagingParameterModel":
      {
        "enabled": false,
        "pageNumber": 1,
        "pageSize": 30
      }
    };
    // if (this.data.Idfornitore != "cross") this.req['Idfornitore'] = this.data.Idfornitore;
    // this.key = (this.data.bolleUscita) ? 'articoliFiliale' : 'articoliFornitore';
    // this.url = (this.data.bolleUscita) ? MySettings.environment['GetArticoliFornitoriBollaUscita'] : this.data.Idfornitore == "cross" ? MySettings.environment.GetArticoliOrdineCross : MySettings.environment['GetArticoliFornitori'];
    this.fetch((data) => {
      this._tableService.hasStarted = true;
      if (this.data.dati != undefined && this.data.dati.length > 0) {
        this._tableService.rows = this.data.dati;
        for (let d of this.data.dati)
          for (let x of data)
            if (d.Idarticolo == x.Idarticolo) {
              x.QtaContata = +d.QtaContata;
              x.GiaSelezionato = true;
              break;
            }
      }
      this.temp = [...data];

      this.rows = [...data]; // Refresh the data
      this._columns = [...this._columns];
      //this.loading = false;
      //this.loadingIndicator = false;

    });
  }

  getCellClass({ row, column, value }): any {
    var t = this;
    return {
      'selectedClass': row.GiaSelezionato != undefined && row.GiaSelezionato
    };
  }

  selP(e, r, rowIndex) {
    console.log('----------------------------------------   SELP');
    r.QtaAggiunta = 0;
    r.BarCodeAggiunta = r.Barcode;

    r.GiaSelezionato = (e.target.checked);
    this.addR(r, false, !e.target.checked);

  }

  fetch(cb) {
    this.loading = true;
    this.loadingIndicator = true;
    this._http.post(
      this.url,
      this.req,
      {
        headers: new HttpHeaders({
          'accept-encoding': 'gzip, deflate'
        })
      }
    )
      .subscribe((res) => {
        console.log('RES %o act %o', res, this.act);
        let col = res['tableMap'].columns;
        this._columns = [];
        console.log('FILTER RES');
        //this.allProducts = [];

        this.allProducts = res;
        if (this.act == 'exit-docs-entry') {
          //delete MasterSlave product

        }
        console.log('AGGIUNGO __CHECK');
        this._columns.push({
          prop: "__Check",
          name: "",
          flexGrow: 2,
          format: "",
          type: "",
          editable: false,
          search: false
        });


        for (let k of Object.keys(col)) {
          let c = col[k];
          if (c.visible) {

            this._columns.push({
              prop: k,
              name: c.caption,
              flexGrow: 2,
              format: c.format,
              type: c.type,
              editable: (c.editable !== undefined) ? c.editable : 0,
              search: true
            });
          }
        };

        cb(res[this.key]);

        this.loading = false;
        this.loadingIndicator = false;
        console.log('My _columns %o', this._columns);
      });
  }

  focus() {
    $(function () {
      $('#txtQ').select();
      $('#txtQ').focus();
    });
  }

  removeOthers(name) {
    this.editing = [{}];
    this.editing[name] = true;
  }

  updated = false;
  updateValue(event, cell, rowIndex, row) {
    console.log('UPDATE VALUE');
    if (event.target.value == undefined || isNaN(event.target.value)) return;
    if (event.target.value == "") event.target.value = "0";
    this.editing[rowIndex + '-' + cell] = false;
    if (+event.target.value < 0) {
      event.target.value = 0;
    }
    this.updated = true;
    for (let r of this.rows)
      if (r == row) {
        r[cell] = +event.target.value;
        break;
      }
    this.rows = [...this.rows];

    this.addIt(row);
  }

  addIt(row) {
    let ind = 0;
    console.log('ADD IT');
    for (let r of this._tableService.rows)
      if (r.Idarticolo == row['Idarticolo']) {
        delete this._tableService.rows[ind];
        this._tableService.rows = this._tableService.rows.filter(function (e) { return e });
        break;
      } else ind++;

    for (let r of this.rows)
      if (r == row) {
        r.QtaAggiunta = 0;
        r.BarCodeAggiunta = r.Barcode;
        r['GiaSelezionato'] = true;
        this._tableService.rows.push(r);
        break;
      }
  }

  isCurrency(t, f) {
    return ((t == 'Currency') && f != '' && f !== undefined);
  }

  updateFilter(event) {
    let columnName = event.currentTarget.id;
    const val = event.target.value.toLowerCase();
    const filteredData = this.temp.filter(function (d) {
      if (d[columnName] === undefined || typeof d[columnName] === "number") return true;
      return d[columnName].toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows = filteredData;
  }

  ngOnInit() {
    this.ableGiacenze = true;
  }

  onSelect({ selected }) {

  }

  myprop;
  selezionaTutto(t) {

    this.search = undefined; this.focusLente(this.myprop);

    /*for (let x of this.rows) {
      x['GiaSelezionato'] = t;
    }*/

    //this._tableService.rows = t ? this.rows : [];

    var ind = 0;
    for (let x of this.rows)
      this.selP({ target: { checked: t } }, x, ind++);

    $('.datatable-body-cell-label').click();
  }

  focusLente(id) {
    $(function () {
      $('#' + id).select();
      $('#' + id).focus();
    });
  }

  onSort(ev) {
    /*return;
    if (ev['column']['prop'] != '__Check') return;
    this.rows = [...this.rows.sort(function(x, y) {
      // true values first
      return (x.GiaSelezionato === y.GiaSelezionato)? 0 : x.GiaSelezionato ? -1 : 1;
      // false values first
      // return (x === y)? 0 : x? 1 : -1;
    })];
    console.log("sorted");*/
  }

  calculateWidthColumn(col): number {
    //console.log('calculateWidthColumn %o',col);
    document.getElementById('columnAdapter').innerHTML = col['name'].toUpperCase();
    var d = getComputedStyle(document.getElementById('columnAdapter')).width;
    var dim = parseInt((d + "").substring(0, (d + "").length - 2));
    //console.log(dim);
    dim = 55 + dim;
    //console.log(col + ": " + dim);
    return dim;
  }

  hasClass(who, asc) {
    if (!asc && document.getElementsByClassName('sort-desc').length == 0) return true;
    if (asc && document.getElementsByClassName('sort-asc').length == 0) return true;
    if (!asc)
      for (let x of Array.prototype.slice.call(document.getElementsByClassName('sort-desc')))
        try {
          return !(who == x.children[0].children[0].children[0].children[1].children[1].innerHTML.trim());
        }
        catch (e) {
          return true;
        }
    if (asc)
      for (let x of Array.prototype.slice.call(document.getElementsByClassName('sort-asc')))
        try {
          return !(who == x.children[0].children[0].children[0].children[1].children[1].innerHTML.trim());
        }
        catch (e) {
          return true;
        }
    return true;
  }

  onActivate($event) {
    console.log('EVENT %o %o', $event, $event['cellIndex']);
    if (event.type === 'dblclick' && this.data.Idfornitore !== undefined) {
      this.addR($event.row, true);
      return;
    }
    if (event.type === 'click' && ($event['cellIndex'] == 4 || $event['cellIndex'] == 5)) {
      if ($event.row != null && $event.row['MasterSlave'] == 'M') {

        //call info
        let myreq = {
          idnegozio: MySettings.environment['idNegozio'],
          GUID: this._authService.getGUID(),
          IdArticolo: $event.row['Idarticolo'],
          DomainId: this._authService.getDomain(),
          ProfileId: this._authService.getProfile(),
          IdFornitore: this.dataS.Idfornitore,
          Idfornitore: this.dataS.Idfornitore,
          DomainContext: MySettings.environment.MyContext,
        };
        console.log('ON ACTIVATE %o req %o', $event.row, myreq);
        let myurl = MySettings.environment['GetArticoloMasterSlave'];
        this._loaderRogges.show();
        this._http.post(
          myurl,
          myreq,
          {
            headers: new HttpHeaders({
              'accept-encoding': 'gzip, deflate'
            })
          }
        )
          .subscribe((res) => {
            this._loaderRogges.hide();
            if (res['esito'] == true) {
              let msg = 'Articolo Master Selezionato: ' + res['ArticoloMaster'] + '\n' + 'Fornitore: ' + res['FornitoreMaster'] + '\n' + 'Articolo Slave Associato: ' + res['ArticoloSlave'] + '\n' + 'Fornitore: ' + res['FornitoreSlave'];
              window.alert(msg);
            }
            else {
              window.alert('errore nella ricerca informazioni articolo: ' + res['errorMessage']);
            }
            console.log('response info master slave %o', res);
          });

      }

    }

  }

  close() {
    if (this.data.chiudi != undefined)
      this._tableService.rows = this.temp = [...this.backup];
    this.dialogRef.close(false);
  }

  confirm() {
    this.dialogRef.close();
  }
  /*
      ngDoCheck()
      {
        console.log('_columns %o',this._columns);
      }
  */
  addR(row, v = false, del = false) {
    console.log('ADDR');
    if (row.MasterSlave == 'S') {
      window.alert('Non è possibile inserire nel carrello ordine un prodotto secondario');
    }
    else {
      var f = false; let ind = 0;
      for (let r of this._tableService.rows) {
        if (r != undefined && ((this.data.Idfornitore == undefined || r.Codice == undefined) ? row.Idarticolo == r.Idarticolo : row.Codice == r.Codice)) {
          r.Totale = ++r.Confezioni * r.CostoPerConfezione;
          r.QtaAggiunta = 0;
          r.BarCodeAggiunta = r.Barcode;
          f = true;
          break;
        } else ind++;
      }

      if (del) {
        delete this._tableService.rows[ind];
        this._tableService.rows = this._tableService.rows.filter(function (e) { return e });
        this._tableService.rows = [...this._tableService.rows];
        return;
      }

      if (!f) {
        if (this.data.bolleUscita != undefined && this.data.bolleUscita)
          this._tableService.rows.push(
            {
              Id: this._tableService.rows.length + 1,
              Articolo: row.DescrizioneEstesa,
              Confezioni: 1,
              Totale: row.CostoLordoConfezione,//row.CostoNettoConfezione,
              Codice: row.Codice,

              Idarticolo: row.Idarticolo,
              IdUdm: row.Idudm,
              ScalaUDM: row.ScalaUdm,


              CustomCodice: row.BarCode,
              CustomClasse: row.CategoriaSuperParent,
              CustomFamiglia: row.CategoriaParent,
              CustomSottofamiglia: row.Categoria,
              CustomArticolo: row.DescrizioneEstesa,

            }
          );
        else
          this._tableService.rows.push(
            {
              Id: this._tableService.rows.length + 1,
              Articolo: (this.data.Idfornitore === undefined) ? row.Articolo : row.DescrizioneEstesa,
              CostoPerConfezione: row.CostoLordoConfezione,
              Confezioni: 1,
              Totale: row.CostoLordoConfezione,
              Codice: row.BarCodice,
              Idfornitore: row.Idfornitore,

              Idarticolo: row.Idarticolo,
              IdUdm: row.idUDM,
              ScalaUDM: row.scalaUDM,
              Idprezzo: row.Idprezzo,
              QtaContata: row.QtaContata,
              IdcatGiacenza: row.IdcatGiacenza,

              CustomCodice: row.BarCode,
              CustomClasse: row.CategoriaSuperParent,
              CustomFamiglia: row.CategoriaParent,
              CustomSottofamiglia: row.Categoria,
              CustomArticolo: row.DescrizioneEstesa,

              BarCodeAggiunta: row.Barcode,
              QtaAggiunta: 0,
              QtaAttesa: row.QtaGiacenza
            }
          );
      }
      this._tableService.rows = [...this._tableService.rows];
      if (v) this.dialogRef.close();
    }
  }

}