import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GceInvoicePerson, GceInvoicePersonCompensation, GceClinic, GceLookUpOptions } from '../../../entities/gce-management';
import { GceManagementService } from '../../../services/gce-management.service';
import { LoaderService } from '../../../loader/loader.service';
import { DatatableComponent } from '../../../shared/datatable/datatable.component';
import { FormComponent } from '../../../shared/form/form.component';
import { FormHeaderComponent } from '../../../shared/form-header/form-header.component';

import * as moment from 'moment';
import { PermissionService } from '../../../services/permission.service';
import { CommonUtilsService } from '../../../services/common-utils.service';

import { AuthGuardService } from '../../../services';

const tz = "Europe/Rome";
const dateFormat = "YYYY-MM-DDTHH:mm:ss";

@Component({
  selector: 'app-gce-dialog-compensation',
  templateUrl: './gce-dialog-compensation.component.html',
  styleUrls: ['./gce-dialog-compensation.component.css']
})
export class GceDialogCompensationComponent implements OnInit {
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild('formData', { static: false }) formData: FormComponent;
  @ViewChild('formHeader', { static: false }) formHeader: FormHeaderComponent;

  person: GceInvoicePerson;
  personCompensations: GceInvoicePersonCompensation[] = [];
  personCompensationsBkp: GceInvoicePersonCompensation[] = [];

  columns: any[] = [];
  limitRows: number = 13;
  heightMainList: number = 503;
  openRightPanel: boolean = false;
  currentRow: any;
  editing: boolean = false;
  newRow: boolean = false;
  keyColumn: any = {};
  role: string = '';
  usr: string = '';
  dm: string = '';
  writePermission: boolean;

  constructor(public dialogRef: MatDialogRef<GceDialogCompensationComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private gceService: GceManagementService, private loaderService: LoaderService, private auth: AuthGuardService) {
    this.person = data;

    this.writePermission = PermissionService.checkPermission("CGE_ECM");

    console.log(data);
  }

  ngOnInit() {
    let usr = this.auth.getUser();
    if (usr != null) {

      this.role = usr['ruolo'];
      this.dm = usr['clinica'];

      if (usr['nomeCompleto'] != null && usr['nomeCompleto'].length > 0)
      {
        this.usr = usr['nomeCompleto'];
      }
      else
      {
        if (usr['email'] != null && usr['email'].length > 0)
        {
          this.usr = usr['email'];
        }
      }
    }
    this.columns = GceInvoicePersonCompensation.getStructure();
    this.columns.forEach(column => {
      if (column.primaryKey) {
        this.keyColumn = column;
      }
      console.log("GceDialogCompensationComponent->ngOnInit column: %o", column.reference);
      if (column.reference) {
        if (column.reference.entity === "GceInvoicePerson") {
          let lookUpOptions: GceLookUpOptions = this.gceService.getLookUpAndOptions([this.person], column.reference);
          column.lookUp = lookUpOptions.lookUp;
          column.options = lookUpOptions.options;
        } else if (column.reference.entity === "GceClinic") {
          this.gceService.loadClinicsForPerson(this.person, this.dm, this.role).subscribe(({ personClinics, clinics }) => {
            let lookUpOptions: GceLookUpOptions = this.gceService.getLookUpAndOptions(clinics, column.reference);
            column.lookUp = lookUpOptions.lookUp;
            column.options = lookUpOptions.options;
          });
        }
      }
    });
    this.personCompensationsBkp = [];

    this.gceService.getPersonCompensations(this.person.idfatturazione).subscribe((response) => {
      console.log("GceDialogCompensationComponent->ngOnInit response: %o", response);
      this.personCompensations = response;
      this.personCompensationsBkp = [...this.personCompensations];
    }, err => {
      this.personCompensationsBkp = [];
    });
  }

  onEdit() {
    this.newRow = false;
    this.editing = true;
    this.openRightPanel = true;
  }

  onNew() {
    console.log("onNew");
    this.editing = false;
    this.newRow = true;
    this.currentRow = { idfatturazione: this.person.idfatturazione };
    this.openRightPanel = true;
    this.table.setNewRow(true);
  }

  onSearch(searchFilter: any) {
    console.log("onSearch %o", searchFilter);
    this.personCompensations = this.personCompensationsBkp.filter(item => {
      for (let key in item) {
        if (item[key] !== null && item[key] !== undefined && item[key].toString().toLowerCase().indexOf(searchFilter.filter) != -1)
          return true;
      }
      return false;
    });
    this.table.offset = 0;
  }

  onToggle(selectedColumns) {
    console.log("onToggle %o %o", selectedColumns);
    this.columns.map(item => {
      item.show = selectedColumns.indexOf(item.columnDef) != -1;
    });
  }

  onChangeRow(row) {
    console.log("onChangeRow %o", row);
    this.currentRow = row;
    this.editing = false;
    this.newRow = false;
    this.openRightPanel = true;
  }

  onRestore(event) {
    console.log("onRestore %o", event);
    this.currentRow = null;
    if (this.newRow) {
      this.currentRow = this.personCompensations[0];
      this.table.setNewRow(false);
    }
    this.clear();
    if (this.personCompensations.length == 0) {
      this.onNew();
    }
  }

  onSave(event) {
    console.log("onSave %o", event);
    let dataToSave = CommonUtilsService.copyData(event.form.getRawValue());

    if(dataToSave['data_inzio']){
      dataToSave['data_inzio'] = moment(dataToSave['data_inzio']).format('YYYY-MM-DD');
    }
    if(dataToSave['data_fine']){
      dataToSave['data_fine'] = moment(dataToSave['data_fine']).format('YYYY-MM-DD');  
    }
    
    if (this.newRow) {
      this.insert(dataToSave);
    } else {
      this.update(dataToSave);
    }
  }

  onDelete(personClinic: GceInvoicePersonCompensation) {
    console.log("onDelete %o", personClinic);
    this.loaderService.show();
    
    this.gceService.deletePersonCompensation(personClinic.idfatturazione, personClinic.cod_clinica, personClinic.data_inzio).subscribe((response) => {
      let index = this.personCompensationsBkp.findIndex((pc: GceInvoicePersonCompensation) => { return pc.idfatturazione === personClinic.idfatturazione && pc.cod_clinica === personClinic.cod_clinica });
      this.personCompensationsBkp.splice(index, 1);
      this.onSearch({ filter: "", fromDate: undefined, toDate: undefined });
      this.clear();
      this.loaderService.hide();
    });
    
  }

  onDeleteAll(event) {
    console.log("onDeleteAll %o", event);
  }

  onCloseForm(event) {
    console.log("onCloseForm %o", event);
    this.clear();
  }

  clear() {
    this.editing = false;
    this.newRow = false;
    this.openRightPanel = false;
  }

  insert(personCompensation: GceInvoicePersonCompensation) {
    this.loaderService.show();
    this.gceService.insertPersonCompensation(personCompensation).subscribe((response: GceInvoicePersonCompensation) => {
      console.log(response);
      this.personCompensationsBkp.push(response);
      this.personCompensations = [...this.personCompensationsBkp];
      // this.onSearch({ filter: "", fromDate: undefined, toDate: undefined });
      this.clear();
      // this.table.setNewRow(false);
      this.loaderService.hide();
    }, err => {

    });
  }

  update(personCompensation: GceInvoicePersonCompensation) {
    this.loaderService.show();
    this.gceService.updatePersonCompensation(personCompensation).subscribe((response: GceInvoicePersonCompensation) => {
      console.log(response);
      let index = this.personCompensationsBkp.findIndex((pc: GceInvoicePersonCompensation) => { return pc.idfatturazione === response.idfatturazione && pc.cod_clinica === response.cod_clinica });
      this.personCompensationsBkp[index] = response;
      this.personCompensations = [...this.personCompensationsBkp];
      // this.onSearch({ filter: "", fromDate: undefined, toDate: undefined });
      this.clear();
      // this.table.setNewRow(false);
      this.loaderService.hide();
    }, err => {

    });
  }

}
