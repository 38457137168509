import { primaryKey, editableOnNew, headerCode, inputType, required, length, order, pattern, editable, Describer, visible, GceEntity, textAlign, relation } from './gce-management';
import { PermissionService } from '../../services/permission.service';
import { GceMedicType } from './gce-medic-type';

/**
 * GceInvoicePerson -> table: medici_fatturazione
 */
export class GceInvoicePerson extends GceEntity {
    @primaryKey()
    @headerCode('GCE.GCEINVOICEPERSON.IDFATTURAZIONE')
    @inputType('number')
    @visible(true)
    @editableOnNew(false)
    @order(1)
    idfatturazione: number;

    @headerCode('GCE.GCEINVOICEPERSON.DESCRIZIONE')
    @inputType('text')
    @required()
    @editable(true)
    @length(255)
    @order(2)
    descrizione: string;

    @headerCode('GCE.GCEINVOICEPERSON.EMAIL')
    @inputType('text')
    @required()
    @editable(true)
    @length(255)
    @order(7)
    email: string;

    @headerCode('GCE.GCEINVOICEPERSON.DATA_NASCITA')
    @inputType('date')
    @editable(true)
    @order(8)
    data_nascita: Date | string;

    @headerCode('GCE.GCEINVOICEPERSON.CODICE_FISCALE')
    @inputType('text')
    @editable(true)
    @length(16)
    @order(9)
    codice_fiscale: string;

    @headerCode('GCE.GCEINVOICEPERSON.PIVA')
    @inputType('text')
    @required()
    @editable(true)
    @length(11)
    @order(10)
    piva: string;

    @headerCode('GCE.GCEINVOICEPERSON.FORNITORE_NAV')
    @inputType('text')
    @required()
    @editable(true)
    @length(50)
    @order(11)
    fornitore_nav: string;

    @headerCode('GCE.GCEINVOICEPERSON.CODICE_XDENT')
    @inputType('number')
    @editable(true)
    @length(7)
    @pattern('0000000')
    @textAlign('left')
    @order(12)
    codice_xdent?: number;

    @headerCode('GCE.GCEINVOICEPERSON.MEDICO_PORTALE')
    @inputType('text')
    @editable(false)
    @editableOnNew(false)
    @length(255)
    @order(13)
    medico_portale: string;

    @headerCode('GCE.GCEINVOICEPERSON.PIVA_PORTALE')
    @inputType('text')
    @editable(false)
    @editableOnNew(false)
    @length(255)
    @order(14)
    piva_portale: string;

    @headerCode('GCE.GCEINVOICEPERSON.CFISC_PORTALE')
    @inputType('text')
    @editable(false)
    @editableOnNew(false)
    @length(255)
    @order(15)
    cfisc_portale: string;

    @headerCode('GCE.GCEINVOICEPERSON.REGIME_PORTALE')
    @inputType('text')
    @editable(false)
    @editableOnNew(false)
    @length(50)
    @order(16)
    regime_portale: string;

    @headerCode('GCE.GCEINVOICEPERSON.CAPITALE_PORTALE')
    @inputType('text')
    @editable(false)
    @editableOnNew(false)
    @length(50)
    @order(17)
    capitale_portale: string;

    @headerCode('GCE.GCEINVOICEPERSON.NOME_NAV')
    @inputType('text')
    @editable(false)
    @editableOnNew(false)
    @length(50)
    @order(18)
    nome_nav: string;

    @headerCode('GCE.GCEINVOICEPERSON.PIVA_NAV')
    @inputType('text')
    @editable(false)
    @editableOnNew(false)
    @length(50)
    @order(19)
    piva_nav: string;

    @headerCode('GCE.GCEINVOICEPERSON.CFISC_NAV')
    @inputType('text')
    @editable(false)
    @editableOnNew(false)
    @length(50)
    @order(20)
    cfisc_nav: string;

    @headerCode('GCE.GCEINVOICEPERSON.ANNULLATO')
    @inputType('boolean')
    @editable(false)
    @order(21)
    annullato: boolean;

    @headerCode('GCE.GCEINVOICEPERSON.ERRORE')
    @inputType('boolean')
    @editable(false)
    @order(23)
    errore: boolean;

    @headerCode('GCE.GCEINVOICEPERSON.DESC_ERRORE')
    @inputType('text')
    @editable(false)
    @length(255)
    @order(24)
    desc_errore: string;

    @headerCode('GCE.GCEINVOICEPERSON.TIPO_MEDICO')
    @inputType('select')
    @required()
    @relation("GceMedicType", {columnRel:'tipo_medico', columnDescr:['descrizione']})
    @order(25)
    tipo_medico: number;

    @headerCode('GCE.GCEINVOICEPERSON.INDIRIZZO')
    @inputType('text')
    @required()
    @length(255)
    @order(26)
    indirizzo: string;

    @headerCode('GCE.GCEINVOICEPERSON.CITTA')
    @inputType('text')
    @required()
    @length(255)
    @order(27)
    citta: string;

    @headerCode('GCE.GCEINVOICEPERSON.CAP')
    @inputType('text')
    @required()
    @length(5)
    @order(28)
    cap: string;

    @headerCode('GCE.GCEINVOICEPERSON.PROVINCIA')
    @inputType('select')
    @required()
    @relation("GceProvCodes", {columnRel:'cod_prov', columnDescr:['descrizione']})
    @order(29)
    provincia: string;

    @headerCode('GCE.GCEINVOICEPERSON.CAPITALE_SOCIALE_INVIO_PORTALE')
    @inputType('number')
    @pattern('0000000000.00')
    @order(30)
    capitale_sociale_invio_portale: number;

    @headerCode('GCE.GCEINVOICEPERSON.REGIME_FISCALE_INVIO_PORTALE')
    @inputType('select')
    @required()
    @relation("GceFiscalReg", {columnRel:'tipo_regime', columnDescr:['descrizione']})
    @order(31)
    regime_fiscale_invio_portale: string;

    @headerCode('GCE.GCEINVOICEPERSON.DATAORA_INVIO_INVIO_PORTALE')
    @inputType('datetime')
    @editable(false)
    @order(32)
    dataora_invio_portale: Date | string;

    @headerCode('GCE.GCEINVOICEPERSON.ESITO_INVIO_PORTALE')
    @inputType('text')
    @editable(false)
    @length(10)
    @order(33)
    esito_invio_portale: string;

    @headerCode('GCE.GCEINVOICEPERSON.MESSAGGI_INVIO_PORTALE')
    @inputType('text')
    @editable(false)
    @length(255)
    @order(34)
    messaggio_invio_portale: string;

    @headerCode('GCE.GCEINVOICEPERSON.FLG_INVIO_DATI_PORTALE')
    @inputType('boolean')
    @editable(true)
    @order(35)
    flg_invio_dati_portale: boolean;

    //new fields AR

    @headerCode('GCE.GCEINVOICEPERSON.id_profilo_xdent')
    @inputType('select')
    @editable(true)
    @relation("GceProfiles", {columnRel:'rowid', columnDescr:['descrizione']})
    @order(36)
    id_profilo_xdent : number;

    @headerCode('GCE.GCEINVOICEPERSON.iscrizione_albo_medici')
    @inputType('text')
    @editable(true)
    @length(4)
    @order(37)
    iscrizione_albo_medici :string;

    @headerCode('GCE.GCEINVOICEPERSON.iscrizione_albo_medici_prov')
    @inputType('select')
    @relation("GceProvCodes", {columnRel:'cod_prov', columnDescr:['descrizione']})
    @editable(true)
    @order(38)
    iscrizione_albo_medici_prov:string;

    @headerCode('GCE.GCEINVOICEPERSON.iscrizione_albo_odontoiatri')
    @inputType('text')
    @editable(true)
    @length(4)
    @order(39)
    iscrizione_albo_odontoiatri :string;

    @headerCode('GCE.GCEINVOICEPERSON.iscrizione_albo_odonto_prov')
    @inputType('select')
    @relation("GceProvCodes", {columnRel:'cod_prov', columnDescr:['descrizione']})
    @editable(true)
    @order(40)
    iscrizione_albo_odontoiatri_prov :string;

    @headerCode('GCE.GCEINVOICEPERSON.anno_laurea')
    @inputType('date')
    @editable(true)
    @order(41)
    data_laurea : Date | string;

    @headerCode('GCE.GCEINVOICEPERSON.scadenza_blsd')
    @inputType('date')
    @editable(true)
    @order(42)
    scadenza_blsd: Date | string;

    @headerCode('GCE.GCEINVOICEPERSON.scadenza_radioprotezione')
    @inputType('date')
    @editable(true)
    @order(43)
    scadenza_radioprotezione : Date | string;
    
    @headerCode('GCE.GCEINVOICEPERSON.scadenza_rc_professionale')
    @inputType('date')
    @editable(true)
    @order(44)
    scadenza_rc_professionale : Date | string;

    @headerCode('GCE.GCEINVOICEPERSON.massimale_rc_professionale')
    @inputType('number')
    @editable(true)
    @length(7)
    @pattern('0000000')
    @textAlign('left')
    @order(45)
    massimale_rc_professionale : number;

    @headerCode('GCE.GCEINVOICEPERSON.codice_iban')
    @inputType('text')
    @editable(true)
    @length(27)
    @order(46)
    codice_iban :string;

    @headerCode('GCE.GCEINVOICEPERSON.indirizzo_pec')
    @inputType('text')
    @editable(true)
    @length(100)
    @order(47)
    indirizzo_pec :string;

    @headerCode('GCE.GCEINVOICEPERSON.CESSATO')
    @inputType('boolean')
    @editable(true)
    @order(22)
    cessato: boolean;

    @headerCode('GCE.GCEINVOICEPERSON.TELEFONO')
    @inputType('text')
    @length(30)
    @order(21)
    telefono?: string;

    @headerCode('GCE.GCEINVOICEPERSON.NOFATTURAE')
    @inputType('boolean')
    @editable(true)
    @order(21)
    flg_nofattura_portale : boolean;

    @headerCode('GCE.GCEINVOICEPERSON.IMG')
    @inputType('file')
    @editable(true)
    @order(3)
    url_image : string;



    medicType?:GceMedicType;

    constructor();
    constructor(idfatturazione: number, descrizione: string, piva: string, fornitore_nav: string, codice_xdent: number, medico_portale: string, piva_portale: string, cfisc_portale: string, regime_portale: string, capitale_portale: string, nome_nav: string, piva_nav: string, cfisc_nav: string, annullato: boolean, errore: boolean, desc_errore: string, email: string, codice_fiscale:string, data_nascita:Date | string, tipo_medico: number, indirizzo:string, citta:string, cap:string, provincia:string, capitale_sociale_invio_portale: number, regime_fiscale_invio_portale: string, dataora_invio_portale: Date | string, esito_invio_portale: string, messaggio_invio_portale: string, flg_invio_dati_portale: boolean);
    constructor(idfatturazione?: number, descrizione?: string, piva?: string, fornitore_nav?: string, codice_xdent?: number, medico_portale?: string, piva_portale?: string, cfisc_portale?: string, regime_portale?: string, capitale_portale?: string, nome_nav?: string, piva_nav?: string, cfisc_nav?: string, annullato?: boolean, errore?: boolean, desc_errore?: string, email?: string, codice_fiscale?:string, data_nascita?:Date | string, tipo_medico?: number, indirizzo?:string, citta?:string, cap?:string, provincia?:string, capitale_sociale_invio_portale?: number, regime_fiscale_invio_portale?: string, dataora_invio_portale?: Date | string, esito_invio_portale?: string, messaggio_invio_portale?: string, flg_invio_dati_portale?: boolean, id_profilo_xdent? : number, iscrizione_albo_medici? : string, iscrizione_albo_medici_prov?  : string, iscrizione_albo_odontoiatri?  : string, iscrizione_albo_odontoiatri_prov?  : string, data_laurea? : Date, scadenza_blsd? : Date | string, scadenza_radioprotezione?  : Date | string, scadenza_rc_professionale?  : Date | string, massimale_rc_professionale? : number, codice_iban? : string, indirizzo_pec? : string, cessato?: boolean, telefono?: string, flg_nofattura_portale?: boolean, url_image?:string
        ) {
        super();
        this.idfatturazione = idfatturazione || 0;
        this.descrizione = descrizione || '';
        this.piva = piva || '';
        this.fornitore_nav = fornitore_nav || '';
        this.codice_xdent = codice_xdent || null;
        this.annullato = annullato || false;
        this.cessato = cessato || false;
        this.desc_errore = desc_errore || '';
        this.medico_portale = medico_portale || '';
        this.piva_portale = piva_portale || '';
        this.cfisc_portale = cfisc_portale || '';
        this.regime_portale = regime_portale || '';
        this.capitale_portale = capitale_portale || '';
        this.nome_nav = nome_nav || '';
        this.piva_nav = piva_nav || '';
        this.cfisc_nav = cfisc_nav || '';
        this.errore = errore || false;
        this.email = email || '';
        this.codice_fiscale = codice_fiscale || '';
        this.data_nascita = data_nascita || new Date();
        this.tipo_medico = tipo_medico || 0;
        this.indirizzo = indirizzo || '';
        this.citta = citta || '';
        this.cap = cap || '';
        this.provincia = provincia || '';
        this.capitale_sociale_invio_portale = capitale_sociale_invio_portale || 0;
        this.regime_fiscale_invio_portale = regime_fiscale_invio_portale || '';
        this.dataora_invio_portale = dataora_invio_portale || new Date();
        this.esito_invio_portale = esito_invio_portale || '';
        this.messaggio_invio_portale = messaggio_invio_portale || '';
        this.flg_invio_dati_portale = flg_invio_dati_portale || false;
        this.id_profilo_xdent = id_profilo_xdent || 0;
        this.iscrizione_albo_medici = iscrizione_albo_medici || '';
        this.iscrizione_albo_medici_prov = iscrizione_albo_medici_prov || '';
        this.iscrizione_albo_odontoiatri = iscrizione_albo_odontoiatri || '';
        this.iscrizione_albo_odontoiatri_prov = iscrizione_albo_odontoiatri_prov || '';
        this.data_laurea = data_laurea || new Date();
        this.scadenza_blsd = scadenza_blsd || new Date();
        this.scadenza_radioprotezione = scadenza_radioprotezione || new Date();
        this.scadenza_rc_professionale =scadenza_rc_professionale || new Date();
        this.massimale_rc_professionale =massimale_rc_professionale || 0 ;
        this.codice_iban =codice_iban || '';
        this.indirizzo_pec =indirizzo_pec || '';
        this.telefono = telefono || '';
        this.flg_nofattura_portale = flg_nofattura_portale || false;
        this.url_image = url_image || '';
    }

    static getStructure(): Array<any> {
        let structure: any[] = Describer.getStructure(new GceInvoicePerson());
        structure.push(...this.getActions());
        return structure;
    }

    protected static getActions(): any[] {
        let actions: any[] = [];

        if(PermissionService.checkPermission("CGE_RCM") || PermissionService.checkPermission("CGE_ECM")){
            let actionCompensation = {
                action: undefined,
                callback: 'opendDialogCompensation',
                callbackOnForm: false,
                columnDef: 'actionCompensation',
                disabled: false,
                header: 'GCE.GCEINVOICEPERSON.COMPENSATION',
                dontShowHeader: false,
                length: 256,
                lookUp: {},
                primaryKey: false,
                reference: null,
                required: false,
                requiredOnNew: false,
                show: true,
                type: 'custom-action',
                values: null,
                visible: true,
                editable: false,
                visibleOnToggle: false,
                imageButton: 'assets/img/medic-compensation-white.png',
                imageTable: 'assets/img/medic-compensation.png',
                tooltip: 'GCE.GCEINVOICEPERSON.COMPENSATION.TOOLTIP',
                order: 3
            };
            actions.push(actionCompensation);
        }

        if(PermissionService.checkPermission("CGE_RCD") || PermissionService.checkPermission("CGE_ECD")){
            let actionClinicAssociation = {
                action: undefined,
                callback: 'opendDialogClinicAssociation',
                callbackOnForm: false,
                columnDef: 'actionClinicAssociation',
                disabled: false,
                header: 'GCE.GCEINVOICEPERSON.CLINICASSOCIATION',
                dontShowHeader: false,
                length: 256,
                lookUp: {},
                primaryKey: false,
                reference: null,
                required: false,
                requiredOnNew: false,
                show: true,
                type: 'custom-action',
                values: null,
                visible: true,
                editable: false,
                visibleOnToggle: false,
                imageButton: 'assets/img/clinic-association-white.png',
                imageTable: 'assets/img/clinic-association.png',
                tooltip: 'GCE.GCEINVOICEPERSON.CLINICASSOCIATION.TOOLTIP',
                order: 4
            }
            actions.push(actionClinicAssociation);
        }

        if(PermissionService.checkPermission("CGE_CAN")){
            let actionCheckPerson = {
                action: undefined,
                callback: 'checkPerson',
                callbackOnForm: false,
                columnDef: 'actionCheckPerson',
                disabled: false,
                header: 'GCE.GCEINVOICEPERSON.CHECKPERSON',
                dontShowHeader: false,
                length: 256,
                lookUp: {},
                primaryKey: false,
                reference: null,
                required: false,
                requiredOnNew: false,
                show: true,
                type: 'custom-action',
                values: null,
                visible: true,
                editable: false,
                visibleOnToggle: false,
                imageButton: 'assets/img/check-person-white.png',
                imageTable: 'assets/img/check-person.png',
                tooltip: 'GCE.GCEINVOICEPERSON.CHECKPERSON.TOOLTIP',
                order: 5
            }
            actions.push(actionCheckPerson);
        }

        
        return actions;
    }
}
