import { Component, OnInit, Input } from '@angular/core';
import { webTicSettingsCard } from'../app.model';

@Component({
  selector: 'app-settings-card',
  templateUrl: './settings-card.component.html',
  styleUrls: ['./settings-card.component.css']
})
export class SettingsCardComponent implements OnInit {
  @Input() settingCard: webTicSettingsCard;
  @Input() index: number;

  constructor() { }

  ngOnInit() {
  }

  onClickHash(action){
    window.location.hash = "/" + action;
  }

}
