import { Injectable, Inject, forwardRef, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { OAuthService } from 'angular-oauth2-oidc';
import { DataRepositoryService } from './data-repository.service';
import { AppConfig } from './../app.config';
import { environment } from '../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private authService: OAuthService;
  private dataRepo: DataRepositoryService;
  constructor(private injector: Injector) {
    setTimeout(() => {
      this.authService = this.injector.get(OAuthService);
      this.dataRepo = this.injector.get(DataRepositoryService);
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   // console.log("TokenInterceptor interceptor request: %o", request);
    if (this.authService) {
      // if(!request.url.startsWith('http://5a266bbce70f7b001228cf06.mockapi.io/programmationView') &&
      //     !request.url.startsWith('http://5a266bbce70f7b001228cf06.mockapi.io/productData') &&
      //     !request.url.startsWith('http://5a266bbce70f7b001228cf06.mockapi.io/roomData') &&
      //     !request.url.startsWith(environment['tmdbAPI'].hostname) &&
      //     !request.url.startsWith(environment['tmdbAPI'].imagesUrl)){
      //   request = request.clone({
      //     setHeaders: {
      //       Authorization: `Bearer ${this.authService.getAccessToken()}`
      //     }
      //   });
      // }

      if (request.url.startsWith(AppConfig.settings.winticApiUrl) ||
        request.url.startsWith('https://webapidentalpro.dentalpro.it') ||
        request.url.startsWith('https://webapidentalprotest.dentalpro.it') ||
        request.url.startsWith(AppConfig.settings.webApi) ||
        request.url.startsWith('https://identity.dentalpro.it') ||
        request.url.startsWith('https://ssopazienti.dentalpro.it') ||
        request.url.startsWith('https://forecast.dentalpro.it')) {


        let headers: any = {};
        headers['Authorization'] = `Bearer ${this.authService.getAccessToken()}`;

        if (environment.clientId === 'DentalPro') {
          if (this.dataRepo != null && this.dataRepo.user != null) {
            headers['DomainId'] = this.dataRepo.user.domain;
            headers['Profile'] = this.dataRepo.user.profile;
            try {
              headers['codClinica'] = this.dataRepo.user.desc_domain.substr(0, 3);
            }
            catch (e) {
              try {
                headers['codClinica'] = this.dataRepo.user.desc_domain[0].substr(0, 3);
              }
              catch (e) {
              }
            }
          }
        }

        request = request.clone({
          setHeaders: headers
        });
       // console.log("interceptor request wth bearer: %o", request);
      }

    }
    return next.handle(request);
  }

}
