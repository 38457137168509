import {
  Component, forwardRef, Input, Output, EventEmitter,
  ViewChild, ElementRef, OnInit, OnChanges, SimpleChange
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputNumberComponent),
      multi: true
    }
  ]
})
export class InputNumberComponent implements ControlValueAccessor, OnInit, OnChanges {
  @ViewChild('inputInteger', { static: false }) inputInteger: ElementRef;
  @ViewChild('inputDecimal', { static: false }) inputDecimal: ElementRef;
  @Input() mask: string = '0000';
  @Input() textAlign: string = 'right';
  @Input() val;
  @Input() disable: boolean = false;
  @Output() blur: EventEmitter<any> = new EventEmitter<any>();
  @Output() focus: EventEmitter<any> = new EventEmitter<any>();

  defaultMask: string = '0000';

  _value: any;
  private _onChange = (_: any) => { };
  private _onTouched = () => { };

  integerLength: number;
  decimalLength: number;

  integerValue?: any;
  decimalValue?: any;

  constructor() { }

  ngOnInit() {
    //this.setLengths(this.defaultMask);
    console.log('Init inutNumber with integerValue %o decimalValue %o', this.integerValue, this.decimalValue);
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    // console.log("changes %o", changes);
    if (changes['mask']) {
      // this.maskedValue = changes['mask'].currentValue.replace(new RegExp('#', 'g'),'').replace(new RegExp('0', 'g'), '_');
      if (changes['mask'].currentValue) {
        this.setLengths(changes['mask'].currentValue);
      } else {
        this.setLengths(this.defaultMask);
      }

    }
    if (changes['val']) {
      this.value = changes['val'].currentValue;
      this.integerValue = this.getIntegerValue();
      this.decimalValue = this.getDecimalValue();
    }
  }

  get value() {
    // console.log("get value %o %o %o", this._value, this.integerValue, this.decimalValue);
    return this._value;
  }

  set value(val) {
    if (val !== this._value) {
      this._value = val;
      setTimeout(() => {
        this._onChange(val);
      }, 1);
    }
    // console.log("set value %o", val, this._value);
  }

  registerOnChange(fn: (_: any) => void): void {
    // console.log("registerOnChange %o", fn);
    this._onChange = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  writeValue(value) {
    console.log("writeValue %o", value);
    if (value) {
      this.value = value;
      this.integerValue = this.getIntegerValue();
      this.decimalValue = this.getDecimalValue();
      console.log("writeValue 2 this.integerValue %o this.decimalValue %o", value, this.integerValue, this.decimalValue);
      if (this.decimalValue == null)
        this.decimalValue = '00';

    } else if (value != null) {
      this.value = 0;
      this.integerValue = '0';
      this.decimalValue = this.completeDecimalPart(0);
    } else {
      this.value = null;
      this.integerValue = null;
      this.decimalValue = null;
    }
    // console.log("writeValue %o", value);
  }

  checkValue(event) {
    // console.log("value %o %o", event.target, event);
    // let newValue = Number(`${event.target.value}${event.key}`);
    // console.log(newValue);
    // if((this.min && event.target.value < this.min) || (this.max && event.target.value > this.max)){
    //     event.preventDefault();
    // } else
    if ([46, 8, 9, 27, 13].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((event.shiftKey || (event.keyCode < 48 || event.keyCode > 57)) && (event.keyCode < 96 || event.keyCode > 105)) {
      event.preventDefault();
    }
  }

  setLengths(mask: string) {
    this.integerLength = mask.indexOf('#') >= 0 ? 10 : mask.split(".")[0].length;
    this.decimalLength = mask.split(".")[1] ? mask.split(".")[1].length : 0;
    console.log("mask %o integerLength %o decimalLength %o", mask, this.integerLength, this.decimalLength);
  }

  // onFocus(event){
  //   let el = event.target;
  //   let val = el.value;
  //   el.value = '';
  //   setTimeout(()=>{
  //     el.value = val;
  //   }, 1);
  //   // console.log(event);
  // }


  onInput(event) {

    // let integerValue = '';
    // let decimalValue = '';

    // if(this.inputInteger){
    //   integerValue =  this.inputInteger.nativeElement.value;
    // }
    // if(this.inputDecimal){
    //   decimalValue =  this.inputDecimal.nativeElement.value;
    // }
    console.log("onInput this.integerValue %o this.decimalValue %o", this.integerValue, this.decimalValue);
    let val = Number(this.integerValue + (this.decimalValue ? '.' + this.decimalValue : ''));
    this.value = val;

    this._onTouched();
  }

  getIntegerValue() {
    // console.log(this.value);
    if (this.value) {
      return this.value.toString().split('.')[0];
    }
    return null;
  }

  getDecimalValue() {
    // console.log(this.value);
    if (this.value && this.value.toString().indexOf('.') >= 0) {
      return this.completeDecimalPart(this.value.toString().split('.')[1]);
    }
    return null;
  }

  completeDecimalPart(value) {
    if (this.decimalLength > 0 && value.toString().length < this.decimalLength) {
      return value + '0'.repeat(this.decimalLength - value.toString().length);
    }
    return value + '';
  }

  onBlur(numberPart, event) {
    // console.log("onBlur() %o %o %o", numberPart,event, this.value);

    if (numberPart === 'integer') {
      this.integerValue = Number(this.integerValue) + '';
      if (!this.inputDecimal) {
        this._onTouched();
        this.blur.emit({ value: this.value });
      } else {
        if (event.relatedTarget !== this.inputDecimal.nativeElement) {
          this._onTouched();
          this.blur.emit({ value: this.value });
        }
      }
    }

    if (numberPart === 'decimal') {
      // console.log("qu2");
      this.decimalValue = this.completeDecimalPart(this.decimalValue);
      if (event.relatedTarget !== this.inputInteger.nativeElement) {
        this._onTouched();
        this.blur.emit({ value: this.value });
      }
    }

    //this.blur.emit();
  }

  onFocus(numberPart, event) {
    this.focus.emit(event);
  }

  onKeyUp(event) {

    var key = event.which || event.keyCode;
    console.log("conKeyup %o inputDecimal %o key %o", event, this.inputDecimal, key);
    if (key === 188 || key === 190 || key === 110) {
      event.preventDefault();
      this.inputDecimal.nativeElement.focus();
      this.inputDecimal.nativeElement.select();
    }
  }
}
