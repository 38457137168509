import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { UserService } from '../services/user.service';
import { AlertDialogService } from '../services/alert-dialog.service';
import { ClockService } from '../services/clock.service';
import { LoaderService } from "../loader/loader.service";
import { Confirm } from "../dialogs/confirm-dialog/confirm-dialog.component";
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { PreferencesService } from '../services/preferences.service';
import { DataRepositoryService } from '../services/data-repository.service';

@Component({
  selector: 'app-user-widget',
  templateUrl: './user-widget.component.html',
  styleUrls: ['./user-widget.component.css']
})

export class UserWidgetComponent implements OnInit {
  @ViewChild('sidenav', {static:false}) sidenav;
  @ViewChild('btnProfile', {static:false}) btnProfile;
  locales;
  actualLg;
  clientId;
  constructor(public userService: UserService, private alertDialog: AlertDialogService,
              public loaderService: LoaderService, public translate: TranslateService,
              private prefServices: PreferencesService,
              public dataRepo: DataRepositoryService, public clockService: ClockService) {
    this.clientId = environment['clientId'];
    this.locales = environment['locales'];
    let acLg = this.translate.currentLang;
    for (let a = 0; a < this.locales.length; a = a + 1) {
      if (this.locales[a].value == acLg)
        this.actualLg = this.locales[a];
    }
  }

  ngOnInit() {

    //console.log('environment %o %o', environment,environment['locales']);
    this.userService.getCurrentUser().subscribe((user) => {
      // console.log('logged user object %o', user);
    });
  }

  openNavProfile() {
    // this.sidenav.style.width ="250px";
    this.sidenav.nativeElement.classList.add("active");
    // document.getElementById('main').style.marginRight = "290px";
  }

  selectLanguage(value) {
    this.translate.use(value).subscribe(()=>{
      this.prefServices.saveLanguage(this.translate.currentLang);
    });

    for (let a = 0; a < this.locales.length; a = a + 1) {
      if (this.locales[a].value == value)
        this.actualLg = this.locales[a];
    }
  }

  closeNavProfile() {
    this.sidenav.nativeElement.classList.remove("active");
    // document.getElementById('main').style.marginRight = "0";
  }

  changePassword() {
    let confirm: Confirm = {
      title: this.translate.instant('USER-WIDGET.CONFIRMDIALOG.CHANGE-PASSWORD.TITLE'),
      message: this.translate.instant('USER-WIDGET.CONFIRMDIALOG.CHANGE-PASSWORD.MESSAGE'),
      buttonOkText: this.translate.instant('USER-WIDGET.CONFIRMDIALOG.CHANGE-PASSWORD.BUTTONOK'),
      buttonCancelText: this.translate.instant('USER-WIDGET.CONFIRMDIALOG.CHANGE-PASSWORD.BUTTONCANCEL'),
      buttonOkStyle: { 'background-color': '#268B26' },
      buttonCancelStyle: { 'background-color': '#AA3939' },
    };

    this.alertDialog.confirm(confirm).subscribe(result => {
      if (result) {
        this.userService.changePassword().subscribe(response => {
          this.loaderService.hide();
          if (response === true) {
            this.alertDialog.open({
              title: this.translate.instant('USER-WIDGET.ALERTDIALOG.CHANGE-PASSWORD.TITLE'),
              message: this.translate.instant('USER-WIDGET.ALERTDIALOG.CHANGE-PASSWORD.MESSAGE'),
              isError: true
            });
          } else {

          }
        });
      } else {

      }
    });
  }


  @HostListener('document:click', ['$event'])
  clickout(event) {
    //console.log("clickout %o %o", event, this.sidenav.nativeElement);
    if (event.target.offsetParent === null) {
      if (event.target !== this.sidenav.nativeElement) {
        this.closeNavProfile();
      }
    } else if (event.target.offsetParent !== this.btnProfile.nativeElement) {
      this.closeNavProfile();
    }
  }
}
