import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy'
})
export class SortPipe implements PipeTransform {

  transform(array: Array<any>, by: any, reverse: boolean = false, sort: boolean = true, translate: boolean = false, type: string = undefined): any {
    // console.log("sort %o, by %o",array, by);
    if (array) {
      if (sort) {
        if (typeof by === 'string') {
          if (by != "") {
            array.sort((a: any, b: any) => {
              if (a[by] && b[by]) {
                if (type === 'number') {
                  return this.compare(+a[by], +b[by]);
                } else {
                  return this.compare(a[by].toString().toUpperCase(), b[by].toString().toUpperCase());
                }
                // if(a[by].toString().toUpperCase() < b[by].toString().toUpperCase()){
                //   return -1;
                // }else if(a[by].toString().toUpperCase() > b[by].toString().toUpperCase()){
                //   return 1;
                // }else{
                //   return 0;
                // }
              }
              else
                return 0;
            });
          } else {
            array.sort((a: any, b: any) => {
              return this.compare(a, b);
              // if(a.toString().toUpperCase() < b.toString().toUpperCase()){
              //   return -1;
              // }else if(a.toString().toUpperCase() > b.toString().toUpperCase()){
              //   return 1;
              // }else{
              //   return 0;
              // }
            });
          }
        } else if (by instanceof Array) {
          array.sort((a: any, b: any) => {
            // console.log("sort item %o",a[by[0]][by[1]]);
            if (a[by[0]]) {
              return this.compare(a[by[0]][by[1]].toString().toUpperCase(), b[by[0]][by[1]].toString().toUpperCase());
              // if(a[by[0]][by[1]].toString().toUpperCase() < b[by[0]][by[1]].toString().toUpperCase()){
              //   return -1;
              // }else if(a[by[0]][by[1]].toString().toUpperCase() > b[by[0]][by[1]].toString().toUpperCase()){
              //   return 1;
              // }else{
              //   return 0;
              // }
            } else {
              return 0;
            }
          });
        }

        if (reverse) {
          array.reverse();
        }
      }
      // console.log("array sort: %o", array);
    }

    return array;
  }

  private compare(a: any, b: any) {
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  }
}
