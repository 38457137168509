import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material';
import 'rxjs/Rx';

@Component({
  selector: 'ChangelogDialog',
  templateUrl: './changelog.dialog.html',
  styleUrls: ['./changelog.dialog.scss'],
})
export class ChangelogDialog {

  title;
  subtitle;
  _translate;
  _model;

  constructor(
    public dialogRef: MatDialogRef<any>,
  ) {
    this.title = "Versioni";
    this.subtitle = "Versioni!!!";

    this.getData();
  }

  getData() {
    /*var jsonfile = require('jsonfile');
    console.log(jsonfile);
    jsonfile.readFile('/assets/changelog/changelog.it.json', function(err, obj) {
      console.log(obj);
    });*/
  }

  closePopup(): void {
    this.dialogRef.close();
  }
}
