//WebTic user model view


export class webTicUserAddress {

  constructor(
  ) { }

}

export class webTicUserData {

  constructor(
    public id: string,
    public given_name: string,
    public family_name: string,
    public email: string,
    public birthdate: Date
  ) { }
}

export class webTicUserProfiles {

  constructor(
    public id: string
  ) { }
}

export class webTicUserAuth {

  constructor(
  ) { }

}

export class webTicUserOrder {

  constructor(
  ) { }

}

export class webTicUser {

  constructor(
    public user: webTicUserData,
    public profiles: Array<webTicUserProfiles>,
    public auths: Array<webTicUserAuth>,
    public orders: Array<webTicUserOrder>
  ) { }

}

export class webTicSettingsCard {
  constructor(
    public title: string,
    public hcolor: string,
    public image: string,
    public action: string,
    public labelCode: string,
    public cssClass: string,
    public controller: webTicController,
    public useHash: boolean,
    public externalUrl: boolean = false
  ) { }
}

export class webTicController {
  constructor(
    public id: number,
    public labelCode: string,
    public order?: number,
    public path?: string,
    public logoPath?: string,
    public entity?: string,
    public actions?: webTicAction[],
    public hcolor?: string,
    public cssClass?: string
  ) { }

}

export class webTicAction {
  constructor(
    public id: number,
    public order: number,
    public labelCode: string,
    public path: string,
    public entity: string,
    public image: string,
    public isWrite: boolean,
    public isDelete: boolean,
    public isEdit: boolean,
    public hasIdParameter: boolean,
    public isTableManagement: boolean,
    public isSingleTableManagement: boolean,
    public hcolor: string,
    public isTableDetailManagement: boolean = false,
    public isPriceList: boolean = false,
    public isFilmManagement: boolean = false,
    public labelTranslate?: string,
    public isPackageManagement: boolean = false
  ) { }
}

export class webTicMenu {
  constructor(
    public title: string,
    public hcolor: string,
    public labelCode: string,
    public image: string,
    public action: string,
    public useRouterLink: boolean,
    public actionObject?: webTicAction
  ) { }
}

export class webTicApplication {
  constructor(
    public id: number,
    public order: number,
    public labelCode: string,
    public path: string,
    public logoPath: string,
    public entity: string
  ) { }
}

export class FormFieldBase<T>{
  value: T;
  key: string;
  label: string;
  required: boolean;
  order: number;
  controlType: string;

  constructor(options: {
    value?: T,
    key?: string,
    label?: string,
    required?: boolean,
    order?: number,
    controlType?: string
  } = {}) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
  }
}

export interface ProfileDomains {
  idProfile: number,
  name?: string,
  description?: string,
  domains?: Domain[]
}

export interface Domain {
  idDomain: number,
  name?: string,
  description?: string
}

export interface ColWidthByType {
  type: string;
  width: any
}

export interface ColWidthByColName {
  colName: string;
  width: any
}

export interface Permission {
  idPermit: number,
  name: string,
  description: string
}