import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable()
export class ExcelService {
  constructor() { }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportAsExcelFile2Sections(json1: any[], json2: any[], excelFileName: string): void {
    //console.log('exportAsExcelFileSections %o', json1);
    const worksheet1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json1);
    const worksheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json2);
    const workbook: XLSX.WorkBook = { Sheets: { 'cliniche': worksheet1 , 'operatori': worksheet2}, SheetNames: ['cliniche',  'operatori'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportAsExcelFileSections(json1: any[], json2: any[], json3: any[], excelFileName: string): void {
    //console.log('exportAsExcelFileSections %o', json3);
    const worksheet1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json1);
    const worksheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json2);
    const worksheet3: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json3);
    const workbook: XLSX.WorkBook = { Sheets: { 'anagrafica': worksheet1 , 'medici cliniche': worksheet2, 'compensi medici': worksheet3}, SheetNames: ['anagrafica',  'medici cliniche', 'compensi medici' ] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  public exportInvoices(invoices:any,excelFileName: string)
  {
    console.log('export invoices %o',invoices);
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(invoices);
    const workbook: XLSX.WorkBook = { Sheets: { 'fatture': worksheet }, SheetNames: ['fatture']};
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportProduzioni(invoices:any,excelFileName: string)
  {
    console.log('export produzioni %o',invoices);
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(invoices);
    const workbook: XLSX.WorkBook = { Sheets: { 'produzioni': worksheet }, SheetNames: ['produzioni']};
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportAppuntamenti(apps:any,excelFileName: string)
  {
    console.log('export appuntamenti %o',apps);
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(apps);
    const workbook: XLSX.WorkBook = { Sheets: { 'appuntamenti': worksheet }, SheetNames: ['appuntamenti']};
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportGeneric(rows:any,excelFileName: string)
  {
    console.log('export generic %o',rows);
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rows);
    const workbook: XLSX.WorkBook = { Sheets: { 'dati': worksheet }, SheetNames: ['dati']};
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportGenericWithType(rows:any,excelFileName: string,tp:string)
  {
    console.log('export generic %o',rows);
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rows);
    const workbook: XLSX.WorkBook = { Sheets: { tp: worksheet }, SheetNames: [tp]};
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }


  public  exportPianiPagamento(invoices:any,excelFileName: string)
  {
    console.log('export piani %o',invoices);
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(invoices);
    const workbook: XLSX.WorkBook = { Sheets: { 'pagamenti': worksheet }, SheetNames: ['pagamenti']};
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportData(rows: any, columns: any, fn: string) {

    let dta = [];
    let toInsert = {};
    for (let a = 0; a < rows.length; a = a + 1) {
      toInsert = {};
      for (let b = 0; b < columns.length; b = b + 1) {
        if (!((columns[b]['prop'] == '__Check') || (columns[b]['prop'] == '__inv') || (columns[b]['prop'] == '__del'))) {

          toInsert[columns[b]['name']] = rows[a][columns[b]['prop']];

        }
      }
      console.log('push index %o %o', a, toInsert);
      dta.push(toInsert);
    }

    console.log('ROWS %o data %o', rows, dta);
    if (fn == 'fatture') {
      for (let x = 0; x < dta.length; x = x + 1) {
        var datePipe = new DatePipe('en-US');
        dta[x]['Data Documento'] = datePipe.transform(rows[x]['DataIns'], 'dd/MM/yyyy');
        dta[x]['Data Fattura'] = datePipe.transform(rows[x]['DataFatturazione'], 'dd/MM/yyyy');
        if (dta[x]['Stato Avanzamento'] == 'PREFATTURA') {
          dta[x]['Data Fattura'] = 'nd';
        }
        else {
          dta[x]['Data Documento'] = 'nd';

        }
      }
    }
    console.log('export row %o column %o with data %o', rows, columns, dta);
    this.exportAsExcelFile(dta, fn);
  }

}
