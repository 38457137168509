import { TastieraArticoli } from '../articoli/articoli.model'

export class Basket {
    Items: BasketItem [] = [];
    Total: undefined | number = 0;
}

export class BasketItem {
    Id: number;
    Item: TastieraArticoli.Articolo;
    Variants: VariantItem [];
    UnitPrice: number;
    Quantity: number;
    Image: string;
}

export class VariantItem {
    Quantity: number;
    Variant: string;
}