import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

import { MomentUtcDateAdapter } from '../moment-utc-date-adapter';
import { Moment } from 'moment';


export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'dch-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentUtcDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class MonthPickerComponent implements OnInit {

  @Input() lbl: string;
  @Input() fcn: string;
  @Input() fg: FormGroup;
  @Input() rq: boolean;

  @Output() cg: EventEmitter<Moment> = new EventEmitter<Moment>();

  constructor() { }

  ngOnInit() {
  }

  onChange() {
    this.cg.emit(this.fg.controls[this.fcn].value);
  }

  chosenYearHandler(normalizedYear: Moment) {
    this.fg.controls[this.fcn].setValue(normalizedYear);
    this.cg.emit(this.fg.controls[this.fcn].value);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    this.fg.controls[this.fcn].setValue(normalizedMonth);
    this.cg.emit(this.fg.controls[this.fcn].value);
    datepicker.close();
  }

}
