import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GceManagementService } from '../../../services/gce-management.service';
import { LoaderService } from '../../../loader/loader.service';
import * as moment from "moment";
import "moment-timezone";
import { GcePresence, GceClinic, GceInvoicePerson, GceInvoicePersonClinic } from '../../../entities/gce-management';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthGuardService } from '../../../services';

const tz = "Europe/Rome";
const dateFormat = "YYYY-MM-DDTHH:mm:ss";

const dateFormatDate = "YYYY-MM-DD";
const dateFormatHour = "HH:mm";

@Component({
  selector: 'app-gce-dialog-badge-form',
  templateUrl: './gce-dialog-badge-form.component.html',
  styleUrls: ['./gce-dialog-badge-form.component.css']
})
export class GceDialogBadgeFormComponent implements OnInit {
  presence: GcePresence = null;
  person: GceInvoicePerson = null;
  persons: GceInvoicePerson[] = [];
  clinics: GceClinic[] = [];
  personClinics: GceInvoicePersonClinic[] = [];
  isNew: boolean = false;
  submitted: boolean = false;
  fromCalendar: boolean = false;
  form: FormGroup;
  role: string = '';
  usr: string = '';
  dm: string = '';
  isAdmin:  boolean = false;
  constructor(public dialogRef: MatDialogRef<GceDialogBadgeFormComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private gceService: GceManagementService, private loaderService: LoaderService,
    private fb: FormBuilder, private auth: AuthGuardService) {
    let usr = this.auth.getUser();
    console.log('user %o', usr);
    if (usr != null) {

      this.role = usr['ruolo'];
      this.dm = usr['clinica'];

      if (usr['nomeCompleto'] != null && usr['nomeCompleto'].length > 0) {
        this.usr = usr['nomeCompleto'];
      }
      else {
        if (usr['email'] != null && usr['email'].length > 0) {
          this.usr = usr['email'];
        }
      }
      if (this.role.toLowerCase().indexOf('amministratore') == 0 || this.role.toLowerCase() == 'hr medici')
      {
        this.isAdmin = true;
      }
    }
    console.log('OPEN FORM  with role %o', this.role);
    
    this.isNew = data.isNew;
    this.presence = data.presence;
    // this.persons = [...gceService.dataRepoInvoicePersons];
    this.fromCalendar = !!data.fromCalendar;
    if (!!this.presence.idfatturazione) {
      this.person = this.gceService.dataRepoInvoicePersons.find((person: GceInvoicePerson) => person.idfatturazione === this.presence.idfatturazione);
      this.loadClinics(this.person);
      if (this.clinics.length === 1 && !this.presence.cod_clinica) {
        this.presence.cod_clinica = this.clinics[0].code_c;
      }
    }
    this.createForm();
    if (!this.isNew || this.fromCalendar) {
      this.rebuildForm();
    }
  }

  ngOnInit() {
    /*
    let usr = this.auth.getUser();
    console.log('user %o',usr);
    if (usr != null) {

      this.role = usr['ruolo'];
      this.dm = usr['clinica'];

      if (usr['nomeCompleto'] != null && usr['nomeCompleto'].length > 0)
      {
        this.usr = usr['nomeCompleto'];
      }
      else
      {
        if (usr['email'] != null && usr['email'].length > 0)
        {
          this.usr = usr['email'];
        }
      }
    }
    console.log('OPEN FORM  with role %o',this.role);
    this.createForm();
    if(!this.isNew || this.fromCalendar){
      this.rebuildForm();
    }
    */

  }

  createForm() {
    this.form = new FormGroup(this.fb.group({
      idfatturazione: ['', [Validators.required]],
      cod_clinica: ['', [Validators.required]],
      data: [{ value: new Date(), disabled: false }, [Validators.required]],
      disponibilita_dalle: [{ value: '', disabled: false }, [Validators.required]],
      disponibilita_alle: [{ value: '', disabled: false }, [Validators.required]],
      ore_disponibilita: [{ value: '', disabled: true }],
      appuntamenti: ['', [Validators.required]],
      ore_appuntamenti: [0, [Validators.required]],
      presenza_calc: [0, [Validators.required]],
      presenza_forzata_rec: null,
      presenza_forzata_hr: null,
      note: [''],
    }).controls, { updateOn: 'blur' });
  }

  rebuildForm() {
    this.form.patchValue({
      idfatturazione: this.presence.idfatturazione,
      cod_clinica: this.presence.cod_clinica,
      data: this.presence.data,
      disponibilita_dalle: this.presence.disponibilita_dalle ? moment(this.presence.disponibilita_dalle, 'HH:mm:ss').format(dateFormatHour) : '',
      disponibilita_alle: this.presence.disponibilita_alle ? moment(this.presence.disponibilita_alle, 'HH:mm:ss').format(dateFormatHour) : '',
      ore_disponibilita: this.presence.ore_disponibilita ? this.getHoursMinutes(this.presence.ore_disponibilita * 60) : '',
      appuntamenti: this.presence.appuntamenti,
      ore_appuntamenti: this.presence.ore_appuntamenti ? this.getHoursMinutes(this.presence.ore_appuntamenti * 60) : '',
      presenza_calc: this.presence.presenza_calc,
      presenza_forzata_rec: this.presence.presenza_forzata_rec,
      presenza_forzata_hr: this.presence.presenza_forzata_hr,
      note: this.presence.note
    });
  }

  save() {
    this.submitted = true;
    let formData = this.form.getRawValue();
    console.log("GceDialogBadgeFormComponent -> save %o", formData);

    if (this.form.valid) {
      this.loaderService.show();

      let presence: GcePresence = new GcePresence(
        formData.idfatturazione,
        formData.cod_clinica,
        moment(formData.data).format(dateFormatDate),
        formData.disponibilita_dalle,
        formData.disponibilita_alle,
        this.getHoursInNumber(formData.ore_disponibilita),
        formData.appuntamenti,
        this.getHoursInNumber(formData.ore_appuntamenti),
        formData.presenza_calc,
        formData.presenza_forzata_rec,
        formData.presenza_forzata_hr,
        formData.note
      );
      if (this.isNew) {
        this.gceService.insertPresence(presence).subscribe((result: GcePresence) => {
          this.loaderService.hide();
          this.dialogRef.close({ saved: true, presence: result });
        }, err => {

        });
      } else {
        this.gceService.updatePresence(presence).subscribe((result: GcePresence) => {
          this.loaderService.hide();
          this.dialogRef.close({ saved: true, presence: result });
        }, err => {

        });
      }

    }
  }

  cancel() {
    this.dialogRef.close({ saved: false });
  }

  loadClinics(person: GceInvoicePerson) {
    console.log('loadclinic for person %o', person);
    this.gceService.loadClinicsForPerson(person, this.dm, this.role).subscribe(({ personClinics, clinics }) => {
      console.log("loadClinics %o, %o ", personClinics, clinics);
      this.personClinics = personClinics;
      this.clinics = clinics;
    }, err => {

    });
  }

  onFilterPersons(filter: string) {
    console.log("onFilterPersons %o %o", filter, this.person);
    this.person = null;
    this.form.patchValue({ idfatturazione: '', cod_clinica: '' });
    this.clinics = [];
    this.gceService.searchInvoicePerson(filter).subscribe((persons: GceInvoicePerson[]) => {
      console.log("onFilterPersons %o %o", persons, this.person);
      this.persons = persons;
    })
  }

  onSelectedPerson(person: GceInvoicePerson) {
    console.log("selected person %o", person);
    this.persons = [];
    this.person = person;
    this.form.patchValue({ idfatturazione: person.idfatturazione, cod_clinica: '' });
    this.loadClinics(this.person);
  }

  dataChange(event) {

  }

  calculateHours(what: string) {
    let hourFrom = moment(this.form.value['disponibilita_dalle'], dateFormatHour);
    let hourTo = moment(this.form.value['disponibilita_alle'], dateFormatHour);
    if (what === 'to') {
      if (hourTo.diff(hourFrom, 'minutes') < 0) {
        this.form.patchValue({ disponibilita_alle: this.form.value['disponibilita_dalle'] });
      }
    }
    let diff = hourTo.diff(hourFrom, 'minutes');
    if (!!diff) {
      this.form.patchValue({ ore_disponibilita: this.getHoursMinutes(diff) });
    }

    //console.log("%o, %o, %o", hourFrom, hourTo, hourTo.diff(hourFrom, 'minutes'));
  }

  getHoursMinutes(minutes: number) {
    let h = minutes / 60 | 0;
    let m = minutes % 60 | 0;
    let hoursMinutes = moment().hours(h).minutes(m).format(dateFormatHour);
    return hoursMinutes;
  }

  getHoursInNumber(hoursMinutes: string): number {
    let m = moment(hoursMinutes, dateFormatHour);
    let hours = m.hours() * 60;
    let mins = m.minutes();
    let t = +((hours + mins) / 60).toPrecision(2);

    console.log("getHoursInNumber %o %o %o %o", m, hours, mins, t);
    return t;
  }
}
