import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'blobToImage'
})
export class BlobToImagePipe implements PipeTransform {
  constructor( ){ }

  transform(imageObject: any, imageField: any, typeField: any): any {
    //console.log("image %o", imageObject);
    if(imageObject){
      let type= imageObject[typeField];
      let image = imageObject[imageField];

      if(image.startsWith('data:image')){
        return image;
      }else{
        return `data:image/${type};base64,` + image;
      }
    }else{
      return 'assets/img/locandina.jpg';
    }

  }

}
