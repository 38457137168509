import { Component, Inject, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MatDialogRef } from '@angular/material';
import { getGroupCategoriesVariants, Nodo, Articolo, checkSelectedVariantsBeforeAddingItem, CheckSelectedVariantResult, selectVariants } from '../domain/concession-system';
import { TranslateService } from '@ngx-translate/core';
import { CartService, CartItem } from '../../services/cart.service';
import { AlertDialogService } from '../../services/alert-dialog.service';

@Component({
  selector: 'variant-concession-dialog',
  templateUrl: './variants-concession.dialog.html',
  styleUrls: ['./variants-concession.dialog.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VariantConcessionDialog implements OnInit {

  private _groupedVariants: { [idcategoria: number]: Array<Articolo> };

  constructor(
    public dialogRef: MatDialogRef<VariantConcessionDialog>,
    private translateService: TranslateService,
    private cartService: CartService,
    private alertDialogService: AlertDialogService,
    @Inject(MAT_DIALOG_DATA) public nodo: Nodo
  ) {
    this.nodo.articolo.varianti.forEach(v => {
      v.selected = 0;
      v.unselectable = false;
    });
    this._groupedVariants = getGroupCategoriesVariants(this.nodo.articolo.varianti);
  }

  ngOnInit(): void {

  }

  get max_variants(){
    return this.nodo.articolo.maxVarianti;
  }
  get min_variants(){
    return this.nodo.articolo.minVarianti;
  }
  get groupedCategories(){
    return Object.keys(this._groupedVariants);
  }
  getCategoryArticolo = (idcategoria: number) => {
    return this._groupedVariants[idcategoria];
  }
  getCategoryName = (idcategoria: number) => {
    return this._groupedVariants[idcategoria][0].categoria;
  }
  closePopup(): void {
    this.dialogRef.close();
  }
  getArticoloImage = (idarticolo: number) => {
     return this.cartService.getImageById(idarticolo, -1);
  }
  addTocart = () => {
     const result = checkSelectedVariantsBeforeAddingItem(this.nodo);

     if(result === CheckSelectedVariantResult.Passed){
      this.dialogRef.close(true);
     }
     else if(result === CheckSelectedVariantResult.QtyMaxVariantsWrong){
        this.alertDialogService.open({
            message: 'CONCESSION_NOT_RESPECTED_MAX_QTY_VARIANTS',
            isError: false,
            withTranslate: true
        });
  }
     else if(result === CheckSelectedVariantResult.QtyMinVariantsWrong){
        this.alertDialogService.open({
          message: 'CONCESSION_NOT_RESPECTED_MIN_QTY_VARIANTS',
          isError: false,
          withTranslate: true
        });
    }
  }
  selectArticolo = (articolo: Articolo) => {
    if(!articolo.unselectable){
      const result = selectVariants(this.nodo, articolo);
      if(result){
        this.dialogRef.close(true);
      }
    }
  }
  cancelSeletion = () => {
      this.nodo.articolo.varianti.forEach(v => {
            v.selected = 0;
      });
  }
}
