import { Component, Inject, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MatDialogRef } from '@angular/material';
import { ArticoliNegozio, Nodo, Articolo, CustomVariantSelected } from '../domain/concession-system';
import { TranslateService } from '@ngx-translate/core';
import { CartService, CartItem } from '../../services/cart.service';
import { AlertDialogService } from '../../services/alert-dialog.service';

@Component({
  selector: 'scale-quantity-dialog',
  templateUrl: './scale-quantity.dialog.html',
  styleUrls: ['./scale-quantity.dialog.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScaleQuantityDialog implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<ScaleQuantityDialog>,
    private translateService: TranslateService,
    private cartService: CartService,
    private alertDialogService: AlertDialogService,
  ) {
  }

  ngOnInit(): void {

  }
  onScaleValueInserted = (value:number) => {
    this.dialogRef.close(value);
  }
}
