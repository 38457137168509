import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthGuardService } from '../services';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { NotificationsService } from 'angular2-notifications';
import { LoaderService } from '../loader';
import * as MySettings from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { YesNoDialogComponent } from '../order-management/shared/dialogs/yes-no.dialog';
import { Location } from '@angular/common';
import { ForecastsService } from '../services/forecasts.service';
import { ForecastComponent } from '../forecast/forecast.component';

@Component({
  selector: 'app-mngforecast',
  templateUrl: './mngforecast.component.html'
})
export class MngforecastComponent implements OnInit {

  monthNow;
  anni;
  mesi;
  fAnno;
  fMese;
  tableMessages;
  columns;
  rows;
  _images;
  editing = {};
  entryPoint = "forecast";
  subtitle;
  caption;
  backCaption;
  back;
  @ViewChild(ForecastComponent, {static:false}) child;

  constructor(
    private _auth: AuthGuardService,
    private _http: HttpClient,
    private _route: ActivatedRoute,
    private _router: Router,
    private _dialog: MatDialog,
    private _notifications: NotificationsService,
    private _location: Location,
    private _loaderRogges: LoaderService,
    private _forecastsService: ForecastsService
  ) {
    this.monthNow = new Date().getMonth();
    this.mesi = []; this.anni = [];
    this.mesi[0] = "Gennaio";
    this.mesi[1] = "Febbraio";
    this.mesi[2] = "Marzo";
    this.mesi[3] = "Aprile";
    this.mesi[4] = "Maggio";
    this.mesi[5] = "Giugno";
    this.mesi[6] = "Luglio";
    this.mesi[7] = "Agosto";
    this.mesi[8] = "Settembre";
    this.mesi[9] = "Ottobre";
    this.mesi[10] = "Novembre";
    this.mesi[11] = "Dicembre";
    var ind = 0; for (var i = new Date().getFullYear(); i < new Date().getFullYear() + 20; i++) this.anni[ind++] = i;

    this.fAnno = 0;
    this.fMese = new Date().getMonth();
    this.tableMessages = MySettings.environment.tableMessages;
    this._images = MySettings.environment.images;

    this.caption = "Imposta Forecast";
    this.subtitle = "Imposta anno e mese.";
    this.backCaption = "Torna indietro";
    this.back = "store";
  }

  ngOnInit() {
  }

  performAction(i) {
    this.child.performAction(i);
  }

  goBack(act) {
    this.rows = [];
    this._router.navigate(['/']);
  }

  cbimg = "./assets/images/icone/icn_back.png";
  changeBack(src = "") {
    if (src != "") this.cbimg = "./assets/images/icone/" + src;
    else this.cbimg = "./assets/images/icone/icn_back.png";
  }

  salvaImpostazioniForecast() {
    this._loaderRogges.show();
    this._http.post(
      MySettings.environment.ForecastInit,
      {
        "idMonth": this.fMese + 1,
        "idYear": this.anni[this.fAnno],
        "idProfile": this._auth.getProfile(),
        "idDomain": this._auth.getDomain(),
        "sub": this._auth.getGUID()
      }
    )
      .map((res) => {
        this._loaderRogges.hide();
        if (!res['esito']) {
          let data = {};
          data['stato'] = 'confirm_ord';
          data['title'] = "Reset forecast";
          data['desc'] = "Il forecast è già stato inizializzato. Vuoi procedere con il reset del forecast?";
          data['options'] = [{ caption: 'Si', action: 'true' }, { caption: 'No', action: 'false' }];
          let dialogRef = this._dialog.open(YesNoDialogComponent, {
            width: '650px',
            data: data
          });
          var t = this;
          dialogRef.afterClosed().subscribe(result => {
            if (result == "true") {
              this._http.post(
                MySettings.environment.ForceForecast,
                {
                  "idMonth": this.fMese + 1,
                  "idYear": this.anni[this.fAnno],
                  "idProfile": this._auth.getProfile(),
                  "idDomain": this._auth.getDomain(),
                  "sub": this._auth.getGUID()
                }
              )
                .map((res) => {
                  if (res) {
                    this._notifications.success(
                      "OK",
                      "Forecast re-inzializzato con successo.",
                      MySettings.environment.notificationOptions
                    );
                  } else {
                    this._notifications.error(
                      "Errore",
                      "Errore durante l'aggiornamento del forecast: " + res['errCode'],
                      MySettings.environment.notificationOptions
                    );
                  }
                }).subscribe(val => []);
            }
          });
        }
        else
          this._notifications.success(
            "OK",
            "Forecast inzializzato con successo.",
            MySettings.environment.notificationOptions
          );
        return;
      }).subscribe(val => []);
  }



  updateForecast(o) {
    this._loaderRogges.show();
    this._http.post(
      MySettings.environment.UpdateForecast,
      o
    )
      .map((res) => {
        this._loaderRogges.hide();
        if (res['esito'])
          this._notifications.error(
            "Conferma",
            "Forecast aggiornato con successo!",
            MySettings.environment.notificationOptions
          );
        else
          this._notifications.error(
            "Errore",
            "C'è stato un errore critico durante l'aggiornamento del forecast: " + res['errCode'],
            MySettings.environment.notificationOptions
          );
      })
      .subscribe(val => []);
  }

}
