import { Component, OnInit, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { PauseService } from '../global/services/pause.service';
//import { AuthGuardService } from '../../auth/auth.service';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import * as Us from '../global/model/user-preferences/user-preferences.model';
import { isIdentifier } from '@angular/compiler';
import { I18NHtmlParser } from '@angular/compiler/src/i18n/i18n_html_parser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-oper-pause',
  templateUrl: './oper-pause.component.html',
  styleUrls: ['./oper-pause.component.css'],
  
})
export class OperPauseComponent implements OnInit {

  inPause;
  operator;
  operatorText;
  count = 0;

  constructor(public _router: Router, public _translate: TranslateService/*, public _authGuardService: AuthGuardService*/) { this.operatorText = { value: "" }; }

  ngOnInit() {
    if (localStorage.getItem('users') != undefined) {
      let users:Us.User[] = JSON.parse(localStorage.getItem('users'));
      for (let u of users)
        if (u.pause != null && u.pause.finishedAt == null) {
          this.inPause = true;
          this.operatorText.value += "<b>" + u.operatorId + "</b>, ";
          ++this.count;
        }
      
      let ot: string;
      this._translate.get((this.count == 1) ? 'ecr.ecr-paus.1904' : 'ecr.ecr-paus.1905').subscribe((res: string) => {
        ot = res.toString();
        this.operatorText.value = ot + " " + this.operatorText.value.substr(0, this.operatorText.value.length - 2);
      });
    }
  }

  cambiaOperatore () {
    //this._authGuardService.doLogin();
  }
}

@NgModule({
  imports: [
    BrowserAnimationsModule
  ],
  exports: [
    
  ],
  declarations: [
    
  ],
  providers: [ 
    
  ],
})
export class OperPauseModule { }