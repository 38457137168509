import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

export interface Alert {
  title?: string;
  messageCode?: string;
  message?: string;
  isError: boolean;
  error?: any;
  withTranslate?: boolean;
  labelErrorCode?: string;
  isDetail?:boolean;
  detail?: string;
}

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css']
})
export class AlertDialogComponent implements OnInit {
  alert: Alert = {
    title: "Alert",
    messageCode: null,
    message: "",
    isError: false,
    error: null,
    withTranslate: false,
    isDetail: false,
    detail:''
  }

  constructor(public dialogRef: MatDialogRef<AlertDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private translate: TranslateService) {
    console.log("Alert: %o", data);
    this.alert.isDetail = data['isDetail'];
    this.alert.detail = data['detail'];
    if (data.withTranslate) {
      if (data.title) {
        this.translate.get(data.title).subscribe(data => {
          this.alert.title = data;
        });
      }
      else {
        this.alert.title = '';
      }
      if (data.message) {
        this.translate.get(data.message).subscribe(data => {
          this.alert.message = data;
        });
      }
    } else {
      this.alert.title = data.title;
      this.alert.message = data.message;
    }
    this.alert.isError = data.isError;
    this.alert.error = data.error;
    if (data.labelErrorCode) {
      this.alert.labelErrorCode = data.labelErrorCode;
      this.translate.get(data.labelErrorCode).subscribe(data => {
        this.alert.message = data;
      });
    } else {
      this.alert.messageCode = data.messageCode;
      if (this.alert.error) {
        this.alert.message = this.alert.error['message'];
        this.alert.title = this.alert.error['name'];
        this.alert.messageCode = this.alert.error['status'];
      }
    }
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }
}
