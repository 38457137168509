import { GmaStockLeaf } from './gma-stock-leaf';
export class GmaStockCategoria {
    idcategoria: number;
    descr: string;
    qta_scorta_minima?: number;
    qta_scorta_massima?: number;
    childs?: GmaStockCategoria[];
    leafs?: GmaStockLeaf[];
    touched?: boolean;
    new_value?:number;

    constructor();
    constructor(idcategoria: number, descr: string, qta_scorta_minima: number, qta_scorta_massima: number, childs: GmaStockCategoria[], leafs: GmaStockLeaf[]);
    constructor(idcategoria?: number, descr?: string, qta_scorta_minima?: number, qta_scorta_massima?: number, childs?: GmaStockCategoria[], leafs?: GmaStockLeaf[]){
        this.idcategoria = idcategoria;
        this.descr = descr;
        this.qta_scorta_minima = qta_scorta_minima;
        this.qta_scorta_massima = qta_scorta_massima;
        this.childs = childs;
        this.leafs = leafs;
    }
}

export interface IGmaStockCategoriaNode{
    expandable: boolean;
    idcategoria: number;
    descr: string;
    qta_scorta_minima: number;
    qta_scorta_massima: number;
    level: number;
    categoria: GmaStockCategoria;
    isLoading: boolean;
}