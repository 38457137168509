import { Injectable } from '@angular/core';
import { PosConfiguration, ArticoliNegozio } from '../concession-system/domain/concession-system';
import { Permission } from '../app.model';

@Injectable()
export class DataRepositoryService {
  user: any = null;
  isAuth: boolean = false;

  products: any[] = [];
  reasonsToDelete: any[];
  // Concession
  posConfiguration: PosConfiguration = null;
  tastiereVarianti: { [idTastiera: number]: ArticoliNegozio } = {};

  constructor() { }

}
