import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-dialog-data',
  templateUrl: './dialog-data.component.html',
  styleUrls: ['./dialog-data.component.css']
})
export class DialogDataComponent implements OnInit {
  dataEntry: object = {};
  dataListComponent:string;

  constructor(public dialogRef: MatDialogRef<DialogDataComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dataEntry = data;
    this.dataListComponent = data.dataListComponent;

    this.dialogRef.beforeClose().subscribe(()=>{
      //console.log("close : %o", this.dataEntry);
      this.dialogRef.close({data: this.dataEntry['data']});

    });
  }

  ngOnInit() {
  }

}
