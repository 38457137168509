import { Injectable } from '@angular/core';
import { ApiAction, ApiProviderWinticplusService } from './api-provider-winticplus.service';
import { environment } from '../../environments/environment';
import { DataRepositoryService } from './data-repository.service';
import { Observable } from 'rxjs/Rx';
import { GmaStockLeaf } from '../entities/gma-management/gma-stock-leaf';
import { GmaStockCategoria } from '../entities/gma-management/gma-stock-categoria';
import { Observer, merge } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { GmaDynamicFlatNode } from '../entities/gma-management/gma-stock-tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { CollectionViewer, SelectionChange } from '@angular/cdk/collections';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';


//https://forecast.dentalpro.it/api/gma/allCategories
//https://forecast.dentalpro.it/api/gma/allLeafs/1
//https://forecast.dentalpro.it/api/gma/updateTree?id=1


//const actionGetAllCategories = new ApiAction(`${environment.gceAPI}/gma/allCategories/`, (data) => { return data }, 'get');
//const actionGetAlLeafs = new ApiAction(`${environment.gceAPI}/gma/allLeafs/`, (data) => { return data }, 'get');
//const actionPutTres = new ApiAction(`${environment.gceAPI}/gma/updateTree/`, (data) => { return data }, 'put');

const actionGetAllCategories = `${environment.webApi}/api/backoffice/scorte/getScorteCategorieArticoli/`;

const actionGetAllFornitori = `${environment.GetListaMinimoOrdineFornitori}`;

const actionGetAlLeafs = `${environment.webApi}/api/backoffice/scorte/getScorteArticoli/`;

const actionPutTres = `${environment.webApi}/api/backoffice/scorte/setScorteArticoliCategorie/`;

const actionPostMinimo = `${environment.SaveMinimoOrdineFornitore}`;


//const actionGetAllCategories = `${environment.webApi}/api/backoffice/scorte/getScorteCategorieArticol/`;

//const actionGetAlLeafs = `${environment.webApi}/api/backoffice/scorte/getScorteArticol/`;

//const actionPutTres = `${environment.webApi}/api/backoffice/scorte/setScorteArticoliCategorie/`;


@Injectable({
  providedIn: 'root'
})
export class GmaManagementService {
  dataRepoGmaCategories: GmaStockCategoria[];
  dataRepoGmaLeafs: GmaStockLeaf[];
  pyld: any;
  constructor(private apiService: ApiProviderWinticplusService, private dataRepo: DataRepositoryService, private _http: HttpClient) { }


  updateNode(pyld: any): Observable<any> {
    console.log("GmaManagementService.updateNode payload %o", pyld);
    this.pyld = pyld;
    return Observable.create((observer: Observer<any>) => {

      this._http.post(actionPutTres, pyld).subscribe((data: any) => {
        //this.apiService.makeAPICall(actionGetAllCategories, pyld).subscribe((data:any)=>{
        console.log("GmaManagementService.updateNode OK %o", data);
        observer.next(data);
      }, err => {
        console.log("GmaManagementService.updateNode Error %o", err);
        observer.error(err);
      });
    });
  }


  getCategories(pyld: any): Observable<GmaStockCategoria[]> {
    this.pyld = pyld;
    return Observable.create((observer: Observer<GmaStockCategoria[]>) => {

      this._http.post(actionGetAllCategories, pyld).subscribe((data: any) => {
        //this.apiService.makeAPICall(actionGetAllCategories, pyld).subscribe((data:any)=>{
        console.log("GmaManagementService.getCategories OK %o", data);
        //ordino listaSoglieCategoriaArticoli per Categoria
        let dcp = data;
        dcp.listaSoglieCategoriaArticoli.sort((a,b) => (a.Categoria > b.Categoria) ? 1 : ((b.Categoria > a.Categoria) ? -1 : 0));
        for(let a = 0; a < dcp.listaSoglieCategoriaArticoli.length; a++)
        {
          dcp.listaSoglieCategoriaArticoli[a].Children.sort((a,b) => (a.Categoria > b.Categoria) ? 1 : ((b.Categoria > a.Categoria) ? -1 : 0));
          for(let b = 0; b < dcp.listaSoglieCategoriaArticoli[a].Children.length; b++)
          {
            dcp.listaSoglieCategoriaArticoli[a].Children[b].Children.sort((a,b) => (a.Categoria > b.Categoria) ? 1 : ((b.Categoria > a.Categoria) ? -1 : 0));
          }
        }
        this.dataRepoGmaCategories = dcp.listaSoglieCategoriaArticoli.map((d) => {
          return new GmaStockCategoria(
            d.IdCategoria,
            d.Categoria,
            //d.QtaScortaMinima,
            //d.QtaScortaMassima,
            d.ValoreScortaMinima,
            d.ValoreScortaMassima,
            this.parseChilds(d.Children),
            []
          );
        });
        observer.next(this.dataRepoGmaCategories);
      }, err => {
        console.log("GmaManagementService.getCategories Error %o", err);
        observer.error(err);
      });
    });
  }

  getFornitori(pyld: any): Observable<any> {
    return this._http.post(actionGetAllFornitori, pyld);
  }

  saveMinimoFornitore(pyld: any): Observable<any> {
    return this._http.post(actionPostMinimo, pyld);
  }

  private parseChilds(childs: any): GmaStockCategoria[] {
    return childs.map(d => {
      return new GmaStockCategoria(
        d.IdCategoria,
        d.Categoria,
        //d.QtaScortaMinima,
        //d.QtaScortaMassima,
        d.ValoreScortaMinima,
        d.ValoreScortaMassima,
        this.parseChilds(d.Children),
        []
      );
    });
  }

  getLeafs(idCat: any): Observable<GmaStockLeaf[]> {
    this.pyld['IdCategoriaArticolo'] = idCat;
    return Observable.create((observer: Observer<GmaStockLeaf[]>) => {
      this._http.post(actionGetAlLeafs, this.pyld).subscribe((data: any) => {
        //this.apiService.makeAPICall(actionGetAlLeafs, this.pyld).subscribe((data)=>{
        console.log("GmaManagementService.getLeafs %o", data);
        this.dataRepoGmaLeafs = data;
        observer.next(this.dataRepoGmaLeafs);
      }, err => {
        console.log("GmaManagementService.getLeafs %o", err);
        observer.error(err);
      });
    });
  }
}



@Injectable()
export class GmaDynamicDataSource {
  dataChange = new BehaviorSubject<GmaDynamicFlatNode[]>([]);

  get data(): GmaDynamicFlatNode[] {
    return this.dataChange.value;
  }

  set data(value: GmaDynamicFlatNode[]) {
    this._treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(private _treeControl: FlatTreeControl<GmaDynamicFlatNode>, private _gmaService: GmaManagementService) { }

  connect(collectionViewer: CollectionViewer): Observable<GmaDynamicFlatNode[]> {
    this._treeControl.expansionModel.onChange.subscribe(change => {
      if ((change as SelectionChange<GmaDynamicFlatNode>).added || (change as SelectionChange<GmaDynamicFlatNode>).removed) {
        this.handleTreeControl(change as SelectionChange<GmaDynamicFlatNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<GmaDynamicFlatNode>) {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed.slice().reverse().forEach(node => this.toggleNode(node, false));
    }
  }

  toggleNode(node: GmaDynamicFlatNode, expand: boolean) {
    const index = this.data.indexOf(node);
    if (index < 0) return;
    if (node.item instanceof GmaStockCategoria) {
      node.isLoading = true;
      if (expand) {
        if ((node.item.childs && node.item.childs.length > 0)) {
          const nodes = node.item.childs.map(child => {
            return new GmaDynamicFlatNode(child, node.level + 1, true);
          });
          this.data.splice(index + 1, 0, ...nodes);
          this.dataChange.next(this.data);
          node.isLoading = false;
        } else {
          console.log(node.item);
          if (node.item['leafs'] && node.item['leafs'].length > 0) {
            const nodes = node.item['leafs'].map(leaf => {
              return new GmaDynamicFlatNode(leaf, node.level + 1, false);
            });
            this.data.splice(index + 1, 0, ...nodes);
            this.dataChange.next(this.data);
            node.isLoading = false;
          } else {
            this._gmaService.getLeafs(node.item.idcategoria).subscribe((leafs: GmaStockLeaf[]) => {
              console.log('LEAFS %o', leafs);
              node.item['leafs'] = leafs['listaSoglieArticoli'];
              const nodes = leafs['listaSoglieArticoli'].map(lf => {
                console.log('LEAF -----> %o', lf);
                let leaf = {
                  idarticolo: lf.IdArticolo,
                  descr: lf.Articolo,
                  qta_scorta_minima: lf.QtaScortaMinima,
                  qta_scorta_massima: lf.QtaScortaMassima,
                  barcode: lf.Barcode
                };
                return new GmaDynamicFlatNode(leaf, node.level + 1, false);
              });
              this.data.splice(index + 1, 0, ...nodes);

              this.dataChange.next(this.data);
              node.isLoading = false;
            });
          }
        }
      } else {
        let count = 0;
        for (let i = index + 1; i < this.data.length
          && this.data[i].level > node.level; i++ , count++) { }
        this.data.splice(index + 1, count);
        this.dataChange.next(this.data);
        node.isLoading = false;
      }

    }
  }

}