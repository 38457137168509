import { Basket, BasketItem } from '../basket/basket.model';

export class User {
    constructor(){}
    operatorId: string;
    name?: string;
    surname?: string;
    mainLanguage: string;
    layout: string;
    pause?: Pause;
}

export class Pause {
    constructor(){}
    startedAt: number;
    finishedAt?: number;
    whereAmI: string;
    basket?: Basket;
}