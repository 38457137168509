import { Component, Inject, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MatDialogRef } from '@angular/material';
import { ArticoliNegozio, Nodo, Articolo, CustomVariantSelected } from '../domain/concession-system';
import { TranslateService } from '@ngx-translate/core';
import { CartService, CartItem } from '../../services/cart.service';
import { AlertDialogService } from '../../services/alert-dialog.service';

@Component({
  selector: 'variant-custom-dialog',
  templateUrl: './variants-custom.dialog.html',
  styleUrls: ['./variants-custom.dialog.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VariantCustomDialog implements OnInit {

  addVariantsSelected:boolean = true;
  variantsSelected: Array<CustomVariantSelected> = [];

  constructor(
    public dialogRef: MatDialogRef<VariantCustomDialog>,
    private translateService: TranslateService,
    private cartService: CartService,
    private alertDialogService: AlertDialogService,
    @Inject(MAT_DIALOG_DATA) public articoliNegozio: ArticoliNegozio
  ) {
  }

  ngOnInit(): void {

  }
  clickedNode = (nodo: Nodo) => {
     if(nodo.Nodi.length === 0){
          this.variantsSelected.push({
              variant: nodo.articolo,
              isToAdd: this.addVariantsSelected
          });
     }
  }
  removeVariant = (index:number) => {
    this.variantsSelected.splice(index, 1);
  }
  close = () => {
    this.dialogRef.close(this.variantsSelected);
  }
}
