import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { ExcelService } from './excel.service';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';


@Injectable({
  providedIn: 'root'
})
export class SsoMonService {

  constructor(private http: HttpClient,private excelService: ExcelService) { }

  loadLogs(from?: string, to?: string): Observable<any> {
    console.log('[SsoMonServiceFROM][loadLogs] %o TO %o', from, to);

    /*
        if (from) {
          from = moment(from).format('YYYYMMDD');
        } else {
          from = moment().startOf('month').format('YYYYMMDD');
        }
        if (to) {
          to = moment(to).format('YYYYMMDD');
        } else {
          to = moment().format('YYYYMMDD');
        }
    */

    let payload = {
      fromD: from,
      toD: to, fromH: "00", toH: "23", fromM: "00", toM: "59"
    };

    return this.http.post(`${environment.identityServerPatientEndpoint}/patient/logs`, payload, { responseType: 'json' });

  }

  exportLogs(logs:any){
    console.log('[SsoMonServiceFROM][exportLogs]');
    this.excelService.exportAsExcelFile(logs, 'logs');
  }

}
