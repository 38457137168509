import { Injectable } from '@angular/core';
import { ApiAction, ApiProviderWinticplusService } from './api-provider-winticplus.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { DataRepositoryService } from './data-repository.service';

import { Permission } from '../app.model';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  static permissions: Permission[];
  

  constructor(private apiService: ApiProviderWinticplusService, private http: HttpClient, public dataRepo: DataRepositoryService) {
    
  }

  getPermissionByProfile(): Observable<Permission[]> {
    console.log("PermissionService.getPermissionByProfile");
    return Observable.create(observer => {
      const actionGetPermissions = new ApiAction(`${AppConfig.settings.identityServerEndpoint}WinTicPlusProfile/getpermits/`, (data) => { return data }, 'get');
      this.apiService.makeAPICall(actionGetPermissions, { urlParams: this.dataRepo.user['profile'] }, false).subscribe(res => {
        console.log("PermissionService.getPermissionByProfile res => %o", res);
        // this.dataRepo.permissions = res;
        PermissionService.permissions = res;
        observer.next(PermissionService.permissions);
      }, err => {
        //observer.error(err);
      });
    });
  }

  static checkPermission(permissionCode: string): boolean {
    console.log("PermissionService.checkPermission permissions %o permissionCode %o", PermissionService.permissions, permissionCode);
    if (!!PermissionService.permissions && PermissionService.permissions.length > 0) {
      let permission: Permission = PermissionService.permissions.find((perm: Permission) => perm.name === permissionCode);
      console.log("PermissionService.checkPermission permission %o", permission);
      return permission !== null && permission !==undefined;
    }

    return false;
  }
}
