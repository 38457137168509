import { Injectable, Input } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { webTicController, webTicApplication } from '../app.model';
import { ApiProviderWinticplusService } from './api-provider-winticplus.service';
import { UserService } from './user.service';
import { LoaderService } from '../loader/loader.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from './../app.config';
import { NgBooleanPipesModule } from 'ngx-pipes';

@Injectable()
export class ControllersService {
  private _application: BehaviorSubject<webTicApplication>;
  private _controllers: BehaviorSubject<webTicController[]>;
  private dataStore: {
    application: webTicApplication,
    controllers: webTicController[],
    currentController: webTicController
  }
  public _currentController: BehaviorSubject<webTicController>;
  public loaded: boolean;
  constructor(private api: ApiProviderWinticplusService, private userService: UserService, private _http: HttpClient, private loaderService: LoaderService) {
    this.dataStore = {
      application: null,
      controllers: [],
      currentController: null
    };
    this._controllers = <BehaviorSubject<webTicController[]>>new BehaviorSubject([]);
    this._application = <BehaviorSubject<webTicApplication>>new BehaviorSubject({});
    this._currentController = <BehaviorSubject<webTicController>>new BehaviorSubject({});
    this.loaded = false;
    //this.loadData();
  }

  get controllers() {
    //this.loadData();
    return this._controllers.asObservable();
  }

  get application() {
    return this._application.asObservable();
  }

  get currentController(): any {
    return this._currentController.asObservable();
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(resolve, ms));
  }

  set currentController(value: any) {
    //this.loaderService.show();
    if (value == null) {
      this.dataStore.currentController = null;
      this._currentController = <BehaviorSubject<webTicController>>new BehaviorSubject({});
      //this.loaderService.hide();
    } else {
      //(async () => {
      //  this.delay(2500).then(any => {
      /*
        this.controllers.subscribe(controllers => {
          controllers.forEach(controller => {
            if (controller.path.indexOf(value) >= 0) {
              this.dataStore.currentController = controller;
              this._currentController.next(Object.assign({}, this.dataStore).currentController);
            }
          });
          this.loaderService.hide();
        });
        */
      //this.loaderService.hide();
      // });

      //await this.delay(2500);
      this.controllers.subscribe(controllers => {
        controllers.forEach(controller => {
          if (controller.path.indexOf(value) >= 0) {
            this.dataStore.currentController = controller;
            this._currentController.next(Object.assign({}, this.dataStore).currentController);
          }
        });
        //this.loaderService.hide();
      });

      //this.loaderService.hide();
      // })();
      /*
      while (this.loaded == false) {
        console.log("set current: %o", value);
      }
      */
      //old do
    }
  }

  getCurrentController(path: string): Observable<webTicController> {
    return Observable.create(observer => {
      if (path == null) {
        this.dataStore.currentController = null;
        observer.next(null);
      } else {
        this.getControllers().subscribe(controllers => {
          this.dataStore.currentController = controllers.find(controller => controller.path.indexOf(path) >= 0);
          observer.next(this.dataStore.currentController);
        }, err => {
          observer.error(err);
        });
      }
    });
  }

  getControllers(): Observable<webTicController[]> {
    return Observable.create(observer => {
      this.userService.getCurrentUser().subscribe((user) => {
        this.api.makeAPICall("getNavigationList", { urlParams: "1", queryParams: `/${user['profile']}` }).subscribe(data => {
          console.log("data controllers: %o", data);
          this.dataStore.controllers = data['controllers'].map(item => {
            let cssClass = item.logoPath.slice(0, item.logoPath.indexOf('.'));
            return new webTicController(
              item.idController, item.labelCode, item.order,
              item.path, item.logoPath, null, item.entity,
              item.color, cssClass
            );
          });

          observer.next(this.dataStore.controllers);
        }, err => {
          console.log("getControllers err %o", err);
          observer.error(err);
        });
      });
    });
  }

  //AppConfig.settings.identityServerEndpoint + "winticplusnavigation/getnavigationtree/1/"
  //https://identitydentalpro.evolove.it/api/winticplusnavigation/getnavigationtree/1/ + ${user['profile']}
  getNControllers(user: any): Observable<webTicController[]> {
    if (user != null) {
      return Observable.create(observer => {

        this._http.get(AppConfig.settings.identityServerEndpoint + "winticplusnavigation/getnavigationtree/1/" + user['profile']).subscribe(data => {
          console.log("data controllers getNControllers: %o", data);
          this.dataStore.controllers = data['nodes'][0]['controllers'].map(item => {
            let cssClass = item.logoPath.slice(0, item.logoPath.indexOf('.'));
            return new webTicController(
              item.idController, item.labelCode, item.order,
              item.path, item.logoPath, null, item.entity,
              item.color, cssClass
            );
          });

          observer.next(this.dataStore.controllers);
        }, err => {
          console.log("getControllers err %o", err);
          observer.error(err);
        });

      });
    }
    else
      return null;
  }

  getApplication(): Observable<webTicApplication> {
    return Observable.create(observer => {
      this.userService.getCurrentUser().subscribe((user) => {
        this.api.makeAPICall("getNavigationList", { urlParams: "1", queryParams: `/${user['profile']}` }).subscribe(data => {
          console.log("data controllers: %o", data);
          this.dataStore.application = new webTicApplication(
            data.idApplication, data.order, data.labelCode,
            data.path, data.logoPath, data.entity
          );

          observer.next(this.dataStore.application);
        }, err => {
          console.log("getControllers err %o", err);
          observer.error(err);
        });
      });
    });
  }

  /*
   public loadData() {
      console.log("load controllers");
      this.userService.getCurrentUser().subscribe((user)=>{
        this.api.makeAPICall("getNavigationList", { urlParams: "1", queryParams:`/${user['profile']}`}).subscribe(data=>{
          console.log("data controllers: %o", data);
          this.dataStore.application = new webTicApplication(
              data.idApplication, data.order, data.labelCode,
              data.path, data.logoPath, data.entity
            );
  
          this._application.next(Object.assign({}, this.dataStore).application);
  
          this.dataStore.controllers = data['controllers'].map(item=>{
              let cssClass = item.logoPath.slice(0, item.logoPath.indexOf('.'));
              return new webTicController(
                item.idController, item.labelCode, item.order,
                item.path, item.logoPath, null, item.entity,
                item.color, cssClass
              );
          });
  
          this._controllers.next(Object.assign({}, this.dataStore).controllers);
        });
      });
    }
  */
  public loadData(user: any) {
    console.log("load controllers");
    this.api.makeAPICall("getNavigationList", { urlParams: "1", queryParams: `/${user['profile']}` }).subscribe(data => {
      console.log("data controllers: %o", data);
      this.dataStore.application = new webTicApplication(
        data.idApplication, data.order, data.labelCode,
        data.path, data.logoPath, data.entity
      );

      this._application.next(Object.assign({}, this.dataStore).application);

      this.dataStore.controllers = data['controllers'].map(item => {
        let cssClass = item.logoPath.slice(0, item.logoPath.indexOf('.'));
        return new webTicController(
          item.idController, item.labelCode, item.order,
          item.path, item.logoPath, null, item.entity,
          item.color, cssClass
        );
      });

      this._controllers.next(Object.assign({}, this.dataStore).controllers);
      this.loaded = true;
    });
  }

  public loadData2(user: any) {
    console.log("load controllers");
    this.api.makeAPICall("getNavigationList", { urlParams: "1", queryParams: `/${user['profile']}` }).subscribe(data => {
      console.log("data controllers: %o", data);
      this.dataStore.application = new webTicApplication(
        data.idApplication, data.order, data.labelCode,
        data.path, data.logoPath, data.entity
      );

      this._application.next(Object.assign({}, this.dataStore).application);

      this.dataStore.controllers = data['controllers'].map(item => {
        let cssClass = item.logoPath.slice(0, item.logoPath.indexOf('.'));
        return new webTicController(
          item.idController, item.labelCode, item.order,
          item.path, item.logoPath, null, item.entity,
          item.color, cssClass
        );
      });

      this._controllers.next(Object.assign({}, this.dataStore).controllers);
      this.loaded = true;
    });
  }

  init() {
    console.log("init controllers");
    this.userService.getCurrentUser().subscribe((user) => {
      // this.loadData(user);
      this.api.makeAPICall("getNavigationList", { urlParams: "1", queryParams: `/${user['profile']}` }).subscribe(data => {
        console.log("data controllers: %o", data);
        this.dataStore.application = new webTicApplication(
          data.idApplication, data.order, data.labelCode,
          data.path, data.logoPath, data.entity
        );

        this._application.next(Object.assign({}, this.dataStore).application);

        this.dataStore.controllers = data['controllers'].map(item => {
          let cssClass = item.logoPath.slice(0, item.logoPath.indexOf('.'));
          return new webTicController(
            item.idController, item.labelCode, item.order,
            item.path, item.logoPath, null, item.entity,
            item.color, cssClass
          );
        });

        this._controllers.next(Object.assign({}, this.dataStore).controllers);
        this.loaded = true;
      });
    });
  }

}
