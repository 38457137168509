import { Injectable } from '@angular/core';

@Injectable()
export class CommonUtilsService {

  constructor() { }

  static copyArrayData(from: any[]): any[] {
    let to = [];
    from.forEach(item => {
      to.push(this.copyData(item));
    });
    return to;
  }

  static copyData(from: any): any {
    let to: any = {};
    for (let key in from) {
      if (from[key]) {
        if (typeof from[key] === "object") {
          if (from[key] instanceof Array) {
            to[key] = this.copyArrayData(from[key]);
          } else if (from[key] instanceof Date) {
            to[key] = new Date();
            to[key].setTime(from[key].getTime());
          } else if (from[key] instanceof Object) {
            to[key] = this.copyData(from[key]);
          } else {
            to[key] = from[key];
          }
        } else {
          to[key] = from[key];
        }
      } else {
        to[key] = from[key];
      }
    }
    return to;
  }

  static filterInArray(filter, field, array): any {
    if (filter) {
      let filterValues = filter.split(" ").filter(a => !!a);
      let filtered = array.filter(ao => {
        return ao[field].split(" ").filter(filterValue => {
          return filterValues.filter(fv => filterValue.toLowerCase().indexOf(fv.toLowerCase()) != -1).length > 0;
        }).length >= filterValues.length;
      });
      return filtered;
    }
    return undefined;
  }

  static mapInfoTableData(data): any {
    let tableData = {};
    data['tables'].forEach(table => {
      if (table['Columns'] && table['Rows']) {
        tableData[table.tableName] = {
          columns: table['Columns'],
          rows: table['Rows'].map(item => item.Row)
        }
      } else {
        tableData[table.tableName] = {
          columns: [],
          rows: []
        }
      }
      tableData[table.tableName]['validator'] = table['validator'];
    });
    return tableData;
  }
}
