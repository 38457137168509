import { primaryKey, headerCode, inputType, visible, required, length, order, pattern, editable, Describer, GceEntity } from './../gce-management/gce-management';

export class ClinicheDatabase extends GceEntity {
    /*
    @headerCode('DATIBASE.CLINIC.CODE_N')
    @inputType('number')
    @editable(false)
    @visible(false)
    @order(1)
    code_n: number;
*/
    @headerCode('DATIBASE.CLINIC.CODE_C')
    @inputType('text')
    @editable(false)
    @order(2)
    code_c: string;

    @headerCode('DATIBASE.CLINIC.legalentity')
    @inputType('text')
    @editable(true)
    @order(37)
    legalentity: string;

    @headerCode('DATIBASE.CLINIC.nome')
    @inputType('text')
    @editable(true)
    @order(3)
    nome: string;

    @headerCode('DATIBASE.CLINIC.indirizzo')
    @inputType('text')
    @editable(true)
    @order(4)
    indirizzo: string;

    @headerCode('DATIBASE.CLINIC.sede_legale')
    @inputType('text')
    @editable(true)
    @order(38)
    sede_legale: string;

    @headerCode('DATIBASE.CLINIC.partita_iva')
    @inputType('text')
    @editable(true)
    @order(39)
    partita_iva: string;

    @headerCode('DATIBASE.CLINIC.ammnistratore')
    @inputType('text')
    @editable(true)
    @order(40)
    ammnistratore: string;

    @headerCode('DATIBASE.CLINIC.area_manager')
    @inputType('text')
    @editable(true)
    @order(11)
    area_manager: string;

    @headerCode('DATIBASE.CLINIC.distretto')
    @inputType('text')
    @editable(true)
    @order(12)
    distretto: string;

    @headerCode('DATIBASE.CLINIC.riuniti')
    @inputType('number')
    @order(14)
    riuniti: number;

    @headerCode('DATIBASE.CLINIC.direttore_sanitario')
    @inputType('text')
    @editable(true)
    @order(13)
    direttore_sanitario: string;

    @headerCode('DATIBASE.CLINIC.id_direttore_sanitario')
    @inputType('text')
    @editable(true)
    @order(13)
    id_direttore_sanitario: number;

    @headerCode('DATIBASE.CLINIC.iscrizione_albo_odontoiatri')
    @inputType('number')
    @editable(false)
    @order(13)
    iscrizione_albo_odontoiatri: number;

    @headerCode('DATIBASE.CLINIC.iscrizione_albo_odontoiatri_prov')
    @inputType('text')
    @editable(false)
    @order(13)
    iscrizione_albo_odontoiatri_prov: string;

    @headerCode('DATIBASE.CLINIC.cluster')
    @inputType('number')
    @order(15)
    cluster: number;

    @headerCode('DATIBASE.CLINIC.regione')
    @inputType('text')
    @editable(true)
    @order(20)
    regione: string;

    @headerCode('DATIBASE.CLINIC.mese_apertura')
    @inputType('date')
    @editable(true)
    @order(26)
    mese_apertura: Date;

    @headerCode('DATIBASE.CLINIC.data_apertura')
    @inputType('date')
    @editable(true)
    @order(27)
    data_apertura: Date;

    @headerCode('DATIBASE.CLINIC.data_chiusura')
    @inputType('date')
    @editable(true)
    @order(28)
    data_chiusura: Date;

    @headerCode('DATIBASE.CLINIC.brand')
    @inputType('text')
    @editable(true)
    @order(30)
    brand: string;

    @headerCode('DATIBASE.CLINIC.vintage')
    @inputType('number')
    @order(31)
    vintage: number;

    @headerCode('DATIBASE.CLINIC.provincia')
    @inputType('text')
    @editable(true)
    @order(16)
    provincia: string;

    @headerCode('DATIBASE.CLINIC.mall_street')
    @inputType('text')
    @editable(true)
    @order(17)
    mall_street: string;

    @headerCode('DATIBASE.CLINIC.latitudine')
    @inputType('text')
    @editable(true)
    @order(18)
    latitudine: string;

    @headerCode('DATIBASE.CLINIC.longitudine')
    @inputType('text')
    @editable(true)
    @order(19)
    longitudine: string;

    @headerCode('DATIBASE.CLINIC.login_areamanager')
    @inputType('text')
    @editable(true)
    @order(35)
    login_areamanager: string;

    @headerCode('DATIBASE.CLINIC.data_acquisizione')
    @inputType('date')
    @editable(true)
    @order(29)
    data_acquisizione: Date;

    @headerCode('DATIBASE.CLINIC.email_clinic_manager')
    @inputType('text')
    @editable(true)
    @order(8)
    email_clinic_manager: string;

    @headerCode('DATIBASE.CLINIC.login_districtmanager')
    @inputType('text')
    @editable(true)
    @order(36)
    login_districtmanager: string;

    @headerCode('DATIBASE.CLINIC.esclusione_followme')
    @inputType('boolean')
    @editable(true)
    @order(32)
    esclusione_followme: boolean;

    @headerCode('DATIBASE.CLINIC.email_affittuario')
    @inputType('text')
    @editable(true)
    @order(33)
    email_affittuario: string;

    @headerCode('DATIBASE.CLINIC.porta_servizio_dentalcash')
    @inputType('number')
    @editable(true)
    @order(34)
    porta_servizio_dentalcash: number;


    @headerCode('DATIBASE.CLINIC.data_prima_fattura')
    @inputType('date')
    @editable(true)
    @order(24)
    data_prima_fattura: Date;

    @headerCode('DATIBASE.CLINIC.company_navision')
    @inputType('text')
    @editable(true)
    @order(21)
    company_navision: string;

    @headerCode('DATIBASE.CLINIC.data_inizio_dentalcash')
    @inputType('date')
    @editable(true)
    @order(25)
    data_inizio_dentalcash: Date;

    @headerCode('DATIBASE.CLINIC.indicazioni')
    @inputType('text')
    @editable(true)
    @order(7)
    indicazioni: string;
    @headerCode('DATIBASE.CLINIC.email')
    @inputType('text')
    @editable(true)
    @order(9)
    email: string;
    @headerCode('DATIBASE.CLINIC.tel')
    @inputType('text')
    @editable(true)
    @order(10)
    tel: string;
    @headerCode('DATIBASE.CLINIC.fax')
    @inputType('text')
    @editable(true)
    @order(42)
    fax: string;
    @headerCode('DATIBASE.CLINIC.descrizione')
    @inputType('text')
    @editable(true)
    @order(41)
    descrizione: string;
    @headerCode('DATIBASE.CLINIC.ip_privato')
    @inputType('text')
    @editable(true)
    @order(22)
    ip_privato: string;

    @headerCode('DATIBASE.CLINIC.Master_Host')
    @inputType('text')
    @editable(true)
    @order(23)
    Master_Host: string;

    @headerCode('DATIBASE.CLINIC.website')
    @inputType('text')
    @editable(true)
    @order(43)
    website: string;

    @headerCode('DATIBASE.CLINIC.sms_sender')
    @inputType('text')
    @editable(true)
    @order(44)
    sms_sender: string;

    @headerCode('DATIBASE.CLINIC.mail_sender')
    @inputType('text')
    @editable(true)
    @order(45)
    mail_sender: string;

    @headerCode('DATIBASE.CLINIC.iban')
    @inputType('text')
    @order(45)
    iban: string;

    @headerCode('DATIBASE.CLINIC.codice_fiscale')
    @inputType('text')
    @order(46)
    codice_fiscale: string;

    @headerCode('DATIBASE.CLINIC.comune')
    @inputType('text')
    @editable(false)
    @order(5)
    comune: string;

    @headerCode('DATIBASE.CLINIC.cap')
    @inputType('text')
    @editable(false)
    @order(6)
    cap: string;

    @headerCode('DATIBASE.CLINIC.ex_listino')
    @inputType('text')
    @editable(false)
    @order(46)
    ex_listino: string;

    @headerCode('DATIBASE.CLINIC.cbm')
    @inputType('text')
    @editable(false)
    @order(47)
    cbm: string;

    @headerCode('DATIBASE.CLINIC.login_cbm')
    @inputType('text')
    @editable(false)
    @order(48)
    login_cbm: string;


    constructor();
    constructor(
        code_c: string,
        legalentity: string,
        nome: string,
        indirizzo: string,
        sede_legale: string,
        partita_iva: string,
        ammnistratore: string,
        area_manager: string,
        distretto: string,
        riuniti: number,
        direttore_sanitario: string,
        id_direttore_sanitario: number,
        iscrizione_albo_odontoiatri: number,
        iscrizione_albo_odontoiatri_prov: string,
        cluster: number,
        regione: string,
        mese_apertura: Date,
        data_apertura: Date,
        data_chiusura: Date,
        brand: string,
        vintage: number,
        provincia: string,
        mall_street: string,
        latitudine: string,
        longitudine: string,
        login_areamanager: string,
        data_acquisizione: Date,
        email_clinic_manager: string,
        login_districtmanager: string,
        esclusione_followme: boolean,
        email_affittuario: string,
        porta_servizio_dentalcash: number,
        data_prima_fattura: Date,
        company_navision: string,
        data_inizio_dentalcash: Date,
        indicazioni: string,
        email: string,
        tel: string,
        fax: string,
        descrizione: string,
        ip_privato: string,
        Master_Host: string,
        website: string,
        sms_sender: string,
        mail_sender: string,
        comune: string,
        cap: string,
        ex_listino: string,
        iban: string,
        codice_fiscale:string,
        cbm:string,
        login_cbm:string
        );
    /*
    public string code_c { get; set; }
    public string legalentity { get; set; }
    public string nome { get; set; }
    public string indirizzo { get; set; }
    public string sede_legale { get; set; }
    public string partita_iva { get; set; }
    public string ammnistratore { get; set; }
    public string area_manager { get; set; }
    public string distretto { get; set; }
    public Nullable<double> riuniti { get; set; }
    public string direttore_sanitario { get; set; }
    public Nullable<int> cluster { get; set; }
    public string regione { get; set; }
    public Nullable<System.DateTime> mese_apertura { get; set; }
    public Nullable<System.DateTime> data_apertura { get; set; }

    public Nullable<System.DateTime> data_chiusura { get; set; }

    public string brand { get; set; }
    public Nullable<int> vintage { get; set; }
    public string provincia { get; set; }
    public string mall_street { get; set; }
    public string latitudine { get; set; }
    public string longitudine { get; set; }
    public string login_areamanager { get; set; }
    public Nullable<System.DateTime> data_acquisizione { get; set; }
    public string email_clinic_manager { get; set; }
    public string login_districtmanager { get; set; }
    public Nullable<bool> esclusione_followme { get; set; }
    public string email_affittuario { get; set; }
    public Nullable<int> porta_servizio_dentalcash { get; set; }

    public Nullable<System.DateTime> data_prima_fattura { get; set; }
    public string company_navision { get; set; }
    public Nullable<System.DateTime> data_inizio_dentalcash { get; set; }
    public string indicazioni { get; set; }
    public string email { get; set; }
    public string tel { get; set; }
    public string fax { get; set; }
    public string descrizione { get; set; }
    public string ip_privato { get; set; }
    public string Master_Host { get; set; }

    */
    constructor(
        code_c?: string,
        legalentity?: string,
        nome?: string,
        indirizzo?: string,
        sede_legale?: string,
        partita_iva?: string,
        ammnistratore?: string,
        area_manager?: string,
        distretto?: string,
        riuniti?: number,
        direttore_sanitario?: string,
        id_direttore_sanitario?: number,
        iscrizione_albo_odontoiatri?: number,
        iscrizione_albo_odontoiatri_prov?: string,
        cluster?: number,
        regione?: string,
        mese_apertura?: Date,
        data_apertura?: Date,
        data_chiusura?:Date,
        brand?: string,
        vintage?: number,
        provincia?: string,
        mall_street?: string,
        latitudine?: string,
        longitudine?: string,
        login_areamanager?: string,
        data_acquisizione?: Date,
        email_clinic_manager?: string,
        login_districtmanager?: string,
        esclusione_followme?: boolean,
        email_affittuario?: string,
        porta_servizio_dentalcash?: number,
        data_prima_fattura?: Date,
        company_navision?: string,
        data_inizio_dentalcash?: Date,
        indicazioni?: string,
        email?: string,
        tel?: string,
        fax?: string,
        descrizione?: string,
        ip_privato?: string,
        Master_Host?: string,
        website?: string,
        sms_sender?: string,
        mail_sender?: string,
        comune?: string,
        cap?: string,
        ex_listino?: string,
        iban?:string,
        codice_fiscale?:string,
        cbm?:string,
        login_cbm?:string) {
        super();
        this.code_c = code_c || '';
        this.legalentity = legalentity || '';
        this.nome = nome || '';
        this.indirizzo = indirizzo || '';
        this.sede_legale = sede_legale || '';
        this.partita_iva = partita_iva || '';
        this.ammnistratore = ammnistratore || '';
        this.area_manager = area_manager || '';
        this.distretto = distretto || '';
        this.riuniti = riuniti || 0;
        this.direttore_sanitario = direttore_sanitario || '';
        this.id_direttore_sanitario = id_direttore_sanitario || 0;
        this.iscrizione_albo_odontoiatri = iscrizione_albo_odontoiatri || 0;
        this.iscrizione_albo_odontoiatri_prov= iscrizione_albo_odontoiatri_prov || '';
        this.cluster = cluster || 0;
        this.regione = regione || '';
        this.mese_apertura = mese_apertura || new Date();
        this.data_apertura = data_apertura || new Date();
        this.data_chiusura = data_chiusura || new Date();
        this.brand = brand || '';
        this.vintage = vintage || 0;
        this.provincia = provincia || '';
        this.mall_street = mall_street || '';
        this.latitudine = latitudine || '';
        this.longitudine = longitudine || '';
        this.login_areamanager = login_areamanager || '';
        this.data_acquisizione = data_acquisizione || new Date();
        this.email_clinic_manager = email_clinic_manager || '';
        this.login_districtmanager = login_districtmanager || '';
        this.esclusione_followme = esclusione_followme || false;
        this.email_affittuario = email_affittuario || '';
        this.porta_servizio_dentalcash = porta_servizio_dentalcash || 0;
        this.data_prima_fattura = data_prima_fattura|| new Date();
        this.company_navision = company_navision|| '';
        this.data_inizio_dentalcash = data_inizio_dentalcash|| new Date();
        this.indicazioni = indicazioni|| '';
        this.email = email|| '';
        this.tel = tel|| '';
        this.fax = fax|| '';
        this.descrizione = descrizione|| '';
        this.ip_privato = ip_privato || '';
        this.Master_Host = Master_Host || '';
        this.website = website || '';
        this.sms_sender = sms_sender || '';
        this.mail_sender = mail_sender || '';
        this.comune = comune || '';
        this.cap = cap || '';
        this.ex_listino = ex_listino || '';
        this.iban = iban || '';
        this.codice_fiscale = codice_fiscale || '';
        this.cbm = cbm || '',
        this.login_cbm = login_cbm || '';
    }

    static getStructure(): Array<any> {
        let structure: any[] = Describer.getStructure(new ClinicheDatabase());
        structure.push(...this.getActions());
        return structure;
    }


    protected static getActions(): any[] {
        let actions: any[] = [];

        /*
             let actionConfirmation = {
                 action: undefined,
                 callback: 'sendEmail',
                 callbackOnForm: false,
                 columnDef: 'sendEmail',
                 disabled: false,
                 header: 'GCE.GCEINVOICE.CONFIRMATION',
                 dontShowHeader: true,
                 length: 256,
                 lookUp: {},
                 primaryKey: false,
                 reference: null,
                 required: false,
                 requiredOnNew: false,
                 show: true,
                 type: 'custom-action',
                 values: null,
                 visible: true,
                 editable: false,
                 visibleOnToggle: true,
                 imageButton: null,
                 imageTable: 'assets/img/invoice-confirm.png',
                 order: 0
             };
             actions.push(actionConfirmation);
 */
        return actions;
    }


}