import * as moment from 'moment';
const dateFormat = "YYYY-MM-DD";

export class GceEnvironment {
  idunico:number;
  mese_lavoro:Date;
  ultima_chiusura_incentive:Date;

  constructor();
  constructor(idunico:number, mese_lavoro:Date, ultima_chiusura_incentive:Date);
  constructor(idunico?:number, mese_lavoro?:Date, ultima_chiusura_incentive?:Date){
    this.idunico = idunico;
    this.mese_lavoro = mese_lavoro;
    this.ultima_chiusura_incentive = ultima_chiusura_incentive;
  }

  public getFromDate():string {
    let m = moment(this.mese_lavoro);
    return m.startOf('month').format(dateFormat);
  }

  public getToDate():string {
    let m = moment(this.mese_lavoro);
    return m.endOf('month').format(dateFormat);
  }
}
