import { primaryKey, visible, headerCode, inputType, relation, length, order, currency, pattern, editable, Describer, GceEntity, roles } from '../gce-management/gce-management';

export class AffittuariMailMensile extends GceEntity {

    @order(1)
    @headerCode('AFFMM.PRINC_ENTITY.MESE')
    @inputType('date')
    @length(255)
    mese: Date | string;

    @order(2)
    @headerCode('AFFMM.PRINC_ENTITY.CLINICA')
    @inputType('select')
    @length(3)
    @visible(false)
    @relation("GceClinic", { columnRel: 'code_c', columnDescr: ['nome'] })
    cod_clinica: string;

    @order(2)
    @headerCode('AFFMM.PRINC_ENTITY.CLINICA')
    @inputType('text')
    @length(100)
    clinica: string;

    @order(3)
    @inputType('hidden')
    @length(255)
    idclinica: string;

    @order(7)
    @headerCode('AFFMM.PRINC_ENTITY.IMP_PRODUZIONE')
    @inputType('numberonlyadmin')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @roles(['Amministratore'])
    importo_produzione: number;

    @order(8)
    @headerCode('AFFMM.PRINC_ENTITY.IMP_FATTURATO')
    @inputType('numberonlyadmin')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @roles(['Amministratore'])
    importo_fatturato: number;

    @order(9)
    @headerCode('AFFMM.PRINC_ENTITY.GIRO_AFFARI')
    @inputType('number')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    giro_affari: number;

    @order(6)
    @headerCode('AFFMM.PRINC_ENTITY.MAIL_AFFITTUARIO')
    @inputType('editabletextbutton')
    @length(255)
    mail_affittuario: string;

    @order(10)
    @headerCode('AFFMM.PRINC_ENTITY.DATAORA_AG')
    @inputType('date')
    dataora_aggiornamento_importi: Date | string;

    @order(4)
    @headerCode('AFFMM.PRINC_ENTITY.FLG_INVIO')
    @inputType('boolean')
    @editable(true)
    flg_invio_effettuato: boolean;

    @order(5)
    @headerCode('AFFMM.PRINC_ENTITY.INV_DATAORA')
    @inputType('date')
    dataora_invio?: Date | string;

    constructor();
    constructor(mese: Date, clinica: string, cod_clinica: string, idclinica: string, importo_produzione: number, importo_fatturato: number, giro_affari: number, mail_affittuario: string, dataora_aggiornamento_importi: Date, flg_invio_effettuato: boolean, dataora_invio?: Date);
    constructor(mese?: Date, clinica?: string, cod_clinica?: string, idclinica?: string, importo_produzione?: number, importo_fatturato?: number, giro_affari?: number, mail_affittuario?: string, dataora_aggiornamento_importi?: Date | string, flg_invio_effettuato?: boolean, dataora_invio?: Date | string) {
        super();

        this.mese = mese || new Date();
        this.clinica = clinica || '';
        this.cod_clinica = cod_clinica || '';
        this.idclinica = idclinica || '';
        this.importo_produzione = importo_produzione || 0;
        this.importo_fatturato = importo_fatturato || 0;
        this.giro_affari = giro_affari || 0;
        this.mail_affittuario = mail_affittuario || '';
        this.dataora_aggiornamento_importi = dataora_aggiornamento_importi || new Date();
        this.flg_invio_effettuato = flg_invio_effettuato || false;
        this.dataora_invio = dataora_invio || new Date();

    }

    static getStructure(): Array<any> {
        let structure: any[] = Describer.getStructure(new AffittuariMailMensile());
        structure.push(...this.getActions());
        return structure;
    }


    protected static getActions(): any[] {
        let actions: any[] = [];

        /*
             let actionConfirmation = {
                 action: undefined,
                 callback: 'sendEmail',
                 callbackOnForm: false,
                 columnDef: 'sendEmail',
                 disabled: false,
                 header: 'GCE.GCEINVOICE.CONFIRMATION',
                 dontShowHeader: true,
                 length: 256,
                 lookUp: {},
                 primaryKey: false,
                 reference: null,
                 required: false,
                 requiredOnNew: false,
                 show: true,
                 type: 'custom-action',
                 values: null,
                 visible: true,
                 editable: false,
                 visibleOnToggle: true,
                 imageButton: null,
                 imageTable: 'assets/img/invoice-confirm.png',
                 order: 0
             };
             actions.push(actionConfirmation);
 */
        return actions;
    }
}