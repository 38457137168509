import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { AuthGuardService } from '../services';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { NotificationsService } from 'angular2-notifications';
import { LoaderService } from '../loader';
import * as myGlobals from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import * as $ from 'jquery';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { ForecastsService } from '../services/forecasts.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { TransitiveCompileNgModuleMetadata } from '@angular/compiler';

@Component({
  selector: 'app-forecast',
  templateUrl: './forecast.component.html',
  styleUrls: ['./forecast.component.css']
})
export class ForecastComponent implements OnInit {
  
  monthNow;
  anni;
  mesi;
  fAnno;
  fMese;
  tableMessages;
  columns;
  rows;
  _images;
  editing = {};
  entryPoint = "forecast";
  subtitle;
  caption;
  backCaption;
  back;
  internalTest;
  searchKw;
  orderByClinic;
  forecastObject;
  oldRead;
  ruolo;

  @Input() header: string;

  @ViewChild(DatatableComponent, null) table: DatatableComponent;

  constructor(
    private _auth: AuthGuardService,
    private _http: HttpClient,
    private _route: ActivatedRoute,
    private _router: Router,
    private _dialog: MatDialog,
    private _notifications: NotificationsService,
    private _location: Location,
    private _loaderRogges: LoaderService,
    private _forecastsService: ForecastsService
  ) {
    this.internalTest = !true;
    this.searchKw = '';
    this.orderByClinic = 'false';
    this.monthNow = new Date().getMonth();
    this.mesi = []; this.anni = [];
    this.mesi[0] = "Gennaio";
    this.mesi[1] = "Febbraio";
    this.mesi[2] = "Marzo";
    this.mesi[3] = "Aprile";
    this.mesi[4] = "Maggio";
    this.mesi[5] = "Giugno";
    this.mesi[6] = "Luglio";
    this.mesi[7] = "Agosto";
    this.mesi[8] = "Settembre";
    this.mesi[9] = "Ottobre";
    this.mesi[10] = "Novembre";
    this.mesi[11] = "Dicembre";
    this.forecastObject = [];
    var ind = 0; for (var i = new Date().getFullYear(); i < new Date().getFullYear() + 20; i++) this.anni[ind++] = i;
    if (this.header == undefined) this.header = "true";

    this.fAnno = 0;
    this.fMese = new Date().getMonth();
    this.tableMessages = myGlobals.environment.tableMessages;
    this._images = myGlobals.environment.images;

    this.caption = "Forecast";
    this.subtitle = "Completa o consulta la tabella.";
    this.backCaption = "Torna indietro";
    this.back = "store";

    this.ruolo = this._auth.getUser()['ruolo'].toLowerCase();
    var col;
    if (this.ruolo != null && ((this.ruolo.indexOf('district manager') > 0 || this.ruolo.indexOf('district manager') == 0) || (this.ruolo.indexOf('direzione commerciale') > 0 || this.ruolo.indexOf('direzione commerciale') == 0) || (this.ruolo.indexOf('area manager') > 0 || this.ruolo.indexOf('area manager') == 0)) || (this.ruolo.indexOf('amministratore') > 0 || this.ruolo.indexOf('amministratore') == 0)) {
      col = [
        {
          "name": "LOGIN_AREAMANAGER",
          "value": "AREA MANAGER",
          "editable": 0,
          "type": "Int32",
          "format": ""
        },
        {
          "name": "IDAMCLINIC",
          "value": "CODICE",
          "editable": 0,
          "type": "Int32",
          "format": ""
        },
        {
          "name": "DESCROLE",
          "value": "CLINICA/OPERATORE",
          "editable": 1,
          "type": "Int32",
          "format": ""
        },
        {
          "name": "IDMONTH",
          "value": "MESE",
          "editable": 0,
          "type": "Int32",
          "format": ""
        },
        {
          "name": "IDYEAR",
          "value": "ANNO",
          "editable": 0,
          "type": "Int32",
          "format": ""
        },
        {
          "name": "NTARGET",
          "value": "TARGET MESE",
          "editable": 1,
          "type": "Currency",
          "format": "#0.00"
        }
        ,
        {
          "name": "INVOICED",
          "value": "Fatturato mese",
          "editable": 1,
          "type": "Currency",
          "format": "#0.00"
        },
        {
          "name": "NSECURITYENTRY",
          "value": "Entrate sicure nel mese",
          "editable": 1,
          "type": "Currency",
          "format": "#0.00"
        },
        {
          "name": "NEDELAYTOINSERT",
          "value": "Dilazioni da inserire",
          "editable": 1,
          "type": "Currency",
          "format": "#0.00"
        },
        {
          "name": "NDELAYTOPAY",
          "value": "Dilazioni da liquidare",
          "editable": 1,
          "type": "Currency",
          "format": "#0.00"
        },
        {
          "name": "NTOT",
          "value": "Fatturato presunto",
          "editable": 1,
          "type": "Currency",
          "format": "#0.00"
        },
        {
          "name": "NFORECASTENTMONTH",
          "value": "Previsione di fine mese",
          "editable": 1,
          "type": "Currency",
          "format": "#0.00"
        }
      ];
    }
    else {
      col = [
        {
          "name": "LOGIN_AREAMANAGER",
          "value": "AREA MANAGER",
          "editable": 0,
          "type": "Int32",
          "format": ""
        },
        {
          "name": "IDAMCLINIC",
          "value": "CODICE",
          "editable": 0,
          "type": "Int32",
          "format": ""
        },
        {
          "name": "DESCROLE",
          "value": "CLINICA/OPERATORE",
          "editable": 1,
          "type": "Int32",
          "format": ""
        },
        {
          "name": "IDMONTH",
          "value": "MESE",
          "editable": 0,
          "type": "Int32",
          "format": ""
        },
        {
          "name": "IDYEAR",
          "value": "ANNO",
          "editable": 0,
          "type": "Int32",
          "format": ""
        },
        {
          "name": "NTARGET",
          "value": "TARGET MESE",
          "editable": 1,
          "type": "Currency",
          "format": "#0.00"
        }
        ,
        {
          "name": "INVOICED",
          "value": "Fatturato mese",
          "editable": 1,
          "type": "Currency",
          "format": "#0.00"
        },
        {
          "name": "NSECURITYENTRY",
          "value": "Entrate sicure nel mese",
          "editable": 1,
          "type": "Currency",
          "format": "#0.00"
        },
        {
          "name": "NEDELAYTOINSERT",
          "value": "Dilazioni da inserire",
          "editable": 1,
          "type": "Currency",
          "format": "#0.00"
        },
        {
          "name": "NDELAYTOPAY",
          "value": "Dilazioni da liquidare",
          "editable": 1,
          "type": "Currency",
          "format": "#0.00"
        },
        {
          "name": "NTOT",
          "value": "Fatturato presunto",
          "editable": 1,
          "type": "Currency",
          "format": "#0.00"
        }
      ];
    }
    this.columns = [];
    for (let c of col) {
      this.columns.push({ name: c.value, prop: c.name, type: c.type, editable: c.editable, format: c.format != undefined ? c.format : "" });
    }
    console.log('COLUMNS for FORECAST %o', this.columns);

    //$('#my-ngx').css('height', (window.innerHeight - 450) + 'px');
  }

  public testJSON(): Observable<any> {
    return this._http.get("./assets/test.json")
  }

  setOffset() {
    //search pageNumber for searchKw
    if (this.searchKw == null || this.searchKw.length == 0)
      return;
    this.searchKw = this.searchKw.toLowerCase();
    let i = 0;
    var notFind = true;
    //console.log('search for %o', this.searchKw);
    do {
      if (this.rows[i].IDAMCLINIC.toLowerCase().indexOf(this.searchKw) == 0) {
        console.log('FIND at %o', i);
        notFind = false;
      }
      if (this.rows[i].LOGIN_AREAMANAGER.toLowerCase().indexOf(this.searchKw) == 0) {
        console.log('FIND at %o', i);
        notFind = false;
      }
      if (this.rows[i].DESCROLE.toLowerCase().indexOf(this.searchKw) == 0) {
        console.log('FIND at %o', i);
        notFind = false;
      }
      if (this.rows[i].IDAMCLINIC.toLowerCase().indexOf(this.searchKw) > 0) {
        console.log('FIND at %o', i);
        notFind = false;
      }
      if (this.rows[i].LOGIN_AREAMANAGER.toLowerCase().indexOf(this.searchKw) > 0) {
        console.log('FIND at %o', i);
        notFind = false;
      }
      if (this.rows[i].DESCROLE.toLowerCase().indexOf(this.searchKw) > 0) {
        console.log('FIND at %o', i);
        notFind = false;
      }
      i++;
    }
    while ((i < this.rows.length) && (notFind == true));

    if (!notFind) {
      //get page for i position
      let o = Math.round(i / (this.table.pageSize));
      //console.log('set offset datatable %o', o);
      this.table.offset = o;
      this.table.bodyComponent.updateOffsetY(o);
    }
  }

  ngOnInit() {
    console.log('INIT FORECAST');
    this.ruolo = this._auth.getUser()['ruolo'].toLowerCase();
    //this.initForecast();
    this.getForecast();
  }

  initForecast() {
  }

  cbimg = "assets/images/icone/icn_back.png";
  changeBack(src = "") {
    if (src != "") this.cbimg = "assets/images/icone/" + src;
    else this.cbimg = "assets/images/icone/icn_back.png";
  }

  changeOrder() {
    if (this.orderByClinic == 'false')
      this.orderByClinic = 'true';
    else
      this.orderByClinic = 'false';
    //this.initForecast();
    this.getForecast();
  }

  focus() {
    $(function () {
      $('#txtQ').select();
      $('#txtQ').focus();
    });
  }

  //forecastObject = [];

  /*
  getForecast() {
    this._loaderRogges.show();
    this._http.post(
      myGlobals.environment.GetForecast,
      {
        "idProfile": this._auth.getProfile(),
        "idDomain": this._auth.getDomain(),
        "sub": this._auth.getGUID(),
        "desc_domain": (this._auth.getTest())['desc_domain'],
        "desc_role":  (this._auth.getTest())['desc_profile'],
        "user_login": (this._auth.getTest())['name']
      }
    )
      .map((res) => {
        var act = (['area manager', 'clinic manager', 'tutor', 'direzione commerciale', 'amministratore', 'amministratore dp'].indexOf(this._auth.getUser()['ruolo'].toLowerCase()) != -1);
        
        this._loaderRogges.hide();
        if (res['esito']) {
          //this._images[4].enabled = true;
          //this._images[4].visible = true;
          if ((!this.internalTest && ( res['obj'] == undefined || res['obj'].length == 0)))
            this._notifications.error(
              "Errore",
              "Errore durante il recupero del forecast. Oggetto vuoto.",
              myGlobals.environment.notificationOptions
            );
          else {
            var obj = res['obj'];

            if (this.internalTest) {
              this.testJSON().subscribe(data => {
                obj = data;
                obj = JSON.parse (obj);
              });
            }

            var prec = "";
            var index = -1;
            for (let o of obj) {
              index++;
              if (prec != o['IDAMCLINIC']) {
                prec = o['IDAMCLINIC'];
                var result = obj.filter(obj => {
                  return obj['IDAMCLINIC'] === prec
                });
                var s0 = 0;
                var s1 = 0;
                var s2 = 0;
                var s3 = 0;
                var s4 = 0;
                var s5 = 0;
                var s6 = 0;
                for (let x of result) {
                  s0 += +x['INVOICED'];
                  s1 += +x['NTARGET'];
                  s2 += +x['NSECURITYENTRY'];
                  s3 += +x['NEDELAYTOINSERT'];
                  s4 += +x['NDELAYTOPAY'];
                  s5 += +x['NFORECASTENTMONTH'];
                  //s6 = s6 + x['INVOICED'] +  x['NSECURITYENTRY'] + x['NEDELAYTOINSERT'] + x['NDELAYTOPAY'];
                  s6 = +x['NTOT'];
                }
                this.forecastObject.push({
                  "first": true,
                  "Id": index,
                  "LOGIN_AREAMANAGER": o['LOGIN_AREAMANAGER'],
                  "IDROLE": o['IDROLE'],
                  "DESCROLE": o['DESCCLINIC'],
                  "IDAMCLINIC": o['IDAMCLINIC'],
                  "IDAMCLINIC2": o['IDAMCLINIC'],
                  "DESCCLINIC": o['DESCCLINIC'],
                  "IDMONTH": "",
                  "IDYEAR": "",
                  "INVOICED": s0,
                  "NTARGET": s1,
                  "NSECURITYENTRY": s2,
                  "NEDELAYTOINSERT": s3,
                  "NDELAYTOPAY": s4,
                  "NFORECASTENTMONTH": s5,
                  "NTOT":s6,
                  "DATA_INS": "0001-01-01T00:00:00",
                  "DATA_UPDATE": "0001-01-01T00:00:00"
                });
                //if (index != obj.length - 1) continue;
                index++;
              }

              //index++;
              o['first'] = false;
              //o['Id'] = (index != obj.length - 1) ? index : index + 1;
              o['Id'] = index;
              o['DESCROLE'] = "--- " + o['DESCROLE'];
              o['IDAMCLINIC2'] = o['IDAMCLINIC'];
              o['IDAMCLINIC'] = "";
              o['NTOT'] = o['INVOICED'] + o['NSECURITYENTRY'] + o['NEDELAYTOINSERT'] + o['NDELAYTOPAY'];
              o['IDMONTH'] = this.mesi[o['IDMONTH'] - 1];
              this.forecastObject.push(o);
            }

            this._forecastsService.forecastObject = this.forecastObject;
            this.rows = [...this._forecastsService.forecastObject];
            console.log('-> forecastObject %o', this._forecastsService.forecastObject);
            for(let i of this._images) i.enabled = i.visible = false;
            this._images[4].visibile = act && this.rows != undefined && this.rows.length > 0; 
            this._images[4].enabled = act && this.rows != undefined && this.rows.length > 0;

            $('#my-ngx').css('height', (window.innerHeight - 300) + 'px');

          }
        } else {
          this._notifications.error(
            "Errore",
            "Errore durante il recupero del forecast: " + res['errCode'],
            myGlobals.environment.notificationOptions
          );
        }
      }).subscribe(val => []);
  }
  */

  getForecast() {
    this._loaderRogges.show();
    let self = this;
    this._http.post(
      environment['GetForecast'],
      {
        "idProfile": this._auth.getProfile(),
        "idDomain": this._auth.getDomain(),
        "sub": this._auth.getGUID(),
        "desc_domain": (this._auth.getTest())['desc_domain'],
        "desc_role": (this._auth.getTest())['desc_profile'],
        "user_login": (this._auth.getTest())['name'],
        "order_by_clinic": this.orderByClinic
      }
    )
      .subscribe((res) => {
        var act = (['area manager', 'district manager', 'clinic manager', 'tutor', 'direzione commerciale', 'amministratore', 'amministratore dp'].indexOf(this._auth.getUser()['ruolo'].toLowerCase()) != -1);
        this.oldRead = Date.now();
        if (res['esito']) {
          //this._images[4].enabled = true;
          //this._images[4].visible = true;
          self.rows = self.forecastObject = self._forecastsService.forecastObject = [];
          if ((!self.internalTest && (res['obj'] == undefined || res['obj'].length == 0)))
            self._notifications.error(
              "Errore",
              "Errore durante il recupero del forecast. Oggetto vuoto.",
              myGlobals.environment.notificationOptions
            );
          else {
            var obj = res['obj'];
            /*
            if (this.internalTest) {
              this.testJSON().subscribe(data => {
                obj = data;
                obj = JSON.parse (obj);
              });
            }
            */
            var prec = "";
            var index = -1;
            for (let o of obj) {
              index++;
              if (prec != o['IDAMCLINIC']) {
                prec = o['IDAMCLINIC'];

                /*
                var result = obj.filter(obj => {
                  return obj['IDAMCLINIC'] === prec
                });
                */

                var result = [];
                for (let x = 0; x < obj.length; x = x + 1) {
                  if (obj[x]['IDAMCLINIC'] === prec)
                    result.push(obj[x]);
                }
                var s0 = 0;
                var s1 = 0;
                var s2 = 0;
                var s3 = 0;
                var s4 = 0;
                var s5 = 0;
                var s6 = 0;
                for (let x of result) {
                  s0 += +x['INVOICED'];
                  s1 += +x['NTARGET'];
                  s2 += +x['NSECURITYENTRY'];
                  s3 += +x['NEDELAYTOINSERT'];
                  s4 += +x['NDELAYTOPAY'];
                  s5 += +x['NFORECASTENTMONTH'];
                  s6 = s6 + x['INVOICED'] + x['NSECURITYENTRY'] + x['NEDELAYTOINSERT'] + x['NDELAYTOPAY'];
                  //s6 = +x['NTOT'];
                }
                self.forecastObject.push({
                  "first": true,
                  "Id": index,
                  "LOGIN_AREAMANAGER": o['LOGIN_AREAMANAGER'],
                  "IDROLE": o['IDROLE'],
                  "DESCROLE": o['DESCCLINIC'],
                  "IDAMCLINIC": o['IDAMCLINIC'],
                  "IDAMCLINIC2": o['IDAMCLINIC'],
                  "DESCCLINIC": o['DESCCLINIC'],
                  "IDMONTH": "",
                  "IDYEAR": "",
                  "INVOICED": s0,
                  "NTARGET": s1,
                  "NSECURITYENTRY": s2,
                  "NEDELAYTOINSERT": s3,
                  "NDELAYTOPAY": s4,
                  "NFORECASTENTMONTH": s5,
                  "NTOT": s6,
                  "DATA_INS": "0001-01-01T00:00:00",
                  "DATA_UPDATE": "0001-01-01T00:00:00"
                });
                //if (index != obj.length - 1) continue;
                index++;
              }

              //index++;
              o['first'] = false;
              //o['Id'] = (index != obj.length - 1) ? index : index + 1;
              o['Id'] = index;
              o['DESCROLE'] = "--- " + o['DESCROLE'];
              //o['NFORECASTENTMONTH']= 0;
              o['IDAMCLINIC2'] = o['IDAMCLINIC'];
              o['IDAMCLINIC'] = "";
              o['NTOT'] = o['INVOICED'] + o['NSECURITYENTRY'] + o['NEDELAYTOINSERT'] + o['NDELAYTOPAY'];
              o['IDMONTH'] = self.mesi[o['IDMONTH'] - 1];
              self.forecastObject.push(o);
            }

            self._forecastsService.forecastObject = self.forecastObject;
            self.rows = [...self._forecastsService.forecastObject];
            console.log('-> forecastObject %o', this._forecastsService.forecastObject);

            for (let i of this._images) i.enabled = i.visible = false;
            this._images[4].visibile = act && this.rows != undefined && this.rows.length > 0;
            this._images[4].enabled = act && this.rows != undefined && this.rows.length > 0;

            $('#my-ngx').css('height', (window.innerHeight - 450) + 'px');
            self._loaderRogges.hide();

          }
        } else {
          self._loaderRogges.hide();
          self._notifications.error(
            "Errore",
            "Errore durante il recupero del forecast: " + res['errCode'],
            myGlobals.environment.notificationOptions
          );
        }
      });
  }

  onSelect({ selected }) { console.log(selected); }

  performAction(i) {
    if (i.action == 'confirm')
      if (this._images[4].enabled) {
        this._loaderRogges.show();
        let self = this;
        let timeNow = Date.now();
        if ((timeNow - this.oldRead) < 180000) {
          
          
          this._forecastsService.updateForecast('/mngforecast').subscribe(res => {
            this._loaderRogges.hide();
            if (res['esito']) {
              self._notifications.success(
                "Conferma",
                "Forecast aggiornato con successo!",
                myGlobals.environment.notificationOptions
              );
              console.log('res from updateforecast %o', res);
              //self.rows = [];
              //self.forecastObject = [];
              self.getForecast();
            }
            else
              self._notifications.error(
                "Errore",
                "C'è stato un errore critico durante l'aggiornamento del forecast: " + res['errCode'],
                myGlobals.environment.notificationOptions
              );
            });
            
        }
        else {
          window.alert('Attenzione: la tua sessione di lavoro sui dati forecast è scaduta, ricarica la pagina prima di effettuare modifiche.');
          this._loaderRogges.hide();
        }
      }
  }

  updateValueForecast(e, p, id, rowIndex, x, row) {
    console.log('updateValueForecast e %o p %o id %o row %o', e, p, id, row);

    if (e.type == 'keypress' && e.keyCode == 13) {
      this.editing[id + '-' + p] = false;
      return;
    }

    if (x != undefined && !x) { this.editing[id + '-' + p] = false; }

    if (e.target.value == "") e.target.value = 0;

    var myid = "0";
    for (let r of this.rows)
      if (r['Id'] == id) {
        r[p] = e.target.value;
        myid = r['IDAMCLINIC2'];
        break;
      }

    if (p == 'NFORECASTENTMONTH') {
      //make all rows with zero
      for (let r of this.rows) {
        if (r['IDAMCLINIC2'] == row['IDAMCLINIC'] && !(r['first'])) {
          //console.log('row %o', r);
          r['NFORECASTENTMONTH'] = 0;
        }
      }
    }

    for (let r of this.rows)
      if (r['Id'] == id) {
        // if (!environment['production']) console.log('CALCULATE VALUE %o ',e.target.value);
        r[p] = e.target.value;
        break;
      }

    if (p == "NTARGET" && (true || this._auth.getUser()['ruolo'].toLowerCase().indexOf("direzione commerciale") >= 0 || this._auth.getUser()['ruolo'].toLowerCase().indexOf("amministratore") >= 0)) {
      this.forecastObject = this.rows;
      return;
    }

    var tot = 0;
    for (let r of this.rows)
      if (r['IDAMCLINIC2'] == myid && !r['first']) {
        //if (!environment['production']) console.log('CALCULATE TOTAL PROGRESSIVE %o ',tot);
        tot += +r[p];
      }

    var xyz = ""; var xyzi = rowIndex;
    do { xyz = this.rows[xyzi--]['IDAMCLINIC']; } while (xyz == "");
    if (p == 'NTARGET') {
      for (let r of this.rows)
        if (r['first'] === true && r['IDAMCLINIC'] == xyz) {
          if (!environment['production']) console.log('CALCULATE VALUE B %o ', tot);
          r[p] = tot;
          break;
        }
    }

    this.forecastObject = this.rows;
  }

  updateValueForecastN(e, p, id, rowIndex, x, row) {
    //console.log('updateValueForecastN e %o p %o id %o row %o', e, p, id, row);
    if (e.type == 'keypress' && e.keyCode == 13) {
      this.editing[id + '-' + p] = false;
      return;
    }

    if (e.target.value == "") e.target.value = "Sconosciuto";

    if (x != undefined && !x) { this.editing[id + '-' + p] = false; }

    for (let r of this.rows)
      if (r['Id'] == id) {
        r[p] = e.target.value;
        break;
      }

    this.forecastObject = this.rows;
  }

  getRowClassForecast(row) {
    var x = row['first'] != undefined && row['first'] == true;
    return { 'sfondoAzz': x };
  }

  changeForecastMonth(e, row) {
    //row['IDMONTH'] = 1 + this.mesi.indexOf(e.target.value);
    row['IDMONTH'] = e.target.value;
  }

  editForecast(row, prop) {
    //console.log('MA');
    
    //r = "amministratore";
    if ((this.ruolo.indexOf('area manager') >= 0 || this.ruolo.indexOf('district manager') >= 0) && ['DESCROLE', 'NTARGET', 'NSECURITYENTRY', 'NEDELAYTOINSERT', 'NDELAYTOPAY', 'INVOICED'].indexOf(prop) != -1 && !row['first']) return true;
    if ((this.ruolo.indexOf('area manager') >= 0 || this.ruolo.indexOf('district manager') >= 0) && ['NFORECASTENTMONTH'].indexOf(prop) != -1 && row['first']) return true;
    //if (r.indexOf('area manager') >= 0 && ['NFORECASTENTMONTH'].indexOf(prop) != -1 && row['first']) return true;
    if ((this.ruolo.indexOf('clinic manager') >= 0 || this.ruolo.indexOf('tutor') >= 0 || this.ruolo.indexOf('rec') >= 0) && ['NSECURITYENTRY', 'NEDELAYTOINSERT', 'NDELAYTOPAY', 'INVOICED'].indexOf(prop) != -1 && !row['first']) return true;
    //if (r.indexOf('direzione commerciale') >= 0 && ['NTARGET'].indexOf(prop) != -1 && row['first']) return true;
    //if (r.indexOf('direzione commerciale') >= 0 && ['NSECURITYENTRY', 'NEDELAYTOINSERT', 'NDELAYTOPAY', 'NTARGET', 'NFORECASTENTMONTH'].indexOf(prop) != -1 && row['first']) return true;
    if (this.ruolo.indexOf('direzione commerciale') >= 0 && ['NTARGET', 'NFORECASTENTMONTH'].indexOf(prop) != -1 && row['first']) return true;
    if (this.ruolo.indexOf('amministratore') >= 0 && ['NSECURITYENTRY', 'NEDELAYTOINSERT', 'NDELAYTOPAY', 'NTARGET', 'NFORECASTENTMONTH', 'INVOICED'].indexOf(prop) != -1 && row['first']) return true;
    if (this.ruolo.indexOf('direzione commerciale') >= 0 && ['DESCROLE', 'NSECURITYENTRY', 'NEDELAYTOINSERT', 'NDELAYTOPAY', 'INVOICED'].indexOf(prop) != -1 && !row['first']) return true;
    //if (r.indexOf('direzione commerciale') >= 0 && ['DESCROLE'].indexOf(prop) != -1 && !row['first']) return true;
    if (this.ruolo.indexOf('amministratore') >= 0 && ['DESCROLE'].indexOf(prop) != -1 && !row['first']) return true;
    return false;
  }

  removeOthers(name) {
    this.editing = [{}];
    this.editing[name] = true;
  }

  calculateWidthColumn(col): number {
    document.getElementById('columnAdapter').innerHTML = col.toUpperCase();
    var d = getComputedStyle(document.getElementById('columnAdapter')).width;

    var dim = parseInt((d + "").substring(0, (d + "").length - 2));
    //console.log(dim);
    dim = 10 + dim;
    //console.log(col + ": " + dim);
    return dim;
  }
  /*
    calculateTable(force = false) {
      var mh = window.innerHeight - (document.getElementById('my-ngx').getBoundingClientRect()).top - 150 - (document.getElementById('infoAggiuntiveOrdine') != undefined ? document.getElementById('infoAggiuntiveOrdine').offsetHeight : 0) + "px";
      console.log('return heigth %o', mh);
      return { 'height': mh + ' !important' };
    }
  */
  isCurrency(t, f) {
    return ((t == 'Currency') && f != '' && f !== undefined);
  }

  hasClass(who, asc) {
    if (!asc && document.getElementsByClassName('sort-desc').length == 0) return true;
    if (asc && document.getElementsByClassName('sort-asc').length == 0) return true;
    if (!asc)
      for (let x of Array.prototype.slice.call(document.getElementsByClassName('sort-desc')))
        try {
          return !(who == x.children[0].children[0].children[0].children[1].children[1].innerHTML.trim());
        }
        catch (e) {
          return true;
        }
    if (asc)
      for (let x of Array.prototype.slice.call(document.getElementsByClassName('sort-asc')))
        try {
          return !(who == x.children[0].children[0].children[0].children[1].children[1].innerHTML.trim());
        }
        catch (e) {
          return true;
        }
    return true;
  }

  onClick(clicked) {

  }

  goBack(act) {
    this.rows = [];
    this._router.navigate(['/']);
  }

}