import { Component, OnInit } from '@angular/core';
import { AuthGuardService } from '../services';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { DialogConsentsComponent } from '../dialogs/dialog-consents/dialog-consents.component';
import { NotificationsService } from 'angular2-notifications';
import { LoaderService } from '../loader';
import { ForecastsService } from '../services/forecasts.service';
import * as MySettings from '../../environments/environment';

@Component({
  selector: 'app-carotaggio',
  templateUrl: './carotaggio.component.html',
  styleUrls: ['./carotaggio.component.css']
})
export class CarotaggioComponent implements OnInit {

  monthNow;
  anni;
  mesi;
  fAnno;
  fMese;
  tableMessages;
  columns;
  col;
  rows;
  consents;
  _images;
  editing = {};
  entryPoint = "crtg";
  subtitle;
  caption;
  backCaption;
  back;
  show = false;

  filters = [];

  constructor(
    private _auth: AuthGuardService,
    private _http: HttpClient,
    private _route: ActivatedRoute,
    private _router: Router,
    private _dialog: MatDialog,
    private _notifications: NotificationsService,
    private _loaderRogges: LoaderService,
    private _forecastsService: ForecastsService
  ) {
    this.tableMessages = MySettings.environment.tableMessages;
    this._images = MySettings.environment.images;

    this.caption = "INQUIRY";
    this.subtitle = "Imposta uno o più filtri per avviare la ricerca.";
    this.backCaption = "Torna indietro";
    this.back = "store";
    this.show = false;

    this.filters = [
      { id: 0, name: "Nominativo/Data nascita", obj: [{ val: "", prop: "nome", name: "Nome", req: true }, { val: "", prop: "cognome", name: "Cognome", req: true }, { val: "", prop: "dataDiNascita", name: "Data di nascita (formato gg-mm-aaaa oppure aaaa-mm-gg)", dateType: true, req: false }] },
      { id: 1, name: "Codici paziente/clinica", obj: [{ val: "", prop: "idPaziente", name: "Codice paziente", req: true }, { val: "", prop: "idClinica", name: "Codice clinica", req: true }] },
      { id: 2, name: "Codice fiscale", obj: [{ val: "", prop: "fiscalCode", name: "Codice Fiscale", req: true }] },
      { id: 3, name: "Numero telefonico", obj: [{ val: "", prop: "nTelefono", name: "Numero di telefono", req: true }] },
      { id: 4, name: "Indirizzo e-mail", obj: [{ val: "", prop: "email", name: "Indirizzo e-mail", req: true }] },
    ];

    this.col = [
      { type: "string", "editable": 0, format: "", length: "70", name: "pazienteCodice", value:"codice" },
      { type: "string", "editable": 0, format: "", length: "100", name: "pazienteCognome", value:"Cognome" },
      { type: "string", "editable": 0, format: "", length: "100", name: "pazienteNome", value:"Nome" },
      { type: "string", "editable": 0, format: "", length: "100", name: "abapp", value: "App" },
      { type: "string", "editable": 0, format: "", length: "140", name: "pazienteClinicaCreazione", value: "Clinica di creazione" },
      { type: "string", "editable": 0, format: "", length: "140", name: "pazienteClinicaPrincipale", value: "Clinica principale" },
      { type: "string", "editable": 0, format: "", length: "50", name: "pazienteSesso",value:"Genere" },
      { type: "string", "editable": 0, format: "", length: "100", name: "pazienteLocalitaNascita", value: "Comune nascita" },
      { type: "string", "editable": 0, format: "", length: "50", name: "pazienteProvinciaNascita", value: "Provincia nascita" },
      { type: "string", "editable": 0, format: "", length: "100", name: "pazienteDataNascita", value: "Data nascita" },
      { type: "string", "editable": 0, format: "", length: "150", name: "pazienteIndirizzo",value:"Indirizzo" },
      { type: "string", "editable": 0, format: "", length: "100", name: "pazienteLocalita" , value:"Comune"},
      { type: "string", "editable": 0, format: "", length: "100", name: "pazienteProvincia",value:"Provincia" },
      { type: "string", "editable": 0, format: "", length: "50", name: "pazienteCap", value:"cap" },
      { type: "string", "editable": 0, format: "", length: "100", name: "pazienteTelefono" , value:"Tel 1"},
      { type: "string", "editable": 0, format: "", length: "100", name: "pazienteTelefono2", value:"Tel 2" },
      { type: "string", "editable": 0, format: "", length: "100", name: "pazienteTelefono3", value:"Tel 3" },
      { type: "string", "editable": 0, format: "", length: "100", name: "pazienteCodiceFiscale" , value:"Codice Fiscale"},
      { type: "string", "editable": 0, format: "", length: "100", name: "pazienteEmail",value:"Email" },
      { type: "string", "editable": 0, format: "", length: "100", name: "pazientePec",value:"Pec" },
      { type: "string", "editable": 0, format: "", length: "100", name: "pazienteProfessione",value:"Professione" }
    ];

    this.columns = [];
    for (let c of this.col)
      this.columns.push({ name: (c['value'] == undefined ? c['name'] : c['value']), prop: c.name, length: c.length, type: c.type, editable: c.editable, format: c.format != undefined ? c.format : "" });
  }

  ngOnInit() {
  }

  openDettaglio(consents: any) {

    //search for consents with row.id in this.rows
    console.log('open consents %o',consents);
    const dialogRef = this._dialog.open(DialogConsentsComponent, {
      height: 'auto',
      data: { consents: consents }
    });

    //console.log('open dettaglio %o of row %o',this.consents[i],row);
  }

  cbimg = "assets/images/icone/icn_back.png";
  changeBack(src = "") {
    if (src != "") this.cbimg = "assets/images/icone/" + src;
    else this.cbimg = "assets/images/icone/icn_back.png";
  }

  focus() {
    $(function () {
      $('#txtQ').select();
      $('#txtQ').focus();
    });
  }

  sel = 0;
  onChange(s) {
    for (let x of this.filters[this.sel].obj) x.val = "";
    this.sel = s;
  }

  onSelect({ selected }) { console.log(selected); }

  performAction(i) { }

  removeOthers(name) {
    this.editing = [{}];
    this.editing[name] = true;
  }

  search() {
    var o = {};
    o["sub"] = this._auth.getGUID();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this._auth.getToken()
      })
    };

    for (let x of this.filters[this.sel].obj) o[x.prop] = x.val;
    /*
        this._loaderRogges.show();
        this._http.post(
          MySettings.environment.SearchCarotaggio,
          o,
          httpOptions
        )
          .map((res) => {
            this._loaderRogges.hide();
            this.show = true;
            if (!res['esito']) {
              this.rows = [...[]];
              this._notifications.error(
                "Errore",
                "Errore durante la ricerca nell'anagrafica: " + res['errCode'],
                MySettings.environment.notificationOptions
              );
            } else {
              if (res['obj'].length == 0) {
                this.rows = [...[]];
              }
              else {
                //console.log('obj returned from DPO %o', res['obj']);
                this.rows = [];
                this.consents = [];
                for(let a = 0; a < res['obj'].length; a = a + 1)
                {
                  let mypaz = res['obj'][a].paziente;
                  mypaz.abapp = 'NO';
                  this.rows.push(mypaz);
                  this.consents.push(res['obj'][a].consensi);
                }
                console.log('obj calculated from DPO rows %o consents %o', this.rows, this.consents);
                //this.rows = [res['obj'][0]['paziente']];
                //this.consents = res['obj'][0]['consensi'];
              }
            }
          }).subscribe(val => []);
          */

    this._loaderRogges.show();
    this._http.post(
      MySettings.environment.SearchCarotaggio,
      o,
      httpOptions
    )
      .subscribe((res) => {
        this._loaderRogges.hide();
        this.show = true;
        if (!res['esito']) {
          this.rows = [...[]];
          this._notifications.error(
            "Errore",
            "Errore durante la ricerca nell'anagrafica: " + res['errCode'],
            MySettings.environment.notificationOptions
          );
        } else {
          if (res['pazienti'].length == 0) {
            this.rows = [...[]];
          }
          else {
            console.log('obj returned from DPO %o', res['pazienti']);
            this.rows = [];
            this.consents = [];
            for (let a = 0; a < res['pazienti'].length; a = a + 1) {
              let mypaz = res['pazienti'][a];
              mypaz.abapp = 'NO';
              this.rows.push(mypaz);
              let tempc =[];
              /*
              <td>{{cst.nome_consenso}}</td>
              <td>{{fDate(cst.data_consenso)}}</td>
              <td>{{cst.modalita}}</td> 
              <td *ngIf="cst.consenso_dato == 0">NO</td>
              */
              if (res['pazienti'][a].pazientiPrivacy1Informativa == true) {
                tempc.push({
                  nome_consenso: 'Privacy',
                  data_consenso: 'nd',
                  modalita: res['pazienti'][a].pazientiPrivacy1InformativaModalitaId,
                  consenso_dato: 1
                });
              }
              else {
                tempc.push({
                  nome_consenso: 'Privacy',
                  data_consenso: 'nd',
                  modalita: 0,
                  consenso_dato: 0
                });
              }

              if (res['pazienti'][a].pazientiPrivacy2DirittiModifica == true) {
                tempc.push({
                  nome_consenso: 'Diritto Modifica',
                  data_consenso: 'nd',
                  modalita: res['pazienti'][a].pazientiPrivacy2DirittiModificaModalitaId,
                  consenso_dato: 1
                });
              }
              else {
                tempc.push({
                  nome_consenso: 'Diritto Modifica',
                  data_consenso: 'nd',
                  modalita: 0,
                  consenso_dato: 0
                });
              }

              if (res['pazienti'][a].pazientiPrivacy3Marketing == true) {
                tempc.push({
                  nome_consenso: 'Marketing',
                  data_consenso: 'nd',
                  modalita: res['pazienti'][a].pazientiPrivacy3MarketingModalitaId,
                  consenso_dato: 1
                });
              }
              else {
                tempc.push({
                  nome_consenso: 'Marketing',
                  data_consenso: 'nd',
                  modalita: 0,
                  consenso_dato: 0
                });
              }

              if (res['pazienti'][a].pazientiPrivacy4Profilazione == true) {
                tempc.push({
                  nome_consenso: 'Profilazione',
                  data_consenso: 'nd',
                  modalita: res['pazienti'][a].pazientiPrivacy4ProfilazioneModalitaId,
                  consenso_dato: 1
                });
              }
              else {
                tempc.push({
                  nome_consenso: 'Profilazione',
                  data_consenso: 'nd',
                  modalita: 0,
                  consenso_dato: 0
                });
              }

              if (res['pazienti'][a].pazientiPrivacyNoContatto == true) {
                tempc.push({
                  nome_consenso: 'No Contatto',
                  data_consenso: 'nd',
                  modalita: res['pazienti'][a].pazientiPrivacyNoContattoModalitaId,
                  consenso_dato: 1
                });
              }
              else {
                tempc.push({
                  nome_consenso: 'No Contatto',
                  data_consenso: 'nd',
                  modalita: 0,
                  consenso_dato: 0
                });
              }
              if (!(res['pazienti'][a].dataOblio == null)) {
                tempc.push({
                  nome_consenso: 'Oblio',
                  data_consenso: res['pazienti'][a].dataOblio,
                  modalita: 0,
                  consenso_dato: 1
                });
              }
              else {
                tempc.push({
                  nome_consenso: 'Oblio',
                  data_consenso: 'nd',
                  modalita: 0,
                  consenso_dato: 0
                });
              }
              this.consents.push(tempc);
            }
            
            console.log('obj calculated from DPO rows %o consents %o', this.rows, this.consents);
            
          }
        }
      },
        error => {
          this._loaderRogges.hide();
          this._notifications.error(
            "Errore",
            "Errore durante la ricerca nell'anagrafica: " + error.message,
            MySettings.environment.notificationOptions
          );
        });
  }

  ableApp() { }

  disableApp() { }

  look() {
    // console.log('LOOK on %o',this.filters[this.sel].obj);
    for (let x of this.filters[this.sel].obj) {
      if ((x.val == undefined || x.val.trim() == "") && !(x.req == false)) return true;
    }
    return false;
  }

  calculateWidthColumn(col): number {
    for (let a = 0; a < this.columns.length; a = a + 1) {
      if (this.columns[a].name == col) {
        //console.log('find col %o', col);
        return parseInt(this.columns[a]['length']);
      }
    }
    console.log('not find col %o', col);
    return 100;
    /*
    document.getElementById('columnAdapter').innerHTML = col.toUpperCase();
    var d = getComputedStyle(document.getElementById('columnAdapter')).width;
    var dim = parseInt((d + "").substring(0, (d + "").length - 2));
    console.log('dim %o col %o',dim,col);
    dim = 25 + dim;
   
    return dim;
    */
  }

  calculateTable(force = false) {
    var mh = window.innerHeight - (document.getElementById('my-ngx').getBoundingClientRect()).top - 60 - (document.getElementById('infoAggiuntiveOrdine') != undefined ? document.getElementById('infoAggiuntiveOrdine').offsetHeight : 0) + "px";

    //return { 'height': mh + ' !important' };
    return { 'height': '300px !important' };
  }

  isCurrency(t, f) {
    return ((t == 'Currency') && f != '' && f !== undefined);
  }

  hasClass(who, asc) {
    if (!asc && document.getElementsByClassName('sort-desc').length == 0) return true;
    if (asc && document.getElementsByClassName('sort-asc').length == 0) return true;
    if (!asc)
      for (let x of Array.prototype.slice.call(document.getElementsByClassName('sort-desc')))
        try {
          return !(who == x.children[0].children[0].children[0].children[1].children[1].innerHTML.trim());
        }
        catch (e) {
          return true;
        }
    if (asc)
      for (let x of Array.prototype.slice.call(document.getElementsByClassName('sort-asc')))
        try {
          return !(who == x.children[0].children[0].children[0].children[1].children[1].innerHTML.trim());
        }
        catch (e) {
          return true;
        }
    return true;
  }

  onClick(clicked) {

  }

  goBack(act) {
    this.rows = [];
    this._router.navigate(['/']);
  }

}
