import { Component, OnInit, Input, SimpleChange } from '@angular/core';
import { webTicAction, webTicSettingsCard } from '../app.model';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { IncentiveService } from '../services/incentive.service';

@Component({
  selector: 'app-incentive',
  templateUrl: './incentive.component.html',
  styleUrls: ['./incentive.component.css']
})
export class IncentiveComponent implements OnInit {
  @Input() actions: webTicAction[] = [];
  current:string;
  settingsCards: webTicSettingsCard[] = [];
  loadedData:boolean = false;

  constructor(private activeRoute: ActivatedRoute, private route: Router,  private incentiveService: IncentiveService) {
    this.incentiveService.getEnvironment().subscribe(data=>{
      this.loadedData = true;
    });
    this.routeEvent(this.route);
  }

  ngOnInit() {
    if(this.route.routerState.root.snapshot.fragment){
      console.log(this.route.routerState.root.snapshot.fragment);
      this.setCurrent(window.location.hash);
    }
  }

  routeEvent(router: Router){
    router.events.subscribe(e => {
      // console.log("first consolelog %o",e);
      if(e instanceof NavigationEnd){
        // console.log(e);
        this.setCurrent(window.location.hash);
      }
    });
  }

  setCurrent(h){
    if(h && h.length > 2){
      h = h.slice(2);
    }
    this.current = h.split("/")[0];
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    if(changes['actions']){
       this.init();
    }
  }

  init(){
    // console.log("actions: %o", this.actions);
    this.actions.sort((a, b)=>{
      if(a.order > b.order) {
        return 1;
      }else if(a.order < b.order){
        return -1;
      }else{
        return 0;
      }
    }).forEach((action:webTicAction) => {
      this.settingsCards.push(new webTicSettingsCard(
        action.entity,
        action.hcolor,
        `assets/img/gce/${action.image}`,
        action.path,
        action.labelCode,
        action.entity,
        undefined,
        true
      ));
    });
  }

  doAction(action){
    console.log("doAction %o", action);
    window.location.hash = "/" + action;
  }
}
