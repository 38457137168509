import { Component, NgModule, AfterViewInit, HostListener } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TastieraArticoli } from './../global/model/articoli/articoli.model';
import { Keyboard } from './../global/model/keyboard/keyboard.model';
import { CommonModule } from '@angular/common';
import { BasketService } from './../global/services/basket.service';
import * as myGlobals from './../global/settings';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import {
  MatButtonModule,
  MatCardModule,
  MatMenuModule,
  MatToolbarModule,
  MatIconModule,
  MatInputModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatExpansionModule,
  MatSelectModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatChipsModule,
  MatListModule,
  MatSidenavModule,
  MatTabsModule,
  MatProgressBarModule
} from '@angular/material';

declare var $: any;

@Component({
  selector: 'cash-right-side',
  templateUrl: './right-side.component.html',
  styleUrls: ['./right-side.component.css'],
  providers: [ ]
})
export class RightSideComponent implements AfterViewInit {
  ta: TastieraArticoli.RootObject;
  tastiera: false;
  screenNodes: false;
  tastiere: TastieraArticoli.Nodi [];
  previous: null | number;
  mainTotal: number;
  keyboards;
  buttgroups;
  keyboardForm: FormGroup = new FormGroup({
    txtQuantity: new FormControl({ value: this._basketService.txtQuantity, disabled: true })
  });
  tastieraTemp: string = "";

  ngAfterViewInit () { }

  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: KeyboardEvent) {
    let allowed = ["Backspace", "Delete", "-", "+"];
    let substitute = ["←", "C", "–", "+"];

    if (!isNaN(+event.key) || allowed.indexOf(event.key) !== -1) {
      this.pressKeyboard(!isNaN(+event.key) ? event.key : substitute[allowed.indexOf(event.key)]);
    }
  }

  constructor (
    public _basketService: BasketService,
  ) {
    this.ta = undefined;
    this.tastiere = [];
    this.previous = null;
    this.previous = -2;
    this.mainTotal = 0;
    this.buttgroups = myGlobals.keyboardButtons;

    let x:Keyboard.Keyboards;
    x = this.keyboards;
    this.keyboards = x;

    console.log(this.keyboards);

    if (this._basketService.basket.Items.length == 0) {
      // fix [object Object] problem
      this.pressKeyboard(0);
      // fix keboard set to zero
      this.tastieraTemp = "";
    }
  }

  completeOrder () {
    this._basketService.callPrePayment();
    // RIPRISTINAAAAA this._home.callPrePayment();
  }

  pressKeyboard (evt: any) {
    let x = +evt;

    if (this._basketService.selectedBasketItem != undefined && this._basketService.selectedBasketItem.Variants.length > 0 && [0, 'C', '←', '–'].indexOf(evt) === -1) return;
    if (this._basketService.selectedItem == undefined && [0, 'C', '←', '–', '+'].indexOf(evt) !== -1) return;

    if (this.tastieraTemp != "" && this._basketService.updateQuantity == 0)
      this.tastieraTemp = "";

    /*console.log("txt: " + this.keyboardForm.controls["txtQuantity"].value );
    if (!isNaN(evt) || evt == '+') {
      let realValue: number = 0;
      if ((this.keyboardForm.controls["txtQuantity"].value + "").indexOf('x') !== -1)
        realValue = +this.keyboardForm.controls["txtQuantity"].value.substr(0, this.keyboardForm.controls["txtQuantity"].value.length - 2);
      else
        realValue = +this.keyboardForm.controls["txtQuantity"].value;

      if ((evt != '+' && realValue + +evt > myGlobals.limitBasketQuantity) || (evt == '+' && realValue + 1 > myGlobals.limitBasketQuantity)) {
        console.log(evt + " button blocked.");
        return;
      }
    }*/

    if (this._basketService.selectedItem == undefined) {
      let num;

      if (!isNaN(evt)) {
        num = +evt;
        if (num == 0 && this.tastieraTemp == "") return;
        this.tastieraTemp += "" + num;
        this.keyboardForm.controls["txtQuantity"].setValue(this.tastieraTemp + " x");
        this._basketService.updateQuantity = +this.tastieraTemp;
        console.log("pressed button " + num + ". txtQuantity: " + this.keyboardForm.controls["txtQuantity"].value);
      } else {
        switch (evt) {
          case '←':
            this.tastieraTemp = this.tastieraTemp.substr(0, this.tastieraTemp.length - 1);
            this.keyboardForm.controls["txtQuantity"].setValue(this.tastieraTemp + " x");
            this._basketService.updateQuantity = +this.tastieraTemp;
          break;
          case 'C':
            this.tastieraTemp = "";
            this._basketService.updateQuantity = 0;
            this.keyboardForm.controls["txtQuantity"].setValue("0");
          break;
        }
        console.log("pressed button " + evt + ". txtQuantity: " + this.keyboardForm.controls["txtQuantity"].value);
      }
      return;
    } else {
      console.log("pressed button: " + evt);

      if (evt == '+')
        this._basketService.updateBasket(1);
      if (evt == '–')
        this._basketService.updateBasket(-1);
      if (evt == '←')
        this._basketService.removeUnit();
      if (evt == 'C')
        this._basketService.removeFromBasket(this._basketService.selectedBasketItem, this._basketService.selectedBasketItem.Quantity);
      else if (!isNaN(evt)) {
        console.log("overwrite: " + this._basketService.overwrite);
        if (this._basketService.overwrite) {
          let str = "Quantity overwrited from " + this._basketService.selectedBasketItem.Quantity + " to " + evt;
          this.keyboardForm.controls["txtQuantity"].setValue(evt);
          if (evt == 0) {
            this._basketService.removeFromBasket(this._basketService.selectedBasketItem, this._basketService.selectedBasketItem.Quantity);
          } else {
            this._basketService.updateBasket(evt - this._basketService.selectedBasketItem.Quantity);
            this._basketService.overwrite = false;
            console.log(str);
          }
        } else {
          let newQ: number = +(this._basketService.txtQuantity + "" + evt);
          console.log(this._basketService.txtQuantity + " -> " + newQ);
          console.log("New quantity: " + newQ + "-" + this._basketService.selectedBasketItem.Quantity + "=" + (newQ - this._basketService.selectedBasketItem.Quantity));
          this._basketService.updateBasket(newQ - this._basketService.selectedBasketItem.Quantity);
        }
      }
    }
  }
}

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatExpansionModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatChipsModule,
    MatListModule,
    MatSidenavModule,
    MatTabsModule,
    MatProgressBarModule,
    BrowserModule,
    CommonModule
  ],
  declarations: [ ],
  providers: [ BasketService ],
  bootstrap: [ RightSideComponent ]
})
export class RightSideModule { }
