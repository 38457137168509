import { Component, OnInit, NgModule, Pipe, PipeTransform, Injectable, ViewContainerRef, Input, HostListener, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { MatFormFieldModule, MatDialogRef, MatDialog } from '@angular/material';
import * as myGlobals from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthGuardService, UserService, AlertDialogService, DataRepositoryService } from '../services';
import { Router } from '@angular/router';
import { TranslateModule, TranslateCompiler, TranslateService } from '@ngx-translate/core';
import { CartService, Cart, CartItem } from '../services/cart.service';
import { LoaderService } from '../loader';
import { PosConcessionService } from './services/pos.service';
import { VariantConcessionDialog } from './dialogs/variants-concession.dialog';
import { PrinterService } from '../services/printer.service';
import { ArticoliNegozio, Nodo, SearchProductItem, Articolo, CustomVariantSelected, getShortLabelUdm } from './domain/concession-system';
import { ConcessionService } from '../services/concession.service';
import { fromEvent, Observable } from 'rxjs';
import { tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SearchProductDialog } from './dialogs/search-product.dialog';
import { DevicemanagerService } from '../services/devicemanager.service';
import { environment } from '../../environments/environment';
import { TastieraComponent } from './tastiera.component';
import { VariantCustomDialog } from './dialogs/variants-custom.dialog';
import { ScaleQuantityDialog } from './dialogs/scale-quantity.dialog';

@Component({
  selector: 'app-concession-system',
  templateUrl: './concession-system.component.html',
  styleUrls: ['./concession-system.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
  ]
})
export class ConcessionSystemComponent implements OnInit {

  @ViewChild('inputSearch', { static: true }) inputSearch: ElementRef;
  @ViewChild('tastieraComp', { static: false }) tastieraComp: TastieraComponent;

  private _selectedItemIndex: number = -1;

  get selectedItemIndex(){
    return this._selectedItemIndex;
  }
  set selectedItemIndex(value:number){
    this._selectedItemIndex = value;
    this.setKeyboardDigitsDisabled();
  }

  idtastiera: number;
  tastieraRoot: any;
  tastiera: Nodo;
  nodi: Array<Partial<Nodo>>;
  nodiBackup: Array<Partial<Nodo>>;
  menuIcons: any;
  keyboardCalc: Array<Array<{
    disabled: boolean,
    caption: string,
    isDouble: boolean,
    enabledOnlyWithItemSelected: boolean
  }>>;
  insideNode: boolean = false;
  keyboardStringValue: string;
  keyboardValue: number;
  preValue: number;
  maxValue: number;
  preValueCashAmount: number;
  insideNodoName: string;
  startPaymentProcess: boolean;
  paymentMethodSelected: boolean;
  paymentMethods;
  variantTest;
  paymentMethodSelectedType;
  _dialogRef: MatDialogRef<any>;
  ta: ArticoliNegozio;
  subscription: any;
  cashAmount: number = 0;
  paymentOK: boolean = undefined;
  paymentStarted: boolean;
  paymentKOMessage: any;
  firstComma = undefined;

  /*** Used to manage the Barcode Reader */
  private scannerTimer: any = null;
  private tempScannedString: string = '';
  private scannedString: string = '';
  /*********************** */

  showFilteredProducts: boolean = false;
  filteredProducts: SearchProductItem[] = [];
  searchProductPageIdex: number = 0;
  showPagingNavigator: boolean = false;
  searchProductsTotItems: number = 0;
  showNavigatorForwardArrow: boolean = true;

  navigatorDetailLabel: string  = '';
  hideSearchBox: boolean = true;

  canClickNode:boolean = true; // Use to debounce click when user select node

  constructor(
    private _http: HttpClient,
    private _auth: AuthGuardService,
    private _router: Router,
    private _translate: TranslateService,
    public _cartService: CartService,
    public _userService: UserService,
    public _loaderService: LoaderService,
    public _posService: PosConcessionService,
    private _dialog: MatDialog,
    private _printerService: PrinterService,
    private concessionService: ConcessionService,
    private alertDialog: AlertDialogService,
    public deviceManagerService: DevicemanagerService,
    private dataRepositoryService: DataRepositoryService
  ) {
    this.keyboardValue = 1;
    this.keyboardCalc = <any>myGlobals.environment['keyboardCalc'];
    this.insideNodoName = "";
    this.maxValue = 999999;
    this.preValue = -1;
    this.preValueCashAmount = -1;
    this.startPaymentProcess = false;
    this.paymentMethodSelected = false;
    this.keyboardStringValue = this.keyboardValue + " x";
    this.initializeVariantsObject();

    // Elenco nodi tastiera
    this.getNodiTastiera();
    // Elenco icone
    this.menuIcons = this.getIcons();
    // Elenco metodi di pagamento
    this.getPaymentMethods();

    this.setKeyboardDigitsDisabled();
  }

  private setKeyboardDigitsDisabled(){
       const disableDigit = this._selectedItemIndex < 0;
       this.keyboardCalc.forEach(r => {
          r.forEach(i => {
            if(i.enabledOnlyWithItemSelected){
              i.disabled = disableDigit;
            }
          });
       });
  }

  private navigatorDetails(): Observable<string>{
    const startPage = Math.max(1, (this.searchProductPageIdex * environment['SEARCH_PRODUCT_PAGE_SIZE']) + 1);
    const endPage = Math.min(this.searchProductsTotItems, startPage + environment['SEARCH_PRODUCT_PAGE_SIZE'] - 1);

    this.showNavigatorForwardArrow = endPage < this.searchProductsTotItems;

    return this._translate.get('search_product_navigator', { 'startPage': startPage, 'endPage': endPage, 'searchProductsTotItems': this.searchProductsTotItems });
  }
  ngOnInit() {
    this.subscription = this._posService.getPaymentSuccess().subscribe(res => {
      this._cartService.cart.payments.push ({
        paymentMethod: this.paymentMethodSelectedType.PaymentMethod,
        amount: 0,
        success: res.data.Status.Success,
        date: new Date(),
        extraInfo: res.data,
        id: this._posService.guid()
      });
      this.paymentOK = res.data.Status.Success;
      if (res.data.Status.Success) {
        this.registerPayment();
        var t = this;
        setTimeout(function () {
          t.finalizePayment();
        }, 2500);
      }
      else
        this.paymentKOMessage = res.data.Status.Message;
    });

    fromEvent(document, 'keypress')
    .pipe(
    tap((e: any) => {
      this.tempScannedString += String.fromCharCode(e.charCode);
      if (this.scannerTimer == null) {
        this.scannerTimer = setTimeout(() => {
          if (this.tempScannedString.length > 2) {
            this.scannedString = this.tempScannedString;
            this.tempScannedString = '';
            this.inputSearch.nativeElement.value = this.scannedString;
            this.searchProduct();
            this.showFilteredProducts = true;
          }
          else {
            this.tempScannedString = '';
          }
          this.scannerTimer = null;
        }, 100);

      }
      })
    )
    .subscribe();

    fromEvent(this.inputSearch.nativeElement, 'keyup').pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => {
        if(this.inputSearch.nativeElement.value !== ''){
          this.searchProduct();
          this.showFilteredProducts = true;
        }
        else{
          this.showFilteredProducts = false;
        }
      }
      )
    ).subscribe();

    this.deviceManagerService.messages.next({ event: myGlobals.environment['getMachineName'], data: {} });
    this.deviceManagerService.messages.subscribe(msg => {
      const evnt = msg.event;
      const obj = msg.data['data'];
      if (evnt === myGlobals.environment['getMachineName']) {
        this.deviceManagerService.machineName = obj['EnvironmentLocalMachine'].MachineName;
      }
    });

    // Load Pos Configuration
    this.concessionService.getPosConfiguration().subscribe(res => {
       this.dataRepositoryService.posConfiguration = res.posConfiguration;
    });
  }

  getPaymentMethods () {
    this._http.post(
      myGlobals.environment.GetPaymentMethods,
      myGlobals.environment.ConcessionSettings
    )
    .map((res) => {
      let r:any = res;
      let pmKeyboardEnabled = ["CONTANTI", "CARTA DI CREDITO", "CARTA DI DEBITO", "BANCOMAT", "CREDIT_CARD"];
      this.paymentMethods = [];
      for (let obj of r.modPag) {
        var x:any = { };
        x.IdPM = obj.IdmodPag;
        x.PaymentMethod = obj.Descr;
        x.Tipo = obj.Tipo;
        x.KeyboardEnabled = (pmKeyboardEnabled.indexOf(obj.Descr) !== -1);
        x.PMEnabled = (pmKeyboardEnabled.indexOf(obj.Descr) !== -1);
        if (obj.Descr == "CONTANTI")
          x.Image = "icn_contanti.png";
        else if (obj.Descr == "BANCOMAT" || obj.Descr == "CARTA DI DEBITO")
          x.Image = "icn_carta_di_debito.png";
        else if (obj.Descr == "CARTA DI CREDITO")
          x.Image = "icn_carta_di_credito.png";
        else if (obj.Descr == "BORSELLINO" || obj.Descr == "MONETICA")
          x.Image = "icn_borsellino.png";
        else if (obj.Descr == "BUONI PASTO")
          x.Image = "icn_buoni_pasto.png";
        else if (obj.Descr == "COUPON" || obj.Descr == "GIFT")
          x.Image = "icn_gift_card.png";
        else
          x.Image = "icn_payment.png";

        if (obj.Descr != "CONTANTI")
          this.paymentMethods.push (x);
        else
          this.paymentMethods.splice(0, 0, x);
      }
      console.log(this.paymentMethods);
    })
    .subscribe(val => []);
  }

  goToBoxOffice () {
    this._router.navigate(['/pm/counter']);
  }

  openPaymentMethod (pm) {
    this.paymentStarted = false;
    this.paymentMethodSelected = true;
    this.paymentMethodSelectedType = pm;
    this.cashAmount = this._cartService.getTotal() - this._cartService.alreadyPayed();
    if (pm.Tipo == 'V') {
      this.paymentStarted = true;
      this._posService.payWithCreditCard(this.cashAmount);
    }
  }

  getId (): string {
    return Math.random().toString(36).substring(7);
  }

  getMachineName () {

  };

  registerPayment() {
    this._cartService.cart.payments.push({
        date: new Date(),
        success: true,
        amount: this.cashAmount,
        paymentMethod: this.paymentMethodSelectedType.PaymentMethod,
        extraInfo: { },
        id: this._posService.guid()
      }
    );
    this._cartService.cart.total -= (this.cashAmount > this._cartService.cart.total ? this._cartService.cart.total : this.cashAmount);

    console.log(this._cartService.cart);

    if (this._cartService.cart.total <= 0) {
      this._printerService.printReceipt(this._cartService.cart);
      this.finalizePayment();
    }
    else {
      this.paymentKOMessage = "";
      this.paymentOK = undefined;
      this.paymentMethodSelected = false;
      this.paymentMethodSelectedType = undefined;
      this.preValueCashAmount = -1;
      this.firstComma = undefined;
    }
  }

  finalizePayment () {
    this.paymentMethodSelected = false;
    this.paymentMethodSelectedType = undefined;
    this.paymentStarted = false;
    this.tastiera = this.tastieraRoot;
    this.nodi = this.tastiera['Nodi'];
    this.insideNodoName = "";
    this.insideNode = false;
    this.cashAmount = 0;
    this.preValueCashAmount = -1;
    this._cartService.emptyCart();
    this.firstComma = undefined;
    this.openPopupConfirm();
  }

  getNodiTastiera() {
    this._loaderService.show();
    this._http.post(
      myGlobals.environment['GetNodiTastiera'],
      myGlobals.environment['ConcessionSettings']
    )
    .map((res: ArticoliNegozio) => {
      this._loaderService.hide();
      if (res.esito) {

        this.ta = res;
        localStorage.setItem('concessionSettings', JSON.stringify(res.TastieraArticoli));

        this.idtastiera = res.idtastiera;
        this.tastiera = res.TastieraArticoli;
        this.tastieraRoot = this.tastiera;
        this.nodi = this.tastiera.Nodi;
      }
    })
    .subscribe(val => []);
  }

  getImagePath(nodo) {
    return myGlobals.environment['ImgTastiera'] + "?idnegozio=" + myGlobals.environment['ConcessionSettings']['idnegozio'] + "&idtastiera=" + this.idtastiera + "&idnodo=" + nodo['idnodo'] + "&lob_size=SMALL&i=png-s";
  }
  getImagePathArticolo(idarticolo:number) {
    return myGlobals.environment['ImgArticolo'] + "?idnegozio=" + myGlobals.environment['ConcessionSettings']['idnegozio'] + "&idarticolo=" + idarticolo + "&lob_size=SMALL";
  }

  getNodeSize(nodo, root = false) {
    if (root)
      return {
        'height': nodo['height'] + 'px',
        'width': nodo['width'] + 'px',
        'top': (0 + +nodo['top']) + 'px',
        'left': (0 + +nodo['left']) + 'px'
      }

    var cost = (nodo['width'] < nodo['height'] ? nodo['width'] : nodo['height']) < 240 ? 35 : 58;
    var lm = parseInt ("" + ((cost * (nodo['width'] < nodo['height'] ? nodo['width'] : nodo['height'])) / 100));
    return {
      'height': lm + "px",
      'width': lm + "px",
      'left': (parseInt ("" + (nodo['width'] / 2)) - parseInt ("" + (lm / 2)) - 8) + "px",
      'top': nodo['height'] //parseInt ("" + ((9 * nodo['height']) / 100)) + "px"
    }
  }

  getPSize (nodo, x = 0) {
    var cost = nodo['height'] < 240 ? 68 : 38;
    return {
      'bottom': '20px',
      'top': parseInt("" + (14 + nodo['height'] - ((nodo['height'] * cost) / 100))) + 'px',
      'text-align': 'center',
      'width': '100%',
      'left': 'auto',
      'right': 'auto',
      'position': 'absolute',
      'margin-top': (x + 35) + 'px !important'
    }
  }

  getIcons () {
    const icons = myGlobals.environment['concessionIcons'];
    icons.find(i => i.name === 'ecr.ecr-home.1207').disabled = false;
    return icons;
  }

  myIsNaN (val) {
    return isNaN(val);
  }

  openPopup(varianti) {
    this._dialogRef = this._dialog.open(VariantConcessionDialog);
    this._dialogRef.componentInstance.variants = varianti;
    this._dialogRef.componentInstance._translate = this._translate;
    this._dialogRef.afterClosed().subscribe(result => {
      this._dialogRef = null;
    });
  }

  // openNode (nodo: Nodo) {

  //   // Debounce
  //   if(this.canClickNode){
  //     this.canClickNode = false;
  //     setTimeout(() => {
  //       this.canClickNode = true;
  //     }, 500);

  //     this.insideNode = nodo.idnodoPadre !== 0;
  //     this.activateButton('resetKeyboard', this.insideNode);

  //     if (nodo.Nodi.length === 0) {
  //         // if (this.preValue !== -1 && this._cartService.getTotalItems() > 0){
  //         //   this.resetKeyboard();
  //         // }

  //         const tastiera = nodo;
  //         if (tastiera.articolo.varianti != null && tastiera.articolo.varianti.length > 0) {
  //           this.openVariants(nodo);
  //           return;
  //           // if (!this.variantTest.defined || this.variantTest.idArticle !== tastiera.idarticolo) {
  //           //   if (this.variantTest.idArticle !== tastiera.idarticolo){
  //           //     this.initializeVariantsObject();
  //           //   }
  //           //   this.buildVariantsKeyboard(tastiera);
  //           // }
  //           // else{
  //           //   this.openPopup(this.variantTest);
  //           // }
  //         }
  //         else{
  //           const articoloTastieraVarianti = this.articoloTastieraVarianti(+nodo['articolo']['idarticolo']);
  //           if(articoloTastieraVarianti === -1){
  //             let item: CartItem;
  //             item = {
  //               productId: nodo['articolo']['idarticolo'],
  //               productDescription: nodo['articolo']['articolo'],
  //               price: nodo['articolo']['importo'],
  //               priceId: nodo['articolo']['idprezzo'],
  //               quantity: this.keyboardValue,
  //               extraInfo: nodo['articolo'],
  //               selected: false
  //             };
  //             this._cartService.addToCart(item);
  //             this.selectItem(item);
  //             this.resetKeyboard();
  //             this.activateButton('openPopupConfirm', this._cartService.getTotalItems() > 0);
  //             return;
  //           }
  //           else{
  //             this.addArticoloWithCustomVarianti(nodo['articolo'], articoloTastieraVarianti);
  //             return;
  //           }
  //         }
  //     }

  //     if (this.insideNode) {
  //       this.tastiera = nodo;
  //       this.nodi = nodo.Nodi;
  //       this.insideNodoName = nodo.nodo;
  //     }
  //     else {
  //       this.insideNodoName = '';
  //     }
  //   }
  // }
  clickedNode (nodo: Nodo) {

      this.insideNode = nodo.idnodoPadre > 1 || (nodo.idnodoPadre === 1 && nodo.Nodi.length > 0);
      this.activateButton('resetKeyboard', this.insideNode);

      if (nodo.Nodi.length === 0) {

          const tastiera = nodo;
          if (tastiera.articolo.varianti != null && tastiera.articolo.varianti.length > 0) {
            this.openVariants(nodo);
            return;
          }
          else{
            let item: CartItem;
            item = {
              productId: nodo['articolo']['idarticolo'],
              productDescription: nodo['articolo']['articolo'],
              price: nodo['articolo']['importo'],
              priceId: nodo['articolo']['idprezzo'],
              quantity: this.keyboardValue,
              extraInfo: nodo['articolo'],
              selected: false,
              customVariant: false,
              idudm: nodo.articolo.idudm,
              scala_udm: nodo.articolo.scala_udm
            };

            if(tastiera.articolo.isScaleProduct){
              const dialogRefScaleQuantity = this._dialog.open(ScaleQuantityDialog, {
                panelClass: 'cnt-scale-quantity-dialog',
              });

              dialogRefScaleQuantity.afterClosed().subscribe((result: number) => {
                if(result){
                   item.quantity = result;
                   item.price = nodo.articolo.importo / nodo.articolo.qtaUnitaria;
                   this._cartService.addToCart(item);
                   this.selectItem(item);
                   this.resetKeyboard();
                   this.activateButton('openPopupConfirm',  this._cartService.getTotalItems() > 0);
                   return;
                }
              });
            }
            else{
              const articoloTastieraVarianti = this.articoloTastieraVarianti(+nodo['articolo']['idarticolo']);
              if(articoloTastieraVarianti === -1){
                this._cartService.addToCart(item);
                this.selectItem(item);
                this.resetKeyboard();
                this.activateButton('openPopupConfirm',  this._cartService.getTotalItems() > 0);
                return;
              }
              else{
                this.addArticoloWithCustomVarianti(nodo['articolo'], articoloTastieraVarianti);
                return;
              }
            }
          }
      }
  }

  selectItem (item: CartItem, clicked = false, index: number = -1) {

    this.selectedItemIndex = index;
    this.keyboardValue = item.quantity;
    this.printValue();
  }

  pressKeyboardButton (val) {
    const item = this._cartService.getItemByIndex(this.selectedItemIndex);
    if (!this.myIsNaN(val)) {
      val = +val;
      if (this.keyboardValue * 10 + val > this.maxValue) return;
      if (this.preValue == -1) {
        this.keyboardValue = val;
        this.preValue = val;
      }
      else
        this.keyboardValue = this.keyboardValue * 10 + val;
      this.printValue();
      if (this.selectedItemIndex >= 0) {
        this._cartService.updateQuantity(this.selectedItemIndex, this.keyboardValue);
      }
      return;
    }

    if (val === 'C') {
      if(this.selectedItemIndex >= 0){
        this.resetKeyboard();
        this._cartService.removeItem(this.selectedItemIndex);
        this.selectedItemIndex = -1;
      }
      return;
    } else if (val === '←') {
      if(this.keyboardValue >= 10){
        const textValue = this.keyboardValue.toString();
        this.keyboardValue = textValue.length > 2 ? +textValue.substring(0, textValue.length - 1) : +textValue[0];
      }
    } else if (val === '+') {
      this.keyboardValue++;
      this._cartService.addOrSubtractQuantity(this.selectedItemIndex, 1);
    } else if (val === '–' && this.keyboardValue > 0) {
      if (this.keyboardValue === 1){
        this._cartService.removeItem(this.selectedItemIndex);
        this.selectedItemIndex = -1;
      }
      else {
        this.keyboardValue--;
        this._cartService.addOrSubtractQuantity(this.selectedItemIndex, -1);
      }
    }

    this.printValue();
  }

  resetKeyboard (val = 1) {
    this.preValue = -1;
    this.keyboardValue = val;
    if (this._cartService.getTotalItems() == 0)
      this.menuIcons[2].disabled = true;
    this.printValue();
  }

  pressMiniKeyboardButton (val) {
    if (!this.myIsNaN(val)) {
      val = +val;
      if (this.firstComma != undefined) {
        if (this.firstComma)
          this.cashAmount = +(this.cashAmount + "." + val);
        else
          this.cashAmount = +((this.cashAmount + "").substring(-1) + val);
        if (isNaN(this.cashAmount)) {
          this.firstComma = undefined;
          this.preValueCashAmount = -1;
          this.cashAmount = 0;
        }
        this.preValueCashAmount = val;
        this.firstComma = this.firstComma ? false : undefined;
        return;
      }
      if (this.cashAmount * 10 + val > this.maxValue) return;
      if (this.preValueCashAmount == -1) {
        this.cashAmount = val;
        this.preValueCashAmount = val;
      }
      else
        this.cashAmount = this.cashAmount * 10 + val;
      return;
    } else if (val != ',') {
      this.firstComma = undefined;
      this.preValueCashAmount = -1;
      this.cashAmount = 0;
    } else {
      if (this.firstComma != undefined) {
        this.firstComma = undefined;
        return;
      }
      this.firstComma = true;
    }
  }

  updateQuantityItem (item: CartItem, quantity: number = 0) {
    if (quantity == 0)
      this._cartService.addOrSubtractQuantity(this.selectedItemIndex, -item.quantity);
    else
      this._cartService.addOrSubtractQuantity(this.selectedItemIndex, quantity);

    this.resetKeyboard();
  }

  printValue () {
    this.keyboardStringValue = this.keyboardValue + " x";
  }

  clickMenuIcon (icon) {
    if (icon.action == 'blank') return;
    if (icon.action == 'test') return null;//this.test();
    if (icon.action == 'resetKeyboard') {
      this.tastiera = this.tastieraRoot;
    //  this.nodi = this.tastiera['Nodi'];
      // this.insideNodoName = "";
      // this.insideNode = false;
      const isRoot = this.tastieraComp.back();
      if(isRoot){
        // We are at the root
        this.activateButton('resetKeyboard', false);
      }
    }
    else if (icon.action === 'openPopupConfirm'){
      this.openPopupConfirm();
    }
    else if (icon.action === 'openPopupConfirmLogout'){
      this.openPopupConfirmLogout();
    }
    else if (icon.action === 'goToBoxOffice'){
      this.goToBoxOffice();
    }
    else if (icon.action === 'openSearch')
    {
        this.hideSearchBox = false;
    }
  }

  activateButton (button, enabled) {
    for (let i of this.menuIcons){
        if (i.action == button) {
          i.disabled = !enabled;
          break;
        }
    }
  }

  searchProduct = (resetIndex: boolean = true) => {

      this.concessionService.searchProduct(this.inputSearch.nativeElement.value, this.searchProductPageIdex).subscribe(res => {
        this.filteredProducts = res.items;
        this.searchProductsTotItems = res.totItems;
        if(resetIndex){
          this.searchProductPageIdex = 0;
        }

           if(res.totItems > environment['SEARCH_PRODUCT_PAGE_SIZE']){
               this.navigatorDetails().subscribe(res => {
                 this.navigatorDetailLabel = res;
                });
               this.showPagingNavigator = true;
           }
           else{
             this.showPagingNavigator = false;
           }
        },
      err => {
          this.alertDialog.showError(err);
      });
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    var detected = ['+', '-', 'C', 'c', ','];
    var passed = ['+', '–', 'C', 'C', ','];
    var p = event.key;
    if (this.myIsNaN(p))
      p = passed[detected.indexOf(event.key)];
    this.startPaymentProcess == undefined || !this.startPaymentProcess ? this.pressKeyboardButton(p) : this.pressMiniKeyboardButton(p);
  }

  openPopupConfirmLogout () {
    this._userService.logoff();
  }

  openPopupConfirm () {
    if (this.startPaymentProcess) {
      this.startPaymentProcess = false;
    }
    this._cartService.emptyCart();
    this.resetKeyboard();
    this.activateButton('openPopupConfirm', this._cartService.getTotalItems() > 0);
    this.tastiera = this.tastieraRoot;
    this.nodi = this.tastiera['Nodi'];
    this.insideNode = false;
    this.activateButton('resetKeyboard', this._cartService.getTotalItems() > 0);
  }

  startPayment () {
    this.startPaymentProcess = true;
    this.activateButton('resetKeyboard', false);
  }

  // gestione varianti
  initializeVariantsObject (): void {
    this.variantTest = {
      defined: false,
      idArticle: 0,
      variantTitle: "",
      minVariant: 0,
      maxVariant: 0,
      categories: []
    };
  }
  buildTestVariants() {
    let test = [];
    for (let category of this.variantTest.categories)
      test.push({
        category: category.categoryName,
        tot: 0,
        itemSelected: []
      });
    return test;
  }
  pushVariantCategory (v, index, indexCat) {
    this.variantTest.categories.push({
      categoryName: v.categoria,
      idSelectedArticle: 0,
      blockedCategory: false,
      items: [
        {
          selectedQuantity: 0,
          name: v.nome,
          background: this.getBackground(v)
        }
      ]
    });
  }
  getBackground (variant) {
    if (!variant.image_enabled)
      return "url(assets/img/misc/bg_box_cassa.jpg) center center no-repeat";
    else
      return 'url(' + this._cartService.getImageById (variant.idarticolo, -1) + ') no-repeat center black';
  }
  buildVariantsKeyboard (tastiera: Nodo) {
    // let ida: number = 0, idn: number = 0;
    // this.variantTest.variantTitle = tastiera.nodo;
    // this.variantTest.maxVariant = tastiera.articolo.maxVarianti;
    // this.variantTest.minVariant = tastiera.articolo.minVarianti;
    // this.variantTest.idMenuArticle = tastiera.idarticolo;
    // this.variantTest.VariantItem = tastiera;
    // for (const variant of tastiera.articolo.varianti) {
    //   let index: number;
    //   let indexCat: number = 0;
    //   for (const tas of this.ta.TastieraArticoli.Nodi) {
    //     index = -1;
    //     if (tas.nodo == variant.categoria) {
    //       for (let ite of tas.Nodi) {
    //         ++index;
    //         if (ite.idarticolo == variant.idarticolo) {
    //           idn = ite.idnodo;
    //           ida = variant.idarticolo;
    //           break;
    //         }
    //       }

    //       if (index > -1) break;
    //     }
    //     indexCat++;
    //   }

    //   let found: number = -1;
    //   for (let i = 0; i < this.variantTest.categories.length; i ++) {
    //     if (this.variantTest.categories[i].categoryName == variant.categoria) {
    //       found = i;
    //       break;
    //     }
    //   }

    //   if (found > -1) {
    //     this.variantTest.categories[found].items.push({
    //       selectedQuantity: 0,
    //       name: variant.nome,
    //       blockedCategory: false,
    //       background: this.getBackground(variant)
    //       //background: this.ta.TastieraArticoli.Nodi[indexCat] == undefined ? this.getBackground() : this.getBackground(this.ta.TastieraArticoli.Nodi[indexCat].Nodi[index])
    //     });
    //   }
    //   else
    //     this.pushVariantCategory(variant, index, indexCat);
    // }

    // this.variantTest.idArticle = ida;
    // this.variantTest.defined = true;
    // this.openPopup(this.variantTest);

  }

  openVariants = (tastiera:Nodo) => {
      const dialogRefTrailer = this._dialog.open(VariantConcessionDialog, {
        width: '70vw',
        height:'auto',
        panelClass: 'cnt-variants-concession',
        data: tastiera
      });

      dialogRefTrailer.afterClosed().subscribe((result:boolean | null) => {
        if(result){
          const cartItem = new CartItem();
          cartItem.price = tastiera.articolo.importo;
          cartItem.priceId = tastiera.articolo.idprezzo;
          cartItem.productDescription = tastiera.articolo.articolo;
          cartItem.productId = tastiera.articolo.idarticolo;
          cartItem.quantity = this.keyboardValue;
          cartItem.idudm = tastiera.articolo.idudm;
          cartItem.scala_udm = tastiera.articolo.scala_udm;
          cartItem.variants = [];
          tastiera.articolo.varianti.filter(v => v.selected).forEach(v => {
              const vartiantCartItem = new CartItem();
              vartiantCartItem.price = v.importo;
              vartiantCartItem.priceId = v.idprezzo;
              vartiantCartItem.productDescription = v.nome;
              vartiantCartItem.productId = v.idarticolo;
              vartiantCartItem.quantity = v.selected;
              vartiantCartItem.idudm = v.idudm;
              vartiantCartItem.scala_udm = v.scala_udm;

              cartItem.variants.push(vartiantCartItem);
          });
          this._cartService.addToCart(cartItem);

        }
      });
  }
  openSearchProduct = () => {
    const dialogRef = this._dialog.open(SearchProductDialog, {
    });
  }

  getProductDetail = (idArticolo:number) => {
      this.concessionService.getProductDetail(idArticolo).subscribe(res => {
            if(res.varianti.length === 0){
              const articoloTastieraVarianti = this.articoloTastieraVarianti(idArticolo);
              if(articoloTastieraVarianti === -1){
                  let item: CartItem;
                  item = {
                    productId: idArticolo,
                    productDescription: res.articolo,
                    price: res.importo,
                    priceId: res.idprezzo,
                    quantity: this.keyboardValue,
                    extraInfo: res,
                    selected: false,
                    customVariant: false,
                    idudm: res.idudm,
                    scala_udm: res.scala_udm
                  };
                  this._cartService.addToCart(item);
                  this.selectItem(item);
              }
              else{
                this.addArticoloWithCustomVarianti(res, articoloTastieraVarianti);
              }
            }
            else{
               const dummyNodo = {
                 articolo: res
               };
               this.openVariants(<any>dummyNodo);
            }
      },
      err => {
          this.alertDialog.showError(err);
      });
  }

  pagingForward = () => {
    this.searchProductPageIdex++;
    this.searchProduct(false);
  }
  pagingBack = () => {
    this.searchProductPageIdex--;
    this.searchProduct(false);
  }
  articoloTastieraVarianti = (idarticolo: number) => {
    if(this.dataRepositoryService.posConfiguration.tastiereArticoli){
      const articolo = this.dataRepositoryService.posConfiguration.tastiereArticoli.find(t => t.idarticolo === idarticolo);
      if(typeof(articolo) !== 'undefined'){
        return articolo.idtastiera;
      }
      else{
        return -1;
      }
    }
    else
    {
      return -1;
    }
  }
  addArticoloWithCustomVarianti = (articolo: Articolo, idTastiera:number) => {
       this.concessionService.getTastieraVarianti(idTastiera).subscribe(res => {
            const dialogRefTrailer = this._dialog.open(VariantCustomDialog, {
              width: '90vw',
              height:'auto',
              panelClass: 'cnt-variants-custom',
              data: res
            });

            dialogRefTrailer.afterClosed().subscribe((variants: Array<CustomVariantSelected>) => {
              const cartItem = new CartItem();
              cartItem.price = articolo.importo;
              cartItem.priceId = articolo.idprezzo;
              cartItem.productDescription = articolo.articolo;
              cartItem.productId = articolo.idarticolo;
              cartItem.quantity = this.keyboardValue;
              cartItem.idudm = articolo.idudm;
              cartItem.scala_udm = articolo.scala_udm;
              cartItem.variants = [];
              variants.forEach(v => {
                  const vartiantCartItem = new CartItem();
                  vartiantCartItem.price = v.variant.importo;
                  vartiantCartItem.priceId = v.variant.idprezzo;
                  vartiantCartItem.productDescription = v.variant.nome;
                  vartiantCartItem.productId = v.variant.idarticolo;
                  vartiantCartItem.quantity = v.isToAdd ? 1 : -1;
                  vartiantCartItem.idudm = v.variant.idudm;
                  vartiantCartItem.scala_udm = v.variant.scala_udm;
                  vartiantCartItem.customVariant = true;

                  cartItem.variants.push(vartiantCartItem);
              });
              this._cartService.addToCart(cartItem);

            });
      });
  }
  getShortLabelUdm = (idudm: number, udm_scale: number) => {
    return getShortLabelUdm(idudm, udm_scale);
  }
}
