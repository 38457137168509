import { Component, OnInit, ViewChild } from '@angular/core';
import { GceManagementService } from '../../services/gce-management.service';
import { FormComponent } from '../../shared/form/form.component';
import { DatatableComponent } from '../../shared/datatable/datatable.component';
import { FormHeaderComponent, ICustomButton } from '../../shared/form-header/form-header.component';
import { MatDialog } from '@angular/material/dialog';
import { GceDialogCompensationComponent } from '../dialogs/gce-dialog-compensation/gce-dialog-compensation.component';
import { GceDialogClinicAssociationComponent } from '../dialogs/gce-dialog-clinic-association/gce-dialog-clinic-association.component';
import { GceDialogAnagraphicComponent } from '../dialogs/gce-dialog-anagraphic/gce-dialog-anagraphic.component';
import { GceClinic, GceInvoicePerson, GceAnagraphic, GceMedicType, GceFiscalReg, GceProfiles, GceProvCodes, GceInvoicePersonClinic, GceInvoicePersonCompensation } from '../../entities/gce-management';
import { IConditionalFormatting } from '../../directives/conditional-formatting.directive';
import { AlertDialogService } from '../../services/alert-dialog.service';
import { PermissionService } from '../../services/permission.service';
import { CommonUtilsService } from '../../services/common-utils.service';
import { GceLookUpOptions } from '../../entities/gce-management/gce-management';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Confirm } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { ActionsService } from '../../services/actions.service';

@Component({
  selector: 'app-gce-personal-data',
  templateUrl: './gce-personal-data.component.html',
  styleUrls: ['./gce-personal-data.component.css']
})



export class GcePersonalDataComponent implements OnInit {
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild('formData', { static: false }) formData: FormComponent;
  @ViewChild('formHeader', { static: false }) formHeader: FormHeaderComponent;

  columns: any[] = [];
  clinics: any[] = [];
  personal: GceInvoicePerson[] = [];
  personalBkp: GceInvoicePerson[] = [];
  personsclinics: GceInvoicePersonClinic[] = [];
  personscompensations: GceInvoicePersonCompensation[] = [];
  limitRows: number;
  heightMainList: number;
  openRightPanel: boolean = false;
  currentRow: any;
  editing: boolean = false;
  newRow: boolean = false;
  keyColumn: any = {};
  modal;
  relationTable;
  showb: boolean = false;

  conditionalFormattingConfig: IConditionalFormatting;

  customButtons: ICustomButton[] = [];

  constructor(private dialog: MatDialog, private gceService: GceManagementService, private alertDialog: AlertDialogService, private permissionService: PermissionService,
    private translateService: TranslateService, private actionsService: ActionsService) {
    this.conditionalFormattingConfig = {
      formulas: [{
        field: 'errore',
        rule: '=== true',
        classes: ['row-danger']
      },]
    };
    if (PermissionService.checkPermission("CGE_ANPEXCEL")) {
      this.customButtons.push({
        matTooltipCode: 'GCE.GCEINVOICEPERSON.BUTTONS.EXPORTTOEXCEL.TOOLTIP',
        labelCode: 'GCE.GCEINVOICEPERSON.BUTTONS.EXPORTTOEXCEL.LABEL',
        img: 'assets/img/excel.png',
        class: 'custom-button',
        clickFunction: this.exportToExcel.bind(this)
      });
      this.customButtons.push({
        matTooltipCode: 'GCE.GCEINVOICEPERSON.BUTTONS.EXPORTTOEXCEL.TOOLTIP',
        labelCode: 'GCE.GCEINVOICEPERSON.BUTTONS.EXPORTTOEXCEL.LABEL',
        img: 'assets/img/check-person.png',
        class: 'custom-button',
        clickFunction: this.batch.bind(this)
      });
    }
  }

  ngOnInit() {
    this.showb = true;
    this.limitRows = Math.floor((window.innerHeight - 269) / 31); //269 340
    this.heightMainList = window.innerHeight - 188; //188 259
    this.columns = GceInvoicePerson.getStructure();
    console.log(' GceInvoicePerson.getStructure() %o', this.columns);
    this.columns.forEach(column => {
      if (column.primaryKey) {
        this.keyColumn = column;
      }

    });
    this.personalBkp = [];

    // console.log(GceInvoicePerson.getStructure());
    this.gceService.getAllClinics().subscribe((clinics: GceClinic[]) => {
      this.gceService.dataRepoClinics = clinics;
    });

    this.gceService.subLoadDataForInvoicePerson().subscribe((dataResultPre: { personsclinics: GceInvoicePersonClinic[], personscompensations: GceInvoicePersonCompensation[] }) => {
      this.personsclinics = dataResultPre.personsclinics;
      this.personscompensations = dataResultPre.personscompensations;

      this.gceService.loadDataForInvoicePerson().subscribe((dataResult: { persons: GceInvoicePerson[], medicTypes: GceMedicType[], fiscalRegs: GceFiscalReg[], profileS: GceProfiles[], provS: GceProvCodes[] }) => {
        this.columns.forEach(column => {
          if (column.primaryKey) {
            this.keyColumn = column;
          }
          if (column.reference) {
            //console.log("column %o", column);
            let lookUpOptions: GceLookUpOptions;
            if (column.reference.entity === "GceMedicType") {
              lookUpOptions = this.gceService.getLookUpAndOptions(this.gceService.dataRepoMedicTypes, column.reference);
            }
            if (column.reference.entity === "GceFiscalReg") {
              lookUpOptions = this.gceService.getLookUpAndOptions(this.gceService.dataRepoFiscalReg, column.reference);
            }
            if (column.reference.entity === "GceProfiles") {
              lookUpOptions = this.gceService.getLookUpAndOptions(this.gceService.dataRepoProfiles, column.reference);
            }
            if (column.reference.entity === "GceProvCodes") {
              lookUpOptions = this.gceService.getLookUpAndOptions(this.gceService.dataRepoProvs, column.reference);
            }
            if (lookUpOptions) {
              column.lookUp = lookUpOptions.lookUp;
              column.options = lookUpOptions.options;
            }
          }
        });

        this.personal = dataResult.persons;
        this.personalBkp = [...this.personal];
        this.showb = false;
        // console.log("GcePersonalDataComponent ngOnInit %o", response);
      }, err => {
        this.personalBkp = [];
        this.showb = false;
      });
    });
  }

  onEdit() {
    this.newRow = false;
    this.editing = true;
    this.openRightPanel = true;
  }

  onNew() {
    this.editing = false;
    this.newRow = true;
    this.currentRow = {};
    this.openRightPanel = true;
    this.table.setNewRow(true);
  }

  onSearch(searchFilter: any) {
    console.log("onSearch %o", searchFilter);
    this.personal = this.personalBkp.filter(item => {
      for (let key in item) {
        if (item[key] != null && item[key] != undefined && item[key].toString().toLowerCase().indexOf(searchFilter.filter) >= 0)
          return true;
      }
      return false;
    });
    this.table.offset = 0;
  }

  onToggle(selectedColumns) {
    console.log("onToggle %o %o", selectedColumns);
    this.columns.map(item => {
      item.show = selectedColumns.indexOf(item.columnDef) != -1;
    });
  }

  onChangeRow(row) {
    console.log("onChangeRow %o", row);
    this.currentRow = CommonUtilsService.copyData(row);
    this.editing = false;
    this.newRow = false;
    this.openRightPanel = true;
  }

  onRestore(event) {
    console.log("onRestore %o", event);
    this.currentRow = null;
    this.table.setNewRow(false);
    this.clear();
    if (this.personal.length == 0) {
      this.onNew();
    }
  }

  onSave(event) {
    
    let dataToSave = event.form.getRawValue();

    if (dataToSave['flg_invio_dati_portale']) {
      let confirm: Confirm = {
        title: "Conferma invio dati portale",
        message: "Sei sicuro che devono essere inviati i dati al portale?",
        buttonOkText: "Si",
        buttonCancelText: "Annulla",
        buttonOkStyle: { 'background-color': '#268B26' },
        buttonCancelStyle: { 'background-color': '#AA3939' },
      };

      this.alertDialog.confirm(confirm).subscribe((result) => {
        if (result) {
          this.save(dataToSave);
        }
      });
    } else {

      this.save(dataToSave);
    }

  }


  delay = ms => new Promise(res => setTimeout(res, ms));
  async batch() {
    /**/
    //console.log('------------------------ medic list %o',this.personal);
    let i = 0;
    for (let a = 0; a < this.personal.length; a++) {
      i++;
      //console.log('------------------------ medic list %o', this.personal[a]);
      if (this.personal[a]['annullato'] == false && this.personal[a]['errore'] == false) {
        let birthDate = this.personal[a]['data_nascita'];
        if (birthDate == null) {
          birthDate = new Date();
        }
        let plyd = {
          username: this.personal[a]['email'],
          idOrganization: 1,
          enabled: true,
          primaryEmail: this.personal[a]['email'],
          givenName: "D.",
          profile:this.personal[a]['tipo_medico'],
          familyName: this.personal[a]['descrizione'],
          birthDate: birthDate
        };
        //console.log('------------------------ medic list %o payload %o', this.personal[a], plyd);
        // if (i > 209&&i<400) {
        /*
        try {
          await this.delay(1000);
          this.actionsService.postMedic(plyd).subscribe(r => {
            console.log('------------------------ medic response %o', r);
          });
        }
        catch (e) {
          console.log('------------------------ medic error payload %o', plyd);
        }
        */
        // }
      }
      else {
        console.log('MEDICO ANNULLATO %o', this.personal[a]['email']);
      }
    }
  }

  save(dataToSave) {
    console.log('MEDICO TO SAVE %o', dataToSave)

    /*

    let birthDate=dataToSave['data_nascita'];
    if(birthDate==null)
    {
      birthDate=new Date();
    }
    let plyd={
      username:dataToSave['email],
    idOrganization:1,
  enabled:true,
    primaryEmail:dataToSave['email],
    "givenName":"Andrea",
    "familyName":"Rogges",
    birthDate:birthDate
    };

    */
    if (dataToSave['data_nascita']) {
      dataToSave['data_nascita'] = moment(dataToSave['data_nascita']).format('YYYY-MM-DD');
    }
    if (dataToSave['data_laurea']) {
      dataToSave['data_laurea'] = moment(dataToSave['data_laurea']).format('YYYY-MM-DD');
    }
    if (dataToSave['scadenza_blsd']) {
      dataToSave['scadenza_blsd'] = moment(dataToSave['scadenza_blsd']).format('YYYY-MM-DD');
    }
    if (dataToSave['scadenza_radioprotezione']) {
      dataToSave['scadenza_radioprotezione'] = moment(dataToSave['scadenza_radioprotezione']).format('YYYY-MM-DD');
    }
    if (dataToSave['scadenza_rc_professionale']) {
      dataToSave['scadenza_rc_professionale'] = moment(dataToSave['scadenza_rc_professionale']).format('YYYY-MM-DD');
    }
    console.log(' insert row %o', dataToSave);

    this.showb = true;
    let birthDate = dataToSave['data_nascita'];
    if (birthDate == null) {
      birthDate = new Date();
    }
    let plyd = {
      username: dataToSave['email'],
      oldusername:this.currentRow['email'],
      idOrganization: 1,
      enabled: true,
      profile:dataToSave['tipo_medico'],
      primaryEmail: dataToSave['email'],
      givenName: "D.",
      familyName: dataToSave['descrizione'],
      birthDate: birthDate
    };
    if (!(dataToSave['cessato'] == true) && !(dataToSave['annullato'] == true)) {
      console.log('currentRow %o',this.currentRow);
      console.log('pyload %o',plyd);
      
      this.actionsService.postMedic(plyd).subscribe(r => {
        this.showb = false;
        if (this.newRow) {
          this.insert(CommonUtilsService.copyData(dataToSave));
        }
        else {
          this.update(CommonUtilsService.copyData(dataToSave));
        }
      });
      
    }
    else {
      if (this.newRow) {
        this.insert(CommonUtilsService.copyData(dataToSave));
      }
      else {
        this.update(CommonUtilsService.copyData(dataToSave));
      }
    }

    /*
    if (this.newRow) {
      //newmedico
      if (dataToSave['cessato'] == false)
        window.alert('creazione utenza per ' + dataToSave['descrizione'] + ' su email ' + dataToSave['email']);
    }
      else
        window.alert('verifica aggiornamento per ' + dataToSave['descrizione'] + ' su email ' + dataToSave['email']);
*/
  }

  onDelete(person: GceInvoicePerson) {
    console.log("onDelete %o", person);
    this.showb = true;
    this.gceService.deleteInvoicePerson(person.idfatturazione).subscribe((response: GceInvoicePerson) => {
      let idx = this.personalBkp.findIndex((per: GceInvoicePerson) => per.idfatturazione === response.idfatturazione);
      this.personalBkp[idx] = response;
      this.personal = [...this.personalBkp];
      this.clear();
      this.showb = false;
    });
  }

  onAction(event) {
    console.log("onAction %o", event);
  }

  onCloseForm(event) {
    console.log("onCloseForm %o", event);
    this.clear();
  }

  onCustomActionClick(args) {
    console.log("onCustomActionClick %o", args);
    if (args.field.columnDef === 'actionCompensation') {
      this.opendDialogCompensation(args.row);
    } else if (args.field.columnDef === 'actionClinicAssociation') {
      this.opendDialogClinicAssociation(args.row);
    } else if (args.field.columnDef === 'actionCheckPerson') {
      this.checkPerson(args.row);
    } else if (args.field.columnDef === 'actionAnagraphic') {
      this.openDialogaAnagraphic(args.row);
    }
  }

  checkPerson(person: GceInvoicePerson) {
    this.gceService.checkPerson(person).subscribe(response => {
      console.log("checkPerson response %o", response);
    })
  }

  opendDialogCompensation(person: GceInvoicePerson) {
    console.log("openDialogCompensation %o", person);
    let dialogRef = this.dialog.open(GceDialogCompensationComponent, {
      width: '80%',
      height: '80%',
      disableClose: true,
      data: person
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

  opendDialogClinicAssociation(person: GceInvoicePerson) {
    console.log("opendDialogClinicAssociation %o", person);
    let dialogRef = this.dialog.open(GceDialogClinicAssociationComponent, {
      width: '80%',
      height: '80%',
      disableClose: true,
      data: person
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

  openDialogaAnagraphic(person: GceInvoicePerson) {
    console.log("openDialogaAnagraphic %o", person);
    this.gceService.getAnagraphic(person.piva).subscribe((anagraphic: GceAnagraphic[]) => {
      if (anagraphic && anagraphic.length > 0) {
        if (anagraphic.length === 1) {
          let dialogRef = this.dialog.open(GceDialogAnagraphicComponent, {
            width: "700px",
            height: "auto",
            disableClose: true,
            data: { anagraphic: anagraphic[0] }

          });
          dialogRef.afterClosed().subscribe(result => {
            console.log(result);
          });
        } else {
          this.alertDialog.open({
            title: "GCE.GCEANAGRAPHIC.WARNING.MULTI.TITLE",
            messageCode: null,
            message: "GCE.GCEANAGRAPHIC.WARNING.MULTI.MESSAGE",
            isError: false,
            error: null,
            withTranslate: true
          });
        }
      } else {
        this.alertDialog.open({
          title: "GCE.GCEANAGRAPHIC.WARNING.NOTEXISTS.TITLE",
          messageCode: null,
          message: "GCE.GCEANAGRAPHIC.WARNING.NOTEXISTS.MESSAGE",
          isError: false,
          error: null,
          withTranslate: true
        });
      }
    }, err => {

    });
  }

  clear() {
    this.editing = false;
    this.newRow = false;
    this.openRightPanel = false;
  }

  insert(person: GceInvoicePerson) {
    this.showb = true;
    person.idfatturazione = 0;

    //check Type and cod_xdent
    if (person.tipo_medico == 3 || person.tipo_medico == 4) {
      console.log('CODICE XDENT NULL');
      person.codice_xdent == null;
    }

    this.gceService.insertInvoicePerson(person).subscribe((response: GceInvoicePerson) => {
      this.personalBkp.push(response);
      this.personal = [...this.personalBkp];
      this.clear();
      this.showb = false;
    }, err => {
      this.showb = false;
    });
  }

  update(person: GceInvoicePerson) {
    this.showb = true;
    this.gceService.updateInvoicePerson(person).subscribe((response: GceInvoicePerson) => {
      let idx = this.personalBkp.findIndex((per: GceInvoicePerson) => per.idfatturazione === response.idfatturazione);
      this.personalBkp[idx] = response;
      this.personal = [...this.personalBkp];
      this.clear();
      this.showb = false;
    }, err => {
      this.showb = false;
    });
  }

  exportToExcel() {
    let fileName = `${this.translateService.instant('AMM.GCE.PERSONALDATA').replace(' ', '_')}_${moment().format('YYYYMMDD')}`;
    this.gceService.exportToExcelInvoicePerson(this.personal, this.gceService.dataRepoClinics, this.personsclinics, this.personscompensations, fileName);
  }
}
