import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.css']
})
export class InputDialogComponent implements OnInit {
  @ViewChild('input', {static:false}) input: ElementRef;

  inputForm:InputForm = {
    title:"input",
    message:"",
    buttonOkText:"Ok",
    buttonCancelText:"Cancel",
    withTranslate:false,
    fieldLabel:"",
    value:"",
    inputType:"text"
  }

  constructor(public dialogRef: MatDialogRef<InputDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private translate: TranslateService) {
    if(data.withTranslate){
      this.translate.get(data.title).subscribe(data=>{
        this.inputForm.title= data;
      });
      this.translate.get(data.message).subscribe(data=>{
        this.inputForm.message= data;
      });
      this.translate.get(data.buttonOkText).subscribe(data=>{
        this.inputForm.buttonOkText= data;
      });
      this.translate.get(data.buttonCancelText).subscribe(data=>{
        this.inputForm.buttonCancelText= data;
      });
      this.translate.get(data.fieldLabel).subscribe(data=>{
        this.inputForm.fieldLabel= data;
      });
      this.inputForm.withTranslate = data.withTranslate;
    }else{
      this.inputForm.title = data.title;
      this.inputForm.message = data.message;
      this.inputForm.buttonOkText = data.buttonOkText;
      this.inputForm.buttonCancelText = data.buttonCancelText;
    }
    this.inputForm.buttonOkStyle = data.buttonOkStyle;
    this.inputForm.buttonCancelStyle = data.buttonCancelStyle;
  }

  ngOnInit() {
  }

  close(){
    console.log("close input-dialog %o", this.input.nativeElement);
    this.dialogRef.close(this.input.nativeElement.value);
  }

  cancel(){
    this.dialogRef.close();
  }
}

export interface InputForm {
  title:string;
  message:string;
  buttonOkText:string;
  buttonCancelText:string;
  buttonOkStyle?:{};
  buttonCancelStyle?:{};
  withTranslate?:boolean;
  fieldLabel:string;
  value:any;
  inputType:string;
}
