import { Component, OnInit, Input, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.css']
})
export class ImageDialogComponent implements OnInit {
  image:any={};
  extension:string;
  columnDef:string;

  constructor(public dialogRef: MatDialogRef<ImageDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.image = data.image;
    this.extension = data.extension;
    this.columnDef = data.columnDef;
    // console.log(data);
  }

  ngOnInit() {
  }

  close(){
    this.dialogRef.close();
  }

}
