/*
export const webApi = "https://webapidentalprotest.dentalpro.it/webapi";
//const winticApiUrl = "https://webapi.winticstellar.com/webapi";
//const identityUrl = "https://identitydentalpro.evolove.it";

//export const webApi = "https://webapidentalpro.dentalpro.it/webapi";
//const winticApiUrl = "https://webapi.winticstellar.com/webapi";
const identityUrl = "https://identity.dentalpro.it";

export const environment = {
  version: "1.4.16",
  production: false,
  useTaxCredit: false,
  isLicenseForEvaluationOnly: true,
  env:'dev',
  webApi: webApi,
  winticApiUrl: undefined,
  identityUrl: identityUrl,
  apiWinTic: undefined,
  apiWinTicDomain: undefined,
  apiAnagWinTic: undefined,
  apiCards: undefined,
  apiCommon: undefined,
  imgHandler: undefined,
  imgHandlerByPosterId: undefined,
  anagraficEndpoint: undefined,
  priceListEndpoint: undefined,
  filmsEndpoint: undefined,
  apiWinticSchedulator: undefined,
  apiTaxtCredit: undefined,
  apiRLibEndpoint: undefined,
  handlersEndpoint: undefined,
  oauthServiceIssuer: identityUrl,
  identityServerEndpoint: `${identityUrl}/api/`,
  identityServerPatientEndpoint:"https://ssopazienti.dentalpro.it/api/",
  apiGeo: `${identityUrl}/api/winticplusaccount/`,

  getPerformances: "getPerformances",
  //getShows:"getCalendar",
  getShows: "getActivePackages",
  getPrices: "getPrices",
  getOccupancy: "getOccupancy",
  lockSeats: "LockSeats",
  lockCards: "wpLockCards",
  getLockedSeats: "getLockedSeats",
  unLockSeats: "UnlockSeats",
  unLockCards: "wpUnLockCards",
  getBestSeats: "GetBestSeat",
  getBasketValues: "GetBasketValues",
  getOperationValues: "wpGetInfoTable",
  getPriceCategories: "getPricesGroups",
  insertBasket: "SellBasket",

  // Calendar API
  getProductDetails: 'getProductsDetails',
  getSchedule: 'GetCalBySpecificDate',
  getScheduleBetweenDate: 'GetCalBetweenDate',
  getScreens: 'getScreens',
  getListPrice: 'getPrices',
  getSlotPrices: 'GetTimeSlotsPrices',
  getListOrganizer: 'GetListOrganizers',
  getListEventType: 'GetListEventType',
  getSalesChannel: 'wpGetInfoTable',
  getActiveListPrice: 'GetActiveListPrices',
  setSchedule: 'setCalendarSchedule',

  // cards api
  getDots: 'wpGetCardSet',
  getLots: 'wpGetFreeCardsFromSet',
  getCards: 'wpGetFreeCards',
  getPersons: 'wpGetAngData',
  // TODO: in locale decommentare gli endpoint commentati (apigeo, identityServerpoint e oauthService issue)

  clientId: "DentalPro",
  companyName: "",
  companyWebPage: "www.evolutiontic.it",

  
  deviceManagerServer: "ws://localhost:12017/devicemanager",
  getMachineName: "getMachineName",
  updatePrinterStatus: "printersStatus",
  updatePosStatus: "PointsOfSaleStatus",
  printDocument: "Print",
  getCenterMap: "getcentermap",
  getGeometryMap: "geteventmap",
  zoomLevel0: 0.0008,
  zoomLevel0_1: 0.00126,
  zoomLevel0_2: 0.0026,
  zoomLevel05: 0.006,
  zoomLevel1: 0.009,
  zoomLevel2: 0.035,
  zoomLevel3: 1,
  nMaxResultSearch: 10,

  svgPreIconSimple: '<svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconSimple: ' viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve">' +
    '<polygon style="fill:#3C3C3B;" points="128.7,196.7 158.7,134 229.3,84 287,75.3 330.7,79.3 378.7,104.7 414.7,136 439.9,203.3 460.7,206.7 489.3,222 496,454.1 78.7,458 73.3,229.3 102,206.7 "/>' +
    '<g> <g> <path style="fill:#FFFFFF;" d="M503.7,462.3H65.7V250.5c0-32.1,25.4-58.3,56.6-58.3c31.2,0,56.6,26.2,56.6,58.3v99.1h211.5v-99.1c0-32.1,25.4-58.3,56.6-58.3c31.2,0,56.6,26.2,56.6,58.3V462.3z M84,444h401.4V250.5c0-22.1-17.2-40.1-38.4-40.1c-21.2,0-38.4,18-38.4,40.1v117.3h-248V250.5c0-22.1-17.2-40.1-38.4-40.1c-21.1,0-38.4,18-38.4,40.1V444z"/>' +
    '<path style="fill:#FFFFFF;" d="M447.1,206.1h-18.2c0-66.3-61.6-120.3-137.3-120.3H278c-75.7,0-137.4,54-137.4,120.3h-18.2c0-76.4,69.8-138.5,155.6-138.5h13.5C377.3,67.6,447.1,129.8,447.1,206.1z"/><g> </g> </g> </g> </svg>',

  svgPreIconOcc: '<svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconOcc: ' viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve">' +
    '<polygon style="fill:#E83859;" points="128.7,196.7 158.7,134 229.3,84 287,75.3 330.7,79.3 378.7,104.7 414.7,136 439.9,203.3 460.7,206.7 489.3,222 496,454.1 78.7,458 73.3,229.3 102,206.7 "/>' +
    '<g> <g> <path style="fill:#FFFFFF;" d="M503.7,462.3H65.7V250.5c0-32.1,25.4-58.3,56.6-58.3c31.2,0,56.6,26.2,56.6,58.3v99.1h211.5v-99.1c0-32.1,25.4-58.3,56.6-58.3c31.2,0,56.6,26.2,56.6,58.3V462.3z M84,444h401.4V250.5c0-22.1-17.2-40.1-38.4-40.1c-21.2,0-38.4,18-38.4,40.1v117.3h-248V250.5c0-22.1-17.2-40.1-38.4-40.1c-21.1,0-38.4,18-38.4,40.1V444z"/>' +
    '<path style="fill:#FFFFFF;" d="M447.1,206.1h-18.2c0-66.3-61.6-120.3-137.3-120.3H278c-75.7,0-137.4,54-137.4,120.3h-18.2c0-76.4,69.8-138.5,155.6-138.5h13.5C377.3,67.6,447.1,129.8,447.1,206.1z"/><g> </g> </g> </g> </svg>',

  svgPreIconSel: '<svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconSel: ' viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve">' +
    '<polygon style="fill:#EB995E;" points="128.7,196.7 158.7,134 229.3,84 287,75.3 330.7,79.3 378.7,104.7 414.7,136 439.9,203.3 460.7,206.7 489.3,222 496,454.1 78.7,458 73.3,229.3 102,206.7 "/>' +
    '<g> <g> <path style="fill:#FFFFFF;" d="M503.7,462.3H65.7V250.5c0-32.1,25.4-58.3,56.6-58.3c31.2,0,56.6,26.2,56.6,58.3v99.1h211.5v-99.1c0-32.1,25.4-58.3,56.6-58.3c31.2,0,56.6,26.2,56.6,58.3V462.3z M84,444h401.4V250.5c0-22.1-17.2-40.1-38.4-40.1c-21.2,0-38.4,18-38.4,40.1v117.3h-248V250.5c0-22.1-17.2-40.1-38.4-40.1c-21.1,0-38.4,18-38.4,40.1V444z"/>' +
    '<path style="fill:#FFFFFF;" d="M447.1,206.1h-18.2c0-66.3-61.6-120.3-137.3-120.3H278c-75.7,0-137.4,54-137.4,120.3h-18.2c0-76.4,69.8-138.5,155.6-138.5h13.5C377.3,67.6,447.1,129.8,447.1,206.1z"/><g> </g> </g> </g> </svg>',

  svgPreIconRes: '<?xml version="1.0" encoding="utf-8"?> <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconRes: ' viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve"><style type="text/css"> .st0{fill:#3C3C3B;}	.st1{fill:#F2C35A;}	.st2{fill:#F2C35A;stroke:#F2C35A;stroke-width:7.8669;stroke-miterlimit:10;}</style><polygon class="st0" points="122.2,194.9 152.7,130.5 224.3,79.2 282.9,70.3 327.2,74.4 376,100.4 412.5,132.6 438.1,201.6 459.2,205.1 488.2,220.8 495,459 71.5,463 66,228.3 95.1,205.1 "/><g><g><g><path class="st1" d="M498.3,463.3H61.1V251.9c0-32.1,25.4-58.2,56.5-58.2c31.2,0,56.5,26.1,56.5,58.2v98.9h211.1v-98.9c0-32.1,25.4-58.2,56.5-58.2c31.2,0,56.5,26.1,56.5,58.2L498.3,463.3L498.3,463.3z M79.3,445.1h400.8V251.9c0-22.1-17.2-40-38.3-40c-21.1,0-38.3,17.9-38.3,40V369H155.9V251.9c0-22.1-17.2-40-38.3-40s-38.3,17.9-38.3,40L79.3,445.1L79.3,445.1z"/><path class="st1" d="M441.8,207.6h-18.2c0-66.2-61.5-120.1-137.1-120.1H273c-75.6,0-137.1,53.9-137.1,120.1h-18.2c0-76.2,69.7-138.3,155.3-138.3h13.5C372.1,69.3,441.8,131.3,441.8,207.6z"/></g></g></g><path class="st2" d="M342.2,158.5c-1.2-0.5-29.8-11.7-52,0.1c-17.6,9.4-48.6,2.3-56.5,0.3v-6.1c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6v7.9c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1v75.9v2.8V305c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6v-62c6,1.5,19,4.3,32.4,4.3c9.3,0,18.9-1.3,26.5-5.4c20.1-10.6,47.3,0,47.6,0.1c0.8,0.3,1.7,0.2,2.4-0.3c0.7-0.5,1.2-1.3,1.2-2.2V161C343.9,159.9,343.2,158.9,342.2,158.5z M338.6,235.9c-8.5-2.7-30.5-8.1-48.4,1.4c-17.6,9.4-48.5,2.3-56.5,0.3v-0.8v-72.4c10.1,2.5,40.3,8.8,58.9-1.1c17.2-9.1,39.7-2.6,46-0.5V235.9z"/></svg>',

  svgPreIconResOcc: '<?xml version="1.0" encoding="utf-8"?> <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconResOcc: '	viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve"><style type="text/css">.st0{fill:#E83859;}.st1{fill:#FFFFFF;}.st2{fill:#FFFFFF;stroke:#FFFFFF;stroke-width:7.8669;stroke-miterlimit:10;}' +
    '</style><polygon class="st0" points="122.2,194.9 152.7,130.5 224.3,79.2 282.9,70.3 327.2,74.4 376,100.4 412.5,132.6 438.1,201.6 459.2,205.1 488.2,220.8 495,459 71.5,463 66,228.3 95.1,205.1 "/><g> <g>	<g>	<path class="st1" d="M498.3,463.3H61.1V251.9c0-32.1,25.4-58.2,56.5-58.2c31.2,0,56.5,26.1,56.5,58.2v98.9h211.1v-98.9c0-32.1,25.4-58.2,56.5-58.2c31.2,0,56.5,26.1,56.5,58.2L498.3,463.3L498.3,463.3z M79.3,445.1h400.8V251.9c0-22.1-17.2-40-38.3-40c-21.1,0-38.3,17.9-38.3,40V369H155.9V251.9c0-22.1-17.2-40-38.3-40s-38.3,17.9-38.3,40L79.3,445.1L79.3,445.1z"/>' +
    '<path class="st1" d="M441.8,207.6h-18.2c0-66.2-61.5-120.1-137.1-120.1H273c-75.6,0-137.1,53.9-137.1,120.1h-18.2c0-76.2,69.7-138.3,155.3-138.3h13.5C372.1,69.3,441.8,131.3,441.8,207.6z"/></g></g></g><path class="st2" d="M342.2,158.5c-1.2-0.5-29.8-11.7-52,0.1c-17.6,9.4-48.6,2.3-56.5,0.3v-6.1c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6v7.9c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1v75.9v2.8V305c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6v-62c6,1.5,19,4.3,32.4,4.3c9.3,0,18.9-1.3,26.5-5.4c20.1-10.6,47.3,0,47.6,0.1c0.8,0.3,1.7,0.2,2.4-0.3c0.7-0.5,1.2-1.3,1.2-2.2V161C343.9,159.9,343.2,158.9,342.2,158.5z M338.6,235.9c-8.5-2.7-30.5-8.1-48.4,1.4c-17.6,9.4-48.5,2.3-56.5,0.3v-0.8v-72.4c10.1,2.5,40.3,8.8,58.9-1.1c17.2-9.1,39.7-2.6,46-0.5V235.9z"/></svg>',

  svgPreIconResSel: '<?xml version="1.0" encoding="utf-8"?> <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconResSel: '	viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve"><style type="text/css">.st0{fill:#F2C35A;}.st1{fill:#FFFFFF;}.st2{fill:#FFFFFF;stroke:#FFFFFF;stroke-width:7.8669;stroke-miterlimit:10;}' +
    '</style><polygon class="st0" points="122.2,194.9 152.7,130.5 224.3,79.2 282.9,70.3 327.2,74.4 376,100.4 412.5,132.6 438.1,201.6 459.2,205.1 488.2,220.8 495,459 71.5,463 66,228.3 95.1,205.1 "/><g> <g>	<g>	<path class="st1" d="M498.3,463.3H61.1V251.9c0-32.1,25.4-58.2,56.5-58.2c31.2,0,56.5,26.1,56.5,58.2v98.9h211.1v-98.9c0-32.1,25.4-58.2,56.5-58.2c31.2,0,56.5,26.1,56.5,58.2L498.3,463.3L498.3,463.3z M79.3,445.1h400.8V251.9c0-22.1-17.2-40-38.3-40c-21.1,0-38.3,17.9-38.3,40V369H155.9V251.9c0-22.1-17.2-40-38.3-40s-38.3,17.9-38.3,40L79.3,445.1L79.3,445.1z"/>' +
    '<path class="st1" d="M441.8,207.6h-18.2c0-66.2-61.5-120.1-137.1-120.1H273c-75.6,0-137.1,53.9-137.1,120.1h-18.2c0-76.2,69.7-138.3,155.3-138.3h13.5C372.1,69.3,441.8,131.3,441.8,207.6z"/></g></g></g><path class="st2" d="M342.2,158.5c-1.2-0.5-29.8-11.7-52,0.1c-17.6,9.4-48.6,2.3-56.5,0.3v-6.1c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6v7.9c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1v75.9v2.8V305c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6v-62c6,1.5,19,4.3,32.4,4.3c9.3,0,18.9-1.3,26.5-5.4c20.1-10.6,47.3,0,47.6,0.1c0.8,0.3,1.7,0.2,2.4-0.3c0.7-0.5,1.2-1.3,1.2-2.2V161C343.9,159.9,343.2,158.9,342.2,158.5z M338.6,235.9c-8.5-2.7-30.5-8.1-48.4,1.4c-17.6,9.4-48.5,2.3-56.5,0.3v-0.8v-72.4c10.1,2.5,40.3,8.8,58.9-1.1c17.2-9.1,39.7-2.6,46-0.5V235.9z"/></svg>',

  svgPreIconDis: '<?xml version="1.0" encoding="utf-8"?> <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconDis: 'viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve"><polygon style="fill:#3C3C3B;" points="127.7,200.7 157.7,138 228.3,88 286,79.3 329.7,83.3 377.7,108.7 413.7,140 438.9,207.3 459.7,210.7 488.3,226 495,458.1 77.7,462 72.3,233.3 101,210.7 "/>' +
    '<g><g>	<path style="fill:#5EB6E7;" d="M503.3,463.3H65.7V251.7c0-32.1,25.4-58.3,56.5-58.3c31.2,0,56.6,26.1,56.6,58.3v99h211.3v-99c0-32.1,25.4-58.3,56.6-58.3c31.2,0,56.6,26.1,56.6,58.3V463.3z M84,445.1h401.1V251.7c0-22.1-17.2-40-38.3-40c-21.1,0-38.3,17.9-38.3,40V369H160.6V251.7c0-22.1-17.2-40-38.4-40c-21.1,0-38.3,17.9-38.3,40V445.1z"/>' +
    '<path style="fill:#5EB6E7;" d="M446.7,207.4h-18.2c0-66.3-61.6-120.2-137.2-120.2h-13.5c-75.7,0-137.2,53.9-137.2,120.2h-18.2C122.3,131.1,192,69,277.8,69h13.5C377,69,446.7,131.1,446.7,207.4z"/></g></g><g><g><g><path style="fill:#5EB6E7;" d="M360.4,312.6l-13.1-56.7c-1.5-5.9-6.7-9.9-12.7-9.9h-25.4c-3.6-5.9-8.3-11.1-13.8-15.2v-37.1c0-9.9-10.6-15.9-18.9-11.7L250.3,195c-4.5,2.2-7.2,6.7-7.2,11.7v16.7c-0.1,0-0.2,0-0.2,0.1c-21.1,8.2-34.6,28.2-34.6,50.8c0,30,24.4,54.5,54.5,54.5c30.2,0,55.7-25,54.4-56.7h7.3l10.6,46.8c1.7,7,8.8,11.3,15.9,9.5C357.9,326.7,362.2,319.6,360.4,312.6z M262.7,315.7c-22.8,0-41.4-18.6-41.4-41.4c0-15.5,8.5-29.3,21.8-36.4v16.8c0,7.2,5.8,13.1,13,13.1c7.2,0,13.1-5.9,13.1-13.1v-21.4c0.3,0.1,0.6,0.2,1,0.3c19.6,3.6,33.9,20.7,33.9,40.7C304.1,297.2,285.6,315.7,262.7,315.7z"/></g></g><g><g><path style="fill:#5EB6E7;" d="M284.5,128.3c-10.8,0-19.6,8.8-19.6,19.6c0,10.8,8.8,19.6,19.6,19.6c10.8,0,19.6-8.8,19.6-19.6C304.2,137.1,295.3,128.3,284.5,128.3z"/></g></g></g></svg>',

  svgPreIconDisSel: '<?xml version="1.0" encoding="utf-8"?> <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconDisSel: 'viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve"><polygon style="fill:#5EB6E7;" points="127.7,200.7 157.7,138 228.3,88 286,79.3 329.7,83.3 377.7,108.7 413.7,140 438.9,207.3 459.7,210.7 488.3,226 495,458.1 77.7,462 72.3,233.3 101,210.7 "/>' +
    '<g><g>	<path style="fill:#FFFFFF;" d="M503.3,463.3H65.7V251.7c0-32.1,25.4-58.3,56.5-58.3c31.2,0,56.6,26.1,56.6,58.3v99h211.3v-99c0-32.1,25.4-58.3,56.6-58.3c31.2,0,56.6,26.1,56.6,58.3V463.3z M84,445.1h401.1V251.7c0-22.1-17.2-40-38.3-40c-21.1,0-38.3,17.9-38.3,40V369H160.6V251.7c0-22.1-17.2-40-38.4-40c-21.1,0-38.3,17.9-38.3,40V445.1z"/>' +
    '<path style="fill:#FFFFFF;" d="M446.7,207.4h-18.2c0-66.3-61.6-120.2-137.2-120.2h-13.5c-75.7,0-137.2,53.9-137.2,120.2h-18.2C122.3,131.1,192,69,277.8,69h13.5C377,69,446.7,131.1,446.7,207.4z"/></g></g><g><g><g><path style="fill:#FFFFFF;" d="M360.4,312.6l-13.1-56.7c-1.5-5.9-6.7-9.9-12.7-9.9h-25.4c-3.6-5.9-8.3-11.1-13.8-15.2v-37.1c0-9.9-10.6-15.9-18.9-11.7L250.3,195c-4.5,2.2-7.2,6.7-7.2,11.7v16.7c-0.1,0-0.2,0-0.2,0.1c-21.1,8.2-34.6,28.2-34.6,50.8c0,30,24.4,54.5,54.5,54.5c30.2,0,55.7-25,54.4-56.7h7.3l10.6,46.8c1.7,7,8.8,11.3,15.9,9.5C357.9,326.7,362.2,319.6,360.4,312.6z M262.7,315.7c-22.8,0-41.4-18.6-41.4-41.4c0-15.5,8.5-29.3,21.8-36.4v16.8c0,7.2,5.8,13.1,13,13.1c7.2,0,13.1-5.9,13.1-13.1v-21.4c0.3,0.1,0.6,0.2,1,0.3c19.6,3.6,33.9,20.7,33.9,40.7C304.1,297.2,285.6,315.7,262.7,315.7z"/></g></g><g><g><path style="fill:#FFFFFF;" d="M284.5,128.3c-10.8,0-19.6,8.8-19.6,19.6c0,10.8,8.8,19.6,19.6,19.6c10.8,0,19.6-8.8,19.6-19.6C304.2,137.1,295.3,128.3,284.5,128.3z"/></g></g></g></svg>',

  svgPreIconDisOcc: '<?xml version="1.0" encoding="utf-8"?> <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconDisOcc: 'viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve"><polygon style="fill:#E83859;" points="127.7,200.7 157.7,138 228.3,88 286,79.3 329.7,83.3 377.7,108.7 413.7,140 438.9,207.3 459.7,210.7 488.3,226 495,458.1 77.7,462 72.3,233.3 101,210.7 "/>' +
    '<g><g>	<path style="fill:#FFFFFF;" d="M503.3,463.3H65.7V251.7c0-32.1,25.4-58.3,56.5-58.3c31.2,0,56.6,26.1,56.6,58.3v99h211.3v-99c0-32.1,25.4-58.3,56.6-58.3c31.2,0,56.6,26.1,56.6,58.3V463.3z M84,445.1h401.1V251.7c0-22.1-17.2-40-38.3-40c-21.1,0-38.3,17.9-38.3,40V369H160.6V251.7c0-22.1-17.2-40-38.4-40c-21.1,0-38.3,17.9-38.3,40V445.1z"/>' +
    '<path style="fill:#FFFFFF;" d="M446.7,207.4h-18.2c0-66.3-61.6-120.2-137.2-120.2h-13.5c-75.7,0-137.2,53.9-137.2,120.2h-18.2C122.3,131.1,192,69,277.8,69h13.5C377,69,446.7,131.1,446.7,207.4z"/></g></g><g><g><g><path style="fill:#FFFFFF;" d="M360.4,312.6l-13.1-56.7c-1.5-5.9-6.7-9.9-12.7-9.9h-25.4c-3.6-5.9-8.3-11.1-13.8-15.2v-37.1c0-9.9-10.6-15.9-18.9-11.7L250.3,195c-4.5,2.2-7.2,6.7-7.2,11.7v16.7c-0.1,0-0.2,0-0.2,0.1c-21.1,8.2-34.6,28.2-34.6,50.8c0,30,24.4,54.5,54.5,54.5c30.2,0,55.7-25,54.4-56.7h7.3l10.6,46.8c1.7,7,8.8,11.3,15.9,9.5C357.9,326.7,362.2,319.6,360.4,312.6z M262.7,315.7c-22.8,0-41.4-18.6-41.4-41.4c0-15.5,8.5-29.3,21.8-36.4v16.8c0,7.2,5.8,13.1,13,13.1c7.2,0,13.1-5.9,13.1-13.1v-21.4c0.3,0.1,0.6,0.2,1,0.3c19.6,3.6,33.9,20.7,33.9,40.7C304.1,297.2,285.6,315.7,262.7,315.7z"/></g></g><g><g><path style="fill:#FFFFFF;" d="M284.5,128.3c-10.8,0-19.6,8.8-19.6,19.6c0,10.8,8.8,19.6,19.6,19.6c10.8,0,19.6-8.8,19.6-19.6C304.2,137.1,295.3,128.3,284.5,128.3z"/></g></g></g></svg>',

  svgPreIconAcc: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconAcc: ' viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve"><polygon style="fill:#3C3C3B;" points="128.7,196.7 158.7,134 229.3,84 287,75.3 330.7,79.3 378.7,104.7 414.7,136 439.9,203.3 460.7,206.7 489.3,222 496,454.1 78.7,458 73.3,229.3 102,206.7 "/><g><g>' +
    '<path style="fill:#5EB6E7;" d="M502.6,463.2H64.7V251.5c0-32.1,25.4-58.3,56.6-58.3c31.3,0,56.6,26.2,56.6,58.3v99.1h211.4v-99.1c0-32.1,25.4-58.3,56.6-58.3c31.2,0,56.6,26.2,56.6,58.3V463.2z M83,444.9h401.3V251.5c0-22.1-17.2-40.1-38.4-40.1c-21.1,0-38.3,18-38.3,40.1v117.3H159.7V251.5c0-22.1-17.2-40.1-38.4-40.1c-21.1,0-38.3,18-38.3,40.1V444.9z"/><path style="fill:#5EB6E7;" d="M446,207.1h-18.2c0-66.3-61.6-120.2-137.3-120.2h-13.5c-75.7,0-137.3,53.9-137.3,120.2h-18.2c0-76.3,69.8-138.5,155.6-138.5h13.5C376.2,68.6,446,130.7,446,207.1z"/></g></g><g><path style="fill:#5EB6E7;" d="M316.9,272.3h-67.2l-15.5,47.2h-33.6l64.7-182.4h36.8l64.7,182.4h-34.4L316.9,272.3z M257.3,247.2h51.8l-25.4-77.9h-0.9L257.3,247.2z"/></g></svg>',

  svgPreIconAccSel: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconAccSel: ' viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve"><polygon style="fill:#5EB6E7;" points="128.7,196.7 158.7,134 229.3,84 287,75.3 330.7,79.3 378.7,104.7 414.7,136 439.9,203.3 460.7,206.7 489.3,222 496,454.1 78.7,458 73.3,229.3 102,206.7 "/><g><g>' +
    '<path style="fill:#FFFFFF;" d="M502.6,463.2H64.7V251.5c0-32.1,25.4-58.3,56.6-58.3c31.3,0,56.6,26.2,56.6,58.3v99.1h211.4v-99.1c0-32.1,25.4-58.3,56.6-58.3c31.2,0,56.6,26.2,56.6,58.3V463.2z M83,444.9h401.3V251.5c0-22.1-17.2-40.1-38.4-40.1c-21.1,0-38.3,18-38.3,40.1v117.3H159.7V251.5c0-22.1-17.2-40.1-38.4-40.1c-21.1,0-38.3,18-38.3,40.1V444.9z"/><path style="fill:#FFFFFF;" d="M446,207.1h-18.2c0-66.3-61.6-120.2-137.3-120.2h-13.5c-75.7,0-137.3,53.9-137.3,120.2h-18.2c0-76.3,69.8-138.5,155.6-138.5h13.5C376.2,68.6,446,130.7,446,207.1z"/></g></g><g><path style="fill:#FFFFFF;" d="M316.9,272.3h-67.2l-15.5,47.2h-33.6l64.7-182.4h36.8l64.7,182.4h-34.4L316.9,272.3z M257.3,247.2h51.8l-25.4-77.9h-0.9L257.3,247.2z"/></g></svg>',

  svgPreIconAccOcc: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconAccOcc: ' viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve"><polygon style="fill:#E83859;" points="128.7,196.7 158.7,134 229.3,84 287,75.3 330.7,79.3 378.7,104.7 414.7,136 439.9,203.3 460.7,206.7 489.3,222 496,454.1 78.7,458 73.3,229.3 102,206.7 "/><g><g>' +
    '<path style="fill:#FFFFFF;" d="M502.6,463.2H64.7V251.5c0-32.1,25.4-58.3,56.6-58.3c31.3,0,56.6,26.2,56.6,58.3v99.1h211.4v-99.1c0-32.1,25.4-58.3,56.6-58.3c31.2,0,56.6,26.2,56.6,58.3V463.2z M83,444.9h401.3V251.5c0-22.1-17.2-40.1-38.4-40.1c-21.1,0-38.3,18-38.3,40.1v117.3H159.7V251.5c0-22.1-17.2-40.1-38.4-40.1c-21.1,0-38.3,18-38.3,40.1V444.9z"/><path style="fill:#FFFFFF;" d="M446,207.1h-18.2c0-66.3-61.6-120.2-137.3-120.2h-13.5c-75.7,0-137.3,53.9-137.3,120.2h-18.2c0-76.3,69.8-138.5,155.6-138.5h13.5C376.2,68.6,446,130.7,446,207.1z"/></g></g><g><path style="fill:#FFFFFF;" d="M316.9,272.3h-67.2l-15.5,47.2h-33.6l64.7-182.4h36.8l64.7,182.4h-34.4L316.9,272.3z M257.3,247.2h51.8l-25.4-77.9h-0.9L257.3,247.2z"/></g></svg>',

  svgPreIconLove: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconLove: ' viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve"><style type="text/css">.st0{fill:#3C3C3B;}.st1{fill:#F45894;}</style><polygon class="st0" points="122.2,194.9 152.7,130.5 224.3,79.2 282.9,70.3 327.2,74.4 376,100.4 412.5,132.6 438.1,201.6 459.2,205.1 488.2,220.8 495,459 71.5,463 66,228.3 95.1,205.1 "/><g><path class="st1" d="M365.5,195.8c0-25.4-20-45.9-44.6-45.9c-0.6,0-1.2,0-1.7,0c-5.2,0.2-10.1,1.3-14.7,3.2c-0.5,0.2-1,0.4-1.5,0.7c-6,2.7-11.3,6.7-15.6,11.7c-3.2,3.7-5.8,8.8-7.7,13.5c-1.9-4.7-4.5-9.7-7.7-13.5c-4.3-5-9.6-9-15.6-11.7c-0.5-0.2-1-0.4-1.5-0.7c-4.6-1.9-9.5-3-14.7-3.2c-0.6,0-1.2,0-1.7,0c-24.6,0-44.6,20.6-44.6,45.9c0,0.6,0,2.9,0,3.5c0,39.6,41.2,76.9,66.5,96.1c6.6,5,12.3,8.9,15.8,11.2c2.2,1.4,5,1.4,7.1,0c3.4-2.2,9-6,15.7-11.2c25.3-19.2,66.5-56.5,66.5-96.1C365.5,198.7,365.5,196.4,365.5,195.8z"/>' +
    '<g><g><path class="st1" d="M498.3,463.3H61.1V251.9c0-32.1,25.4-58.2,56.5-58.2c31.2,0,56.5,26.1,56.5,58.2v98.9h211.1v-98.9c0-32.1,25.4-58.2,56.5-58.2c31.2,0,56.5,26.1,56.5,58.2V463.3z M79.3,445.1h400.8V251.9c0-22.1-17.2-40-38.3-40c-21.1,0-38.3,17.9-38.3,40V369H155.9V251.9c0-22.1-17.2-40-38.3-40c-21.1,0-38.3,17.9-38.3,40V445.1z"/><path class="st1" d="M441.8,207.6h-18.2c0-66.2-61.5-120.1-137.1-120.1h-13.5c-75.6,0-137.1,53.9-137.1,120.1h-18.2c0-76.2,69.7-138.3,155.3-138.3h13.5C372.1,69.3,441.8,131.3,441.8,207.6z"/></g></g></g></svg>',

  svgPreIconLoveSel: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconLoveSel: ' viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve"><style type="text/css">.st0{fill:#F45894;}.st1{fill:#FFFFFF;}</style><polygon class="st0" points="122.2,194.9 152.7,130.5 224.3,79.2 282.9,70.3 327.2,74.4 376,100.4 412.5,132.6 438.1,201.6 459.2,205.1 488.2,220.8 495,459 71.5,463 66,228.3 95.1,205.1 "/><g><path class="st1" d="M365.5,195.8c0-25.4-20-45.9-44.6-45.9c-0.6,0-1.2,0-1.7,0c-5.2,0.2-10.1,1.3-14.7,3.2c-0.5,0.2-1,0.4-1.5,0.7c-6,2.7-11.3,6.7-15.6,11.7c-3.2,3.7-5.8,8.8-7.7,13.5c-1.9-4.7-4.5-9.7-7.7-13.5c-4.3-5-9.6-9-15.6-11.7c-0.5-0.2-1-0.4-1.5-0.7c-4.6-1.9-9.5-3-14.7-3.2c-0.6,0-1.2,0-1.7,0c-24.6,0-44.6,20.6-44.6,45.9c0,0.6,0,2.9,0,3.5c0,39.6,41.2,76.9,66.5,96.1c6.6,5,12.3,8.9,15.8,11.2c2.2,1.4,5,1.4,7.1,0c3.4-2.2,9-6,15.7-11.2c25.3-19.2,66.5-56.5,66.5-96.1C365.5,198.7,365.5,196.4,365.5,195.8z"/>' +
    '<g><g><path class="st1" d="M498.3,463.3H61.1V251.9c0-32.1,25.4-58.2,56.5-58.2c31.2,0,56.5,26.1,56.5,58.2v98.9h211.1v-98.9c0-32.1,25.4-58.2,56.5-58.2c31.2,0,56.5,26.1,56.5,58.2V463.3z M79.3,445.1h400.8V251.9c0-22.1-17.2-40-38.3-40c-21.1,0-38.3,17.9-38.3,40V369H155.9V251.9c0-22.1-17.2-40-38.3-40c-21.1,0-38.3,17.9-38.3,40V445.1z"/><path class="st1" d="M441.8,207.6h-18.2c0-66.2-61.5-120.1-137.1-120.1h-13.5c-75.6,0-137.1,53.9-137.1,120.1h-18.2c0-76.2,69.7-138.3,155.3-138.3h13.5C372.1,69.3,441.8,131.3,441.8,207.6z"/></g></g></g></svg>',

  svgPreIconLoveOcc: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconLoveOcc: ' viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve"><style type="text/css">.st0{fill:#E83859;}.st1{fill:#FFFFFF;}</style><polygon class="st0" points="122.2,194.9 152.7,130.5 224.3,79.2 282.9,70.3 327.2,74.4 376,100.4 412.5,132.6 438.1,201.6 459.2,205.1 488.2,220.8 495,459 71.5,463 66,228.3 95.1,205.1 "/><g><path class="st1" d="M365.5,195.8c0-25.4-20-45.9-44.6-45.9c-0.6,0-1.2,0-1.7,0c-5.2,0.2-10.1,1.3-14.7,3.2c-0.5,0.2-1,0.4-1.5,0.7c-6,2.7-11.3,6.7-15.6,11.7c-3.2,3.7-5.8,8.8-7.7,13.5c-1.9-4.7-4.5-9.7-7.7-13.5c-4.3-5-9.6-9-15.6-11.7c-0.5-0.2-1-0.4-1.5-0.7c-4.6-1.9-9.5-3-14.7-3.2c-0.6,0-1.2,0-1.7,0c-24.6,0-44.6,20.6-44.6,45.9c0,0.6,0,2.9,0,3.5c0,39.6,41.2,76.9,66.5,96.1c6.6,5,12.3,8.9,15.8,11.2c2.2,1.4,5,1.4,7.1,0c3.4-2.2,9-6,15.7-11.2c25.3-19.2,66.5-56.5,66.5-96.1C365.5,198.7,365.5,196.4,365.5,195.8z"/>' +
    '<g><g><path class="st1" d="M498.3,463.3H61.1V251.9c0-32.1,25.4-58.2,56.5-58.2c31.2,0,56.5,26.1,56.5,58.2v98.9h211.1v-98.9c0-32.1,25.4-58.2,56.5-58.2c31.2,0,56.5,26.1,56.5,58.2V463.3z M79.3,445.1h400.8V251.9c0-22.1-17.2-40-38.3-40c-21.1,0-38.3,17.9-38.3,40V369H155.9V251.9c0-22.1-17.2-40-38.3-40c-21.1,0-38.3,17.9-38.3,40V445.1z"/><path class="st1" d="M441.8,207.6h-18.2c0-66.2-61.5-120.1-137.1-120.1h-13.5c-75.6,0-137.1,53.9-137.1,120.1h-18.2c0-76.2,69.7-138.3,155.3-138.3h13.5C372.1,69.3,441.8,131.3,441.8,207.6z"/></g></g></g></svg>',

  scaleIconForSpacing: 0.9,
  dimSvgIconSimpleFreeSeatLevel2: 264,
  dimSvgIconSimpleFreeSeatLevel1: "1.00",
  dimSvgIconSimpleFreeSeatLevelT1: 50,
  dimSvgIconSimpleFreeSeatLevel0: 20,
  dimSvgIconSimpleFreeSeatLevelT1_1: 66,
  dimSvgIconSimpleFreeSeatLevelT1_2: 132,

  svgPreCinemaScreen: ' <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" ',
  svgPostCinemaScreen: 'viewBox="0 0 1105 107.5" style="enable-background:new 0 0 1105 107.5;" xml:space="preserve">' +
    '<style type="text/css"> .st0{fill:url(#SVGID_1_);} .st1{fill:#FFFFFF;} .st2{fill:#A3A3A3;}</style>' +
    '<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="553.3363" y1="37.6008" x2="553.3363" y2="71.3008" gradientTransform="matrix(1 0 0 -1 0 132)">' +
    '<stop  offset="0" style="stop-color:#010101;stop-opacity:0"/>' +
    '<stop  offset="1" style="stop-color:#010101"/>' +
    '</linearGradient>' +
    '<polygon class="st0" points="1068.6,60.7 872.9,60.7 657.9,60.7 644.6,60.7 462.1,60.7 448.8,60.7 233.8,60.7 37.9,60.7 9,94.4 233.8,94.4 433,94.4 448.8,94.4 657.9,94.4 673.6,94.4 872.9,94.4 1097.7,94.4 "/>' +
    '<polygon class="st1" points="1097.7,25.7 9,25.7 12.2,22.3 1094.6,22.3 "/>' +
    '<polygon class="st2" points="872.9,25.7 673.6,25.7 657.9,25.7 448.8,25.7 433,25.7 233.8,25.7 9,25.7 37.9,61.4 233.8,61.4 448.8,61.4 462.1,61.4 644.6,61.4 657.9,61.4 872.9,61.4 1068.6,61.4 1097.7,25.7 "/>' +
    '<g>' +
    '<path class="st1" d="M501.9,46.4c0-0.5-0.1-0.9-0.5-1.3c-0.4-0.4-1-0.6-1.9-0.9c-1.1-0.3-1.9-0.6-2.5-1.1c-0.6-0.5-0.9-1.1-0.9-2c0-0.9,0.4-1.5,1-2.1c0.6-0.6,1.5-0.9,2.5-0.9c1.1,0,2,0.4,2.6,1c0.6,0.6,1,1.4,1,2.3l0,0h-1.4c0-0.6-0.3-1.1-0.6-1.5c-0.4-0.4-1-0.6-1.6-0.6c-0.6,0-1.3,0.1-1.5,0.5c-0.3,0.4-0.5,0.8-0.5,1.3c0,0.5,0.3,0.9,0.6,1.1c0.4,0.3,1,0.6,1.9,0.9c1.1,0.3,1.9,0.8,2.5,1.3c0.6,0.5,0.9,1.3,0.9,2c0,0.9-0.4,1.5-1,2.1c-0.6,0.6-1.5,0.8-2.6,0.8c-1,0-2-0.3-2.8-0.9c-0.8-0.6-1.1-1.4-1.1-2.4l0,0h1.4c0,0.6,0.3,1.1,0.8,1.5c0.5,0.4,1.1,0.5,1.8,0.5c0.6,0,1.3-0.1,1.6-0.5C501.9,47.1,501.9,46.9,501.9,46.4z"/>' +
    '<path class="st1" d="M508.4,48.2c0.5,0,0.9-0.1,1.1-0.5c0.3-0.4,0.5-0.6,0.5-1.1h1.3l0,0c0,0.6-0.3,1.3-0.9,1.9c-0.6,0.6-1.3,0.8-2.1,0.8c-1.1,0-2-0.4-2.6-1.1c-0.6-0.8-0.9-1.8-0.9-2.9v-0.3c0-1.1,0.3-2,0.9-2.9c0.6-0.9,1.5-1.1,2.6-1.1c0.9,0,1.6,0.3,2.1,0.9c0.5,0.6,0.9,1.3,0.9,2l0,0H510c0-0.5-0.1-0.9-0.5-1.3c-0.4-0.4-0.8-0.5-1.3-0.5c-0.8,0-1.3,0.3-1.5,0.9c-0.3,0.6-0.5,1.3-0.5,2v0.3c0,0.8,0.1,1.5,0.5,2C507.1,47.7,507.7,48.2,508.4,48.2z"/>' +
    '<path class="st1" d="M514.5,42.1c0.3-0.4,0.6-0.6,1-0.9c0.4-0.3,0.9-0.4,1.4-0.4c0.9,0,1.5,0.3,2,0.8s0.8,1.3,0.8,2.4v5h-1.5v-5c0-0.6-0.1-1.1-0.4-1.5c-0.3-0.4-0.8-0.5-1.3-0.5c-0.4,0-0.8,0.1-1.1,0.3c-0.4,0.1-0.6,0.4-0.8,0.8v6.1h-1.5V37.6h1.5v4.5H514.5z"/>' +
    '<path class="st1" d="M525,49.3c-1.1,0-2-0.4-2.6-1.1c-0.6-0.8-1-1.6-1-2.9v-0.4c0-1.1,0.4-2.1,1-2.9c0.6-0.8,1.5-1.1,2.4-1.1c1,0,1.9,0.4,2.4,1c0.5,0.6,0.8,1.5,0.8,2.6v0.8h-5.2l0,0c0,0.8,0.1,1.5,0.5,1.9c0.4,0.4,0.9,0.8,1.6,0.8c0.5,0,0.9-0.1,1.3-0.3c0.4-0.1,0.6-0.4,1-0.6l0.6,1c-0.3,0.3-0.6,0.5-1.1,0.8S525.8,49.3,525,49.3z M524.9,42.1c-0.5,0-0.9,0.3-1.3,0.6c-0.4,0.4-0.5,0.9-0.6,1.5l0,0h3.7v-0.1c0-0.6-0.1-1-0.4-1.4C526,42.4,525.4,42.1,524.9,42.1z"/>' +
    '<path class="st1" d="M533.5,42.4h-0.8c-0.4,0-0.8,0.1-1,0.3c-0.3,0.1-0.5,0.4-0.6,0.8v5.7h-1.5v-7.9h1.3l0.1,1.1c0.3-0.4,0.5-0.8,0.9-1s0.8-0.4,1.1-0.4c0.1,0,0.3,0,0.3,0s0.1,0,0.3,0L533.5,42.4z"/>' +
    '<path class="st1" d="M536.2,41.1l0.1,1c0.3-0.4,0.6-0.6,1-0.9s0.9-0.3,1.4-0.3c0.5,0,1,0.1,1.4,0.4s0.6,0.6,0.9,1.1c0.3-0.5,0.6-0.8,1-1.1c0.4-0.4,0.9-0.4,1.4-0.4c0.8,0,1.5,0.3,1.9,0.9c0.4,0.6,0.8,1.4,0.8,2.5v4.8h-1.5v-4.8c0-0.8-0.1-1.4-0.4-1.6c-0.3-0.3-0.6-0.5-1.3-0.5c-0.5,0-0.9,0.1-1.3,0.5c-0.4,0.4-0.5,0.8-0.5,1.3l0,0v5h-1.5v-4.8c0-0.8-0.1-1.3-0.4-1.6c-0.3-0.4-0.6-0.5-1.3-0.5c-0.5,0-0.8,0.1-1.1,0.3c-0.4,0.1-0.5,0.4-0.6,0.8v5.9h-1.5v-7.9H536.2z"/>' +
    '<path class="st1" d="M547.8,45c0-1.1,0.4-2.1,1-2.9c0.6-0.8,1.5-1.1,2.6-1.1c1.1,0,2,0.4,2.6,1.1c0.6,0.8,1,1.8,1,2.9v0.1c0,1.1-0.4,2.1-1,2.9c-0.6,0.8-1.5,1.1-2.6,1.1c-1.1,0-2-0.4-2.6-1.1c-0.6-0.8-1-1.8-1-2.9V45z M549.4,45.2c0,0.9,0.1,1.5,0.5,2.1c0.4,0.6,0.9,0.9,1.6,0.9c0.8,0,1.3-0.3,1.6-0.9c0.4-0.6,0.5-1.3,0.5-2.1V45c0-0.9-0.1-1.5-0.5-2.1c-0.4-0.6-0.9-0.9-1.6-0.9s-1.3,0.3-1.6,0.9c-0.4,0.6-0.5,1.3-0.5,2.1V45.2z"/>' +
    '<path class="st1" d="M568.4,45.7L568.4,45.7c0,1-0.4,1.9-1,2.5c-0.6,0.6-1.6,1-2.9,1c-1.3,0-2.3-0.4-3-1.3c-0.8-0.9-1.1-2-1.1-3.3v-1.9c0-1.4,0.4-2.4,1.1-3.3c0.8-0.9,1.8-1.3,3-1.3c1.3,0,2.1,0.4,2.9,1c0.8,0.6,1,1.5,1,2.5l0,0H567c0-0.8-0.3-1.4-0.6-1.8c-0.4-0.4-1-0.6-1.9-0.6c-0.8,0-1.5,0.4-1.9,1c-0.4,0.6-0.8,1.5-0.8,2.4v1.9c0,1,0.3,1.8,0.8,2.4c0.5,0.6,1.1,1,1.9,1c0.8,0,1.4-0.3,1.9-0.6c0.5-0.4,0.6-1,0.6-1.8H568.4z"/>' +
    '<path class="st1" d="M571.9,49.1h-1.5V38.3h1.5V49.1z"/>' +
    '<path class="st1" d="M582.8,49.1h-1.5l-5.2-8.2l0,0v8.2h-1.5V38.3h1.5l5.2,8.2l0,0v-8.2h1.5V49.1z"/>' +
    '<path class="st1" d="M591.1,44.1h-4.4v3.9h5.2v1.1h-6.6V38.3h6.6v1.1h-5V43h4.4v1.1H591.1z"/>' +
    '<path class="st1" d="M598.9,47.1L598.9,47.1l3.5-8.7h1.8v10.7h-1.5V41l0,0l-3.3,8.1h-1l-3.4-8.3l0,0v8.3h-1.5V38.3h1.9L598.9,47.1z"/>' +
    '<path class="st1" d="M612.4,46.3h-4l-1,2.8h-1.5l3.9-10.7h1.3l3.8,10.7h-1.5L612.4,46.3z M608.9,45.2h3.2l-1.5-4.7l0,0L608.9,45.2z"/>' +
    '</g>' +
    '</svg>',
  
  iconSimpleFreeSeatLevel0: "../../assets/img/minimal/10_10_1.png",
  iconSimpleFreeSeatLevel1: "../../assets/img/minimal/28_28_libero.png",
  iconSimpleFreeTeathSeatLevel1: "../../assets/img/minimal/20_20_libero.png",
  iconSimpleFreeSeatLevel2: "../../assets/img/minimal/50_50_libero.png",
  iconSimpleFreeSelectedSeatLevel0: "../../assets/img/minimal/10_10_2.png",
  iconSimpleFreeSelectedSeatLevel1: "../../assets/img/minimal/28_28_sel.png",
  iconSimpleFreeTeathSelectedSeatLevel1: "../../assets/img/minimal/20_20_sel.png",
  iconSimpleFreeSelectedSeatLevel2: "../../assets/img/minimal/50_50_sel.png",
  iconSimpleBusySeatLevel0: "../../assets/img/minimal/10_10_2.png",
  iconSimpleBusySeatLevel1: "../../assets/img/minimal/28_28_occupato.png",
  iconSimpleBusyTeathSeatLevel1: "../../assets/img/minimal/20_20_occupato.png",
  iconSimpleBusySeatLevel2: "../../assets/img/minimal/50_50_occupato.png",

  iconScreenLevel2: "../../assets/img/schermo_1_new.png",
  iconScreenLevel1: "../../assets/img/schermo_0_new.png",
  iconScreenLevel0: "../../assets/img/schermo_2_new.png",
  dimXMap: 980,
  dimYMap: 650,
  dimXMediumMap: 700,
  dimYMediumMap: 400,
  dimPricesX: 500,
  dimPricesY: 248,
  dimFontNormal: 12,
  dimFontEvidence: 16,
  dimFontBiggest: 30,
  dimFontSmall: 8,
  dimFontVerySmall: 5,
  dimPricesXContainer: 28,
  dimPricesYContainer: 225,
  ticketTypeKeyDefault: "F1",
  maxTicketsPerSession: "50",
  prefixScreen: "Sala",
  prefixReadOnly: "SOLA LETTURA",
  prefixOccupiedBy: "bloccato da",
  notSelectable: "POSTO/I NON SELEZIONABILE/I",
  tokenPrevendita: "02",
  tokenVendita: "01",
  temporaryFiscalNature: "T",
  temporaryCodOperation: "01",
  refreshRate: 5000,
  upperBoundMorning: 13,
  lowerBoundEvening: 18,
  minutesBeforeWarning: 15,
  performanceTitleLength: 16,
  sessionIdPrefixLength: 10,
  sessionIdSuffixLength: 10000,
  imagesPath: "assets/winticstellar/img",
  dtimeDayValues: [
    { value: 1, viewValue: 'UXLBL.COUNTER.LUNEDI' },
    { value: 2, viewValue: 'UXLBL.COUNTER.MARTEDI' },
    { value: 3, viewValue: 'UXLBL.COUNTER.MERCOLEDI' },
    { value: 4, viewValue: 'UXLBL.COUNTER.GIOVEDI' },
    { value: 5, viewValue: 'UXLBL.COUNTER.VENERDI' },
    { value: 6, viewValue: 'UXLBL.COUNTER.SABATO' },
    { value: 0, viewValue: 'UXLBL.COUNTER.DOMENICA' }
  ],
  showLandingDividerLine: false,
  landingDividerText: '',
  tmdbAPI: {
    hostname: 'https://api.themoviedb.org',
    imagesUrl: 'https://image.tmdb.org/t/p/original',
    version: '3',
    apiKey: 'cd47c6768e40e65abc75a4dc09781add',
    language: 'it',
    includeAdult: false,
    logoUrl: 'https://www.themoviedb.org/assets/1/v4/logos/primary-green-d70eebe18a5eb5b166d5c1ef0796715b8d1a2cbc698f96d311d62f894ae87085.svg',
    maxCastPersons: 5
  },
  defaultLocale: 'it',
  locales: [
    { value: 'it', viewValue: 'Italiano', img: '/assets/common/img/ITA.png' }
  ],
  versTile: "v2",
  tiles: [
    {
      img: "icn_ordini.png",
      value: "ordini",
      double: false,
      enabled: true,
      action: "pm/store/action/orders"
    },
    {
      img: "icn_entrate.png",
      value: "entrate",
      double: false,
      enabled: true,
      action: "pm/store/action/entry-docs"
    },
    {
      img: "icn_uscite.png",
      value: "uscite",
      double: false,
      enabled: true,
      action: "pm/store/action/exit-docs-entry"
    },
    {
      img: "icn_inventario.png",
      value: "inventario",
      double: true,
      enabled: false,
      action: "pm/store/action/inventory"
    },
    {
      img: "icn_avvisi.png",
      value: "avvisi",
      double: false,
      enabled: true,
      action: "pm/store/action/alerts",
      gif: "icn_avvisi.gif"
    },
    {
      img: "icn_report.png",
      value: "report",
      double: false,
      enabled: true,
      action: "pm/store/action/reports"
    }
  ],
  tilesSuppliers: [
    {
      img: "icn_ordini_ricevuti.png",
      value: "ordini ricevuti",
      double: false,
      enabled: true,
      action: "pm/suppliers/action/orders"
    },
    {
      img: "icn_ordini_fatturabili.png",
      value: "ordini fatturabili",
      double: false,
      enabled: true,
      action: "pm/suppliers/action/invoiceable"
    },
    {
      img: "icn_fatture.png",
      value: "fatture",
      double: false,
      enabled: true,
      action: "pm/suppliers/action/invoices"
    }
  ],
  images: [
    {
      caption: "Crea un nuovo ordine",
      src: "aggiungi",
      enabled: false,
      action: "add",
      visibile: true
    },
    {
      caption: "Modifica la selezione",
      src: "modifica",
      enabled: false,
      action: "edit",
      visibile: true
    },
    {
      caption: "Elimina la selezione",
      src: "cancella",
      enabled: false,
      action: "delete",
      visibile: true
    },
    {
      caption: "Salva come bozza",
      src: "bozza",
      enabled: false,
      action: "draft",
      visibile: true
    },
    {
      caption: "Salva e conferma",
      src: "definitivo_white",
      enabled: false,
      action: "confirm",
      visibile: true
    },
    {
      caption: "Stampa",
      src: "stampa",
      enabled: false,
      action: "print",
      visibile: true
    },
    {
      caption: "Duplica l'ordine",
      src: "duplica",
      enabled: false,
      action: "duplicate",
      visibile: true
    },
    {
      caption: "Crea un ordine sotto scorta",
      src: "aggiungi_",
      enabled: false,
      action: "sottoscorta",
      visibile: true
    }
  ],
  imagesS: [
    [
      {
        caption: "Visualizza",
        src: "lente_box",
        enabled: true,
        action: "view",
        visibile: true
      },
      {
        caption: "Stampa",
        src: "stampa",
        enabled: true,
        action: "print",
        visibile: true
      },
      {
        caption: "Evadi (genera DDT)",
        src: "invia",
        enabled: true,
        action: "go",
        visibile: true
      },
      {
        caption: "Respingi",
        src: "delete",
        enabled: true,
        action: "ko",
        visibile: true
      }
    ],
    [
      {
        caption: "Visualizza",
        src: "lente_box",
        enabled: true,
        action: "view",
        visibile: true
      },
      {
        caption: "Stampa",
        src: "stampa",
        enabled: true,
        action: "print",
        visibile: true
      },
      {
        caption: "Genera Prefattura",
        src: "tab_nome",
        enabled: true,
        action: "inv",
        visibile: true
      },
      {
        caption: "Elimina Fattura",
        src: "delete",
        enabled: true,
        action: "reminv",
        visibile: true
      }
    ],
    [
      {
        caption: "Stampa",
        src: "stampa",
        enabled: true,
        action: "print",
        visibile: true
      }
    ]
  ],
  imagesMM: [
    {
      caption: "Crea un nuovo ordine",
      src: "aggiungi",
      enabled: false,
      action: "add",
      visibile: true
    },
    {
      caption: "Modifica la selezione",
      src: "modifica",
      enabled: false,
      action: "edit",
      visibile: true
    },
    {
      caption: "Elimina la selezione",
      src: "cancella",
      enabled: false,
      action: "delete",
      visibile: true
    },
    {
      caption: "Salva come bozza",
      src: "bozza",
      enabled: false,
      action: "draft",
      visibile: true
    },
    {
      caption: "Salva e conferma",
      src: "definitivo_white",
      enabled: false,
      action: "confirm",
      visibile: true
    },
    {
      caption: "Stampa",
      src: "stampa",
      enabled: false,
      action: "print",
      visibile: true
    },
    {
      caption: "Duplica l'ordine",
      src: "duplica",
      enabled: false,
      action: "duplicate",
      visibile: true
    },
    {
      caption: "Crea un ordine sotto scorta",
      src: "aggiungi_",
      enabled: false,
      action: "sottoscorta",
      visibile: true
    }
  ],
  notificationOptions: { timeOut: 3000, showProgressBar: true, pauseOnHover: true, clickToClose: true, animate: 'fromRight' },

  oAuthClientId: "DentalPro",
  oAuthScope: "openid profile roles https://identity.dentalpro.it/resources",
  oAuthIssuer: "https://identity.dentalpro.it",
  //oAuthIssuer: "https://identityv2.dentalpro.it",
  //oAuthIssuer: "https://identity.dentalpro.it",
  idNegozio: 650,
  GetInfoWS: webApi + "/api/getInfoNegozio",
  getListaOrdiniFornitore: webApi + "/api/backoffice/getListaOrdiniFornitori",
  getListaOrdiniDaFatturare: webApi + "/api/backoffice/getListaOrdiniFornitoriDaFatturare",
  getListaOrdiniFatturati: webApi + "/api/backoffice/getListaOrdiniFornitoriFatturati",
  eliminaFatturaFornitori: webApi + "/api/backoffice/documents/eliminaFatturaFornitori" ,
  emettiFattureFornitori: webApi + "/api/backoffice/documents/emettiFattureNoteDiCreditoFornitori",
  emettiFatturaElettronica: webApi + "/api/backoffice/documents/emettiFatturaElettronica",
  eliminaNotaDiCreditoFornitori: webApi + "/api/backoffice/documents/eliminaNotaDiCreditoFornitori" ,
  GetCategorieFornitori: webApi + "/api/backoffice/getCategorieFornitori",
  GetBudgetResiduoClinica:  webApi + "/api/backoffice/residui/getBudgetResiduoClinica",
  GetElencoFornitori: webApi + "/api/backoffice/getElencoFornitori",
  GetArticoloMasterSlave: webApi + "/api/backoffice/getArticoloMasterSlave",
  GetDocumentiMasterSlave: webApi + "/api/backoffice/getDocumentiMasterSlave",
  //GetElencoCompletoFornitori: webApi + "/api/backoffice/getElencoCompletoFornitori",
  GetArticoliFornitori: webApi + "/api/backoffice/getArticoliFornitore",
  GetArticoliFornitoriOrdineCross: webApi + "/api/backoffice/getArticoliFornitori",
  GetArticoliFornitoriBollaUscita: webApi + "/api/backoffice/getArticoliFiliale",
  GetElencoOrdini: webApi + "/api/backoffice/getListaOrdini",
  GetDettaglioOrdine: webApi + "/api/backoffice/getDettaglioOrdine",
  GetDettaglioBolleEntrata: webApi + "/api/backoffice/getDettaglioBollaEntrata",
  GetDettaglioBolleUscita: webApi + "/api/backoffice/getDettaglioBollaUscita",
  GetElencoBolleEntrata: webApi + "/api/backoffice/getListaBolleEntrata",
  GetElencoBolleUscita: webApi + "/api/backoffice/getListaBolleUscita",
  ForecastInit: "https://forecast.dentalpro.it/initMonth",
  GetForecast: "https://forecast.dentalpro.it/getForecasts",
  ForceForecast: "https://forecast.dentalpro.it/updateMonth",
  UpdateForecast: "https://forecast.dentalpro.it/updateForecasts",
  //SearchCarotaggio: "https://forecast.dentalpro.it/search",
  SearchCarotaggio: "https://api-staff.dentalpro.it/api/Pazientis/search",
  GetMailAffittuariMese:"https://forecast.dentalpro.it/api/affittuari_mail_mensile",
  PostMailAffittuariMese:"https://forecast.dentalpro.it/api/affittuari_mail_mensili_filtered",
  SaveOrder: webApi + "/api/backoffice/documents/emettiOrdine",
  SaveOrderCross: webApi + "/api/backoffice/documents/emettiOrdineCross",
  DeleteOrder: webApi + "/api/backoffice/documents/eliminaOrdine",
  GetHandlerPDF: webApi + "/Handlers/handlerDocuments.ashx?idnegozio={0}&iddocumento={1}&idadm={2}",
  GetHandlerFatture: webApi + "/Handlers/handlerFatture.ashx?idnegozio={0}&iddocumento={1}&idadm={2}",
  EditOrder: webApi + "/api/backoffice/documents/modificaOrdine",
  GetElencoInventari: webApi + "/api/backoffice/inventory/getListaInventari",
  GetInventarioItems: webApi + "/api/backoffice/inventory/getInventoryItems",
  GetElencoFiliali: webApi + "/api/backoffice/getElencoFiliali",
  EmettiBolla: webApi + "/api/backoffice/documents/emettiBollaEntrata",
  EmettiBollaUscita: webApi + "/api/backoffice/documents/emettiBollaUscita",
  EmettiBollaUscitaTotOrdine: webApi + "/api/backoffice/documents/emettiBollaUscitaDaBollaEntrata",
  GetArticoliInventario: webApi + "/api/backoffice/inventory/getArticoliInventario",
  ConfermaOrdineFornitore: webApi + "/api/backoffice/documents/confermaOrdineFornitore",
  GetHandlerInventario: webApi + "/Handlers/handlerInventoryXLS.ashx?idnegozio={0}&iddocumento={1}&idadm={2}",
  //SaveInventarioProvvisorio: webApi + "/api/backoffice/inventory/registraInventarioProvvisorio",
  SaveInventarioProvvisorio: webApi + "/api/backoffice/inventory/registraInventarioContati",
  SaveInventarioDefinitivo: webApi + "/api/backoffice/inventory/registraInventarioDefinitivo",
  //DeleteInventarioProvvisorio: webApi + "/api/backoffice/inventory/eliminaInventarioProvvisorio",
  DeleteInventarioProvvisorio: webApi + "/api/backoffice/inventory/eliminaInventarioContati",
  GetDettaglioInventario: webApi + "/api/backoffice/inventory/getInventario",
  GetElencoAlerts: webApi + "/api/backoffice/utility/alerts/checkAlerts",
  GetBottoniBolleUscita: webApi + "/api/backoffice/getTipologieBolleUscita",
  GetReports: webApi + "/api/backoffice/reports/getReportsMenu",
  GetReportParams: webApi + "/api/backoffice/reports/getReportParameters",
  GetReportsData: webApi + "/api/backoffice/reports/getReportData",
  GetReportsDataHandler: webApi + "/Handlers/reports/{0}/handlerReports.ashx",
  ResendEmailOrdine: webApi + "/api/backoffice/utility/mail/sendMailOrder",
  SaveOrdineCross: webApi + "/api/backoffice/documents/emettiOrdineCross",
  GetArticoliOrdineCross: webApi + "/api/backoffice/getArticoliFornitori",
  GetListaMinimoOrdineFornitori: webApi + "/api/backoffice/minimi/getListaOrdiniMinimiFornitori",
  SaveMinimoOrdineFornitore: webApi + "/api/backoffice/minimi/setMinimoOrdineFornitore",
  getListaFatture:"https://api-staff.dentalpro.it/api/Fattures",
  getListaProduzioni:"https://api-staff.dentalpro.it/api/PreventiviPrestazioniEsecuzionis",
  MyContext: "DENTALPRO",
  tableMessages: {
    emptyMessage: 'Nessun dato da visualizzare',
    totalMessage: 'righe totali'
  }, GetAllMessages: "https://forecast.dentalpro.it/getAll",
  GetMessage: "https://forecast.dentalpro.it/getMessage/{0}",
  GetOperators: "https://forecast.dentalpro.it/getoperators",
  GetAllMessagesPerClinic: "https://forecast.dentalpro.it/getMessages/{0}/{1}",
  PostMessage: "https://forecast.dentalpro.it/postMessage",
  UpdateMessage: "https://forecast.dentalpro.it/updateMessage",
  DeleteMessage: "https://forecast.dentalpro.it/deleteMessage/",
  GetAllDomains: "https://identity.dentalpro.it/api/winticplusdomain/getall",
  GetAllProfiles: "https://identity.dentalpro.it/api/winticplusprofile/getall",
  //GetAllDomains: "https://identity.dentalpro.it/api/winticplusdomain/getall",
  //GetAllProfiles: "https://identity.dentalpro.it/api/winticplusprofile/getall",
  GetDetailUser: "https://identity.dentalpro.it/api/winticplusaccount/get/",
  GetDetailPatient: "https://ssopazienti.dentalpro.it/api/winticplusaccount/get/",
  PostMedic:"https://identity.dentalpro.it/api/winticplusaccount/doctor",
  ActiveMessage: "https://forecast.dentalpro.it/activeMessage/",
  DisActiveMessage: "https://forecast.dentalpro.it/disactiveMessage/",
  DefaultTime: { value: 2, viewvalue: '2 mesi' },
  TimeValues: [{ value: 2, viewvalue: '2 mesi' }, { value: 4, viewvalue: '4 mesi' }, { value: 6, viewvalue: '6 mesi' }, { value: 12, viewvalue: 'un anno' }, { value: 0, viewvalue: 'tutti' }],

  GetNodiTastiera: webApi + "/api/getArticoliNegozio",
  ImgTastiera: webApi + "/Handlers/handlerNodiTastiera.ashx",
  ImgArticolo: webApi + "/Handlers/handlerArticoli.ashx",
  ArticoliNegozio: webApi + "/api/getArticoliNegozio",
  AddArticoloToCheckOut: webApi + "/api/addArticoloToCheckOut",
  RemoveArticoloToCheckOut: webApi + "/api/removeArticoloToCheckOut",
  GetPaymentMethods: webApi + "/api/getPaymentsMode",
  PayAndGenerateTicket: webApi + "/api/EmettiScontrinoNegozio",
  SearchProduct: webApi + '/api/search/searchArticoli',
  GetProductDetail: webApi + '/api/getArticoloTastiera',
  GetPosConfiguration: webApi + '/api/orders/getPosConfiguration',
  GetTastieraVarianti: webApi + '/api/articoli/getTastieraVarianti',

  ConcessionSettings:
  {
    "idnegozio": 250,
    "web_box": "WEB_POS_01",
    "web_operator": "utente pos",
    "sessionId": "GLS_SESSION_ID_125",
    "trackid": 100,
    "postazione": {
      "IdPostazione": 82,
      stringID: "WEB_POS_01"
    }
  },
  concessionIcons: [
    { src: "arrow_up", name: "ecr.ecr-home.1200", disabled: true, visible: true, action: "resetKeyboard" },
    { src: "storni_resi", name: "ecr.ecr-home.1201", disabled: true, visible: true, action: "blank" },
    { src: "annulla", name: "ecr.ecr-home.1202", disabled: true, visible: false, action: "openPopupConfirm" },
    { src: "pausa", name: "ecr.ecr-home.1203", disabled: true, visible: true, action: "callPause" },
    { src: "servizi", name: "ecr.ecr-home.1204", disabled: true, visible: true, action: "blank" },
    { src: "icn_settings", name: "ecr.ecr-home.1227", disabled: true, visible: true, action: "openSettingsPopup" },
    { src: "cassetto", name: "ecr.ecr-home.1205", disabled: false, visible: true, action: "blank" },
    { src: "iconizza", name: "ecr.ecr-home.1206", disabled: true, visible: false, action: "blank" },
    { src: "cerca", name: "ecr.ecr-home.1207", disabled: false, visible: true, action: "openSearch" },
    { src: "carte", name: "ecr.ecr-home.1208", disabled: true, visible: true, action: "blank" },
    { src: "customize", name: "ecr.ecr-home.1209", disabled: true, visible: true, action: "openPopupLayout" },
    { src: "esci", name: "ecr.ecr-home.1210", disabled: false, visible: false, action: "test" },
    { src: "box_office", name: "BOX OFFICE", disabled: false, visible: true, action: "goToBoxOffice" },
  ],
  keyboardCalc: [
    [
      { disabled: false, caption: "C", isDouble: false, enabledOnlyWithItemSelected: true },
      { disabled: false, caption: "←", isDouble: false, enabledOnlyWithItemSelected: true  },
      { disabled: true, caption: "AC", isDouble: false, enabledOnlyWithItemSelected: false  },
      { disabled: true, caption: "/", isDouble: false, enabledOnlyWithItemSelected: false  }
    ],
    [
      { disabled: false, caption: "7", isDouble: false, enabledOnlyWithItemSelected: false  },
      { disabled: false, caption: "8", isDouble: false, enabledOnlyWithItemSelected: false  },
      { disabled: false, caption: "9", isDouble: false, enabledOnlyWithItemSelected: false  },
      { disabled: true, caption: "X", isDouble: false, enabledOnlyWithItemSelected: false  }
    ],
    [
      { disabled: false, caption: "4", isDouble: false, enabledOnlyWithItemSelected: false  },
      { disabled: false, caption: "5", isDouble: false, enabledOnlyWithItemSelected: false  },
      { disabled: false, caption: "6", isDouble: false, enabledOnlyWithItemSelected: false  },
      { disabled: false, caption: "–", isDouble: false, enabledOnlyWithItemSelected: true  }
    ],
    [
      { disabled: false, caption: "1", isDouble: false, enabledOnlyWithItemSelected: false  },
      { disabled: false, caption: "2", isDouble: false, enabledOnlyWithItemSelected: false  },
      { disabled: false, caption: "3", isDouble: false, enabledOnlyWithItemSelected: false  },
      { disabled: false, caption: "+", isDouble: false, enabledOnlyWithItemSelected: true  }
    ],
    [
      { disabled: false, caption: "0", isDouble: false, enabledOnlyWithItemSelected: false  },
      { disabled: true, caption: ".", isDouble: false, enabledOnlyWithItemSelected: false  },
      { disabled: false, caption: "=", isDouble: true, enabledOnlyWithItemSelected: true  }
    ]
  ],
  SEARCH_PRODUCT_PAGE_SIZE: 10,
  

  filmSearch: {
    lastNDays: 30
  },
  
  calendar: {
    toggleEvents: false
  },
  gceAPI: 'https://forecast.dentalpro.it/api',
 //gceAPI: 'http://localhost:57526/api',
  provs:[
    {cod_prov:"AG",	descrizione:"Agrigento",	regione:"Sicilia"},																
{cod_prov:"AL",	descrizione:"Alessandria",	regione:"Piemonte"},
{cod_prov:"AN",	descrizione:"Ancona",	regione:"Marche"},
{cod_prov:"AO",	descrizione:"Aosta",	regione:"Valle d'Aosta"},
{cod_prov:"AQ",	descrizione:"L'Aquila",	regione:"Abruzzo"},
{cod_prov:"AR",	descrizione:"Arezzo",	regione:"Toscana"},
{cod_prov:"AP",	descrizione:"Ascoli-Piceno",	regione:"Marche"},
{cod_prov:"AT",	descrizione:"Asti",	regione:"Piemonte"},
{cod_prov:"AV",	descrizione:"Avellino",	regione:"Campania"},
{cod_prov:"BA",	descrizione:"Bari",	regione:"Puglia"},
{cod_prov:"BT",	descrizione:"Barletta-Andria-Trani",	regione:"Puglia"},
{cod_prov:"BL",	descrizione:"Belluno",	regione:"Veneto"},
{cod_prov:"BN",	descrizione:"Benevento",	regione:"Campania"},
{cod_prov:"BG",	descrizione:"Bergamo",	regione:"Lombardia"},
{cod_prov:"BI",	descrizione:"Biella",	regione:"Piemonte"},
{cod_prov:"BO",	descrizione:"Bologna",	regione:"Emilia Romagna"},
{cod_prov:"BZ",	descrizione:"Bolzano",	regione:"Trentino Alto Adige"},
{cod_prov:"BS",	descrizione:"Brescia",	regione:"Lombardia"},
{cod_prov:"BR",	descrizione:"Brindisi",	regione:"Puglia"},
{cod_prov:"CA",	descrizione:"Cagliari",	regione:"Sardegna"},
{cod_prov:"CL",	descrizione:"Caltanissetta",	regione:"Sicilia"},
{cod_prov:"CB",	descrizione:"Campobasso",	regione:"Molise"},
{cod_prov:"CI",	descrizione:"Carbonia Iglesias",	regione:"Sardegna"},
{cod_prov:"CE",	descrizione:"Caserta",	regione:"Campania"},
{cod_prov:"CT",	descrizione:"Catania",	regione:"Sicilia"},
{cod_prov:"CZ",	descrizione:"Catanzaro",	regione:"Calabria"},
{cod_prov:"CH",	descrizione:"Chieti",	regione:"Abruzzo"},
{cod_prov:"CO",	descrizione:"Como",	regione:"Lombardia"},
{cod_prov:"CS",	descrizione:"Cosenza",	regione:"Calabria"},
{cod_prov:"CR",	descrizione:"Cremona",	regione:"Lombardia"},
{cod_prov:"KR",	descrizione:"Crotone",	regione:"Calabria"},
{cod_prov:"CN",	descrizione:"Cuneo",	regione:"Piemonte"},
{cod_prov:"EN",	descrizione:"Enna",	regione:"Sicilia"},
{cod_prov:"FM",	descrizione:"Fermo",	regione:"Marche"},
{cod_prov:"FE",	descrizione:"Ferrara",	regione:"Emilia Romagna"},
{cod_prov:"FI",	descrizione:"Firenze",	regione:"Toscana"},
{cod_prov:"FG",	descrizione:"Foggia",	regione:"Puglia"},
{cod_prov:"FC",	descrizione:"Forli-Cesena",	regione:"Emilia Romagna"},
{cod_prov:"FR",	descrizione:"Frosinone",	regione:"Lazio"},
{cod_prov:"GE",	descrizione:"Genova",	regione:"Liguria"},
{cod_prov:"GO",	descrizione:"Gorizia",	regione:"Friuli Venezia Giulia"},
{cod_prov:"GR",	descrizione:"Grosseto",	regione:"Toscana"},
{cod_prov:"IM",	descrizione:"Imperia",	regione:"Liguria"},
{cod_prov:"IS",	descrizione:"Isernia",	regione:"Molise"},
{cod_prov:"SP",	descrizione:"La-Spezia",	regione:"Liguria"},
{cod_prov:"LT",	descrizione:"Latina",	regione:"Lazio"},
{cod_prov:"LE",	descrizione:"Lecce",	regione:"Puglia"},
{cod_prov:"LC",	descrizione:"Lecco",	regione:"Lombardia"},
{cod_prov:"LI",	descrizione:"Livorno",	regione:"Toscana"},
{cod_prov:"LO",	descrizione:"Lodi",	regione:"Lombardia"},
{cod_prov:"LU",	descrizione:"Lucca",	regione:"Toscana"},
{cod_prov:"MC",	descrizione:"Macerata",	regione:"Marche"},
{cod_prov:"MN",	descrizione:"Mantova",	regione:"Lombardia"},
{cod_prov:"MS",	descrizione:"Massa-Carrara",	regione:"Toscana"},
{cod_prov:"MT",	descrizione:"Matera",	regione:"Basilicata"},
{cod_prov:"VS",	descrizione:"Medio Campidano",	regione:"Sardegna"},
{cod_prov:"ME",	descrizione:"Messina",	regione:"Sicilia"},
{cod_prov:"MI",	descrizione:"Milano",	regione:"Lombardia"},
{cod_prov:"MO",	descrizione:"Modena",	regione:"Emilia Romagna"},
{cod_prov:"MB",	descrizione:"Monza Brianza",	regione:"Lombardia"},
{cod_prov:"NA",	descrizione:"Napoli",	regione:"Campania"},
{cod_prov:"NO",	descrizione:"Novara",	regione:"Piemonte"},
{cod_prov:"NU",	descrizione:"Nuoro",	regione:"Sardegna"},
{cod_prov:"OG",	descrizione:"Ogliastra",	regione:"Sardegna"},
{cod_prov:"OT",	descrizione:"Olbia Tempio",	regione:"Sardegna"},
{cod_prov:"OR",	descrizione:"Oristano",	regione:"Sardegna"},
{cod_prov:"PD",	descrizione:"Padova",	regione:"Veneto"},
{cod_prov:"PA",	descrizione:"Palermo",	regione:"Sicilia"},
{cod_prov:"PR",	descrizione:"Parma",	regione:"Emilia Romagna"},
{cod_prov:"PV",	descrizione:"Pavia",	regione:"Lombardia"},
{cod_prov:"PG",	descrizione:"Perugia",	regione:"Umbria"},
{cod_prov:"PU",	descrizione:"Pesaro-Urbino",	regione:"Marche"},
{cod_prov:"PE",	descrizione:"Pescara",	regione:"Abruzzo"},
{cod_prov:"PC",	descrizione:"Piacenza",	regione:"Emilia Romagna"},
{cod_prov:"PI",	descrizione:"Pisa",	regione:"Toscana"},
{cod_prov:"PT",	descrizione:"Pistoia",	regione:"Toscana"},
{cod_prov:"PN",	descrizione:"Pordenone",	regione:"Friuli Venezia Giulia"},
{cod_prov:"PZ",	descrizione:"Potenza",	regione:"Basilicata"},
{cod_prov:"PO",	descrizione:"Prato",	regione:"Toscana"},
{cod_prov:"RG",	descrizione:"Ragusa",	regione:"Sicilia"},
{cod_prov:"RA",	descrizione:"Ravenna",	regione:"Emilia Romagna"},
{cod_prov:"RC",	descrizione:"Reggio-Calabria",	regione:"Calabria"},
{cod_prov:"RE",	descrizione:"Reggio-Emilia",	regione:"Emilia Romagna"},
{cod_prov:"RI",	descrizione:"Rieti",	regione:"Lazio"},
{cod_prov:"RN",	descrizione:"Rimini",	regione:"Emilia Romagna"},
{cod_prov:"RM",	descrizione:"Roma",	regione:"Lazio"},
{cod_prov:"RO",	descrizione:"Rovigo",	regione:"Veneto"},
{cod_prov:"SA",	descrizione:"Salerno",	regione:"Campania"},
{cod_prov:"SM", descrizione:"San Marino", regione: "San Marino" },
{cod_prov:"SS",	descrizione:"Sassari",	regione:"Sardegna"},
{cod_prov:"SV",	descrizione:"Savona",	regione:"Liguria"},
{cod_prov:"SI",	descrizione:"Siena",	regione:"Toscana"},
{cod_prov:"SR",	descrizione:"Siracusa",	regione:"Sicilia"},
{cod_prov:"SO",	descrizione:"Sondrio",	regione:"Lombardia"},
{cod_prov:"TA",	descrizione:"Taranto",	regione:"Puglia"},
{cod_prov:"TE",	descrizione:"Teramo",	regione:"Abruzzo"},
{cod_prov:"TR",	descrizione:"Terni",	regione:"Umbria"},
{cod_prov:"TO",	descrizione:"Torino",	regione:"Piemonte"},
{cod_prov:"TP",	descrizione:"Trapani",	regione:"Sicilia"},
{cod_prov:"TN",	descrizione:"Trento",	regione:"Trentino Alto Adige"},
{cod_prov:"TV",	descrizione:"Treviso",	regione:"Veneto"},
{cod_prov:"TS",	descrizione:"Trieste",	regione:"Friuli Venezia Giulia"},
{cod_prov:"UD",	descrizione:"Udine",	regione:"Friuli Venezia Giulia"},
{cod_prov:"VA",	descrizione:"Varese",	regione:"Lombardia"},
{cod_prov:"VE",	descrizione:"Venezia",	regione:"Veneto"},
{cod_prov:"VB",	descrizione:"Verbania",	regione:"Piemonte"},
{cod_prov:"VC",	descrizione:"Vercelli",	regione:"Piemonte"},
{cod_prov:"VR",	descrizione:"Verona",	regione:"Veneto"},
{cod_prov:"VV",	descrizione:"Vibo-Valentia",	regione:"Calabria"},
{cod_prov:"VI",	descrizione:"Vicenza",	regione:"Veneto"},
{cod_prov:"VT",	descrizione:"Viterbo",	regione:"Lazio"}    
  ],
  baseApiForecast:"https://forecast.dentalpro.it/"
};
*/



export const webApi = "https://webapidentalpro.dentalpro.it/webapi";
const winticApiUrl = "https://webapi.winticstellar.com/webapi";
const identityUrl = "https://identity.dentalpro.it";

export const environment = {
  version: "1.4.23",
  production: true,
  useTaxCredit: false,
  isLicenseForEvaluationOnly: true,
  env: 'dev',
  webApi: webApi,
  winticApiUrl: undefined,
  identityUrl: "https://identity.dentalpro.it",
  apiWinTic: `${winticApiUrl}/api/WPBoxOfficeServices/`,
  apiWinTicDomain: undefined,
  apiAnagWinTic: `${winticApiUrl}/api/WPGeneralAnagrafic/`,
  apiCards: `${winticApiUrl}/api/WPCard/`,
  apiCommon: `${winticApiUrl}/api/WPCommon/`,
  imgHandler: `${winticApiUrl}/Handlers/handlerPoster.ashx`,
  imgHandlerByPosterId: `${winticApiUrl}/Handlers/handlerPosters.ashx`,
  anagraficEndpoint: `${winticApiUrl}/api/WPGeneralAnagrafic/`,
  priceListEndpoint: `${winticApiUrl}/api/WPAdminManagePrices/`,
  filmsEndpoint: `${winticApiUrl}/api/WPAdminManageProducts/`,
  apiWinticSchedulator: `${winticApiUrl}/api/WinticPlusCalendarSchedulator/`,
  apiTaxtCredit: undefined,
  apiRLibEndpoint: undefined,
  handlersEndpoint: undefined,
  oauthServiceIssuer: "https://identity.dentalpro.it",
  identityServerEndpoint: "https://identity.dentalpro.it/api/",
  identityServerPatientEndpoint:"https://ssopazienti.dentalpro.it/api/",
  apiGeo: "https://identity.dentalpro.it/api/winticplusaccount/",

  getPerformances: "getPerformances",
  //getShows:"getCalendar",
  getShows: "getActivePackages",
  getPrices: "getPrices",
  getOccupancy: "getOccupancy",
  lockSeats: "LockSeats",
  lockCards: "wpLockCards",
  getLockedSeats: "getLockedSeats",
  unLockSeats: "UnlockSeats",
  unLockCards: "wpUnLockCards",
  getBestSeats: "GetBestSeat",
  getBasketValues: "GetBasketValues",
  getOperationValues: "wpGetInfoTable",
  getPriceCategories: "getPricesGroups",
  insertBasket: "SellBasket",

  // Calendar API
  getProductDetails: 'getProductsDetails',
  getSchedule: 'GetCalBySpecificDate',
  getScheduleBetweenDate: 'GetCalBetweenDate',
  getScreens: 'getScreens',
  getListPrice: 'getPrices',
  getSlotPrices: 'GetTimeSlotsPrices',
  getListOrganizer: 'GetListOrganizers',
  getListEventType: 'GetListEventType',
  getSalesChannel: 'wpGetInfoTable',
  getActiveListPrice: 'GetActiveListPrices',
  setSchedule: 'setCalendarSchedule',

  // cards api
  getDots: 'wpGetCardSet',
  getLots: 'wpGetFreeCardsFromSet',
  getCards: 'wpGetFreeCards',
  getPersons: 'wpGetAngData',
  // TODO: in locale decommentare gli endpoint commentati (apigeo, identityServerpoint e oauthService issue)
  clientId: "DentalPro",
  companyName: "",
  companyWebPage: "www.evolutiontic.it",

  //locale
  
  //winticstellar

  deviceManagerServer: "ws://localhost:12017/devicemanager",
  getMachineName: "getMachineName",
  updatePrinterStatus: "printersStatus",
  updatePosStatus: "PointsOfSaleStatus",
  printDocument: "Print",
  getCenterMap: "getcentermap",
  getGeometryMap: "geteventmap",
  zoomLevel0: 0.0008,
  zoomLevel0_1: 0.00126,
  zoomLevel0_2: 0.0026,
  zoomLevel05: 0.006,
  zoomLevel1: 0.009,
  zoomLevel2: 0.035,
  zoomLevel3: 1,
  nMaxResultSearch: 10,

  svgPreIconSimple: '<svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconSimple: ' viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve">' +
    '<polygon style="fill:#3C3C3B;" points="128.7,196.7 158.7,134 229.3,84 287,75.3 330.7,79.3 378.7,104.7 414.7,136 439.9,203.3 460.7,206.7 489.3,222 496,454.1 78.7,458 73.3,229.3 102,206.7 "/>' +
    '<g> <g> <path style="fill:#FFFFFF;" d="M503.7,462.3H65.7V250.5c0-32.1,25.4-58.3,56.6-58.3c31.2,0,56.6,26.2,56.6,58.3v99.1h211.5v-99.1c0-32.1,25.4-58.3,56.6-58.3c31.2,0,56.6,26.2,56.6,58.3V462.3z M84,444h401.4V250.5c0-22.1-17.2-40.1-38.4-40.1c-21.2,0-38.4,18-38.4,40.1v117.3h-248V250.5c0-22.1-17.2-40.1-38.4-40.1c-21.1,0-38.4,18-38.4,40.1V444z"/>' +
    '<path style="fill:#FFFFFF;" d="M447.1,206.1h-18.2c0-66.3-61.6-120.3-137.3-120.3H278c-75.7,0-137.4,54-137.4,120.3h-18.2c0-76.4,69.8-138.5,155.6-138.5h13.5C377.3,67.6,447.1,129.8,447.1,206.1z"/><g> </g> </g> </g> </svg>',

  svgPreIconOcc: '<svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconOcc: ' viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve">' +
    '<polygon style="fill:#E83859;" points="128.7,196.7 158.7,134 229.3,84 287,75.3 330.7,79.3 378.7,104.7 414.7,136 439.9,203.3 460.7,206.7 489.3,222 496,454.1 78.7,458 73.3,229.3 102,206.7 "/>' +
    '<g> <g> <path style="fill:#FFFFFF;" d="M503.7,462.3H65.7V250.5c0-32.1,25.4-58.3,56.6-58.3c31.2,0,56.6,26.2,56.6,58.3v99.1h211.5v-99.1c0-32.1,25.4-58.3,56.6-58.3c31.2,0,56.6,26.2,56.6,58.3V462.3z M84,444h401.4V250.5c0-22.1-17.2-40.1-38.4-40.1c-21.2,0-38.4,18-38.4,40.1v117.3h-248V250.5c0-22.1-17.2-40.1-38.4-40.1c-21.1,0-38.4,18-38.4,40.1V444z"/>' +
    '<path style="fill:#FFFFFF;" d="M447.1,206.1h-18.2c0-66.3-61.6-120.3-137.3-120.3H278c-75.7,0-137.4,54-137.4,120.3h-18.2c0-76.4,69.8-138.5,155.6-138.5h13.5C377.3,67.6,447.1,129.8,447.1,206.1z"/><g> </g> </g> </g> </svg>',

  svgPreIconSel: '<svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconSel: ' viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve">' +
    '<polygon style="fill:#EB995E;" points="128.7,196.7 158.7,134 229.3,84 287,75.3 330.7,79.3 378.7,104.7 414.7,136 439.9,203.3 460.7,206.7 489.3,222 496,454.1 78.7,458 73.3,229.3 102,206.7 "/>' +
    '<g> <g> <path style="fill:#FFFFFF;" d="M503.7,462.3H65.7V250.5c0-32.1,25.4-58.3,56.6-58.3c31.2,0,56.6,26.2,56.6,58.3v99.1h211.5v-99.1c0-32.1,25.4-58.3,56.6-58.3c31.2,0,56.6,26.2,56.6,58.3V462.3z M84,444h401.4V250.5c0-22.1-17.2-40.1-38.4-40.1c-21.2,0-38.4,18-38.4,40.1v117.3h-248V250.5c0-22.1-17.2-40.1-38.4-40.1c-21.1,0-38.4,18-38.4,40.1V444z"/>' +
    '<path style="fill:#FFFFFF;" d="M447.1,206.1h-18.2c0-66.3-61.6-120.3-137.3-120.3H278c-75.7,0-137.4,54-137.4,120.3h-18.2c0-76.4,69.8-138.5,155.6-138.5h13.5C377.3,67.6,447.1,129.8,447.1,206.1z"/><g> </g> </g> </g> </svg>',

  svgPreIconRes: '<?xml version="1.0" encoding="utf-8"?> <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconRes: ' viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve"><style type="text/css"> .st0{fill:#3C3C3B;}	.st1{fill:#F2C35A;}	.st2{fill:#F2C35A;stroke:#F2C35A;stroke-width:7.8669;stroke-miterlimit:10;}</style><polygon class="st0" points="122.2,194.9 152.7,130.5 224.3,79.2 282.9,70.3 327.2,74.4 376,100.4 412.5,132.6 438.1,201.6 459.2,205.1 488.2,220.8 495,459 71.5,463 66,228.3 95.1,205.1 "/><g><g><g><path class="st1" d="M498.3,463.3H61.1V251.9c0-32.1,25.4-58.2,56.5-58.2c31.2,0,56.5,26.1,56.5,58.2v98.9h211.1v-98.9c0-32.1,25.4-58.2,56.5-58.2c31.2,0,56.5,26.1,56.5,58.2L498.3,463.3L498.3,463.3z M79.3,445.1h400.8V251.9c0-22.1-17.2-40-38.3-40c-21.1,0-38.3,17.9-38.3,40V369H155.9V251.9c0-22.1-17.2-40-38.3-40s-38.3,17.9-38.3,40L79.3,445.1L79.3,445.1z"/><path class="st1" d="M441.8,207.6h-18.2c0-66.2-61.5-120.1-137.1-120.1H273c-75.6,0-137.1,53.9-137.1,120.1h-18.2c0-76.2,69.7-138.3,155.3-138.3h13.5C372.1,69.3,441.8,131.3,441.8,207.6z"/></g></g></g><path class="st2" d="M342.2,158.5c-1.2-0.5-29.8-11.7-52,0.1c-17.6,9.4-48.6,2.3-56.5,0.3v-6.1c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6v7.9c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1v75.9v2.8V305c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6v-62c6,1.5,19,4.3,32.4,4.3c9.3,0,18.9-1.3,26.5-5.4c20.1-10.6,47.3,0,47.6,0.1c0.8,0.3,1.7,0.2,2.4-0.3c0.7-0.5,1.2-1.3,1.2-2.2V161C343.9,159.9,343.2,158.9,342.2,158.5z M338.6,235.9c-8.5-2.7-30.5-8.1-48.4,1.4c-17.6,9.4-48.5,2.3-56.5,0.3v-0.8v-72.4c10.1,2.5,40.3,8.8,58.9-1.1c17.2-9.1,39.7-2.6,46-0.5V235.9z"/></svg>',

  svgPreIconResOcc: '<?xml version="1.0" encoding="utf-8"?> <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconResOcc: '	viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve"><style type="text/css">.st0{fill:#E83859;}.st1{fill:#FFFFFF;}.st2{fill:#FFFFFF;stroke:#FFFFFF;stroke-width:7.8669;stroke-miterlimit:10;}' +
    '</style><polygon class="st0" points="122.2,194.9 152.7,130.5 224.3,79.2 282.9,70.3 327.2,74.4 376,100.4 412.5,132.6 438.1,201.6 459.2,205.1 488.2,220.8 495,459 71.5,463 66,228.3 95.1,205.1 "/><g> <g>	<g>	<path class="st1" d="M498.3,463.3H61.1V251.9c0-32.1,25.4-58.2,56.5-58.2c31.2,0,56.5,26.1,56.5,58.2v98.9h211.1v-98.9c0-32.1,25.4-58.2,56.5-58.2c31.2,0,56.5,26.1,56.5,58.2L498.3,463.3L498.3,463.3z M79.3,445.1h400.8V251.9c0-22.1-17.2-40-38.3-40c-21.1,0-38.3,17.9-38.3,40V369H155.9V251.9c0-22.1-17.2-40-38.3-40s-38.3,17.9-38.3,40L79.3,445.1L79.3,445.1z"/>' +
    '<path class="st1" d="M441.8,207.6h-18.2c0-66.2-61.5-120.1-137.1-120.1H273c-75.6,0-137.1,53.9-137.1,120.1h-18.2c0-76.2,69.7-138.3,155.3-138.3h13.5C372.1,69.3,441.8,131.3,441.8,207.6z"/></g></g></g><path class="st2" d="M342.2,158.5c-1.2-0.5-29.8-11.7-52,0.1c-17.6,9.4-48.6,2.3-56.5,0.3v-6.1c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6v7.9c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1v75.9v2.8V305c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6v-62c6,1.5,19,4.3,32.4,4.3c9.3,0,18.9-1.3,26.5-5.4c20.1-10.6,47.3,0,47.6,0.1c0.8,0.3,1.7,0.2,2.4-0.3c0.7-0.5,1.2-1.3,1.2-2.2V161C343.9,159.9,343.2,158.9,342.2,158.5z M338.6,235.9c-8.5-2.7-30.5-8.1-48.4,1.4c-17.6,9.4-48.5,2.3-56.5,0.3v-0.8v-72.4c10.1,2.5,40.3,8.8,58.9-1.1c17.2-9.1,39.7-2.6,46-0.5V235.9z"/></svg>',

  svgPreIconResSel: '<?xml version="1.0" encoding="utf-8"?> <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconResSel: '	viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve"><style type="text/css">.st0{fill:#F2C35A;}.st1{fill:#FFFFFF;}.st2{fill:#FFFFFF;stroke:#FFFFFF;stroke-width:7.8669;stroke-miterlimit:10;}' +
    '</style><polygon class="st0" points="122.2,194.9 152.7,130.5 224.3,79.2 282.9,70.3 327.2,74.4 376,100.4 412.5,132.6 438.1,201.6 459.2,205.1 488.2,220.8 495,459 71.5,463 66,228.3 95.1,205.1 "/><g> <g>	<g>	<path class="st1" d="M498.3,463.3H61.1V251.9c0-32.1,25.4-58.2,56.5-58.2c31.2,0,56.5,26.1,56.5,58.2v98.9h211.1v-98.9c0-32.1,25.4-58.2,56.5-58.2c31.2,0,56.5,26.1,56.5,58.2L498.3,463.3L498.3,463.3z M79.3,445.1h400.8V251.9c0-22.1-17.2-40-38.3-40c-21.1,0-38.3,17.9-38.3,40V369H155.9V251.9c0-22.1-17.2-40-38.3-40s-38.3,17.9-38.3,40L79.3,445.1L79.3,445.1z"/>' +
    '<path class="st1" d="M441.8,207.6h-18.2c0-66.2-61.5-120.1-137.1-120.1H273c-75.6,0-137.1,53.9-137.1,120.1h-18.2c0-76.2,69.7-138.3,155.3-138.3h13.5C372.1,69.3,441.8,131.3,441.8,207.6z"/></g></g></g><path class="st2" d="M342.2,158.5c-1.2-0.5-29.8-11.7-52,0.1c-17.6,9.4-48.6,2.3-56.5,0.3v-6.1c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6v7.9c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1v75.9v2.8V305c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6v-62c6,1.5,19,4.3,32.4,4.3c9.3,0,18.9-1.3,26.5-5.4c20.1-10.6,47.3,0,47.6,0.1c0.8,0.3,1.7,0.2,2.4-0.3c0.7-0.5,1.2-1.3,1.2-2.2V161C343.9,159.9,343.2,158.9,342.2,158.5z M338.6,235.9c-8.5-2.7-30.5-8.1-48.4,1.4c-17.6,9.4-48.5,2.3-56.5,0.3v-0.8v-72.4c10.1,2.5,40.3,8.8,58.9-1.1c17.2-9.1,39.7-2.6,46-0.5V235.9z"/></svg>',

  svgPreIconDis: '<?xml version="1.0" encoding="utf-8"?> <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconDis: 'viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve"><polygon style="fill:#3C3C3B;" points="127.7,200.7 157.7,138 228.3,88 286,79.3 329.7,83.3 377.7,108.7 413.7,140 438.9,207.3 459.7,210.7 488.3,226 495,458.1 77.7,462 72.3,233.3 101,210.7 "/>' +
    '<g><g>	<path style="fill:#5EB6E7;" d="M503.3,463.3H65.7V251.7c0-32.1,25.4-58.3,56.5-58.3c31.2,0,56.6,26.1,56.6,58.3v99h211.3v-99c0-32.1,25.4-58.3,56.6-58.3c31.2,0,56.6,26.1,56.6,58.3V463.3z M84,445.1h401.1V251.7c0-22.1-17.2-40-38.3-40c-21.1,0-38.3,17.9-38.3,40V369H160.6V251.7c0-22.1-17.2-40-38.4-40c-21.1,0-38.3,17.9-38.3,40V445.1z"/>' +
    '<path style="fill:#5EB6E7;" d="M446.7,207.4h-18.2c0-66.3-61.6-120.2-137.2-120.2h-13.5c-75.7,0-137.2,53.9-137.2,120.2h-18.2C122.3,131.1,192,69,277.8,69h13.5C377,69,446.7,131.1,446.7,207.4z"/></g></g><g><g><g><path style="fill:#5EB6E7;" d="M360.4,312.6l-13.1-56.7c-1.5-5.9-6.7-9.9-12.7-9.9h-25.4c-3.6-5.9-8.3-11.1-13.8-15.2v-37.1c0-9.9-10.6-15.9-18.9-11.7L250.3,195c-4.5,2.2-7.2,6.7-7.2,11.7v16.7c-0.1,0-0.2,0-0.2,0.1c-21.1,8.2-34.6,28.2-34.6,50.8c0,30,24.4,54.5,54.5,54.5c30.2,0,55.7-25,54.4-56.7h7.3l10.6,46.8c1.7,7,8.8,11.3,15.9,9.5C357.9,326.7,362.2,319.6,360.4,312.6z M262.7,315.7c-22.8,0-41.4-18.6-41.4-41.4c0-15.5,8.5-29.3,21.8-36.4v16.8c0,7.2,5.8,13.1,13,13.1c7.2,0,13.1-5.9,13.1-13.1v-21.4c0.3,0.1,0.6,0.2,1,0.3c19.6,3.6,33.9,20.7,33.9,40.7C304.1,297.2,285.6,315.7,262.7,315.7z"/></g></g><g><g><path style="fill:#5EB6E7;" d="M284.5,128.3c-10.8,0-19.6,8.8-19.6,19.6c0,10.8,8.8,19.6,19.6,19.6c10.8,0,19.6-8.8,19.6-19.6C304.2,137.1,295.3,128.3,284.5,128.3z"/></g></g></g></svg>',

  svgPreIconDisSel: '<?xml version="1.0" encoding="utf-8"?> <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconDisSel: 'viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve"><polygon style="fill:#5EB6E7;" points="127.7,200.7 157.7,138 228.3,88 286,79.3 329.7,83.3 377.7,108.7 413.7,140 438.9,207.3 459.7,210.7 488.3,226 495,458.1 77.7,462 72.3,233.3 101,210.7 "/>' +
    '<g><g>	<path style="fill:#FFFFFF;" d="M503.3,463.3H65.7V251.7c0-32.1,25.4-58.3,56.5-58.3c31.2,0,56.6,26.1,56.6,58.3v99h211.3v-99c0-32.1,25.4-58.3,56.6-58.3c31.2,0,56.6,26.1,56.6,58.3V463.3z M84,445.1h401.1V251.7c0-22.1-17.2-40-38.3-40c-21.1,0-38.3,17.9-38.3,40V369H160.6V251.7c0-22.1-17.2-40-38.4-40c-21.1,0-38.3,17.9-38.3,40V445.1z"/>' +
    '<path style="fill:#FFFFFF;" d="M446.7,207.4h-18.2c0-66.3-61.6-120.2-137.2-120.2h-13.5c-75.7,0-137.2,53.9-137.2,120.2h-18.2C122.3,131.1,192,69,277.8,69h13.5C377,69,446.7,131.1,446.7,207.4z"/></g></g><g><g><g><path style="fill:#FFFFFF;" d="M360.4,312.6l-13.1-56.7c-1.5-5.9-6.7-9.9-12.7-9.9h-25.4c-3.6-5.9-8.3-11.1-13.8-15.2v-37.1c0-9.9-10.6-15.9-18.9-11.7L250.3,195c-4.5,2.2-7.2,6.7-7.2,11.7v16.7c-0.1,0-0.2,0-0.2,0.1c-21.1,8.2-34.6,28.2-34.6,50.8c0,30,24.4,54.5,54.5,54.5c30.2,0,55.7-25,54.4-56.7h7.3l10.6,46.8c1.7,7,8.8,11.3,15.9,9.5C357.9,326.7,362.2,319.6,360.4,312.6z M262.7,315.7c-22.8,0-41.4-18.6-41.4-41.4c0-15.5,8.5-29.3,21.8-36.4v16.8c0,7.2,5.8,13.1,13,13.1c7.2,0,13.1-5.9,13.1-13.1v-21.4c0.3,0.1,0.6,0.2,1,0.3c19.6,3.6,33.9,20.7,33.9,40.7C304.1,297.2,285.6,315.7,262.7,315.7z"/></g></g><g><g><path style="fill:#FFFFFF;" d="M284.5,128.3c-10.8,0-19.6,8.8-19.6,19.6c0,10.8,8.8,19.6,19.6,19.6c10.8,0,19.6-8.8,19.6-19.6C304.2,137.1,295.3,128.3,284.5,128.3z"/></g></g></g></svg>',

  svgPreIconDisOcc: '<?xml version="1.0" encoding="utf-8"?> <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconDisOcc: 'viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve"><polygon style="fill:#E83859;" points="127.7,200.7 157.7,138 228.3,88 286,79.3 329.7,83.3 377.7,108.7 413.7,140 438.9,207.3 459.7,210.7 488.3,226 495,458.1 77.7,462 72.3,233.3 101,210.7 "/>' +
    '<g><g>	<path style="fill:#FFFFFF;" d="M503.3,463.3H65.7V251.7c0-32.1,25.4-58.3,56.5-58.3c31.2,0,56.6,26.1,56.6,58.3v99h211.3v-99c0-32.1,25.4-58.3,56.6-58.3c31.2,0,56.6,26.1,56.6,58.3V463.3z M84,445.1h401.1V251.7c0-22.1-17.2-40-38.3-40c-21.1,0-38.3,17.9-38.3,40V369H160.6V251.7c0-22.1-17.2-40-38.4-40c-21.1,0-38.3,17.9-38.3,40V445.1z"/>' +
    '<path style="fill:#FFFFFF;" d="M446.7,207.4h-18.2c0-66.3-61.6-120.2-137.2-120.2h-13.5c-75.7,0-137.2,53.9-137.2,120.2h-18.2C122.3,131.1,192,69,277.8,69h13.5C377,69,446.7,131.1,446.7,207.4z"/></g></g><g><g><g><path style="fill:#FFFFFF;" d="M360.4,312.6l-13.1-56.7c-1.5-5.9-6.7-9.9-12.7-9.9h-25.4c-3.6-5.9-8.3-11.1-13.8-15.2v-37.1c0-9.9-10.6-15.9-18.9-11.7L250.3,195c-4.5,2.2-7.2,6.7-7.2,11.7v16.7c-0.1,0-0.2,0-0.2,0.1c-21.1,8.2-34.6,28.2-34.6,50.8c0,30,24.4,54.5,54.5,54.5c30.2,0,55.7-25,54.4-56.7h7.3l10.6,46.8c1.7,7,8.8,11.3,15.9,9.5C357.9,326.7,362.2,319.6,360.4,312.6z M262.7,315.7c-22.8,0-41.4-18.6-41.4-41.4c0-15.5,8.5-29.3,21.8-36.4v16.8c0,7.2,5.8,13.1,13,13.1c7.2,0,13.1-5.9,13.1-13.1v-21.4c0.3,0.1,0.6,0.2,1,0.3c19.6,3.6,33.9,20.7,33.9,40.7C304.1,297.2,285.6,315.7,262.7,315.7z"/></g></g><g><g><path style="fill:#FFFFFF;" d="M284.5,128.3c-10.8,0-19.6,8.8-19.6,19.6c0,10.8,8.8,19.6,19.6,19.6c10.8,0,19.6-8.8,19.6-19.6C304.2,137.1,295.3,128.3,284.5,128.3z"/></g></g></g></svg>',

  svgPreIconAcc: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconAcc: ' viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve"><polygon style="fill:#3C3C3B;" points="128.7,196.7 158.7,134 229.3,84 287,75.3 330.7,79.3 378.7,104.7 414.7,136 439.9,203.3 460.7,206.7 489.3,222 496,454.1 78.7,458 73.3,229.3 102,206.7 "/><g><g>' +
    '<path style="fill:#5EB6E7;" d="M502.6,463.2H64.7V251.5c0-32.1,25.4-58.3,56.6-58.3c31.3,0,56.6,26.2,56.6,58.3v99.1h211.4v-99.1c0-32.1,25.4-58.3,56.6-58.3c31.2,0,56.6,26.2,56.6,58.3V463.2z M83,444.9h401.3V251.5c0-22.1-17.2-40.1-38.4-40.1c-21.1,0-38.3,18-38.3,40.1v117.3H159.7V251.5c0-22.1-17.2-40.1-38.4-40.1c-21.1,0-38.3,18-38.3,40.1V444.9z"/><path style="fill:#5EB6E7;" d="M446,207.1h-18.2c0-66.3-61.6-120.2-137.3-120.2h-13.5c-75.7,0-137.3,53.9-137.3,120.2h-18.2c0-76.3,69.8-138.5,155.6-138.5h13.5C376.2,68.6,446,130.7,446,207.1z"/></g></g><g><path style="fill:#5EB6E7;" d="M316.9,272.3h-67.2l-15.5,47.2h-33.6l64.7-182.4h36.8l64.7,182.4h-34.4L316.9,272.3z M257.3,247.2h51.8l-25.4-77.9h-0.9L257.3,247.2z"/></g></svg>',

  svgPreIconAccSel: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconAccSel: ' viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve"><polygon style="fill:#5EB6E7;" points="128.7,196.7 158.7,134 229.3,84 287,75.3 330.7,79.3 378.7,104.7 414.7,136 439.9,203.3 460.7,206.7 489.3,222 496,454.1 78.7,458 73.3,229.3 102,206.7 "/><g><g>' +
    '<path style="fill:#FFFFFF;" d="M502.6,463.2H64.7V251.5c0-32.1,25.4-58.3,56.6-58.3c31.3,0,56.6,26.2,56.6,58.3v99.1h211.4v-99.1c0-32.1,25.4-58.3,56.6-58.3c31.2,0,56.6,26.2,56.6,58.3V463.2z M83,444.9h401.3V251.5c0-22.1-17.2-40.1-38.4-40.1c-21.1,0-38.3,18-38.3,40.1v117.3H159.7V251.5c0-22.1-17.2-40.1-38.4-40.1c-21.1,0-38.3,18-38.3,40.1V444.9z"/><path style="fill:#FFFFFF;" d="M446,207.1h-18.2c0-66.3-61.6-120.2-137.3-120.2h-13.5c-75.7,0-137.3,53.9-137.3,120.2h-18.2c0-76.3,69.8-138.5,155.6-138.5h13.5C376.2,68.6,446,130.7,446,207.1z"/></g></g><g><path style="fill:#FFFFFF;" d="M316.9,272.3h-67.2l-15.5,47.2h-33.6l64.7-182.4h36.8l64.7,182.4h-34.4L316.9,272.3z M257.3,247.2h51.8l-25.4-77.9h-0.9L257.3,247.2z"/></g></svg>',

  svgPreIconAccOcc: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconAccOcc: ' viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve"><polygon style="fill:#E83859;" points="128.7,196.7 158.7,134 229.3,84 287,75.3 330.7,79.3 378.7,104.7 414.7,136 439.9,203.3 460.7,206.7 489.3,222 496,454.1 78.7,458 73.3,229.3 102,206.7 "/><g><g>' +
    '<path style="fill:#FFFFFF;" d="M502.6,463.2H64.7V251.5c0-32.1,25.4-58.3,56.6-58.3c31.3,0,56.6,26.2,56.6,58.3v99.1h211.4v-99.1c0-32.1,25.4-58.3,56.6-58.3c31.2,0,56.6,26.2,56.6,58.3V463.2z M83,444.9h401.3V251.5c0-22.1-17.2-40.1-38.4-40.1c-21.1,0-38.3,18-38.3,40.1v117.3H159.7V251.5c0-22.1-17.2-40.1-38.4-40.1c-21.1,0-38.3,18-38.3,40.1V444.9z"/><path style="fill:#FFFFFF;" d="M446,207.1h-18.2c0-66.3-61.6-120.2-137.3-120.2h-13.5c-75.7,0-137.3,53.9-137.3,120.2h-18.2c0-76.3,69.8-138.5,155.6-138.5h13.5C376.2,68.6,446,130.7,446,207.1z"/></g></g><g><path style="fill:#FFFFFF;" d="M316.9,272.3h-67.2l-15.5,47.2h-33.6l64.7-182.4h36.8l64.7,182.4h-34.4L316.9,272.3z M257.3,247.2h51.8l-25.4-77.9h-0.9L257.3,247.2z"/></g></svg>',

  svgPreIconLove: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconLove: ' viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve"><style type="text/css">.st0{fill:#3C3C3B;}.st1{fill:#F45894;}</style><polygon class="st0" points="122.2,194.9 152.7,130.5 224.3,79.2 282.9,70.3 327.2,74.4 376,100.4 412.5,132.6 438.1,201.6 459.2,205.1 488.2,220.8 495,459 71.5,463 66,228.3 95.1,205.1 "/><g><path class="st1" d="M365.5,195.8c0-25.4-20-45.9-44.6-45.9c-0.6,0-1.2,0-1.7,0c-5.2,0.2-10.1,1.3-14.7,3.2c-0.5,0.2-1,0.4-1.5,0.7c-6,2.7-11.3,6.7-15.6,11.7c-3.2,3.7-5.8,8.8-7.7,13.5c-1.9-4.7-4.5-9.7-7.7-13.5c-4.3-5-9.6-9-15.6-11.7c-0.5-0.2-1-0.4-1.5-0.7c-4.6-1.9-9.5-3-14.7-3.2c-0.6,0-1.2,0-1.7,0c-24.6,0-44.6,20.6-44.6,45.9c0,0.6,0,2.9,0,3.5c0,39.6,41.2,76.9,66.5,96.1c6.6,5,12.3,8.9,15.8,11.2c2.2,1.4,5,1.4,7.1,0c3.4-2.2,9-6,15.7-11.2c25.3-19.2,66.5-56.5,66.5-96.1C365.5,198.7,365.5,196.4,365.5,195.8z"/>' +
    '<g><g><path class="st1" d="M498.3,463.3H61.1V251.9c0-32.1,25.4-58.2,56.5-58.2c31.2,0,56.5,26.1,56.5,58.2v98.9h211.1v-98.9c0-32.1,25.4-58.2,56.5-58.2c31.2,0,56.5,26.1,56.5,58.2V463.3z M79.3,445.1h400.8V251.9c0-22.1-17.2-40-38.3-40c-21.1,0-38.3,17.9-38.3,40V369H155.9V251.9c0-22.1-17.2-40-38.3-40c-21.1,0-38.3,17.9-38.3,40V445.1z"/><path class="st1" d="M441.8,207.6h-18.2c0-66.2-61.5-120.1-137.1-120.1h-13.5c-75.6,0-137.1,53.9-137.1,120.1h-18.2c0-76.2,69.7-138.3,155.3-138.3h13.5C372.1,69.3,441.8,131.3,441.8,207.6z"/></g></g></g></svg>',

  svgPreIconLoveSel: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconLoveSel: ' viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve"><style type="text/css">.st0{fill:#F45894;}.st1{fill:#FFFFFF;}</style><polygon class="st0" points="122.2,194.9 152.7,130.5 224.3,79.2 282.9,70.3 327.2,74.4 376,100.4 412.5,132.6 438.1,201.6 459.2,205.1 488.2,220.8 495,459 71.5,463 66,228.3 95.1,205.1 "/><g><path class="st1" d="M365.5,195.8c0-25.4-20-45.9-44.6-45.9c-0.6,0-1.2,0-1.7,0c-5.2,0.2-10.1,1.3-14.7,3.2c-0.5,0.2-1,0.4-1.5,0.7c-6,2.7-11.3,6.7-15.6,11.7c-3.2,3.7-5.8,8.8-7.7,13.5c-1.9-4.7-4.5-9.7-7.7-13.5c-4.3-5-9.6-9-15.6-11.7c-0.5-0.2-1-0.4-1.5-0.7c-4.6-1.9-9.5-3-14.7-3.2c-0.6,0-1.2,0-1.7,0c-24.6,0-44.6,20.6-44.6,45.9c0,0.6,0,2.9,0,3.5c0,39.6,41.2,76.9,66.5,96.1c6.6,5,12.3,8.9,15.8,11.2c2.2,1.4,5,1.4,7.1,0c3.4-2.2,9-6,15.7-11.2c25.3-19.2,66.5-56.5,66.5-96.1C365.5,198.7,365.5,196.4,365.5,195.8z"/>' +
    '<g><g><path class="st1" d="M498.3,463.3H61.1V251.9c0-32.1,25.4-58.2,56.5-58.2c31.2,0,56.5,26.1,56.5,58.2v98.9h211.1v-98.9c0-32.1,25.4-58.2,56.5-58.2c31.2,0,56.5,26.1,56.5,58.2V463.3z M79.3,445.1h400.8V251.9c0-22.1-17.2-40-38.3-40c-21.1,0-38.3,17.9-38.3,40V369H155.9V251.9c0-22.1-17.2-40-38.3-40c-21.1,0-38.3,17.9-38.3,40V445.1z"/><path class="st1" d="M441.8,207.6h-18.2c0-66.2-61.5-120.1-137.1-120.1h-13.5c-75.6,0-137.1,53.9-137.1,120.1h-18.2c0-76.2,69.7-138.3,155.3-138.3h13.5C372.1,69.3,441.8,131.3,441.8,207.6z"/></g></g></g></svg>',

  svgPreIconLoveOcc: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"',
  svgPostIconLoveOcc: ' viewBox="0 0 566.9 566.9" style="enable-background:new 0 0 566.9 566.9;" xml:space="preserve"><style type="text/css">.st0{fill:#E83859;}.st1{fill:#FFFFFF;}</style><polygon class="st0" points="122.2,194.9 152.7,130.5 224.3,79.2 282.9,70.3 327.2,74.4 376,100.4 412.5,132.6 438.1,201.6 459.2,205.1 488.2,220.8 495,459 71.5,463 66,228.3 95.1,205.1 "/><g><path class="st1" d="M365.5,195.8c0-25.4-20-45.9-44.6-45.9c-0.6,0-1.2,0-1.7,0c-5.2,0.2-10.1,1.3-14.7,3.2c-0.5,0.2-1,0.4-1.5,0.7c-6,2.7-11.3,6.7-15.6,11.7c-3.2,3.7-5.8,8.8-7.7,13.5c-1.9-4.7-4.5-9.7-7.7-13.5c-4.3-5-9.6-9-15.6-11.7c-0.5-0.2-1-0.4-1.5-0.7c-4.6-1.9-9.5-3-14.7-3.2c-0.6,0-1.2,0-1.7,0c-24.6,0-44.6,20.6-44.6,45.9c0,0.6,0,2.9,0,3.5c0,39.6,41.2,76.9,66.5,96.1c6.6,5,12.3,8.9,15.8,11.2c2.2,1.4,5,1.4,7.1,0c3.4-2.2,9-6,15.7-11.2c25.3-19.2,66.5-56.5,66.5-96.1C365.5,198.7,365.5,196.4,365.5,195.8z"/>' +
    '<g><g><path class="st1" d="M498.3,463.3H61.1V251.9c0-32.1,25.4-58.2,56.5-58.2c31.2,0,56.5,26.1,56.5,58.2v98.9h211.1v-98.9c0-32.1,25.4-58.2,56.5-58.2c31.2,0,56.5,26.1,56.5,58.2V463.3z M79.3,445.1h400.8V251.9c0-22.1-17.2-40-38.3-40c-21.1,0-38.3,17.9-38.3,40V369H155.9V251.9c0-22.1-17.2-40-38.3-40c-21.1,0-38.3,17.9-38.3,40V445.1z"/><path class="st1" d="M441.8,207.6h-18.2c0-66.2-61.5-120.1-137.1-120.1h-13.5c-75.6,0-137.1,53.9-137.1,120.1h-18.2c0-76.2,69.7-138.3,155.3-138.3h13.5C372.1,69.3,441.8,131.3,441.8,207.6z"/></g></g></g></svg>',

  scaleIconForSpacing: 0.9,
  dimSvgIconSimpleFreeSeatLevel2: 264,
  dimSvgIconSimpleFreeSeatLevel1: "1.00",
  dimSvgIconSimpleFreeSeatLevelT1: 50,
  dimSvgIconSimpleFreeSeatLevel0: 20,
  dimSvgIconSimpleFreeSeatLevelT1_1: 66,
  dimSvgIconSimpleFreeSeatLevelT1_2: 132,

  svgPreCinemaScreen: ' <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" ',
  svgPostCinemaScreen: 'viewBox="0 0 1105 107.5" style="enable-background:new 0 0 1105 107.5;" xml:space="preserve">' +
    '<style type="text/css"> .st0{fill:url(#SVGID_1_);} .st1{fill:#FFFFFF;} .st2{fill:#A3A3A3;}</style>' +
    '<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="553.3363" y1="37.6008" x2="553.3363" y2="71.3008" gradientTransform="matrix(1 0 0 -1 0 132)">' +
    '<stop  offset="0" style="stop-color:#010101;stop-opacity:0"/>' +
    '<stop  offset="1" style="stop-color:#010101"/>' +
    '</linearGradient>' +
    '<polygon class="st0" points="1068.6,60.7 872.9,60.7 657.9,60.7 644.6,60.7 462.1,60.7 448.8,60.7 233.8,60.7 37.9,60.7 9,94.4 233.8,94.4 433,94.4 448.8,94.4 657.9,94.4 673.6,94.4 872.9,94.4 1097.7,94.4 "/>' +
    '<polygon class="st1" points="1097.7,25.7 9,25.7 12.2,22.3 1094.6,22.3 "/>' +
    '<polygon class="st2" points="872.9,25.7 673.6,25.7 657.9,25.7 448.8,25.7 433,25.7 233.8,25.7 9,25.7 37.9,61.4 233.8,61.4 448.8,61.4 462.1,61.4 644.6,61.4 657.9,61.4 872.9,61.4 1068.6,61.4 1097.7,25.7 "/>' +
    '<g>' +
    '<path class="st1" d="M501.9,46.4c0-0.5-0.1-0.9-0.5-1.3c-0.4-0.4-1-0.6-1.9-0.9c-1.1-0.3-1.9-0.6-2.5-1.1c-0.6-0.5-0.9-1.1-0.9-2c0-0.9,0.4-1.5,1-2.1c0.6-0.6,1.5-0.9,2.5-0.9c1.1,0,2,0.4,2.6,1c0.6,0.6,1,1.4,1,2.3l0,0h-1.4c0-0.6-0.3-1.1-0.6-1.5c-0.4-0.4-1-0.6-1.6-0.6c-0.6,0-1.3,0.1-1.5,0.5c-0.3,0.4-0.5,0.8-0.5,1.3c0,0.5,0.3,0.9,0.6,1.1c0.4,0.3,1,0.6,1.9,0.9c1.1,0.3,1.9,0.8,2.5,1.3c0.6,0.5,0.9,1.3,0.9,2c0,0.9-0.4,1.5-1,2.1c-0.6,0.6-1.5,0.8-2.6,0.8c-1,0-2-0.3-2.8-0.9c-0.8-0.6-1.1-1.4-1.1-2.4l0,0h1.4c0,0.6,0.3,1.1,0.8,1.5c0.5,0.4,1.1,0.5,1.8,0.5c0.6,0,1.3-0.1,1.6-0.5C501.9,47.1,501.9,46.9,501.9,46.4z"/>' +
    '<path class="st1" d="M508.4,48.2c0.5,0,0.9-0.1,1.1-0.5c0.3-0.4,0.5-0.6,0.5-1.1h1.3l0,0c0,0.6-0.3,1.3-0.9,1.9c-0.6,0.6-1.3,0.8-2.1,0.8c-1.1,0-2-0.4-2.6-1.1c-0.6-0.8-0.9-1.8-0.9-2.9v-0.3c0-1.1,0.3-2,0.9-2.9c0.6-0.9,1.5-1.1,2.6-1.1c0.9,0,1.6,0.3,2.1,0.9c0.5,0.6,0.9,1.3,0.9,2l0,0H510c0-0.5-0.1-0.9-0.5-1.3c-0.4-0.4-0.8-0.5-1.3-0.5c-0.8,0-1.3,0.3-1.5,0.9c-0.3,0.6-0.5,1.3-0.5,2v0.3c0,0.8,0.1,1.5,0.5,2C507.1,47.7,507.7,48.2,508.4,48.2z"/>' +
    '<path class="st1" d="M514.5,42.1c0.3-0.4,0.6-0.6,1-0.9c0.4-0.3,0.9-0.4,1.4-0.4c0.9,0,1.5,0.3,2,0.8s0.8,1.3,0.8,2.4v5h-1.5v-5c0-0.6-0.1-1.1-0.4-1.5c-0.3-0.4-0.8-0.5-1.3-0.5c-0.4,0-0.8,0.1-1.1,0.3c-0.4,0.1-0.6,0.4-0.8,0.8v6.1h-1.5V37.6h1.5v4.5H514.5z"/>' +
    '<path class="st1" d="M525,49.3c-1.1,0-2-0.4-2.6-1.1c-0.6-0.8-1-1.6-1-2.9v-0.4c0-1.1,0.4-2.1,1-2.9c0.6-0.8,1.5-1.1,2.4-1.1c1,0,1.9,0.4,2.4,1c0.5,0.6,0.8,1.5,0.8,2.6v0.8h-5.2l0,0c0,0.8,0.1,1.5,0.5,1.9c0.4,0.4,0.9,0.8,1.6,0.8c0.5,0,0.9-0.1,1.3-0.3c0.4-0.1,0.6-0.4,1-0.6l0.6,1c-0.3,0.3-0.6,0.5-1.1,0.8S525.8,49.3,525,49.3z M524.9,42.1c-0.5,0-0.9,0.3-1.3,0.6c-0.4,0.4-0.5,0.9-0.6,1.5l0,0h3.7v-0.1c0-0.6-0.1-1-0.4-1.4C526,42.4,525.4,42.1,524.9,42.1z"/>' +
    '<path class="st1" d="M533.5,42.4h-0.8c-0.4,0-0.8,0.1-1,0.3c-0.3,0.1-0.5,0.4-0.6,0.8v5.7h-1.5v-7.9h1.3l0.1,1.1c0.3-0.4,0.5-0.8,0.9-1s0.8-0.4,1.1-0.4c0.1,0,0.3,0,0.3,0s0.1,0,0.3,0L533.5,42.4z"/>' +
    '<path class="st1" d="M536.2,41.1l0.1,1c0.3-0.4,0.6-0.6,1-0.9s0.9-0.3,1.4-0.3c0.5,0,1,0.1,1.4,0.4s0.6,0.6,0.9,1.1c0.3-0.5,0.6-0.8,1-1.1c0.4-0.4,0.9-0.4,1.4-0.4c0.8,0,1.5,0.3,1.9,0.9c0.4,0.6,0.8,1.4,0.8,2.5v4.8h-1.5v-4.8c0-0.8-0.1-1.4-0.4-1.6c-0.3-0.3-0.6-0.5-1.3-0.5c-0.5,0-0.9,0.1-1.3,0.5c-0.4,0.4-0.5,0.8-0.5,1.3l0,0v5h-1.5v-4.8c0-0.8-0.1-1.3-0.4-1.6c-0.3-0.4-0.6-0.5-1.3-0.5c-0.5,0-0.8,0.1-1.1,0.3c-0.4,0.1-0.5,0.4-0.6,0.8v5.9h-1.5v-7.9H536.2z"/>' +
    '<path class="st1" d="M547.8,45c0-1.1,0.4-2.1,1-2.9c0.6-0.8,1.5-1.1,2.6-1.1c1.1,0,2,0.4,2.6,1.1c0.6,0.8,1,1.8,1,2.9v0.1c0,1.1-0.4,2.1-1,2.9c-0.6,0.8-1.5,1.1-2.6,1.1c-1.1,0-2-0.4-2.6-1.1c-0.6-0.8-1-1.8-1-2.9V45z M549.4,45.2c0,0.9,0.1,1.5,0.5,2.1c0.4,0.6,0.9,0.9,1.6,0.9c0.8,0,1.3-0.3,1.6-0.9c0.4-0.6,0.5-1.3,0.5-2.1V45c0-0.9-0.1-1.5-0.5-2.1c-0.4-0.6-0.9-0.9-1.6-0.9s-1.3,0.3-1.6,0.9c-0.4,0.6-0.5,1.3-0.5,2.1V45.2z"/>' +
    '<path class="st1" d="M568.4,45.7L568.4,45.7c0,1-0.4,1.9-1,2.5c-0.6,0.6-1.6,1-2.9,1c-1.3,0-2.3-0.4-3-1.3c-0.8-0.9-1.1-2-1.1-3.3v-1.9c0-1.4,0.4-2.4,1.1-3.3c0.8-0.9,1.8-1.3,3-1.3c1.3,0,2.1,0.4,2.9,1c0.8,0.6,1,1.5,1,2.5l0,0H567c0-0.8-0.3-1.4-0.6-1.8c-0.4-0.4-1-0.6-1.9-0.6c-0.8,0-1.5,0.4-1.9,1c-0.4,0.6-0.8,1.5-0.8,2.4v1.9c0,1,0.3,1.8,0.8,2.4c0.5,0.6,1.1,1,1.9,1c0.8,0,1.4-0.3,1.9-0.6c0.5-0.4,0.6-1,0.6-1.8H568.4z"/>' +
    '<path class="st1" d="M571.9,49.1h-1.5V38.3h1.5V49.1z"/>' +
    '<path class="st1" d="M582.8,49.1h-1.5l-5.2-8.2l0,0v8.2h-1.5V38.3h1.5l5.2,8.2l0,0v-8.2h1.5V49.1z"/>' +
    '<path class="st1" d="M591.1,44.1h-4.4v3.9h5.2v1.1h-6.6V38.3h6.6v1.1h-5V43h4.4v1.1H591.1z"/>' +
    '<path class="st1" d="M598.9,47.1L598.9,47.1l3.5-8.7h1.8v10.7h-1.5V41l0,0l-3.3,8.1h-1l-3.4-8.3l0,0v8.3h-1.5V38.3h1.9L598.9,47.1z"/>' +
    '<path class="st1" d="M612.4,46.3h-4l-1,2.8h-1.5l3.9-10.7h1.3l3.8,10.7h-1.5L612.4,46.3z M608.9,45.2h3.2l-1.5-4.7l0,0L608.9,45.2z"/>' +
    '</g>' +
    '</svg>',
  
  iconSimpleFreeSeatLevel0: "../../assets/img/minimal/10_10_1.png",
  iconSimpleFreeSeatLevel1: "../../assets/img/minimal/28_28_libero.png",
  iconSimpleFreeTeathSeatLevel1: "../../assets/img/minimal/20_20_libero.png",
  iconSimpleFreeSeatLevel2: "../../assets/img/minimal/50_50_libero.png",
  iconSimpleFreeSelectedSeatLevel0: "../../assets/img/minimal/10_10_2.png",
  iconSimpleFreeSelectedSeatLevel1: "../../assets/img/minimal/28_28_sel.png",
  iconSimpleFreeTeathSelectedSeatLevel1: "../../assets/img/minimal/20_20_sel.png",
  iconSimpleFreeSelectedSeatLevel2: "../../assets/img/minimal/50_50_sel.png",
  iconSimpleBusySeatLevel0: "../../assets/img/minimal/10_10_2.png",
  iconSimpleBusySeatLevel1: "../../assets/img/minimal/28_28_occupato.png",
  iconSimpleBusyTeathSeatLevel1: "../../assets/img/minimal/20_20_occupato.png",
  iconSimpleBusySeatLevel2: "../../assets/img/minimal/50_50_occupato.png",

  iconScreenLevel2: "../../assets/img/schermo_1_new.png",
  iconScreenLevel1: "../../assets/img/schermo_0_new.png",
  iconScreenLevel0: "../../assets/img/schermo_2_new.png",
  dimXMap: 980,
  dimYMap: 650,
  dimXMediumMap: 700,
  dimYMediumMap: 400,
  dimPricesX: 500,
  dimPricesY: 248,
  dimFontNormal: 12,
  dimFontEvidence: 16,
  dimFontBiggest: 30,
  dimFontSmall: 8,
  dimFontVerySmall: 5,
  dimPricesXContainer: 28,
  dimPricesYContainer: 225,
  ticketTypeKeyDefault: "F1",
  maxTicketsPerSession: "50",
  prefixScreen: "Sala",
  prefixReadOnly: "SOLA LETTURA",
  prefixOccupiedBy: "bloccato da",
  notSelectable: "POSTO/I NON SELEZIONABILE/I",
  tokenPrevendita: "02",
  tokenVendita: "01",
  temporaryFiscalNature: "T",
  temporaryCodOperation: "01",
  refreshRate: 5000,
  upperBoundMorning: 13,
  lowerBoundEvening: 18,
  minutesBeforeWarning: 15,
  performanceTitleLength: 16,
  sessionIdPrefixLength: 10,
  sessionIdSuffixLength: 10000,
  imagesPath: "assets/winticstellar/img",
  dtimeDayValues: [
    { value: 1, viewValue: 'UXLBL.COUNTER.LUNEDI' },
    { value: 2, viewValue: 'UXLBL.COUNTER.MARTEDI' },
    { value: 3, viewValue: 'UXLBL.COUNTER.MERCOLEDI' },
    { value: 4, viewValue: 'UXLBL.COUNTER.GIOVEDI' },
    { value: 5, viewValue: 'UXLBL.COUNTER.VENERDI' },
    { value: 6, viewValue: 'UXLBL.COUNTER.SABATO' },
    { value: 0, viewValue: 'UXLBL.COUNTER.DOMENICA' }
  ],
  showLandingDividerLine: false,
  landingDividerText: '',
  tmdbAPI: {
    hostname: 'https://api.themoviedb.org',
    imagesUrl: 'https://image.tmdb.org/t/p/original',
    version: '3',
    apiKey: 'cd47c6768e40e65abc75a4dc09781add',
    language: 'it',
    includeAdult: false,
    logoUrl: 'https://www.themoviedb.org/assets/1/v4/logos/primary-green-d70eebe18a5eb5b166d5c1ef0796715b8d1a2cbc698f96d311d62f894ae87085.svg',
    maxCastPersons: 5
  },
  defaultLocale: 'it',
  locales: [
    { value: 'it', viewValue: 'Italiano', img: '/assets/common/img/ITA.png' }
  ],

 
  versTile: "v2",
  tiles: [
    {
      img: "icn_ordini.png",
      value: "ordini",
      double: false,
      enabled: true,
      action: "pm/store/action/orders"
    },
    {
      img: "icn_entrate.png",
      value: "entrate",
      double: false,
      enabled: true,
      action: "pm/store/action/entry-docs"
    },
    {
      img: "icn_uscite.png",
      value: "uscite",
      double: false,
      enabled: true,
      action: "pm/store/action/exit-docs-entry"
    },
    {
      img: "icn_inventario.png",
      value: "inventario",
      double: true,
      enabled: false,
      action: "pm/store/action/inventory"
    },
    {
      img: "icn_avvisi.png",
      value: "avvisi",
      double: false,
      enabled: true,
      action: "pm/store/action/alerts",
      gif: "icn_avvisi.gif"
    },
    {
      img: "icn_report.png",
      value: "report",
      double: false,
      enabled: true,
      action: "pm/store/action/reports"
    }
  ],
  tilesSuppliers: [
    {
      img: "icn_ordini_ricevuti.png",
      value: "ordini ricevuti",
      double: false,
      enabled: true,
      action: "pm/suppliers/action/orders"
    },
    {
      img: "icn_ordini_fatturabili.png",
      value: "ordini fatturabili",
      double: false,
      enabled: true,
      action: "pm/suppliers/action/invoiceable"
    },
    {
      img: "icn_fatture.png",
      value: "fatture",
      double: false,
      enabled: true,
      action: "pm/suppliers/action/invoices"
    }
  ],
  images: [
    {
      caption: "Crea un nuovo ordine",
      src: "aggiungi",
      enabled: false,
      action: "add",
      visibile: true
    },
    {
      caption: "Modifica la selezione",
      src: "modifica",
      enabled: false,
      action: "edit",
      visibile: true
    },
    {
      caption: "Elimina la selezione",
      src: "cancella",
      enabled: false,
      action: "delete",
      visibile: true
    },
    {
      caption: "Salva come bozza",
      src: "bozza",
      enabled: false,
      action: "draft",
      visibile: true
    },
    {
      caption: "Salva e conferma",
      src: "definitivo_white",
      enabled: false,
      action: "confirm",
      visibile: true
    },
    {
      caption: "Stampa",
      src: "stampa",
      enabled: false,
      action: "print",
      visibile: true
    },
    {
      caption: "Duplica l'ordine",
      src: "duplica",
      enabled: false,
      action: "duplicate",
      visibile: true
    },
    {
      caption: "Crea un ordine sotto scorta",
      src: "aggiungi_",
      enabled: false,
      action: "sottoscorta",
      visibile: true
    }
  ],
  imagesMM: [
    {
      caption: "Crea un nuovo ordine",
      src: "aggiungi",
      enabled: false,
      action: "add",
      visibile: true
    },
    {
      caption: "Modifica la selezione",
      src: "modifica",
      enabled: false,
      action: "edit",
      visibile: true
    },
    {
      caption: "Elimina la selezione",
      src: "cancella",
      enabled: false,
      action: "delete",
      visibile: true
    },
    {
      caption: "Salva come bozza",
      src: "bozza",
      enabled: false,
      action: "draft",
      visibile: true
    },
    {
      caption: "Salva e conferma",
      src: "definitivo_white",
      enabled: false,
      action: "confirm",
      visibile: true
    },
    {
      caption: "Stampa",
      src: "stampa",
      enabled: false,
      action: "print",
      visibile: true
    },
    {
      caption: "Duplica l'ordine",
      src: "duplica",
      enabled: false,
      action: "duplicate",
      visibile: true
    },
    {
      caption: "Crea un ordine sotto scorta",
      src: "aggiungi_",
      enabled: false,
      action: "sottoscorta",
      visibile: true
    }
  ],
  imagesS: [
    [
      {
        caption: "Visualizza",
        src: "lente_box",
        enabled: true,
        action: "view",
        visibile: true
      },
      {
        caption: "Stampa",
        src: "stampa",
        enabled: true,
        action: "print",
        visibile: true
      },
      {
        caption: "Evadi (genera DDT)",
        src: "invia",
        enabled: true,
        action: "go",
        visibile: true
      },
      {
        caption: "Respingi",
        src: "delete",
        enabled: true,
        action: "ko",
        visibile: true
      }
    ],
    [
      {
        caption: "Visualizza",
        src: "lente_box",
        enabled: true,
        action: "view",
        visibile: true
      },
      {
        caption: "Stampa",
        src: "stampa",
        enabled: true,
        action: "print",
        visibile: true
      },
      {
        caption: "Genera Prefattura",
        src: "tab_nome",
        enabled: true,
        action: "inv",
        visibile: true
      },
      {
        caption: "Elimina Fattura",
        src: "delete",
        enabled: true,
        action: "reminv",
        visibile: true
      }
    ],
    [
      {
        caption: "Stampa",
        src: "stampa",
        enabled: true,
        action: "print",
        visibile: true
      }
    ]
  ],
  notificationOptions: { timeOut: 3000, showProgressBar: true, pauseOnHover: true, clickToClose: true, animate: 'fromRight' },

  oAuthClientId: "DentalPro",
  oAuthScope: "openid profile roles https://identity.dentalpro.it/resources",
  oAuthIssuer: "https://identity.dentalpro.it",
  idNegozio: 950,
  GetInfoWS: webApi + "/api/getInfoNegozio",
  getListaOrdiniFornitore: webApi + "/api/backoffice/getListaOrdiniFornitori",
  getListaOrdiniDaFatturare: webApi + "/api/backoffice/getListaOrdiniFornitoriDaFatturare",
  getListaOrdiniFatturati: webApi + "/api/backoffice/getListaOrdiniFornitoriFatturati",
  eliminaFatturaFornitori: webApi + "/api/backoffice/documents/eliminaFatturaFornitori",
  emettiFattureFornitori: webApi + "/api/backoffice/documents/emettiFattureNoteDiCreditoFornitori",
  emettiFatturaElettronica: webApi + "/api/backoffice/documents/emettiFatturaElettronica",
  eliminaNotaDiCreditoFornitori: webApi + "/api/backoffice/documents/eliminaNotaDiCreditoFornitori",
  GetCategorieFornitori: webApi + "/api/backoffice/getCategorieFornitori",
  GetBudgetResiduoClinica:  webApi + "/api/backoffice/residui/getBudgetResiduoClinica",
  GetElencoFornitori: webApi + "/api/backoffice/getElencoFornitori",
  //GetElencoCompletoFornitori: webApi + "/api/backoffice/getElencoCompletoFornitori",
  GetArticoliFornitori: webApi + "/api/backoffice/getArticoliFornitore",
  GetArticoliFornitoriOrdineCross: webApi + "/api/backoffice/getArticoliFornitori",
  GetArticoliFornitoriBollaUscita: webApi + "/api/backoffice/getArticoliFiliale",
  GetElencoOrdini: webApi + "/api/backoffice/getListaOrdini",
  GetDettaglioOrdine: webApi + "/api/backoffice/getDettaglioOrdine",
  GetDettaglioBolleEntrata: webApi + "/api/backoffice/getDettaglioBollaEntrata",
  GetDettaglioBolleUscita: webApi + "/api/backoffice/getDettaglioBollaUscita",
  GetElencoBolleEntrata: webApi + "/api/backoffice/getListaBolleEntrata",
  GetElencoBolleUscita: webApi + "/api/backoffice/getListaBolleUscita",
  ForecastInit: "https://forecast.dentalpro.it/initMonth",
  GetForecast: "https://forecast.dentalpro.it/getForecastsOrdered",
  ForceForecast: "https://forecast.dentalpro.it/updateMonth",
  UpdateForecast: "https://forecast.dentalpro.it/updateForecasts",
  SearchCarotaggio: "https://api-staff.dentalpro.it/api/Pazientis/search",
  GetMailAffittuariMese:"https://forecast.dentalpro.it/api/affittuari_mail_mensile",
  PostMailAffittuariMese:"https://forecast.dentalpro.it/api/affittuari_mail_mensili_filtered",
  SaveOrder: webApi + "/api/backoffice/documents/emettiOrdine",
  SaveOrderCross: webApi + "/api/backoffice/documents/emettiOrdineCross",
  DeleteOrder: webApi + "/api/backoffice/documents/eliminaOrdine",
  GetHandlerPDF: webApi + "/Handlers/handlerDocuments.ashx?idnegozio={0}&iddocumento={1}&idadm={2}",
  GetHandlerFatture: webApi + "/Handlers/handlerFatture.ashx?idnegozio={0}&iddocumento={1}&idadm={2}",
  EditOrder: webApi + "/api/backoffice/documents/modificaOrdine",
  GetElencoInventari: webApi + "/api/backoffice/inventory/getListaInventari",
  GetInventarioItems: webApi + "/api/backoffice/inventory/getInventoryItems",
  GetElencoFiliali: webApi + "/api/backoffice/getElencoFiliali",
  EmettiBolla: webApi + "/api/backoffice/documents/emettiBollaEntrata",
  EmettiBollaUscita: webApi + "/api/backoffice/documents/emettiBollaUscita",
  EmettiBollaUscitaTotOrdine: webApi + "/api/backoffice/documents/emettiBollaUscitaDaBollaEntrata",
  GetArticoliInventario: webApi + "/api/backoffice/inventory/getArticoliInventario",
  ConfermaOrdineFornitore: webApi + "/api/backoffice/documents/confermaOrdineFornitore",
  GetHandlerInventario: webApi + "/Handlers/handlerInventoryXLS.ashx?idnegozio={0}&iddocumento={1}&idadm={2}",
  //SaveInventarioProvvisorio: webApi + "/api/backoffice/inventory/registraInventarioProvvisorio",
  SaveInventarioProvvisorio: webApi + "/api/backoffice/inventory/registraInventarioContati",
  SaveInventarioDefinitivo: webApi + "/api/backoffice/inventory/registraInventarioDefinitivo",
  //DeleteInventarioProvvisorio: webApi + "/api/backoffice/inventory/eliminaInventarioProvvisorio",
  DeleteInventarioProvvisorio: webApi + "/api/backoffice/inventory/eliminaInventarioContati",
  GetDettaglioInventario: webApi + "/api/backoffice/inventory/getInventario",
  GetElencoAlerts: webApi + "/api/backoffice/utility/alerts/checkAlerts",
  GetBottoniBolleUscita: webApi + "/api/backoffice/getTipologieBolleUscita",
  GetReports: webApi + "/api/backoffice/reports/getReportsMenu",
  GetReportParams: webApi + "/api/backoffice/reports/getReportParameters",
  GetReportsData: webApi + "/api/backoffice/reports/getReportData",
  GetReportsDataHandler: webApi + "/Handlers/reports/{0}/handlerReports.ashx",
  ResendEmailOrdine: webApi + "/api/backoffice/utility/mail/sendMailOrder",
  SaveOrdineCross: webApi + "/api/backoffice/documents/emettiOrdineCross",
  GetArticoliOrdineCross: webApi + "/api/backoffice/getArticoliFornitori",
  GetListaMinimoOrdineFornitori: webApi + "/api/backoffice/minimi/getListaOrdiniMinimiFornitori",
  SaveMinimoOrdineFornitore: webApi + "/api/backoffice/minimi/setMinimoOrdineFornitore",
  getListaFatture:"https://api-staff.dentalpro.it/api/Fattures",
  getListaProduzioni:"https://api-staff.dentalpro.it/api/PreventiviPrestazioniEsecuzionis",
  MyContext: "DENTALPRO",
  tableMessages: {
    emptyMessage: 'Nessun dato da visualizzare',
    totalMessage: 'righe totali'
  }, GetAllMessages: "https://forecast.dentalpro.it/getAll",
  GetMessage: "https://forecast.dentalpro.it/getMessage/{0}",
  GetOperators: "https://forecast.dentalpro.it/getoperators",
  GetAllMessagesPerClinic: "https://forecast.dentalpro.it/getMessages/{0}/{1}",
  PostMessage: "https://forecast.dentalpro.it/postMessage",
  UpdateMessage: "https://forecast.dentalpro.it/updateMessage",
  DeleteMessage: "https://forecast.dentalpro.it/deleteMessage/",
  GetAllDomains: "https://identity.dentalpro.it/api/winticplusdomain/getall",
  GetAllProfiles: "https://identity.dentalpro.it/api/winticplusprofile/getall",
  GetDetailUser: "https://identity.dentalpro.it/api/winticplusaccount/get/",
  GetDetailPatient: "https://ssopazienti.dentalpro.it/api/winticplusaccount/get/",
  PostMedic:"https://identity.dentalpro.it/api/winticplusaccount/doctor",
  ActiveMessage: "https://forecast.dentalpro.it/activeMessage/",
  DisActiveMessage: "https://forecast.dentalpro.it/disactiveMessage/",
  DefaultTime: { value: 2, viewvalue: '2 mesi' },
  TimeValues: [{ value: 2, viewvalue: '2 mesi' }, { value: 4, viewvalue: '4 mesi' }, { value: 6, viewvalue: '6 mesi' }, { value: 12, viewvalue: 'un anno' }, { value: 0, viewvalue: 'tutti' }],
 
  GetNodiTastiera: webApi + "/api/getArticoliNegozio",
  ImgTastiera: webApi + "/Handlers/handlerNodiTastiera.ashx",
  ImgArticolo: webApi + "/Handlers/handlerArticoli.ashx",
  ArticoliNegozio: webApi + "/api/getArticoliNegozio",
  AddArticoloToCheckOut: webApi + "/api/addArticoloToCheckOut",
  RemoveArticoloToCheckOut: webApi + "/api/removeArticoloToCheckOut",
  GetPaymentMethods: webApi + "/api/getPaymentsMode",
  PayAndGenerateTicket: webApi + "/api/EmettiScontrinoNegozio",
  SearchProduct: webApi + '/api/search/searchArticoli',
  GetProductDetail: webApi + '/api/getArticoloTastiera',
  GetPosConfiguration: webApi + '/api/orders/getPosConfiguration',
  GetTastieraVarianti: webApi + '/api/articoli/getTastieraVarianti',

  ConcessionSettings:
  {
    "idnegozio": 250,
    "web_box": "WEB_POS_01",
    "web_operator": "utente pos",
    "sessionId": "GLS_SESSION_ID_125",
    "trackid": 100,
    "postazione": {
      "IdPostazione": 82,
      stringID: "WEB_POS_01"
    }
  },
  concessionIcons: [
    { src: "arrow_up", name: "ecr.ecr-home.1200", disabled: true, visible: true, action: "resetKeyboard" },
    { src: "storni_resi", name: "ecr.ecr-home.1201", disabled: true, visible: true, action: "blank" },
    { src: "annulla", name: "ecr.ecr-home.1202", disabled: true, visible: false, action: "openPopupConfirm" },
    { src: "pausa", name: "ecr.ecr-home.1203", disabled: true, visible: true, action: "callPause" },
    { src: "servizi", name: "ecr.ecr-home.1204", disabled: true, visible: true, action: "blank" },
    { src: "icn_settings", name: "ecr.ecr-home.1227", disabled: true, visible: true, action: "openSettingsPopup" },
    { src: "cassetto", name: "ecr.ecr-home.1205", disabled: false, visible: true, action: "blank" },
    { src: "iconizza", name: "ecr.ecr-home.1206", disabled: true, visible: false, action: "blank" },
    { src: "cerca", name: "ecr.ecr-home.1207", disabled: false, visible: true, action: "openSearch" },
    { src: "carte", name: "ecr.ecr-home.1208", disabled: true, visible: true, action: "blank" },
    { src: "customize", name: "ecr.ecr-home.1209", disabled: true, visible: true, action: "openPopupLayout" },
    { src: "esci", name: "ecr.ecr-home.1210", disabled: false, visible: false, action: "test" },
    { src: "box_office", name: "BOX OFFICE", disabled: false, visible: true, action: "goToBoxOffice" },
  ],
  keyboardCalc: [
    [
      { disabled: false, caption: "C", isDouble: false, enabledOnlyWithItemSelected: true },
      { disabled: false, caption: "←", isDouble: false, enabledOnlyWithItemSelected: true },
      { disabled: true, caption: "AC", isDouble: false, enabledOnlyWithItemSelected: false },
      { disabled: true, caption: "/", isDouble: false, enabledOnlyWithItemSelected: false }
    ],
    [
      { disabled: false, caption: "7", isDouble: false, enabledOnlyWithItemSelected: false },
      { disabled: false, caption: "8", isDouble: false, enabledOnlyWithItemSelected: false },
      { disabled: false, caption: "9", isDouble: false, enabledOnlyWithItemSelected: false },
      { disabled: true, caption: "X", isDouble: false, enabledOnlyWithItemSelected: false }
    ],
    [
      { disabled: false, caption: "4", isDouble: false, enabledOnlyWithItemSelected: false },
      { disabled: false, caption: "5", isDouble: false, enabledOnlyWithItemSelected: false },
      { disabled: false, caption: "6", isDouble: false, enabledOnlyWithItemSelected: false },
      { disabled: false, caption: "–", isDouble: false, enabledOnlyWithItemSelected: true }
    ],
    [
      { disabled: false, caption: "1", isDouble: false, enabledOnlyWithItemSelected: false },
      { disabled: false, caption: "2", isDouble: false, enabledOnlyWithItemSelected: false },
      { disabled: false, caption: "3", isDouble: false, enabledOnlyWithItemSelected: false },
      { disabled: false, caption: "+", isDouble: false, enabledOnlyWithItemSelected: true }
    ],
    [
      { disabled: false, caption: "0", isDouble: false, enabledOnlyWithItemSelected: false },
      { disabled: true, caption: ".", isDouble: false, enabledOnlyWithItemSelected: false },
      { disabled: false, caption: "=", isDouble: true, enabledOnlyWithItemSelected: true }
    ]
  ],
  SEARCH_PRODUCT_PAGE_SIZE: 10,
  

  filmSearch: {
    lastNDays: 30
  },
  
  calendar: {
    toggleEvents: false
  },
  gceAPI: 'https://forecast.dentalpro.it/api',
  getPrescrizione:'/prescrizioni',
  //gceAPI: 'http://localhost:57526/api',
  provs: [
    { cod_prov: "AG", descrizione: "Agrigento", regione: "Sicilia" },
    { cod_prov: "AL", descrizione: "Alessandria", regione: "Piemonte" },
    { cod_prov: "AN", descrizione: "Ancona", regione: "Marche" },
    { cod_prov: "AO", descrizione: "Aosta", regione: "Valle d'Aosta" },
    { cod_prov: "AQ", descrizione: "L'Aquila", regione: "Abruzzo" },
    { cod_prov: "AR", descrizione: "Arezzo", regione: "Toscana" },
    { cod_prov: "AP", descrizione: "Ascoli-Piceno", regione: "Marche" },
    { cod_prov: "AT", descrizione: "Asti", regione: "Piemonte" },
    { cod_prov: "AV", descrizione: "Avellino", regione: "Campania" },
    { cod_prov: "BA", descrizione: "Bari", regione: "Puglia" },
    { cod_prov: "BT", descrizione: "Barletta-Andria-Trani", regione: "Puglia" },
    { cod_prov: "BL", descrizione: "Belluno", regione: "Veneto" },
    { cod_prov: "BN", descrizione: "Benevento", regione: "Campania" },
    { cod_prov: "BG", descrizione: "Bergamo", regione: "Lombardia" },
    { cod_prov: "BI", descrizione: "Biella", regione: "Piemonte" },
    { cod_prov: "BO", descrizione: "Bologna", regione: "Emilia Romagna" },
    { cod_prov: "BZ", descrizione: "Bolzano", regione: "Trentino Alto Adige" },
    { cod_prov: "BS", descrizione: "Brescia", regione: "Lombardia" },
    { cod_prov: "BR", descrizione: "Brindisi", regione: "Puglia" },
    { cod_prov: "CA", descrizione: "Cagliari", regione: "Sardegna" },
    { cod_prov: "CL", descrizione: "Caltanissetta", regione: "Sicilia" },
    { cod_prov: "CB", descrizione: "Campobasso", regione: "Molise" },
    { cod_prov: "CI", descrizione: "Carbonia Iglesias", regione: "Sardegna" },
    { cod_prov: "CE", descrizione: "Caserta", regione: "Campania" },
    { cod_prov: "CT", descrizione: "Catania", regione: "Sicilia" },
    { cod_prov: "CZ", descrizione: "Catanzaro", regione: "Calabria" },
    { cod_prov: "CH", descrizione: "Chieti", regione: "Abruzzo" },
    { cod_prov: "CO", descrizione: "Como", regione: "Lombardia" },
    { cod_prov: "CS", descrizione: "Cosenza", regione: "Calabria" },
    { cod_prov: "CR", descrizione: "Cremona", regione: "Lombardia" },
    { cod_prov: "KR", descrizione: "Crotone", regione: "Calabria" },
    { cod_prov: "CN", descrizione: "Cuneo", regione: "Piemonte" },
    { cod_prov: "EN", descrizione: "Enna", regione: "Sicilia" },
    { cod_prov: "FM", descrizione: "Fermo", regione: "Marche" },
    { cod_prov: "FE", descrizione: "Ferrara", regione: "Emilia Romagna" },
    { cod_prov: "FI", descrizione: "Firenze", regione: "Toscana" },
    { cod_prov: "FG", descrizione: "Foggia", regione: "Puglia" },
    { cod_prov: "FC", descrizione: "Forli-Cesena", regione: "Emilia Romagna" },
    { cod_prov: "FR", descrizione: "Frosinone", regione: "Lazio" },
    { cod_prov: "GE", descrizione: "Genova", regione: "Liguria" },
    { cod_prov: "GO", descrizione: "Gorizia", regione: "Friuli Venezia Giulia" },
    { cod_prov: "GR", descrizione: "Grosseto", regione: "Toscana" },
    { cod_prov: "IM", descrizione: "Imperia", regione: "Liguria" },
    { cod_prov: "IS", descrizione: "Isernia", regione: "Molise" },
    { cod_prov: "SP", descrizione: "La-Spezia", regione: "Liguria" },
    { cod_prov: "LT", descrizione: "Latina", regione: "Lazio" },
    { cod_prov: "LE", descrizione: "Lecce", regione: "Puglia" },
    { cod_prov: "LC", descrizione: "Lecco", regione: "Lombardia" },
    { cod_prov: "LI", descrizione: "Livorno", regione: "Toscana" },
    { cod_prov: "LO", descrizione: "Lodi", regione: "Lombardia" },
    { cod_prov: "LU", descrizione: "Lucca", regione: "Toscana" },
    { cod_prov: "MC", descrizione: "Macerata", regione: "Marche" },
    { cod_prov: "MN", descrizione: "Mantova", regione: "Lombardia" },
    { cod_prov: "MS", descrizione: "Massa-Carrara", regione: "Toscana" },
    { cod_prov: "MT", descrizione: "Matera", regione: "Basilicata" },
    { cod_prov: "VS", descrizione: "Medio Campidano", regione: "Sardegna" },
    { cod_prov: "ME", descrizione: "Messina", regione: "Sicilia" },
    { cod_prov: "MI", descrizione: "Milano", regione: "Lombardia" },
    { cod_prov: "MO", descrizione: "Modena", regione: "Emilia Romagna" },
    { cod_prov: "MB", descrizione: "Monza Brianza", regione: "Lombardia" },
    { cod_prov: "NA", descrizione: "Napoli", regione: "Campania" },
    { cod_prov: "NO", descrizione: "Novara", regione: "Piemonte" },
    { cod_prov: "NU", descrizione: "Nuoro", regione: "Sardegna" },
    { cod_prov: "OG", descrizione: "Ogliastra", regione: "Sardegna" },
    { cod_prov: "OT", descrizione: "Olbia Tempio", regione: "Sardegna" },
    { cod_prov: "OR", descrizione: "Oristano", regione: "Sardegna" },
    { cod_prov: "PD", descrizione: "Padova", regione: "Veneto" },
    { cod_prov: "PA", descrizione: "Palermo", regione: "Sicilia" },
    { cod_prov: "PR", descrizione: "Parma", regione: "Emilia Romagna" },
    { cod_prov: "PV", descrizione: "Pavia", regione: "Lombardia" },
    { cod_prov: "PG", descrizione: "Perugia", regione: "Umbria" },
    { cod_prov: "PU", descrizione: "Pesaro-Urbino", regione: "Marche" },
    { cod_prov: "PE", descrizione: "Pescara", regione: "Abruzzo" },
    { cod_prov: "PC", descrizione: "Piacenza", regione: "Emilia Romagna" },
    { cod_prov: "PI", descrizione: "Pisa", regione: "Toscana" },
    { cod_prov: "PT", descrizione: "Pistoia", regione: "Toscana" },
    { cod_prov: "PN", descrizione: "Pordenone", regione: "Friuli Venezia Giulia" },
    { cod_prov: "PZ", descrizione: "Potenza", regione: "Basilicata" },
    { cod_prov: "PO", descrizione: "Prato", regione: "Toscana" },
    { cod_prov: "RG", descrizione: "Ragusa", regione: "Sicilia" },
    { cod_prov: "RA", descrizione: "Ravenna", regione: "Emilia Romagna" },
    { cod_prov: "RC", descrizione: "Reggio-Calabria", regione: "Calabria" },
    { cod_prov: "RE", descrizione: "Reggio-Emilia", regione: "Emilia Romagna" },
    { cod_prov: "RI", descrizione: "Rieti", regione: "Lazio" },
    { cod_prov: "RN", descrizione: "Rimini", regione: "Emilia Romagna" },
    { cod_prov: "RM", descrizione: "Roma", regione: "Lazio" },
    { cod_prov: "RO", descrizione: "Rovigo", regione: "Veneto" },
    { cod_prov: "SA", descrizione: "Salerno", regione: "Campania" },
    { cod_prov: "SM", descrizione: "San Marino", regione: "San Marino" },
    { cod_prov: "SS", descrizione: "Sassari", regione: "Sardegna" },
    { cod_prov: "SV", descrizione: "Savona", regione: "Liguria" },
    { cod_prov: "SI", descrizione: "Siena", regione: "Toscana" },
    { cod_prov: "SR", descrizione: "Siracusa", regione: "Sicilia" },
    { cod_prov: "SO", descrizione: "Sondrio", regione: "Lombardia" },
    { cod_prov: "TA", descrizione: "Taranto", regione: "Puglia" },
    { cod_prov: "TE", descrizione: "Teramo", regione: "Abruzzo" },
    { cod_prov: "TR", descrizione: "Terni", regione: "Umbria" },
    { cod_prov: "TO", descrizione: "Torino", regione: "Piemonte" },
    { cod_prov: "TP", descrizione: "Trapani", regione: "Sicilia" },
    { cod_prov: "TN", descrizione: "Trento", regione: "Trentino Alto Adige" },
    { cod_prov: "TV", descrizione: "Treviso", regione: "Veneto" },
    { cod_prov: "TS", descrizione: "Trieste", regione: "Friuli Venezia Giulia" },
    { cod_prov: "UD", descrizione: "Udine", regione: "Friuli Venezia Giulia" },
    { cod_prov: "VA", descrizione: "Varese", regione: "Lombardia" },
    { cod_prov: "VE", descrizione: "Venezia", regione: "Veneto" },
    { cod_prov: "VB", descrizione: "Verbania", regione: "Piemonte" },
    { cod_prov: "VC", descrizione: "Vercelli", regione: "Piemonte" },
    { cod_prov: "VR", descrizione: "Verona", regione: "Veneto" },
    { cod_prov: "VV", descrizione: "Vibo-Valentia", regione: "Calabria" },
    { cod_prov: "VI", descrizione: "Vicenza", regione: "Veneto" },
    { cod_prov: "VT", descrizione: "Viterbo", regione: "Lazio" }
  ],
  baseApiForecast:"https://forecast.dentalpro.it/"
};


