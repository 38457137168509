import { Component, Inject, OnInit, AfterViewInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { AlertDialogService } from '../../services/alert-dialog.service';
import { fromEvent } from 'rxjs';
import { tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'search-product-dialog',
  templateUrl: './search-product.dialog.html',
  styleUrls: ['./search-product.dialog.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchProductDialog implements OnInit {

  @ViewChild('searchtxt', { static: false }) searchtxt: ElementRef;

  searchTxt: string = '';

  constructor(
    public dialogRef: MatDialogRef<SearchProductDialog>,
    private translateService: TranslateService,
    private alertDialogService: AlertDialogService,
  ) {

  }

  ngOnInit(): void {

  }

}
