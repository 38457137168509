import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClinichedatabaseService } from '../../services/clinichedatabase.service';
import { LoaderService } from '../../loader/loader.service';
import { AlertDialogService } from '../../services/alert-dialog.service';
import { Confirm } from "../../dialogs/confirm-dialog/confirm-dialog.component";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import * as moment from 'moment';
import { ExcelService } from '../../services/excel.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

import "moment-timezone";

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SecurityContext } from '@angular/core';

import { ClinicheDatabase } from '../../entities/datibase/clinichedatabase-model';
import { windowWhen } from 'rxjs/operators';

const tz = "Europe/Rome";
const dateFormat = "YYYY-MM-DDTHH:mm:ss";

const dateFormatDate = "YYYY-MM-DD";
const dateFormatHour = "HH:mm";

@Component({
  selector: 'target-import',
  templateUrl: './target-import.component.html',
  styleUrls: ['./target-import.component.css']
})
export class TargetImportComponent implements OnInit {
  role: string;
  isAdmin: boolean = false;
  isCA: boolean = false;
  isDC: boolean = false;
  usr: any;
  willDownload = false;

  constructor(public dialogRef: MatDialogRef<TargetImportComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private dbService: ClinichedatabaseService, private loaderService: LoaderService, private alertDialog: AlertDialogService,
    private fb: FormBuilder, private _sanitizer: DomSanitizer, private http: HttpClient) {

    this.role = data.role;
    this.usr = data.usr;

    if (this.role != null && (this.role.toLowerCase() == 'amministratore' || this.role.toLowerCase() == 'hr medici')) {
      this.isDC = false;
      this.isAdmin = true;
      this.isCA = false;
    }
    if (this.role != null && (this.role.toLowerCase() == 'direzione commerciale')) {
      this.isDC = true;
      this.isAdmin = false;
      this.isCA = false;
    }
    if (this.role != null && (this.role.toLowerCase() == 'ciclo_attivo' || this.role.toLowerCase() == 'ciclo attivo')) {
      this.isDC = false;
      this.isAdmin = false;
      this.isCA = true;
    }

  }

  parseDateExcel(excelTimestamp) {
    const secondsInDay = 24 * 60 * 60;
    const excelEpoch = new Date(1899, 11, 31);
    const excelEpochAsUnixTimestamp = excelEpoch.getTime();
    const missingLeapYearDay = secondsInDay * 1000;
    const delta = excelEpochAsUnixTimestamp - missingLeapYearDay;
    const excelTimestampAsUnixTimestamp = excelTimestamp * secondsInDay * 1000;
    const parsed = excelTimestampAsUnixTimestamp + delta;
    return isNaN(parsed) ? null : parsed;
  }

  setDownload(data) {
    this.willDownload = true;
    setTimeout(() => {
      const el = document.querySelector("#download");
      el.setAttribute("href", `data:text/json;charset=utf-8,${encodeURIComponent(data)}`);
    }, 1000)
  }

  onFileChange(ev) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    console.log('onFileChange');
    if (this.isAdmin == true || this.isDC == true) {
      if (window.confirm('Vuoi sovrascrivere i dati presenti nel file Excel con quelli nel DB?')) {
        this.loaderService.show();
        reader.onload = (event) => {
          const data = reader.result;
          workBook = XLSX.read(data, { type: 'binary' });
          jsonData = workBook.SheetNames.reduce((initial, name) => {
            const sheet = workBook.Sheets[name];
            initial[name] = XLSX.utils.sheet_to_json(sheet);
            return initial;
          }, {});
          const dataString = JSON.stringify(jsonData);
          document.getElementById('output').innerHTML = dataString.slice(0, 1000).concat("...");

          //this.setDownload(dataString);
          //var obj = JSON.parse(jsonData);
          let arrayCall = [];
          let jsonDataC = jsonData['Target'];
          let jsonDataP = jsonData['operatori'];
          console.log('jsondata %o cliniche %o operatori %o', jsonData.Target, jsonDataC, jsonDataP);
          for (var it in jsonDataC) {
            let clinica = {};
            // if (jsonDataC[it]['Codice'] == "004") {

            clinica['CodClinica'] = jsonDataC[it]['Clinica'].substr(0, 3) || '';
            clinica['Mese'] = '2022-01-31T00:00:00';
            clinica['TgCheckUp'] = jsonDataC[it]['TgtCU'] || 0;
            clinica['TgFatturato'] = jsonDataC[it]['TgtF'] || 0;
            clinica['TgProduzione'] = jsonDataC[it]['TgtP'] || 0;

            /*
                        if (!(clinica['Mese'] == null) && (typeof clinica['Mese'] === 'string' || clinica['Mese'] instanceof String)) {
                          if (clinica['Mese'].trim().toLowerCase() == 'invalid date') {
                            clinica['Mese'] = null;
                          }
                          else {
                            clinica['Mese'] = moment(clinica['Mese'], 'DD-MM-YYYY').format(dateFormat);
                          }
                        }
                        else {
                          if (!(clinica['Mese'] == null) && (typeof clinica['Mese'] === 'number' || clinica['Mese'] instanceof Number)) {
                            clinica['Mese'] = moment(new Date(this.parseDateExcel(clinica['Mese']))).format(dateFormat);
                          }
                        }
            
                        if (!(clinica['ModificaDt'] == null) && (typeof clinica['ModificaDt'] === 'string' || clinica['ModificaDt'] instanceof String)) {
                          if (clinica['ModificaDt'].trim().toLowerCase() == 'invalid date') {
                            clinica['ModificaDt'] = null;
                          }
                          else {
                            clinica['ModificaDt'] = moment(clinica['ModificaDt'], 'DD-MM-YYYY').format(dateFormat);
                          }
                        }
                        else {
                          if (!(clinica['ModificaDt'] == null) && (typeof clinica['ModificaDt'] === 'number' || clinica['ModificaDt'] instanceof Number)) {
                            clinica['ModificaDt'] = moment(new Date(this.parseDateExcel(clinica['ModificaDt']))).format(dateFormat);
                          }
                        }
            */
            console.log('ITEM %o CLINICA %o', jsonDataC[it], clinica);

            if (!(clinica['CodClinica'] == null) && clinica['CodClinica'].toString().trim().length > 0) {

              // let ret = this.http.put(`${environment.gceAPI}/incentive_target_clinica/` + clinica['RigaId'], clinica, { responseType: 'json' });
              let ret = this.http.post(`${environment.gceAPI}/update_target_f_p`, clinica, { responseType: 'json' });
              arrayCall.push(ret);
            }
            //}
          }
          let arrayCallO = [];
          /*
                    for (var it in jsonDataP) {
                      let operator = {};
          
                      operator['CodClinica'] = jsonDataP[it]['CodClinica'] || '';
                      operator['IdOperatore'] = jsonDataP[it]['IdOperatore'] || 0;
                      operator['IdProfilo'] = jsonDataP[it]['IdProfilo'] || '';
                      operator['Login'] = jsonDataP[it]['Login'] || '';
                      operator['Mese'] = jsonDataP[it]['Mese'] || new Date();
                      operator['ModificaDt'] = jsonDataP[it]['ModificaDt'] || new Date();
                      operator['RigaId'] = jsonDataP[it]['RigaId'] || 0;
          
                      operator['TgFatturatoOperatore'] = jsonDataP[it]['TgFatturatoOperatore'] || 0;
          
                      console.log('ITEM %o CLINICA %o', jsonDataP[it], operator);
          
                      if (!(operator['CodClinica'] == null) && operator['CodClinica'].toString().trim().length > 0) {
          
                        let ret = this.http.put(`${environment.gceAPI}/incentive_target_operatore/` + operator['RigaId'], operator, { responseType: 'json' });
                        arrayCallO.push(ret);
                      }
          
                    }
          */
          this.loaderService.hide();

          Observable.forkJoin(arrayCall).subscribe(results => {
            //operators
            /*
            Observable.forkJoin(arrayCallO).subscribe(results2 => {
              this.loaderService.hide();
              window.alert('Import terminato');
              this.dialogRef.close();

            },
              err => {
                this.loaderService.hide();
                window.alert('Errore nella procedura di importazione.');
                this.dialogRef.close();
              });
            */
            this.loaderService.hide();
            window.alert('Import terminato');
          }, err => {
            this.loaderService.hide();
            window.alert('Errore nella procedura di importazione.');
            this.dialogRef.close();
          });


        }
        reader.readAsBinaryString(file);
      }
    }
    else {
      window.alert('Att.ne: il tuo profilo non è abilitato per importare dati da file Excel')
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  ngOnInit() {

  }


}
