import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GceManagementService } from '../../../services/gce-management.service';
import { LoaderService } from '../../../loader/loader.service';
import { GceClinic } from '../../../entities/gce-management';
import { DataRepositoryService } from '../../../services/data-repository.service';
import { AlertDialogService } from '../../../services/alert-dialog.service';
import { Confirm } from "../../../dialogs/confirm-dialog/confirm-dialog.component";
import * as moment from "moment";
import "moment-timezone";

const tz = "Europe/Rome";

const dateFormat = "YYYY-MM-DDTHH:mm:ss";
@Component({
  selector: 'app-gce-dialog-calculate-billing',
  templateUrl: './gce-dialog-calculate-billing.component.html',
  styleUrls: ['./gce-dialog-calculate-billing.component.css']
})
export class GceDialogCalculateBillingComponent implements OnInit {
  @ViewChild('pickerData', { static: false }) pickerData: MatDatepicker<Date>;
  clinics: GceClinic[] = [];
  selectedClinic: GceClinic = null;
  selectedClinicCode: string = null;
  form: FormGroup;
  submitted: boolean = false;
  month: any;

  constructor(public dialogRef: MatDialogRef<GceDialogCalculateBillingComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private gceService: GceManagementService, private loaderService: LoaderService, private dataRepo: DataRepositoryService,
    private alertDialog: AlertDialogService, private fb: FormBuilder) {

    this.month = data.month;
    console.log('month %o', this.month);
    let cc = '';

    try {
      cc = this.dataRepo.user.desc_domain.substr(0, 3);
    }
    catch (e) {
      try {
        cc = this.dataRepo.user.desc_domain[0].substr(0, 3);
      }
      catch (e) {
      }
    }

    let clinic: GceClinic = this.gceService.getClinicByCode(cc);
    if (clinic) {
      this.clinics = [clinic];
      this.selectedClinicCode = clinic.code_c;
      this.selectedClinic = clinic;
    } else {
      this.clinics = this.gceService.dataRepoClinics;
      this.selectedClinicCode = null;
      this.selectedClinic = null;
    }
  }

  createForm() {
    this.form = new FormGroup(this.fb.group({
      cod_clinica: ['', [Validators.required]]
    }).controls, { updateOn: 'blur' });
  }

  rebuildForm() {
    this.form.patchValue({
      cod_clinica: this.selectedClinicCode
    });
  }

  ngOnInit() {
    this.createForm();
    //  this.rebuildForm();
  }

  confirm() {
    this.submitted = true;
    if (this.form.valid) {
      let formData = this.form.getRawValue();
      const confirm: Confirm = {
        title: "GCE.GCEINVOICE.DIALOG.CALCULATEBILLING.TITLE",
        message: "GCE.GCEINVOICE.DIALOG.CALCULATEBILLING.MESSAGE",
        buttonOkText: "GCE.GCEINVOICE.DIALOG.CALCULATEBILLING.BUTTON.OK",
        buttonCancelText: "GCE.GCEINVOICE.DIALOG.CALCULATEBILLING.BUTTON.CANCEL",
        buttonOkStyle: { 'background-color': '#268B26' },
        buttonCancelStyle: { 'background-color': '#AA3939' },
        withTranslate: true,
        messageParams: [
          {
            key: 'COD_CLINICA', value: formData.cod_clinica
          },
          {
            key: 'CLINICA', value: `${this.selectedClinic.nome}`
          }
        ]
      };
      this.alertDialog.confirm(confirm).subscribe(result => {
        if (result) {
          this.loaderService.show();
          this.gceService.calculateBilling(formData.cod_clinica, this.month).subscribe((d) => {
            this.loaderService.hide();
            /*
            this.alertDialog.open({
              message: 'GCE.GCEINVOICE.DIALOG.CALCULATEDBILLING.MESSAGE',
              isError: false,
              withTranslate: true
            });
            */
            this.dialogRef.close({ calculate: true });
          }, err => {
            var d = new Date(Date.now());
            this.loaderService.hide();
            this.alertDialog.open({
              message: 'GCE.GCEINVOICE.DIALOG.CALCULATEBILLING.ERROR.MESSAGE',
              isError: true,
              withTranslate: true,
              isDetail: true,
              detail: moment(d).format(dateFormat).toString() + ' - ' + JSON.stringify(err)
            });
            this.dialogRef.close({ calculate: false });
          });
        }
      });
    }

    // this.dialogRef.close({calculate:true});
  }

  cancel() {
    this.dialogRef.close({ calculate: false });
  }

  onChangeClinic(event) {
    console.log("onChangeClinic %o", event);
    let clinic: GceClinic = this.gceService.getClinicByCode(event.value);
    this.selectedClinic = clinic;
  }
}
