import { Directive, Renderer2, ElementRef, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, DefaultValueAccessor } from '@angular/forms';

const UPPERCASE_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TextUpperCaseDirective),
  multi: true,
};

@Directive({
  selector: 'input[uppercase]',
  host: {
      // When the user updates the input
      '(input)': 'onInput($event)',
      '(blur)': 'onTouched()',
    },
  providers: [
    UPPERCASE_INPUT_CONTROL_VALUE_ACCESSOR,
  ],
})
export class TextUpperCaseDirective extends DefaultValueAccessor  {
  onTouched = () => {};
  onChange = (_: any) => {};
  constructor(renderer: Renderer2, elementRef: ElementRef) {
    super(renderer, elementRef, false);
  }

  writeValue(value: any): void {
    const transformed = this.transformValue(value);

    super.writeValue(transformed);
  }

  onInput(event): void {
    // console.log("onInput %o", this);
    const transformed = this.transformValue(event.target.value);
    super.writeValue(transformed);
    this.onChange(transformed);
  }

  private transformValue(value: any): any {
    // console.log("transformValue %o", this);
    const result = value && typeof value === 'string'
      ? value.toUpperCase()
      : value;

    return result;
  }
}
