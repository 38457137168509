import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '../../shared/datatable/datatable.component';
import { FormHeaderComponent, ICustomButton, IFormHeaderSearch, FormHeaderSearchEventsEnum } from '../../shared/form-header/form-header.component';
import { GceInvoice, GceInvoicePerson, GceClinic, GceLookUpOptions, GceInvoicePersonCompensation } from '../../entities/gce-management';
import { MatDialog } from '@angular/material';
import { GceManagementService } from '../../services/gce-management.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertDialogService } from '../../services/alert-dialog.service';
import { Confirm } from "../../dialogs/confirm-dialog/confirm-dialog.component";
import { FormComponent } from '../../shared/form/form.component';
import { Alert } from '../../dialogs/alert-dialog/alert-dialog.component';
import * as moment from 'moment';
import { GceDialogInvoiceFormComponent } from '../dialogs/gce-dialog-invoice-form/gce-dialog-invoice-form.component';
import { GceDialogCalculateBillingComponent } from '../dialogs/gce-dialog-calculate-billing/gce-dialog-calculate-billing.component';
import { PermissionService } from '../../services/permission.service';
import { GcePerformanceCalculationDetail } from '../../entities/gce-management/gce-performance-calculation-detail';
import { GcePerformanceCalculationDetracts } from '../../entities/gce-management/gce-performance-calculation-detracts';
import { GceDialogPerformanceCalculationDetailComponent } from '../dialogs/gce-dialog-performance-calculation-detail/gce-dialog-performance-calculation-detail.component';
import { AuthGuardService } from '../../services';
import { show } from '../../entities/gce-management/gce-management';
import { IConditionalFormatting } from '../../directives/conditional-formatting.directive';
import { LoaderService } from '../../loader/loader.service';
const dateFormatDate = "YYYY-MM-DD";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-gce-invoices',
  templateUrl: './gce-invoices.component.html',
  styleUrls: ['./gce-invoices.component.css']
})
export class GceInvoicesComponent implements OnInit {
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild('formHeader', { static: false }) formHeader: FormHeaderComponent;
  @ViewChild('formData', { static: false }) formData: FormComponent;
  columns: any[] = [];
  medics: any[] = [];
  invoices: GceInvoice[] = [];
  invoicesBkp: GceInvoice[] = [];
  limitRows: number;
  heightMainList: number;
  currentRow: any;
  newRow: any;
  editing: boolean = false;
  keyColumn: any = {};
  customButtons: ICustomButton[] = [];
  openRightPanel: boolean = false;
  role: string = '';
  dm: string = '';
  usr: string = '';
  fromDate: string = this.gceService.dataRepoGceEnvironment.getFromDate();
  toDate: string = this.gceService.dataRepoGceEnvironment.getToDate();
  showb: boolean = false;
  writePermission: boolean;
  nerr: number = 0;
  nop: number = 0;
  isAdmin: boolean = false;
  invoice: GceInvoice = null;
  conditionalFormattingConfig: IConditionalFormatting;

  details: GcePerformanceCalculationDetail[];
  detracts: GcePerformanceCalculationDetracts[];

  t1: number = 0;
  t2: number = 0;
  t3: number = 0;
  t4: number = 0;
  t5: number = 0;
  t6: number = 0;
  t7: number = 0;

  t11_1: number = null;
  t11_2: number = null;
  t11_3: number = null;
  t12_1: number = null;
  t12_2: number = null;
  t12_3: number = null;
  t13_t: number = null;

  t14_1: number = null;
  t14_2: number = null;
  t14_3: number = null;
  t15_1: number = null;
  t15_2: number = null;
  t15_3: number = null;
  t16_1: number = null;
  t16_2: number = null;
  t16_3: number = null;
  t17_1: number = null;
  t17_2: number = null;
  t17_3: number = null;
  t18_1: number = null;
  t18_2: number = null;
  t18_3: number = null;
  t18A_2: number = null;
  t18A_3: number = null;
  t19_1: number = null;
  t19_2: number = null;
  t19_3: number = null;

  t20_3: number = null;
  t21_3: number = null;
  t110_t: number = null;

  constructor(private dialog: MatDialog, private gceService: GceManagementService,
    private translateService: TranslateService,
    private alertDialog: AlertDialogService, private auth: AuthGuardService, private loaderService: LoaderService, private http: HttpClient) {
    this.conditionalFormattingConfig = {
      formulas: [{
        field: 'flg_doc_emesso',
        rule: '== true',
        classes: ['row-touched']
      }]
    };

    this.writePermission = PermissionService.checkPermission("CGE_EFTT");
    if (PermissionService.checkPermission("CGE_CFTT")) {
      this.customButtons.push({
        matTooltipCode: 'GCE.GCEINVOICE.BUTTONS.CALCULATEBILLING.TOOLTIP',
        labelCode: 'GCE.GCEINVOICE.BUTTONS.CALCULATEBILLING.LABEL',
        img: 'assets/img/invoice.png',
        class: 'custom-button',
        clickFunction: this.openDialogCalculateBilling.bind(this)
      });
    }
    this.customButtons.push({
      matTooltipCode: 'GCE.GCEINVOICEPERSON.BUTTONS.EXPORTTOEXCEL.REPORTPRESTAIONITOOLTIP',
      labelCode: 'GCE.GCEINVOICEPERSON.BUTTONS.EXPORTTOEXCEL.REPORTPRESTAIONITOOLTIP',
      img: 'assets/img/excel.png',
      class: 'custom-button',
      clickFunction: this.simpleExportToExcel.bind(this)
    });
    this.customButtons.push({
      matTooltipCode: 'GCE.GCEINVOICEPERSON.BUTTONS.EXPORTTOEXCEL.MONTHREPORT',
      labelCode: 'GCE.GCEINVOICEPERSON.BUTTONS.EXPORTTOEXCEL.MONTHREPORT',
      img: 'assets/img/excel2.png',
      class: 'custom-button',
      clickFunction: this.exportAllToExcel.bind(this)
    });
  }

  simpleExportToExcel() {
    console.log('export to excel %o', this.currentRow);
    if (!(this.currentRow == null) && this.currentRow.idfatturazione > 0) {
      this.gceService.getPerformanceCalculationDetailGeneric(this.currentRow.idfatturazione, this.currentRow.mese, this.currentRow.cod_clinica).subscribe(ret => {
        this.gceService.simpleExportInvoiceDetailToExcel(this.currentRow, ret['details'], ret['detracts']);
      }, err => {
        this.showb = false;
        window.alert('Errore nel recupero delle informazioni di dettaglio per la fattura selezionata.');
      });
    }
    else {
      window.alert('Selezionare una liquidazione per esportare il report medico semplificato.');
    }
  }

  exportAllToExcel() {
    console.log('export All to excel %o', this.invoices);
    this.gceService.exportAllToExcel(this.invoices);
  }

  calcValuesForReport() {
    this.t1 = 0;
    this.t2 = 0;
    this.t3 = 0;
    this.t4 = 0;
    this.t5 = 0;
    this.t6 = 0;
    this.t7 = 0;

    this.t11_1 = null;
    this.t11_2 = null;
    this.t11_3 = null;
    this.t12_1 = null;
    this.t12_2 = null;
    this.t12_3 = null;
    this.t13_t = null;

    this.t14_1 = null;
    this.t14_2 = null;
    this.t14_3 = null;
    this.t15_1 = null;
    this.t15_2 = null;
    this.t15_3 = null;
    this.t16_1 = null;
    this.t16_2 = null;
    this.t16_3 = null;
    this.t17_1 = null;
    this.t17_2 = null;
    this.t17_3 = null;
    this.t18_1 = null;
    this.t18_2 = null;
    this.t18_3 = null;
    this.t18A_2 = null;
    this.t18A_3 = null;
    this.t19_1 = null;
    this.t19_2 = null;
    this.t19_3 = null;

    this.t20_3 = null;
    this.t21_3 = null;
    this.t110_t = null;


    let hrm = false;
    if (!(this.invoice.importo_gettone_calc == null))
      this.invoice.importo_gettone_calc = Math.round(this.invoice.importo_gettone_calc * 100) / 100 || 0;
    if (!(this.invoice.importo_gettone_manuale == null))
      this.invoice.importo_gettone_manuale = Math.round(this.invoice.importo_gettone_manuale * 100) / 100 || 0;
    if (!(this.invoice.importo_direzione_calc == null))
      this.invoice.importo_direzione_calc = Math.round(this.invoice.importo_direzione_calc * 100) / 100 || 0;
    if (!(this.invoice.importo_direzione_manuale == null))
      this.invoice.importo_direzione_manuale = Math.round(this.invoice.importo_direzione_manuale * 100) / 100 || 0;
    if (!(this.invoice.importo_fisso_extra_calc == null))
      this.invoice.importo_fisso_extra_calc = Math.round(this.invoice.importo_fisso_extra_calc * 100) / 100 || 0;
    if (!(this.invoice.importo_prestazioni_calc == null))
      this.invoice.importo_prestazioni_calc = Math.round(this.invoice.importo_prestazioni_calc * 100) / 100 || 0;

    if (!(this.invoice.importo_integr_minimo_calc == null))
      this.invoice.importo_integr_minimo_calc = Math.round(this.invoice.importo_integr_minimo_calc * 100) / 100 || 0;
    if (!(this.invoice.importo_prestazioni_manuale == null))
      this.invoice.importo_prestazioni_manuale = Math.round(this.invoice.importo_prestazioni_manuale * 100) / 100 || 0;
    if (!(this.invoice.importo_riporto_mese_succ == null))
      this.invoice.importo_riporto_mese_succ = Math.round(this.invoice.importo_riporto_mese_succ * 100) / 100 || 0;

    if (!(this.invoice.importo_riporto_mese_prec == null))
      this.invoice.importo_riporto_mese_prec = Math.round(this.invoice.importo_riporto_mese_prec * 100) / 100 || 0;
    if (!(this.invoice.tot_fattura_prestazione == null))
      this.invoice.tot_fattura_prestazione = Math.round(this.invoice.tot_fattura_prestazione * 100) / 100 || 0;
    if (!(this.invoice.importo_prest_mediche_manuale == null))
      this.invoice.importo_prest_mediche_manuale = Math.round(this.invoice.importo_prest_mediche_manuale * 100) / 100 || 0;
    if (!(this.invoice.importo_prest_anastesista_manuale == null))
      this.invoice.importo_prest_anastesista_manuale = Math.round(this.invoice.importo_prest_anastesista_manuale * 100) / 100 || 0;

    if (!(this.invoice.importo_gettone_manuale_hr == null))
      this.invoice.importo_gettone_manuale_hr = Math.round(this.invoice.importo_gettone_manuale_hr * 100) / 100 || 0;
    if (!(this.invoice.importo_direzione_manuale_hr == null))
      this.invoice.importo_direzione_manuale_hr = Math.round(this.invoice.importo_direzione_manuale_hr * 100) / 100 || 0;
    if (!(this.invoice.importo_prestazioni_manuale_hr == null))
      this.invoice.importo_prestazioni_manuale_hr = Math.round(this.invoice.importo_prestazioni_manuale_hr * 100) / 100 || 0;

    if (!(this.invoice.importo_direzione__area_manuale_hr == null))
      this.invoice.importo_direzione__area_manuale_hr = Math.round(this.invoice.importo_direzione__area_manuale_hr * 100) / 100 || 0;
    if (!(this.invoice.importo_gettone_unitario_hr == null))
      this.invoice.importo_gettone_unitario_hr = Math.round(this.invoice.importo_gettone_unitario_hr * 100) / 100 || 0;
    if (!(this.invoice.importo_prest_mediche_manuale_hr == null))
      this.invoice.importo_prest_mediche_manuale_hr = Math.round(this.invoice.importo_prest_mediche_manuale_hr * 100) / 100 || 0;
    if (!(this.invoice.importo_prest_anastesista_manuale_hr == null))
      this.invoice.importo_prest_anastesista_manuale_hr = Math.round(this.invoice.importo_prest_anastesista_manuale_hr * 100) / 100 || 0;

    if (this.invoice.nota_riporto_mese_succ != null) {

      this.invoice.nota_riporto_mese_succ = this.invoice.nota_riporto_mese_succ.replace(/&#10;/g, '\n').replace(/&#9;/g, '\t').replace(/&#8364;/g, '€').replace(/&#34;/g, '"').replace(/&#232;/g, 'e\'').replace(/&#242;/g, 'o\'').replace(/&#224;/g, 'a\'').replace(/&#249;/g, 'u\'').replace(/&#236;/g, 'i\'');

    }

    if (this.invoice.note != null) {

      this.invoice.note = this.invoice.note.replace(/&#10;/g, '\n').replace(/&#9;/g, '\t').replace(/&#8364;/g, '€').replace(/&#34;/g, '"').replace(/&#232;/g, 'e\'').replace(/&#242;/g, 'o\'').replace(/&#224;/g, 'a\'').replace(/&#249;/g, 'u\'').replace(/&#236;/g, 'i\'');

    }

    if (this.invoice.note_hr != null) {

      this.invoice.note_hr = this.invoice.note_hr.replace(/&#10;/g, '\n').replace(/&#9;/g, '\t').replace(/&#8364;/g, '€').replace(/&#34;/g, '"').replace(/&#232;/g, 'e\'').replace(/&#242;/g, 'o\'').replace(/&#224;/g, 'a\'').replace(/&#249;/g, 'u\'').replace(/&#236;/g, 'i\'');

    }

    for (let a = 0; a < this.detracts.length; a = a + 1) {
      if (this.detracts[a]['importo_addebito_medico'] != null) {
        this.detracts[a]['importo_addebito_medico'] = Math.round(this.detracts[a]['importo_addebito_medico'] * 100) / 100;
      }
      if (this.detracts[a]['perc_medico'] != null) {
        this.detracts[a]['perc_medico'] = Math.round(this.detracts[a]['perc_medico'] * 100) / 100;
      }
      if (this.detracts[a]['importo_ordine'] != null) {
        this.detracts[a]['importo_ordine'] = Math.round(this.detracts[a]['importo_ordine'] * 100) / 100;
      }
    }

    console.log('invoice %o', this.invoice);
    if (this.invoice.gg_disponibilta_calc > 0)
      this.t1 = this.invoice.gg_disponibilta_calc;
    if (this.invoice.gg_agenda_calc > 0)
      this.t2 = this.invoice.gg_agenda_calc;
    if (this.invoice.gg_gettone_calc > 0)
      this.t3 = this.invoice.gg_gettone_calc;
    //GETTONI MAN
    if (this.invoice.gg_gettone_manuale != null)
      this.t3 = this.invoice.gg_gettone_manuale;
    //GETTONI HR
    if (this.invoice.gg_gettone_manuale_hr != null)
      this.t3 = this.invoice.gg_gettone_manuale_hr;
    if (this.invoice.importo_gettone_unitario != null && this.invoice.gg_gettone_calc != null)
      this.t14_1 = this.invoice.importo_gettone_unitario * this.invoice.gg_gettone_calc;
    if (this.invoice.importo_gettone_unitario != null && this.invoice.gg_gettone_manuale != null) {
      this.t14_2 = this.invoice.importo_gettone_unitario * this.invoice.gg_gettone_manuale;
    }
    if (this.invoice.importo_gettone_unitario != null && this.invoice.gg_gettone_manuale_hr != null) {
      this.t14_3 = this.invoice.importo_gettone_unitario * this.invoice.gg_gettone_manuale_hr;
    }
    if (this.invoice.gg_gettone_calc == 0 || this.invoice.importo_gettone_unitario == 0)
      this.t14_1 = 0;
    if (this.invoice.gg_gettone_manuale == 0 || this.invoice.importo_gettone_unitario == 0)
      this.t14_2 = 0;
    if (this.invoice.gg_gettone_manuale_hr == 0 || this.invoice.importo_gettone_unitario == 0)
      this.t14_3 = 0;

    if (this.invoice.importo_fisso_extra_calc > 0)
      this.t15_1 = this.invoice.importo_fisso_extra_calc;

    if (this.invoice.importo_integr_minimo_calc > 0)
      this.t19_1 = this.invoice.importo_integr_minimo_calc;

    if (this.invoice.importo_prestazioni_manuale > 0)
      this.t16_2 = this.invoice.importo_prestazioni_manuale;
    if (this.invoice.importo_prestazioni_manuale < 0)
      this.t16_2 = this.invoice.importo_prestazioni_manuale;

    if (this.invoice.importo_prest_mediche_manuale > 0)
      this.t16_2 = this.t16_2 + this.invoice.importo_prest_mediche_manuale;
    if (this.invoice.importo_prest_mediche_manuale < 0)
      this.t16_2 = this.t16_2 + this.invoice.importo_prest_mediche_manuale;
    if (this.invoice.importo_prest_anastesista_manuale > 0)
      this.t16_2 = this.t16_2 + this.invoice.importo_prest_anastesista_manuale;
    if (this.invoice.importo_prest_anastesista_manuale < 0)
      this.t16_2 = this.t16_2 + this.invoice.importo_prest_anastesista_manuale;

    if (this.invoice.importo_prestazioni_manuale_hr > 0)
      this.t16_3 = this.invoice.importo_prestazioni_manuale_hr;
    if (this.invoice.importo_prestazioni_manuale_hr < 0)
      this.t16_3 = this.invoice.importo_prestazioni_manuale_hr;

    if (this.invoice.importo_prest_mediche_manuale_hr > 0)
      this.t16_3 = this.t16_3 + this.invoice.importo_prest_mediche_manuale_hr;
    if (this.invoice.importo_prest_mediche_manuale_hr < 0)
      this.t16_3 = this.t16_3 + this.invoice.importo_prest_mediche_manuale_hr;
    if (this.invoice.importo_prest_anastesista_manuale_hr > 0)
      this.t16_3 = this.t16_3 + this.invoice.importo_prest_anastesista_manuale_hr;
    if (this.invoice.importo_prest_anastesista_manuale_hr < 0)
      this.t16_3 = this.t16_3 + this.invoice.importo_prest_anastesista_manuale_hr;

    if (this.invoice.importo_direzione_calc > 0)
      this.t11_1 = this.invoice.importo_direzione_calc;
    if (this.invoice.importo_direzione_manuale > 0)
      this.t11_2 = this.invoice.importo_direzione_manuale;
    if (this.invoice.importo_direzione_manuale_hr > 0)
      this.t11_3 = this.invoice.importo_direzione_manuale_hr;

    if (this.invoice.importo_direzione__area_calc > 0)
      this.t12_1 = this.invoice.importo_direzione__area_calc;
    if (this.invoice.importo_direzione__area_manuale > 0)
      this.t12_2 = this.invoice.importo_direzione__area_manuale;
    if (this.invoice.importo_direzione__area_manuale_hr > 0)
      this.t12_3 = this.invoice.importo_direzione__area_manuale_hr;

    let d11 = null;
    if (!(this.t11_1 == null))
      d11 = this.t11_1;
    if (!(this.t11_2 == null))
      d11 = this.t11_2;
    if (!(this.t11_3 == null))
      d11 = this.t11_3;

    let d12 = null;

    if (!(this.t12_1 == null))
      d12 = this.t12_1;
    if (!(this.t12_2 == null)) {
      d12 = this.t12_2;

    }
    if (!(this.t12_3 == null)) {
      d12 = this.t12_3;

    }
    this.t13_t = d11 + d12;

    for (let a = 0; a < this.details.length; a = a + 1) {
      console.log('DT %o imp %o', this.details[a], this.details[a]['importo_prest_base_calc_medico']);
      if (this.details[a]['importo_prest_base_calc_medico'] > 0) {
        //this.t4 = this.t4 + this.details[a]['importo_prest_base_calc_medico'];
        this.t5 = this.t5 + this.details[a]['importo_prest_base_calc_medico'];
      }

      /*
      if (this.details[a]['compenso_medico'] > 0) {
        this.t16_1 = this.t16_1 + this.details[a]['compenso_medico'];
      }


      if (this.details[a]['compenso_medico'] < 0) {
        this.t17_1 = this.t17_1 + this.details[a]['compenso_medico'];
      }
      */

      if (this.details[a]['compenso_medico'] > 0 || this.details[a]['compenso_medico'] < 0) {
        this.t16_1 = this.t16_1 + this.details[a]['compenso_medico'];
      }

      if (this.details[a]['importo_prest_lordo'] > 0) {
        //this.t4 = this.t4 + this.details[a]['importo_prest_base_calc_medico'];
        this.t4 = this.t4 + this.details[a]['importo_prest_lordo'];
      }

      if (this.details[a]['importo_prest_base_calc_medico'] < 0) {
        this.t6 = this.t6 + this.details[a]['importo_prest_base_calc_medico'];
        //Warning

      }

    }

    if (this.invoice.importo_addebiti_lab_calc > 0 && (!(this.t18_1 == 0) || this.invoice.importo_addebiti_lab_calc > this.t18_1)) {
      this.t18_1 = this.invoice.importo_addebiti_lab_calc;
    }
    let dinvisaling = 0;
    if (this.invoice.importo_detrazioni_invisalign_manuale > 0 || this.invoice.importo_detrazioni_invisalign_manuale < 0) {
      this.t18A_2 = this.invoice.importo_detrazioni_invisalign_manuale;
      if (this.t18A_2 > 0)
        this.t18A_2 = this.t18A_2 * -1;
      dinvisaling = this.t18A_2;
    }

    if ((this.invoice.importo_addebiti_lab_manuale_hr > 0 || this.invoice.importo_addebiti_lab_manuale_hr == 0) && (!(this.t18_3 == 0) || this.invoice.importo_addebiti_lab_manuale_hr > this.t18_3)) {
      this.t18_3 = this.invoice.importo_addebiti_lab_manuale_hr;
    }

    if (this.invoice.importo_detrazioni_invisalign_manuale_hr > 0 || this.invoice.importo_detrazioni_invisalign_manuale_hr < 0) {
      this.t18A_3 = this.invoice.importo_detrazioni_invisalign_manuale_hr;
      if (this.t18A_3 > 0)
        this.t18A_3 = this.t18A_3 * -1;
      dinvisaling = this.t18A_3;
    }

    if (this.t18_1 > 0)
      this.t18_1 = this.t18_1 * -1;
    if (this.t18_2 > 0)
      this.t18_2 = this.t18_2 * -1;
    if (this.t18_3 > 0)
      this.t18_3 = this.t18_3 * -1;

    let p14 = null;
    if (!(this.t14_1 == null))
      p14 = this.t14_1;
    if (!(this.t14_2 == null))
      p14 = this.t14_2;
    if (!(this.t14_3 == null))
      p14 = this.t14_3;

    let p15 = null;

    if (!(this.t15_1 == null))
      p15 = this.t15_1;
    if (!(this.t15_2 == null)) {
      p15 = this.t15_2;

    }
    if (!(this.t15_3 == null)) {
      p15 = this.t15_3;

    }
    let p16 = null;
    if (!(this.t16_1 == null))
      p16 = this.t16_1;
    if (!(this.t16_2 == null)) {
      p16 = this.t16_2;
      hrm = true;
    }
    if (!(this.t16_3 == null)) {
      p16 = this.t16_3;
      hrm = true;
    }
    let p17 = null;
    if (!(this.t17_1 == null))
      p17 = this.t17_1;
    if (!(this.t17_2 == null))
      p17 = this.t17_2;
    if (!(this.t17_3 == null))
      p17 = this.t17_3;

    let p18 = null;
    if (!(this.t18_1 == null))
      p18 = this.t18_1;
    if (!(this.t18_2 == null))
      p18 = this.t18_2;
    if (!(this.t18_3 == null))
      p18 = this.t18_3;

    let p19 = null;
    if (!(this.t19_1 == null))
      p19 = this.t19_1;
    if (!(this.t19_2 == null))
      p19 = this.t19_2;
    if (!(this.t19_3 == null))
      p19 = this.t19_3;

    if (hrm == false)
      this.t110_t = p14 + p15 + p16 + p17 + p18 + p19 + dinvisaling;
    else
      this.t110_t = p14 + p15 + p16 + p18 + p19 + dinvisaling;

    for (let a = 0; a < this.detracts.length; a = a + 1) {
      if (this.detracts[a].importo_ordine > 0)
        this.t7 = this.t7 + this.detracts[a].importo_ordine;
    }
    //importi riporti
    if (this.invoice.importo_riporto_mese_prec > 0 || this.invoice.importo_riporto_mese_prec < 0) {
      this.t20_3 = this.invoice.importo_riporto_mese_prec;
      this.t110_t = this.t110_t + this.t20_3;
    }
    if (this.invoice.importo_riporto_mese_succ > 0 || this.invoice.importo_riporto_mese_succ < 0)
      this.t21_3 = this.invoice.importo_riporto_mese_succ;

  }

  ngOnInit() {
    this.limitRows = Math.floor((window.innerHeight - 269) / 31); //269 340
    this.heightMainList = window.innerHeight - 188; //188 259
    this.columns = GceInvoice.getStructure();
    this.showb = true;
    this.invoicesBkp = [];

    let usr = this.auth.getUser();
    if (usr != null) {
      this.role = usr['ruolo'];
      this.dm = usr['clinica'];
      if (usr['nomeCompleto'] != null && usr['nomeCompleto'].length > 0) {
        this.usr = usr['nomeCompleto'];
      }
      else {
        if (usr['email'] != null && usr['email'].length > 0) {
          this.usr = usr['email'];
        }
      }
    }
    if (this.role != null && (this.role.toLowerCase() == 'amministratore' || this.role.toLowerCase() == 'hr medici')) {
      this.isAdmin = true;
    }
    console.log('USER %o', usr);
    this.gceService.loadDataForInvoices(this.fromDate, this.toDate).subscribe((dataResult: { clinics: GceClinic[], persons: GceInvoicePerson[], invoices: GceInvoice[] }) => {
      //manage column based on logged profile

      this.columns.forEach(column => {

        if (column.primaryKey) {
          this.keyColumn = column;
        }
        if (column.reference) {
          let lookUpOptions: GceLookUpOptions;
          if (column.reference.entity == "GceInvoicePerson") {
            lookUpOptions = this.gceService.getLookUpAndOptions(this.gceService.dataRepoInvoicePersons, column.reference);
          } else if (column.reference.entity == "GceClinic") {
            lookUpOptions = this.gceService.getLookUpAndOptions(this.gceService.dataRepoClinics, column.reference);
          }
          if (lookUpOptions) {
            column.lookUp = lookUpOptions.lookUp;
            column.options = lookUpOptions.options;
          }
        }
      });
      let colcp = [];
      this.columns.forEach(column => {
        let vis = false;
        if (column.roles != null) {
          column.roles.forEach(r => {
            if (r.toLowerCase() == this.role.toLowerCase())
              vis = true;
          });
        }
        else {
          vis = true;
        }
        if (vis == true) {
          colcp.push(column);
        }
      });

      this.columns = colcp;
      console.log("columns %o", this.columns);
      this.invoices = dataResult.invoices;
      //san text
      for (let a = 0; a < this.invoices.length; a = a + 1) {
        if (!(this.invoices[a].note == null) && this.invoices[a].note.length > 0)
          this.invoices[a].note = this.invoices[a].note.replace(/&#10;/g, '\n').replace(/&#9;/g, '\t').replace(/&#8364;/g, '€').replace(/&#34;/g, '"').replace(/&#232;/g, 'e\'').replace(/&#242;/g, 'o\'').replace(/&#224;/g, 'a\'').replace(/&#249;/g, 'u\'').replace(/&#236;/g, 'i\'');
        if (!(this.invoices[a].note_hr == null) && this.invoices[a].note_hr.length > 0)
          this.invoices[a].note_hr = this.invoices[a].note_hr.replace(/&#10;/g, '\n').replace(/&#9;/g, '\t').replace(/&#8364;/g, '€').replace(/&#34;/g, '"').replace(/&#232;/g, 'e\'').replace(/&#242;/g, 'o\'').replace(/&#224;/g, 'a\'').replace(/&#249;/g, 'u\'').replace(/&#236;/g, 'i\'');
        if (!(this.invoices[a].nota_riporto_mese_succ == null) && this.invoices[a].nota_riporto_mese_succ.length > 0)
          this.invoices[a].nota_riporto_mese_succ = this.invoices[a].nota_riporto_mese_succ.replace(/&#10;/g, '\n').replace(/&#9;/g, '\t').replace(/&#8364;/g, '€').replace(/&#34;/g, '"').replace(/&#232;/g, 'e\'').replace(/&#242;/g, 'o\'').replace(/&#224;/g, 'a\'').replace(/&#249;/g, 'u\'').replace(/&#236;/g, 'i\'');
      }

      this.invoicesBkp = [...this.invoices];
      this.showb = false;
      //get medics
      this.gceService.getAllInvoicePersons().subscribe(m => { this.medics = m });
    }, err => {
      this.invoicesBkp = [];
      this.showb = false;
    });
  }

  onEdit() {
    this.openDialogInvoiceForm(this.currentRow);
  }

  onSave() {
    console.log('onSave');
  }

  onDelete() {
    console.log('onDelete');
  }

  onSearch(searchFilter: IFormHeaderSearch) {

    if (searchFilter.event === FormHeaderSearchEventsEnum.INPUT_CHANGE) {
      let filterValues = searchFilter.filter.split(" ").filter(a => !!a);
      console.log("onSearch %o filterValue %o", searchFilter, filterValues);

      this.invoices = this.invoicesBkp.filter((item: GceInvoice) => {
        for (let key in item) {
          //console.log('Key %o item %o', key, item[key]);
          if (!!item[key]) {
            /*
            if (!!(item[key] as GceInvoicePerson).idfatturazione) {
              //??

              if ((<GceInvoicePerson>item[key]).descrizione.split(" ").filter(val => {
                return filterValues.filter(fv => val.toLowerCase().indexOf(fv.toLowerCase()) != -1).length > 0;
              }).length >= filterValues.length) {
                return true;
              }
            }
            if (!!(item[key] as GceClinic).code_c) { //??
             
              if ((<GceClinic>item[key]).legalentity.split(" ").filter(val => {
                return filterValues.filter(fv => val.toLowerCase().indexOf(fv.toLowerCase()) != -1).length > 0;
              }).length >= filterValues.length) {
                return true;
              }
            } else {
              if (item[key] !== null && item[key] !== undefined && item[key].toString().toLowerCase().indexOf(searchFilter.filter) != -1) {
                console.log('FIND ?');
                return true;
              }
            }
            */

            if (!!(item[key] as GceInvoicePerson).idfatturazione) {
              for (let key2 in item[key]) {
                if (key2 == 'descrizione') {

                  if (item[key][key2] != null && item[key][key2].toString().toLowerCase().indexOf(searchFilter.filter) >= 0) {
                    console.log('GceInvoicePerson key2 %o item %o value %o', key2, item[key][key2].toString().toLowerCase(), searchFilter.filter);

                    return true;
                  }
                }
              }
            }
            if (!!(item[key] as GceClinic).code_c) {

              for (let key2 in item[key]) {

                if (key2 == 'code_c' || key2 == 'nome') {

                  if (item[key][key2] != null && item[key][key2].toString().toLowerCase().indexOf(searchFilter.filter) >= 0) {
                    console.log('GceClinic key2 %o item %o value %o', key2, item[key][key2].toString().toLowerCase(), searchFilter.filter);
                    return true;
                  }
                }
              }
            }

            if (key == 'id') {
              if (item[key].toString().toLowerCase().indexOf(searchFilter.filter) >= 0)
                return true;
            }
            //if (item[key] != null && item[key] != undefined && item[key].toString().toLowerCase().indexOf(searchFilter.filter) >= 0) {
            //console.log('FIND %o', item);
            //return true;
            //}
          }
        }
        return false;
      });
    } else {
      if (searchFilter.event === FormHeaderSearchEventsEnum.FROM_CHANGE) {
        let mfd = moment(searchFilter.fromDate);
        let mtd = moment(searchFilter.toDate);
        this.fromDate = mfd.format(dateFormatDate);
        if (mtd.diff(mfd, 'days') > mfd.daysInMonth() || mtd.isBefore(mfd)) {
          this.toDate = mfd.endOf('month').format(dateFormatDate);
        }
      }
      if (searchFilter.event === FormHeaderSearchEventsEnum.TO_CHANGE) {
        let mfd = moment(searchFilter.fromDate);
        let mtd = moment(searchFilter.toDate);
        this.toDate = mtd.format(dateFormatDate);
        if (mtd.diff(mfd, 'days') > mtd.daysInMonth() || mtd.isBefore(mfd)) {
          this.fromDate = mtd.startOf('month').format(dateFormatDate);
        }
      }
      if (this.fromDate && this.toDate) {
        //console.log("search %o %o", this.fromDate, this.toDate);
        this.gceService.getInvoicesFromToGeneric(this.fromDate, this.toDate).subscribe((invoices: GceInvoice[]) => {
          this.invoices = invoices;
          this.invoicesBkp = [...this.invoices];
        });
      }
    }

    this.table.offset = 0;
  }

  onToggle(selectedColumns) {
    console.log("onToggle %o %o", selectedColumns);
    this.columns.map(item => {
      item.show = selectedColumns.indexOf(item.columnDef) != -1;
    });
  }

  onChangeRow(row) {
    console.log("onChangeRow %o", row);
    this.currentRow = row;
    this.editing = false;
  }

  onRestore(event) {
    console.log("onRestore %o", event);
    this.clear();
  }

  onAction(event) {
    console.log("onAction %o", event);
  }

  onCloseForm(event) {
    console.log("onCloseForm %o", event);
    this.clear();
  }

  onOpenDialog(args) {
    console.log("onOpenDialog %o", args);

  }

  onCustomActionClick(event) {
    console.log("onCustomActionClick %o", event);
    if (this[event.field.callback]) {
      this[event.field.callback](event.row);
    }
  }

  filterClinics(dm: any, clinics: any) {
    let mydm = dm.substr(0, 3);
    console.log('filter clinics %o with dm %o', clinics, mydm);
    let ret = [];

    for (let a = 0; a < clinics.length; a = a + 1) {
      if (clinics[a].code_c == mydm)
        ret.push(clinics[a]);
    }
    return ret;
  }


  onNew() {
    console.log('ON new %o with from %o to %o', this.gceService, this.fromDate, this.toDate);
    if (this.currentRow != null) {
      var d = new Date(Date.now());
      this.openRightPanel = true;
      let inv = {};
      if (this.role.toLowerCase().indexOf("amministratore") >= 0 || this.role.toLowerCase().indexOf("hr medici") >= 0) {
        inv = {
          clinic: this.currentRow.clinic,
          person: this.currentRow.person,
          id: 0,
          mese: this.currentRow.mese,
          idfatturazione: this.currentRow.idfatturazione,
          cod_clinica: this.currentRow.cod_clinica,
          tipo_record: 1,
          ts_calcolo: null,
          flg_conferma_clinica: false,
          ts_conferma_clinica: null,
          user_conferma_clinica: '',
          flg_conferma_hr: true,
          user_conferma_hr: this.usr,
          ts_conferma_hr: moment(d).format('YYYY-MM-DD'),
          flg_doc_emesso: false,
          ts_doc_emesso: null,
          flg_gettone: false,
          gg_disponibilta_calc: null,
          gg_agenda_calc: null,
          gg_gettone_calc: null,
          gg_gettone_manuale: null,
          importo_gettone_calc: null,
          importo_gettone_manuale: null,
          importo_direzione_calc: null,
          importo_direzione_manuale: null,
          importo_fisso_extra_calc: null,
          importo_prestazioni_calc: null,
          importo_integr_minimo_calc: null,
          importo_prestazioni_manuale: null,
          importo_riporto_mese_succ: null,
          nota_riporto_mese_succ: '',
          importo_riporto_mese_prec: null,
          tot_fattura_prestazione: null,
          note: '',
          flg_scarto_doc: false,
          msg_scarto: '',
          richiesta_annullo_nav: null,
          flg_annullo_nav: null,
          utente_richiesta_annullo_nav: '',
          ts_annullo_nav: null,
          importo_prest_mediche_manuale: null,
          importo_prest_anastesista_manuale: null,
          note_hr: '',
          gg_gettone_manuale_hr: null,
          importo_gettone_manuale_hr: null,
          importo_direzione_manuale_hr: null,
          importo_prestazioni_manuale_hr: null,
          importo_direzione__area_manuale_hr: null,
          importo_direzione__area_calc: null,
          importo_direzione__area_manuale: null,
          importo_gettone_unitario_hr: null,
          importo_prest_mediche_manuale_hr: null,
          importo_prest_anastesista_manuale_hr: null,
          importo_addebiti_lab_calc: null,
          importo_addebiti_lab_manuale_hr: null
        };
      }
      else {
        inv = {
          clinic: this.currentRow.clinic,
          person: this.currentRow.person,
          id: 0,
          mese: this.currentRow.mese,
          idfatturazione: this.currentRow.idfatturazione,
          cod_clinica: this.currentRow.cod_clinica,
          tipo_record: 1,
          ts_calcolo: null,
          flg_conferma_clinica: true,
          ts_conferma_clinica: moment(d).format('YYYY-MM-DD'),
          user_conferma_clinica: this.usr,
          flg_conferma_hr: false,
          user_conferma_hr: null,
          ts_conferma_hr: null,
          flg_doc_emesso: false,
          ts_doc_emesso: null,
          flg_gettone: false,
          gg_disponibilta_calc: null,
          gg_agenda_calc: null,
          gg_gettone_calc: null,
          gg_gettone_manuale: null,
          importo_gettone_calc: null,
          importo_gettone_manuale: null,
          importo_direzione_calc: null,
          importo_direzione_manuale: null,
          importo_fisso_extra_calc: null,
          importo_prestazioni_calc: null,
          importo_integr_minimo_calc: null,
          importo_prestazioni_manuale: null,
          importo_riporto_mese_succ: null,
          nota_riporto_mese_succ: '',
          importo_riporto_mese_prec: null,
          tot_fattura_prestazione: null,
          note: '',
          flg_scarto_doc: false,
          msg_scarto: '',
          richiesta_annullo_nav: null,
          flg_annullo_nav: null,
          utente_richiesta_annullo_nav: '',
          ts_annullo_nav: null,
          importo_prest_mediche_manuale: null,
          importo_prest_anastesista_manuale: null,
          note_hr: '',
          gg_gettone_manuale_hr: null,
          importo_gettone_manuale_hr: null,
          importo_direzione_manuale_hr: null,
          importo_prestazioni_manuale_hr: null,
          importo_direzione__area_manuale_hr: null,
          importo_direzione__area_calc: null,
          importo_direzione__area_manuale: null,
          importo_gettone_unitario_hr: null,
          importo_prest_mediche_manuale_hr: null,
          importo_prest_anastesista_manuale_hr: null,
          importo_addebiti_lab_calc: null,
          importo_addebiti_lab_manuale_hr: null
        };
      }
      let dialogRef = this.dialog.open(GceDialogInvoiceFormComponent, {
        width: "auto",
        height: "auto",
        disableClose: true,
        data: { invoice: inv, role: this.role, usr: this.usr, nerr: 0, nop: 0, isNew: true }
      });
      dialogRef.afterClosed().subscribe((result: { invoice: GceInvoice, saved: boolean }) => {
        console.log('result from dialog %o', result);
        if (result != null && result.saved) {
          this.invoices.push(result.invoice);
          this.invoices = [...this.invoices];
          this.openRightPanel = false;

          //this.onSearch({ filter: "", fromDate: undefined, toDate: undefined });
        }
        this.clear();
      });
    }
    else {
      //no clinic & doctor selected
      let inv = {};
      var d = new Date(Date.now());
      let clinics = this.gceService.dataRepoClinics;
      let medics = this.medics;
      if (this.role.toLowerCase().indexOf("amministratore") >= 0 || this.role.toLowerCase().indexOf("hr medici") >= 0) {

        this.openRightPanel = true;
        inv = {
          clinic: null,
          person: null,
          id: 0,
          mese: this.toDate,
          idfatturazione: null,
          cod_clinica: null,
          tipo_record: 1,
          ts_calcolo: null,
          flg_conferma_clinica: false,
          ts_conferma_clinica: null,
          user_conferma_clinica: '',
          flg_conferma_hr: true,
          user_conferma_hr: this.usr,
          ts_conferma_hr: moment(d).format('YYYY-MM-DD'),
          flg_doc_emesso: false,
          ts_doc_emesso: null,
          flg_gettone: false,
          gg_disponibilta_calc: null,
          gg_agenda_calc: null,
          gg_gettone_calc: null,
          gg_gettone_manuale: null,
          importo_gettone_calc: null,
          importo_gettone_manuale: null,
          importo_direzione_calc: null,
          importo_direzione_manuale: null,
          importo_fisso_extra_calc: null,
          importo_prestazioni_calc: null,
          importo_integr_minimo_calc: null,
          importo_prestazioni_manuale: null,
          importo_riporto_mese_succ: null,
          nota_riporto_mese_succ: '',
          importo_riporto_mese_prec: null,
          tot_fattura_prestazione: null,
          note: '',
          flg_scarto_doc: false,
          msg_scarto: '',
          richiesta_annullo_nav: null,
          flg_annullo_nav: null,
          utente_richiesta_annullo_nav: '',
          ts_annullo_nav: null,
          importo_prest_mediche_manuale: null,
          importo_prest_anastesista_manuale: null,
          note_hr: '',
          gg_gettone_manuale_hr: null,
          importo_gettone_manuale_hr: null,
          importo_direzione_manuale_hr: null,
          importo_prestazioni_manuale_hr: null,
          importo_direzione__area_manuale_hr: null,
          importo_direzione__area_calc: null,
          importo_direzione__area_manuale: null,
          importo_gettone_unitario_hr: null,
          importo_prest_mediche_manuale_hr: null,
          importo_prest_anastesista_manuale_hr: null,
          importo_addebiti_lab_calc: null,
          importo_addebiti_lab_manuale_hr: null
        };
      }
      else {
        //clinic is determinated by domain
        clinics = this.filterClinics(this.dm, this.gceService.dataRepoClinics);
        //medics = this.filterMedics(this.dm, this.medics);
        let clinicDm = clinics[0];
        inv = {
          clinic: clinicDm,
          person: null,
          id: 0,
          mese: this.toDate,
          idfatturazione: null,
          cod_clinica: clinicDm['code_c'],
          tipo_record: 1,
          ts_calcolo: null,
          flg_conferma_clinica: true,
          ts_conferma_clinica: moment(d).format('YYYY-MM-DD'),
          user_conferma_clinica: this.usr,
          flg_conferma_hr: false,
          user_conferma_hr: null,
          ts_conferma_hr: null,
          flg_doc_emesso: false,
          ts_doc_emesso: null,
          flg_gettone: false,
          gg_disponibilta_calc: null,
          gg_agenda_calc: null,
          gg_gettone_calc: null,
          gg_gettone_manuale: null,
          importo_gettone_calc: null,
          importo_gettone_manuale: null,
          importo_direzione_calc: null,
          importo_direzione_manuale: null,
          importo_fisso_extra_calc: null,
          importo_prestazioni_calc: null,
          importo_integr_minimo_calc: null,
          importo_prestazioni_manuale: null,
          importo_riporto_mese_succ: null,
          nota_riporto_mese_succ: '',
          importo_riporto_mese_prec: null,
          tot_fattura_prestazione: null,
          note: '',
          flg_scarto_doc: false,
          msg_scarto: '',
          richiesta_annullo_nav: null,
          flg_annullo_nav: null,
          utente_richiesta_annullo_nav: '',
          ts_annullo_nav: null,
          importo_prest_mediche_manuale: null,
          importo_prest_anastesista_manuale: null,
          note_hr: '',
          gg_gettone_manuale_hr: null,
          importo_gettone_manuale_hr: null,
          importo_direzione_manuale_hr: null,
          importo_prestazioni_manuale_hr: null,
          importo_direzione__area_manuale_hr: null,
          importo_direzione__area_calc: null,
          importo_direzione__area_manuale: null,
          importo_gettone_unitario_hr: null,
          importo_prest_mediche_manuale_hr: null,
          importo_prest_anastesista_manuale_hr: null,
          importo_addebiti_lab_calc: null,
          importo_addebiti_lab_manuale_hr: null
        };
      }
      let dialogRef = this.dialog.open(GceDialogInvoiceFormComponent, {
        width: "90%",
        height: "90%",
        disableClose: true,
        data: { invoice: inv, role: this.role, usr: this.usr, nerr: 0, nop: 0, isNew: true, clinics: clinics, medics: medics }
      });
      dialogRef.afterClosed().subscribe((result: { invoice: GceInvoice, saved: boolean }) => {
        console.log('result from dialog %o', result);
        if (result != null && result.saved) {
          this.invoices.push(result.invoice);
          this.invoices = [...this.invoices];
          this.openRightPanel = false;

          //this.onSearch({ filter: "", fromDate: undefined, toDate: undefined });
        }
        this.clear();
      });

    }
  }

  onDblClickRow(row) {
    //this.clear();
    this.openDialogInvoiceForm(row);
  }

  clear() {
    console.log('clear');
    this.currentRow = null;
    this.openRightPanel = false;
    this.editing = false;
  }

  openDialogInvoiceForm(invoice: GceInvoice) {
    console.log("openDialogInvoiceForm");
    this.showb = true;
    this.gceService.getPerformanceCalculationDetailGeneric(invoice.idfatturazione, invoice.mese, invoice.cod_clinica).subscribe(ret => {
      console.log('RET for open dialog %o', ret);
      if (ret != null) {
        this.nerr = 0;
        this.nop = 0;
        for (let i = 0; i < ret['detractsall'].length; i++) {
          //console.log('TEST for open dialog %o AND %o', ret['detracts'][i]['flg_riga_valida'],ret['detracts'][i]['touched']);
          if (!(ret['detractsall'][i]['flg_riga_valida'] == true) && (ret['detractsall'][i]['cod_clinica'] == invoice.cod_clinica)) {
            this.nerr = this.nerr + 1;
          }
        }
        if (this.isAdmin == true) {
          console.log('CHECK DETRACTS ONLY FOR THEI CLINIC dt %o dtall %o invoice %o', ret['detracts'], ret['detractsall'], invoice);
          for (let i = 0; i < ret['detracts'].length; i++) {
            if ((ret['detracts'][i]['flg_conferma_hr_richiesta'] == true) && !(ret['detracts'][i]['flg_conferma_hr'] == true) && (ret['detracts'][i]['cod_clinica'] == invoice.cod_clinica)) {
              this.nop = this.nop + 1;
            }
          }
        }

        this.showb = false;
        let dialogRef = this.dialog.open(GceDialogInvoiceFormComponent, {
          width: "90%",
          height: "90%",
          disableClose: true,
          data: { invoice: invoice, role: this.role, usr: this.usr, nerr: this.nerr, nop: this.nop, details: ret['details'], detracts: ret['detracts'] }
        });
        dialogRef.afterClosed().subscribe((result: { invoice: GceInvoice, saved: boolean, removed?: boolean }) => {
          console.log('result from dialog %o', result);
          if (result != null && result.saved) {

            for (let a = 0; a < this.invoices.length; a = a + 1) {
              if (this.invoices[a].id == result.invoice['id']) {
                console.log('find the invoices %o', result.invoice['id']);
                this.invoices[a] = result.invoice;
                //this.invoices[a].flg_doc_emesso = true;
              }
            }
            this.invoices = [...this.invoices];

            //this.onSearch({ filter: "", fromDate: undefined, toDate: undefined });

          }
          else {
            if (result != null && result.removed == true) {
              console.log('delete the invoices %o', result.invoice['id']);
              let invoicestemp = this.invoices;
              this.invoices = [];
              for (let a = 0; a < invoicestemp.length; a = a + 1) {
                if (invoicestemp[a].id == result.invoice['id']) {
                  console.log('invoice to remove finded');
                }
                else {
                  this.invoices.push(invoicestemp[a]);
                }
              }

              this.invoices = [...this.invoices];

            }
          }
          this.clear();
        });

      }
    }, err => {
      this.showb = false;
    });

  }

  openDialogCalculateBilling() {
    console.log("openDialogCalculateBilling");
    let dialogRef = this.dialog.open(GceDialogCalculateBillingComponent, {
      width: "500px",
      height: "auto",
      disableClose: true,
      data: { month: this.toDate }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      //aggiornare dati
      console.log("result %o", result);
      this.showb = true;
      this.gceService.loadDataForInvoices(this.fromDate, this.toDate).subscribe((dataResult: { clinics: GceClinic[], persons: GceInvoicePerson[], invoices: GceInvoice[] }) => {
        //manage column based on logged profile
        this.invoices = dataResult.invoices;
        this.invoicesBkp = [...this.invoices];
        this.showb = false;
      }, err => {
        this.invoicesBkp = [];
        this.showb = false;
      });
    });
  }

  sendReport(invoice: GceInvoice) {
    console.log("sendReport %o", invoice);
    if (invoice.flg_doc_emesso == true) {
      this.invoice = invoice;


      this.gceService.getPerformanceCalculationDetailGeneric(invoice.idfatturazione, invoice.mese, invoice.cod_clinica).subscribe(ret => {
        if (ret != null) {
          this.nerr = 0;
          this.nop = 0;
          this.details = ret['details'];
          this.detracts = ret['detracts'];
          const confirm: Confirm = {
            title: "GCE.GCEINVOICE.DIALOG.SENDINVOICE.TITLE",
            message: "GCE.GCEINVOICE.DIALOG.SENDINVOICE.MESSAGE",
            buttonOkText: "GCE.GCEINVOICE.DIALOG.CONFIRMINVOICE.BUTTON.OK",
            buttonCancelText: "GCE.GCEINVOICE.DIALOG.CONFIRMINVOICE.BUTTON.CANCEL",
            buttonOkStyle: { 'background-color': '#268B26' },
            buttonCancelStyle: { 'background-color': '#AA3939' },
            withTranslate: true,
            messageParams: [{ key: 'INVOICE_NUMBER', value: invoice.id }, { key: 'IS_SEND_MAIL', value: true }]
          };

          this.alertDialog.confirm(confirm).subscribe(result => {
            if (result) {
              this.loaderService.show();
              if (!(this.invoice.person.email == null) && (this.invoice.person.email.length > 0)) {
                this.calcValuesForReport();
                let t1 = null;
                if (!(this.t1 == null))
                  t1 = Math.round(this.t1 * 100) / 100;
                let t2 = null;
                if (!(this.t2 == null))
                  t2 = Math.round(this.t2 * 100) / 100;
                let t3 = null;
                if (!(this.t3 == null))
                  t3 = Math.round(this.t3 * 100) / 100;
                let t4 = null;
                if (!(this.t4 == null))
                  t4 = Math.round(this.t4 * 100) / 100;
                let t5 = null;
                if (!(this.t5 == null))
                  t5 = Math.round(this.t5 * 100) / 100;
                let t6 = null;
                if (!(this.t6 == null))
                  t6 = Math.round(this.t6 * 100) / 100;
                let t7 = null;
                if (!(this.t7 == null))
                  t7 = Math.round(this.t7 * 100) / 100;
                let t11_1 = null;
                if (!(this.t11_1 == null))
                  t11_1 = Math.round(this.t11_1 * 100) / 100;
                let t11_2 = null;
                if (!(this.t11_2 == null))
                  t11_2 = Math.round(this.t11_2 * 100) / 100;
                let t11_3 = null;
                if (!(this.t11_3 == null))
                  t11_3 = Math.round(this.t11_3 * 100) / 100;
                let t12_1 = null;
                if (!(this.t12_1 == null))
                  t12_1 = Math.round(this.t12_1 * 100) / 100;
                let t12_2 = null;
                if (!(this.t12_2 == null))
                  t12_2 = Math.round(this.t12_2 * 100) / 100;
                let t12_3 = null;
                if (!(this.t12_3 == null))
                  t12_3 = Math.round(this.t12_3 * 100) / 100;
                let t13_t = null;
                if (!(this.t13_t == null))
                  t13_t = Math.round(this.t13_t * 100) / 100;
                let t14_1 = null;
                if (!(this.t14_1 == null))
                  t14_1 = Math.round(this.t14_1 * 100) / 100;
                let t14_2 = null;
                if (!(this.t14_2 == null))
                  t14_2 = Math.round(this.t14_2 * 100) / 100;
                let t14_3 = null;
                if (!(this.t14_3 == null))
                  t14_3 = Math.round(this.t14_3 * 100) / 100;
                let t15_1 = null;
                if (!(this.t15_1 == null))
                  t15_1 = Math.round(this.t15_1 * 100) / 100;
                let t15_2 = null;
                if (!(this.t15_2 == null))
                  t15_2 = Math.round(this.t15_2 * 100) / 100;
                let t15_3 = null;
                if (!(this.t15_3 == null))
                  t15_3 = Math.round(this.t15_3 * 100) / 100;
                let t16_1 = null;
                if (!(this.t16_1 == null))
                  t16_1 = Math.round(this.t16_1 * 100) / 100;
                let t16_2 = null;
                if (!(this.t16_2 == null))
                  t16_2 = Math.round(this.t16_2 * 100) / 100;
                let t16_3 = null;
                if (!(this.t16_3 == null))
                  t16_3 = Math.round(this.t16_3 * 100) / 100;
                let t17_1 = null;
                if (!(this.t17_1 == null))
                  t17_1 = Math.round(this.t17_1 * 100) / 100;
                let t17_2 = null;
                if (!(this.t17_2 == null))
                  t17_2 = Math.round(this.t17_2 * 100) / 100;
                let t17_3 = null;
                if (!(this.t17_3 == null))
                  t17_3 = Math.round(this.t17_3 * 100) / 100;
                let t18_1 = null;
                if (!(this.t18_1 == null))
                  t18_1 = Math.round(this.t18_1 * 100) / 100;
                let t18_2 = null;
                if (!(this.t18_2 == null))
                  t18_2 = Math.round(this.t18_2 * 100) / 100;
                let t18_3 = null;
                if (!(this.t18_3 == null))
                  t18_3 = Math.round(this.t18_3 * 100) / 100;
                let t18A_2 = null;
                if (!(this.t18A_2 == null))
                  t18A_2 = Math.round(this.t18A_2 * 100) / 100;
                let t18A_3 = null;
                if (!(this.t18A_3 == null))
                  t18A_3 = Math.round(this.t18A_3 * 100) / 100;
                let t19_1 = null;
                if (!(this.t19_1 == null))
                  t19_1 = Math.round(this.t19_1 * 100) / 100;
                let t19_2 = null;
                if (!(this.t19_2 == null))
                  t19_2 = Math.round(this.t19_2 * 100) / 100;
                let t19_3 = null;
                if (!(this.t19_3 == null))
                  t19_3 = Math.round(this.t19_3 * 100) / 100;
                let t110_t = null;
                if (!(this.t110_t == null))
                  t110_t = Math.round(this.t110_t * 100) / 100;
                let t20_3 = null;
                if (!(this.t20_3 == null))
                  t20_3 = Math.round(this.t20_3 * 100) / 100;
                let t21_3 = null;
                if (!(this.t21_3 == null))
                  t21_3 = Math.round(this.t21_3 * 100) / 100;
                let blb = this.gceService.exportPerformanceCalculationDetailDefToExcel(this.invoice,
                  this.details,
                  this.detracts,
                  t1,
                  t2,
                  t3,
                  t4,
                  t5,
                  t6,
                  t7,
                  t11_1,
                  t11_2,
                  t11_3,
                  t12_1,
                  t12_2,
                  t12_3,
                  t13_t,
                  t14_1,
                  t14_2,
                  t14_3,
                  t15_1,
                  t15_2,
                  t15_3,
                  t16_1,
                  t16_2,
                  t16_3,
                  t17_1,
                  t17_2,
                  t17_3,
                  t18_1,
                  t18_2,
                  t18_3,
                  t18A_2,
                  t18A_3,
                  t19_1,
                  t19_2,
                  t19_3,
                  t110_t,
                  t20_3,
                  t21_3,
                  false
                );
                //console.log('------------------------------------------> BLOB %o', blb);

                let self = this;

                let fileReader = new FileReader();
                this.showb = false;
                fileReader.onloadend = function () {
                  // console.log('------------------------------------------> BLB', fileReader.result);
                  let payload = {
                    email: self.invoice.person.email,
                    subject: 'Report liquidazione mensile',
                    message: "<div>Gentile Dottore,<br> in allegato trova il suo report per la clinica di " + self.invoice.clinic.nome + "<br>La pre-fattura è stata emessa; sarà visibile nel portale al massimo entro 24 h.<br>Cordiali Saluti<br><br><p><i>Questa e-mail e tutti i suoi allegati sono da intendersi confidenziali e riservati. Se non siete l'effettivo destinatario o avete ricevuto il messaggio per errore siete pregati di cancellarlo dal vostro sistema e di avvisare il mittente.</i></p><p><i>Siete altresì informati che qualsiasi utilizzo, copia, comunicazione, divulgazione dei contenuti del messaggio e dei suoi allegati, a qualunque titolo, è vietata dalla legge.</i></p><p><i>This email and any attachments are privileged and confidential. If you are not the intended recipient please delete it from your system and notify the sender.</i></p><p><i>You should not copy it or use it for any purpose nor disclose or distribute its contents to any other person. You should as well be aware that any use, copy, disclosure and distribution of the contents and the possible attachments of this email, at any title, is forbidden by the Law.</i></p></div>",
                    attachFile: btoa(fileReader.result.toString()).replace(/.{76}(?=.)/g, '$&\n')
                  };
                  console.log('------------------------------------------> PAYLOAD %o', payload);
                  self.http.post<any>('https://forecast.dentalpro.it/api/fmc_fatturazione_mese_send_mail/', payload).subscribe(data => {
                    //console.log('------------------------------------------> MAIL SENT %o', data);
                    self.loaderService.hide();

                  },
                    err => {
                      /*
                      self.alertDialog.open({
                        message: 'GCE.GCEINVOICE.DIALOG.CONFIRMINVOICE.ERROR.MESSAGE',
                        isError: true,
                        withTranslate: true
                      });
                      */

                      self.loaderService.hide();
                    });
                  /*
                                    payload.email = 'seveso@dentalpro.it';
                                    self.http.post<any>('https://forecast.dentalpro.it/api/fmc_fatturazione_mese_send_mail/', payload).subscribe(data => {
                  
                                      self.loaderService.hide();
                  
                                    },
                                      err => {
                  
                                        self.loaderService.hide();
                                      });
                                      */

                };
                fileReader.readAsBinaryString(blb);

              }
              else {
                //No email
                window.alert("Non è presente una mail valida in anagrafica per il medico destinatario del report.");
                this.loaderService.hide();
              }
            }
            else {
              //No email
              this.loaderService.hide();


            }
          });
        }

      });
    }
    else {
      window.alert("E' possibile inviare il report solo per le liquidazioni confermate.");
    }
  }

  confirmInvoice(invoice: GceInvoice) {
    console.log("confirmInvoice %o", invoice);

    if (invoice.flg_doc_emesso == true) {
      window.alert('La liquidazione è già stata confermata.');
    }
    else {
      this.invoice = invoice;

      this.showb = true;
      this.gceService.getPerformanceCalculationDetailGeneric(invoice.idfatturazione, invoice.mese, invoice.cod_clinica).subscribe(ret => {
        console.log('RET for open dialog %o', ret);
        if (ret != null) {
          this.nerr = 0;
          this.nop = 0;
          this.details = ret['details'];
          this.detracts = ret['detracts'];

          for (let i = 0; i < ret['detractsall'].length; i++) {
            //console.log('TEST for open dialog %o AND %o', ret['detracts'][i]['flg_riga_valida'],ret['detracts'][i]['touched']);
            if (!(ret['detractsall'][i]['flg_riga_valida'] == true)) {
              this.nerr = this.nerr + 1;
            }
          }
          if (this.isAdmin == true) {
            for (let i = 0; i < ret['detracts'].length; i++) {
              if ((ret['detracts'][i]['flg_conferma_hr_richiesta'] == true) && !(ret['detracts'][i]['flg_conferma_hr'] == true)) {
                this.nop = this.nop + 1;
              }
            }
          }
          if (this.nerr == 0 && this.nop == 0) {


            const confirm: Confirm = {
              title: "GCE.GCEINVOICE.DIALOG.CONFIRMINVOICE.TITLE",
              message: "GCE.GCEINVOICE.DIALOG.CONFIRMINVOICE.MESSAGE",
              buttonOkText: "GCE.GCEINVOICE.DIALOG.CONFIRMINVOICE.BUTTON.OK",
              buttonCancelText: "GCE.GCEINVOICE.DIALOG.CONFIRMINVOICE.BUTTON.CANCEL",
              buttonOkStyle: { 'background-color': '#268B26' },
              buttonCancelStyle: { 'background-color': '#AA3939' },
              withTranslate: true,
              messageParams: [{ key: 'INVOICE_NUMBER', value: invoice.id }]
            };

            this.alertDialog.confirm(confirm).subscribe(result => {
              if (result) {

                if (!(invoice['flg_conferma_hr'] == true)) {
                  var d = new Date(Date.now());
                  invoice['flg_conferma_hr'] = true;
                  invoice['user_conferma_hr'] = this.usr;
                  invoice['ts_conferma_hr'] = moment(d).format('YYYY-MM-DD');
                }
                if (!(invoice['flg_conferma_clinica'] == true)) {
                  invoice['flg_conferma_clinica'] = true;
                  invoice['ts_conferma_clinica'] = moment(d).format('YYYY-MM-DD');
                  invoice['user_conferma_clinica'] = 'da HR -' + this.usr;
                }
                this.gceService.updateInvoice(invoice).subscribe((result: GceInvoice) => {
                  console.log('result of update invoice %o', result);

                  this.gceService.calculateBillingDoctor(invoice.cod_clinica, invoice.idfatturazione, this.gceService.dataRepoGceEnvironment.getToDate()).subscribe(r => {
                    console.log('result of calculateBillingDoctor %o', r);

                    this.gceService.confirmInvoice(invoice).subscribe((d) => {
                      console.log('result of confirmInvoice %o', d);

                      //update with result value -> is confirmed!
                      for (let a = 0; a < this.invoices.length; a = a + 1) {
                        if (this.invoices[a].id == result['id']) {
                          console.log('find the invoices %o', result['id']);
                          this.invoices[a] = result;
                          this.invoices[a].flg_doc_emesso = true;
                        }
                      }
                      this.invoices = [...this.invoices];
                      if (!(this.invoice.person.email == null) && (this.invoice.person.email.length > 0)) {
                        this.calcValuesForReport();
                        let t1 = null;
                        if (!(this.t1 == null))
                          t1 = Math.round(this.t1 * 100) / 100;
                        let t2 = null;
                        if (!(this.t2 == null))
                          t2 = Math.round(this.t2 * 100) / 100;
                        let t3 = null;
                        if (!(this.t3 == null))
                          t3 = Math.round(this.t3 * 100) / 100;
                        let t4 = null;
                        if (!(this.t4 == null))
                          t4 = Math.round(this.t4 * 100) / 100;
                        let t5 = null;
                        if (!(this.t5 == null))
                          t5 = Math.round(this.t5 * 100) / 100;
                        let t6 = null;
                        if (!(this.t6 == null))
                          t6 = Math.round(this.t6 * 100) / 100;
                        let t7 = null;
                        if (!(this.t7 == null))
                          t7 = Math.round(this.t7 * 100) / 100;
                        let t11_1 = null;
                        if (!(this.t11_1 == null))
                          t11_1 = Math.round(this.t11_1 * 100) / 100;
                        let t11_2 = null;
                        if (!(this.t11_2 == null))
                          t11_2 = Math.round(this.t11_2 * 100) / 100;
                        let t11_3 = null;
                        if (!(this.t11_3 == null))
                          t11_3 = Math.round(this.t11_3 * 100) / 100;
                        let t12_1 = null;
                        if (!(this.t12_1 == null))
                          t12_1 = Math.round(this.t12_1 * 100) / 100;
                        let t12_2 = null;
                        if (!(this.t12_2 == null))
                          t12_2 = Math.round(this.t12_2 * 100) / 100;
                        let t12_3 = null;
                        if (!(this.t12_3 == null))
                          t12_3 = Math.round(this.t12_3 * 100) / 100;
                        let t13_t = null;
                        if (!(this.t13_t == null))
                          t13_t = Math.round(this.t13_t * 100) / 100;
                        let t14_1 = null;
                        if (!(this.t14_1 == null))
                          t14_1 = Math.round(this.t14_1 * 100) / 100;
                        let t14_2 = null;
                        if (!(this.t14_2 == null))
                          t14_2 = Math.round(this.t14_2 * 100) / 100;
                        let t14_3 = null;
                        if (!(this.t14_3 == null))
                          t14_3 = Math.round(this.t14_3 * 100) / 100;
                        let t15_1 = null;
                        if (!(this.t15_1 == null))
                          t15_1 = Math.round(this.t15_1 * 100) / 100;
                        let t15_2 = null;
                        if (!(this.t15_2 == null))
                          t15_2 = Math.round(this.t15_2 * 100) / 100;
                        let t15_3 = null;
                        if (!(this.t15_3 == null))
                          t15_3 = Math.round(this.t15_3 * 100) / 100;
                        let t16_1 = null;
                        if (!(this.t16_1 == null))
                          t16_1 = Math.round(this.t16_1 * 100) / 100;
                        let t16_2 = null;
                        if (!(this.t16_2 == null))
                          t16_2 = Math.round(this.t16_2 * 100) / 100;
                        let t16_3 = null;
                        if (!(this.t16_3 == null))
                          t16_3 = Math.round(this.t16_3 * 100) / 100;
                        let t17_1 = null;
                        if (!(this.t17_1 == null))
                          t17_1 = Math.round(this.t17_1 * 100) / 100;
                        let t17_2 = null;
                        if (!(this.t17_2 == null))
                          t17_2 = Math.round(this.t17_2 * 100) / 100;
                        let t17_3 = null;
                        if (!(this.t17_3 == null))
                          t17_3 = Math.round(this.t17_3 * 100) / 100;
                        let t18_1 = null;
                        if (!(this.t18_1 == null))
                          t18_1 = Math.round(this.t18_1 * 100) / 100;
                        let t18_2 = null;
                        if (!(this.t18_2 == null))
                          t18_2 = Math.round(this.t18_2 * 100) / 100;
                        let t18_3 = null;
                        if (!(this.t18_3 == null))
                          t18_3 = Math.round(this.t18_3 * 100) / 100;
                        let t18A_2 = null;
                        if (!(this.t18A_2 == null))
                          t18A_2 = Math.round(this.t18A_2 * 100) / 100;
                        let t18A_3 = null;
                        if (!(this.t18A_3 == null))
                          t18A_3 = Math.round(this.t18A_3 * 100) / 100;
                        let t19_1 = null;
                        if (!(this.t19_1 == null))
                          t19_1 = Math.round(this.t19_1 * 100) / 100;
                        let t19_2 = null;
                        if (!(this.t19_2 == null))
                          t19_2 = Math.round(this.t19_2 * 100) / 100;
                        let t19_3 = null;
                        if (!(this.t19_3 == null))
                          t19_3 = Math.round(this.t19_3 * 100) / 100;
                        let t110_t = null;
                        if (!(this.t110_t == null))
                          t110_t = Math.round(this.t110_t * 100) / 100;
                        let t20_3 = null;
                        if (!(this.t20_3 == null))
                          t20_3 = Math.round(this.t20_3 * 100) / 100;
                        let t21_3 = null;
                        if (!(this.t21_3 == null))
                          t21_3 = Math.round(this.t21_3 * 100) / 100;
                        let blb = this.gceService.exportPerformanceCalculationDetailDefToExcel(this.invoice,
                          this.details,
                          this.detracts,
                          t1,
                          t2,
                          t3,
                          t4,
                          t5,
                          t6,
                          t7,
                          t11_1,
                          t11_2,
                          t11_3,
                          t12_1,
                          t12_2,
                          t12_3,
                          t13_t,
                          t14_1,
                          t14_2,
                          t14_3,
                          t15_1,
                          t15_2,
                          t15_3,
                          t16_1,
                          t16_2,
                          t16_3,
                          t17_1,
                          t17_2,
                          t17_3,
                          t18_1,
                          t18_2,
                          t18_3,
                          t18A_2,
                          t18A_3,
                          t19_1,
                          t19_2,
                          t19_3,
                          t110_t,
                          t20_3,
                          t21_3,
                          false
                        );
                        //console.log('------------------------------------------> BLOB %o', blb);
                        /*
                                                let self = this;
                        
                                                let fileReader = new FileReader();
                                                this.showb = false;
                                                fileReader.onloadend = function () {
                                                  // console.log('------------------------------------------> BLB', fileReader.result);
                                                  let payload = {
                                                    email: self.invoice.person.email,
                                                    subject: 'Report liquidazione mensile',
                                                    message: "<div>Gentile Dottore,<br> in allegato trova il suo report per la clinica di " + self.invoice.clinic.nome + "<br>La pre-fattura è stata emessa; sarà visibile nel portale al massimo entro 24 h.<br>Cordiali Saluti<br><br><p><i>Questa e-mail e tutti i suoi allegati sono da intendersi confidenziali e riservati. Se non siete l'effettivo destinatario o avete ricevuto il messaggio per errore siete pregati di cancellarlo dal vostro sistema e di avvisare il mittente.</i></p><p><i>Siete altresì informati che qualsiasi utilizzo, copia, comunicazione, divulgazione dei contenuti del messaggio e dei suoi allegati, a qualunque titolo, è vietata dalla legge.</i></p><p><i>This email and any attachments are privileged and confidential. If you are not the intended recipient please delete it from your system and notify the sender.</i></p><p><i>You should not copy it or use it for any purpose nor disclose or distribute its contents to any other person. You should as well be aware that any use, copy, disclosure and distribution of the contents and the possible attachments of this email, at any title, is forbidden by the Law.</i></p></div>",
                                                    attachFile: btoa(fileReader.result.toString()).replace(/.{76}(?=.)/g, '$&\n')
                                                  };
                                                  console.log('------------------------------------------> PAYLOAD %o', payload);
                                                  self.http.post<any>('https://forecast.dentalpro.it/api/fmc_fatturazione_mese_send_mail/', payload).subscribe(data => {
                                                    //console.log('------------------------------------------> MAIL SENT %o', data);
                                                    self.loaderService.hide();
                        
                                                  },
                                                    err => {
                        
                                                      self.loaderService.hide();
                                                    });
                        
                                                };
                                                fileReader.readAsBinaryString(blb);
                        */
                      }
                      else {
                        //No email

                        this.loaderService.hide();
                        window.alert("Non è presente una mail valida in anagrafica per il medico destinatario del report.");
                      }

                      //this.onSearch({ filter: "", fromDate: undefined, toDate: undefined });
                      /*
                      this.alertDialog.open({
                        message: 'GCE.GCEINVOICE.DIALOG.CONFIRMEDINVOICE.MESSAGE',
                        isError: false,
                        withTranslate: true
                      });
                      */
                    }, err => {
                      this.showb = false;
                      this.loaderService.hide();
                      this.alertDialog.open({
                        message: 'GCE.GCEINVOICE.DIALOG.CONFIRMINVOICE.ERROR.MESSAGE',
                        isError: true,
                        withTranslate: true
                      });
                    })
                  },
                    err => {
                      this.showb = false;
                      this.loaderService.hide();
                      this.alertDialog.open({
                        message: 'GCE.GCEINVOICE.DIALOG.CONFIRMINVOICE.ERROR.MESSAGE',
                        isError: true,
                        withTranslate: true
                      })
                    })
                },
                  err => {
                    this.showb = false;
                  });
              }
              else {
                this.showb = false;
              }
            });
          }
          else {
            //errori da correggere
            this.showb = false;
            this.loaderService.hide();
            if (this.nop == 0) {
              window.alert("Non è possibile confermare le liquidazioni: correggere le anomalie di riconciliazione ordini.");
            }
            else {
              window.alert("Non è possibile confermare le liquidazioni: correggere tutte le anomalie e approvare le modifiche alle riconciliazione ordini.");
            }
          }
        }
        else {
          //errori nella getdetail
          this.showb = false;
          this.loaderService.hide();
          window.alert("Errore nel recupero delle informazioni di dettaglio liquidazione.");
        }
      });
    }
  }


  openDialogPerformanceCalculationDetail(invoice: GceInvoice) {
    this.showb = true;
    this.gceService.getPerformanceCalculationDetailGeneric(invoice.idfatturazione, invoice.mese, invoice.cod_clinica).subscribe(ret => {
      console.log('RET for open dialog %o', ret);
      this.showb = false;
      if (ret != null) {
        let dialogRef = this.dialog.open(GceDialogPerformanceCalculationDetailComponent, {
          width: "90%",
          height: "90%",
          data: {
            invoice: invoice,
            details: ret['details'],
            detracts: ret['detracts'],
            role: this.role
          }
        });
        /*
        dialogRef.afterClosed().subscribe(result => {
          console.log(result);
        });
        */
      }
    }, err => {
      this.showb = false;
      window.alert('Errore nel recupero delle informazioni di dettaglio per la fattura selezionata.');
    });
  }
}
