import { primaryKey, headerCode, inputType, required, length, order, pattern, editable, Describer, GceEntity } from './gce-management';

/**
 * GcePerson -> table: medici_invio_anagrafica
 */
export class GceAnagraphic extends GceEntity {
    @primaryKey()
    @headerCode('GCE.GCEANAGRAPHIC.MAIL')
    @inputType('text')
    @required()
    @length(100)
    @order(1)
    mail: string;

    @headerCode('GCE.GCEANAGRAPHIC.PARTITA_IVA')
    @inputType('text')
    @required()
    @length(11)
    @order(2)
    partita_iva: string;

    @headerCode('GCE.GCEANAGRAPHIC.CODICE_FISCALE')
    @inputType('text')
    @length(16)
    @order(3)
    codice_fiscale: string;

    @headerCode('GCE.GCEANAGRAPHIC.RAGIONE_SOCIALE')
    @inputType('text')
    @required()
    @length(255)
    @order(4)
    ragione_sociale: string;

    @headerCode('GCE.GCEANAGRAPHIC.INDIRIZZO')
    @inputType('text')
    @length(255)
    @order(5)
    indirizzo: string;

    @headerCode('GCE.GCEANAGRAPHIC.CITTA')
    @inputType('text')
    @length(255)
    @order(6)
    citta: string;

    @headerCode('GCE.GCEANAGRAPHIC.CAP')
    @inputType('text')
    @length(5)
    @order(7)
    cap: string;

    @headerCode('GCE.GCEANAGRAPHIC.PROVINCIA')
    @inputType('text')
    @length(2)
    @order(8)
    provincia: string;

    @headerCode('GCE.GCEANAGRAPHIC.CAPITALE_SOCIALE')
    @inputType('number')
    @pattern('00000000000')
    @order(9)
    capitale_sociale: number;

    @headerCode('GCE.GCEANAGRAPHIC.REGIME_FISCALE')
    @inputType('text')
    @length(4)
    @order(10)
    regime_fiscale: string;

    @headerCode('GCE.GCEANAGRAPHIC.DATAORA_INVIO')
    @inputType('datetime')
    @editable(false)
    @order(11)
    dataora_invio: Date | string;

    @headerCode('GCE.GCEANAGRAPHIC.ESITO')
    @inputType('text')
    @editable(false)
    @length(10)
    @order(12)
    esito: string;

    @headerCode('GCE.GCEANAGRAPHIC.MESSAGGI')
    @inputType('text')
    @editable(false)
    @length(255)
    @order(13)
    messaggi: string;

    constructor();
    constructor(mail: string, partita_iva: string, codice_fiscale: string, ragione_sociale: string, indirizzo: string, citta: string, cap: string, provincia: string, capitale_sociale: number, regime_fiscale: string, dataora_invio: Date | string, esito: string, messaggi: string);
    constructor(mail?: string, partita_iva?: string, codice_fiscale?: string, ragione_sociale?: string, indirizzo?: string, citta?: string, cap?: string, provincia?: string, capitale_sociale?: number, regime_fiscale?: string, dataora_invio?: Date | string, esito?: string, messaggi?: string) {
        super();
        this.mail = mail || '';
        this.partita_iva = partita_iva || '';
        this.codice_fiscale = codice_fiscale || '';
        this.ragione_sociale = ragione_sociale || '';
        this.indirizzo = indirizzo || '';
        this.citta = citta || '';
        this.cap = cap || '';
        this.provincia = provincia || '';
        this.capitale_sociale = capitale_sociale || 0;
        this.regime_fiscale = regime_fiscale || '';
        this.dataora_invio = dataora_invio || new Date();
        this.esito = esito || '';
        this.messaggi = messaggi || '';
    }

    static getStructure(): Array<any> {
        let structure: any[] = Describer.getStructure(new GceAnagraphic());
        structure.push(...this.getActions());
        return structure;
    }

    protected static getActions(): any[] {
        let actions: any[] = [];
        return actions;
    }
}
