import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IncentiveService } from '../../services/incentive.service';
import { AuthGuardService } from '../../services';
import { LoaderService } from '../../loader/loader.service';
import { MatTableDataSource } from '@angular/material/table';
import { AlertDialogService } from '../../services/alert-dialog.service';
import * as moment from 'moment';
import { AfterViewInit, Component, ViewChild, OnInit } from '@angular/core';
import { GceManagementService } from '../../services/gce-management.service';
import { ExcelService } from '../../services/excel.service';
import { MatSort, MatPaginator } from '@angular/material';
const dateFormatDate = "YYYY-MM-DD";
import { FormHeaderComponent, ICustomButton, IFormHeaderSearch, FormHeaderSearchEventsEnum } from '../../shared/form-header/form-header.component';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Rx';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Observer, merge } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-bonus',
  templateUrl: './bonus.component.html',
  styleUrls: ['./bonus.component.css', '../incentive.component.css']
})
export class BonusComponent implements OnInit {

  displayedColumns = ['codClinica', 'nomeClinica', 'isTargetClinic', 'mese', 'idProfilo', 'LoginUtente', 'bonusMensileFatturato', 'bonusMensileProduzione', 'bonusContinuita', 'bonusTrimestrale', 'bonusQualityTrimestrale', 'ConguaglioTrimestrale', 'UltimoCalcoloDT'];
  dataSource: any;
  columns: any[] = [];
  detracts: any;
  detractsBck: any;
  limitRows: number;
  heightMainList: number;
  currentRow: any;
  editing: boolean = false;
  keyColumn: any = {};
  customButtons: ICustomButton[] = [];
  role: string = '';
  dm: string = '';
  fromDate: any;
  toDate: any;
  isAdmin: boolean = false;
  isAM: boolean = false;
  isDM: boolean = false;
  isCM: boolean = false;
  isViewer: boolean = true;
  showb: boolean;
  usr: any;
  kwSearch: any;
  expandedElement: any;
  oldSelectedElement: any;
  expandedElement2: any;
  isReadOnly: boolean = false;

  @ViewChild(MatSort, null) sort: MatSort;
  @ViewChild(MatPaginator, null) paginator: MatPaginator;

  constructor(
    private incentiveService: IncentiveService,
    private alertDialog: AlertDialogService,
    private auth: AuthGuardService,
    private loaderService: LoaderService,
    private http: HttpClient,
    private gceService: GceManagementService,
    private excelService: ExcelService,
    private fb: FormBuilder) {
    this.fromDate = new Date();
    this.toDate = new Date();
    // this.dataSourceSlave = new MatTableDataSource();
    this.dataSource = new MatTableDataSource();
  }

  onSearch(e) {
    this.dataSource.filter = e.trim().toLowerCase();
  }

  clickD(row) {
    console.log('row %o', row);
  }


  export() {
    if (this.isViewer == true) {
      window.alert('Il tuo profilo non permette questa funzione.');
    }
    else {
      console.log('export %o', this.dataSource.data);
      let datasMaster: any[] = [];
      this.dataSource.data.forEach(
        e => {
          let tp = e;
          e.persons = null;
          datasMaster.push(e);
        });
      this.excelService.exportAsExcelFile(datasMaster, 'incentive_bonus_' + this.fromDate.toLocaleString('default', { month: 'long' }));
    }
  }

  init(from, to) {
    this.showb = true;
    let py = {
      from: from,
      to: to,
      desc_domain: this.dm,
      profile: this.role,
      usr: this.usr
    };

    console.log('fromDate %o toDate %o service %o', this.fromDate, this.toDate, this.gceService);
    this.http.post(`${environment.gceAPI}/incentive_all_bonus/`, py, { responseType: 'json' }).subscribe(res => {
      // this.http.post(`http://localhost:57526/api/incentive_all_bonus/`, py, { responseType: 'json' }).subscribe(res => {
      this.showb = false;
      //this.allData = res;
      console.log('response for getBonus %o', res);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.data = res;
      var close_date = new Date(this.incentiveService.dataRepoGceEnvironment.ultima_chiusura_incentive);
      console.log('CLOSE DATE %o %o %o conf %o %o', close_date, this.fromDate.getMonth(), this.fromDate.getFullYear(), close_date.getMonth(), close_date.getFullYear());
      if (this.fromDate.getFullYear() >= close_date.getFullYear()) {
        this.isReadOnly = false;
      }
      else {
        if (this.fromDate.getMonth() >= close_date.getMonth()) {
          this.isReadOnly = false;
        }
        else {
          this.isReadOnly = true;
        }
      }

      this.dataSource.filterPredicate = (data: any, filterValue: string) => {
        console.log('DATA FOR FILTER %o %o', data, filterValue);
        if (data.codClinica.trim().toLowerCase().indexOf(filterValue) >= 0)
          return true;
        else {
          if (data.idProfilo.trim().toLowerCase().indexOf(filterValue) >= 0)
            return true;
          else {
            if (data.LoginUtente.trim().toLowerCase().indexOf(filterValue) >= 0)
              return true;
            else
              return false;
          }
        }
      }, err => {
        console.log('response for getBonus %o', err);
        this.showb = false;
        window.alert('Errore nel recupero delle informazioni dal DB. ' + err['message']);
      }
    });
  }



  calc() {

    if (this.isReadOnly == false) {
      var ms = this.fromDate.toLocaleString('default', { month: 'long' });
      if (window.confirm("Sei sicuro di voler calcolare i bonus per il mese di " + ms + "?")) {
        this.showb = true;
        let py = {
          from: moment(this.fromDate).startOf('month').format('YYYYMMDD'),
          to: moment(this.fromDate).endOf('month').format('YYYYMMDD'),
          desc_domain: null
        };

        this.http.post(`${environment.gceAPI}/calc_all_bonus/`, py, { responseType: 'json' }).subscribe(res => {
          // this.http.post(`http://localhost:57526/api/calc_all_bonus/`, py, { responseType: 'json' }).subscribe(res => {
          this.init(moment(this.fromDate).startOf('month').format('YYYYMMDD'), moment(this.fromDate).endOf('month').format('YYYYMMDD'));
          window.alert('Calcolo del mese terminato.');
          console.log('response for calc_all_bonus %o', res);
        }, err => {
          console.log('response for  calc_all_bonus %o', err);
          this.showb = false;
          window.alert('Errore nel calcolo incentive del mese sul DB. ' + err['message']);
        });
      }
    }
    else {
      window.alert('Il mese è chiuso, non è possibile salvare modifiche');
    }
  }

  fromDateChange(e) {
    //console.log('fromDateChange %o %o %o', e,this.fromDate,moment(this.fromDate));
    this.init(moment(this.fromDate).startOf('month').format('YYYYMMDD'), moment(this.fromDate).endOf('month').format('YYYYMMDD'));
  }

  closeM() {
    console.log('CLOSEM');
    var det = new Date();
    if ((window.confirm('Sei sicuro di voler chiudere il mese di lavoro in data ' + moment(det).format('DD/MM/YYYY') + '?'))) {
      this.incentiveService.setCloseDate(det).subscribe(e => {
        window.alert('Mese chiuso correttamente');
      });
    }
  }

  ngOnInit() {
    //the Date from/to are first day of currentMonth and last day of currentMonth

    console.log('BONUS -> DATE TO CHECK %o', this.incentiveService.dataRepoGceEnvironment);

    let usr = this.auth.getUser();
    if (usr != null) {
      this.role = usr['ruolo'];
      this.dm = usr['clinica'];
      if (usr['nomeCompleto'] != null && usr['nomeCompleto'].length > 0) {
        this.usr = usr['nomeCompleto'];
      }
      else {
        if (usr['email'] != null && usr['email'].length > 0) {
          this.usr = usr['email'];
        }
      }
    }

    if (this.role != null) {
      if (this.role.toLowerCase().indexOf('amministratore') == 0 || this.role.toLowerCase().indexOf('hr medici') == 0 || this.role.toLowerCase().indexOf('direzione') == 0) {
        this.isAdmin = true;
        this.isAM = false;
        this.isCM = false;
        this.isDM = false;
        this.isViewer = false;
      }
      else if (this.role.toLowerCase().indexOf('area') == 0 && this.role.toLowerCase().indexOf('manager') > 0) {
        this.isAdmin = false;
        this.isAM = true;
        this.isCM = false;
        this.isDM = false;
        this.isViewer = false;
      }
      else if (this.role.toLowerCase().indexOf('district') == 0 && this.role.toLowerCase().indexOf('manager') > 0) {
        this.isAdmin = false;
        this.isAM = false;
        this.isCM = false;
        this.isDM = true;
        this.isViewer = false;
      }
      else if (this.role.toLowerCase().indexOf('clinic') == 0 && this.role.toLowerCase().indexOf('manager') > 0) {
        this.isAdmin = false;
        this.isAM = false;
        this.isCM = true;
        this.isDM = false;
        this.isViewer = false;
      }
      else {
        this.isAdmin = false;
        this.isAM = false;
        this.isCM = false;
        this.isDM = false;
        this.isViewer = true;
      }
    }
    else {
      this.isAdmin = false;
      this.isAM = false;
      this.isCM = false;
      this.isDM = false;
      this.isViewer = false;
    }
    this.init(moment().startOf('month').format('YYYYMMDD'), moment().endOf('month').format('YYYYMMDD'));
  }

}
