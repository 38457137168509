import { Injectable } from '@angular/core';
import { ApiAction, ApiProviderWinticplusService } from './api-provider-winticplus.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/Rx';

import { ClinicheDatabase } from '../entities/datibase/clinichedatabase-model';
import { Observer, merge } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { ExcelService } from './excel.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
export class ClinichedatabaseService {

  constructor(private _http: HttpClient) { }


  getCliniche(): any {
    return this._http.get(`${environment.gceAPI}/cliniche_db_unico/`, { responseType: 'json' });
  }


  getDirettoriSanitari(): any {
    return this._http.get(`${environment.gceAPI}/fmc_direttori_sanitari/`, { responseType: 'json' });
  }

  getAreaManagers(): any {
    return this._http.get(`${environment.identityUrl}/api/winticplusaccount/getusrsbyprofile?idp=9`);
  }

  getDistrictManagers(): any {
    return this._http.get(`${environment.identityUrl}/api/winticplusaccount/getusrsbyprofile?idp=41`);
  }

  getCBMs(): any {
    return this._http.get(`${environment.identityUrl}/api/winticplusaccount/getusrsbyprofile?idp=55`);
  }

  getClinicManagers(): any {
    return this._http.get(`${environment.identityUrl}/api/winticplusaccount/getusrsbyprofile?idp=7`);
  }

  getCompanies(): any {
    return this._http.get(`${environment.gceAPI}/companies`);
  }

  putClinica(pyld: any): any {
    console.log('FORMDATA for update %o',pyld);
  
    return this._http.put(`${environment.gceAPI}/cliniche_db_unico/` + pyld['code_c'], pyld, { responseType: 'json' });
    //return this._http.put(`http://localhost:57526/api/cliniche_db_unico/` + pyld['code_c'], pyld, { responseType: 'json' });
  }

  postClinica(pyld: any): any {
    return this._http.post(`${environment.gceAPI}/cliniche_db_unico/`, pyld, { responseType: 'json' });
  }

 getMedicsClinica(pyld: any): any {
    return this._http.get(`${environment.gceAPI}/fmc_medici_complete_fatturazione_clinica_by_clinic/` + pyld);
  }

  exportMailsCli(mails: any) {
    console.log('Export Cliniche for excel %o', mails);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    let fileName = `cliniche_db_${moment(mails[0].mese).format('MMMMYY')}`;

    let badgeForExcel: any[] = mails.map((bdg: ClinicheDatabase) => {
      let dt = '';
      let ret = {
        'CODICE CLINICA': bdg.code_c, 
        'NOME': bdg.nome,
        'INDIRIZZO': bdg.indirizzo,
        'COMUNE':bdg.comune,
        'CAP': bdg.cap,
        'INDICAZIONI': bdg.indicazioni,
        'EMAIL CLINIC MANAGER': bdg.email_clinic_manager,
        'EMAIL': bdg.email,
        'TELEFONO': bdg.tel,
        'AREA MANAGER': bdg.area_manager,
        'DISTRETTO': bdg.distretto,
        'DIRETTORE SANITARIO': bdg.direttore_sanitario,
        'ID DIRETTORE SANITARIO': bdg.id_direttore_sanitario,
        'RIUNITI': bdg.riuniti,
        'CLUSTER': bdg.cluster,
        'PROVINCIA': bdg.provincia,
        'MALL STREET': bdg.mall_street,
        'LATITUDINE': bdg.latitudine,
        'LONGITUDINE': bdg.longitudine,
        'REGIONE': bdg.regione,
        'COMPANY NAVISION': bdg.company_navision,
        'IP PRIVATO': bdg.ip_privato,
        'MASTER HOST': bdg.Master_Host,
        'DATA PRIMA FATTURA': moment(bdg.data_prima_fattura).format('DD-MM-YYYY'),
        'DATA INIZIO DENTALCASH': moment(bdg.data_inizio_dentalcash).format('DD-MM-YYYY'),
        'MESE APERTURA': moment(bdg.mese_apertura).format('DD-MM-YYYY'),
        'DATA APERTURA': moment(bdg.data_apertura).format('DD-MM-YYYY'),
        'DATA CHIUSURA': moment(bdg.data_chiusura).format('DD-MM-YYYY'),
        'DATA ACQUISIZIONE': moment(bdg.data_acquisizione).format('DD-MM-YYYY'),
        'BRAND': bdg.brand,
        'VINTAGE': bdg.vintage,
        'ESCLUSIONE FOLLOWME': bdg.esclusione_followme,
        'EMAIL AFFITTUARIO': bdg.email_affittuario,
        'PORTA SERVIZIO DENTALCASH': bdg.porta_servizio_dentalcash,
        'LOGIN AREA MANAGER': bdg.login_areamanager,
        'LOGIN DISTRICT MANAGER': bdg.login_districtmanager,
        'LEGAL ENTITY': bdg.legalentity,
        'SEDE LEGALE': bdg.sede_legale,
        'PARTITA IVA': bdg.partita_iva,
        'AMMINISTRATORE': bdg.ammnistratore,
        'DESCRIZIONE': bdg.descrizione,
        'FAX': bdg.fax,
        'WEBSITE':bdg.website,
        'SMS SENDER':bdg.sms_sender,
        'EMAIL SENDER':bdg.mail_sender,
        'EX LISTINO':bdg.ex_listino,
        'IBAN':bdg.iban,
        'CODICE FISCALE':bdg.codice_fiscale,
        'CBM':bdg.cbm,
        'LOGIN CBM':bdg.login_cbm
      };

      return ret;
    });

    const wsDetracts = XLSX.utils.json_to_sheet(badgeForExcel);
    var wscols = [
      { wch: 13 },
      { wch: 20 },
      { wch: 50 },
      { wch: 20 },
      { wch: 7 },
      { wch: 30 },
      { wch: 25 },
      { wch: 25 },
      { wch: 20 },
      { wch: 25 },
      { wch: 20 },
      { wch: 20 },
      { wch: 9 },
      { wch: 9 },
      { wch: 12 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 25 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 30 },
      { wch: 15 },
      { wch: 15 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 50 },
      { wch: 15 },
      { wch: 30 },
      { wch: 40 },
      { wch: 25 },
      { wch: 20 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 }
    ];
    wsDetracts['!cols'] = wscols;

    XLSX.utils.book_append_sheet(wb, wsDetracts, "dati cliniche");

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //save file
    const data: Blob = new Blob([wbout], { type: EXCEL_TYPE });
    //let fileName = `Dott. ${invoice.person.descrizione} - ${moment(invoice.ts_calcolo).format('MMMMYY')} -  ${invoice.clinic.nome}`;

    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
}

