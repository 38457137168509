import { Injectable } from '@angular/core';
import { ApiProviderWinticplusService } from '.';
import { ApiAction } from './api-provider-winticplus.service';
import { Articolo, SearchProductResponse, SearchProductItem, ConcessionSettings, SearchProductSettings, GetProductDetailResponse, GetProductDetailSettings, GetPosConfigurationResponse, ArticoliNegozio, GetTastieraVariantiSettings } from '../concession-system/domain/concession-system';
import { environment } from '../..//environments/environment';
import { DevicemanagerService } from './devicemanager.service';
import { AlertDialogService } from './alert-dialog.service';
import { map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { DataRepositoryService } from './data-repository.service';

@Injectable()
export class ConcessionService {

  constructor(
    private api: ApiProviderWinticplusService,
    private deviceManagerService: DevicemanagerService,
    private dialogService: AlertDialogService,
    private dataRepositoryService: DataRepositoryService
    ) {}

  private _baseSettings: ConcessionSettings = null;

  get baseSettings(){
    if(!this._baseSettings){
      const settings = environment['ConcessionSettings'];
      settings.web_box = this.deviceManagerService.machineName;
      this._baseSettings = settings;
    }
    return this._baseSettings;
  }

  public searchProduct = (filter: string, pageIndex: number) => {

      const apiAction = new ApiAction<{items: SearchProductItem[], totItems: number}>(environment.SearchProduct, (data:SearchProductResponse) => {
        return {
                  items: data.articoli,
                  totItems: data.numRigheTotali
               };
      }, 'post');

      const settings = {...this.baseSettings};
      (<SearchProductSettings>settings).stringToFind = filter;
      (<SearchProductSettings>settings).pagingParameterModel = {
        enabled: true,
        pageNumber: pageIndex,
        pageSize: environment['SEARCH_PRODUCT_PAGE_SIZE']
      };

      return this.api.makeAPICallGen<{items: SearchProductItem[], totItems: number}>(apiAction, settings).pipe(
          catchError(err => {
            this.dialogService.showError(err);
            return of(<{items: SearchProductItem[], totItems: number}>{ items: [], totItems: 0});
          })
      );
  }
  public getProductDetail = (idarticolo: number) => {

    const apiAction = new ApiAction<Articolo>(environment.GetProductDetail, (data:GetProductDetailResponse) => data.articoloTastiera, 'post');

    const settings = {...this.baseSettings};
    (<GetProductDetailSettings>settings).IdArticolo = idarticolo;

    return this.api.makeAPICallGen<Articolo>(apiAction, settings).pipe(
        catchError(err => {
          this.dialogService.showError(err);
          return of(<Articolo>null);
        })
    );
  }
  public getPosConfiguration = () => {
    const apiAction = new ApiAction<GetPosConfigurationResponse>(environment.GetPosConfiguration, (data:GetPosConfigurationResponse) => data, 'post');

    const settings = {...this.baseSettings};


    // !!!!! ATTENZIONE !!!!!!
    // Da cambiare !!!!!!!!!!!!!!!!!!
    settings.web_box = 'APP_COMANDE_01';
    settings.postazione.IdPostazione = 82;
    settings.postazione.stringID = 'APP_COMANDE_01';
    // *****************************

    return this.api.makeAPICallGen<GetPosConfigurationResponse>(apiAction, settings).pipe(
      catchError(err => {
        this.dialogService.showError(err);
        return of(<GetPosConfigurationResponse>null);
      })
    );
  }

  public getTastieraVarianti = (idTastieraVatianti: number) => {
    if(this.dataRepositoryService.tastiereVarianti[idTastieraVatianti]){
      return of(this.dataRepositoryService.tastiereVarianti[idTastieraVatianti]);
    }
    else{
      const apiAction = new ApiAction<ArticoliNegozio>(environment.GetTastieraVarianti, (data:ArticoliNegozio) => data, 'post');

      const settings = {...this.baseSettings};
      (<GetTastieraVariantiSettings>settings).IdTastieraVarianti = idTastieraVatianti;

          // !!!!! ATTENZIONE !!!!!!
    // Da cambiare !!!!!!!!!!!!!!!!!!
    settings.web_box = 'APP_COMANDE_01';
    settings.postazione.IdPostazione = 82;
    settings.postazione.stringID = 'APP_COMANDE_01';
    // *****************************


      return this.api.makeAPICallGen<ArticoliNegozio>(apiAction, settings).pipe(
        tap((value) => {
            this.dataRepositoryService.tastiereVarianti[idTastieraVatianti] = value;
        }),
        catchError(err => {
          this.dialogService.showError(err);
          return of(<ArticoliNegozio>null);
        })
      );
    }
  }
}
