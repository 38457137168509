import { Injectable } from '@angular/core';
import { MatDialog, MatListOption, MatDialogRef } from '@angular/material';
import { AlertDialogComponent, Alert } from '../dialogs/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent, Confirm } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { InputDialogComponent, InputForm } from "../dialogs/input-dialog/input-dialog.component";
import { Observable } from 'rxjs/Observable';

@Injectable()
export class AlertDialogService {
  static _dialog: MatDialog;
  
  constructor(private dialog: MatDialog) { 
    AlertDialogService._dialog = dialog;
  }

  public open(data: Alert): MatDialogRef<AlertDialogComponent> {
    return this.dialog.open(AlertDialogComponent, {
      data: data
    });
  }

  public confirm(data: Confirm, withTranslate: boolean = false): Observable<boolean> {
    return Observable.create(observer => {
      let dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: data
      });
      dialogRef.afterClosed().subscribe(result => {
        observer.next(result);
      });
    });
  }

  public input(data: InputForm, withTranslate: boolean = false): Observable<any> {
    return Observable.create(observer => {
      let dialogRef = this.dialog.open(InputDialogComponent, {
        data: data
      });
      dialogRef.afterClosed().subscribe(result => {
        observer.next(result);
      });
    });
  }

  public showError(err) {
    this.open({
      title: "Error",
      message: err.errorMessage,
      messageCode: err.errorCode,
      isError: true,
      error: err.error,
      labelErrorCode: err.labelErrorCode,
      withTranslate: true
    });
  }

  public static confirmDialog(data: Confirm, withTranslate: boolean = false): Observable<boolean> {
    return Observable.create(observer => {
      let dialogRef = AlertDialogService._dialog.open(ConfirmDialogComponent, {
        data: data
      });
      dialogRef.afterClosed().subscribe(result => {
        observer.next(result);
      });
    });
  }
}
