import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/observable/fromEvent';
import { DataRepositoryService } from "./data-repository.service";

export class Preferences {
  public preferencesTables:PreferencesTable[];
  public language: string;
  public calendar: CalendarPreferences
}

export class CalendarPreferences {
  public scale:number;
}

export class PreferencesTable {
  public tableName: string;
  public columnsVisible:string[];
}

@Injectable()
export class PreferencesService {
  preferences:Preferences;

  constructor(private dataRepo: DataRepositoryService) {
    let pref = [
      {
        tabella:"users",
        columnsVisible:[
          'Name', 'Description'
        ]
      }
    ]
  }

  save(preferences:Preferences):Observable<any>{
    console.log("preferences %o", preferences);
    return Observable.create(observer=>{
      let data={
        user:this.dataRepo.user.subject,
        preferences:preferences
      }
      console.log("data to save on localStorage %o", data);
      localStorage.setItem("preferences", JSON.stringify(data));
      observer.next("saved preferences on localStorage");
    });
  }

  saveLanguage(language:string){
    this.load().subscribe((pref:Preferences)=>{
      pref.language = language;
      this.save(pref).subscribe(response=>{
        //observer.next("saved language preferences");
        console.log("saved language preferences");
      });
    });
  }


  saveCalendarPreferences(calendarPref: CalendarPreferences) {
    this.load().subscribe((pref:Preferences)=>{
      pref.calendar = calendarPref;
      this.save(pref).subscribe(response=>{
        console.log("saved calendar preferences");
      });
    });
  }


  load():Observable<Preferences>{
    return Observable.create(observer=>{
      let data = JSON.parse(localStorage.getItem('preferences'));
      if(data){
        this.preferences = data['preferences'];
      }else{
        this.preferences = new Preferences();
      }
      observer.next(this.preferences);

    });
  }

  getTablePreferences(tableName:string):Observable<PreferencesTable>{
    return Observable.create(observer=>{
      this.load().subscribe((preferences:Preferences)=>{
        if(!preferences.preferencesTables){
          preferences.preferencesTables = [];
        }
        let preft = preferences.preferencesTables.find(item => item.tableName === tableName);
        if(!preft){
          preft = new PreferencesTable();
          preft.tableName = tableName;
          preft.columnsVisible = [];
          preferences.preferencesTables.push(preft);
        }
        observer.next(preft);
      });
    })
  }

  saveTablePreferences(preferencesTable: PreferencesTable):Observable<any>{
    return Observable.create(observer=>{
      let pt = this.preferences.preferencesTables.find(item=>item.tableName===preferencesTable.tableName);
      pt = preferencesTable;
      this.save(this.preferences).subscribe(response=>{
          observer.next("saved table preferences on localStorage");
      });
    });
  }

  /* cash */
  set(myProp: string, myObj: any): void {
    console.log("preferences concession %o", myObj);
    return Observable.create(observer=>{
      let data = {
        user: this.dataRepo.user.subject,
        concessionPreferences: myObj
      }
      console.log("data to save on localStorage %o", data);
      localStorage.setItem("concessionPreferences", JSON.stringify(data));
      observer.next("saved concession preferences on localStorage");
    });
  }
  get<T>(myProp: string): T {
    if (localStorage.getItem('concessionPreferences') == null) return null;
    var ls = JSON.parse(localStorage.getItem('concessionPreferences'))['concessionPreferences'];
    return ls[myProp];
  }

}
