import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatButtonModule } from '@angular/material';

@Component({
  selector: 'app-dialog-message-broadcast',
  templateUrl: './dialog-message-broadcast.component.html',
  styleUrls: ['./dialog-message-broadcast.component.css']
})
export class DialogMessageBroadcastComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogMessageBroadcastComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close(): void {
    this.dialogRef.close();
  }

  fDate(dt: any): string {
    var dti = new Date(dt);

    return dti.getDate() + '-' + (dti.getMonth() + 1) + '-' + dti.getFullYear();
  }

  getDescClinic(id: any) {
    //serach in data.dom
    //console.log('data.dom %o', this.data.dom);
    if (id.IDCLINIC == 0)
      return('Tutte');
    for (let a = 0; a < this.data.dom.length; a = a + 1) {
      if (this.data.dom[a].idDomain == id.IDCLINIC)
      {
        let ret = this.data.dom[a].name;
        if (ret.length > 25)
        {
          ret = ret.substring(0,22) + '... ';
        }
        return (ret);
      }
    }
    return id;
  }

  getDescProfile(id: any) {
    //search in data.pro
    //console.log('data.pro %o', this.data.pro);
    for (let a = 0; a < this.data.pro.length; a = a + 1) {
      if (this.data.pro[a].idProfile == id)
        return (this.data.pro[a].name)
    }
    return id;
  }

  ngOnInit() {
  }

}
