import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, AfterViewInit, OnChanges, SimpleChange } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {MatAutocompleteSelectedEvent} from '@angular/material';
import { CommonUtilsService } from '../../services/common-utils.service';

@Component({
  selector: 'app-form-field-select-search',
  templateUrl: './form-field-select-search.component.html',
  styleUrls: ['./form-field-select-search.component.css']
})
export class FormFieldSelectSearchComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() element: any;
  @Input() filteredElements: any[];
  @Input() sortBy: string;
  @Input() viewField: string | string[];
  @Input() fieldId: string;
  @Input() defaultAnotherSourceImg: string;
  @Input() placeholder: string;
  @Input() withoutImage:boolean = false;
  @Input() customImage:string;
  @Input() disabled:boolean = false;
  @Output() selectedItem:EventEmitter<any> = new EventEmitter<any>();
  @Output() filter: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('inputFilter', {static:false}) inputFilter: ElementRef;
  reverse:boolean = false;
  sort:boolean = true;
  filteredElementsAux: any[];
  valueInput:string;

  constructor() { }

  ngOnInit() {
    this.sort = !!this.sortBy;
  }

  selected(event: MatAutocompleteSelectedEvent){
    const value = event.option.value;
    let item = undefined;

    if(value[this.fieldId]) {
      item = this.filteredElements.find(i => i[this.fieldId] === value[this.fieldId]);
    }
    this.selectedItem.emit(item);
    this.inputFilter.nativeElement.blur();
    this.filteredElementsAux = [];
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}){
    if(changes['element'] && !!this.element){
      if(this.viewField instanceof Array){
        this.valueInput = this.viewField.map(vf=>{
          return this.element[vf];
        }).join(' - ');
      }else{
        this.valueInput = this.element[this.viewField];
      }
    }
    if(changes['filteredElements']) {
      this.filteredElementsAux = [];
      this.filteredElementsAux = this.filteredElements.map(element=>{
        let fen = CommonUtilsService.copyData(element);
        if(this.viewField instanceof Array){
          fen.valueInput = this.viewField.map(vf=>{
            return fen[vf];
          }).join(' - ');
        }else{
          fen.valueInput = fen[this.viewField];
        }
        return fen;
      });
    }
  }

  ngAfterViewInit(){
    if(this.inputFilter) {
      Observable.fromEvent(this.inputFilter.nativeElement, 'keyup')
          .debounceTime(500)
          .distinctUntilChanged()
          .subscribe((e:KeyboardEvent ) => {
            const code = e.keyCode || e.which;
            const value = this.inputFilter.nativeElement.value.toLowerCase();

            if(code < 37 || code > 40){
             this.filter.emit(value);
            }
          });
    }
  }
}
