import { Component, OnInit, Input, Output, ElementRef, ViewChild, AfterViewInit, EventEmitter, AfterViewChecked } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent, MatChipInputEvent} from '@angular/material';

@Component({
  selector: 'app-form-field-chip',
  templateUrl: './form-field-chip.component.html',
  styleUrls: ['./form-field-chip.component.css']
})
export class FormFieldChipComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @Input() elements: any[];
  @Input() filteredElements: any[];
  @Input() sortBy: string;
  @Input() viewField: string;
  @Input() fieldId: string;
  @Input() defaultAnotherSourceImg: string;
  @Input() withoutImage:boolean = false;
  @Output() filter: EventEmitter<any> = new EventEmitter<any>();
  @Output() added:EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('inputFilter', {static:false}) inputFilter: ElementRef;
  reverse:boolean = false;
  sort:boolean = true;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor() { }

  ngOnInit() {
    this.sort = !!this.sortBy;
  }

  ngAfterViewInit(){
    // this.filteredElements = this.elementsToFilter;

    console.log("this.elementsToFilter %o - this.fieldForAuto %o", this.viewField);

    if(this.inputFilter) {
      Observable.fromEvent(this.inputFilter.nativeElement, 'keyup')
          .debounceTime(150)
          .distinctUntilChanged()
          .subscribe((e:KeyboardEvent ) => {
            const code = e.keyCode || e.which;
            // console.log("code: %o %o %o", e, e.keyCode, e.which);
            //const code
            if(code < 37 || code > 40){
              const filterValue = this.inputFilter.nativeElement.value;
              this.filter.emit(filterValue);
            }
          //   console.log("filterValue %o", filterValue);
          //   if(filterValue){
          //     this.filteredElements = this.elementsToFilter.filter(element => element[this.fieldForAuto].toLowerCase().indexOf(filterValue)!=-1);
          //     console.log("this.filteredElements %o", this.filteredElements);
          //   }else{
          //     this.filteredElements = this.elementsToFilter;
          //   }
          });
    }
  }

  ngAfterViewChecked() {

  }

  removeElement(item){
    let index = this.elements.findIndex(i => i[this.fieldId] === item[this.fieldId]);
    this.elements.splice(index, 1);
  }

  tokenEnd(event: MatChipInputEvent){
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      let item = this.filteredElements.find(i => i[this.viewField].toLowerCase() === value.toLowerCase());
      if(item){
        this.addElement(item);
      } else {
        item = {};
        item[this.fieldId] = value;
        item[this.viewField] = value;
        this.addElement(item);
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  private addElement(item) {
    if(!this.elements){
      this.elements = [];
    }
    // console.log("addElement %o %o",item, this.elements);
    if( item[this.fieldId] ){
      if(this.elements.findIndex(i => i[this.fieldId] === item[this.fieldId]) === -1){
        this.elements.push(item);
        this.added.emit(this.elements);
      }
    }else if( item['ExternalRifList'] ){
      if(this.elements.filter(e=>e['ExternalRifList']).findIndex(i => i['ExternalRifList'][0]['ExternalCode'] === item['ExternalRifList'][0]['ExternalCode']) === -1){
        this.elements.push(item);
        this.added.emit(this.elements);
      }
    }
    // console.log("addElement item %o elements %o", item, this.elements);
  }

  selected(event: MatAutocompleteSelectedEvent){
    const value = event.option.value;
    // console.log("event select: %o %o %o", this.filteredElements, value, this.fieldId);
    let item = undefined;
    if(value[this.fieldId]) {
      item = this.filteredElements.find(i => i[this.fieldId] === value[this.fieldId]);
    } else if( value['ExternalRifList'] ) {
      item = this.filteredElements.filter(e=>e['ExternalRifList']).find(i => {
        // console.log("find %o %o", i, value);
        return i['ExternalRifList'][0]['ExternalCode'] === value['ExternalRifList'][0]['ExternalCode'];
      });
    }
    // console.log("item %o", item);
    if(item){
      this.addElement(item);
    }
    this.inputFilter.nativeElement.value = '';
    this.inputFilter.nativeElement.blur();

  }
}
