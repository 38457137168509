import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AppConfig } from './../app.config';


@Injectable()
export class ClockService {

  private clock: Observable<Date>;
  private clockReal: Observable<Date>;
  public startDate: Date;
  public startDateReal: Date;
  public DescrStoreId: string;
  public now: Date;
  public nowReal: Date;
  public a: number;
  public z: number;


  constructor(private http: HttpClient) {

    //console.log(' call getBasketValues with tempTicketsResume %o', payloadGetBasketValue);
    this.nowReal = new Date();
    this.a = 0;
    this.z = 0;

    this.clock = Observable.interval(1000).map(tick => {
      this.a = this.a + 1;

      if (this.startDate != null) {
        let b = this.startDate.getTime();
        //console.log('return date %o milliseconds %o a %o',this.startDate, b ,this.a);
        this.now = new Date(b + 1000 * this.a);
        return new Date(b + 1000 * this.a);
      }
      else
        return new Date(1000 * this.a);
    }).share();

    this.clockReal = Observable.interval(1000).map(tick => {
      this.z = this.z + 1;

      if (this.startDateReal != null) {
        let b = this.startDateReal.getTime();
        //console.log('return date %o milliseconds %o a %o',this.startDate, b ,this.a);
        this.nowReal = new Date(b + 1000 * this.z);
        return new Date(b + 1000 * this.z);
      }
      else
        return new Date(1000 * this.z);
    }).share();

  }

  getClock(): Observable<Date> {
    //console.log('++++++++++++++---------------------------------++++++++++++++++++++++++++ GET CLOCK clock %o clockReal %o now %o nowReal %o',this.clock, this.clockReal, this.now, this.nowReal);
    return this.clock;
  }

  getClockReal(): Observable<Date> {
    //console.log('++++++++++++++---------------------------------++++++++++++++++++++++++++ GET CLOCKREAL clock %o clockReal %o now %o nowReal %o',this.clock, this.clockReal, this.now, this.nowReal);
    return this.clockReal;
  }

  getTimeNow(): Date {
    //console.log('++++++++++++++---------------------------------++++++++++++++++++++++++++ GET TIMENOW now %o nowReal %o',this.now, this.nowReal);
    return this.now;
  }

  getRealTimeNow(): Date {
    //console.log('++++++++++++++---------------------------------++++++++++++++++++++++++++ GET TIMENOWREAL now %o nowReal %o',this.now, this.nowReal);
    return this.nowReal;
  }

  setClock(storeId) {
    //console.log('++++++++++++++---------------------------------++++++++++++++++++++++++++ SET CLOCK');
    let payloadGetBasketValue = {
      StoreId: storeId
    };
    this.DescrStoreId = '';
    if (AppConfig.settings.apiWinTicDomain != null && AppConfig.settings.apiWinTicDomain.length > 0) {
      return this.http.post(AppConfig.settings.apiWinTicDomain + 'getCurrentDateTime', payloadGetBasketValue).map(data => {
        //console.log('data in clock service %o data %o',data, new Date(data['NowMmssSrvDT']));
        this.startDate = new Date(data['NowMmssSrvDT']);
        this.startDateReal = new Date(data['NowMmssSrvDT']);
        this.DescrStoreId = data['DescrStore'];
      });
    }
    else {
      //console.log('data in clock service %o', new Date(Date.now()));
      this.startDate = new Date(Date.now());
      this.startDateReal = new Date(Date.now());
    }
  }

  setClockByTime(newTime) {
    //console.log('++++++++++++++---------------------------------++++++++++++++++++++++++++ SET CLOCK BY TIME');
    this.startDate = new Date(newTime);
  }

}