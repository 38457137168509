import { Component, OnInit, NgModule, Pipe, PipeTransform, Injectable, ViewContainerRef, Input, HostListener, ViewEncapsulation, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MatFormFieldModule, MatDialogRef, MatDialog } from '@angular/material';
import { AlertDialogService } from '../../services';

@Component({
  selector: 'numeric-keyboard',
  templateUrl: './numeric-keyboard.component.html',
  styleUrls: ['./numeric-keyboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
  ]
})
export class NumericKeyboardComponent {

  @Output() valueAvailable = new EventEmitter<number>();

  digits: Array<string> = ['7', '8', '9', '4', '5', '6', '1', '2', '3', '0', '.'];
  weight:string = '';

  constructor(
    private alertService: AlertDialogService
  ) {
  }

  addDigit = (digit:string) => {
    this.weight += digit;
  }
  insert = () => {
    if(this.weight === ''){
      this.alertService.open({
        message: 'Si prega di inserire un valore',
        isError: true
      });
      return;
    }
    if(this.weight.endsWith('.')){
      this.weight = this.weight.substring(0, this.weight.length - 1);
    }
    this.valueAvailable.emit((parseFloat(parseFloat(this.weight).toFixed(2))));
  }
}
