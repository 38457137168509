import { Component, NgModule, ViewContainerRef } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { MatDialogModule, MatButtonModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import * as Us from '../../global/model/user-preferences/user-preferences.model';

@Component({
  selector: 'layoutDialog',
  templateUrl: './layout.dialog.html',
  styleUrls: ['./layout.dialog.scss'],
})
export class LayoutDialog {
  selected;
  user;

  constructor(
    public dialogRef2: MatDialogRef<any>
  ) { }

  closePopup(): void {
    this.dialogRef2.close();
  }

  confirmLayout() {
    let users:Us.User[];
    users = JSON.parse(localStorage.getItem('users'));
    for (let u of users)
      if (u.operatorId == this.user) {
        u.layout = this.selected + "";
        localStorage.setItem('users', JSON.stringify(users));
        break;
      }
    this.closePopup();
  }

  selectTheme(theme: number) {
    this.selected = theme;
  }
}