import { primaryKey, editableOnNew, headerCode, inputType, required, length, order, pattern, editable, Describer, visible, GceEntity, textAlign } from './gce-management';

/**
 * GceMedicType -> table: fmc_tipimedico
 */
export class GceProvCodes extends GceEntity {
    @primaryKey()
    @headerCode('GCE.GCEMEDICTYPE.TIPO_MEDICO')
    @inputType('text')
    @editable(false)
    @editableOnNew(false)
    @length(2)
    @order(1)
    cod_prov: string;

    @headerCode('GCE.GCEMEDICTYPE.DESCRIZIONE')
    @inputType('text')
    @required()
    @editable(true)
    @length(64)
    @order(2)
    descrizione: string;

    @headerCode('GCE.GCEMEDICTYPE.GRUPPO_ARTICOLO_NAV_PREST')
    @inputType('text')
    @required()
    @editable(true)
    @length(64)
    @order(3)
    regione: string;

    constructor();
    constructor(cod_prov: string, descrizione: string, regione: string);
    constructor(cod_prov?: string, descrizione?: string, regione?: string){
        super();
        this.cod_prov = cod_prov || 'XX';
        this.descrizione = descrizione || '';
        this.regione = regione || '';
    }

    static getStructure(): Array<any> {
        let structure: any[] = Describer.getStructure(new  GceProvCodes());
        structure.push(...this.getActions());
        return structure;
    }
    protected static getActions(): any[] {
        let actions: any[] = [];
        return actions;
    }
}