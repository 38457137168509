import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { LoaderService } from '../loader';
import { DataRepositoryService } from './data-repository.service';
import { environment } from '../../environments/environment';
import { AlertDialogService } from "./alert-dialog.service";
import { BaseResponseApi } from '../concession-system/domain/concession-system';
import { AppConfig } from './../app.config';

export class ApiAction<T> {
  endpoint: string;
  mapper: (data: any) => T;
  action: string;

  constructor(
    endpoint: string,
    mapper: (dta: any) => T,
    action: string
  ) {
    this.endpoint = endpoint;
    this.mapper = mapper;
    this.action = action;
  }
}

@Injectable()
export class ApiProviderWinticplusService {

  constructor(private http: HttpClient, private loaderService: LoaderService,
    private dataRepo: DataRepositoryService, private alertDialog: AlertDialogService) { }

  makeAPICall(action: string | ApiAction<any>, query: object = {}, showLoader: boolean = true, responseType: any = 'json'): Observable<any> {
    console.log("apicall action %o query %o", action,query);
    let pathControl="";
    if (query['structureAction'] != null)
    {
      console.log("apicall path %o", query['structureAction'].path);
      pathControl=query['structureAction'].path;
    }
    let self = this;
    let actionHelper: any = action instanceof ApiAction ? action : this.getActions()[action];
    console.log("actionHelper %o", actionHelper);
    if (showLoader) {
      this.loaderService.show();
    }

    return Observable.create(observer => {
      if (actionHelper) {
        let endpoint = actionHelper['endpoint'];
        let content: any = query;
        if(pathControl.indexOf("patient")>=0&&action.toString().indexOf("actionGetall")>=0)
        {
          //let'sgetPatients
          endpoint=endpoint.replace("https://identity.dentalpro.it","https://ssopazienti.dentalpro.it");
          console.log("ENDPOINT %o", endpoint);
        }
        const httpOptions = {
          responseType: responseType
        }
        if (query['resource']) {
          endpoint = endpoint.replace('resource', query['resource']);
        }
        if (query['content']) content = query['content'];
        if (query['action']) {
          endpoint = endpoint.replace('action', query['action']['name']);
          actionHelper['action'] = query['action']['method'].toLowerCase();
        }
        let api: Observable<Object>;
        if (actionHelper.action === 'post') {
          if (!query['urlParams']) query['urlParams'] = "";
          endpoint += query['urlParams'];
          api = self.http.post(endpoint, content, httpOptions);
        } else if (actionHelper.action === 'get') {
          if (!query['urlParams']) query['urlParams'] = "";
          if (!query['queryParams']) query['queryParams'] = "";
          endpoint += query['urlParams'] + query['queryParams'];
          api = self.http.get(endpoint);
        } else if (actionHelper.action === 'delete') {
          if (!query['urlParams']) query['urlParams'] = "";
          if (!query['queryParams']) query['queryParams'] = "";
          endpoint += query['urlParams'] + query['queryParams'];
          api = self.http.delete(endpoint);
        } else if (actionHelper.action === 'put') {
          if (!query['urlParams']) query['urlParams'] = "";
          if (!query['queryParams']) query['queryParams'] = "";
          endpoint += query['urlParams'] + query['queryParams'];
          api = self.http.put(endpoint, content, httpOptions);
        } else {
          observer.error({ errorCode: 498, errorMessage: 'not implemented' });
          if (showLoader) {
            this.loaderService.hide();
          }
          return;
        }
        api.subscribe(
          data => {
            const mappedData = actionHelper.mapper(data);
            // console.log("data %o - mappedData %o", data, mappedData);
            if (mappedData && (!mappedData['errorCode'] || mappedData['errorCode'] === 'WP.API.OK')) {
              if (mappedData.length === 0) {
                // console.log("mappedData complete");
                observer.next(mappedData);
                observer.complete();
                if (showLoader) {
                  this.loaderService.hide();
                }
              } else {
                observer.next(mappedData);
                if (showLoader) {
                  this.loaderService.hide();
                }
                // console.log("mappedData next");
              }
            } else if (mappedData) {
              if (!environment['production']) {
                this.alertDialog.open({
                  title: "API Provider Error",
                  message: `Api error: <br><br> Api Response: ${JSON.stringify(mappedData)} <br><br> actionHelper: ${JSON.stringify(actionHelper)}`,
                  isError: true
                });
              }

              if (showLoader) {
                this.loaderService.hide();
              }

              this.alertDialog.open({
                title: "WP.API.APIPROVIDER.ALERT.TITLE",
                message: mappedData['errorMessage'],
                messageCode: mappedData['errorCode'],
                labelErrorCode: mappedData['labelErrorCode'],
                isDetail: mappedData['isDetail'],
                detail: mappedData['detail'],
                isError: true,
                withTranslate: true
              });

              observer.error(mappedData);

            }
            else {
              if (!environment['production']) {
                this.alertDialog.open({
                  title: "API Provider Error",
                  message: `Api response undefined: <br><br>actionHelper: ${JSON.stringify(actionHelper)}`,
                  isError: true
                });
              }
              observer.error({ errorCode: 496, errorMessage: 'api error' });
              if (showLoader) {
                this.loaderService.hide();
              }
            }

          },
          err => {
            console.log("makeApiCall %o", err);
            if (err.status === 400) {
              if (showLoader) {
                this.loaderService.hide();
              }

              this.alertDialog.open({
                title: "WP.API.APIPROVIDER.ALERT.TITLE",
                labelErrorCode: err.error.labelErrorCode,
                message: err.error.errorMessage,
                isDetail: err.error['isDetail'],
                detail: err.error['detail'],
                isError: true,
                withTranslate: true
              });

              observer.error(err);
            } else if (err.status === 404) {
              if (showLoader) {
                this.loaderService.hide();
              }

              this.alertDialog.showError(err);

              observer.error(err);

            } else if (err.status === 500) {
              if (showLoader) {
                this.loaderService.hide();
              }
              this.alertDialog.open({
                title: "WP.API.APIPROVIDER.ERROR.500.TITLE",
                message: "WP.API.APIPROVIDER.ERROR.500.MESSAGE",
                isError: true,
                withTranslate: true
              });
              observer.error(err);

            } else {
              if (showLoader) {
                this.loaderService.hide();
              }
              if (!environment['production']) {
                this.alertDialog.open({
                  title: "API Provider Error",
                  message: `Api call error: <br><br>Error: ${JSON.stringify(err)}<br><br> actionHelper: ${JSON.stringify(actionHelper)}`,
                  isError: true
                });
              }
              // console.log(err);
              observer.error({ errorCode: 497, error: err });
            }

          });
      } else {
        if (!environment['production']) {
          this.alertDialog.open({
            title: "API Provider Error",
            message: "Action undefined",
            isError: true
          });
        }

        observer.error({ errorCode: 499 }); //todo check error codes
        if (showLoader) {
          this.loaderService.hide();
        }
      }
    });
  }

  makeAPICallGen<T>(action: string | ApiAction<T>, query: object = {}, showLoader: boolean = true, responseType: any = 'json'): Observable<T> {
    const self = this;

    const actionHelper: ApiAction<T> = action instanceof ApiAction ? action : this.getActions()[action];
    if (showLoader) {
      this.loaderService.show();
    }

    return Observable.create(observer => {
      if (actionHelper) {
        let endpoint = actionHelper['endpoint'];
        let content: any = query;
        if (query['resource']) {
          endpoint = endpoint.replace('resource', query['resource']);
        }
        if (query['content']) content = query['content'];
        if (query['action']) {
          endpoint = endpoint.replace('action', query['action']['name']);
          actionHelper['action'] = query['action']['method'].toLowerCase();
        }
        let api: Observable<BaseResponseApi>;
        if (actionHelper.action === 'post') {
          // console.log("my query: %o", content);
          // console.log("my query claims: %o", this.userService.user);
          content['DomainId'] = this.dataRepo.user.domain;
          content['StoreId'] = this.dataRepo.user.StoreId;
          content['OperatorSub'] = this.dataRepo.user.sub;
          content['Schema'] = "CINEMA";
          // console.log("post %o %o", endpoint, content);
          const httpOptions = {
            responseType: responseType
          }
          api = <any>self.http.post(endpoint, content, httpOptions);
        } else if (actionHelper.action === 'get') {
          if (!query['urlParams']) query['urlParams'] = "";
          if (!query['queryParams']) query['queryParams'] = "";
          endpoint += query['urlParams'] + query['queryParams'];
          api = <any>self.http.get(endpoint);
        } else if (actionHelper.action === 'delete') {
          if (!query['urlParams']) query['urlParams'] = "";
          if (!query['queryParams']) query['queryParams'] = "";
          endpoint += query['urlParams'] + query['queryParams'];
          api = <any>self.http.delete(endpoint);
        } else {
          observer.error({ errorCode: 498, errorMessage: 'not implemented' });
          if (showLoader) {
            this.loaderService.hide();
          }
          return;
        }
        api.subscribe(
          data => {
            if (data && (!data.errorCode || data.errorCode === '0')) {
              const mappedData = actionHelper.mapper(data);
              observer.next(mappedData);
              observer.complete();
              if (showLoader) {
                this.loaderService.hide();
              }

            } else if (data) {
              if (!environment['production']) {
                this.alertDialog.open({
                  title: "API Provider Error",
                  message: `Api error: <br><br> Api Response: ${JSON.stringify(data)} <br><br> actionHelper: ${JSON.stringify(actionHelper)}`,
                  isError: true
                });
              }
              observer.error(data);
              if (showLoader) {
                this.loaderService.hide();
              }
            }
            else {
              if (!environment['production']) {
                this.alertDialog.open({
                  title: "API Provider Error",
                  message: `Api response undefined: <br><br>actionHelper: ${JSON.stringify(actionHelper)}`,
                  isError: true
                });
              }
              observer.error({ errorCode: 496, errorMessage: 'api error' });
              if (showLoader) {
                this.loaderService.hide();
              }
            }

          },
          err => {
            if (!environment['production']) {
              this.alertDialog.open({
                title: "API Provider Error",
                message: `Api call error: <br><br>Error: ${JSON.stringify(err)}<br><br> actionHelper: ${JSON.stringify(actionHelper)}`,
                isError: true
              });
            }
            // console.log(err);
            observer.error({ errorCode: 497, error: err });
            if (showLoader) {
              this.loaderService.hide();
            }
          });
      } else {
        if (!environment['production']) {
          this.alertDialog.open({
            title: "API Provider Error",
            message: "Action undefined",
            isError: true
          });
        }

        observer.error({ errorCode: 499 }); //todo check error codes
        if (showLoader) {
          this.loaderService.hide();
        }
      }
    });
  }

  getActions(): any {
    const actions = {
      action: '?', //will be set dynamically
      getNavigationList: {
        endpoint: AppConfig.settings.identityServerEndpoint + "winticplusnavigation/getnavigationtree/",
        mapper: (data) => {
          let comparator = (a, b) => { a.order > b.order };
          if (data['nodes'] && data['nodes'][0]) {
            let comparator = (a, b) => { a.order > b.order };
            data['nodes'].sort(comparator);
            data['nodes'][0].controllers.sort(comparator);
            return data['nodes'][0]; //temporarily get the first application
          }
          return undefined;
        },
        action: 'get'

      },
      getNavigationByControllerList: {
        endpoint: AppConfig.settings.identityServerEndpoint + "winticplusnavigation/getactionstreebycontroller/",
        mapper: (data) => {
          let comparator = (a, b) => { a.order > b.order };
          if (data['nodes'] && data['nodes'][0]) {
            let comparator = (a, b) => { a.order > b.order };
            data['nodes'].sort(comparator);
            data['nodes'][0].controllers[0].actions.sort(comparator);
            return data['nodes'][0].controllers[0].actions; //temporarily get the first application
          }
          return undefined;
        },
        action: 'get'

      },
      actionGetById: {
        endpoint: AppConfig.settings.identityServerEndpoint + "resource/get/",
        mapper: (data) => {
          return data;
        },
        action: 'get'
      },
      actionGetall: {
        endpoint: AppConfig.settings.identityServerEndpoint + "resource/getall",
        mapper: (data) => {
          return data;
        },
        action: 'get'
      },
      actionGetallPatients: {
        endpoint: AppConfig.settings.identityServerPatientEndpoint + "resource/getall",
        mapper: (data) => {
          return data;
        },
        action: 'get'
      },
      actionGetStructure: {
        endpoint: AppConfig.settings.identityServerEndpoint + "resource/getstructure",
        mapper: (data) => {
          return data;
        },
        action: 'get'
      },
      actionDelete: {
        endpoint: AppConfig.settings.identityServerEndpoint + "resource/delete",
        mapper: (data) => {
          return data;
        },
        action: 'delete'
      },
      actionUpdate: {
        endpoint: AppConfig.settings.identityServerEndpoint + "resource/post",
        mapper: (data) => {
          return data;
        },
        action: 'post'
      },
      actionUpdatePatient: {
        endpoint: "https://ssopazienti.dentalpro.it/api/winticplusaccount/post",
        mapper: (data) => {
          return data;
        },
        action: 'post'
      },
      resetPassword: {
        endpoint: AppConfig.settings.identityServerEndpoint + "winticplusaccount/resetpassword",
        mapper: (data) => {
          return data;
        },
        action: 'post'
      },
      resetMFA: {
        endpoint: AppConfig.settings.identityServerEndpoint + "winticplusaccount/resetmfa",
        mapper: (data) => {
          return data;
        },
        action: 'post'
      },
      actionGeneric: {
        endpoint: AppConfig.settings.identityServerEndpoint + "resource/action",
        mapper: (data) => {
          if (typeof data === "boolean") {
            if (data) {
              return true;
            } else {
              return {
                errorCode: 491
              }
            }
          }
          return data;
        },
        action: '?' //will be set dynamically
      },
    }
    return actions;
  }
}
