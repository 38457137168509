import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { LoaderService } from '../loader';
import * as MySettings from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NotificationsService } from 'angular2-notifications';
import { AuthGuardService } from './auth-guard.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { isString } from 'util';

@Injectable()

export class ForecastsService {
  backup;
  isError;
  idErrorClinic;
  isError1;
  idErrorClinic1;
  isError2;
  idErrorClinic2;
  isError3;
  idErrorClinic3;
  isError4;
  idErrorClinic4;
  isError5;
  idErrorClinic5;

  constructor(
    public _loaderRogges: LoaderService,
    public _http: HttpClient,
    public _notifications: NotificationsService,
    public _auth: AuthGuardService,
    public _router: Router
  ) {
    this.mesi = []; this.anni = [];
    this.mesi[0] = "Gennaio";
    this.mesi[1] = "Febbraio";
    this.mesi[2] = "Marzo";
    this.mesi[3] = "Aprile";
    this.mesi[4] = "Maggio";
    this.mesi[5] = "Giugno";
    this.mesi[6] = "Luglio";
    this.mesi[7] = "Agosto";
    this.mesi[8] = "Settembre";
    this.mesi[9] = "Ottobre";
    this.mesi[10] = "Novembre";
    this.mesi[11] = "Dicembre";
    var ind = 0; for (var i = new Date().getFullYear(); i < new Date().getFullYear() + 20; i++) this.anni[ind++] = i;
    this.isError = false;
    this.idErrorClinic = [];
    this.isError1 = false;
    this.idErrorClinic1 = [];
    this.isError2 = false;
    this.idErrorClinic2 = [];
    this.isError3 = false;
    this.idErrorClinic3 = [];
    this.isError4 = false;
    this.idErrorClinic4 = [];
    this.isError5 = false;
    this.idErrorClinic5 = [];
  }

  forecastObject = [];
  mesi; anni;

  updateForecast(x) {
    this.isError = false;
    this.idErrorClinic = [];
    this.isError1 = false;
    this.idErrorClinic1 = [];
    this.isError2 = false;
    this.idErrorClinic2 = [];
    this.isError3 = false;
    this.idErrorClinic3 = [];
    this.isError4 = false;
    this.idErrorClinic4 = [];
    this.isError5 = false;
    this.idErrorClinic5 = [];
    //console.log('update forecast FIRST %o', this.forecastObject);
    this.prepareObject(this.forecastObject);
    //console.log('update forecast SECOND %o', this.forecastObject);
    if (this.isError == true || this.isError1 == true || this.isError2 == true || this.isError3 == true || this.isError4 == true || this.isError5 == true) {
      let str = '';
      if (this.isError == true) {
        str = 'Il totale dei valori di TARGET per ruolo non corrisponde al totale di clinica codici ';
        for (let a = 0; a < this.idErrorClinic.length; a = a + 1) {
          if (a == (this.idErrorClinic.length - 1))
            str = str + this.idErrorClinic[a];
          else
            str = str + this.idErrorClinic[a] + ', ';
        }
      }
      if (this.isError1 == true) {
        if (str.length == 0)
          str = str + '\n';
        str = str + 'Il totale dei valori di FATTURATO MESE per ruolo non corrisponde al totale di clinica codici ';
        for (let a = 0; a < this.idErrorClinic1.length; a = a + 1) {
          if (a == (this.idErrorClinic.length - 1))
            str = str + this.idErrorClinic1[a];
          else
            str = str + this.idErrorClinic1[a] + ', ';
        }
      }
      if (this.isError2 == true) {
        if (str.length == 0)
          str = str + '\n';
        str = str + 'Il totale dei valori di ENTRATE SICURE NEL MESE per ruolo non corrisponde al totale di clinica codici ';
        for (let a = 0; a < this.idErrorClinic2.length; a = a + 1) {
          if (a == (this.idErrorClinic2.length - 1))
            str = str + this.idErrorClinic2[a];
          else
            str = str + this.idErrorClinic2[a] + ', ';
        }
      }
      if (this.isError3 == true) {
        if (str.length == 0)
          str = str + '\n';
        str = str + 'Il totale dei valori di DILAZIONI DA INSERIRE per ruolo non corrisponde al totale di clinica codici ';
        for (let a = 0; a < this.idErrorClinic3.length; a = a + 1) {
          if (a == (this.idErrorClinic3.length - 1))
            str = str + this.idErrorClinic3[a];
          else
            str = str + this.idErrorClinic3[a] + ', ';
        }
      }
      if (this.isError4 == true) {
        if (str.length == 0)
          str = str + '\n';
        str = str + 'Il totale dei valori di DILAZIONI DA LIQUIDARE per ruolo non corrisponde al totale di clinica codici ';
        for (let a = 0; a < this.idErrorClinic4.length; a = a + 1) {
          if (a == (this.idErrorClinic4.length - 1))
            str = str + this.idErrorClinic4[a];
          else
            str = str + this.idErrorClinic4[a] + ', ';
        }
      }
      if (this.isError5 == true) {
        if (str.length == 0)
          str = str + '\n';
        str = str + 'Il totale dei valori di PREVISIONI FINE MESE per ruolo non corrisponde al totale di clinica codici ';
        for (let a = 0; a < this.idErrorClinic5.length; a = a + 1) {
          if (a == (this.idErrorClinic5.length - 1))
            str = str + this.idErrorClinic5[a];
          else
            str = str + this.idErrorClinic5[a] + ', ';
        }
      }
      window.alert(str);
      this._loaderRogges.hide();
    }

    else {
      let req = {
        "idProfile": this._auth.getProfile(),
        "idDomain": this._auth.getDomain(),
        "sub": this._auth.getGUID(),
        "obj": this.forecastObject
      };
      console.log('req for update forecast %o', req);
      return this._http.post(
        MySettings.environment.UpdateForecast,

        {
          "idProfile": this._auth.getProfile(),
          "idDomain": this._auth.getDomain(),
          "sub": this._auth.getGUID(),
          "obj": this.forecastObject
        }

      );
    }
  }

  prepareObject(rows) {
    //console.log('rows before POST %o', rows);
    var o = [];
    var ids = []; var ids2 = []; var ids3 = [];
    //check sums
    let totalToCheck = 0;
    let partialToCheck = 0;
    let totalToCheck1 = 0;
    let partialToCheck1 = 0;
    let totalToCheck2 = 0;
    let partialToCheck2 = 0;
    let totalToCheck3 = 0;
    let partialToCheck3 = 0;
    let totalToCheck4 = 0;
    let partialToCheck4 = 0;
    let totalToCheck5 = 0;
    let partialToCheck5 = 0;
    var idc;
    for (let r of rows) {
      if (r['first']) {
        if (isString(r['NFORECASTENTMONTH'])) {
          r['NFORECASTENTMONTH'] = parseFloat(r['NFORECASTENTMONTH'].replace('€', '').replace(',', '.'));
        }
      }
    }

    if (!((this._auth.getUser()['ruolo'].toLowerCase().indexOf("direzione commerciale") >= 0) || (this._auth.getUser()['ruolo'].toLowerCase().indexOf("amministratore") >= 0))) {
      for (let r of rows) {
        //for now assume that it's ordered
        if (r['first']) {
          //checkPrevious
          /*
          console.log('check row 0 %o with %o', totalToCheck, partialToCheck);
          console.log('check row 1 %o with %o', totalToCheck1, partialToCheck1);
          console.log('check row 2 %o with %o', totalToCheck2, partialToCheck2);
          console.log('check row 3 %o with %o', totalToCheck3, partialToCheck3);
          console.log('check row 4 %o with %o', totalToCheck4, partialToCheck4);
          console.log('check row 5 %o with %o', totalToCheck5, partialToCheck5);
          */

          if ((totalToCheck - partialToCheck > 1) || (totalToCheck - partialToCheck < -1) || (partialToCheck - totalToCheck > 1) || (partialToCheck - totalToCheck < -1)) {
            this.isError = true;
            this.idErrorClinic.push(idc);
          }
          /*
          if ((totalToCheck1 - partialToCheck1 > 1) || (totalToCheck1 - partialToCheck1 < -1) || (partialToCheck1 - totalToCheck1 > 1) || (partialToCheck1 - totalToCheck1 < -1)) {
            this.isError1 = true;
            this.idErrorClinic1.push(idc);
          }
          if ((totalToCheck2 - partialToCheck2 > 1) || (totalToCheck2 - partialToCheck2 < -1) || (partialToCheck2 - totalToCheck2 > 1) || (partialToCheck2 - totalToCheck2 < -1)) {
            this.isError2 = true;
            this.idErrorClinic2.push(idc);
          }
          if ((totalToCheck3 - partialToCheck3 > 1) || (totalToCheck3 - partialToCheck3 < -1) || (partialToCheck3 - totalToCheck3 > 1) || (partialToCheck3 - totalToCheck3 < -1)) {
            this.isError3 = true;
            this.idErrorClinic3.push(idc);
          }
          if ((totalToCheck4 - partialToCheck4 > 1) || (totalToCheck4 - partialToCheck4 < -1) || (partialToCheck4 - totalToCheck4 > 1) || (partialToCheck4 - totalToCheck4 < -1)) {
            this.isError4 = true;
            this.idErrorClinic4.push(idc);
          }
          if ((totalToCheck5 - partialToCheck5 > 1) || (totalToCheck5 - partialToCheck5 < -1) || (partialToCheck5 - totalToCheck5 > 1) || (partialToCheck5 - totalToCheck5 < -1)) {
            this.isError5 = true;
            this.idErrorClinic5.push(idc);
          }
          */
          idc = r['IDAMCLINIC2'];
          totalToCheck = +r['NTARGET'];
          partialToCheck = 0;
          totalToCheck1 = +r['INVOICED'];
          partialToCheck1 = 0;
          totalToCheck2 = +r['NSECURITYENTRY'];
          partialToCheck2 = 0;
          totalToCheck3 = +r['NEDELAYTOINSERT'];
          partialToCheck3 = 0;
          totalToCheck4 = +r['NDELAYTOPAY'];
          partialToCheck4 = 0;
          totalToCheck5 = +r['NFORECASTENTMONTH'];
          partialToCheck5 = 0;
        }
        else {
          let v = +r['NTARGET'];
          partialToCheck = partialToCheck + v;
          let v1 = +r['INVOICED'];
          partialToCheck1 = partialToCheck1 + v1;
          let v2 = +r['NSECURITYENTRY'];
          partialToCheck2 = partialToCheck2 + v2;
          let v3 = +r['NEDELAYTOINSERT'];
          partialToCheck3 = partialToCheck3 + v3;
          let v4 = +r['NDELAYTOPAY'];
          partialToCheck4 = partialToCheck4 + v4;
          let v5 = +r['NFORECASTENTMONTH'];
          partialToCheck5 = partialToCheck5 + v5;
        }
      }
      /*
      console.log('last check row 0 %o with %o', totalToCheck, partialToCheck);
      console.log('last check row 1 %o with %o', totalToCheck1, partialToCheck1);
      console.log('last check row 2 %o with %o', totalToCheck2, partialToCheck2);
      console.log('last check row 3 %o with %o', totalToCheck3, partialToCheck3);
      console.log('last check row 4 %o with %o', totalToCheck4, partialToCheck4);
      console.log('last check row 5 %o with %o', totalToCheck5, partialToCheck5);
      */
      if ((totalToCheck - partialToCheck > 1) || (totalToCheck - partialToCheck < -1) || (partialToCheck - totalToCheck > 1) || (partialToCheck - totalToCheck < -1)) {
        this.isError = true;
        this.idErrorClinic.push(idc);
      }
      /*
      if ((totalToCheck1 - partialToCheck1 > 1) || (totalToCheck1 - partialToCheck1 < -1) || (partialToCheck1 - totalToCheck1 > 1) || (partialToCheck1 - totalToCheck1 < -1)) {
        this.isError1 = true;
        this.idErrorClinic1.push(idc);
      }
      if ((totalToCheck2 - partialToCheck2 > 1) || (totalToCheck2 - partialToCheck2 < -1) || (partialToCheck2 - totalToCheck2 > 1) || (partialToCheck2 - totalToCheck2 < -1)) {
        this.isError2 = true;
        this.idErrorClinic2.push(idc);
      }
      if ((totalToCheck3 - partialToCheck3 > 1) || (totalToCheck3 - partialToCheck3 < -1) || (partialToCheck3 - totalToCheck3 > 1) || (partialToCheck3 - totalToCheck3 < -1)) {
        this.isError3 = true;
        this.idErrorClinic3.push(idc);
      }
      if ((totalToCheck4 - partialToCheck4 > 1) || (totalToCheck4 - partialToCheck4 < -1) || (partialToCheck4 - totalToCheck4 > 1) || (partialToCheck4 - totalToCheck4 < -1)) {
        this.isError4 = true;
        this.idErrorClinic4.push(idc);
      }
      if ((totalToCheck5 - partialToCheck5 > 1) || (totalToCheck5 - partialToCheck5 < -1) || (partialToCheck5 - totalToCheck5 > 1) || (partialToCheck5 - totalToCheck5 < -1)) {
        this.isError5 = true;
        this.idErrorClinic5.push(idc);
      }
      */
    }
    if (!this.isError) {
      for (let r of rows) {
        if (!r['first']) {
          var id = r['IDAMCLINIC2'];
          //console.log("ruolo %o", this._auth.getUser()['ruolo'].toLowerCase());
          //console.log("init r[NTARGET] %o", r['NTARGET']);
          r['NTARGET'] = +r['NTARGET'];
          //console.log("post r[NTARGET] %o", r['NTARGET']);
          r['IDAMCLINIC'] = id;
          r['IDMONTH'] = this.mesi.indexOf(r['IDMONTH']) + 1;
          if (r['DESCROLE'].substring(0, 4) == "--- ")
            r['DESCROLE'] = r['DESCROLE'].substr(4);
          //if (this._auth.getUser()['ruolo'].toLowerCase().indexOf("direzione commerciale") >= 0 && ids.indexOf(id) == -1) {
          if (this._auth.getUser()['ruolo'].toLowerCase().indexOf("NOdirezione commerciale") >= 0 && ids.indexOf(id) == -1) {
            r['NTARGET'] = +rows[rows.map(e => e.IDAMCLINIC).indexOf(id)]['NTARGET'];
            //console.log("Ruolo direzione commerciale update r[NTARGET] %o", r['NTARGET']);
            ids.push(id);
          } else if ((this._auth.getUser()['ruolo'].toLowerCase().indexOf("area manager") >= 0 || this._auth.getUser()['ruolo'].toLowerCase().indexOf("direzione commerciale") >= 0) && ids2.indexOf(id) == -1) {
            //console.log("Ruolo area manager id %o r[NFORECASTMONTH] %o", id, r['NFORECASTENTMONTH']);
            r['NFORECASTENTMONTH'] = +rows[rows.map(e => e.IDAMCLINIC).indexOf(id)]['NFORECASTENTMONTH'];
            //console.log("---------------- r[NFORECASTMONTH] %o", id, r['NFORECASTENTMONTH']);
            ids2.push(id);
            //mod by ar -> duplicate of if?
            if ((this._auth.getUser()['ruolo'].toLowerCase().indexOf("amministratore") >= 0 && ids3.indexOf(id) == -1) || (this._auth.getUser()['ruolo'].toLowerCase().indexOf("direzione commerciale") >= 0 && ids3.indexOf(id) == -1)) {
              var totTutti = 0;
              var result = []; rows.forEach(function (o) { if (o.IDAMCLINIC2 == id && (o.first == undefined || !o.first)) result.push(o); });
              if (result != undefined && result[0]['Id'] == r['Id']) {
                for (let ar of result) totTutti += ar['NTARGET'];
                if (totTutti == 0) {
                  r['NTARGET'] = +rows[rows.map(e => e.IDAMCLINIC).indexOf(id)]['NTARGET'];
                  //console.log("Target tutti 0: " + result[0]['Id'] + " == " + r['Id'] + " = " + r['NTARGET']);
                } else {
                  var first = [];
                  rows.forEach(function (o) { if (o.IDAMCLINIC2 == id && o['first']) first.push(o); });
                  if (first.length > 0) {
                    var perc = first[0]['NTARGET'] / totTutti;
                    //console.log("Target (old %o delta %o) <> 0: " + result[0]['Id'] + " == " + r['Id'] + " = " + first[0]['NTARGET'], totTutti, perc);
                    //old value -> OLD SUM?
                    var f = first[0]['NTARGET'];
                  }
                  var first = []; 
                  rows.forEach(function (o) { if (o.IDAMCLINIC2 == id && !o['first']) first.push(o); });
                  if (first.length > 0) {
                    var delta = f - totTutti;
                    var add = +(delta / first.length).toFixed();
                    var totalone = 0;
                    var firstInc = -1;
                    for (let r2 of rows) {
                      if (r2['IDAMCLINIC2'] == id && !r2['first']) {
                        if (firstInc == -1) firstInc = r2['Id'];
                        var old = r2['NTARGET'];
                        //r2['NTARGET'] += add;
                        r2['NTARGET'] = old * perc;
                        totalone += add;
                        //console.log("NTARGET passato da " + old + " a " + r2['NTARGET'] + " nella riga " + r2['Id']);
                      }
                    }
                  }
                }
              }
              //r['NFORECASTENTMONTH'] = +rows[rows.map(e => e.IDAMCLINIC).indexOf(id)]['NFORECASTENTMONTH'];
              //r['NSECURITYENTRY'] = +rows[rows.map(e => e.IDAMCLINIC).indexOf(id)]['NSECURITYENTRY'];
              //r['NEDELAYTOINSERT'] = +rows[rows.map(e => e.IDAMCLINIC).indexOf(id)]['NEDELAYTOINSERT'];
              //r['NDELAYTOPAY'] = +rows[rows.map(e => e.IDAMCLINIC).indexOf(id)]['NDELAYTOPAY'];
              ids3.push(id);
            }

          } else if ((this._auth.getUser()['ruolo'].toLowerCase().indexOf("amministratore") >= 0 && ids3.indexOf(id) == -1) || (this._auth.getUser()['ruolo'].toLowerCase().indexOf("direzione commerciale") >= 0 && ids3.indexOf(id) == -1)) {
            var totTutti = 0;
            var result = []; rows.forEach(function (o) { if (o.IDAMCLINIC2 == id && (o.first == undefined || !o.first)) result.push(o); });
            if (result != undefined && result[0]['Id'] == r['Id']) {
              for (let ar of result) totTutti += ar['NTARGET'];
              if (totTutti == 0) {
                r['NTARGET'] = +rows[rows.map(e => e.IDAMCLINIC).indexOf(id)]['NTARGET'];
                //console.log("Target tutti 0: " + result[0]['Id'] + " == " + r['Id'] + " = " + r['NTARGET']);
              } else {
                var first = []; rows.forEach(function (o) { if (o.IDAMCLINIC2 == id && o['first']) first.push(o); });
                var perc = first[0]['NTARGET'] / totTutti;
                //console.log("Target (old %o delta %o) <> 0: " + result[0]['Id'] + " == " + r['Id'] + " = " + first[0]['NTARGET'], totTutti, perc);
                //old value -> OLD SUM?
                var f = first[0]['NTARGET'];
                var first = []; rows.forEach(function (o) { if (o.IDAMCLINIC2 == id && !o['first']) first.push(o); });
                if (first.length > 0) {
                  var delta = f - totTutti;
                  var add = +(delta / first.length).toFixed();
                  var totalone = 0;
                  var firstInc = -1;
                  for (let r2 of rows) {
                    if (r2['IDAMCLINIC2'] == id && !r2['first']) {
                      if (firstInc == -1) firstInc = r2['Id'];
                      var old = r2['NTARGET'];
                      //r2['NTARGET'] += add;
                      r2['NTARGET'] = old * perc;
                      totalone += add;
                      //console.log("NTARGET passato da " + old + " a " + r2['NTARGET'] + " nella riga " + r2['Id']);
                    }
                  }
                }
              }
            }
            //r['NFORECASTENTMONTH'] = +rows[rows.map(e => e.IDAMCLINIC).indexOf(id)]['NFORECASTENTMONTH'];
            //r['NSECURITYENTRY'] = +rows[rows.map(e => e.IDAMCLINIC).indexOf(id)]['NSECURITYENTRY'];
            //r['NEDELAYTOINSERT'] = +rows[rows.map(e => e.IDAMCLINIC).indexOf(id)]['NEDELAYTOINSERT'];
            //r['NDELAYTOPAY'] = +rows[rows.map(e => e.IDAMCLINIC).indexOf(id)]['NDELAYTOPAY'];
            ids3.push(id);
          }
          //r['NFORECASTENTMONTH'] = +rows[rows.map(e => e.IDAMCLINIC).indexOf(id)]['NFORECASTENTMONTH'];
          //r['NSECURITYENTRY'] = +rows[rows.map(e => e.IDAMCLINIC).indexOf(id)]['NSECURITYENTRY'];
          //r['NEDELAYTOINSERT'] = +rows[rows.map(e => e.IDAMCLINIC).indexOf(id)]['NEDELAYTOINSERT'];
          //r['NDELAYTOPAY'] = +rows[rows.map(e => e.IDAMCLINIC).indexOf(id)]['NDELAYTOPAY'];
          for (var propertyName in r)
            if (r[propertyName] == undefined)
              r[propertyName] = 0;

          delete r['IDAMCLINIC2'];
          delete r['Id'];
          delete r['first'];
          o.push(r);
        } else continue;
      }
      //console.log('rows after update %o', o);

      this.forecastObject = o;
    }
  }
}