import { Component, OnInit, NgModule, Injectable, Input, Inject, Output } from '@angular/core';
import { TastieraArticoli } from '../../global/model/articoli/articoli.model';
import { Basket, BasketItem, VariantItem } from '../../global/model/basket/basket.model';
import { EventEmitter } from '@angular/core';

@Injectable()
export class PauseService {
    private toDelete = [
        { 'key': 'operatorPauseFrom', 'value': "0" },
        { 'key': 'operatorPauseTo', 'value': "0" }, 
        { 'key': 'operatorId', 'value': '' }, 
        { 'key': 'lastRoute', 'value': '' }, 
        { 'key': 'lastKeyboard', 'value': '' }, 
        { 'key': 'lastCart', 'value': '' },
        { 'key': 'isInPause', 'value': "false" }
    ];

    public isInPause(): boolean {
        return (localStorage.getItem('isInPause') != undefined && localStorage.getItem('isInPause') === 'true');
    }

    public backFromPause(otherOperator): boolean {
        if (otherOperator) return false;
        this.emptyLocalStorageForPause();

        return true;
    }
    
    public emptyLocalStorageForPause(): void {
        for (let d of this.toDelete)
            localStorage.removeItem(d.key);
    }

    public initializeLocalStorage() {
        console.log("Local Storage initialized.");
        for (let d of this.toDelete) {
            if (d.key == 'operatorPauseFrom')
                d.value = this.fromDateToTimestamp(new Date()) + "";
            else if (d.key == 'isInPause')
                d.value = "true";
            else if (d.key == 'operatorId')
                d.value = localStorage.getItem('email');
            
            localStorage.setItem(d.key, d.value);
        }
    }

    private fromDateToTimestamp(d): number {
        return new Date(d).getTime();
    }

    private fromTimestampToDate(ts: number): Date {
        let a = ts / 1000000 | 0
        return new Date(a);
    }
}