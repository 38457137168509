import { Component, OnInit, Inject, ViewChild, ViewChildren, QueryList, AfterViewInit, ElementRef } from '@angular/core';
import { ApiProviderWinticplusService } from '../../services/api-provider-winticplus.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatChipInputEvent } from '@angular/material';
import { ProfileDomains, Domain } from '../../app.model';
import { LoaderService } from '../../loader';
import { Observable } from 'rxjs';
import { CommonUtilsService } from '../../services/common-utils.service';

@Component({
  selector: 'app-assign-profile',
  templateUrl: './assign-profile.component.html',
  styleUrls: ['./assign-profile.component.css']
})
export class AssignProfileComponent implements OnInit, AfterViewInit {
  profiles: any[] = [];
  domains: any[] = [];
  domainsMaster: any[] = [];
  selectedProfiles: any[] = [];
  selectedDomains: any[] = [];
  profileDomains: ProfileDomains[] = [];
  isDomainsVisible: boolean = false;
  currentProfile: any = {};
  qr: string;
  user: any;
  currentDomainsType: any;
  domainsTypes: any[] = [];
  filteredDomains: any[] = [];
  //saveOnDialog:boolean;

  @ViewChild('dynamicform', null) dynamicform;
  @ViewChild('cbSelectAllDomains', null) cbSelectAllDomains;
  @ViewChild('listDomains', null) listDomains;
  @ViewChildren('allDomains') allDomains: QueryList<any>;
  @ViewChildren('allProfiles') allProfiles: QueryList<any>;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(public dialogRef: MatDialogRef<AssignProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiProviderWinticplusService,
    private loaderService: LoaderService) {
    //this.saveOnDialog=data.saveOnDialog;
    //let prds:ProfileDomains[]=[];

    console.log("data data.profileDomains: %o", data.profileDomains);
    if (data.profileDomains) {
      let prds: ProfileDomains[] = data.profileDomains.map((prd) => { return prd.idProfile }).filter((value, index, array) => array.indexOf(value) === index).map((prd) => { return { idProfile: prd } });
      prds.forEach((prd) => {
        let dpds = data.profileDomains.filter((dpd) => dpd.idProfile === prd.idProfile && dpd.idDomain >= 0).map((dpd) => { return { idDomain: dpd.idDomain } });
        prd.domains = dpds;
        this.selectedProfiles.push(prd.idProfile);
      });
      this.profileDomains = prds;
    }
    if (data.user)
      this.user = data.user;
    this.init();
  }

  ngAfterViewInit() {
    this.allProfiles.changes.subscribe(t => {
      //this.loaderService.show();
      if (t['_results'].length > 0) {
        // console.log("allProfiles.changes() %o",t);
        this.loaderService.hide();
      }
    });
    this.allDomains.changes.subscribe(t => {
      //this.loaderService.show();
      if (t['_results'].length > 0) {
        // console.log("allDomains.changes() %o",t);
        this.loaderService.hide();
      }
    });

    // console.log("this.filter %o", this.filter);
    if (this.filter) {
      Observable.fromEvent(this.filter.nativeElement, 'keyup')
        .debounceTime(150)
        .distinctUntilChanged()
        .subscribe(() => {
          const filterValue: string = this.filter.nativeElement.value.toLowerCase().trim();
          // console.log("filter %o", filterValue);
          this.setVisibleDomains(this.searchDomains(filterValue));
          //this.filterDomains(filterValue);
        });
    }
  }

  changeDTypes($event) {
    // console.log('changeDTypes event %o currentDomainType %o %o', $event, this.currentDomainsType, this.filter.nativeElement.value);
    this.setVisibleDomains(this.searchDomains(this.filter.nativeElement.value));
  }

  setVisibleDomains(domains: any[]) {
    this.domains.forEach(dm => {
      dm.visible = false;
      if (domains.findIndex(fd => fd.idDomain === dm.idDomain) >= 0) {
        dm.visible = true;
      }
    });
    this._indeterminateDomains = this.selectedDomains.length > 0 && this.domains.length > this.selectedDomains.length;
    this._checkedDomains = this.domains.length === this.selectedDomains.length;
  }

  searchDomains(filter): any[] {
    // console.log('filterDomains filterDomains %o %o', filter, this.domainsMaster);
    if (!!filter) {
      let filterValues: string[] = filter.split(" ").filter(a => !!a);
      // console.log("filterDomains filterValues %o",filterValues);
      let filteredDomains = this.domains.filter(dm => {
        // console.log("filterDomains dm.name.split %o", dm.name.split(" ").filter(a => !!a));
        return dm.name.split(" ").filter(a => !!a).filter((nameValue: string) => {
          return filterValues.filter((fv: string) => nameValue.toLowerCase().indexOf(fv.toLowerCase()) != -1).length > 0;
        }).length >= filterValues.length && (this.currentDomainsType === 'TUTTI' || dm.tipo === this.currentDomainsType);
      });
      return filteredDomains;
      // console.log('filterDomains filterDomains %o %o', filter, this.domains);
    } else {
      return this.domains.filter(dm => this.currentDomainsType === 'TUTTI' || dm.tipo === this.currentDomainsType);
    }
  }

  getHeightOption(domain: any) {
    if (domain.visible == true)
      return '48px';
    else
      return '0px';
  }

  getWidthOption(domain: any) {
    if (domain.visible == true)
      return '80%';
    else
      return '0px';
  }

  getColor(domain: any) {
    if (domain.visible == true)
      return 'white';
    else
      return 'white';
  }

  getClassOption(domain: any) {
    if (domain.visible == true)
      return 'optionVisible';
    else
      return 'optionNotVisible';
  }



  ngOnInit() {
    if (this.filter) {
      this.filter.nativeElement.value = "";
    }
    this.allProfiles = new QueryList<any>();
    this.allDomains = new QueryList<any>();
  }

  init() {
    this.getProfiles();
    this.getDomains();
    this.qr = '';

  }

  private getProfiles() {
    let query = { resource: 'winticplusprofile' };
    this.api.makeAPICall('actionGetall', query).subscribe(data => {
      this.profiles = data;
      this.loaderService.hide();
      //console.log("profiles: %o", this.profiles);
    });
  }

  private isIn(domains, domain) {
    for (let i = 0; i < domains.length; i = i + 1) {
      if (domain == domains[i])
        return true;
    }
    return false;
  }

  private isInById(domains, domain) {
    for (let i = 0; i < domains.length; i = i + 1) {
      if (domain.idDomain == domains[i].idDomain)
        return true;
    }
    return false;
  }


  private getDomains() {
    let query = { resource: 'winticplusdomain' };
    this.api.makeAPICall('actionGetall', query).subscribe(data => {
      this.domains = data;
      // for (let a = 0; a < this.domains.length; a = a + 1) {
      //   this.domains[a].visible = true;
      // }
      this.domains.forEach(dm => { dm.visible = true; });
      this.domainsMaster = CommonUtilsService.copyArrayData(this.domains);

      console.log("domains: %o", this.domains);
      //populate domainsTypes
      this.domainsTypes = ["TUTTI"];
      this.currentDomainsType = "TUTTI";
      for (let a = 0; a < this.domains.length; a = a + 1) {
        if (a == 0) {
          this.domainsTypes.push(this.domains[a]['tipo']);
        }
        else {
          //check if this.domains[a]['tipo'] is in this.domainsTypes;
          if (!this.isIn(this.domainsTypes, this.domains[a]['tipo'])) {
            this.domainsTypes.push(this.domains[a]['tipo']);
          }
        }
      }

      this.loaderService.hide();
    });
  }

  onClickProfile(profile: any): void {

    this.selectedDomains = [];
    this.currentProfile = {};
    this.isDomainsVisible = false;

    let pro = this.profileDomains.filter((pro) => pro.idProfile === profile.idProfile)[0];

    // console.log("onClick %o pro %o", profile, pro);
    if (pro != undefined) {
      this.isDomainsVisible = true;
      this.currentProfile = profile;
      this.selectedDomains = pro.domains.map((d) => {
        return `${d.idDomain}`;
      });
    }
    this.cbSelectAllDomains.checked = this.domains.length === this.selectedDomains.length;
    this._indeterminateDomains = this.selectedDomains.length > 0 && this.domains.length > this.selectedDomains.length;
    this._checkedDomains = this.domains.length === this.selectedDomains.length;
  }

  updateCheckedProfiles(event: any, profile: any): void {
    //console.log("updateCheckedProfiles %o, profile: %o",event, profile);
    this.selectedDomains = [];
    this.isDomainsVisible = false;
    this.currentProfile = {};
    this.cbSelectAllDomains.checked = this.domains.length === this.selectedDomains.length;

    let pro = this.profileDomains.filter((pro) => pro.idProfile === profile.idProfile)[0];
    //  console.log("pro %o",pro);
    if (event.checked) {
      this.isDomainsVisible = true;
      this.currentProfile = profile;
      pro = { idProfile: profile.idProfile, domains: [] };
      this.selectedProfiles.push(profile.idProfile);
      this.profileDomains.push(pro);
    } else {
      let idx = this.profileDomains.indexOf(pro, 0);
      if (idx >= 0) {
        this.profileDomains.splice(idx, 1);
        this.selectedProfiles.splice(idx, 1);
      }
    }
    this._indeterminateDomains = this.selectedDomains.length > 0 && this.domains.length > this.selectedDomains.length;
    this._checkedDomains = this.domains.length === this.selectedDomains.length;
    console.log("ProfileDomain: %o, selectedDomains: %o", this.profileDomains, this.selectedProfiles);
  }

  onSelectedDomainsChange(values: string[]) {
    console.log('onSelectedDomainsChange values %o', values, this.selectedDomains);

    // for (let a = 0; a < values.length; a = a + 1) {
    //  this.selectedDomains.push(values[a]);
    // }
    this.selectedDomains = values;

    let dms: Domain[] = this.selectedDomains.map((d) => {
      return { idDomain: +d }
    });

    let pro = this.profileDomains.filter((pro) => pro.idProfile === this.currentProfile.idProfile)[0];
    pro.domains = dms;

    this._indeterminateDomains = this.selectedDomains.length > 0 && this.domains.length > this.selectedDomains.length;
    this._checkedDomains = this.domains.length === this.selectedDomains.length;
    console.log('onSelectedDomainsChange %o dms %o selectedDomains %o', this.profileDomains, dms, this.selectedDomains);
  }

  closeDomains() {
    this.isDomainsVisible = false;
    this.selectedDomains = [];
    this.currentProfile = {};
  }

  selectAllDomains(event) {
    // console.log("selectAllDomains event %o, domains %o", event, this.domains);
    let selectedDomainsAux: any[] = [...this.selectedDomains];
    if (event.checked) {
      // selectedDomainsAux = this.domains.filter(dm=>dm.visible).map((domain)=>{ 
      //   return `${domain.idDomain}`; 
      // }).concat(this.selectedDomains);
      this.domains.filter(dm => dm.visible).forEach(dm => {
        if (selectedDomainsAux.findIndex(sd => sd === `${dm.idDomain}`) == -1) {
          // console.log("dm %o", dm);
          selectedDomainsAux.push(`${dm.idDomain}`);
        }
      });
    } else {
      this.domains.filter(dm => dm.visible).forEach(dm => {
        let idx = selectedDomainsAux.findIndex(sd => sd === `${dm.idDomain}`);
        selectedDomainsAux.splice(idx, 1);
      });
    }
    this.selectedDomains = selectedDomainsAux;
    // console.log('after %o',this.selectedDomains);

    let dms: Domain[] = this.selectedDomains.map((d) => {
      return { idDomain: +d };
    });

    let pro = this.profileDomains.filter((pro) => pro.idProfile === this.currentProfile.idProfile)[0];
    pro.domains = dms;

    this._indeterminateDomains = this.selectedDomains.length > 0 && this.domains.length > this.selectedDomains.length;
    this._checkedDomains = this.domains.length === this.selectedDomains.length;
    // console.log('selectAllDomains %o , dms %o',this.profileDomains, dms);

  }

  close() {
    console.log('close');
    this.dialogRef.close();
  }

  submit() {
    let proDomain = [];

    let find = false;
    console.log('submit on this.profileDomains %o', this.profileDomains);
    this.profileDomains.forEach((prd) => {
      if (prd.domains.length > 0) {
        prd.domains.forEach((d) => {
          proDomain.push({ idProfile: prd.idProfile, idDomain: d.idDomain.toString() });
        });
      } else {
        proDomain.push({ idProfile: prd.idProfile, idDomain: -1 });
      }
    });
    let cpProDomain = proDomain;
    if (proDomain.length > 1) {
      proDomain = [];

      for (let a = 0; a < cpProDomain.length; a = a + 1) {
        //try to insert cpDataFirst[a] in proDomain
        find = false;
        if (a < cpProDomain.length - 1) {
          for (let b = a + 1; b < cpProDomain.length; b = b + 1) {
            if (cpProDomain[b] == cpProDomain[a]) {
              find = true;
            }
          }
          if (find == false)
            proDomain.push(cpProDomain[a]);
        }
        else
          proDomain.push(cpProDomain[a]);
      }
    }
    console.log("proDomain %o and its copy %o", proDomain, cpProDomain);
    this.dialogRef.close({
      idsActionProfile: proDomain,
      user: this.user,
      submit: true
    });
  }

  _indeterminateDomains: boolean;
  get indeterminateDomains(): boolean {
    // return this.selectedDomains.length > 0 && this.domains.length > this.selectedDomains.length;
    return this._indeterminateDomains;
  }

  set indeterminateDomains(value: boolean) {
    this._indeterminateDomains = value;
  }

  _checkedDomains: boolean;
  get checkedDomains(): boolean {
    //return this.domains.length === this.selectedDomains.length;
    return this._checkedDomains;
  }

  set checkedDomains(value: boolean) {
    this.checkedDomains = value;
  }
}
