import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paginate'
})
export class PaginationPipe implements PipeTransform {

  transform(rows: any[], offset:number=1,  limit:number=0, sortBy:string, reverse:boolean=false, type:string=undefined): any[] {
    // console.log("pagination %o %o", sortBy, rows);
    let newRows:any[]=[];
    if(limit==0){
      return rows;
    }

    if(sortBy!=""){
      rows.sort((a:any, b:any)=>{
        let aa;
        let bb;

        if(type==='number' || type==='decimal'){
          aa = +(a[sortBy]?a[sortBy]:"");
          bb = +(b[sortBy]?b[sortBy]:"");
        }else{
          aa = (a[sortBy]?a[sortBy]:"").toString().toUpperCase();
          bb = (b[sortBy]?b[sortBy]:"").toString().toUpperCase();
        }
        return this.compare(aa, bb);
      });
      if(reverse){
        rows.reverse();
      }
    }
    newRows = rows.slice(offset, offset+limit);
    return newRows;
  }

  private compare(a: any, b:any){
    if( a < b) {
      return -1;
    }else if(a > b){
      return 1;
    }else{
      return 0;
    }
  }
}
