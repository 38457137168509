import { PaymentMethod } from '../payments/payment-method';

export class Payments {
    Total: number;
    ToPay: number;
    Payments: Payment[] = [];
    Completed: boolean;
    Success: boolean;
    CompletedInfo;
}

export class Payment {
    PaymentMethod: PaymentMethod2;
    Total: number;
    DatePayment: Date;
    PaymentOk: boolean;
}

export class PaymentMethod2 extends PaymentMethod {
    Paid?: number | null;
}