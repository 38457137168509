import { Injectable, Output, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { LoaderService } from '../loader';
import * as moment from 'moment';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import * as myGlobals from '../../environments/environment';
import { WebsocketService } from './websocket.service';

@Injectable()
export class PrinterService {
    public cart: any;
    public addToPrintQueue: Subject<any>;
    public printerProblems = false;
    public printersList = [];

    constructor(
        private http: HttpClient,
        private loaderService: LoaderService,
        wsService2: WebsocketService
    ) {
        this.addToPrintQueue = <Subject<any>>wsService2
            .connect("ws://localhost:12017/devicemanager")
            .map((response: MessageEvent) => {
                let data = JSON.parse(response.data);
                return data;
            });
        this.addToPrintQueue.subscribe((msg) => {
            let err = 0; let errDef = false;
            if (msg.event == 'printersStatus')
                this.printersList = msg.data.Printers;
            if (!msg.data.Status.Success)
                this.printerProblems = true;
            else {
                for (let printer of this.printersList)
                    if (printer.Status != 0) {
                        err++;
                        if (printer.Default && printer.Status != 0) {
                            errDef = true;
                            break;
                        }
                    }
                if (err == this.printersList.length || errDef)
                    this.printerProblems = true;
            }

            console.log("%o %o", this.printerProblems, this.printersList);
        });
    }

    printReceipt (cart) {
        let FiscalReceipts = {
            DocumentType: 5,
            Print: "Stampa questa stringa prima dell'header",
            Display: "Visualizza sul display questa stringa prima dell'header",
            printRecItems: [],
            printRecSubtotal: true,
            printRecTotals: []
        };
        for (let i of cart.products)
            FiscalReceipts.printRecItems.push({
                Description: i.productDescription,
                unitPrice: i.price,
                Quantity: i.quantity,
                Department: 1
            });
        var ind = 0;
        for (let p of cart.payments)
            if (FiscalReceipts.printRecTotals == undefined || FiscalReceipts.printRecTotals.map(e => e.paymentMethod).indexOf(p.paymentMethod) == -1) {
                var pm = [];
                cart.payments.forEach(function (o) { if (o.success && p.paymentMethod == o.paymentMethod) pm.push (o); });
                var tot:number | undefined = 0;
                for (let pT of pm)
                    tot += pT.amount;
                if (tot != undefined)
                    FiscalReceipts.printRecTotals.push({
                        Description: p.paymentMethod,
                        Payment: tot,
                        PaymentType: p.paymentMethod,
                        Index: ind++
                    });
            }

        this.printFiscalReceiptWS(FiscalReceipts, '14f5b48d-3e88-402f-9a17-6a6e7be8850c', 'a60e8a01-e795-4a26-a90d-7eeba1eda59a');
    }

    printFiscalReceiptWS (parFiscalReceipts, parPrinterId, parLayoutId) {
        var data = {
            "event":"Print",
            "data":{
               "Id":this.getId(),
               "ClientId":"STELLAR2",
               "Document":{
                  "ModelLayout":"FISCAL_RECEIPT",
                  "Print":"Stampa questa stringa prima dell'header",
                  "Display":"Visualizza sul display questa stringa prima dell'header",
                  "printRecItems": parFiscalReceipts.printRecItems,
                  "printRecSubtotal": !true,
                  "CfOrganizzatore": "12345678901",
                  "RagSocOrg": "WINTIC SRL",
                  "CfTit": "12179870154     ",
                  "RagsocTit": "CREA INFORMATICA S.R.L.",
                  "DataEmissione": new Date(),
                  "printRecTotals": parFiscalReceipts.printRecTotals,
                  "$$hashKey":"object:5"
               }
            }
         };

        console.log("stampa: " + parFiscalReceipts);
        this.addToPrintQueue.next(data);
    }

    getId (): string {
        var text = "";
        var possible = "abcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < 6; i++)
          text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }
}
