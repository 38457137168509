import { Injectable } from '@angular/core';
import * as Rx from 'rxjs/Rx';

@Injectable()
export class WebsocketConcessionService {
  constructor() { }

  private subject1: Rx.Subject<MessageEvent>;
  private subject2: Rx.Subject<MessageEvent>;

  public connect(url, first): Rx.Subject<MessageEvent> {
    if (first) {
      if (!this.subject1) {
        this.subject1 = this.create(url);
        console.log("Successfully connected: " + url);
      }
      return this.subject1;
    } else {
      if (!this.subject2) {
        this.subject2 = this.create(url);
        console.log("Successfully connected: " + url);
      }
      return this.subject2;
    }
  }

  private create(url): Rx.Subject<MessageEvent> {
    let ws = new WebSocket(url);

    let observable = Rx.Observable.create(
      (obs: Rx.Observer<MessageEvent>) => {
        ws.onmessage = obs.next.bind(obs);
        ws.onerror = obs.error.bind(obs);
        ws.onclose = obs.complete.bind(obs);
        return ws.close.bind(ws);
      })
    let observer = {
      next: (data: Object) => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify(data));
        }
      }
	  }
	  return Rx.Subject.create(observer, observable);
  }
}