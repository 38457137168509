import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-dialog-toggle',
  templateUrl: './dialog-toggle.component.html',
  styleUrls: ['./dialog-toggle.component.css']
})
export class DialogToggleComponent implements OnInit {

  checked: boolean = true;
  constructor(
    public dialogRef: MatDialogRef<DialogToggleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    data.forEach(item => {
      this.checked = this.checked && item.show;
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onColumnsSort(): void {
    this.data.sort((a,b) => {
      if (a.header < b.header) {
        return -1;
      }
      if (a.header > b.header) {
        return 1;
      }
      return 0;
    });

  }

  onChangeColumnShow(ele) {
    console.log(ele.target.parentNode.attributes.getNamedItem('value'));
  }

  ngOnInit() {

  }

}
