import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/range';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/observable/fromEvent';


@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() offset: number = 0;
  @Input() limit: number = 1;
  @Input() size: number = 1;
  @Input() range: number = 2;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
  currentPage: number;
  totalPages: number;
  pages: Observable<number[]>;

  constructor() { }

  ngOnInit() {
    this.getPages(this.offset, this.limit, this.size);
  }

  ngOnChanges(){
    this.getPages(this.offset, this.limit, this.size);
  }

  getPages(offset: number, limit: number, size: number) {
    this.currentPage = this.getCurrentPage(offset, limit);
    this.totalPages = this.getTotalPages(limit, size);
    let leftRng = 0;
    let rightRng = 0;
    // console.log("currentPage: %o , range: %o , totalPages-range: %o ", this.currentPage, this.range, this.totalPages - this.range);
    if(this.currentPage <= this.range){
      rightRng = this.range - this.currentPage + 1;
    }else if(this.currentPage >= this.totalPages - this.range){
      leftRng = this.range - (this.totalPages - this.currentPage);
    }

    // console.log("leftRng %o, rightRng %o", leftRng, rightRng);

    this.pages = Observable.range(-(this.range + leftRng), (this.range * 2)+ rightRng + 1 )
                           .map(offset => this.currentPage + offset)
                           .filter(page => this.isValidPageNumber(page, this.totalPages))
                           .toArray();

    // this.pages.subscribe((pages)=>{
    //   // console.log("pages %o", pages);
    // });
  }

  getCurrentPage(offset: number, limit: number): number {
    return Math.floor(offset / limit) + 1;
  }

  getTotalPages(limit: number, size: number): number {
    return Math.ceil(Math.max(size, 1) / Math.max(limit, 1));
  }

  isValidPageNumber(page: number, totalPages: number): boolean {
    return page > 0 && page <= totalPages;
  }

  selectPage(page: number, event) {
    this.cancelEvent(event);
    if (this.isValidPageNumber(page, this.totalPages)) {
      this.pageChange.emit((page - 1) * this.limit);
    }
  }

  cancelEvent(event) {
    event.preventDefault();
  }
}
