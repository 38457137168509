import { Injectable } from '@angular/core';

@Injectable()
export class TableService {

    rows = [];
    hasStarted;
    
    constructor() {
        this.rows = [];
    }

}