import { Component, OnInit, ViewChildren, QueryList, AfterViewInit, Inject, Renderer2, OnDestroy } from '@angular/core';
import { webTicSettingsCard, webTicController, webTicAction } from '../app.model';
import { UserService, ControllersService, DataRepositoryService } from '../services';
import { webTicUser, webTicUserData, webTicUserProfiles, webTicUserOrder } from '../app.model';
import { LoaderService } from '../loader';
import { environment } from "../../environments/environment";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.css']
})


export class StartPageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren('allSettingCards') allSettingCards: QueryList<any>;
  showLandingDividerLine = environment['showLandingDividerLine'];
  landingDividerText = environment['landingDividerText'];
  public myUserService: any;
  public myDataRepo: any;
  public mySettingsCard: any;
  public u: any;
  viewPage: boolean = false;
  showb: boolean = false;
  constructor(public userService: UserService,
    private controllersService: ControllersService,
    private loaderService: LoaderService,
    private renderer: Renderer2,
    public dataRepo: DataRepositoryService,
    private sanitizer: DomSanitizer) {
    this.userService.getCurrentUser().subscribe(f => {
      this.u = f;
    });
    this.renderer.addClass(document.body, 'withoutoverflow')
    {
      this.myDataRepo = dataRepo;
      this.myUserService = userService;
    };
  }

  settingsCards: webTicSettingsCard[] = [];

  async delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  ngDoCheck() {
    this.myDataRepo = this.dataRepo;
    this.myUserService = this.userService;
    this.mySettingsCard = this.settingsCards;

    
    if (this.u != null && this.u != undefined && (this.settingsCards == null || this.settingsCards.length == 0)) {
      console.log("CHECK StartPageComponent DD onInit() %o %o %o", this.u, this.userService, this.settingsCards);
      //  console.log("OK GET CONTROLLERS %o",this.controllersService);
      this.controllersService.getNControllers(this.u).subscribe(controllers => {
        this.settingsCards = [];
        // console.log("StartPageComponent DD onInit() %o", controllers);
        controllers.forEach(controller => {
          // console.log("StarPageComponent ngOnInit controller: %o", controller);
          let path: any = '';
          let externalUrl: boolean = false;
          if (!(controller.path.indexOf('forecast') == -1))
            path = 'forecast/';
          else if (!(controller.path.indexOf('mngfrcst') == -1))
            path = 'mngfrcst/';
          else if (!(controller.path.indexOf('messages-mngmt') == -1))
            path = 'messages-mngmt/';
          else if (!(controller.path.indexOf('crtg') == -1))
            path = 'crtg/';
          else if (!(controller.path.indexOf('http:') == -1) || !(controller.path.indexOf('https:') == -1) || !(controller.path.indexOf('intranet') == -1)) {
            path = controller.path;
            externalUrl = true;
          }
          else {
            path = ((controller.path.indexOf('webpos') > 0) ? '' : 'pm/') + controller.path;
          }
          //console.log("StarPageComponent ngOnInit %o", path);
          this.settingsCards.push(new webTicSettingsCard(
            controller.entity,
            '#62749c',
            controller.logoPath,
            path,
            controller.labelCode,
            controller.cssClass,
            controller,
            false,
            externalUrl
          ));
        });
        this.showb = false;
        //this.loaderService.hide();
      });
    }




    /*
    if (this.settingsCards == null || this.settingsCards.length == 0) {
      //this.settingsCards = [];
      this.controllersService.controllers.subscribe(controllers => {
        console.log("StartPageComponent onInit() %o", controllers);
        controllers.forEach(controller => {
          console.log("StarPageComponent ngOnInit controller: %o", controller);
          let path: any = '';
          let externalUrl: boolean = false;
          if (!(controller.path.indexOf('forecast') == -1))
            path = 'forecast/';
          else if (!(controller.path.indexOf('mngfrcst') == -1))
            path = 'mngfrcst/';
          else if (!(controller.path.indexOf('messages-mngmt') == -1))
            path = 'messages-mngmt/';
          else if (!(controller.path.indexOf('crtg') == -1))
            path = 'crtg/';
          else if (!(controller.path.indexOf('http:') == -1) || !(controller.path.indexOf('https:') == -1)) {
            path = controller.path;
            externalUrl = true;
          }
          else {
            path = ((controller.path.indexOf('webpos') > 0) ? '' : 'pm/') + controller.path;
          }
          console.log("StarPageComponent ngOnInit %o", path);
          // let path = `pm/${controller.path}`;
          this.settingsCards.push(new webTicSettingsCard(
            controller.entity,
            '#62749c',
            controller.logoPath,
            path,
            controller.labelCode,
            controller.cssClass,
            controller,
            false,
            externalUrl
          ));
        });
        this.loaderService.hide();
      });
    }
    */
  }

  ngAfterViewInit() {
    this.userService.getCurrentUser().subscribe(f => {
      this.u = f;
      console.log("CURRENT USER A %o", f);
      this.myDataRepo = this.dataRepo;
      this.myUserService = this.userService;
      this.mySettingsCard = this.settingsCards;
      this.viewPage = true;
    });

    this.allSettingCards.changes.subscribe(t => {
      if (t['_results'].length > 0) {
        // console.log("allSettingCards.changes() %o",t);
        this.loaderService.hide();
      }
    })
  }



  ngOnInit() {
    //this.showb = this.userService.isAuth;

    this.userService.getCurrentUser().subscribe(f => {
      this.u = f;
      console.log("CURRENT USER B %o", f);
      this.viewPage = true;
      this.myDataRepo = this.dataRepo;
      this.myUserService = this.userService;

      this.allSettingCards = new QueryList<any>();
      //this.controllersService.loadData();
      this.controllersService.currentController = null;
      console.log("StartPageComponent CC onInit()");
      this.controllersService.getNControllers(f).subscribe(controllers => {
        this.settingsCards = [];
        console.log("StartPageComponent DD onInit() %o", controllers);
        controllers.forEach(controller => {
          // console.log("StarPageComponent ngOnInit controller: %o", controller);
          let path: any = '';
          let externalUrl: boolean = false;
          if (!(controller.path.indexOf('forecast') == -1))
            path = 'forecast/';
          else if (!(controller.path.indexOf('mngfrcst') == -1))
            path = 'mngfrcst/';
          else if (!(controller.path.indexOf('messages-mngmt') == -1))
            path = 'messages-mngmt/';
          else if (!(controller.path.indexOf('crtg') == -1))
            path = 'crtg/';
          else if (!(controller.path.indexOf('http:') == -1) || !(controller.path.indexOf('https:') == -1) || !(controller.path.indexOf('intranet') == -1)) {
            path = controller.path;
            externalUrl = true;
          }
          else {
            path = ((controller.path.indexOf('webpos') > 0) ? '' : 'pm/') + controller.path;
          }
          //console.log("StarPageComponent ngOnInit %o", path);
          this.settingsCards.push(new webTicSettingsCard(
            controller.entity,
            '#62749c',
            controller.logoPath,
            path,
            controller.labelCode,
            controller.cssClass,
            controller,
            false,
            externalUrl
          ));
        });
        this.showb = false;
        //this.loaderService.hide();
      });
    });
    /*
    this.allSettingCards = new QueryList<any>();
    //this.controllersService.loadData();
    this.controllersService.currentController = null;
    console.log("StartPageComponent CC onInit()");
    //this.loaderService.show();
    // this.controllersService.getControllers().subscribe(controllers=>{
    //   this.settingsCards = [];
    //   console.log("StartPageComponent onInit() %o", controllers);
    //
    //   this.settingsCards = controllers.map(controller=>{
    //     let path = '';
    //     if (!(controller.path.indexOf('forecast') == -1)){
    //       path = 'forecast/';
    //     }else if (!(controller.path.indexOf('mngfrcst') == -1)){
    //       path = 'mngfrcst/';
    //     }else if (!(controller.path.indexOf('messages-mngmt') == -1)){
    //       path = 'messages-mngmt/';
    //     }else if (!(controller.path.indexOf('crtg') == -1)) {
    //       path = 'crtg/';
    //     }else {
    //       path = ((controller.path.indexOf('webpos') > 0) ? '' : 'pm/') + controller.path;
    //     }
    //
    //     return new webTicSettingsCard(
    //       controller.entity,
    //       '#62749c',
    //       controller.logoPath,
    //       path,
    //       controller.labelCode,
    //       controller.cssClass,
    //       controller,
    //       false
    //     );
    //   });
    //   this.loaderService.hide();
    // });
    //console.log("StarPageComponent A controllesService: %o", this.controllersService);
    //this.delay(2000).then(any => {
      console.log("StarPageComponent B controllesService: %o", this.controllersService);
      this.controllersService.getControllers().subscribe(controllers => {
        this.settingsCards = [];
        console.log("StartPageComponent DD onInit() %o", controllers);
        controllers.forEach(controller => {
         // console.log("StarPageComponent ngOnInit controller: %o", controller);
          let path: any = '';
          let externalUrl: boolean = false;
          if (!(controller.path.indexOf('forecast') == -1))
            path = 'forecast/';
          else if (!(controller.path.indexOf('mngfrcst') == -1))
            path = 'mngfrcst/';
          else if (!(controller.path.indexOf('messages-mngmt') == -1))
            path = 'messages-mngmt/';
          else if (!(controller.path.indexOf('crtg') == -1))
            path = 'crtg/';
          else if (!(controller.path.indexOf('http:') == -1) || !(controller.path.indexOf('https:') == -1) || !(controller.path.indexOf('intranet') == -1)) {
            path = controller.path;
            externalUrl = true;
          }
          else {
            path = ((controller.path.indexOf('webpos') > 0) ? '' : 'pm/') + controller.path;
          }
          //console.log("StarPageComponent ngOnInit %o", path);
          this.settingsCards.push(new webTicSettingsCard(
            controller.entity,
            '#62749c',
            controller.logoPath,
            path,
            controller.labelCode,
            controller.cssClass,
            controller,
            false,
            externalUrl
          ));
        });
        this.showb = false;
        //this.loaderService.hide();
      });
   // });
*/
  }

  goToCrm()
  {
    window.open("https://hd.dentalpro.it/ext/14814/index.php/sso/sso?auth=https://identityv2.dentalpro.it");
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'withoutoverflow');
  }
}