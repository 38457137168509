import { } from 'reflect-metadata';
import { bindCallback } from 'rxjs';

export function primaryKey(){
    return function(target:Object, key: string | symbol){
        Reflect.defineMetadata('primaryKey', true, target, key);
    }
}

export function getIsPrimaryKey(target:Object, key:string | symbol):boolean {
    return !!Reflect.getMetadata('primaryKey', target, key);
}

export function disabled(value:boolean){
    return function(target:Object, key: string | symbol){
        Reflect.defineMetadata('disabled', value, target, key);
    }
}

export function getIsDisabled(target:Object, key:string | symbol):boolean {
    let disabled = Reflect.getMetadata('disabled', target, key);
    return disabled===undefined ? false : disabled;
}

export function visible(value:boolean){
    return function(target:Object, key: string | symbol){
        Reflect.defineMetadata('visible', value, target, key);
    }
}

export function getIsVisibile(target:Object, key:string | symbol):boolean {
    let visible = Reflect.getMetadata('visible', target, key);
    return visible===undefined ? true : visible;
}

export function editable(value:boolean){
    return function(target:Object, key: string | symbol){
        Reflect.defineMetadata('editable', value, target, key);
    }
}

export function getIsEditable(target:Object, key:string | symbol):boolean{
    let editable = Reflect.getMetadata('editable', target, key);
    return editable===undefined ? true : editable;
}

export function show(value:boolean){
    return function(target:Object, key: string | symbol){
        Reflect.defineMetadata('show', value, target, key);
    }
}

export function getIsShow(target:Object, key:string | symbol):boolean {
    let show = Reflect.getMetadata('show', target, key);
    return show===undefined ? true : show;
}

export function required(){
    return function(target:Object, key: string | symbol){
        Reflect.defineMetadata('required', true, target, key);
    }
}

export function getIsRequired(target:Object, key:string | symbol):boolean {
    let required = Reflect.getMetadata('required', target, key);
    return required===undefined ? false : required;
}

export function headerCode(value:string) {
    return function(target:Object, key: string | symbol){
        Reflect.defineMetadata('headerCode', value, target, key);
    }
}

export function getHeaderCode(target:Object, key:string | symbol):string {
    let headerCode = Reflect.getMetadata('headerCode', target, key);
    return headerCode;
}

export function roles(value:string[]) {
    return function(target:Object, key: string | symbol){
        Reflect.defineMetadata('roles', value, target, key);
    }
}

export function getRoles(target:Object, key:string | symbol):string {
    let headerCode = Reflect.getMetadata('roles', target, key);
    return headerCode;
}


export function order(value:number) {
    return function(target:Object, key: string | symbol){
        Reflect.defineMetadata('order', value, target, key);
    }
}

export function getOrder(target:Object, key:string | symbol):number {
    let order = Reflect.getMetadata('order', target, key);
    return order;
}

export function inputType(value:string) {
    return function(target:Object, key: string | symbol){
        Reflect.defineMetadata('type', value, target, key);
    }
}

export function getInputType(target:Object, key:string | symbol):string {
    let type = Reflect.getMetadata('type', target, key);
    return type;
}

export function length(value:number) {
    return function(target:Object, key: string | symbol){
        Reflect.defineMetadata('length', value, target, key);
    }
}

export function getLength(target:Object, key:string | symbol):number {
    let length = Reflect.getMetadata('length', target, key);
    return length===undefined ? 256 : length;
}

export function pattern(value:string) {
    return function(target:Object, key: string | symbol){
        Reflect.defineMetadata('pattern', value, target, key);
    }
}

export function getPattern(target:Object, key:string | symbol):string {
    let pattern = Reflect.getMetadata('pattern', target, key);
    return pattern===undefined ? '' : pattern;
}

export function relation(value:string, options:{columnRel:string, columnDescr:string[]}) {
    return function(target:Object, key: string | symbol){
        Reflect.defineMetadata('relation', {
            relation:value, columnRel: options.columnRel, columnDescr: options.columnDescr
        }, target, key);
    }
}

export function getRelation(target:Object, key:string | symbol):any {
    let relation = Reflect.getMetadata('relation', target, key);
    return relation;
}

export function editableOnNew(value:boolean){
    return function(target:Object, key: string | symbol){
        Reflect.defineMetadata('editableOnNew', value, target, key);
    }
}

export function getIsEditableOnNew(target:Object, key:string | symbol):boolean{
    let editable = Reflect.getMetadata('editableOnNew', target, key);
    return editable===undefined ? true : editable;
}

export function textAlign(value:string) {
    return function(target:Object, key: string | symbol){
        Reflect.defineMetadata('textAlign', value, target, key);
    }
}

export function getTextAlign(target:Object, key:string | symbol):string {
    let textAlign = Reflect.getMetadata('textAlign', target, key);
    return textAlign===undefined ? '' : textAlign;
}

export function tableColumnStyle(value:{}){
    return function(target:Object, key: string | symbol){
        Reflect.defineMetadata('tableColumnStyle', value, target, key);
    }
}

export function getTableColumnStyle(target:Object, key:string | symbol):{} {
    let tableColumnStyle = Reflect.getMetadata('tableColumnStyle', target, key);
    return tableColumnStyle===undefined ? {'min-width.px': 150, 'width.%':100, 'max-width.px':200} : tableColumnStyle;
}

export function currency(value:{isCurrency: boolean, currencyCode?:string, display?:string | boolean, digitsInfo?:string, locale?:string}){
    return function(target:Object, key: string | symbol){
        Reflect.defineMetadata('currency', value, target, key);
    }
}

export function getCurrency(target:Object, key:string | symbol){
    let currency = Reflect.getMetadata('currency', target, key);
    return currency;
} 

export class Describer {
    static describe(instance:any): Array<string> {
        return Object.getOwnPropertyNames(instance);
    }

    static getStructure(instance:any): Array<any> {
        let d = this.describe(instance);
        let structure = d.map(prop=>{
            //console.log(prop);
            let type = getInputType(instance, prop);
            let rel = getRelation(instance, prop);

            return {
                action: undefined,
                callback: false,
                callbackOnForm: false,
                columnDef: prop.toString(),
                disabled: getIsDisabled(instance, prop),
                header: getHeaderCode(instance, prop),
                length: getLength(instance, prop),
                lookUp: {},
                primaryKey: getIsPrimaryKey(instance, prop),
                reference: rel ? { entity: rel['relation'], keyColumn: rel['columnRel'], value: rel['columnDescr'] } : undefined,
                required: getIsRequired(instance, prop),
                requiredOnNew: getIsRequired(instance, prop),
                show: getIsShow(instance, prop),
                type: !!type ? type : typeof instance[prop],
                values: null,
                visible: getIsVisibile(instance, prop),
                editable: getIsEditable(instance, prop),
                order: getOrder(instance, prop),
                pattern: getPattern(instance, prop),
                editableOnNew: getIsEditableOnNew(instance, prop),
                textAlign: getTextAlign(instance, prop),
                tableColumnStyle: getTableColumnStyle(instance, prop),
                currency: getCurrency(instance, prop),
                roles:getRoles(instance,prop)
            }

        });
        return structure;
    }

    static isId(prop:string){
        if(prop.startsWith('id')) return true;
        return false;
    }
}

export abstract class GceEntity {
    static getStructure():Array<any>{
        throw new Error("not Implemented");
    }
    protected static getActions():any[] {
        throw new Error("not Implemented")
    }
}

export interface GceLookUpOptions {
  lookUp:{};
  options:{key:string, value:string}[];
}
