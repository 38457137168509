import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConcessionSystemComponent } from './concession-system.component';
import { VariantConcessionDialog } from './dialogs/variants-concession.dialog';
import { Routes } from '@angular/router';
import { SharedModule } from '../shared.module';
import { ConcessionService } from '../services/concession.service';
import { SearchProductDialog } from './dialogs/search-product.dialog';
import { VariantCustomDialog } from './dialogs/variants-custom.dialog';
import { TastieraComponent } from './tastiera.component';
import { ScaleQuantityDialog } from './dialogs/scale-quantity.dialog';
import { NumericKeyboardComponent } from './numeric-keyboard/numeric-keyboard.component';
import { MetaCartModule } from '../meta-cart/meta-cart.module';
import { MatDialogModule } from '@angular/material/dialog';

// routes
export const ROUTES: Routes = [
  {
    path: '',
    component: ConcessionSystemComponent
  },
];



@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MetaCartModule,
    MatDialogModule
  ],
  providers: [
    ConcessionService
  ],
  declarations: [
    ConcessionSystemComponent,
    VariantConcessionDialog,
    SearchProductDialog,
    VariantCustomDialog,
    TastieraComponent,
    ScaleQuantityDialog,
    NumericKeyboardComponent,
  ],
  entryComponents: [
    VariantConcessionDialog,
    SearchProductDialog,
    VariantCustomDialog,
    ScaleQuantityDialog
  ],
  exports: [
    ConcessionSystemComponent
  ],
})
export class ConcessionSystemModule {}
