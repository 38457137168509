import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arraytotext'
})
export class ArrayToTextPipe implements PipeTransform {

  transform(value:[any], field:string, subField:string=null): any {
  //  console.log("arraytotext: %o",value, field, subField);
    let retVal = "";
    if(value) {
      if(subField){
        if(value[field]){
          value[field].forEach(item=>{
            if(item[subField]){
              retVal += item[subField] + "; ";
            }
          });
        }
      }else{
        value.forEach(item=>{
          if(item[field]){
            retVal += item[field] + "; ";
          }
        });
      }
    }
    //console.log("arraytotext retVal: %o",retVal);
    return retVal;
  }
}
