import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'checkpreset'
})
export class CheckpresetPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (args === 'date') {
        const date = moment(value).format('DD/MM/YYYY');
      return date;
    } else  {
      const hour = moment(value).format('HH:mm');
      return hour;
    }
  }

}
