import { Component, ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { CartService } from '../services';
import { CartEvoComponent } from './cart-evo/cart-evo.component';

@Component({
  selector: 'meta-cart-comp',
  templateUrl: './meta-cart.component.html',
  styleUrls: ['./meta-cart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
  ]
})
export class MetaCartComponent {
@Input() isBoxOffice: string;
@ViewChild('cartevo', {static: false}) cartevo: CartEvoComponent;

  constructor(
    public _cartService: CartService,

  ) {
  }

  testCartEvoTot = () => {
    console.log(this.cartevo.getTotCart());
  }
}
