import { Component, OnInit , Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatButtonModule} from '@angular/material';
@Component({
  selector: 'app-dialog-login-required',
  templateUrl: './dialog-login-required.component.html',
  styleUrls: ['./dialog-login-required.component.css']
})
export class DialogLoginRequiredComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogLoginRequiredComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close(): void {
    this.dialogRef.close();
  }
  
  ngOnInit() {
  }

}
