import { NgModule } from '@angular/core';
import { ProductsPipe } from './pipes/products.pipe';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

// import { MatDialogModule } from '@angular/material';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  declarations: [
      ProductsPipe,
  ],
  providers: [
  ],
  exports: [
    ProductsPipe,
    TranslateModule,
    FormsModule,
    // MatDialogModule,
  ],
  entryComponents: [
  ]
})
export class SharedModule {}
