import { NgModule , Input} from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { MetaCartComponent } from './meta-cart.component';
import { CartEvoComponent } from './cart-evo/cart-evo.component';
import { BrowserModule } from '@angular/platform-browser';

import {
  MatButtonModule,
  MatCheckboxModule,
  MatInputModule,
  MatMenuModule,
  MatExpansionModule,
  MatIconModule,
  MatToolbarModule,
  MatListModule,
  MatAutocompleteModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatDialogModule,
  MatCardModule,
  MatTableModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatChipsModule,
  MatGridListModule,
  MatSlideToggleModule,
  MatRadioModule,
  MatSliderModule,
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltipDefaultOptions,
  MatStepperModule,
  MatDividerModule,
  DateAdapter
} from '@angular/material';

import {MatFormFieldModule} from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  imports: [
    MatCardModule,
    MatSelectModule,
    MatListModule,
    MatFormFieldModule,
    FormsModule,
    MatMenuModule,
    MatSlideToggleModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserModule
  ],
  declarations: [
      MetaCartComponent,
      CartEvoComponent,
  ],
  providers: [
  ],
  exports: [
    MetaCartComponent,
    CartEvoComponent,
  ],
  entryComponents: [
  ]
})
export class MetaCartModule {
  constructor(

  ) {

  }
}
