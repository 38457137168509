import { Injectable, EventEmitter} from '@angular/core';
import { Observable, Subject } from 'rxjs/Rx';
import { WebsocketService } from './ws.service';
import { Pos } from './pay/pos.model';
import { PayWsReq } from './pay/pay.req';
import { PosResp } from './pos/pos.ans';
import { PrintWsReq } from './print/print.req';
import { BasketService } from './basket.service';

const POS_URL = 'ws://127.0.0.1:12017/pointsofsale';
const PRINT_URL = 'ws://127.0.0.1:12017/printers';

@Injectable()
export class PosService {
    public messages: Subject<any>;
    public addToPrintQueue: Subject<any>;
    paymentSuccess:EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(wsService1: WebsocketService, wsService2: WebsocketService, basketService: BasketService) {
		this.messages = <Subject<any>>wsService1
			.connect(POS_URL, 1)
			.map((response: MessageEvent) => {
                let data = JSON.parse(response.data);
                
				return data;
            });
        this.addToPrintQueue = <Subject<any>>wsService2
			.connect(PRINT_URL, 0)
			.map((response: MessageEvent) => {
                let data = JSON.parse(response.data);
                
				return data;
            });

        this.messages.subscribe((msg) => {
            let err = 0; let errDef = false;
            if (msg.event == 'PointsOfSaleStatus') {
                basketService.posList = msg.data.PointsOfSale;
                for (let pos of basketService.posList)
                    if (pos.Status != 0 && pos.Status != 8) {
                        err++;
                        if (pos.Default && pos.Status != 0 && pos.Status != 8) {
                            errDef = true;
                            break;
                        }
                    }
                if (err == basketService.posList.length || errDef)
                    basketService.posProblems = true;
            } else if (msg.event == 'Pay') {
                if (msg.data.Status.Success) {
                    basketService.payments.Completed = true;
                    basketService.payments.Success = true;
                    basketService.payments.CompletedInfo = msg.data.Status.addInfo;
                    basketService.payments.Payments.push({
                        PaymentMethod: basketService.prePaymentCards,
                        Total: parseInt(msg.data.Status.addInfo.Amount) / 100,
                        DatePayment: new Date(),
                        PaymentOk: true
                    });

                    let FiscalReceipts = {
                        DocumentType: 5,
                        Print: "Stampa questa stringa prima dell'header",
                        Display: "Visualizza sul display questa stringa prima dell'header",
                        printRecItems: [],
                        printRecSubtotal: true,
                        printRecTotals: []
                    };

                    for (let i of basketService.basket.Items)
                        FiscalReceipts.printRecItems.push(
                            {
                                Description: i.Item.articolo,
                                unitPrice: i.UnitPrice,
                                Quantity: i.Quantity,
                                Department: 1
                            }
                        );
                    for (let p of basketService.payments.Payments)
                        if (p.PaymentOk)
                            FiscalReceipts.printRecTotals.push(
                                {
                                    Description: p.PaymentMethod.PaymentMethod,
                                    Payment: p.Total,
                                    PaymentType: p.PaymentMethod.IdPM,
                                    Index: 1
                                }
                            );

                    let idDef = basketService.printersList.find(x => x.Default).Id;
                    this.printFiscalReceipt(FiscalReceipts, idDef, 'a60e8a01-e795-4a26-a90d-7eeba1eda59a');
                    this.paymentSuccess.emit(true);
                } else {
                    basketService.payments.Payments.push({
                        PaymentMethod: basketService.prePaymentCards,
                        Total: basketService.payments.ToPay,
                        DatePayment: new Date(),
                        PaymentOk: false
                    });
                    basketService.payments.Completed = true;
                    basketService.payments.Success = false;
                    this.paymentSuccess.emit(false);
                }
            }
        });
        this.addToPrintQueue.subscribe((msg) => {
            let err = 0; let errDef = false;
            if (msg.event == 'printersStatus')
                basketService.printersList = msg.data.Printers;
            if (!msg.data.Status.Success)
                basketService.printerProblems = true;
            else {
                for (let printer of basketService.printersList)
                    if (printer.Status != 0) {
                        err++;
                        if (printer.Default && printer.Status != 0) {
                            errDef = true;
                            break;
                        }
                    }
                if (err == basketService.printersList.length || errDef)
                    basketService.printerProblems = true;
            }
        });

    }

    payWithCreditCard(amount) {
        let payload = { event: "Pay", data: { Id: this.getId(), Status: { addInfo: { Amount: amount } }, PointOfSale: { Id: '491b7067-b571-4551-a46b-6231be6db7be' } } };
        this.messages.next(payload);
    }
    
    getId (): string {
        var text = "";
        var possible = "abcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < 6; i++)
          text += possible.charAt(Math.floor(Math.random() * possible.length));
      
        return text;
    }

    printFiscalReceipt(parFiscalReceipts, parPrinterId, parLayoutId) {
        var data = { event: "Print", data: { Id: this.getId(), Printer: { Id: parPrinterId }, Layout: { Id: parLayoutId }, DocumentType: 5, FiscalReceipt: parFiscalReceipts }};
        console.log(parFiscalReceipts);
        this.addToPrintQueue.next(data);
    }

    getPaymentSuccess() {
        return this.paymentSuccess;
    }
}