import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClinichedatabaseService } from '../../services/clinichedatabase.service';
import { LoaderService } from '../../loader/loader.service';
import { AlertDialogService } from '../../services/alert-dialog.service';
import { Confirm } from "../../dialogs/confirm-dialog/confirm-dialog.component";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import * as moment from 'moment';
import { ExcelService } from '../../services/excel.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

import "moment-timezone";

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SecurityContext } from '@angular/core';

import { ClinicheDatabase } from '../../entities/datibase/clinichedatabase-model';
import { windowWhen } from 'rxjs/operators';

const tz = "Europe/Rome";
const dateFormat = "YYYY-MM-DDTHH:mm:ss";

const dateFormatDate = "YYYY-MM-DD";
const dateFormatHour = "HH:mm";

@Component({
  selector: 'target3-import',
  templateUrl: './target3-import.component.html',
  styleUrls: ['./target3-import.component.css']
})
export class Target3ImportComponent implements OnInit {
  role: string;
  isAdmin: boolean = false;
  isCA: boolean = false;
  isDC: boolean = false;
  usr: any;
  willDownload = false;

  constructor(public dialogRef: MatDialogRef<Target3ImportComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private dbService: ClinichedatabaseService, private loaderService: LoaderService, private alertDialog: AlertDialogService,
    private fb: FormBuilder, private _sanitizer: DomSanitizer, private http: HttpClient) {

    this.role = data.role;
    this.usr = data.usr;

    if (this.role != null && (this.role.toLowerCase() == 'amministratore' || this.role.toLowerCase() == 'hr medici')) {
      this.isDC = false;
      this.isAdmin = true;
      this.isCA = false;
    }
    if (this.role != null && (this.role.toLowerCase() == 'direzione commerciale')) {
      this.isDC = true;
      this.isAdmin = false;
      this.isCA = false;
    }
    if (this.role != null && (this.role.toLowerCase() == 'ciclo_attivo' || this.role.toLowerCase() == 'ciclo attivo')) {
      this.isDC = false;
      this.isAdmin = false;
      this.isCA = true;
    }

  }

  parseDateExcel(excelTimestamp) {
    const secondsInDay = 24 * 60 * 60;
    const excelEpoch = new Date(1899, 11, 31);
    const excelEpochAsUnixTimestamp = excelEpoch.getTime();
    const missingLeapYearDay = secondsInDay * 1000;
    const delta = excelEpochAsUnixTimestamp - missingLeapYearDay;
    const excelTimestampAsUnixTimestamp = excelTimestamp * secondsInDay * 1000;
    const parsed = excelTimestampAsUnixTimestamp + delta;
    return isNaN(parsed) ? null : parsed;
  }

  setDownload(data) {
    this.willDownload = true;
    setTimeout(() => {
      const el = document.querySelector("#download");
      el.setAttribute("href", `data:text/json;charset=utf-8,${encodeURIComponent(data)}`);
    }, 1000)
  }

  onFileChange(ev) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    if (this.isAdmin == true || this.isDC == true) {
      if (window.confirm('Vuoi sovrascrivere i dati presenti nel file Excel con quelli nel DB?')) {
        //this.loaderService.show();
        reader.onload = (event) => {
          const data = reader.result;
          workBook = XLSX.read(data, { type: 'binary' });
          jsonData = workBook.SheetNames.reduce((initial, name) => {
            const sheet = workBook.Sheets[name];
            initial[name] = XLSX.utils.sheet_to_json(sheet);
            return initial;
          }, {});
          const dataString = JSON.stringify(jsonData);
          document.getElementById('output').innerHTML = dataString.slice(0, 1000).concat("...");

          let arrayCall = [];
          let arrayCallO = [];
         
        
          console.log('jsondata %o', jsonData);
          let jsonDataG = jsonData['DB TARGET PERSONALI 04.2022'];
         // let jsonDataF = jsonData['Personali 08.2021'];
          console.log('jsondata luglio %o', jsonDataG);
        
          for (var ito in jsonDataG) {
            console.log('ito %o', jsonDataG[ito]);
            let operatore = {};
            operatore['Mese'] = '2022-04-30T00:00:00';
            operatore['CodClinica'] =jsonDataG[ito]['Clinica'].substr(0, 3);;
            let ret1 = this.http.post(`${environment.gceAPI}/reset_target_operatore`, operatore, { responseType: 'json' });
            arrayCall.push(ret1);
          }

          /*
          for (var ito in jsonDataF) {
            let operatore = {};
            operatore['Mese'] = '2021-08-31T00:00:00';
            operatore['CodClinica'] = jsonDataF[ito]['Clinica'].substr(0, 3);;
            let ret1 = this.http.post(`${environment.gceAPI}/reset_target_operatore`, operatore, { responseType: 'json' });
            arrayCall.push(ret1);
          }
          */
          /*
          for (var ito in jsonDataM) {
            let operatore = {};
            operatore['Mese'] = '2021-03-31T00:00:00';
            operatore['CodClinica'] = jsonDataM[ito]['Clinica'];
            let ret1 = this.http.post(`${environment.gceAPI}/reset_target_operatore`, operatore, { responseType: 'json' });
            arrayCall.push(ret1);
          }
          */



          for (var it in jsonDataG) {
            let operatore = {};
            operatore['Mese'] = '2022-04-30T00:00:00';
            operatore['CodClinica'] = jsonDataG[it]['Clinica'].substr(0, 3);;
            operatore['Login'] =jsonDataG[it]['Nome'];
            operatore['TgFatturatoOperatore'] = jsonDataG[it]['Target Personale'];
            let ret1 = this.http.post(`${environment.gceAPI}/update_target_p`, operatore, { responseType: 'json' });
            arrayCallO.push(ret1);
          }
/*
          for (var it in jsonDataF) {
            let operatore = {};
            operatore['Mese'] = '2021-08-31T00:00:00';
            operatore['CodClinica'] = jsonDataF[it]['Clinica'].substr(0, 3);;
            operatore['Login'] =jsonDataF[it]['Nome'];
            operatore['TgFatturatoOperatore'] = jsonDataF[it]['Target Personale'];
            let ret1 = this.http.post(`${environment.gceAPI}/update_target_p`, operatore, { responseType: 'json' });
            arrayCallO.push(ret1);
          }
          */
          /*
          for (var it in jsonDataF) {
            let operatore = {};
            operatore['Mese'] = '2021-02-28T00:00:00';
            operatore['CodClinica'] = jsonDataF[it]['Clinica'];
            operatore['Login'] = jsonDataF[it]['Nome'];
            operatore['TgFatturatoOperatore'] = jsonDataF[it]['Target Personale'];
            let ret1 = this.http.post(`${environment.gceAPI}/update_target_p`, operatore, { responseType: 'json' });
            arrayCallO.push(ret1);
          }
          for (var it in jsonDataM) {
            let operatore = {};
            operatore['Mese'] = '2021-03-31T00:00:00';
            operatore['CodClinica'] = jsonDataM[it]['Clinica'];
            operatore['Login'] = jsonDataM[it]['Nome'];
            operatore['TgFatturatoOperatore'] = jsonDataM[it]['Target Personale'];
            let ret1 = this.http.post(`${environment.gceAPI}/update_target_p`, operatore, { responseType: 'json' });
            arrayCallO.push(ret1);
          }
          */
          
          Observable.forkJoin(arrayCall).subscribe(results => {
            Observable.forkJoin(arrayCallO).subscribe(results2 => {
              this.loaderService.hide();
              window.alert('Import terminato');
              this.dialogRef.close();

            },
              err => {
                this.loaderService.hide();
                console.log('ERR %o RES %o',err,results);
                window.alert('Errore nella procedura di importazione.');
                this.dialogRef.close();
              });
          }, err => {
            this.loaderService.hide();
            console.log('ERR %o RES %o',err);
            window.alert('Errore nella procedura di importazione.');
            this.dialogRef.close();
          });
          

        }
        reader.readAsBinaryString(file);
      }
    }
    else {
      window.alert('Att.ne: il tuo profilo non è abilitato per importare dati da file Excel')
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  ngOnInit() {

  }


}
