import { Injectable, Output, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '../loader';
import * as moment from 'moment';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import * as myGlobals from '../../environments/environment';

export class CartItem {
    productId: number;
    quantity: number;
    idudm: number;
    scala_udm: number;
    productDescription: string;
    priceId: number;
    price: number;
    extraInfo?: any;
    variants?: CartItem[] = [];
    selected: boolean; // Used for the UX to set the item when the user click on it
    customVariant: boolean; // Used when the item is a variant from a custom keyboard. In this case I use the Quantity field this wy: when is +1 I show a + sign otheriwse a - sign
}

export class Cart {
    category: CartCategory;
    products: CartItem[] | null;
    total: number;
    extraInfo: any;
    payments: Payment[];
}

export class PaymentMethod {
    name: string;
    enabled: boolean;
}

export class Payment {
    date: Date;
    success: boolean;
    amount: number;
    paymentMethod: PaymentMethod;
    extraInfo: any;
    id: string;
}

export class CartCategory {
    category: string;
}

@Injectable()
export class CartService {
    public cart: Cart;

    constructor(
        private http: HttpClient,
        private loaderService: LoaderService
    ) {

    }

    initCart(category) {
        this.cart = new Cart();
        this.cart.products  = [];
        this.cart.total = 0;
        this.cart.extraInfo = null;
        this.cart.category = new CartCategory();
        this.cart.payments = [];
        this.cart.category.category = category;
    }

    alreadyPayed () {
        var tot = 0;
        if (this.cart.payments == undefined) return tot;
        for (let p of this.cart.payments)
            if (p.success)
                tot += p.amount;
        return tot;
    }

    addToCart(item: CartItem) {
        let found: CartItem = null;

        for (const it of this.cart.products){
          if(!it.variants || it.variants.length === 0){
            if (it.productId === item.productId) {
              it.quantity += item.quantity;
              this.cart.total += item.price;
              found = it;
              break;
            }
          }
        }

        if (found == null) {
            this.cart.products.push(item);
            this.cart.total += item.quantity * item.price;
            found = item;
        }

        // I put the new element (or the element with the updated quantity) at the top of the array
        this.cart.products.sort(function (x, y) { return x == found ? -1 : y == found ? 1 : 0; });

    }

    getImageById (idarticolo, idnegozio, small_image = true) {
        return myGlobals.webApi + '/Handlers/handlerArticoli.ashx?idnegozio=' + myGlobals.environment.idNegozio + '&idarticolo=' + idarticolo + '&lob_size=' + (small_image ? 'SMALL' : 'LARGE') + '&i=png-' + (small_image ? 's' : 'l');
    }

    addOrSubtractQuantity(index:number, quantity: number): boolean {
        if (this.getTotalItems() === 0){
          return false;
        }
        let toDelete = false;
        const product = this.cart.products[index];
        if (product.quantity + quantity > 0){
          product.quantity = product.quantity + quantity;
        }
        else{
          toDelete = true;
        }

        if (toDelete) {
            this.cart.products.splice(index, 1);
        }

        this.cart.total = 0;
        for (const p of this.cart.products){
          this.cart.total += p.price * p.quantity;
        }

        return true;
    }

    updateQuantity(index: number, quantity: number) {
        if (this.getTotalItems() == 0) return false;
        if (quantity == 0) return this.removeItem(index);
        this.cart.products[index].quantity = quantity;

        this.cart.total = 0;
        for (let p of this.cart.products)
            this.cart.total += p.price * p.quantity;

        return true;
    }

    removeItem(index: number): boolean {
        return this.addOrSubtractQuantity(index, -this.cart.products[index].quantity);
    }

    getItemById(id: number): undefined | CartItem {
        if (this.getTotalItems() == 0) return undefined;
        for (let p of this.cart.products)
            if (p.productId == id)
                return p;

        return undefined;
    }
    getItemByIndex(index: number){
       if(this.cart.products.length >= index + 1){
         return this.cart.products[index];
       }
       return null;
    }

    emptyCart() {
        this.initCart(this.cart.category);
    }

    getTotal() {
        var tot = 0;
        for (var p of this.cart.products)
            tot += p.quantity * p.price;
        return tot;
    }

    getTotalItems() {
        return this.cart == undefined || this.cart.products == undefined ? 0 : this.cart.products.length;
    }

    getItems() {
        return this.cart.products;
    }
}
