import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ViewChild, ElementRef, SimpleChange } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/range';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/observable/fromEvent';
import { IConditionalFormatting } from '../../directives/conditional-formatting.directive';
import { LoaderService } from '../../loader/loader.service';
import { HttpClient } from '@angular/common/http';


import { GceManagementService } from '../../services/gce-management.service';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.css']
})
export class DatatableComponent implements OnInit, OnChanges {
  @Input() offset: number = 0;
  @Input() limit: number = 1;
  @Input() lookUpButton: any;
  @Input() rows: any[];
  @Input() columns: any[];
  @Input() maxColumns: number = 0;
  @Input() columnLookUp = {};
  @Input() entity: string;
  @Input() autoSelFirstRow: boolean = false;
  @Input() leftRadius: boolean = true;
  @Input() modal: boolean = false;
  @Input() allowMultiSelect: boolean = false;
  @Input() keyColumn: any;
  @Input() conditionalFormattingConfig: IConditionalFormatting;
  @Input() withFooter: boolean = true;
  @Input() isAdmin: boolean = false;
  @Output() clickCustomColumn: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() dblClickRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectRowsForDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() customActionClick: EventEmitter<any> = new EventEmitter<any>();

  sortColumn: { column: any, reverse: boolean } = { column: undefined, reverse: false };
  currentRow: any;
  newRow: boolean = false;
  fileToUpload: File | null = null;
  rowFile: any;
  selectedRowsForDelete: any[] = [];

  constructor(public http: HttpClient, private gceManagementService: GceManagementService, private loaderService: LoaderService) { }

  setNewRow(newRow: boolean) {
    // console.log("setNewRow %o", newRow);
    this.newRow = newRow;
    if (this.newRow) {
      this.currentRow = {};
    } else {
      this.init();
    }
  }

  ngOnInit() {
    console.log("table init %o", this.columns);

    this.init();
  }

  init() {
    //console.log("init %o", this.columns);
    if (this.autoSelFirstRow && !this.newRow) {
      this.selectRow(this.rows[0]);
    } else {
      this.currentRow = {};
    }
    this.setSort("");
  }

  getValue(value: any, options: any) {
    if (value == null)
      return null;
    //console.log('get value %o from %o', value, options);
    if (options != null && options.length > 0) {
      for (let a = 0; a < options.length; a = a + 1) {
        if (value == options[a].key)
          return (options[a].value);
      }
    }
    return null;
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    console.log("table changes %o", this.newRow);
    // console.log("keyColumn %o", this.keyColumn);
    if (changes) {
      // console.log("ngOnChanges %o", changes);
    }
    //this.init();
  }

  onChangeFile(ev, row) {
    console.log('EE %o row %o', ev.target.files, row);
    this.fileToUpload = (ev.target as HTMLInputElement).files.item(0);
    this.rowFile = row;
  }


  RequestUpload(row) {
    //gceManagementService.postFile
    if (row == this.rowFile && !(this.fileToUpload == null)) {

      console.log('EE %o row %o', this.fileToUpload, row);
      //check type
      let exts = this.fileToUpload.name.split('.');
      let ext = exts[exts.length - 1];
      if ((ext.toLowerCase() == 'bmp') ||
        (ext.toLowerCase() == 'jpg') ||
        (ext.toLowerCase() == 'jpeg') ||
        (ext.toLowerCase() == 'png') ||
        (ext.toLowerCase() == 'tiff')) {
        if (this.fileToUpload.size < 2100000) {
          this.loaderService.show();
          this.gceManagementService.postMedicFile(this.fileToUpload).subscribe(
            f => {
              console.log('ret for upload Medic File %o', f);

              this.gceManagementService.updateMedicFile(f, row.idfatturazione).subscribe(
                g => {
                  console.log('ret for update Medic File %o', g);
                  this.loaderService.hide();
                  row.url_image = f;
                  window.alert('Immagine caricata.');
                },
                err => {
                  this.loaderService.hide();
                  window.alert('Attenzione. Errore: ' + err.toString());
                }
              );
            },
            err => {
              this.loaderService.hide();
              window.alert('Attenzione. Errore: ' + err.toString());
            }
          );
        }
        else {
          window.alert('Attenzione: è possibile caricare solo file con dimensione inferiore a 2MB');
        }
      }

      else {
        window.alert('Attenzione: è possibile caricare solo file del tipo bmp, jpg, jpeg, png e tiff');
      }
    }
    else {
      window.alert('Attenzione: file per Upload non selezionato');
    }

  }

  setSort(column: any) {
    this.selectedRowsForDelete = [];
    let reverse = false;
    // console.log("sortby %o %o",column, this.sortColumn);
    if (this.sortColumn.column && this.sortColumn.column['columnDef'] === column.columnDef) {
      reverse = !this.sortColumn['reverse'];
    }

    this.sortColumn = {
      column: column,
      reverse: reverse
    };

    this.offset = 0;
  }

  selectRow(row) {
    this.newRow = false;
    this.currentRow = row;
    this.changeRow.emit(row);
  }

  onDblClickRow(row) {
    this.newRow = false;
    this.currentRow = row;
    this.dblClickRow.emit(row);
  }


  onPageChange(offset) {
    this.selectedRowsForDelete = [];
    this.offset = offset;
    // console.log("this.autoSelFirstRow %o", this.autoSelFirstRow);
    if (this.autoSelFirstRow) {
      this.selectRow(this.rows[offset]);
    }
  }

  wheelTable(event) {
    // console.log("wheelTable %o", event);

    if (event.deltaY >= 100) {

    } else {

    }
  }

  isSelectedAllRowsForDelete(): boolean {
    // console.log("isSelectedAllRowsForDelete %o %o", this.selectedRowsForDelete.length, this.rows.length)
    return this.selectedRowsForDelete.length === this.rows.slice(this.offset, this.offset + this.limit).length;
  }

  selectAllRowsForDelete(event) {
    event.stopPropagation();
    if (event.target.checked) {
      this.selectedRowsForDelete = [];
      this.selectedRowsForDelete.push(...this.rows.slice(this.offset, this.offset + this.limit));
    } else {
      this.selectedRowsForDelete = [];
    }
    this.selectRowsForDelete.emit({ selectedRowsForDelete: this.selectedRowsForDelete });
    // console.log("selectAllRowsForDelete selectedRows: %o", this.selectedRowsForDelete);
  }

  isSelectedRowForDelete(row): boolean {
    return this.selectedRowsForDelete.findIndex(sr => sr[this.keyColumn.columnDef] === row[this.keyColumn.columnDef]) >= 0;
  }

  selectRowForDelete(event, row) {
    // console.log("selectRowForDelete %o", event);
    event.stopPropagation();
    if (event.target.checked) {
      this.selectedRowsForDelete.push(row);
    } else {
      let idx = this.selectedRowsForDelete.findIndex(sr => sr[this.keyColumn.columnDef] === row[this.keyColumn.columnDef]);
      if (idx >= 0) {
        this.selectedRowsForDelete.splice(idx, 1);
      }
    }
    this.selectRowsForDelete.emit({ selectedRowsForDelete: this.selectedRowsForDelete });
  }

  getThStyle(column) {
    let style;
    let styleBase = { 'width': (column.type === 'color' || column.type === 'boolean' ? 'auto' : 'auto') };

    if (column.tableColumnStyle) {
      style = Object.assign(styleBase, column.tableColumnStyle);
    } else {
      style = styleBase;
    }
    return style;
  }
}
