import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClinichedatabaseService } from '../../services/clinichedatabase.service';
import { LoaderService } from '../../loader/loader.service';
import { AlertDialogService } from '../../services/alert-dialog.service';
import { Confirm } from "../../dialogs/confirm-dialog/confirm-dialog.component";

import * as moment from "moment";
import "moment-timezone";

import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SecurityContext } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClinicheDatabase } from '../../entities/datibase/clinichedatabase-model';

const tz = "Europe/Rome";
const dateFormat = "YYYY-MM-DDTHH:mm:ss";

const dateFormatDate = "YYYY-MM-DD";
const dateFormatHour = "HH:mm";

@Component({
  selector: 'app-db-dialog-clinica-form',
  templateUrl: './db-dialog-clinica-form.component.html',
  styleUrls: ['./db-dialog-clinica-form.component.css']
})
export class DBDialogClinicaFormComponent implements OnInit {
  clinica: ClinicheDatabase = null;
  oldclinica: ClinicheDatabase = null;
  role: string;
  submitted: boolean = false;
  form: FormGroup;
  isAdmin: boolean = false;
  isCA: boolean = false;
  isDC: boolean = false;
  usr: any;
  nerr: number = 0;
  nop: number = 0;
  isNew: boolean = false;
  districtsM: any;
  areasM: any;
  clinicsM: any;
  cbmM: any;
  medics: any;
  companies: any;
  saved: any;

  constructor(public dialogRef: MatDialogRef<DBDialogClinicaFormComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private dbService: ClinichedatabaseService, private loaderService: LoaderService, private alertDialog: AlertDialogService,
    private fb: FormBuilder, private _sanitizer: DomSanitizer, private http: HttpClient) {
    this.clinica = data.clinica;
    this.role = data.role;
    this.usr = data.usr;
    this.nerr = data.nerr;
    this.isNew = data.isNew;
    this.saved = false;
    if (data.isNew == true)
      this.isNew = true;
    if (this.role != null && (this.role.toLowerCase() == 'amministratore' || this.role.toLowerCase() == 'amministratore dp' || this.role.toLowerCase() == 'hr medici')) {
      this.isDC = false;
      this.isAdmin = true;
      this.isCA = false;
    }
    if (this.role != null && (this.role.toLowerCase() == 'direzione commerciale')) {
      this.isDC = true;
      this.isAdmin = false;
      this.isCA = false;
    }
    if (this.role != null && (this.role.toLowerCase() == 'ciclo_attivo' || this.role.toLowerCase() == 'ciclo attivo')) {
      this.isDC = false;
      this.isAdmin = false;
      this.isCA = true;
    }

  }

  cancel() {
    this.dialogRef.close({ saved: this.saved, clinica: this.clinica });
  }

  ngOnInit() {
    this.createForm();
    this.rebuildForm();

    this.loaderService.show();
    this.dbService.getAreaManagers().subscribe(am => {

      let res = [];
      for (let a = 0; a < am.length; a = a + 1) {
        let r = am[a].toUpperCase();
        res.push(r);
      }
      this.areasM = res;
      this.dbService.getDistrictManagers().subscribe(dm => {

        let res2 = [];
        for (let a = 0; a < dm.length; a = a + 1) {
          let r = dm[a].toUpperCase();
          res2.push(r);
        }
        this.districtsM = res2;

        this.dbService.getCompanies().subscribe(cp => {
          this.companies = cp;

          this.dbService.getCBMs().subscribe(cbm => {
            this.cbmM = cbm;
            this.loaderService.hide();
            console.log('Data clinic %o', this.clinica);

            // this.dbService.getMedicsClinica(this.clinica.code_c).subscribe(med => {
            //   console.log('response Data clinic medics %o', med);
            //   this.medics = med;
            //   this.loaderService.hide();
            // },
            //   err => {
            //     window.alert('Errore nel recupero delle informazioni relative ai medici ' + err);
            //     this.loaderService.hide();
            //   });

          },
            err => {
              window.alert('Errore nel recupero delle informazioni relative a CBM ' + err);
              this.loaderService.hide();
            });
        },
          err => {
            //errore recupero info
            window.alert('Errore nel recupero delle informazioni relative a Companies ' + err);
            this.loaderService.hide();
          });
      }, err => {
        //errore recupero info
        window.alert('Errore nel recupero delle informazioni relative a District Manager ' + err);
        this.loaderService.hide();
      });
    }, err => {
      //errore recupero info
      window.alert('Errore nel recupero delle informazioni relative a Area Manager ' + err);
      this.loaderService.hide();
    });

  }

  forbiddenNameValidator(nameRe: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const forbidden = nameRe.test(control.value);
      return forbidden ? { forbiddenName: { value: control.value } } : null;
    };
  }

  createForm() {
    this.form = new FormGroup(this.fb.group({

      code_c: [null],
      legalentity: [null],
      nome: [null],
      porta_servizio_dentalcash: [null],

      indirizzo: [null],
      sede_legale: [null],
      partita_iva: [null],
      ammnistratore: [null],

      area_manager: [null],
      distretto: [null],
      riuniti: [null],
      direttore_sanitario: [null],
      id_direttore_sanitario: [null],
      iscrizione_albo_odontoiatri: [null],
      iscrizione_albo_odontoiatri_prov: [null],
      cluster: [null],
      regione: [null],
      mese_apertura: [null],
      data_apertura: [null],
      data_chiusura: [null],
      brand: [null],
      vintage: [null],
      provincia: [null],
      mall_street: [null],
      latitudine: [null],
      longitudine: [null],
      login_areamanager: [null],
      data_acquisizione: [null],
      email_clinic_manager: [null],
      login_districtmanager: [null],
      esclusione_followme: [null],
      email_affittuario: [null],
      data_prima_fattura: [null],
      company_navision: [null],
      data_inizio_dentalcash: [null],
      indicazioni: [null],
      email: [null],
      tel: [null],
      fax: [null],
      descrizione: [null],
      ip_privato: [null],
      Master_Host: [null],
      website: [null],
      sms_sender: [null],
      mail_sender: [null],
      comune: [null],
      cap: [null],
      ex_listino: [null],
      iban: [null],
      codice_fiscale: [null],
      cbm: [null],
      login_cbm: [null]

    }).controls, { updateOn: 'blur' });
    this.form.valueChanges.subscribe(x => {
      console.log('form value changed %o', x);
    })
  }

  encode(data: any) {
    var str = String.fromCharCode.apply(null, data);
    return btoa(str).replace(/.{76}(?=.)/g, '$&\n');
  }

  bin2String(array: any) {
    var result = "";
    for (var i = 0; i < array.length; i++) {
      result += String.fromCharCode(parseInt(array[i], 2));
    }
    return result;
  }

  _arrayBufferToBase64(buffer: ArrayBuffer) {
    var binary;
    var len = buffer.byteLength;
    console.log('BUFFER %o BYTES %o LEN %o', buffer, len);
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(buffer[i]);
    }
    return window.btoa(binary);
  }

  rebuildForm() {
    console.log('REBUILD FORM clinica %o', this.clinica);

    this.form.patchValue({

      code_c: this.clinica.code_c,
      legalentity: this.clinica.legalentity,
      nome: this.clinica.nome,
      porta_servizio_dentalcash: this.clinica.porta_servizio_dentalcash,

      indirizzo: this.clinica.indirizzo,
      sede_legale: this.clinica.sede_legale,
      partita_iva: this.clinica.partita_iva,
      ammnistratore: this.clinica.ammnistratore,

      area_manager: this.clinica.area_manager,
      distretto: this.clinica.distretto,
      riuniti: this.clinica.riuniti,
      direttore_sanitario: this.clinica.direttore_sanitario,
      id_direttore_sanitario: this.clinica.id_direttore_sanitario,
      iscrizione_albo_odontoiatri: this.clinica.iscrizione_albo_odontoiatri,
      iscrizione_albo_odontoiatri_prov: this.clinica.iscrizione_albo_odontoiatri_prov,
      cluster: this.clinica.cluster,
      regione: this.clinica.regione,
      mese_apertura: this.clinica.mese_apertura,
      data_apertura: this.clinica.data_apertura,
      data_chiusura: this.clinica.data_chiusura,
      brand: this.clinica.brand,
      vintage: this.clinica.vintage,
      provincia: this.clinica.provincia,
      mall_street: this.clinica.mall_street,

      latitudine: this.clinica.latitudine,
      longitudine: this.clinica.longitudine,
      login_areamanager: this.clinica.login_areamanager,
      data_acquisizione: this.clinica.data_acquisizione,
      email_clinic_manager: this.clinica.email_clinic_manager,
      login_districtmanager: this.clinica.login_districtmanager,
      esclusione_followme: this.clinica.esclusione_followme,
      email_affittuario: this.clinica.email_affittuario,
      data_prima_fattura: this.clinica.data_prima_fattura,
      company_navision: this.clinica.company_navision,
      data_inizio_dentalcash: this.clinica.data_inizio_dentalcash,
      indicazioni: this.clinica.indicazioni,
      email: this.clinica.email,
      tel: this.clinica.tel,
      fax: this.clinica.fax,
      descrizione: this.clinica.descrizione,
      ip_privato: this.clinica.ip_privato,
      Master_Host: this.clinica.Master_Host,
      website: this.clinica.website,
      sms_sender: this.clinica.sms_sender,
      mail_sender: this.clinica.mail_sender,
      comune: this.clinica.comune,
      cap: this.clinica.cap,
      ex_listino: this.clinica.ex_listino,
      iban: this.clinica.iban,
      codice_fiscale: this.clinica.codice_fiscale,
      cbm: this.clinica.cbm,
      login_cbm: this.clinica.login_cbm

    });

  }

  toggleSwitchValue(columnDef: string) {
    let newVal = !this.form.get(columnDef).value;
    this.form.controls[columnDef].patchValue(newVal);
  }


  onChangeNull($event) {
    console.log('onChange null %o', $event);
  }

  save() {
    this.submitted = true;
    let formData = this.form.getRawValue();
    //check dates

    if (this.form.valid) {
      if (window.confirm('Sei sicuro di voler salvare la clinica?')) {
        this.loaderService.show();
        console.log('FORMDATA for update %o', formData);
        //check IBAN
        //if (formData.iban != null && (formData.iban.length == 27 || formData.iban.length == 0)) {
        if (!(formData.data_prima_fattura == null))
          formData.data_prima_fattura = moment(formData.data_prima_fattura).format(dateFormat);
        if (!(formData.data_inizio_dentalcash == null))
          formData.data_inizio_dentalcash = moment(formData.data_inizio_dentalcash).format(dateFormat);

        if (this.isNew == true) {
          this.dbService.postClinica(formData).subscribe((result: ClinicheDatabase) => {
            // console.log('result for update %o', result);
            this.saved = true;
            this.isNew = false;
            this.loaderService.hide();
            this.clinica = new ClinicheDatabase();

            this.clinica.code_c = formData.code_c;
            this.clinica.legalentity = formData.legalentity;
            this.clinica.nome = formData.nome;
            this.clinica.porta_servizio_dentalcash = formData.porta_servizio_dentalcash;

            this.clinica.indirizzo = formData.indirizzo;
            this.clinica.sede_legale = formData.sede_legale;
            this.clinica.partita_iva = formData.partita_iva;
            this.clinica.ammnistratore = formData.ammnistratore;

            this.clinica.area_manager = formData.area_manager;
            this.clinica.distretto = formData.distretto;
            this.clinica.riuniti = formData.riuniti;
            this.clinica.direttore_sanitario = formData.direttore_sanitario;
            this.clinica.id_direttore_sanitario = formData.id_direttore_sanitario;
            this.clinica.iscrizione_albo_odontoiatri = formData.iscrizione_albo_odontoiatri;
            this.clinica.iscrizione_albo_odontoiatri_prov = formData.iscrizione_albo_odontoiatri_prov;
            this.clinica.cluster = formData.cluster;
            this.clinica.regione = formData.regione;
            this.clinica.mese_apertura = formData.mese_apertura;
            this.clinica.data_apertura = formData.data_apertura;
            this.clinica.data_chiusura = formData.data_chiusura;
            this.clinica.brand = formData.brand;
            this.clinica.vintage = formData.vintage;
            this.clinica.provincia = formData.provincia;
            this.clinica.mall_street = formData.mall_street;

            this.clinica.latitudine = formData.latitudine;
            this.clinica.longitudine = formData.longitudine;
            this.clinica.login_areamanager = formData.login_areamanager;
            this.clinica.data_acquisizione = formData.data_acquisizione;
            this.clinica.email_clinic_manager = formData.email_clinic_manager;
            this.clinica.login_districtmanager = formData.login_districtmanager;
            this.clinica.esclusione_followme = formData.esclusione_followme;
            this.clinica.email_affittuario = formData.email_affittuario;
            this.clinica.data_prima_fattura = formData.data_prima_fattura;
            this.clinica.company_navision = formData.company_navision;
            this.clinica.data_inizio_dentalcash = formData.data_inizio_dentalcash;
            this.clinica.indicazioni = formData.indicazioni;
            this.clinica.email = formData.email;
            this.clinica.tel = formData.tel;
            this.clinica.fax = formData.fax;
            this.clinica.descrizione = formData.descrizione;
            this.clinica.ip_privato = formData.ip_privato;
            this.clinica.Master_Host = formData.Master_Host;
            this.clinica.website = formData.website;
            this.clinica.sms_sender = formData.sms_sender;
            this.clinica.mail_sender = formData.mail_sender;
            this.clinica.comune = formData.comune;
            this.clinica.cap = formData.cap;
            this.clinica.ex_listino = formData.ex_listino;
            this.clinica.iban = formData.iban;
            this.clinica.codice_fiscale = formData.codice_fiscale;
            this.clinica.cbm = formData.cbm;
            this.clinica.login_cbm = formData.login_cbm;
            window.alert('Salvataggio effettuato');
            this.dialogRef.close({ saved: this.saved, clinica: this.clinica });
          }, err => {
            console.log('erro update %o', err);
            if (formData.code_c == null) {
              window.alert('Errore nel salvataggio dei dati - codice clinica nullo');
            }
            else {
              window.alert('Errore nel salvataggio dei dati' + err.message);
            }
            this.saved = false;
            this.loaderService.hide();
          });
        }
        else {
          this.dbService.putClinica(formData).subscribe((result: ClinicheDatabase) => {
            console.log('result for update %o', result);
            this.saved = true;
            this.loaderService.hide();
            this.clinica = new ClinicheDatabase();

            this.clinica.code_c = formData.code_c;
            this.clinica.legalentity = formData.legalentity;
            this.clinica.nome = formData.nome;
            this.clinica.porta_servizio_dentalcash = formData.porta_servizio_dentalcash;

            this.clinica.indirizzo = formData.indirizzo;
            this.clinica.sede_legale = formData.sede_legale;
            this.clinica.partita_iva = formData.partita_iva;
            this.clinica.ammnistratore = formData.ammnistratore;

            this.clinica.area_manager = formData.area_manager;
            this.clinica.distretto = formData.distretto;
            this.clinica.riuniti = formData.riuniti;
            this.clinica.direttore_sanitario = formData.direttore_sanitario;
            this.clinica.id_direttore_sanitario = formData.id_direttore_sanitario;
            this.clinica.iscrizione_albo_odontoiatri = formData.iscrizione_albo_odontoiatri;
            this.clinica.iscrizione_albo_odontoiatri_prov = formData.iscrizione_albo_odontoiatri_prov;

            this.clinica.cluster = formData.cluster;
            this.clinica.regione = formData.regione;
            this.clinica.mese_apertura = formData.mese_apertura;
            this.clinica.data_apertura = formData.data_apertura;
            this.clinica.data_chiusura = formData.data_chiusura;
            this.clinica.brand = formData.brand;
            this.clinica.vintage = formData.vintage;
            this.clinica.provincia = formData.provincia;
            this.clinica.mall_street = formData.mall_street;

            this.clinica.latitudine = formData.latitudine;
            this.clinica.longitudine = formData.longitudine;
            this.clinica.login_areamanager = formData.login_areamanager;
            this.clinica.data_acquisizione = formData.data_acquisizione;
            this.clinica.email_clinic_manager = formData.email_clinic_manager;
            this.clinica.login_districtmanager = formData.login_districtmanager;
            this.clinica.esclusione_followme = formData.esclusione_followme;
            this.clinica.email_affittuario = formData.email_affittuario;
            this.clinica.data_prima_fattura = formData.data_prima_fattura;
            this.clinica.company_navision = formData.company_navision;
            this.clinica.data_inizio_dentalcash = formData.data_inizio_dentalcash;
            this.clinica.indicazioni = formData.indicazioni;
            this.clinica.email = formData.email;
            this.clinica.tel = formData.tel;
            this.clinica.fax = formData.fax;
            this.clinica.descrizione = formData.descrizione;
            this.clinica.ip_privato = formData.ip_privato;
            this.clinica.Master_Host = formData.Master_Host;
            this.clinica.website = formData.website;
            this.clinica.sms_sender = formData.sms_sender;
            this.clinica.mail_sender = formData.mail_sender;
            this.clinica.comune = formData.comune;
            this.clinica.cap = formData.cap;
            this.clinica.ex_listino = formData.ex_listino;
            this.clinica.iban = formData.iban;
            this.clinica.codice_fiscale = formData.codice_fiscale;
            //this.clinica = result;
            //update actual clinics
            window.alert('Salvataggio effettuato');
            this.dialogRef.close({ saved: this.saved, clinica: this.clinica });
          }, err => {
            console.log('erro update %o', err);
            if (formData.code_c == null) {
              window.alert('Errore nel salvataggio dei dati - codice clinica nullo');
            }
            else {
              window.alert('Errore nel salvataggio dei dati' + err.message);
            }
            this.saved = false;
            this.loaderService.hide();
          });
        }
        // }
        // else {
        //   this.loaderService.hide();
        //   window.alert('Il codice IBAN è scorretto (deve avere 27 caratteri)');
        // }
      }
    }
  }
}
