import { Component, OnInit } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { GmaStockCategoria, IGmaStockCategoriaNode } from '../../entities/gma-management/gma-stock-categoria';
import { GmaManagementService, GmaDynamicDataSource } from '../../services/gma-management.service';
import { GmaDynamicFlatNode } from '../../entities/gma-management/gma-stock-tree';
import * as MySettings from '../../../environments/environment';
import { AuthGuardService } from './../../services/auth-guard.service';
import { LoaderService } from './../../loader';

@Component({
  selector: 'app-minordine-categorie',
  templateUrl: './minordine-categorie.component.html',
  styleUrls: ['./minordine-categorie.component.css']
})
export class MinOrdineCategorieComponent implements OnInit {
  // private _transformer  = (node: GmaStockCategoria, level: number)=>{
  //   return {
  //     expandable: !!node.childs && node.childs.length > 0,
  //     idcategoria: node.idcategoria,
  //     descr: node.descr,
  //     qta_scorta_minima: node.qta_scorta_minima,
  //     qta_scorta_massima: node.qta_scorta_massima,
  //     level: level,
  //   };
  // }

  // treeControl = new FlatTreeControl<IGmaStockCategoriaNode>( node => node.level, node => node.expandable );

  // treeFlattener = new MatTreeFlattener(this._transformer, node => node.level, node => node.expandable, node => node.childs);

  // dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  treeControl: FlatTreeControl<GmaDynamicFlatNode>;
  dataSource: GmaDynamicDataSource;
  oldDataSource: any;

  constructor(private gmaService: GmaManagementService, private _auth: AuthGuardService, private _loaderRogges: LoaderService) {
    
  }

  ngOnInit() {
    this.treeControl = new FlatTreeControl<GmaDynamicFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new GmaDynamicDataSource(this.treeControl, this.gmaService);
    this.oldDataSource = new GmaDynamicDataSource(this.treeControl, this.gmaService);
    this._loaderRogges.show();
    let payload = {
      idnegozio: MySettings.environment.idNegozio,
      GUID: this._auth.getGUID(),
      DomainId: this._auth.getDomain(),
      ProfileId: this._auth.getProfile()
    };
    this.gmaService.getFornitori(payload).subscribe((fornitori: any) => {
      console.log('ret of getFornitori %o', fornitori);
      this._loaderRogges.hide();
      if (fornitori == null || fornitori.listaOrdiniMiminiFornitori == null || fornitori.listaOrdiniMiminiFornitori.length == 0) {
        window.alert('Errore nella lettura dei fornitori: DataBase non disponibile');
      }
      else {
        this.dataSource.data = fornitori.listaOrdiniMiminiFornitori.map(cat => {
          return new GmaDynamicFlatNode(cat, 0, true);
        });
        console.log('INIT');
        this.oldDataSource.data = fornitori.listaOrdiniMiminiFornitori.map(cat => {
          return new GmaDynamicFlatNode(cat, 0, true);
        });
      }
    });
  }

  getClass(item: any) {
    //console.log('getClass %o',item);
  }

  getHeight() {
    var w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName('body')[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth,
      y = w.innerHeight || e.clientHeight || g.clientHeight;
    let yr = Math.round((y * 7.5) / 10) + 'px';
    //console.log(' rett hg %o',yr);
    return yr;
  }

  changeValue(node: any, e: any) {
    console.log('changeValue node %o event %o', node, event);
    node['touched'] = true;
    node['new_value'] = e.target.value;
  }

  updateAll() {
    //console.log('datas %o',this.dataSource.data);
    for (let a = 0; a < this.dataSource.data.length; a = a + 1) {
      if (this.dataSource.data[a]['touched'] == true) {
        console.log('find touched datas %o with value %o', this.dataSource.data[a].item, this.dataSource.data[a]['new_value']);
        try {
          this.saveNode(this.dataSource.data[a], 0, this.dataSource.data[a]['new_value'], false);
        }
        catch (error) {
          console.error(error);
        }
      }
    }

  }

  getLevel = (node: GmaDynamicFlatNode) => node.level;
  isExpandable = (node: GmaDynamicFlatNode) => node.expandable;

  hasChild = (_: number, _nodeData: GmaDynamicFlatNode) => _nodeData.expandable;

  saveNode(node, qta_scorta_minima, qta_scorta_massima, con) {
    console.log('saveNode node %o qta_scorta_massima %o');
    let e = true;
    let qtmin = qta_scorta_minima;
    if (!(qtmin == 0 || qtmin < 0 || qtmin > 0))
      qtmin = 0;
    if (qta_scorta_massima < 0) {
      e = false;
      window.alert('Il minimo ordine non può essere minore di zero');
    }
   

    if (e == true) {

      let payld =
      {
        idnegozio: MySettings.environment.idNegozio,
        GUID: this._auth.getGUID(),
        DomainId: this._auth.getDomain(),
        ProfileId: this._auth.getProfile(),
        DomainContext: "DENTALPRO",
        IdFornitore: node.item.IdFornitore,
        ValoreMinimoOrdinabile: qta_scorta_massima
      };

      console.log("SAVE payload %o node %o, qta_scorta_minima %o, qta_scorta_massima %o", payld, node, qtmin, qta_scorta_massima);
      this._loaderRogges.show();
      this.gmaService.saveMinimoFornitore(payld).subscribe((retsavenode: any) => {
        console.log('ret of savenode %o', retsavenode);
        this._loaderRogges.hide();
        if (retsavenode['esito'] == true) {
          if (con == true)
            window.alert('Operazione eseguita con successo');
        }
        else {
          window.alert('ERRORE nell\'operazione: ' + retsavenode['errorMessage']);
        }
      });
    }

  }

  onSearch(event) {
    //console.log('filter %o dataSource %o', event.filter, this.dataSource.data);
    this.dataSource = new GmaDynamicDataSource(this.treeControl, this.gmaService);
    //this.dataSource.data = this.oldDataSource.data;
    console.log('filter %o dataSource %o oldDataSource %o', event.filter, this.dataSource.data, this.oldDataSource.data);
    if (event.filter == null || event.filter.length == 0) {
      for (let a = 0; a < this.oldDataSource.data.length; a = a + 1) {
        this.dataSource.data.push(this.oldDataSource.data[a]);
      }
    }
    else {
      for (let a = 0; a < this.oldDataSource.data.length; a = a + 1) {
        if (this.oldDataSource.data[a]['item']['Fornitore'].toLowerCase().indexOf(event.filter.toLowerCase()) >= 0) {

          this.dataSource.data.push(this.oldDataSource.data[a]);
        }
      }
    }
  }
}
