import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GceInvoicePerson, GceInvoicePersonClinic, GceClinic, GceLookUpOptions } from '../../../entities/gce-management';
import { GceManagementService } from '../../../services/gce-management.service';
import { LoaderService } from '../../../loader/loader.service';
import { DatatableComponent } from '../../../shared/datatable/datatable.component';
import { FormComponent } from '../../../shared/form/form.component';
import { FormHeaderComponent } from '../../../shared/form-header/form-header.component';
import * as moment from 'moment';
import { PermissionService } from '../../../services/permission.service';
import { CommonUtilsService } from '../../../services/common-utils.service';

const tz = "Europe/Rome";
const dateFormat = "YYYY-MM-DDTHH:mm:ss";

@Component({
  selector: 'app-gce-dialog-clinic-association',
  templateUrl: './gce-dialog-clinic-association.component.html',
  styleUrls: ['./gce-dialog-clinic-association.component.css']
})
export class GceDialogClinicAssociationComponent implements OnInit {
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild('formData', { static: false }) formData: FormComponent;
  @ViewChild('formHeader', { static: false }) formHeader: FormHeaderComponent;

  person: GceInvoicePerson;
  personClinics: GceInvoicePersonClinic[] = [];
  personClinicsBkp: GceInvoicePersonClinic[] = [];

  columns: any[] = [];
  limitRows: number = 13;
  heightMainList: number = 503;
  openRightPanel: boolean = false;
  currentRow: any;
  editing: boolean = false;
  newRow: boolean = false;
  keyColumn: any = {};

  writePermission: boolean;

  constructor(public dialogRef: MatDialogRef<GceDialogClinicAssociationComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private gceService: GceManagementService, private loaderService: LoaderService) {
    this.person = data;

    this.writePermission = PermissionService.checkPermission("CGE_ECD");

    console.log(data);
  }

  ngOnInit() {
    this.columns = GceInvoicePersonClinic.getStructure();
    this.columns.forEach(column => {
      if (column.primaryKey) {
        this.keyColumn = column;
      }
      //console.log("GceDialogClinicAssociationComponent->ngOnInit column: %o", column.reference);
      if (column.reference) {
        let lookUpOptions: GceLookUpOptions;
        if (column.reference.entity === "GceInvoicePerson") {
          lookUpOptions = this.gceService.getLookUpAndOptions([this.person], column.reference);
        } else if (column.reference.entity === "GceClinic") {
          lookUpOptions = this.gceService.getLookUpAndOptions(this.gceService.dataRepoClinics, column.reference);
        }
        if (lookUpOptions) {
          column.lookUp = lookUpOptions.lookUp;
          column.options = lookUpOptions.options;
        }
      }
    });
    this.personClinicsBkp = [];

    this.gceService.getPersonClinics(this.person.idfatturazione).subscribe((response) => {
      this.personClinics = response;
      this.personClinicsBkp = [...this.personClinics];
    }, err => {
      this.personClinicsBkp = [];
    });
  }

  onEdit() {
    this.newRow = false;
    this.editing = true;
    this.openRightPanel = true;
  }

  onNew() {
    this.editing = false;
    this.newRow = true;
    this.currentRow = { idfatturazione: this.person.idfatturazione };
    this.openRightPanel = true;
    this.table.setNewRow(true);
  }

  onSearch(searchFilter: any) {
    console.log("onSearch %o", searchFilter);
    this.personClinics = this.personClinicsBkp.filter(item => {
      for (let key in item) {
        if (item[key] !== null && item[key] !== undefined && item[key].toString().toLowerCase().indexOf(searchFilter.filter) != -1)
          return true;
      }
      return false;
    });
    this.table.offset = 0;
  }

  onToggle(selectedColumns) {
    console.log("onToggle %o %o", selectedColumns);
    this.columns.map(item => {
      item.show = selectedColumns.indexOf(item.columnDef) != -1;
    });
  }

  onChangeRow(row) {
    console.log("onChangeRow %o", row);
    this.currentRow = row;
    this.editing = false;
    this.newRow = false;
    this.openRightPanel = true;
  }

  onRestore(event) {
    console.log("onRestore %o", event);
    if (this.newRow) {
      this.currentRow = this.personClinics[0];
      this.table.setNewRow(false);
    }
    this.clear();
    if (this.personClinics.length == 0) {
      this.onNew();
    }
  }

  onSave(event) {
    console.log("onSave %o", event);
    let dataToSave = CommonUtilsService.copyData(event.form.getRawValue());
    if(dataToSave['data_inizio']){
      dataToSave['data_inizio'] = moment(dataToSave['data_inizio']).format('YYYY-MM-DD');
    }
    if(dataToSave['data_fine']){
      dataToSave['data_fine'] = moment(dataToSave['data_fine']).format('YYYY-MM-DD');
    }
    if(dataToSave['data_inzio_gestione']){
      dataToSave['data_inzio_gestione'] = moment(dataToSave['data_inzio_gestione']).format('YYYY-MM-DD');
    }
    if(dataToSave['data_fine_gestione']){
      dataToSave['data_fine_gestione'] = moment(dataToSave['data_fine_gestione']).format('YYYY-MM-DD');
    }
    
    if (this.newRow) {
      this.insert(dataToSave);
    } else {
      this.update(dataToSave);
    }
  }

  onDelete(personClinic: GceInvoicePersonClinic) {
    console.log("onDelete %o", personClinic);
    this.loaderService.show();
    this.gceService.deletePersonClinic(personClinic.idfatturazione, personClinic.cod_clinica).subscribe((response) => {
      let index = this.personClinicsBkp.findIndex((pc: GceInvoicePersonClinic) => { return pc.idfatturazione === personClinic.idfatturazione && pc.cod_clinica === personClinic.cod_clinica });
      this.personClinicsBkp.splice(index, 1);
      this.onSearch({ filter: "", fromDate: undefined, toDate: undefined });
      this.clear();
      this.loaderService.hide();
    });
  }

  onDeleteAll(event) {
    console.log("onDeleteAll %o", event);
  }

  onCloseForm(event) {
    console.log("onCloseForm %o", event);
    this.clear();
  }

  clear() {
    this.editing = false;
    this.newRow = false;
    this.openRightPanel = false;
  }

  insert(personClinic: GceInvoicePersonClinic) {
    this.loaderService.show();
    this.gceService.insertPersonClinic(personClinic).subscribe((personClinic: GceInvoicePersonClinic) => {
      console.log(personClinic);
      this.personClinicsBkp.push(personClinic);
      this.onSearch({ filter: "", fromDate: undefined, toDate: undefined });
      this.clear();
      this.table.setNewRow(false);
      this.loaderService.hide();
    }, err => {

    });
  }

  update(personClinic: GceInvoicePersonClinic) {
    this.loaderService.show();
    this.gceService.updatePersonClinic(personClinic).subscribe((response: GceInvoicePersonClinic) => {
      let index = this.personClinicsBkp.findIndex((pc: GceInvoicePersonClinic) => { return pc.idfatturazione === response.idfatturazione && pc.cod_clinica === response.cod_clinica });
      this.personClinicsBkp[index] = response;
      this.onSearch({ filter: "", fromDate: undefined, toDate: undefined });
      this.clear();
      this.table.setNewRow(false);
      this.loaderService.hide();
    }, err => {

    });
  }

}
