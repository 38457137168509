import { Directive, ElementRef, Input, AfterViewInit } from '@angular/core';
import { DayPilot } from 'daypilot-pro-angular';


@Directive({
  selector: '[draggableToScheduler]'
})
export class DraggableToSchedulerDirective implements AfterViewInit {
  @Input('draggableToScheduler') options: any;

  constructor(private el: ElementRef) { }

  ngAfterViewInit(): void {
    this.options.element = this.el.nativeElement;
    // console.log(this.options);
    DayPilot.Scheduler.makeDraggable(this.options);
  }
}
