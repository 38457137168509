import { primaryKey, editableOnNew, headerCode, inputType, required, length, order, pattern, editable, Describer, visible, GceEntity, textAlign, relation, currency } from './gce-management';

/**
 * GcePerformanceCalculationDetail -> table: fmc_dettaglio_calcolo_prestationi
 */
export class GcePerformanceCalculationDetail extends GceEntity {
    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.IDFATTURAZIONE')
    @inputType('select')
    @relation("GceInvoicePerson", { columnRel: 'idfatturazione', columnDescr: ['descrizione', 'piva'] })
    @visible(false)
    @order(1)
    idfatturazione: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.MESE')
    @inputType('date')
    @visible(false)
    @order(2)
    mese: Date | string;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.COD_CLINICA')
    @inputType('select')
    @length(255)
    //@relation("GceClinic", { columnRel: 'code_c', columnDescr: ['legalentity', 'nome'] })
    @relation("GceClinic", { columnRel: 'code_c', columnDescr: ['nome'] })
    @visible(false)
    @order(3)
    cod_clinica: string;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.ID_CLINICA')
    @inputType('text')
    @editable(true)
    @length(255)
    @visible(false)
    @order(4)
    id_clinica: string;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.DATA_PRODUZIONE')
    @inputType('date')
    @order(5)
    data_produzione: Date | string;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.CODPAZIENTE')
    @inputType('text')
    @editable(true)
    @order(6)
    codpaziente: any;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.NUM_PREVENTIVO')
    @inputType('number')
    @editable(true)
    @order(7)
    num_preventivo: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.DESCRIZIONE_PRESTAZIONE')
    @inputType('text')
    @editable(true)
    @length(255)
    @order(8)
    descrizione_prestazione: string;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.DENTE')
    @inputType('text')
    @editable(true)
    @length(255)
    @order(9)
    dente: string;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.FISSO_MEDICO')
    @inputType('number')
    @pattern('00000.00')
    @currency({isCurrency:true, currencyCode:'EUR', display:'symbol', digitsInfo:'2.2-2', locale:'it'})
    @editable(true)
    @order(10)
    fisso_medico: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.RIDUZIONE_RIFACIMENTO')
    @inputType('number')
    @editable(true)
    @order(11)
    riduzione_rifacimento: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.IMPORTO_PRODUZIONE')
    @inputType('number')
    @pattern('00000.00')
    @currency({isCurrency:true, currencyCode:'EUR', display:'symbol', digitsInfo:'2.2-2', locale:'it'})
    @visible(false)
    @order(28)
    importo_produzione: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.IMPORTO_PREST_BASE_CALC_MEDICO')
    @inputType('number')
    @pattern('00000.00')
    @currency({isCurrency:true, currencyCode:'EUR', display:'symbol', digitsInfo:'2.2-2', locale:'it'})
    @editable(true)
    @visible(false)
    @order(13)
    importo_prest_base_calc_medico: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.PERC_MEDICO')
    @inputType('number')
    @editable(true)
    @order(14)
    perc_medico: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.COMPENSO_MEDICO')
    @inputType('number')
    @pattern('00000.00')
    @currency({isCurrency:true, currencyCode:'EUR', display:'symbol', digitsInfo:'2.2-2', locale:'it'})
    @editable(true)
    @order(15)
    compenso_medico: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.N_PRESTAZIONI')
    @inputType('number')
    @editable(true)
    @order(16)
    n_prestazioni: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.CODFASE')
    @inputType('number')
    @editable(true)
    @order(17)
    codfase: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.CODPREV')
    @inputType('number')
    @editable(true)
    @order(18)
    codprev: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.CODPDC')
    @inputType('number')
    @editable(true)
    @order(19)
    codpdc: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.N_FASI')
    @inputType('number')
    @editable(true)
    @order(20)
    n_fasi: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.TIPO_CALCOLO')
    @inputType('text')
    @editable(true)
    @length(255)
    @order(21)
    tipo_calcolo: string;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.CODGENERE_CALCOLO')
    @inputType('number')
    @editable(true)
    @order(22)
    codgenere_calcolo: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.DESCRIZIONE_GENERE')
    @inputType('text')
    @editable(true)
    @length(255)
    @order(23)
    descrizione_genere: string;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.CODPRESTAZIONE')
    @inputType('number')
    @editable(true)
    @order(24)
    codprestazione: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.OPERATORE')
    @inputType('number')
    @editable(true)
    @order(25)
    operatore: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.COD_PROFILO')
    @inputType('number')
    @editable(true)
    @order(26)
    cod_profilo: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.FLG_CALCOLO')
    @inputType('number')
    @editable(true)
    @order(27)
    flg_calcolo: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.IMPORTO_PRODUZIONE')
    @inputType('number')
    @pattern('00000.00')
    @currency({isCurrency:true, currencyCode:'EUR', display:'symbol', digitsInfo:'2.2-2', locale:'it'})
    @visible(false)
    @order(12)
    importo_prest_lordo : number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.PERC_MEDICO')
    @inputType('number')
    @editable(true)
    @visible(false)
    @order(14)
    pct_finanziamento : number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.IMPORTO_PREST_BASE_CALC_MEDICO')
    @inputType('number')
    @pattern('00000.00')
    @currency({isCurrency:true, currencyCode:'EUR', display:'symbol', digitsInfo:'2.2-2', locale:'it'})
    @editable(true)
    @order(13)
    importo_prest_base_calc_medico_nettofin  : number;
   
    constructor();
    constructor(idfatturazione: number, mese: Date | string, cod_clinica: string, id_clinica: string, operatore: number, cod_profilo: number, flg_calcolo: number, data_produzione: Date | string, codgenere_calcolo: number, descrizione_genere: string, codprestazione: number, descrizione_prestazione: string, dente: string, tipo_calcolo: string, fisso_medico: number, perc_medico: number, codpaziente: any, num_preventivo: number, riduzione_rifacimento: number, importo_produzione: number, importo_prest_base_calc_medico: number, compenso_medico: number, n_prestazioni: number, codfase: number, codprev: number, codpdc: number, n_fasi: number);
    constructor(idfatturazione?: number, 
        mese?: Date | string, 
        cod_clinica?: string, 
        id_clinica?: string, 
        operatore?: number, 
        cod_profilo?: number, 
        flg_calcolo?: number, 
        data_produzione?: Date | string, 
        codgenere_calcolo?: number, 
        descrizione_genere?: string, 
        codprestazione?: number, 
        descrizione_prestazione?: string, 
        dente?: string, 
        tipo_calcolo?: string, 
        fisso_medico?: number, 
        perc_medico?: number, 
        codpaziente?: any, 
        num_preventivo?: number, 
        riduzione_rifacimento?: number, 
        importo_produzione?: number, 
        importo_prest_base_calc_medico?: number, 
        compenso_medico?: number,
         n_prestazioni?: number, 
         codfase?: number, 
         codprev?: number, 
         codpdc?: number, 
         n_fasi?: number, 
         importo_prest_lordo?:number, 
         importo_prest_base_calc_medico_nettofin?  : number,
         pct_finanziamento?  : number) {
        super();

        this.idfatturazione = idfatturazione || 0;
        this.mese = mese || new Date();
        this.cod_clinica = cod_clinica || '';
        this.id_clinica = id_clinica || '';
        this.operatore = operatore || 0;
        this.cod_profilo = cod_profilo || 0;
        this.flg_calcolo = flg_calcolo || 0;
        this.data_produzione = data_produzione || new Date();
        this.codgenere_calcolo = codgenere_calcolo || 0;
        this.descrizione_genere = descrizione_genere || '';
        this.codprestazione = codprestazione || 0;
        this.descrizione_prestazione = descrizione_prestazione || '';
        this.dente = dente || '';
        this.tipo_calcolo = tipo_calcolo || '';
        this.fisso_medico = fisso_medico || 0;
        this.perc_medico = perc_medico || 0;
        this.codpaziente = codpaziente || '';
        this.num_preventivo = num_preventivo || 0;
        this.riduzione_rifacimento = riduzione_rifacimento || 0;
        this.importo_produzione = importo_produzione || 0;
        this.importo_prest_base_calc_medico = importo_prest_base_calc_medico || 0;
        this.compenso_medico = compenso_medico || 0;
        this.n_prestazioni = n_prestazioni || 0;
        this.codfase = codfase || 0;
        this.codprev = codprev || 0;
        this.codpdc = codpdc || 0;
        this.n_fasi = n_fasi || 0;
        this.importo_prest_lordo = importo_prest_lordo || 0;
        this.importo_prest_base_calc_medico_nettofin=importo_prest_base_calc_medico_nettofin|| 0;;
        this.pct_finanziamento=pct_finanziamento|| 0;
    }

    static getStructure(): Array<any> {
        let structure: any[] = Describer.getStructure(new GcePerformanceCalculationDetail());
        structure.push(...this.getActions());
        return structure;
    }

    protected static getActions(): any[] {
        let actions: any[] = [];
        return actions;
    }
}