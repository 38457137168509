import { Component } from "@angular/core";
import { Subscription } from 'rxjs/Subscription';
import { ClockService } from "../services/clock.service";
import { UserService } from '../services';
import { AuthGuardService } from './../services/auth-guard.service';
import { MatDialog } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { MessagesDialogComponent } from './../order-management/shared/dialogs/msgs.dialog';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.css']
})
export class ClockComponent {

  time: Date;
  areMessages: boolean;
  numberMessages = 0;

  constructor(
    public userService: UserService,
    public clockService: ClockService,
    private _http: HttpClient,
    private _dialog: MatDialog,
    private _auth: AuthGuardService) {
    //console.log('apiWinTicDomain %o',environment['apiWinTicDomain']);
    if (environment['apiWinTicDomain'] != null && environment['apiWinTicDomain'].length > 0 && environment['apiWinTicDomain'] != 'undefined') {
      this.clockService.setClock(1).subscribe();
    }
    else {
      this.clockService.setClock(1);
    }
  }

  ngOnInit() {
    this.areMessages = false;
    this.clockService.getClockReal().subscribe(time => this.time = time);
    let self = this;
    this._http.get(
      environment['GetAllMessagesPerClinic'].replace("{0}", this._auth.getDomain()).replace("{1}", this._auth.getProfile())
    ).subscribe(res => {
      if (res['esito'] && res['obj'].length > 0) {
        self.areMessages = true;
        self.numberMessages = res['obj'].length;
      }
    });
  }

  getMessages() {
    let self = this;
    this._http.get(
      environment['GetAllMessagesPerClinic'].replace("{0}", this._auth.getDomain()).replace("{1}", this._auth.getProfile())
    ).subscribe(res => {
      if (res['esito'] && res['obj'].length > 0) {
        var data = [];
        data['msgs'] = res['obj'];
        data['options'] = [{ caption: 'ok', action: 'true' }];
        let dialogRef = self._dialog.open(MessagesDialogComponent, {
          //width: '820px',
          //height: '500px',
         // width:'60%',
         // height:'70%',
          data: data,
          panelClass: 'sizingForDialog'
        });
      }
    });
  }

}
