import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { webTicAction, webTicController } from '../app.model';
import { ApiProviderWinticplusService } from './api-provider-winticplus.service';
import { UserService } from './user.service';
import { LoaderService } from '../loader';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class ActionsService {
  private _currentAction: BehaviorSubject<webTicAction>;
  private dataStore: {
    currentAction: webTicAction
  }

  actions: webTicAction[];

  constructor(private api: ApiProviderWinticplusService, private userService: UserService,
    private loaderService: LoaderService,
    private translate: TranslateService,
    private http: HttpClient) {
    this.dataStore = {
      currentAction: null
    };
    this._currentAction = <BehaviorSubject<webTicAction>>new BehaviorSubject({});


  }

  get currentAction(): any {
    return this._currentAction.asObservable();
  }

  set currentAction(value: any) {
    if (value == null) {
      this.dataStore.currentAction = null;
      this._currentAction = <BehaviorSubject<webTicAction>>new BehaviorSubject({});
    } else {
      let action = this.actions.find(action => action.id === value);
      this.dataStore.currentAction = action;
      this._currentAction.next(Object.assign({}, this.dataStore).currentAction);
    }
  }

  public getDetailUser(id: any, patient: any): any {
    console.log('PATIENT %o',patient)
    if (!(patient == true))
      return this.http.get(`${environment.GetDetailUser}` + id.toString());
    else
      return this.http.get(`${environment.GetDetailPatient}` + id.toString());
  }

  public postMedic(pyld: any): any {
    return this.http.post(`${environment.PostMedic}`, pyld, { responseType: 'json' });
  }

  public getActions(controller: webTicController): Observable<webTicAction[]> {
    let obs;
    if (controller != null && controller.path != null && controller.path.length > 0) {
      if (controller.path.startsWith('auth') || controller.path.startsWith('devicemanager') || controller.path.startsWith('gce') || controller.path.startsWith('incentive') || controller.path.startsWith('gma') || controller.path.startsWith('datibase')) {
        obs = this.getAuthActions(controller.id);
      } else if (controller.path.startsWith('datas')) {
        obs = this.getDatasActions(controller.id);
      } else if (controller.path.startsWith('calendar')) {
        obs = this.getCalendarActions(controller.id);
      }
      else {
        // console.log("wtf?");
      }
    }
    return obs;
  }

  private getAuthActions(idController: number): Observable<webTicAction[]> {
    return Observable.create(observer => {
      this.userService.getCurrentUser().subscribe((user) => {
        this.api.makeAPICall("getNavigationByControllerList", { urlParams: "1", queryParams: `/${idController}/${user['profile']}` }).subscribe(data => {
          this.actions = data.map(item => {
            let labelTranslate: string;
            this.translate.get(item.labelCode).subscribe((res: string) => {
              // console.log(res);
              labelTranslate = res;
            });
            let action: webTicAction = new webTicAction(
              item.idAction, item.order, item.labelCode, item.path, item.entity,
              item.logoPath, item.isWrite, item.isDelete, item.isWrite, item.hasIdParameter,
              item.isTableManagement, item.isSingleTableManagement,
              item.color,
              false, false, false,
              labelTranslate
            );
            // console.log("action" , action);
            return action;
          });
          console.log('_A');
          this.loaderService.hide();
          observer.next(this.actions);
        }, err => {
          console.log('_B');
          this.loaderService.hide();
        });
      });
    });
  }

  private getDatasActions(idController: number): Observable<webTicAction[]> {
    return Observable.create(observer => {
      this.userService.getCurrentUser().subscribe((user) => {
        this.api.makeAPICall("getNavigationByControllerList", { urlParams: "1", queryParams: `/${idController}/${user['profile']}` }).subscribe(data => {
          let fakeActions = data.map(item => {
            let fake: webTicAction = new webTicAction(
              item.idAction, item.order, item.labelCode, item.path, item.entity,
              item.logoPath, item.isWrite, item.isDelete, item.isWrite, item.hasIdParameter,
              item.isTableManagement, item.isSingleTableManagement, item.color
            );
            return fake;
          });
          this.api.makeAPICall("getTableList", {}).subscribe(data => {
            let index = 1;
            // console.log("data: %o", data);

            this.actions = data.map(item => {
              // console.log("item %o", item);
              let isTableDetailManagement = false;
              let isPriceList = false;
              let isFilmManagement = false;
              let isPackageManagement = false;

              if (item.TableName === 'BIGLIETTO') {
                isPriceList = true;
              } else if (item.TableName === 'FILM') {
                isFilmManagement = true;
              } else if (item.TableName === 'PACCHETTO') {
                isPackageManagement = true;
              }
              let labelTranslate: string;
              this.translate.get(item.Label ? item.Label : item.TableName).subscribe((res: string) => {
                // console.log(res);
                labelTranslate = res;
              });
              let action: webTicAction = new webTicAction(
                index,
                index++,
                item.Label ? item.Label : item.TableName,
                item.TableName,
                item.TableName,
                `${item.TableName.toLowerCase()}.png`,
                !!item.AllowIns,
                !!item.AllowDelete,
                !!item.AllowEdit,
                fakeActions[0].hasIdParameter,
                fakeActions[0].isTableManagement,
                fakeActions[0].isSingleTableManagement,
                fakeActions[0].color,
                isTableDetailManagement,
                isPriceList,
                isFilmManagement,
                labelTranslate,
                isPackageManagement
              );
              // console.log("action" , action);
              return action;
            });
            console.log('_C');
            this.loaderService.hide();
            // console.log("actions %o", actions);
            observer.next(this.actions);
          }, err => {
            console.log('_D');
            this.loaderService.hide();
          });
        }, err => {
          console.log('_E');
          this.loaderService.hide();
        });
      });
    });
  }

  private getCalendarActions(idController: number): Observable<webTicAction[]> {
    return Observable.create(observer => {
      this.userService.getCurrentUser().subscribe((user) => {
        this.api.makeAPICall("getNavigationByControllerList", { urlParams: "1", queryParams: `/${idController}/${user['profile']}` }).subscribe(data => {
          let fakeActions = data.map(item => {
            let labelTranslate: string;
            this.translate.get(item.labelCode).subscribe((res: string) => {
              // console.log(res);
              labelTranslate = res;
            });
            let fake: webTicAction = new webTicAction(
              item.idAction, item.order, item.labelCode, item.path, item.entity,
              item.logoPath, item.isWrite, item.isDelete, item.isWrite, item.hasIdParameter,
              item.isTableManagement, item.isSingleTableManagement,
              item.color, false, false, false,
              labelTranslate
            );
            // console.log("action" , fake);
            return fake;
          });
          this.actions = [];
          console.log('_F');
          this.loaderService.hide();
          observer.next(this.actions);
        }, err => {
          console.log('_G');
          this.loaderService.hide();
        });
      });
    });
  }
}
