import { Component, OnInit, NgModule, Pipe, PipeTransform, Injectable, ViewContainerRef, Input, HostListener, ViewEncapsulation, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MatFormFieldModule, MatDialogRef, MatDialog } from '@angular/material';
import * as myGlobals from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Nodo } from './domain/concession-system';

@Component({
  selector: 'tastiera-comp',
  templateUrl: './tastiera.component.html',
  styleUrls: ['./tastiera.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
  ]
})
export class TastieraComponent {

  private _nodi: Array<Nodo> = [];
  startPaymentProcess;
  showFilteredProducts;
  
  @Input() get nodi(){
     return this._nodi;
  }
  set nodi(value: Array<Nodo>){
    this._nodi = value;
    this.previousNodi = [];
    this.previousNodi.push(value);
  }
  @Input() idtastiera: number = 0;
  @Input() showBackIcon: boolean = false;
  @Output() clickedNodeEv: EventEmitter<Nodo> = new EventEmitter<Nodo>();

  canClickNode:boolean = true; // Use to debounce click when user select node
  insideNode: boolean = false;
  insideNodoName: string = '';
  previousNodi: Array<Array<Nodo>> = []; // Used to nvigate back on nodes

  constructor(
    private _http: HttpClient,
  ) {
  }

  getNodeSize(nodo, root = false) {
    if (root){
      return {
        'height': nodo['height'] + 'px',
        'width': nodo['width'] + 'px',
        'top': (0 + +nodo['top']) + 'px',
        'left': (0 + +nodo['left']) + 'px'
      }
    }

    const cost = (nodo['width'] < nodo['height'] ? nodo['width'] : nodo['height']) < 240 ? 35 : 58;
    const lm = parseInt ("" + ((cost * (nodo['width'] < nodo['height'] ? nodo['width'] : nodo['height'])) / 100));
    return {
      'height': lm + "px",
      'width': lm + "px",
      'left': (parseInt ("" + (nodo['width'] / 2)) - parseInt ("" + (lm / 2)) - 8) + "px",
      'top': nodo['height'] //parseInt ("" + ((9 * nodo['height']) / 100)) + "px"
    }
  }

  getImagePath(nodo) {
    return myGlobals.environment['ImgTastiera'] + "?idnegozio=" + myGlobals.environment['ConcessionSettings']['idnegozio'] + "&idtastiera=" + this.idtastiera + "&idnodo=" + nodo['idnodo'] + "&lob_size=SMALL&i=png-s";
  }

  getPSize (nodo, x = 0) {
    const cost = nodo['height'] < 240 ? 68 : 38;
    const fontSize = nodo['width'] === 120 ? 12 : 16;
    return {
      'bottom': '20px',
      'top': parseInt("" + (14 + nodo['height'] - ((nodo['height'] * cost) / 100))) + 'px',
      'text-align': 'center',
      'width': '100%',
      'left': 'auto',
      'right': 'auto',
      'position': 'absolute',
      'margin-top': (x + 35) + 'px !important',
      'font-size': fontSize + 'px'
    };
  }

  openNode (nodo: Nodo) {

    // Debounce
    if(this.canClickNode){
      this.canClickNode = false;
      setTimeout(() => {
        this.canClickNode = true;
      }, 500);

     // this.insideNode = nodo.idnodoPadre !== 0;

      this.clickedNodeEv.emit(nodo);

      if (nodo.idnodoPadre !== 0 && nodo.Nodi.length !== 0) {
        this.insideNode = true;
        this.previousNodi.push([...this._nodi]);
        this._nodi = nodo.Nodi;
        this.insideNodoName = nodo.nodo;
      }
    }
  }
  back = () => {
    this._nodi = this.previousNodi.pop();
    const isRoot = this.previousNodi.length === 1;
    this.insideNode = !isRoot;
    return isRoot;  // Returns whethers we are at the root or not
  }
}
