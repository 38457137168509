import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'products'
})
export class ProductsPipe implements PipeTransform {
  transform(value: string, nodo: any): string {
    var w: number = nodo['width'];
    var coeff: number = nodo['articolo'] == undefined ? 19 : 30;
    var l = parseInt ("" + ((coeff * w) / 100));
    return value.length > l ? value.substring(0, l) + "..." : value;
  }

}
