import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'secureDom'
})
export class SecureDomPipe implements PipeTransform {
  constructor( private domSanitizer:DomSanitizer){ }

  transform(value: any): any {
    let result = this.domSanitizer.bypassSecurityTrustResourceUrl( value );
    console.log("value %o %o", value, result);
    return result;
  }


}
