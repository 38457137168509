import { Component, OnInit, Input, ViewChild, SimpleChange } from '@angular/core';
import { ApiProviderWinticplusService } from '../../services/api-provider-winticplus.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogDataComponent } from '../../dialogs/dialog-data/dialog-data.component';
import { AssignProfileComponent } from '../assign-profile/assign-profile.component';
import { AlertDialogService } from '../../services/alert-dialog.service';
import { MatDialog } from '@angular/material';
import { DatatableComponent } from '../../shared/datatable/datatable.component';
import { FormComponent } from '../../shared/form/form.component';
import { FormHeaderComponent } from '../../shared/form-header/form-header.component';
import { LoaderService } from '../../loader';
import { PreferencesService } from "../../services/preferences.service";
import { ActionsService } from '../../services/actions.service';
import { Confirm } from "../../dialogs/confirm-dialog/confirm-dialog.component";

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/observable/fromEvent';

import { ENTER } from '@angular/cdk/keycodes';
const COMMA = 188;

import * as moment from "moment";
import "moment-timezone";
import { editable } from '../../entities/gce-management/gce-management';

const typeMap = {
  text: 'text',
  date: 'date',
  number: 'number',
  decimal: 'decimal',
  varchar: 'text',
  externalkey: 'select',
  action: 'action',
  select: 'selectStatic',
  boolean: 'boolean',
  array: 'custom',
  address: 'custom',
  actionprofile: 'actionprofile',
  color: 'color'
}
const tz = "Europe/Rome";
const dateFormat = "YYYY-MM-DDTHH:mm:ss";
@Component({
  selector: 'app-data-identity-list',
  templateUrl: './data-identity-list.component.html',
  styleUrls: ['./data-identity-list.component.css']
})
export class DataIdentityListComponent implements OnInit {

  constructor(private api: ApiProviderWinticplusService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private alertDialog: AlertDialogService,
    private actionsService: ActionsService,
    private loaderService: LoaderService,
    private prefService: PreferencesService) { }

  tz = "Europe/Rome"; //should be set automatically
  separatorKeysCodes = [ENTER, COMMA];
  columnLookUp = {};
  columns = [];
  rows = [];
  rowsBackup = [];
  displayedColumns = [];
  isLoadingRows: boolean = false;
  isLoadingColumns: boolean = false;
  insertAction: object | boolean = false;
  deleteAction: object | boolean = false;
  updateAction: object | boolean = false;
  actions: any[] = null;
  relationTable: boolean = false;
  title: string = "";
  keyRowId: {};
  keyRowIdValue: number;
  isPatient: boolean = false;

  @Input() dataEntry: Object;
  // Table
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild('formData', { static: false }) formData: FormComponent;
  @ViewChild('formHeader', { static: false }) formHeader: FormHeaderComponent;
  @Input() modal: boolean = false;
  limitRows: number;
  currentRow: any;
  editing: boolean = false;
  newRow: boolean = false;

  heightMainList: number;
  openRightPanel: boolean = false;

  onEdit() {
    this.newRow = false;
    this.editing = true;
    this.openRightPanel = true;
  }

  onNew() {
    this.editing = false;
    this.newRow = true;
    this.currentRow = {};
    this.columns.forEach(column => {
      // console.log("column", column);
      switch (column.type) {
        case 'text':
          break;
        case 'date':
          break;
        case 'number':
          let number: number = 0;
          this.currentRow[column.columnDef] = number;
          break;
        case 'decimal':
          let decimal: number = 0;
          this.currentRow[column.columnDef] = decimal;
          break;
        case 'select':
          break;
        case 'action':
          break;
        case 'selectStatic':
          break;
        case 'boolean':
          break;
        case 'custom':
          break;
        case 'actionprofile':
          break;
        //this.currentRow[column.columnDef]:column.type;
      }
    });
    if (this.keyRowId && this.keyRowIdValue) {
      this.currentRow[this.keyRowId['columnDef']] = this.keyRowIdValue;
    }
    this.openRightPanel = true;
    this.table.setNewRow(true);
  }

  onClickCustomColumn(args) {
    // console.log("clickCustomColumn %o", args);
    let callback = args.callback;
    let callbackOnForm = args.callbackOnForm;
    let params = args.params;
    this.currentRow = params[1];

    if (callbackOnForm) {
      setTimeout(() => {
        this.formData.data = params[1];
        this.formData.init();
        if (this.formData[callback]) {
          this.formData[callback].apply(this.formData, params);
        }
      }, 1);
    } else {
      if (this[callback]) {
        this[callback].apply(this, params);
      }
    }
  }

  onChangeRow(row) {
    // console.log("onChangeRow %o", row)
    this.currentRow = row;
    this.editing = false;
    this.newRow = false;
    this.openRightPanel = true;
  }

  onResetMFA(args) {
    console.log("onResetMFA %o", args);

    this.api.makeAPICall("resetMFA", { uid: args['subject'] }).subscribe(data => {
      // console.log("resetPassword response %o", data);
    }, err => {
      // console.log("resetPassword error %o", err);
    });

  }

  onResetPassword(args) {
    // console.log("onResetPassword %o", args);
    let confirm: Confirm = {
      title: "CONFIRMDIALOG.RESETPASSWORD.TITLE",
      message: "CONFIRMDIALOG.RESETPASSWORD.MESSAGE",
      buttonOkText: "CONFIRMDIALOG.RESETPASSWORD.BUTTONOK",
      buttonCancelText: "CONFIRMDIALOG.RESETPASSWORD.BUTTONCANCEL",
      buttonOkStyle: { 'background-color': '#268B26' },
      buttonCancelStyle: { 'background-color': '#AA3939' },
      withTranslate: true
    };
    this.alertDialog.confirm(confirm).subscribe(result => {
      if (result) {
        // console.log("query %o action %o",query, actionName);
        this.api.makeAPICall("resetPassword", { uid: args['subject'] }).subscribe(data => {
          // console.log("resetPassword response %o", data);
        }, err => {
          // console.log("resetPassword error %o", err);
        });
      }
    });
    // let outcome = confirm("Are you sure message?");
  }

  onSave(args) {
    // console.log("onSave %o",args);
    let data = args.data;
    let form = args.form;
    let editing = args.editing;
    let newRow = args.newRow;
    if (!data || !form || !form.valid) return;

    let dataCopy = data;
    let dataToSave = form.getRawValue();
    console.log("form %o data tosave %o dataCopy %o", form, dataToSave, dataCopy);
    for (let key in dataToSave) {
      if (typeof dataToSave[key] === "object") {
        if (dataToSave[key] instanceof Date) {
          dataCopy[key] = moment.tz(dataToSave[key], tz).format(dateFormat);
        } else {
          if (!(dataToSave[key] == null)) {
            if (dataToSave[key][key] instanceof Array) {
              dataCopy[key] = dataToSave[key][key];
            } else {
              dataCopy[key] = dataToSave[key];
            }
          }
        }
      } else {
        dataCopy[key] = dataToSave[key];
      }
    }

    this.save(dataCopy).subscribe(dataSaved => {
      for (let key in dataSaved) {
        data[key] = dataSaved[key];
      }
      let index = this.rows.indexOf(data);
      if (newRow && index === -1) {
        this.rowsBackup.push(data);
      }
      this.onSearch({ filter: "", fromDate: undefined, toDate: undefined });
      this.clear();
      this.table.setNewRow(false);
      this.loaderService.hide();
    },
      err => {
        // console.log(err);
        this.loaderService.hide();
        //alert(err.errorCode);
        this.alertDialog.open({
          title: "Error",
          message: err.errorMessage,
          messageCode: err.errorCode,
          isError: true,
          error: err.error
        });
      });
  }

  onRestore(row) {
    if (this.newRow) {
      this.currentRow = this.rows[0];
      this.table.setNewRow(false);
    }
    this.clear();
    if (this.rows.length == 0) {
      this.onNew();
    }
  }

  onDelete(row) {
    let index = this.rows.indexOf(row);

    // let outcome = confirm("Are you sure message?"); //todo replace with confirm dialog
    // if (outcome) {
    let idRow = "";
    this.columns.forEach(column => {
      if (column.primaryKey) {
        idRow += "/" + row[column.columnDef];
      }
    })
    this.api.makeAPICall('actionDelete', { urlParams: idRow, resource: this.dataEntry['resource'] }).subscribe(
      data => {
        this.rows.splice(index, 1);
        this.rowsBackup = [...this.rows];

        this.clear();
        this.table.setNewRow(false);
        if (this.rows.length == 0) {
          this.onNew();
        }
        this.onSearch({ filter: "", fromDate: undefined, toDate: undefined });
        this.loaderService.hide();
      },
      err => {
        // console.log(err);
        this.loaderService.hide();
        alert("cant do this");
      }
    );
    // }
  }

  clear() {
    this.editing = false;
    this.newRow = false;
    this.openRightPanel = false;
    //this.table.setNewRow(false);
  }

  onAction(args) {
    let data = args.data;
    let form = args.form;
    let action = args.action;

    // console.log("onAction %o %o %o", data, form, action);

    let dataCopy = data;
    let dataToSave = form.getRawValue();
    for (let key in dataToSave) {
      dataCopy[key] = dataToSave[key];
    }

    let query = {
      resource: this.dataEntry['resource'],
      action: {
        name: action.actionName,
        method: action.actionType
      },
      content: dataCopy
    };
    if (action.isInsertAction) {
      // console.log("insertAction %o", query);
      this.api.makeAPICall("actionGeneric", query)
        .subscribe(
          dataInserted => {
            this.rows.push(dataCopy);
            this.dataEntry['data']['rows'] = this.rows;
            this.clear();
            this.table.setNewRow(false);
            this.loaderService.hide();
          },
          err => {
            this.loaderService.hide();
            // console.log(err);
          });
    } else if (action.isDeleteAction) {
      let outcome = confirm("Are you sure message?");
      if (outcome) {
        this.api.makeAPICall("actionGeneric", query)
          .subscribe(
            dataDeleted => {
              let index = this.rows.indexOf(data);
              this.rows.splice(index, 1);
              this.rowsBackup = [...this.rows];
              this.dataEntry['data']['rows'] = this.rows;

              this.clear();
              this.table.setNewRow(false);
              if (this.rows.length == 0) {
                this.onNew();
              }
              this.loaderService.hide();
            },
            err => {
              this.loaderService.hide();
              // console.log(err);
            });
      }
    } else if (action.isUpdateAction) {
      this.api.makeAPICall("actionGeneric", query)
        .subscribe(
          dataUpdated => {
            for (let key in dataUpdated) {
              data[key] = dataUpdated[key];
            }
            this.dataEntry['data']['rows'] = this.rows;
            this.clear();
            this.table.setNewRow(false);
            this.loaderService.hide();
          },
          err => {
            this.loaderService.hide();
            // console.log(err);
          });
    }
  }

  onSearch(searchFilter) {
    // console.log("onSearch %o", filter);
    this.rows = this.rowsBackup.filter(item => {
      for (let key in item) {
        if (item[key] !== null && item[key] !== undefined && item[key].toString().toLowerCase().indexOf(searchFilter.filter) != -1)
          return true;
      }
      return false;
    });
    this.table.offset = 0;
  }

  onToggle(selectedColumns) {
    // console.log("onToggle %o %o", selectedColumns, this.dataEntry);
    this.columns.map(item => {
      item.show = selectedColumns.indexOf(item.columnDef) != -1;
    });
    // console.log(this.columns);
  }
  // End Table

  ngOnInit() {
    console.log('------------->ngOnInit DATA-IDENTITY-LIST dataEntry %o formData %o', this.dataEntry, this.formData);
    if (this.modal) {
      if (this.rows.length == 0) {
        this.onNew();
      }
    }
  }

  init(dataEntry): void {
    this.dataEntry = dataEntry;
    console.log('dataEntry %o', this.dataEntry);
    if ((this.dataEntry['structureAction'] != null) && (this.dataEntry['structureAction'].path.indexOf("patient") >= 0) && (this.dataEntry['resource'] != null) && (this.dataEntry['resource'].indexOf("winticplusaccount") >= 0)) {
      this.isPatient = true;
    }

    let self = this;
    if (this.modal) {
      this.limitRows = Math.floor(344 / 31);
    } else {
      this.limitRows = Math.floor((window.innerHeight - 340) / 31);
      this.heightMainList = window.innerHeight - 259;
    }

    this.title = dataEntry.title;
    this.isLoadingRows = true;
    this.isLoadingColumns = true;
    this.columnLookUp = {};
    this.rows = [];
    this.rowsBackup = [];
    this.columns = [];
    this.insertAction = false;
    this.deleteAction = false;
    this.updateAction = false;
    // if(self.filter){
    //   self.filter.nativeElement.value="";
    // }
    //console.log("dataEntry %o", dataEntry );
    if (dataEntry && dataEntry['data']) {
      this.relationTable = true;
      this.keyRowId = dataEntry['data']['keyRowId'];
      this.keyRowIdValue = dataEntry['data']['keyRowIdValue'];
      this.processRawRows(dataEntry['data']['rows']);
      this.processRawColumns(dataEntry['data']['columns']);
      if (dataEntry.actions && dataEntry.actions.constructor === Array) {
        this.actions = dataEntry.actions;
        dataEntry.actions.forEach(action => {
          if (action.isInsertAction) {
            this.insertAction = action;
          } else if (action.isDeleteAction) {
            action.iconName = action.iconName.replace(" ", "_");
            this.deleteAction = action;
          } else if (action.isUpdateAction) {
            action.iconName = action.iconName.replace(" ", "_");
            this.updateAction = action;
          } // else skip
        });
      }
      return;
    }

    this.api.makeAPICall("actionGetStructure", dataEntry)
      .subscribe(
        data => {
          console.log('after actionGetStructure %o', data);
          //this.loaderService.hide();
          this.processRawColumns(data['rows'])

          this.api.makeAPICall('actionGetall', dataEntry, true)
            .subscribe(
              data2 => {
                //console.log('ret getAll %o', data2);
                //this.loaderService.hide();
                self.processRawRows(data2);

              },
              err2 => {
                // console.log(err);
                self.isLoadingRows = false;
                self.rows = [];
                self.rowsBackup = [];
                self.columns = [];
                this.loaderService.hide();
                this.alertDialog.open({
                  title: "Connection error rows",
                  message: err2.errorMessage,
                  messageCode: err2.errorCode,
                  isError: true,
                  error: err2.error
                });
              });
        },
        err => {
          console.log(err);
          self.isLoadingColumns = false;
          self.rows = [];
          self.rowsBackup = [];
          self.columns = [];
          this.loaderService.hide();
          this.alertDialog.open({
            title: "Connection error columns",
            message: err.errorMessage,
            messageCode: err.errorCode,
            isError: true,
            error: err.error
          });
        });


  }

  processRawRows(data) {
    let self = this;
    self.rows = data.map(item => {
      return item;
    });
    //create a backup to apply the filter, need to update the backup data onChange
    self.rowsBackup = [...self.rows];

    //self.table.offset = 0;
    if (!self.relationTable) {
      // this.formHeader.viewSearch = true;
      //this.formHeader.init();
    }
    // console.log("processRawRows %o",self.rows);
    self.isLoadingRows = false;
  }

  mapRawColumn(item) {
    let action = undefined;
    let callback = undefined;
    let callbackOnForm: boolean = false;
    if (item.name === 'idsActionProfile') {
      item.type = 'actionprofile';
    }

    const type = item.type;
    if (type === 'action') {
      action = {
        name: item.name,
        iconName: item.iconName,
        isFont: item.isIconFont,
        label: item.label,
        type: type,
        rows: item.rows,
        actions: item.actions
      }
      callback = "onActionRow";
      callbackOnForm = false;
    } else if (type === 'array') {
      //item = item.rows[0];
      callback = "onEditCustomColumn";
      callbackOnForm = true;
    } else if (type === 'address') {
      callback = "onEditCustomColumn";
      callbackOnForm = true;
    } else if (type == 'actionprofile') {
      callback = "onAssignProfile";
      callbackOnForm = false;
    }
    return {
      columnDef: item.name,
      header: item.label,
      visible: item.visible || type === 'action',
      show: true,
      disabled: !item.editable,
      type: this.normalizeType(type),
      length: item.maxLength,
      required: item.required,
      requiredOnNew: item.requiredOnNew,
      reference: item.reference,
      primaryKey: item.isKey,
      lookUp: {},
      action: action,
      values: item.values,
      callback: callback,
      callbackOnForm: callbackOnForm,
      editable: item.editable,
      editableOnNew: true
    }
  }

  processRawColumns(data) {
    let self = this;

    let newColumns = data.map(item => {
      let column = self.mapRawColumn(item);
      if (column.type === 'custom') {
        column['subColumns'] = item.rows.map(item => self.mapRawColumn(item));
        column['subType'] = item.type;
      }
      if (column.primaryKey) {
        self.keyRowId = column;
      }
      //console.log("processRawColumns %o column %o", data, column);
      return column;
    });
    //self.columns = newColumns.filter(item => item.visible);
    this.columns = newColumns;
    this.columns.forEach(item => {
      self.fetchReferenceTable(item);
      if (item.type === 'custom') {
        // console.log(item.subColumns);
        item.subColumns.forEach(subColumn => {
          self.fetchReferenceTable(subColumn);
        });
      }
    });
    this.isLoadingColumns = false;
    if (this.dataEntry && this.dataEntry['widthFix']) {
      setTimeout(() => {
        // let ele : any = this.table.element.getElementsByTagName("datatable-body")[0];
        // ele.style.width = this.dataEntry['widthFix'];
        // ele = this.table.element.getElementsByTagName("datatable-header")[0];
        // ele.style.width = this.dataEntry['widthFix'];
      }, 400);

    }
    //console.log(self.columns);
  }

  normalizeType(type): string {
    type = type.toLowerCase().replace(/[^a-z]/gi, '');
    if (typeMap[type]) {
      return typeMap[type];
    } else return "text";
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    this.currentRow = undefined;
    this.clear();
    this.init(changes['dataEntry'].currentValue);
  }

  onReorderColumn(reordered) {
    let temp = this.columns[reordered.prevValue - 1];
    this.columns[reordered.prevValue - 1] = this.columns[reordered.newValue - 1];
    this.columns[reordered.newValue - 1] = temp;
  }

  save(row): Observable<any> {
    // console.log("save");
    let actionName = "actionUpdate";
    if (!(row.idsActionProfile == null) && (row.idsActionProfile.length > 0)) {
      for (let a = 0; a < row.idsActionProfile.length; a++) {
        if (row.idsActionProfile[a].idProfile == 61||row.idsActionProfile[a].idProfile == 62)
          actionName = "actionUpdatePatient";
      }

    }
    let query: any = { content: row, resource: this.dataEntry['resource'] };
    if (this.dataEntry['resource'] === "winticplusprofile" && this.editing) {
      actionName = "actionGeneric";
      query['action'] = {
        name: 'updateall',
        method: 'post',
      };
    } else if (this.dataEntry['resource'] === "winticplusaccount" && this.newRow) {
      actionName = "actionGeneric";
      query['action'] = {
        name: 'newaccount',
        method: 'post',
      };
    }

    //console.log("------------------------!!!!!!!!!!!!!!!!!!!!!!!!!!!-----------------------------query %o action %o",query, actionName);
    return Observable.create(observer => {
      this.api.makeAPICall(actionName, query)
        .subscribe(data => {
          observer.next(data);
        },
          err => {
            observer.error(err);
          }
        );
    });
  }

  onAssignProfile(column, row): void {
    console.log("column %o", column);
    console.log("row %o column.columnDef %o row[column.columnDef] %o", row, column.columnDef, row[column.columnDef]);
    let dataFirst = [];
    let cpDataFirst = dataFirst = row[column.columnDef];
    let find = false;
    if (cpDataFirst == null || !(cpDataFirst.length > 0))
      cpDataFirst = [];
    if (dataFirst == null || !(dataFirst.length > 0))
      dataFirst = [];
    if (dataFirst.length > 1) {
      dataFirst = [];
      for (let a = 0; a < cpDataFirst.length; a = a + 1) {
        //try to insert cpDataFirst[a] in proDomain
        find = false;
        if (a < cpDataFirst.length - 1) {
          for (let b = a + 1; b < cpDataFirst.length; b = b + 1) {
            if (cpDataFirst[b].idProfile == cpDataFirst[a].idProfile && cpDataFirst[b].idDomain == cpDataFirst[a].idDomain) {
              find = true;
            }
          }
          if (find == false)
            dataFirst.push(cpDataFirst[a]);
        }
        else
          dataFirst.push(cpDataFirst[a]);
      }
    }
    console.log("row %o dataFirst %o", row, dataFirst);

    //calluserdetail!
    this.loaderService.show();

    this.actionsService.getDetailUser(row['subject'], this.isPatient).subscribe(u => {
      this.loaderService.hide();
      let myProfiles = [];
      for (let a = 0; a < u['idsActionProfile'].length; a++) {
        myProfiles.push({
          idProfile: u['idsActionProfile'][a].idProfile,
          idDomain: u['idsActionProfile'][a].idDomain.toString()
        });
      }
      let dialogRef = this.dialog.open(AssignProfileComponent, {
        width: '70%',
        data: { profileDomains: myProfiles, user: u }
      });
      dialogRef.afterClosed().subscribe(data => {
        console.log("afterClosed");
        if (data) {
          let rowcp = data.user;;
          rowcp[column.columnDef] = data[column.columnDef];
          row[column.columnDef] = data[column.columnDef];
          //console.log("--------------------------------vvvvvvvvvvvvvvvvvvvvvvvvvv-----------------submit data %o row %o column.columnDef %o", data,row,column.columnDef);
          this.save(rowcp)
            .subscribe(data => {
              for (let key in data) { //write back in the row
                row[key] = data[key];
              }
              this.formData.init();
              this.loaderService.hide();
              //console.log("actionUpdate profile domains finish");
            },
              err => {
                // console.log(err);
                //alert(err.errorCode);
                this.loaderService.hide();
                this.alertDialog.open({
                  title: "Error",
                  message: err.errorMessage,
                  messageCode: err.errorCode,
                  isError: true,
                  error: err.error
                });
              });
        }
      });
    });

  }

  fetchReferenceTable(column) {
    this.columnLookUp[column.columnDef] = column;
    if (column.header) {
      this.translate.get(column.header).subscribe(data => {
        column.header = data;
      });
    }
    if (column.type === 'selectStatic') {
      column.options = column.values.map(item => {
        column.lookUp[item.value] = item.text;
        //console.log("selectStatic: %o",item);
        return { text: item.text, value: item.value };
      });
      return;
    }
    const ref = column.reference;
    if (!ref) return;
    let query = { resource: ref.entity };
    //console.log("fetchReferenceTable reference: %o", ref);
    this.api.makeAPICall('actionGetall', query)
      .subscribe(
        data => {
          //console.log("fetchReferenceTable data: %o", data);
          column.options = data.map(
            item => {
              this.translate.get(item[ref.value]).subscribe(data => {
                item[ref.value] = data;
              });
              column.lookUp[item[ref.keyColumn]] = item[ref.value];
              return {
                key: item[ref.keyColumn],
                value: item[ref.value]
              }
            });
          this.loaderService.hide();
        },
        err => {
          column.options = [];
          this.loaderService.hide();
          // console.log(err);
        }
      );
  }

  onActionRow(column, row) {
    let action = column.action;
    // console.log("onActionRow %o %o", row, action);
    const data = {
      data: {
        rows: row.actions,
        columns: action.rows,
        keyRowId: this.keyRowId,
        keyRowIdValue: row[this.keyRowId['columnDef']]
      },
      resource: this.dataEntry['resource'],
      widthFix: (window.innerWidth * 0.8 - 180).toFixed(2) + "px",
      actions: action.actions,
      dataListComponent: 'DataIdentity'
    };
    // console.log("dialog action data: %o", data)
    let dialogRef = this.dialog.open(DialogDataComponent, {
      width: "100%",
      height: "500px",
      data: data,
      panelClass: ['admin-dialog', 'gestione_utenti']
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      row.actions = result.data.rows;
    });
  }
  onCloseForm(event) {
    this.clear();
  }
}
