export interface Documenti {
  idcontenuto: number;
  ordine_categoria: number;
  ordine: number;
  categoria: string;
  descrizione: string;
  tipo: string;
  tipo_dato: string;
  modello: string;
}
export interface ProprietaArticolo {
  idproprieta: number;
  proprieta: string;
  idvalore: number;
  valore: string;
  descrizione_proprieta: string;
  tipo_dato: string;
  formato: string;
}
export interface Articolo {
  vendibile: number;
  data_usc: string;
  data_ent: string;
  idnegozio: number;
  idtastiera: number;
  idarticolo: number;
  codice_articolo: string;
  articolo: string;
  nome: string;
  validita_articolo: string;
  proprietaArticolo: Array<ProprietaArticolo>;
  tags: [
    {
      tag: string
    }
  ];
  documenti: Array<Documenti>;
  idcategoria: number;
  categoria: string;
  flag_distinta: number;
  iddistinta: number;
  qtaDistinta: number;
  distintaBase: Array<Object>;
  minVarianti: number;
  maxVarianti: number;
  variantixCategoria: number;
  variantixArticolo: number;
  varianti: Array<Articolo>;
  udm: string;
  idudm: number;
  scala_udm: number;
  idtipo_giacenza: number;
  flag_giacenza: number;
  tipo_giacenza: string;
  giacenza: number;
  idlistino: number;
  idprezzo: number;
  idiva: number;
  imponibile: number;
  importo: number;
  valIva: number;
  perc_iva: number;
  descrizione_iva: string;
  reparto_iva: string;
  percSconto: number;
  qtaUnitaria: number;
  image_enabled: number;
  small_image: number;
  large_image: number;
  timestamp: string;
  gallery_index: [
    {
      index: number;
      timestamp: string
    }
  ];
  decimali_iva: number;
  isScaleProduct: boolean; // True when the item requires to insert the quantity from a scale
  selected: number;  // Used on UX to set the items which has been selected by the user. IT's a number because shows how many times the item has been selected and this changes properly the quantity
  unselectable: boolean; // Used on UX to set whether the item is selectable or not. This depends, for instance, if the user has already selected the max items permited on a category
}

export interface ArticoliNegozio {
  idtastiera: number;
  idpostazione: number;
  agg_tastiera: string;
  TastieraArticoli: Nodo;
  Tastiera: Nodo;
  Articoli: Array<Articolo>;
  esito: true;
  errorMessage: string;
  errorCode: string;
  executionTime: string;
  idnegozio: number;
}

export interface Nodo {
  nodo: string;
  idnodo: number;
  idnodoPadre: number;
  Nodi: Array<Nodo>;
  idarticolo: number;
  image_enabled: number;
  small_image_nodo: number;
  large_image_nodo: number;
  timestamp: string;
  backcolor: number;
  forecolor: number;
  font_name: string;
  font_size: number;
  font_bold: number;
  text_align: number;
  top: number;
  left: number;
  width: number;
  height: number;
  tipo: number;
  image_width: number;
  image_height: number;
  articolo: Articolo;
}

// Groups variants by Category
export function getGroupCategoriesVariants(variants: Array<Articolo>): { [idcategoria: number]: Array<Articolo> }{
      return variants.reduce(function(rv, x) {
        (rv[x['idcategoria']] = rv[x['idcategoria']] || []).push(x);
        return rv;
      }, {});
}

export enum CheckSelectedVariantResult{
   Passed = 0,
   QtyMaxVariantsWrong = 1,
   QtyMinVariantsWrong = 2
}

export interface BaseResponseApi{
  errorCode: string;
  errorMessage: string;
  esito: boolean;
  executionTime: string;
  idnegozio: number;
}

export interface SearchProductItem{
  BarCode: string;
  Categoria: string;
  Codice: string;
  Descr: string;
  Idarticolo: number;
  Idcategoria: number;
  Nome: string;
  UltimaModifica: string;
}
export interface SearchProductResponse extends BaseResponseApi{
    articoli: Array<SearchProductItem>;
    numRigheTotali: number;
}

export interface GetProductDetailResponse extends BaseResponseApi{
  articoloTastiera: Articolo;
}
export interface PosConfigTastieraArticoli{
  idtastiera: number;
  idarticolo: number;
  varianti_gratis: number;
}
export interface PosConfiguration{
  TavoloLiberatoAdOgniComanda: boolean;
  VariantiAPagamento: boolean;
  MostraVariantiSempre: boolean;
  CopertoObbligatorio: boolean;
  IdUbicazioneDiIngresso: number;
  VisualizzazioneMappe: string;
  AttendiOrdinazioneAllaPrenotazioneTavolo: string;
  IdTastieraVarianti: number;
  tastiereArticoli: Array<PosConfigTastieraArticoli>;
}
export interface GetPosConfigurationResponse extends BaseResponseApi{
  posConfiguration: PosConfiguration;
}

export interface PostazioneSettings{
  IdPostazione: number;
  stringID: string;
}
export interface ConcessionSettings
{
  idnegozio: number;
  web_box: string;
  web_operator: string;
  sessionId: string;
  trackid: number;
  postazione: PostazioneSettings;
}

export interface PagingSettings{
  enabled: boolean;
  pageNumber: number;
  pageSize: number;
}
export interface PageableConcessionSettings extends ConcessionSettings{
  pagingParameterModel: PagingSettings;
}

export interface SearchProductSettings extends PageableConcessionSettings{
  stringToFind: string;
}

export interface GetProductDetailSettings extends ConcessionSettings{
  idnegozio: number;
  IdArticolo: number;
}
export interface GetTastieraVariantiSettings extends ConcessionSettings{
    IdTastieraVarianti: number;
}

export interface CustomVariantSelected{
  variant: Articolo;
  isToAdd: boolean;
}

const udm_table:{ [udmid: number]: { [udm_scala: string]: { short_label: string, label: string}}} =
   {
     2: {
      '0': { short_label: 'HH', label: 'Ore'},
      '-60': { short_label: 'MI', label: 'Minuti'},
      '-3600': { short_label: 'SS', label: 'Secondi'}
    },
    4: {
      '-3': { short_label: 'MM', label: 'Millimetri'},
      '-2': { short_label: 'CM', label: 'Centimetri'},
      '-1': { short_label: 'DM', label: 'Decimetri'},
      '0': { short_label: 'M', label: 'Metri'},
      '1': { short_label: 'DAM', label: 'Decametri'},
      '2': { short_label: 'HM', label: 'Ettometri'},
      '3': { short_label: 'KM', label: 'Chilometri'},
    },
    6: {
      '-3': { short_label: 'ML', label: 'Millilitri'},
      '-2': { short_label: 'CL', label: 'Centilitri'},
      '-1': { short_label: 'DL', label: 'Decilitri'},
      '0': { short_label: 'L', label: 'Litri'},
      '1': { short_label: 'DAL', label: 'Decalitri'},
      '2': { short_label: 'HL', label: 'Ettolitri'},
      '3': { short_label: 'KL', label: 'Chilolitri'},
    },
    8: {
      '-3': { short_label: 'MG', label: 'Milligrammi'},
      '-2': { short_label: 'CG', label: 'Centigrammi'},
      '-1': { short_label: 'DG', label: 'Decigrammi'},
      '0': { short_label: 'G', label: 'Grammi'},
      '1': { short_label: 'DAG', label: 'Decagrammi'},
      '2': { short_label: 'HG', label: 'Ettogrammi'},
      '3': { short_label: 'KG', label: 'Chilogrammi'},
      '5': { short_label: 'Q', label: 'Quintale'},
      '6': { short_label: 'T', label: 'Tonnellata'},
    },
    10: {
      '0': { short_label: 'NR', label: 'Numero'},
    },
  }
;



// Check whether the variants chosen for a given product are coherent with the settings of max_variants e min_variants
export function checkSelectedVariantsBeforeAddingItem(nodo: Nodo): CheckSelectedVariantResult{
     const max_variants = nodo.articolo.maxVarianti;
     const min_variants = nodo.articolo.minVarianti;

     const variants = nodo.articolo.varianti.filter(v => v.selected > 0);

     let maxVariantsCheckPassed = true;

     if(max_variants > 0){
         maxVariantsCheckPassed = variants.length === max_variants;
     }
     if(!maxVariantsCheckPassed){
           return CheckSelectedVariantResult.QtyMaxVariantsWrong;
     }
     if(min_variants > 0){
        const groupedProductsByCategory = getGroupCategoriesVariants(nodo.articolo.varianti);
        const groupedChosenVariantsByCategory = getGroupCategoriesVariants(variants);

        for(const category in groupedProductsByCategory){
          const chosenVariant = groupedChosenVariantsByCategory[category];
          if(chosenVariant){
            const qtyChosen = chosenVariant.length;
            if(qtyChosen > min_variants){
              return CheckSelectedVariantResult.QtyMinVariantsWrong;
            }
          }
        }
     }

     return CheckSelectedVariantResult.Passed;
}

// Set the variants as selected and check whether the category this variant belongs to permits the selection of othere variants.
// Finally return a boolean indicating whether all the variants selected satisfy the requirements on max_variants and min_variants.
export function selectVariants(nodo: Nodo, variantSelected: Articolo): boolean{
  const max_variants = nodo.articolo.maxVarianti;
  const min_variants = nodo.articolo.minVarianti;

  if(min_variants === 0 ||  variantSelected.selected < min_variants){
    variantSelected.selected++;
  }

  const groupedProductsByCategory = getGroupCategoriesVariants(nodo.articolo.varianti);

  if(min_variants > 0){
    const categoryVariants = groupedProductsByCategory[variantSelected.idcategoria];

    for(const variant of categoryVariants){
      variant.unselectable = false;
    }

     const categoryVariantsSelected = categoryVariants.filter(v => v.selected > 0).reduce((a,b) => a + b.selected, 0);
     if(categoryVariantsSelected === min_variants){
          for(const variant of categoryVariants){
            if(!variant.selected){
              variant.unselectable = true;
            }
          }
     }
  }

  const variants = nodo.articolo.varianti.filter(v => v.selected > 0).reduce((a,b) => a + b.selected, 0);

  if(variants > 0 && variants === max_variants){
    return true;
  }
  else{
    return false;
  }
}

export function getShortLabelUdm(idudm: number, udm_scale: number){
  return udm_table[idudm][udm_scale].short_label;
}

