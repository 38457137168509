import { Injectable } from '@angular/core';
import { TastieraArticoli } from '../../global/model/articoli/articoli.model';
import { Basket, BasketItem, VariantItem } from '../../global/model/basket/basket.model';
import { PointsOfSale } from '../../global/services/pos/pos.ans';
import { Printer } from '../../global/services/print/printers';
import { PaymentMethod, PaymentMethodScontrino } from '../../global/model/payments/payment-method';
import { AddArticoloResponse, listaArticoliScontrino, EmettiScontrinoResponse } from '../../global/model/payments/add-articolo-resp';
import { Payments, Payment, PaymentMethod2 } from '../../global/model/payments/payments';
import { CompletePaymentTicket } from '../../global/model/payments/complete-payment';
import { HttpClient } from "@angular/common/http";

import * as myGlobals from '../../global/settings';


@Injectable()
export class BasketService {
    public basket: Basket = new Basket();
    public selectedItem: TastieraArticoli.Articolo;
    public selectedBasketItem: BasketItem;
    public txtQuantity: number = 0;
    public updateQuantity: number = 0;
    public overwrite: boolean = false;
    public sessionNumber: string = this.getSessionNumber();
    public prePayment: boolean = false;
    public prePaymentCards: PaymentMethod;
    public payments: Payments;
    public lastTotal: number = 0;
    public lastCharge: number = 0;
    public prePaymentMessage: boolean = false;
    public PaymentMethods: PaymentMethod[];
    public decimalSeparator: string = this.whatDecimalSeparator();
    public StillPaying: boolean = !true;

    public posProblems = false;
    public posList: PointsOfSale[];

    public printerProblems = false;
    public printersList: Printer[];

    public showTDetails: boolean = false;

    public constructor(private http: HttpClient) {}

    public callPrePayment() {
        this.prePayment = true;
    }

    public multiplyPerProduct(): void {
        if (this.updateQuantity == 0) return;
    }

    private getSessionNumber() {
        if (this.sessionNumber == undefined) {
            if (localStorage.getItem('sessionNumber') == undefined || localStorage.getItem('sessionNumber') == '') {
                return this.generateSessionNumber();
            }
            else {
                this.sessionNumber = localStorage.getItem('sessionNumber');
                localStorage.removeItem('sessionNumber');
                return this.sessionNumber;
            }
        }

        return this.sessionNumber;
    }

    public generateSessionNumber(len = undefined, letter = undefined) {
        if (len == undefined && letter == undefined) {
            var sn = this.generateSessionNumber(3, true) + "_SESSION_ECWTS_" + this.generateSessionNumber(3, true) + this.generateSessionNumber(2, false) + this.generateSessionNumber(3, true) + this.generateSessionNumber(1, false) + this.generateSessionNumber(3, true) + this.generateSessionNumber(1, false);
            localStorage.setItem('sessionNumber', sn);
            return sn;
        }

        var text = "";
        var possibleL = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        var possibleC = "0123456789";

        for (var i = 0; i < len; i++)
            text += (letter ? possibleL.charAt(Math.floor(Math.random() * possibleL.length)) : possibleC.charAt(Math.floor(Math.random() * possibleC.length)));

        return text;
    }

    public emptyCart(): void {
        this.selectedBasketItem = this.selectedItem = undefined;
        this.basket.Items = [];
        this.basket.Total = this.txtQuantity = this.updateQuantity = 0;
        this.overwrite = false;
        this.prePaymentCards = undefined;
        this.payments = undefined;
        this.prePaymentMessage = true;

        this.sessionNumber = this.generateSessionNumber();
    }

    public removeUnit(): void {
        console.log("txtQ: " + this.txtQuantity);
        if (this.txtQuantity < 10) {
            this.removeFromBasket(this.selectedBasketItem);
            --this.txtQuantity;
            console.log("Unit removed");
        }
        else {
            this.removeFromBasket(this.selectedBasketItem, (this.txtQuantity - parseInt("" + this.txtQuantity / 10)));
            this.txtQuantity = parseInt("" + this.txtQuantity / 10);
            console.log("Unit removed: " + (this.txtQuantity - parseInt("" + this.txtQuantity / 10)));
        }
    }

    public updateBasket(num: number) {
        console.log("Item update");
        let qty: number = 0;
        for (let i = 0; i < this.basket.Items.length; i++)
            if (this.basket.Items[i].Item.idarticolo == this.selectedItem.idarticolo) {
                console.log("Item found");
                if (this.basket.Items[i].Quantity + num <= 0)
                    this.removeFromBasket(this.basket.Items[i]);
                else {
                    console.log("Update from " + this.basket.Items[i].Quantity + " to " + (this.basket.Items[i].Quantity + num));
                    this.basket.Items[i].Quantity += num;
                    this.basket.Total += (this.basket.Items[i].UnitPrice * num);
                    this.txtQuantity += num;

                    this.payments.Total = this.basket.Total;
                    this.payments.ToPay = this.basket.Total;
                }
            }
    }

    public addtoBasket(item: TastieraArticoli.Articolo, quantityToAdd: number = 1, variants: VariantItem[] = []): void {
        let found = false;
        let bI: BasketItem;
        let qty: number;

        if (this.prePaymentMessage) this.prePaymentMessage = false;

        if (variants.length == 0)
            for (let i = 0; i < this.basket.Items.length; i++)
                if (this.basket.Items[i].Item.idarticolo == item.idarticolo) {
                    console.log(this.basket.Items[i].Item.idarticolo + " == " + item.idarticolo);
                    if (quantityToAdd == 1)
                        qty = ++this.basket.Items[i].Quantity;
                    else {
                        this.basket.Items[i].Quantity += quantityToAdd;
                        qty = this.basket.Items[i].Quantity;
                    }
                    console.log("Item added FOR THE " + qty + "° TIME to the basket: " + item.idarticolo + ", " + item.articolo);
                    found = true;
                    bI = this.basket.Items[i];

                    // we can remove the element and "promote it" to the top of the queue
                    let itemToPromote = this.basket.Items[i];
                    this.basket.Items = this.basket.Items.filter(obj => obj !== this.basket.Items[i]);
                    this.basket.Items.unshift(itemToPromote);

                    console.log(this.basket.Items);
                    break;
                }

        if (!found) {
            let _basketItem = new BasketItem();
            _basketItem.Item = item;
            _basketItem.Quantity = quantityToAdd;
            _basketItem.UnitPrice = item.importo;
            _basketItem.Variants = variants;
            _basketItem.Id = this.getId();
            _basketItem.Image = this.getImage(item);

            console.log("Item added FOR THE 1ST TIME to the basket: " + item.idarticolo + ", " + item.articolo);
            // With "unshift" method we can add a new element at the top of our "stack"
            this.basket.Items.unshift(_basketItem);
            bI = _basketItem;
        }

        console.log("Basket: " + this.basket.Items.length);
        this.basket.Total += (item.importo * quantityToAdd);

        this.overwrite = true;
        this.selectMyItem(bI);
    }

    addOrRemoveItemCartWS(item, quantityToAdd): boolean {
        console.log("!!!!!!!!!!! WS CALLED !!!!!!!!!!!!");
        myGlobals.AddToCartData.sessionId = this.getSessionNumber();
        myGlobals.AddToCartData.listaCheckOut[myGlobals.AddToCartData.listaCheckOut.length] = {
            qtaRichiesta: quantityToAdd,
            idarticolo: item.idarticolo,
            varianti: null
        };
        if (this.payments == undefined) {
            this.payments = new Payments();
            this.payments.Payments = [];
        }

        let self = this;
        let resp: any | AddArticoloResponse;
        this.http.post(
            myGlobals.AddArticoloToCheckOut,
            myGlobals.AddToCartData
        )
        .subscribe(res => {
            resp = res;
            let f: BasketItem;
            console.log("rogges");
            myGlobals.AddToCartData.listaCheckOut = [];
            if (!resp.esito) {
                for (let oo of self.basket.Items)
                    if (oo.Item == item) {
                        f = oo;
                        break;
                    }
                return false;
            }
        });

        return true;
    }

    getImage (item: TastieraArticoli.Articolo): string {
        if (item.image_enabled) {
            return myGlobals.getArticleImage(item.idnegozio, item.idarticolo, item.small_image);
        }

        return "";
    }

    scrollTo(id: number): void {
        var aTag = $($(".basket-item[basketid='" + id + "']"));
        console.log(aTag);
        //$('#basket').scrollTop(0);
        //$('#basket').animate({ scrollTop: aTag.offset().top }, 'slow');
        $('#basket').animate({ scrollTop: 0 }, 'slow');
    }

    public selectMyItem(item: BasketItem) {
        this.selectedBasketItem = this.selectedItem = undefined;
        this.selectedItem = item.Item;
        this.selectedBasketItem = item;
        this.txtQuantity = this.selectedBasketItem.Quantity;
        let x = this;
        setTimeout(function () { x.scrollTo(item.Id); }, 500);
    }

    public removeFromBasket(item: BasketItem, qtyToRemove: number = -1): boolean {
        console.log("Old Total: " + this.basket.Total);
        let str;

        if (item == undefined) {
            console.log("Item undefined");
            console.log("%o", item);
            console.log("%o", this.basket);

            return;
        }
        if (item.Quantity == 1) {
            if (item.Item == this.selectedItem)
                this.selectedItem = undefined;
            this.basket.Items = this.basket.Items.filter(obj => obj !== item);
            str = "Item " + item.Item.articolo + " deleted. New total: " + this.basket.Total;

            if (this.basket.Items.length > 0) {
                this.selectedBasketItem = this.basket.Items[0];
                this.selectedItem = this.basket.Items[0].Item;
            }
            else {
                this.selectedBasketItem = this.selectedItem = undefined;
                this.txtQuantity = 0;
            }
        } else {
            let qty = 0;
            for (let i = 0; i < this.basket.Items.length; i++)
                if (this.basket.Items[i].Item.idarticolo == item.Item.idarticolo) {
                    if (qtyToRemove == -1)
                        qty = --this.basket.Items[i].Quantity;
                    else {
                        console.log(qtyToRemove + "--- BEFORE: " + this.basket.Items[i].Quantity);
                        this.basket.Items[i].Quantity -= qtyToRemove;
                        console.log("--- AFTER: " + this.basket.Items[i].Quantity);
                        qty = this.basket.Items[i].Quantity;

                        if (qty == 0) {
                            this.selectedBasketItem = this.selectedItem = undefined;
                            this.basket.Items = this.basket.Items.filter(obj => obj !== item);
                            this.txtQuantity = 0;
                        }
                    }
                    break;
                }
            str = "Unit of " + item.Item.articolo + " deleted. New quantity: " + qty + ", new total: " + this.basket.Total;
        }

        if (qtyToRemove == -1)
            this.basket.Total -= item.UnitPrice;
        else
            this.basket.Total -= item.UnitPrice * qtyToRemove;
    }

    public updateQuantityLabel(label: string): void {

    }

    public getTimestamp(): number {
        return +new Date();
    }

    public getId(): number {
        return +(parseInt("" + Math.random() * 100000) + "" + this.getTimestamp());
    }

    public addToPayments (quantity: number) {
        let x: Payment = new Payment();
        x.DatePayment = new Date();
        x.Total = quantity;
        x.PaymentOk = true;
        let pm = new PaymentMethod2();
        pm = this.prePaymentCards;
        pm.Paid = quantity;
        x.PaymentMethod = pm;
        this.payments.Payments[this.payments.Payments.length] = x;
        this.payments.ToPay -= quantity;
    }

    private whatDecimalSeparator() {
        var n = 1.1;
        return n.toLocaleString().substring(1, 2);
    }

    public Pay(payments, basket) : boolean {
        if (this.StillPaying) return;
        let x = new CompletePaymentTicket();
        x.idnegozio = 200;
        x.GUID = myGlobals.AddToCartData.GUID;
        x.web_box = "DESKTOP-SERGIO";
        x.web_operator = "SMART_OPERATOR_EVO";
        x.trackid = "4000";
        x.billType = "SF";
        x.sessionId = localStorage.getItem('sessionNumber');
        x.booked = "true";
        x.idordine = "SS1004314";
        //x.listaModalitaPagamento = new PaymentMethodScontrino()[payments.Payments == undefined ? 1 : payments.Payments.length];
        for (let pm of payments.Payments) {
            let t = new PaymentMethodScontrino();
            t.descrizione = "cassa";
            t.idmod_pag = pm.PaymentMethod.IdPM;
            t.importo = pm.PaymentMethod.Paid;
            t.mod_pag = pm.PaymentMethod.PaymentMethod;
            x.listaModalitaPagamento.push(t);
        }
        //x.listaArticoli = new listaArticoliScontrino()[basket.Items.length];
        for (let art of basket.Items) {
            let a = new listaArticoliScontrino();
            a.articolo = art.Item.articolo;
            a.codice_articolo = art.Item.codice_articolo;
            a.idarticolo = art.Item.idarticolo;
            a.idprezzo = art.Item.idprezzo;
            a.idudm = art.Item.idudm;
            a.percSconto = art.Item.percSconto;
            a.qta = art.Quantity;
            a.scala_udm = art.Item.scala_udm;
            //if (art.Variants.length == 0)
                a.variantiArticolo = null;
            /*else {
                a.variantiArticolo = new VarianteArticolo()[art.Variants.length];
                let va = new VarianteArticolo();
                for (let d of art.Variants) {
                    d.
                    va.articolo = d.articolo;
                    va.codice_articolo = d.codice_articolo;
                    va.idarticolo = d.idarticolo;
                    va.idprezzo = d.idprezzo;
                    va.idudm = d.idudm;
                    va.percSconto = d.percSconto;
                    va.qta = d.Quantity;
                    va.scala_udm = d.scala_udm;
                }
            }*/
            x.listaArticoli.push(a);
        }

        this.StillPaying = true;
        this.http.post(
            myGlobals.PayAndGenerateTicket,
            x
        )
        .subscribe(res => {
            let resp: any | EmettiScontrinoResponse = res
            console.log(resp, x);
            this.StillPaying = false;
        });

        return true;
    }
}
