import { Pipe, PipeTransform } from '@angular/core';
import { ColWidthByType, ColWidthByColName } from '../app.model';

@Pipe({
  name: 'colWidth'
})
export class ColWidthPipe implements PipeTransform {
  colWidthsTypes: ColWidthByType[]=[
     {
       "type":"boolean","width":50
     },
     {
       "type":"date","width":100
     },
     {
       "type":"select","width":180
     },
     {
       "type":"custom","width":80
     },
     {
       "type":"text","width":400
     },
     {
       "type":"selectStatic","width":100
     },
     {
       "type":"actionprofile","width":80
     },
     {
       "type":"action","width":80
     },
     {
       "type":"number","width":80
     },
     {
       "type":"array","width":80
     }
  ]
;
colWidthsByName: ColWidthByColName[] =[
  {"colName":"winticplusaccount.username", "width":200},
  {"colName":"winticplusaccount.provider", "width":90},
  {"colName":"winticplusaccount.providerId", "width":70},
  {"colName":"winticplusaccount.idOrganization", "width":110},
  {"colName":"winticplusaccount.registerAt", "width":125},
  {"colName":"winticplusaccount.idsActionProfile", "width":115},
  {"colName":"winticplusaccount.primaryEmail", "width":170},
  {"colName":"winticplusaccount.primaryEMailConfirmed", "width":195},
  {"colName":"winticplusaccount.confirmedEmailAt", "width":215},
  {"colName":"winticplusaccount.otherEmails", "width":80},
  {"colName":"winticplusaccount.telephoneNumber", "width":130},
  {"colName":"winticplusaccount.telephoneNumberConfirmed", "width":205},
  {"colName":"winticplusaccount.confirmedTelephoneNumberAt", "width":160},
  {"colName":"winticplusaccount.givenName", "width":70},
  {"colName":"winticplusaccount.familyName", "width":70},
  {"colName":"winticplusaccount.gender", "width":50},
  {"colName":"winticplusaccount.avatarUrl", "width":0},
  {"colName":"winticplusaccount.birthDate", "width":105},
  {"colName":"winticplusaccount.primaryAddress", "width":125},
  {"colName":"winticplusaccount.enabled", "width":65},

  {"colName":"winticplusact.idController", "width":210},
  {"colName":"winticplusact.order", "width":70},
  {"colName":"winticplusact.name", "width":170},
  {"colName":"winticplusact.labelCode", "width":250},
  {"colName":"winticplusact.path", "width":90},
  {"colName":"winticplusact.logoPath", "width":225},
  {"colName":"winticplusact.description", "width":250},
  {"colName":"winticplusact.hasIdParameter", "width":110},
  {"colName":"winticplusact.isTableManagement", "width":125},
  {"colName":"winticplusact.isSingleTableManagement", "width":190},

  {"colName":"winticplusctrl.idController", "width":140},
  {"colName":"winticplusctrl.name", "width":210},
  {"colName":"winticplusctrl.labelCode", "width":210},
  {"colName":"winticplusctrl.path", "width":200},
  {"colName":"winticplusctrl.logoPath", "width":170},
  {"colName":"winticplusctrl.order", "width":70},
  {"colName":"winticplusctrl.description", "width":330},

  {"colName":"winticplusapp.name", "width":210},
  {"colName":"winticplusapp.labelCode", "width":180},
  {"colName":"winticplusapp.order", "width":70},
  {"colName":"winticplusapp.path", "width":280},
  {"colName":"winticplusapp.logoPath", "width":170},
  {"colName":"winticplusapp.description", "width":380},

  {"colName":"winticplusprofile.name", "width":190},
  {"colName":"winticplusprofile.description", "width":210},

  {"colName":"winticplusprofile.idAction", "width":150},
  {"colName":"winticplusprofile.isRead", "width":125},
  {"colName":"winticplusprofile.isWrite", "width":125},
  {"colName":"winticplusprofile.isDelete", "width":140},

  {"colName":"winticplusdomain.name", "width":250},
  {"colName":"winticplusdomain.description", "width":250},

  {"colName":"winticplusorganization.name", "width":150},
  {"colName":"winticplusorganization.description", "width":300},

  {"colName":"BIGLIETTO.INIZIO_VALIDITA", "width":60},
  {"colName":"BIGLIETTO.IDTIPOBIGL", "width":90},
  {"colName":"BIGLIETTO.PREZZO_LORDO", "width":35},
  {"colName":"BIGLIETTO.IVA", "width":35},
  {"colName":"BIGLIETTO.DIR_PREV", "width":75},
  {"colName":"BIGLIETTO.DIR_PREN", "width":75},
  {"colName":"BIGLIETTO.VALUTA", "width":75},
  {"colName":"BIGLIETTO.FINE_VALIDITA", "width":75},
  {"colName":"BIGLIETTO.DIR_INTERNET", "width":75},

  {"colName":"TIPO_BIGLIETTO.TASTO", "width":115},
  {"colName":"TIPO_BIGLIETTO.TIPO_TITOLO", "width":160},
  {"colName":"TIPO_BIGLIETTO.DESCR_POSTO", "width":160},
  {"colName":"TIPO_BIGLIETTO.TRATTAMENTO", "width":83},
  {"colName":"TIPO_BIGLIETTO.DESCR", "width":237},

  {"colName":"TIPOTITOLO.CODICE", "width":90},
  {"colName":"TIPOTITOLO.DESCRIZIONE", "width":190},

  {"colName":"DATIFISSI.PATHTEMPLATE", "width":340},
  {"colName":"DATIFISSI.SCADPREN", "width":60},
  {"colName":"DATIFISSI.ORA", "width":90},
  {"colName":"DATIFISSI.COMUNE", "width":70},
  {"colName":"DATIFISSI.RAG_SOC", "width":200},
  {"colName":"DATIFISSI.FRAZIONE", "width":70},
  {"colName":"DATIFISSI.VALUTA", "width":70},
  {"colName":"DATIFISSI.ASSEGNAPOSTI", "width":70},
  {"colName":"DATIFISSI.CODFISC", "width":120},
  {"colName":"DATIFISSI.INTAGDS", "width":70},
  {"colName":"DATIFISSI.GIORNO", "width":70},
  {"colName":"DATIFISSI.CTRL_CARTA", "width":70},
  {"colName":"DATIFISSI.PATHTAGLIO", "width":300},
  {"colName":"DATIFISSI.VISUALIZZA_INIZIO", "width":70},
  {"colName":"DATIFISSI.PROVINCIA", "width":70},
  {"colName":"DATIFISSI.GESTORE", "width":200},
  {"colName":"DATIFISSI.VISUALIZZA_FINE", "width":70},

  {"colName":"TIPO_POSTO.TIPO", "width":240},

  {"colName":"FILM.TITOLO", "width":300},
  {"colName":"FILM.ANNO", "width":48},
  {"colName":"FILM.REGISTA", "width":150},
  {"colName":"FILM.COLORE", "width":48},
  {"colName":"FILM.RAGAZZI", "width":55},
  {"colName":"FILM.IDPROD", "width":60},
  {"colName":"FILM.IDNOLO", "width":60},
  {"colName":"FILM.DURATA", "width":50},
  {"colName":"FILM.IDNAZIONE", "width":150},
  {"colName":"FILM.ESENZIONE", "width":60},
  {"colName":"FILM.TIPO", "width":60},
  {"colName":"FILM.PASSO", "width":60},
  {"colName":"FILM.PROGR_OBBL", "width":60},
  {"colName":"FILM.ASSICURAZIONE", "width":60},
  {"colName":"FILM.VALUTA", "width":60},
  {"colName":"FILM.MODIFICA", "width":60},

  {"colName":"TARIFFE.CANCELLATO", "width":65},

  {"colName":"NAZIONI.IDNAZ", "width":60},
  {"colName":"NAZIONI.DESCR", "width":'auto'},
  {"colName":"NAZIONI.ESENZIONE", "width":80},
];

  constructor() {

  }
  transform(type:any, entity:string, colName:string): any {
     console.log(`${entity}.${colName}`);
    let w =[];
    if(colName && entity){
        w = this.colWidthsByName.filter(item=>item.colName===`${entity}.${colName}`);
    }
    if(w[0]===undefined) {
      w = this.colWidthsTypes.filter(item=>item.type===type);
    }

    if(w[0]===undefined) return { 'width.px': 80 };

    if(w[0]==='auto') return { 'width': 'auto' };
    
    return { 'width.px': w[0].width };
  }


}
