import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '../../shared/datatable/datatable.component';
import { FormHeaderComponent, ICustomButton, IFormHeaderSearch, FormHeaderSearchEventsEnum } from '../../shared/form-header/form-header.component';
import { GceDialogCalculateBillingComponent } from '../dialogs/gce-dialog-calculate-billing/gce-dialog-calculate-billing.component';
import { MatDialog } from '@angular/material/dialog';
import { GceManagementService } from '../../services/gce-management.service';
import { GceInvoice, GceInvoicePerson, GcePresence, GceClinic, GceLookUpOptions } from '../../entities/gce-management';
import { GceDialogBadgeFormComponent } from '../dialogs/gce-dialog-badge-form/gce-dialog-badge-form.component';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { PermissionService } from '../../services/permission.service';
const dateFormatDate = "YYYY-MM-DD";

@Component({
  selector: 'app-gce-badge',
  templateUrl: './gce-badge.component.html',
  styleUrls: ['./gce-badge.component.css']
})
export class GceBadgeComponent implements OnInit {
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild('formHeader', { static: false }) formHeader: FormHeaderComponent;
  columns: any[] = [];
  badge: GcePresence[] = [];
  badgeBkp: GcePresence[] = [];
  limitRows: number;
  heightMainList: number;
  currentRow: any;
  editing: boolean = false;
  newRow: boolean = false;
  keyColumn: any = {};
  calendarOpen: boolean = false;
  calendarPerson: GceInvoicePerson = null;
  customButtons: ICustomButton[] = [];
  invoices: GceInvoice[] = [];
  invoicesBkp: GceInvoice[] = [];

  titleHeader: string = null;
  refresChalendar: any = null;

  fromDate: string = this.gceService.dataRepoGceEnvironment.getFromDate();
  toDate: string = this.gceService.dataRepoGceEnvironment.getToDate();
  showb: boolean = false;
  writePermission: boolean;

  // customActions:ICustomAction[] = [{
  //   actionName: 'MedicCalendar',
  //   tooltip: 'GCE.GCEPRESENCE.BUTTONS.MONTHMEDIC',
  //   icon: 'assets/img/calendar/icon/date-picker.png',
  //   clickFunction: undefined
  // }];

  constructor(private dialog: MatDialog, private gceService: GceManagementService, private translateService: TranslateService) {
    this.writePermission = PermissionService.checkPermission("CGE_ECPR");

    if (PermissionService.checkPermission("CGE_CFTT")) {
      this.customButtons.push({
        matTooltipCode: 'GCE.GCEINVOICE.BUTTONS.CALCULATEBILLING.TOOLTIP',
        labelCode: 'GCE.GCEINVOICE.BUTTONS.CALCULATEBILLING.LABEL',
        img: 'assets/img/invoice.png',
        class: 'custom-button',
        clickFunction: this.openDialogCalculateBilling.bind(this)
      });
      this.customButtons.push({
        matTooltipCode: 'GCE.GCEINVOICEPERSON.BUTTONS.EXPORTTOEXCEL.TOOLTIP',
        labelCode: 'GCE.GCEINVOICEPERSON.BUTTONS.EXPORTTOEXCEL.LABEL',
        img: 'assets/img/excel.png',
        class: 'custom-button',
        clickFunction: this.exportToExcel.bind(this)
      });
      
    }

  }

  openDialogCalculateBilling() {
    console.log("openDialogCalculateBilling");
    let dialogRef = this.dialog.open(GceDialogCalculateBillingComponent, {
      width: "500px",
      height: "auto",
      disableClose: true,
      data: { month: this.toDate }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      //aggiornare dati
      console.log("result %o", result);
      this.showb = true;
      this.gceService.loadDataForInvoices(this.fromDate, this.toDate).subscribe((dataResult: { clinics: GceClinic[], persons: GceInvoicePerson[], invoices: GceInvoice[] }) => {
        //manage column based on logged profile
        this.invoices = dataResult.invoices;
        this.invoicesBkp = [...this.invoices];
        this.showb = false;
      }, err => {
        this.invoicesBkp = [];
        this.showb = false;
      });
    });
  }

  ngOnInit() {
    this.showb = true;
    this.limitRows = Math.floor((window.innerHeight - 269) / 31); //269 340
    this.heightMainList = window.innerHeight - 188; //188 259
    this.columns = GcePresence.getStructure();

    this.badgeBkp = [];

    this.gceService.loadDataForBadge(this.fromDate, this.toDate).subscribe((dataResult: { clinics: GceClinic[], persons: GceInvoicePerson[], presences: GcePresence[] }) => {
      this.columns.forEach(column => {
        console.log("column %o", column);
        if (column.primaryKey) {
          this.keyColumn = column;
        }
        if (column.reference) {

          let lookUpOptions: GceLookUpOptions;
          if (column.reference.entity === "GceInvoicePerson") {
            lookUpOptions = this.gceService.getLookUpAndOptions(this.gceService.dataRepoInvoicePersons, column.reference);
          } else if (column.reference.entity === "GceClinic") {
            lookUpOptions = this.gceService.getLookUpAndOptions(this.gceService.dataRepoClinics, column.reference);
          }
          if (lookUpOptions) {
            column.lookUp = lookUpOptions.lookUp;
            column.options = lookUpOptions.options;
          }
        }
      });

      this.badge = dataResult.presences;
      this.badgeBkp = [...this.badge];
      this.showb = false;
    }, err => {
      this.badgeBkp = [];
      this.showb = false;
    });
  }

  onEdit() {
    // this.newRow = false;
    // this.editing = true;
    this.openDialogBadgeForm(this.currentRow, false);
  }

  onNew() {
    // this.editing = false;
    // this.newRow = true;
    // this.table.setNewRow(true);
    let presence = new GcePresence();
    if (!!this.calendarPerson) {
      presence.idfatturazione = this.calendarPerson.idfatturazione;
    }
    this.openDialogBadgeForm(presence, true);
  }

  onSearch(searchFilter: IFormHeaderSearch) {
    //console.log("onSearch %o", searchFilter);

    if (searchFilter.event === FormHeaderSearchEventsEnum.INPUT_CHANGE) {
      let filterValues = searchFilter.filter.split(" ").filter(a => !!a);

      this.badge = this.badgeBkp.filter((item: GcePresence) => {
        for (let key in item) {
          if (!!item[key]) {
            //console.log("onSearch %o, %o", key, item[key]);
            if (!!(item[key] as GceInvoicePerson).idfatturazione) {
              //console.log("onSearch Person: %o %o", key, item[key]);
              if ((<GceInvoicePerson>item[key]).descrizione.split(" ").filter(val => {
                return filterValues.filter(fv => val.toLowerCase().indexOf(fv.toLowerCase()) != -1).length > 0;
              }).length >= filterValues.length) {
                return true;
              }
            }
            if (!!(item[key] as GceClinic).code_c) {
              //console.log("onSearch Clinic: %o %o", key, item[key]);
              if ((<GceClinic>item[key]).nome.split(" ").filter(val => {
                return filterValues.filter(fv => val.toLowerCase().indexOf(fv.toLowerCase()) != -1).length > 0;
              }).length >= filterValues.length) {
                return true;
              }
            } else {
              if (item[key] !== null && item[key] !== undefined && item[key].toString().toLowerCase().indexOf(searchFilter.filter) != -1) {
                return true;
              }
            }
          }

        }
        return false;
      });
    } else {
      if (searchFilter.event === FormHeaderSearchEventsEnum.FROM_CHANGE) {
        let mfd = moment(searchFilter.fromDate);
        let mtd = moment(searchFilter.toDate);
        this.fromDate = mfd.format(dateFormatDate);
        if (mtd.diff(mfd, 'days') > mfd.daysInMonth() || mtd.isBefore(mfd)) {
          this.toDate = mfd.endOf('month').format(dateFormatDate);
        }
      }
      if (searchFilter.event === FormHeaderSearchEventsEnum.TO_CHANGE) {
        let mfd = moment(searchFilter.fromDate);
        let mtd = moment(searchFilter.toDate);
        this.toDate = mtd.format(dateFormatDate);
        if (mtd.diff(mfd, 'days') > mtd.daysInMonth() || mtd.isBefore(mfd)) {
          this.fromDate = mtd.startOf('month').format(dateFormatDate);
        }
      }
      if (this.fromDate && this.toDate) {
        //console.log("search %o %o", this.fromDate, this.toDate);
        this.gceService.getPresencesFromToGeneric(this.fromDate, this.toDate).subscribe((presences: GcePresence[]) => {
          this.badge = presences;
          this.badgeBkp = [...this.badge];
        });
      }
    }

    this.table.offset = 0;
  }

  onToggle(selectedColumns) {
    console.log("onToggle %o %o", selectedColumns);
    this.columns.map(item => {
      item.show = selectedColumns.indexOf(item.columnDef) != -1;
    });
  }

  onChangeRow(row) {
    console.log("onChangeRow %o", row);
    this.currentRow = row;
    this.editing = false;
    this.newRow = false;
  }

  onRestore(event) {
    console.log("onRestore %o", event);
    if (this.newRow) {
      this.currentRow = this.badge[0];
      this.table.setNewRow(false);
    }
    this.clear();
    if (this.badge.length == 0) {
      this.onNew();
    }
  }

  onDelete(presence: GcePresence) {
    console.log("onDelete %o", presence);
    this.showb = true;
    this.gceService.deletePresence(presence).subscribe((response) => {
      this.showb = false;
    });
  }

  onAction(event) {
    console.log("onAction %o", event);
  }

  onCloseForm(event) {
    console.log("onCloseForm %o", event);
    this.clear();
  }

  onOpenDialog(args) {
    console.log("onOpenDialog %o", args);

  }

  onCustomActionClick(event) {
    console.log("onCustomActionClick %o", event);
    if (event.field.columnDef === 'actionOpenCalendar') {
      let presence: GcePresence = event.row;
      let person: GceInvoicePerson = this.gceService.dataRepoInvoicePersons.find((p: GceInvoicePerson) => p.idfatturazione === presence.idfatturazione);
      this.openCalendar(person);
    }
  }

  clear() {
    this.currentRow = null;
    this.editing = false;
    this.newRow = false;
  }

  exportToExcel() {
    console.log('export to excel %o', this.badge);
    this.gceService.exportBadgeToExcel(this.badge);
  }

  openCalendar(person: GceInvoicePerson) {
    console.log("openCalendar");
    this.clear();

    this.customButtons = [{
      labelCode: 'GCE.GCEPRESENCE.BUTTONS.MONTHMEDIC.CLOSE',
      matTooltipCode: 'GCE.GCEPRESENCE.BUTTONS.MONTHMEDIC.CLOSE',
      img: 'assets/img/back.svg',
      class: 'custom-button',
      clickFunction: this.closeCalendar.bind(this)
    }];
    this.customButtons.push({
      matTooltipCode: 'GCE.GCEINVOICE.BUTTONS.CALCULATEBILLING.TOOLTIP',
      labelCode: 'GCE.GCEINVOICE.BUTTONS.CALCULATEBILLING.LABEL',
      img: 'assets/img/invoice.png',
      class: 'custom-button',
      clickFunction: this.openDialogCalculateBilling.bind(this)
    });
    this.customButtons.push({
      matTooltipCode: 'GCE.GCEINVOICEPERSON.BUTTONS.EXPORTTOEXCEL.TOOLTIP',
      labelCode: 'GCE.GCEINVOICEPERSON.BUTTONS.EXPORTTOEXCEL.LABEL',
      img: 'assets/img/excel.png',
      class: 'custom-button',
      clickFunction: this.exportToExcel.bind(this)
    });

    this.refresChalendar = moment().valueOf();
    this.calendarPerson = person;
    this.titleHeader = `${this.translateService.instant('GCE.GCEPRESENCE.CALENDAR')} - ${person.descrizione}`;
    this.calendarOpen = true;

  }

  closeCalendar() {
    this.calendarOpen = false;
    this.calendarPerson = null;
    this.titleHeader = null;
    this.customButtons = [];
    this.customButtons.push({
      matTooltipCode: 'GCE.GCEINVOICE.BUTTONS.CALCULATEBILLING.TOOLTIP',
      labelCode: 'GCE.GCEINVOICE.BUTTONS.CALCULATEBILLING.LABEL',
      img: 'assets/img/invoice.png',
      class: 'custom-button',
      clickFunction: this.openDialogCalculateBilling.bind(this)
    });
    this.customButtons.push({
      matTooltipCode: 'GCE.GCEINVOICEPERSON.BUTTONS.EXPORTTOEXCEL.TOOLTIP',
      labelCode: 'GCE.GCEINVOICEPERSON.BUTTONS.EXPORTTOEXCEL.LABEL',
      img: 'assets/img/excel.png',
      class: 'custom-button',
      clickFunction: this.exportToExcel.bind(this)
    });
  }

  openDialogBadgeForm(presence: GcePresence, isNew: boolean) {
    let dialogRef = this.dialog.open(GceDialogBadgeFormComponent, {
      width: "auto",
      height: "auto",
      disableClose: true,
      data: { presence: presence, isNew: isNew, fromCalendar: this.calendarOpen }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('RESULT %o', result);
      if (result != null && result.saved) {
        if (isNew) {
          this.badgeBkp.push(result.presence);
        } else {
          let index = this.badgeBkp.findIndex((prsc: GcePresence) => prsc.idfatturazione === result.presence.idfatturazione && prsc.cod_clinica === result.presence.cod_clinica && moment(prsc.data).format(dateFormatDate) === moment(result.presence.data).format(dateFormatDate));
          //console.log("update %o %o %o", result.presence, index, this.badgeBkp[index]);
          this.badgeBkp[index] = result.presence;
        }
        this.onSearch({ filter: "", fromDate: undefined, toDate: undefined });

        if (this.calendarOpen) {
          this.refresChalendar = moment().valueOf();
        }
      }
    });
  }

  onCalendarOpenPresence(presence: GcePresence) {
    console.log("onCalendarOpenPresence %o", presence);
    this.openDialogBadgeForm(presence, false);
  }


  onDblClickRow(row) {
    console.log('on double click row %o', row);
    this.openDialogBadgeForm(row, false);
  }
}
