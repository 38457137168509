import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GceManagementService } from '../../../services/gce-management.service';
import { LoaderService } from '../../../loader/loader.service';
import { GceAnagraphic } from '../../../entities/gce-management';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PermissionService } from '../../../services/permission.service';

@Component({
  selector: 'app-gce-dialog-anagraphic',
  templateUrl: './gce-dialog-anagraphic.component.html',
  styleUrls: ['./gce-dialog-anagraphic.component.css']
})
export class GceDialogAnagraphicComponent implements OnInit {
  anagraphic: GceAnagraphic = null;

  submitted: boolean = false;
  form: FormGroup;

  writePermission:boolean;

  constructor(public dialogRef: MatDialogRef<GceDialogAnagraphicComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private gceService: GceManagementService, private loaderService: LoaderService,
    private fb: FormBuilder) {
    this.anagraphic = data.anagraphic;

    this.writePermission = PermissionService.checkPermission("CGE_ETF");

    console.log("data %o", data);
  }

  ngOnInit() {
    this.createForm();
    this.rebuildForm();
  }

  createForm() {
    this.form = new FormGroup(this.fb.group({
      mail: [{ value: '', disabled: !this.writePermission }, Validators.required],
      partita_iva: [{ value: '', disabled: true }],
      codice_fiscale: [{ value: '', disabled: !this.writePermission }, Validators.required],
      ragione_sociale: [{ value: '', disabled: !this.writePermission }, Validators.required],
      indirizzo: [{ value: '', disabled: !this.writePermission }, Validators.required],
      citta: [{ value: '', disabled: !this.writePermission }, Validators.required],
      cap: [{ value: '', disabled: !this.writePermission }, Validators.required],
      provincia: [{ value: '', disabled: !this.writePermission }, Validators.required],
      capitale_sociale: [{ value: '', disabled: !this.writePermission }, Validators.required],
      regime_fiscale: [{ value: '', disabled: !this.writePermission }, Validators.required],
      dataora_invio: [{ value: '', disabled: true }],
      esito: [{ value: '', disabled: true }],
      messaggi: [{ value: '', disabled: true }],
    }).controls, { updateOn: 'blur' });
  }

  rebuildForm() {
    this.form.patchValue({
      mail: this.anagraphic.mail,
      partita_iva: this.anagraphic.partita_iva,
      codice_fiscale: this.anagraphic.codice_fiscale,
      ragione_sociale: this.anagraphic.ragione_sociale,
      indirizzo: this.anagraphic.indirizzo,
      citta: this.anagraphic.citta,
      cap: this.anagraphic.cap,
      provincia: this.anagraphic.provincia,
      capitale_sociale: this.anagraphic.capitale_sociale,
      regime_fiscale: this.anagraphic.regime_fiscale,
      dataora_invio: this.anagraphic.dataora_invio,
      esito: this.anagraphic.esito,
      messaggi: this.anagraphic.messaggi,
    });
  }

  save() {
    this.submitted = true;
    let formData = this.form.getRawValue();

    if (this.form.valid) {
      this.loaderService.show();
      this.gceService.updateAnagraphic(formData).subscribe((result: GceAnagraphic) => {
        this.loaderService.hide();
        this.dialogRef.close({ saved: true, invoice: result });
      }, err => {

      });
    }
  }

  cancel() {
    this.dialogRef.close({ saved: false });
  }
}
