import { Injectable } from '@angular/core';
import { ApiAction, ApiProviderWinticplusService } from './api-provider-winticplus.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/Rx';
import {  GceEnvironment } from '../entities/gce-management';

import { Observer, merge } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { ExcelService } from './excel.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const actionGetEnvironment = new ApiAction(`${environment.gceAPI}/fmc_ambiente/`, (data) => { return new GceEnvironment(data[0].idunico, data[0].mese_lavoro, data[0].ultima_chiusura_incentive); }, 'get');

@Injectable({
  providedIn: 'root'
})
export class IncentiveService {
  dataRepoGceEnvironment: GceEnvironment = null;

  constructor(private _http: HttpClient, private apiService: ApiProviderWinticplusService) { }

  getAllTarget(pyld: any): any {
    return this._http.post(`${environment.gceAPI}/api/incentive_all_target/`, pyld, { responseType: 'json' });
  }

  getAllBonus(pyld: any): any {
    return this._http.post(`${environment.gceAPI}/api/incentive_all_bonus/`, pyld, { responseType: 'json' });
  }

  getAllAvanzamento(pyld: any): any {
    return this._http.post(`${environment.gceAPI}/api/incentive_all_risultato/`, pyld, { responseType: 'json' });
  }

  deletePrevisione(pyld: any): any {
    return this._http.post(`${environment.gceAPI}/incentive_previsioni_operatore_del?RigaId=${pyld.RigaId}`, pyld, { responseType: 'json' });
  }

  deleteTarget(pyld: any): any {
    return this._http.post(`${environment.gceAPI}/incentive_target_operatore_del?RigaId=${pyld.RigaId}`, pyld, { responseType: 'json' });
  }

  getFatture(pyld:any): any {
    return this._http.get(`${environment.getListaFatture}` + '/' +  pyld.idClinica + '/' + pyld.month, { responseType: 'json' });
  }

  getProduzioni(pyld:any): any {
    //return this._http.get(`${environment.getListaProduzioni}` + '/' +  pyld.idClinica + '/' + pyld.month, { responseType: 'json' });
    return this._http.get(`${environment.baseApiForecast}/prestazioniesecuzioni` + '/' +  pyld.idClinica + '/' + pyld.month, { responseType: 'json' });
  }

  getPianoPagamenti(pyld:any): any {
    //return this._http.get(`${environment.getListaProduzioni}` + '/' +  pyld.idClinica + '/' + pyld.month, { responseType: 'json' });
    return this._http.get(`${environment.baseApiForecast}/pianipagamento` + '/' +  pyld.idClinica + '/' + pyld.month, { responseType: 'json' });
  }

  getAppPassati(pyld:any): any {
    //return this._http.get(`${environment.getListaProduzioni}` + '/' +  pyld.idClinica + '/' + pyld.month, { responseType: 'json' });
    return this._http.get(`${environment.baseApiForecast}/apppassati` + '/' +  pyld.idClinica + '/' + pyld.month, { responseType: 'json' });
  }

  setCloseDate(closeDate: any)
  {
    let pyld = {
      idunico : this.dataRepoGceEnvironment.idunico,
      mese_lavoro : null,
      gruppo_articolo_nav_prest_default : '',
      gruppo_articolo_nav_ds : '',
      gruppo_articolo_nav_dac  : '',
      mese_aperto_calcolo  : null,
      richiesta_chiusura_produzione : false,
      messaggio_elaborazione_chiusura : '',
      flg_app_non_disponibile : null,
      messaggio_app : '',
      ultima_chiusura_incentive : closeDate
    };
    return this._http.post(`${environment.gceAPI}/updt_close_date/`, pyld, { responseType: 'json' });
  }

  getEnvironment(): Observable<GceEnvironment[]> {
    return Observable.create((observer: Observer<GceEnvironment[]>) => {
      this.apiService.makeAPICall(actionGetEnvironment).subscribe((data) => {
        console.log("GceManagementService.getEnvironment %o", data);
        this.dataRepoGceEnvironment = data;
        observer.next(data);
      }, err => {
        console.log("GceManagementService.getEnvironment %o", err);
        observer.error(err);
      });
    });
  }
 
}
