import { Observable } from 'rxjs/Rx';
import { YesNoDialog } from './yesno.dialog';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material';
import { Injectable } from '@angular/core';

@Injectable()
export class YesNoService {

    constructor(private dialog: MatDialog) { }

    public confirm(title: string, message: string, show: boolean): Observable<boolean> {

        let dialogRef: MatDialogRef<YesNoDialog>;

        dialogRef = this.dialog.open(YesNoDialog);
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;
        dialogRef.componentInstance.showCancel = show;

        return dialogRef.afterClosed();
    }
}