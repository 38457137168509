import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { LoaderState } from './loader-state';

@Injectable()
export class LoaderService {
  private loaderSubject = new Subject<LoaderState>();
  loaderState = this.loaderSubject.asObservable();

  constructor() { }

  show() {
    this.loaderSubject.next(<LoaderState>{show: true});
    setTimeout(()=>
      {
        this.hide();
        // console.log("automatic close loader");
      },
      600000
    );
  }
  hide() {
    /*setTimeout(()=>
      {
        this.loaderSubject.next(<LoaderState>{show: false})
      },
      1000
    );*/
    this.loaderSubject.next(<LoaderState>{show: false});
  }
}
