import { LOCALE_ID, Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, Renderer2, ComponentFactoryResolver } from '@angular/core';
import { AuthGuardService } from './../../services/auth-guard.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from './../../loader';
import { AddProductStoreDialogComponent } from '../shared/dialogs/add-product.dialog';
import { MatDialog } from '@angular/material';
import { TableService } from '../shared/services/table.service';
import * as $ from 'jquery';
import * as MySettings from '../../../environments/environment';
import { NotificationsService } from 'angular2-notifications';
import { YesNoDialogComponent } from '../shared/dialogs/yes-no.dialog';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { UserService } from './../../services/user.service';

import * as moment from 'moment';
import _date = moment.unitOfTime._date;
import { BackOrderDialogComponent } from '../shared/dialogs/backorder.dialog';
import { BackOrderMaxThresholdDialogComponent } from '../shared/dialogs/backorderthreshold.dialog';
import { OrderDetailDownloadDialogComponent } from '../shared/dialogs/order-detail-download.dialog';
import { Location, DatePipe } from '@angular/common';
import { ReturnStatement } from '@angular/compiler';


@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ActionComponent implements OnInit {

  DPversion = "2.2.0.8";
  rows = [];
  idBridge = 0;
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  columns = [];
  temp = [];
  sub: any;
  listaCambiFIltrabili = [];
  listaCambiTime = [];
  listaCambiFIltrabiliOpzioni = [];
  listaMasterSlave = [];
  doctors = [];
  doctor = {};
  key;
  url;
  dataInserimento;
  req;
  infoAggiuntive = false;
  entryPoint;
  sel;
  numericofiltro: number;
  numericofiltro2: number;
  sottoscortaJ;
  tableMessages;
  myUser;
  applied = false;
  tipoBolle = false;
  _tipologieBolle = [];
  //_images = MySettings.environment['images'];
  _images;
  header;
  _tiles;
  newOrder;
  clickable;
  doubleClickable;
  exception;
  mainId;
  firstOpen = true;
  selected = [];
  selectable;
  pparams;
  elementiPerPagina;
  filters = false;
  insideReports = false;
  hidJSON;
  backCaption;
  back;
  caption;
  subtitle;
  showReportParams;
  _params;
  filterDataDa;
  filterDataA;
  io = {};
  _repoParamsBack;
  infoOrdine = false;
  detB = false;
  excIndicator = false;
  reporequest;
  idcf; idf;
  forecast;
  stringafiltro;
  impostazioniForecast;
  clickedTile;
  _repoResp;
  _repoParams;
  _handlers = ['pdf', 'xls'];
  pulisciR = false;
  atterra = undefined;
  acco = "Documento accompagnatorio";
  cbimg = "./assets/images/icone/icn_back.png";
  ans;
  indGen = 0;
  ia1 = "";
  ia2;
  ia3 = "";
  ia4 = "";
  idfo = 0;
  myprop;
  bollaSave = false;
  requestBackorder;
  _reports;
  tot = 0;
  rifObj; notObj;
  backup = undefined;
  click;
  goOn;
  oldTile; editing = {};
  id_print = 0; updated = false;
  search; updated2;
  urlPDF;
  oldC;
  oldR;
  insideBolleUscita = false;
  insideAlerts = false;
  selectedEdit;
  startValueTime;
  columns2;
  myFId = "0";
  br: any[];
  brR: any[];
  ca = []; ca2 = [];
  expanded = false;
  filterStato = new FormControl("tutti");
  filterStatoCustom = new FormControl("tutticampi");
  filterStatoTime;
  filterMasterSlave;
  filterStatoCustomOpz = new FormControl("tutticampiopz");
  listaStati = [];
  idTipoDocumentoUscita;
  tableOffset;
  disableSem;
  residuo: number = 0;
  isAdmin: boolean = false;
  role: string = '';
  lengthInv = 0;

  @ViewChild(DatatableComponent, null) table: DatatableComponent;

  constructor(
    private _auth: AuthGuardService,
    private _http: HttpClient,
    private _route: ActivatedRoute,
    private _router: Router,
    private _dialog: MatDialog,
    private _tableService: TableService,
    private _notifications: NotificationsService,
    private _location: Location,
    private _loaderRogges: LoaderService,
    private _loaderRogges2: LoaderService,
    private _renderer: Renderer2,
    public userService: UserService
  ) {
    // this._renderer.addClass(document.body, 'withoutoverflow');
    this.tableOffset = 0;
    this.columns2 = [];
    this.goOn = true;
    this.columns2.push({ name: "Id", prop: "Id", flexGrow: 1, type: 'Int32' });
    this.columns2.push({ name: "Classe", prop: "CustomClasse", flexGrow: 4, type: 'String' });
    this.columns2.push({ name: "Famiglia", prop: "CustomFamiglia", flexGrow: 4, type: 'String' });
    this.columns2.push({ name: "Sottofamiglia", prop: "CustomSottofamiglia", flexGrow: 4, type: 'String' });
    this.columns2.push({ name: "Codice", prop: "CustomCodice", flexGrow: 4, type: 'String' });
    this.columns2.push({ name: "Articolo", prop: "Articolo", flexGrow: 4, type: 'String' });
    this.columns2.push({ name: "Confezioni", prop: "Confezioni", flexGrow: 2, type: 'Int32' });
    this._renderer.addClass(document.body, 'withoutoverflow');
    this.hidJSON = "";
    this.filterStatoTime = new FormControl(MySettings.environment.DefaultTime.viewvalue);
    this.filterMasterSlave = new FormControl('Solo padri');
    this._images = MySettings.environment.images;
    this.startValueTime = MySettings.environment.DefaultTime;
    this.listaCambiTime = MySettings.environment.TimeValues;
    this.listaMasterSlave = [{ value: 0, viewvalue: 'Solo padri' }, { value: 1, viewvalue: 'Tutti' }];
    this.numericofiltro = 0;
    this.numericofiltro2 = 0;
    this.stringafiltro = "";
    this.tableMessages = MySettings.environment.tableMessages;
    this.elementiPerPagina = 10;// +sessionStorage.getItem('elementiPerPagina');
    this.disableSem = false;
    if (this.elementiPerPagina == 0) this.elementiPerPagina = 10;
    this.userService.getCurrentUser().subscribe(f => {
      console.log('INIT STORE %o', f);
      this.myUser = f;

      console.log('------------------------------------------ > usr read %o', this.myUser);
      let usr = this._auth.getUser();
      if (usr != null) {
        this.role = usr['ruolo'];
      }
      if (this.role != null && (this.role.toLowerCase() == 'amministratore' || this.role.toLowerCase() == 'ufficio acquisti')) {
        this.isAdmin = true;
      }

      this.sub = this._route.params.subscribe(params => {
        this.pparams = params;
        this.entryPoint = params['entryPoint'];
        let id = 0; let idD = 0;
        if (params['idInv'] !== undefined) {
          id = +params['idInv'];
          this.entryPoint = 'inventoryDetail';
          this.mainId = id;
        } else if (params['id'] !== undefined) {
          id = +params['id'];
          this.entryPoint = 'orderDetail';
          this.mainId = id;
        } else if (params['repoCode'] !== undefined) {
          id = params['repoCode'];
          this.entryPoint = 'reports';
          this.mainId = id;
          this.insideReports = true;
        } else if (params['ided'] !== undefined) {
          id = +params['ided'];
          this.entryPoint = 'bolla-entrata-detail';
          this.mainId = id;
        } else if (params['idD'] !== undefined) {
          idD = +params['idD'];
          this.mainId = idD;
          if (params['tile'] == 'entry-docs') {
            this.subtitle = "ORDINE #" + idD + " - " + localStorage.getItem('fName');
            this.entryPoint = 'entry-docs-detail';
            this.clickable = false;
            this.doubleClickable = true;
          } else if (params['tile'] == 'exit-docs') {
            this.subtitle = "ORDINE #" + idD + " - " + localStorage.getItem('fName');
            this.entryPoint = 'exit-docs-detail';
            this.clickable = false;
            this.doubleClickable = true;
          } else {
            if (params['idD'] == "cross")
              this.subtitle = "FORNITORI MULTIPLI";
            else
              this.subtitle = params['tile'] + " " + (this._auth.getUser()['ruolo'] == "Ufficio acquisti" && sessionStorage.getItem('dup') != undefined ? sessionStorage.getItem('myFi') + " - " + localStorage.getItem('fName') : localStorage.getItem('fName'));
            this.entryPoint = 'flDetail';
            this.clickable = false;
            this.selectable = true;
            this.doubleClickable = true;
          }
        }


        this._images[6].visibile = this._images[6].enabled = this._images[7].visibile = this._images[7].enabled = false;

        this.filters = false;
        console.log('CALLTABLE contructor');
        this.callTable(id, idD);
        this._images[7].caption = "Crea un ordine sotto scorta";
        console.log('D1');
        //this._loaderRogges.hide();
        console.log('end constructor for images %o', this._images);
        if (this.entryPoint == "exit-docs-entry" && this._images != null && this._images.length > 0) {
          console.log('add button for exit-docs-entry');

          for (let a = 0; a < this._images.length; a = a + 1) {
            this._images[a].enabled = false;
            this.filters = true;
          }

        }
      });
    });
  }

  @ViewChild('mi1', null) _input: ElementRef;
  @ViewChild('mi2', null) _input2: ElementRef;
  @ViewChild('mi3', null) _input3: ElementRef;
  _openCalendar(picker: MatDatepicker<Date>, x = null) {
    picker.open();
    setTimeout(() => x == null ? this._input.nativeElement.focus() : x == 2 ? this._input2.nativeElement.focus() : this._input3.nativeElement.focus());
  }
  _openCalendarDyn(picker: MatDatepicker<Date>, id) {
    picker.open();
    var _input = document.getElementById(id);
    setTimeout(() => _input.focus());
  }

  getReportImg(x) {
    return './assets/images/tiles/v2/' + (x ? 'icn_report_2.png' : 'icn_report_hover.png')
  }

  focus() {

    $(function () {
      $('#txtQ').select();
      $('#txtQ').focus();
    });

  }

  focusLente(id) {
    console.log('FOCUS LENTE');


    $(function () {
      $('#' + id).select();
      $('#' + id).focus();
    });
    /* check
    if (this.br != undefined)
      this.rows = [...this.br];
      */
    this.editing = {};

  }

  focusLenteReport(id, ind) {
    //console.log('FOCUS LENTE REPORT');
    $(function () {
      $('#' + id).select();
      $('#' + id).focus();
    });
    if (this.brR != undefined) this._repoResp.Queries[ind].Table = [...this.brR];

  }


  hasClass(who, asc) {
    if (!asc && document.getElementsByClassName('sort-desc').length == 0) return true;
    if (asc && document.getElementsByClassName('sort-asc').length == 0) return true;
    if (!asc)
      for (let x of Array.prototype.slice.call(document.getElementsByClassName('sort-desc')))
        try {
          return !(who == x.children[0].children[0].children[0].children[1].children[1].innerHTML.trim());
        }
        catch (e) {
          return true;
        }
    if (asc)
      for (let x of Array.prototype.slice.call(document.getElementsByClassName('sort-asc')))
        try {
          return !(who == x.children[0].children[0].children[0].children[1].children[1].innerHTML.trim());
        } catch (e) {
          return true;
        }
    return true;
  }

  onSort(ev) {
    //console.log(ev);
  }

  rebuildTable() {

    //console.log('this.table %o element %o %o', document.getElementsByClassName('myTable'), document.getElementsByClassName('myTable')[0].children[0].children[1], document.getElementsByTagName('datatable-body')[0]);
    //document.getElementsByClassName('myTable')[0].children[0].children[1].scrollTop = 0;
    //document.getElementsByTagName('datatable-body')[0].scrollTop = 0;
    //$("datatable-body").scrollTop(0);

    //$('#my-ngx').css('height', '500px !important')

    //let tempRows = this.rows;
    let cpRows = this.rows;
    this.rows = [];

    this.columns = [];
    this.columns.push({ name: "Id", prop: "Id", flexGrow: 1, type: 'Int32' });
    this.columns.push({ name: "Classe", prop: "CustomClasse", flexGrow: 4, type: 'String' });
    this.columns.push({ name: "Famiglia", prop: "CustomFamiglia", flexGrow: 4, type: 'String' });
    this.columns.push({ name: "Sottofamiglia", prop: "CustomSottofamiglia", flexGrow: 4, type: 'String' });
    this.columns.push({ name: "Codice", prop: "CustomCodice", flexGrow: 4, type: 'String' });
    this.columns.push({ name: "Articolo", prop: "Articolo", flexGrow: 4, type: 'String' });
    this.columns.push({ name: "Confezioni", prop: "Confezioni", flexGrow: 2, type: 'Int32' });

    for (let c = 0; c < cpRows.length; c = c + 1) {
      this.rows.push(cpRows[c]);
    }

    console.log('rebuildTable for %o', this.rows);
    //this.selected = [];
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>, da = 1) {
    if (this.backup == undefined) this.backup = this.rows;
    if (event.value != null) {
      if (da == 3) {
        this.rows = this.backup;
        // >= o <=
        var interesse;
        if (this.filterStatoCustomOpz.value == "5")
          interesse = moment(event.value).add(23, 'h').add(59, 'm').toDate();
        else
          interesse = moment(event.value).add(0, 'h').add(0, 'm').toDate();
        if (this.backup == undefined) this.backup = this.rows;
        var campo = "";
        for (let x of this.columns)
          if (x.name.toLowerCase() == this.filterStatoCustom.value.toLowerCase()) {
            campo = x.prop;
            break;
          }
        var preR =
          this.filterStatoCustomOpz.value == "1" ?
            this.backup.filter(
              x => new Date(x[campo]) >= interesse && new Date(x[campo]) <= moment(event.value).add(23, 'h').add(59, 'm').toDate()
            )
            :
            this.filterStatoCustomOpz.value == "5" ?
              this.backup.filter(
                x => new Date(x[campo]) <= interesse
              )
              :
              this.backup.filter(
                x => new Date(x[campo]) >= interesse
              );
        //if (this.filterStato.value != "tutti")
        //preR = preR.filter(x => x.StatoAvanzamento === this.filterStato.value.toUpperCase());
        this.rows = [...preR];
        return;
      }
      if (da == 1) this.filterDataDa = event.value; else this.filterDataA = moment(event.value).add(23, 'h').add(59, 'm').toDate();
      if (this.filterDataDa != undefined && this.filterDataA != undefined) {
        if (this.backup == undefined) this.backup = this.rows;
        var preR = this.backup.filter(x => new Date(x.DataIns) >= this.filterDataDa && new Date(x.DataIns) <= this.filterDataA);
        if (this.filterStato.value != "tutti")
          preR = preR.filter(x => x.StatoAvanzamento === this.filterStato.value.toUpperCase());
        this.rows = [...preR];
      }
      else
        this.rows = [...this.backup];
    }
    else
      this.rows = [...this.backup];
  }

  callDocsEntry() {
    console.log('CHECK');

    this.insideBolleUscita = false;
    this.key = 'orders-add';
    this.url = undefined;
    this.req = undefined;
    this.header = false;
    this.newOrder = true;
    this.clickable = false;
    this.selectable = false;

    this.back = "";
    this.backCaption = "Dashboard";
    this.caption = "Bolle di Uscita";
    this.subtitle = "Seleziona una tipologia";
    this.tipoBolle = true;

    var req = {
      idnegozio: MySettings.environment.idNegozio,
      GUID: this._auth.getGUID(),
      DomainId: this._auth.getDomain(),
      ProfileId: this._auth.getProfile(),
      "CategoriaFornitore": "FORNITORE",
      "DomainContext": MySettings.environment.MyContext
    };

    this._tableService.rows = this.rows = this.columns = [...[]];

    this.loadingIndicator = true;
    this.infoAggiuntive = false;
    /*
        this._http.post(
          MySettings.environment.GetBottoniBolleUscita,
          req
        )
          .map((res) => {
            this.loadingIndicator = false;
            this.header = false;
            var eli = ['uscite', 'uscite_destin', 'uscite_r', 'uscite_td'];
            for (let e of eli) sessionStorage.removeItem(e);
            this.bollaSave = false;
            this._tipologieBolle = res['tipologieBolla'];
            for (let t of this._tipologieBolle) t['img'] = './assets/images/tiles/v2/icn_uscite.png';
          })
          .subscribe(val => []);
          */

    this._http.post(
      MySettings.environment.GetBottoniBolleUscita,
      req
    )
      .subscribe((res) => {
        console.log('res in callDocsEntry %o', res);
        this.loadingIndicator = false;
        this.header = false;
        var eli = ['uscite', 'uscite_destin', 'uscite_r', 'uscite_td'];
        for (let e of eli) sessionStorage.removeItem(e);
        this.bollaSave = false;
        this._tipologieBolle = res['tipologieBolla'];
        for (let t of this._tipologieBolle) t['img'] = './assets/images/tiles/v2/icn_uscite.png';
      });
  }


  callTable(id = 0, idD = 0) {
    this.updated = false;
    this._images[7].enabled = false;
    this.impostazioniForecast = this.forecast = false;
    console.log('CALLTABLE THIS.ENTRYPOINT %o', this.entryPoint);

    switch (this.entryPoint) {
      case 'exit-docs-entry-list':

        this.url = MySettings.environment.GetElencoBolleEntrata;
        this.req = {
          idnegozio: MySettings.environment.idNegozio,
          GUID: this._auth.getGUID(),
          DomainId: this._auth.getDomain(),
          ProfileId: this._auth.getProfile(),
          withStock: true
        };
        console.log('scarico');
        break;
      case 'imposta-forecast':

        this.back = "";
        this.backCaption = "Dashboard";
        this.caption = "Impostazioni forecast";
        this.subtitle = "Seleziona mese ed anno";
        this.header = true;
        this.newOrder = false;
        this.clickable = false;
        this.selectable = true;

        this.impostazioniForecast = true;

        this._images[0].visibile = true;
        this._images[0].enabled = true;

        for (let i of this._images) {
          i.caption = i.caption.replace("l'ordine", "la bolla");
          i.caption = i.caption.replace("ordine", "bolla");
          i.caption = i.caption.replace("un nuovo", "una nuova");
          i.caption = i.caption.replace("il", "la");
          i.caption = i.caption.replace("zionato", "zionata");
        }

        this._images[1].visibile = !true;
        this._images[2].visibile = !true;
        this._images[3].visibile = !true;
        this._images[4].visibile = !true;
        this._images[5].visibile = !true;

        break;
      case 'forecast':

        this.back = "";
        this.backCaption = "Dashboard";
        this.caption = "Forecast";
        this.subtitle = "Compila la tabella corrispondente";
        this.header = true;
        this.newOrder = false;
        this.clickable = false;
        this.selectable = false;
        this.tipoBolle = false;
        this.loadingIndicator = undefined;

        this.forecast = true;
        this.exception = true;

        this._images[0].visibile = true;
        this._images[0].enabled = true;

        for (let i of this._images) {
          i.caption = i.caption.replace("l'ordine", "la bolla");
          i.caption = i.caption.replace("ordine", "bolla");
          i.caption = i.caption.replace("un nuovo", "una nuova");
          i.caption = i.caption.replace("il", "la");
          i.caption = i.caption.replace("zionato", "zionata");
        }

        this._images[1].visibile = !true;
        this._images[2].visibile = !true;
        this._images[3].visibile = !true;
        this._images[4].visibile = true;
        this._images[4].enabled = true;
        this._images[5].visibile = !true;
        break;
      case 'entry-docs':
        this.key = 'ordini';

        this.url = MySettings.environment.GetElencoBolleEntrata;
        this.req = {};
        //if(this.isAdmin == true)
        //{
        this.req = {
          idnegozio: MySettings.environment.idNegozio,
          GUID: this._auth.getGUID(),
          DomainId: this._auth.getDomain(),
          ProfileId: this._auth.getProfile()
        };
        //}
        for (let a = 0; a < MySettings.environment.TimeValues.length; a = a + 1) {
          if (MySettings.environment.TimeValues[a].viewvalue == this.filterStatoTime.value) {
            if (!(MySettings.environment.TimeValues[a].value == 0)) {
              this.req.DataMax = new Date(Date.now());
              this.req.DataMin = new Date(Date.now() - (MySettings.environment.TimeValues[a].value * 30 * 24 * 60 * 60 * 1000));
            }
            else {
              this.req.DataMax = new Date(Date.now());
              this.req.DataMin = new Date(Date.now() - (24 * 30 * 12 * 60 * 60 * 1000));
            }
            this.req.DataMax.setHours(23);
            this.req.DataMax.setMinutes(59);
            this.req.DataMin.setHours(0);
            this.req.DataMin.setMinutes(0);

            this.req.DataMax = moment.utc({ year: this.req.DataMax.getFullYear(), month: this.req.DataMax.getMonth(), day: this.req.DataMax.getDate(), hour: this.req.DataMax.getHours(), minute: this.req.DataMax.getMinutes(), second: this.req.DataMax.getSeconds(), millisecond: this.req.DataMax.getMilliseconds() }).locale("it");
            this.req.DataMin = moment.utc({ year: this.req.DataMin.getFullYear(), month: this.req.DataMin.getMonth(), day: this.req.DataMin.getDate(), hour: this.req.DataMin.getHours(), minute: this.req.DataMin.getMinutes(), second: this.req.DataMin.getSeconds(), millisecond: this.req.DataMin.getMilliseconds() }).locale("it");
          }
        }

        this.back = "";
        this.backCaption = "Dashboard";
        this.caption = "Bolle di entrata";
        this.subtitle = undefined;
        this.header = true;
        this.newOrder = false;
        this.clickable = false;
        this.selectable = true;
        this.filters = true;
        this._images[0].visibile = true;
        this._images[0].enabled = true;

        for (let i of this._images) {
          i.caption = i.caption.replace("l'ordine", "la bolla");
          i.caption = i.caption.replace("ordine", "bolla");
          i.caption = i.caption.replace("un nuovo", "una nuova");
          i.caption = i.caption.replace("il", "la");
          i.caption = i.caption.replace("zionato", "zionata");
        }

        this._images[1].visibile = !true;
        this._images[2].visibile = !true;
        this._images[3].visibile = !true;
        this._images[4].visibile = !true;
        this._images[5].visibile = true;
        break;

      case 'reports':
        var rparent;
        var r = null;
        this.caption = "Reports";
        if (this.insideReports) {
          r = JSON.parse(localStorage.getItem('repoInfo'));
          this.subtitle = r['Descr'];
          rparent = r['RCode'];
          this.back = (r['RParent'] == "") ? "action/reports" : "action/reports/" + r['RParent'];
          this.backCaption = (r['RParent'] == "") ? "Torna ai report" : "Sottomenu precedente";
        } else {
          rparent = "";
          this.subtitle = "Seleziona un'opzione";
          this.back = "";
          this.backCaption = "Dashboard";
        }

        this.header = false;
        this.newOrder = false;
        this.clickable = false;
        this.selectable = false;
        this.excIndicator = true;

        var reqq; var ourkey;
        this.loadingIndicator = true;

        if (r != null && r['IsReport']) {
          this.back = r['RParent'] == "" ? "/action/reports" : "";
          for (let i of this._images) i.visibile = false;
          this._images[5].src = "excel";
          this._images[5].visibile = true;
          this._images[5].enabled = false;
          this._images[5].caption = "Esporta il report";
          this._images[5].action = 'repoXLS';
          url = MySettings.environment.GetReportParams;
          reqq = {
            idnegozio: MySettings.environment.idNegozio,
            GUID: this._auth.getGUID(),
            DomainId: this._auth.getDomain(),
            ProfileId: this._auth.getProfile(),
            RCode: this.mainId
          };
          ourkey = 'Items';
        } else {
          url = MySettings.environment.GetReports;
          reqq = {
            idnegozio: MySettings.environment.idNegozio,
            GUID: this._auth.getGUID(),
            DomainId: this._auth.getDomain(),
            ProfileId: this._auth.getProfile(),
            RParent: rparent
          };
          ourkey = 'Items';
        }

        this._http.post(
          url,
          reqq
        )
          .subscribe((res) => {
            this.reporequest = res;
            if ((r != null && !r['IsReport']) || (res['Items'] != undefined && res['Items'].length > 0)) {
              this._reports = res[ourkey];
              this.loadingIndicator = false;
            } else {
              if (res['Params'].length > 0) {
                this.subtitle = "Compila i parametri per generare il report - " + r['Descr'];
                this.loadingIndicator = false;
                for (let p of res['Params']) if (p.value == undefined || p.value == null) p.value = "";
                this._params = res['Params'];
                this.showReportParams = true;
                return;
              } else {
                this.showReportParams = true;
                this.subtitle = "Scegli la modalità di fruizione del report - " + r['Descr'];
                this.loadingIndicator = false;
                this._repoParamsBack = res['Params'];
                this.generateReport(res['Params'], 'xxx');
              }
            }
          });
        break;

      case 'inventory':
        this.req = {};
        if (!(this.myUser == null) && !(this.myUser['inventario'] == null) && (this.myUser['inventario'] == 'true')) {
          this.req = {
            idnegozio: MySettings.environment.idNegozio,
            GUID: this._auth.getGUID(),
            DomainId: this._auth.getDomain(),
            ProfileId: this._auth.getProfile(),
            DataInventario: "2018-05-07T12:00:01",
            pagingParameterModel:
            {
              "enabled": false,
              "pageNumber": 1,
              "pageSize": 10
            }
          };
        }
        this.key = 'inventari';
        this.url = MySettings.environment.GetElencoInventari;

        this.back = "";
        this.backCaption = "Dashboard";
        this.caption = "Gestione Inventario";
        this.subtitle = undefined;
        this.header = true;
        this.newOrder = false;
        this.clickable = false;
        this.selectable = true;

        for (let i of this._images)
          i.caption = i.caption.replace("ordine", "inventario");

        this._images[0].visibile = true;
        this._images[0].enabled = true;
        this._images[1].visibile = false;
        this._images[3].visibile = false;
        this._images[4].visibile = false;
        this._images[5].visibile = true;

        this._images[5].src = "excel";
        break;

      case 'inventoryDetail':
        this.req = {};
        if (!(this.myUser == null) && !(this.myUser['inventario'] == null) && (this.myUser['inventario'] == 'true')) {
          this.req = {
            idnegozio: MySettings.environment.idNegozio,
            GUID: this._auth.getGUID(),
            DomainId: this._auth.getDomain(),
            ProfileId: this._auth.getProfile(),
            idDoc: (JSON.parse(localStorage.getItem('pz')))['Iddoc'],
            pagingParameterModel:
            {
              "enabled": false,
              "pageNumber": 1,
              "pageSize": 10
            }
          };
        };
        this.key = 'inventario';
        this.url = MySettings.environment.GetDettaglioInventario;

        this._images[5].visibile = false;
        if ((JSON.parse(localStorage.getItem('pz')))['TipoDocumento'] == "INVENTARIO PROVVISORIO" || (JSON.parse(localStorage.getItem('pz')))['TipoDocumento'] == "INVENTARIO CONTATI")
          for (let i of this._images)
            i.enabled = true;

        this._images[1].enabled = this._images[2].enabled = false;

        this.back = "action/inventory";
        this.backCaption = "Elenco inventari";
        this.caption = "Dettaglio inventario";
        this.subtitle = "#" + ((JSON.parse(localStorage.getItem('pz'))['Iddoc'])) + " - " + ((JSON.parse(localStorage.getItem('pz'))['Filiale']));
        this.header = true;
        this.newOrder = false;
        this.clickable = false;
        this.selectable = true;

        for (var i of this._images)
          i.caption = i.caption.replace("ordine", "inventario");
        this._images[0].caption = "Aggiungi uno o più prodotti";
        this._images[1].caption = "Modifica la quantità del prodotto selezionato";
        this._images[2].caption = "Cancella il prodotto selezionato";
        this._images[4].caption = "Salva e conferma inventario definitivo";
        break;

      case 'inventory-add':
        this.back = "action/inventory";
        this.backCaption = "Inventario";
        this.caption = "Nuovo Inventario";
        this.subtitle = undefined;
        this.header = true;
        this.newOrder = false;
        this.clickable = false;
        this.selectable = true;

        this._images[0].visibile = true;
        this._images[0].enabled = true;
        this._images[3].visibile = this._images[1].visibile = this._images[5].visibile = false;
        this._images[4].visibile = this._images[3].visibile = true;
        this._images[4].enabled = false;

        this._images[0].caption = "Aggiungi uno o più prodotti";
        this._images[2].caption = "Cancella il prodotto selezionato";

        this.columns = [];
        this.columns.push({ name: "Id", prop: "Id", flexGrow: 1, type: 'Int32' });
        this.columns.push({ name: "Codice Confezioni", prop: "BarCodeAggiunta", flexGrow: 2, type: 'Int32', editable: 0 });
        this.columns.push({ name: "Articolo", prop: "Articolo", flexGrow: 4 });
        this.columns.push({ name: "Confezioni Attese", prop: "QtaAttesa", flexGrow: 2, type: 'Int32', editable: 0 });
        this.columns.push({ name: "Confezioni Contate", prop: "QtaContata", flexGrow: 2, type: 'Int32', editable: 1 });
        //this.columns.push({ name: "Aggiungi Confezioni", prop: "QtaAggiunta", flexGrow: 2, type: 'Int32', editable: 1 });

        this.temp = [];
        this.rows = [];
        this.req = this.url = undefined;
        this.exception = true;
        this.loadingIndicator = undefined;
        console.log('pa 5a');
        this.performAction(this._images[0]);
        console.log('pa 5');
        break;

      case 'entry-docs-add':
        this.key = 'ordini';
        this.url = MySettings.environment.GetElencoOrdini;
        //add filter for last orders
        //magage filter date
        //get filterStatoTime.value
        this.req = {
          idnegozio: MySettings.environment.idNegozio,
          GUID: this._auth.getGUID(),
          DomainId: this._auth.getDomain(),
          ProfileId: this._auth.getProfile(),
          IdStatoAvanzamentoOrdine: 15
        };

        for (let a = 0; a < MySettings.environment.TimeValues.length; a = a + 1) {
          if (MySettings.environment.TimeValues[a].viewvalue == this.filterStatoTime.value) {
            if (!(MySettings.environment.TimeValues[a].value == 0)) {
              this.req.DataMax = new Date(Date.now());
              this.req.DataMin = new Date(Date.now() - (MySettings.environment.TimeValues[a].value * 30 * 24 * 60 * 60 * 1000));
            }
            else {
              this.req.DataMax = new Date(Date.now());
              this.req.DataMin = new Date(Date.now() - (24 * 30 * 12 * 60 * 60 * 1000));
            }
            this.req.DataMax.setHours(23);
            this.req.DataMax.setMinutes(59);
            this.req.DataMin.setHours(0);
            this.req.DataMin.setMinutes(0);

            this.req.DataMax = moment.utc({ year: this.req.DataMax.getFullYear(), month: this.req.DataMax.getMonth(), day: this.req.DataMax.getDate(), hour: this.req.DataMax.getHours(), minute: this.req.DataMax.getMinutes(), second: this.req.DataMax.getSeconds(), millisecond: this.req.DataMax.getMilliseconds() }).locale("it");
            this.req.DataMin = moment.utc({ year: this.req.DataMin.getFullYear(), month: this.req.DataMin.getMonth(), day: this.req.DataMin.getDate(), hour: this.req.DataMin.getHours(), minute: this.req.DataMin.getMinutes(), second: this.req.DataMin.getSeconds(), millisecond: this.req.DataMin.getMilliseconds() }).locale("it");
          }
        }

        this.back = "action/entry-docs";
        this.backCaption = "Torna alle bolle";
        this.caption = "Bolle di entrata";
        this.subtitle = "Seleziona un ordine";
        this.header = true;
        this.filters = true;
        this.newOrder = false;
        this.clickable = true;
        this.selectable = false;

        for (let i of this._images)
          i.visibile = false;

        for (let i of this._images) {
          i.caption = i.caption.replace("l'ordine", "la bolla");
          i.caption = i.caption.replace("ordine", "bolla");
          i.caption = i.caption.replace("un nuovo", "una nuova");
          i.caption = i.caption.replace("il", "la");
          i.caption = i.caption.replace("zionato", "zionata");
        }
        break;

      case 'entry-docs-detail':
        this.key = 'dettaglioOrdine';
        this.url = MySettings.environment.GetDettaglioOrdine;
        this.req = {
          idnegozio: MySettings.environment.idNegozio,
          GUID: this._auth.getGUID(),
          DomainId: this._auth.getDomain(),
          ProfileId: this._auth.getProfile(),
          iddocumento: this.mainId
        };

        this.back = "action/entry-docs-add";
        this.backCaption = "Torna alla selezione ordini";
        this.caption = "Bolle di entrata";
        this.header = true;
        this.newOrder = false;
        this.clickable = false;
        this.selectable = false;

        this._images[0].visibile = this._images[1].visibile = this._images[2].visibile = this._images[3].visibile = this._images[5].visibile = false;
        this._images[4].enabled = true;
        this._images[2].enabled = false;

        this.infoAggiuntive = true;

        let indice = 0;
        for (let i of this._images) {
          if (indice++ < 2) continue;
          i.caption = i.caption.replace("l'ordine", "la bolla");
          i.caption = i.caption.replace("ordine", "bolla");
          i.caption = i.caption.replace("un nuovo", "una nuova");
          i.caption = i.caption.replace("il", "la");
          i.caption = i.caption.replace("zionato", "zionata");
        }
        break;

      case 'exit-docs-entry':
        //if (this.isAdmin == true)
        this.callDocsEntry();
        break;

      case 'exit-docs':
        this.key = 'ordini';
        this.url = MySettings.environment.GetElencoBolleUscita;
        this.req = {
          idnegozio: MySettings.environment.idNegozio,
          GUID: this._auth.getGUID(),
          DomainId: this._auth.getDomain(),
          ProfileId: this._auth.getProfile()
        };

        for (let a = 0; a < MySettings.environment.TimeValues.length; a = a + 1) {
          if (MySettings.environment.TimeValues[a].viewvalue == this.filterStatoTime.value) {
            if (!(MySettings.environment.TimeValues[a].value == 0)) {
              this.req.DataMax = new Date(Date.now());
              this.req.DataMin = new Date(Date.now() - (MySettings.environment.TimeValues[a].value * 30 * 24 * 60 * 60 * 1000));
            }
            else {
              this.req.DataMax = new Date(Date.now());
              this.req.DataMin = new Date(Date.now() - (24 * 30 * 12 * 60 * 60 * 1000));
            }
            this.req.DataMax.setHours(23);
            this.req.DataMax.setMinutes(59);
            this.req.DataMin.setHours(0);
            this.req.DataMin.setMinutes(0);

            this.req.DataMax = moment.utc({ year: this.req.DataMax.getFullYear(), month: this.req.DataMax.getMonth(), day: this.req.DataMax.getDate(), hour: this.req.DataMax.getHours(), minute: this.req.DataMax.getMinutes(), second: this.req.DataMax.getSeconds(), millisecond: this.req.DataMax.getMilliseconds() }).locale("it");
            this.req.DataMin = moment.utc({ year: this.req.DataMin.getFullYear(), month: this.req.DataMin.getMonth(), day: this.req.DataMin.getDate(), hour: this.req.DataMin.getHours(), minute: this.req.DataMin.getMinutes(), second: this.req.DataMin.getSeconds(), millisecond: this.req.DataMin.getMilliseconds() }).locale("it");
          }
        }

        this.back = "";
        this.backCaption = "Dashboard";
        this.caption = "USCITE";
        this.subtitle = undefined;
        this.header = true;
        this.newOrder = false;
        this.clickable = false;
        this.selectable = true;

        this._images[0].visibile = true;
        this._images[0].enabled = true;
        this._tableService.rows = [];

        for (let i of this._images) {
          i.caption = i.caption.replace("l'ordine", "la bolla");
          i.caption = i.caption.replace("ordine", "bolla");
          i.caption = i.caption.replace("un nuovo", "una nuova");
          i.caption = i.caption.replace("il", "la");
          i.caption = i.caption.replace("zionato", "zionata");
        }
        break;

      case 'exit-docs-add':
        this.key = 'dettaglioOrdine';
        this.back = "action/exit-docs-entry";
        this.backCaption = "Torna indietro";
        this.caption = "Scarichi";
        this.subtitle = "Seleziona uno o più prodotti";
        this.header = true;
        this.newOrder = false;
        this.clickable = false;
        this.selectable = true;

        this._images[1].visibile = this._images[3].visibile = this._images[5].visibile = false;

        for (let i of this._images) {
          i.caption = i.caption.replace("l'ordine", "la bolla");
          i.caption = i.caption.replace("ordine", "bolla");
          i.caption = i.caption.replace("un nuovo", "una nuova");
          i.caption = i.caption.replace("il", "la");
          i.caption = i.caption.replace("zionato", "zionata");
        }

        this._images[0].enabled = true;
        this._images[0].caption = "Seleziona un articolo";
        this._images[2].caption = "Cancella un articolo";

        for (let i of this._images) {
          i.caption = i.caption.replace("l'ordine", "la bolla");
          i.caption = i.caption.replace("ordine", "bolla");
          i.caption = i.caption.replace("un nuovo", "una nuova");
          i.caption = i.caption.replace("il", "la");
          i.caption = i.caption.replace("zionato", "zionata");
        }

        this.columns = [];
        this.columns.push({ name: "Id", prop: "Id", flexGrow: 1, type: 'Int32' });
        this.columns.push({ name: "Articolo", prop: "Articolo", flexGrow: 4 });
        this.columns.push({ name: "Confezioni", prop: "Confezioni", flexGrow: 2, type: 'Int32' });

        this.temp = [];
        this.rows = [];
        this.req = this.url = undefined;
        this.exception = true;
        this.loadingIndicator = undefined;

        this._images[1].visibile = !true;
        this._images[2].visibile = !true;
        this._images[3].visibile = !true;
        this._images[4].visibile = true;

        break;

      case 'exit-docs-detail':
        this.key = 'dettaglioOrdine';
        this.url = MySettings.environment.GetDettaglioOrdine;
        this.req = {
          idnegozio: MySettings.environment.idNegozio,
          GUID: this._auth.getGUID(),
          DomainId: this._auth.getDomain(),
          ProfileId: this._auth.getProfile(),
          iddocumento: this.mainId
        };

        this.back = "action/exit-docs-add";
        this.backCaption = "Torna alla selezione ordini";
        this.caption = "Bolle di uscita";
        this.header = true;
        this.newOrder = false;
        this.clickable = false;
        this.selectable = false;

        this._images[0].visibile = this._images[1].visibile = this._images[2].visibile = this._images[3].visibile = this._images[5].visibile = false;
        this._images[4].enabled = true;

        indice = 0;
        for (let i of this._images) {
          if (indice++ < 2) continue;
          i.caption = i.caption.replace("l'ordine", "la bolla");
          i.caption = i.caption.replace("ordine", "bolla");
          i.caption = i.caption.replace("un nuovo", "una nuova");
          i.caption = i.caption.replace("il", "la");
          i.caption = i.caption.replace("zionato", "zionata");
        }
        break;

      case 'inventory-add':
        this.key = 'articoliInventario';
        this.url = MySettings.environment.GetInventarioItems;
        this.req = {};
        if (!(this.myUser == null) && !(this.myUser['inventario'] == null) && (this.myUser['inventario'] == 'true')) {
          this.req = {
            idnegozio: MySettings.environment.idNegozio,
            GUID: this._auth.getGUID(),
            DomainId: this._auth.getDomain(),
            ProfileId: this._auth.getProfile(),
            IdcatGiacenza: "6",
            DataInventario: "2018-05-07T12:00:01",
            pagingParameterModel: {
              enabled: false,
              pageNumber: 1,
              pageSize: 5
            }
          }
        }
        this.back = "action/inventory";
        this.backCaption = "Inventario";
        this.caption = "Nuovo Inventario";
        this.subtitle = undefined;
        this.header = true;
        this.newOrder = false;
        this.clickable = false;
        this.selectable = true;

        this._images[0].visibile = false;
        this._images[5].enabled = false;
        this._images[3].visibile = this._images[4].visibile = this._images[1].visibile = false;

        indice = 0;
        for (let i of this._images) {
          i.caption = i.caption.replace("l'ordine", "l'inventario");
          i.caption = i.caption.replace("ordine", "inventario");
        }
        break;

      case 'orders':
        this.key = 'ordini';
        this.url = MySettings.environment.GetElencoOrdini;
        this.req = {
          idnegozio: MySettings.environment.idNegozio,
          GUID: this._auth.getGUID(),
          DomainId: this._auth.getDomain(),
          ProfileId: this._auth.getProfile()
        };

        for (let a = 0; a < MySettings.environment.TimeValues.length; a = a + 1) {
          if (MySettings.environment.TimeValues[a].viewvalue == this.filterStatoTime.value) {
            if (!(MySettings.environment.TimeValues[a].value == 0)) {
              this.req.DataMax = new Date(Date.now());
              this.req.DataMin = new Date(Date.now() - (MySettings.environment.TimeValues[a].value * 30 * 24 * 60 * 60 * 1000));
            }
            else {
              this.req.DataMax = new Date(Date.now());
              this.req.DataMin = new Date(Date.now() - (24 * 30 * 12 * 60 * 60 * 1000));
            }
            this.req.DataMax.setHours(23);
            this.req.DataMax.setMinutes(59);
            this.req.DataMin.setHours(0);
            this.req.DataMin.setMinutes(0);

            this.req.DataMax = moment.utc({ year: this.req.DataMax.getFullYear(), month: this.req.DataMax.getMonth(), day: this.req.DataMax.getDate(), hour: this.req.DataMax.getHours(), minute: this.req.DataMax.getMinutes(), second: this.req.DataMax.getSeconds(), millisecond: this.req.DataMax.getMilliseconds() }).locale("it");
            this.req.DataMin = moment.utc({ year: this.req.DataMin.getFullYear(), month: this.req.DataMin.getMonth(), day: this.req.DataMin.getDate(), hour: this.req.DataMin.getHours(), minute: this.req.DataMin.getMinutes(), second: this.req.DataMin.getSeconds(), millisecond: this.req.DataMin.getMilliseconds() }).locale("it");
          }
        }
        console.log('filterStatoTime value %o req %o', this.filterStatoTime.value, this.req);

        this.back = "";
        this.backCaption = "Dashboard";
        this.caption = "Lista ordini";
        this.subtitle = undefined;
        this.header = true;
        this.newOrder = false;
        this.clickable = false;
        this.selectable = true;
        this.filters = true;

        sessionStorage.removeItem('dup');
        sessionStorage.removeItem('dupInfo');

        this._images[0].enabled = true;
        this._images[5].enabled = false;
        this._images[3].visibile = this._images[4].visibile = this._images[1].visibile = false;
        this._images[6].visibile = true;
        this._images[6].enabled = false;

        this._http.post(
          MySettings.environment.GetReportsData,
          {
            idnegozio: MySettings.environment.idNegozio,
            GUID: this._auth.getGUID(),
            DomainId: this._auth.getDomain(),
            ProfileId: this._auth.getProfile(),
            RCode: "CHECK_SOTTOSCORTA",
            Params: []
          }
        )
          .subscribe((res) => {
            this._images[7].visibile = this._images[7].enabled = (res['esito'] == true && res['Queries'] != undefined && res['Queries'].length > 0);
            if (this._images[7].enabled) {
              this.sottoscortaJ = res['Queries'][0];
            } else
              this.sottoscortaJ = undefined;
          });

        break;

      case 'orders-sottoscorta':
        this.back = "action/orders";
        this.backCaption = "Lista ordini";
        this.caption = "NUOVO ORDINE SOTTOSCORTA";
        this.subtitle = "SELEZIONA UNO TRA I LABORATORI/FORNITORI";
        this.header = true;
        this.newOrder = false;
        this.clickable = false;
        this.selectable = true;
        this.filters = false;
        this.exception = true;

        this._images[0].enabled = false;
        this._images[5].enabled = false;
        this._images[3].visibile = this._images[4].visibile = this._images[1].visibile = false;
        this._images[6].visibile = true;
        this._images[6].enabled = false;

        if (localStorage.getItem('sottoscorta') == undefined) this._router.navigate(['/pm/store/action/orders']);

        this.rows = [];
        var forn = [];
        var ss = JSON.parse(localStorage.getItem('sottoscorta'));
        for (var t of ss['Table'])
          if (forn.indexOf(t['IDFORNITORE']) === -1)
            forn.push({ idf: t['IDFORNITORE'], tot: 1, cat: t['CATEGORIAFORNITORE'], for: t['FORNITORE'], idcf: t['IDCATEGORIA_FORNITORE'] });
          else
            for (let x of forn)
              if (x.idf == t['IDFORNITORE']) {
                x.tot++;
                break;
              }

        this.url = undefined;
        this.clickable = true;
        this.columns = [];
        this.columns.push({ name: "", prop: "Id", flexGrow: 1, type: 'Int32' });
        this.columns.push({ name: "CATEGORIA", prop: "Categoria", flexGrow: 1, type: 'String' });
        this.columns.push({ name: "DENOMINAZIONE", prop: "Fornitore", flexGrow: 1, type: 'String' });
        this.columns.push({ name: "PRODOTTI SOTTO SCORTA", prop: "Tot", flexGrow: 1, type: 'Int32' });

        this.loadingIndicator = this.tipoBolle = false;

        var id1 = 1;
        for (let x of forn) {
          this.rows.push(
            {
              Id: id1++,
              Categoria: x.cat,
              Fornitore: x.for,
              Tot: x.tot,
              IdF: x.idf,
              IdCF: x.idcf
            }
          );
        }

        break;

      case 'alerts':
        this.key = 'alerts';
        this.url = MySettings.environment.GetElencoAlerts;
        this.req = {
          idnegozio: MySettings.environment.idNegozio,
          GUID: this._auth.getGUID(),
          DomainId: this._auth.getDomain(),
          ProfileId: this._auth.getProfile(),
          ALERT_TYPE: "DENTAL"
        };

        this.insideAlerts = false;
        this.back = "";
        this.backCaption = "Dashboard";
        this.caption = "Lista avvisi";
        this.subtitle = '';//"v." + this.DPversion;
        this.header = false;
        this.newOrder = false;
        this.clickable = false;
        this.selectable = true;

        this._images[0].enabled = false;
        this._images[5].enabled = false;
        this._images[3].visibile = this._images[4].visibile = this._images[1].visibile = false;

        break;

      case 'bolla-entrata-detail':
        this.key = 'inventario';
        this.url = MySettings.environment.GetDettaglioBolleEntrata;
        this.req = {
          idnegozio: MySettings.environment.idNegozio,
          GUID: this._auth.getGUID(),
          DomainId: this._auth.getDomain(),
          ProfileId: this._auth.getProfile(),
          iddocumento: this.mainId
        };
        this.header = true;
        this.newOrder = false;
        this.clickable = false;
        this.selectable = true;

        id = this.mainId;
        this.back = "action/entry-docs";
        this.backCaption = "Lista bolle di entrata";
        this.caption = "Dettaglio Bolla di entrata";
        this.subtitle = "Nr. " + id;

        this._images[5].enabled = true;

        let indexi = 0;
        for (let i of this._images) {
          i.visibile = false;
          i.caption = i.caption.replace("l'ordine", "la bolla");
          i.caption = i.caption.replace("ordine", "bolla");
        }

        this._images[5].visibile = false;

        this.columns = [];
        this.key = "dettaglioOrdine";

        if (sessionStorage.getItem('dettaglioOrdine') != undefined) {
          this.io = JSON.parse(sessionStorage.getItem('dettaglioOrdine'));
          this.infoOrdine = true;
          this.detB = true;
        }
        else { this.infoOrdine = false; this.detB = false; }
        break;

      case 'orderDetail':
        this.key = 'dettaglioOrdine';
        this.url = MySettings.environment.GetDettaglioOrdine;
        this.req = {
          idnegozio: MySettings.environment.idNegozio,
          GUID: this._auth.getGUID(),
          DomainId: this._auth.getDomain(),
          ProfileId: this._auth.getProfile(),
          iddocumento: id
        };
        this.header = true;
        this.newOrder = false;
        this.clickable = false;
        this.selectable = true;

        this.back = "action/orders";
        this.backCaption = "Lista ordini";
        this.caption = "Dettaglio Ordine";
        this.subtitle = "Nr. " + id + " - INSERITO IL ";

        this._images[0].visibile = true;
        this._images[1].visibile = false;
        this._images[5].visibile = false;
        this._images[0].enabled = true;

        if (sessionStorage.getItem('dettaglioOrdine') != undefined) {
          this.io = JSON.parse(sessionStorage.getItem('dettaglioOrdine'));
          this.infoOrdine = true;
          ////console.log('dettaglioOrdine %o',this.io);
          //arghhhhhhhhhhhhhhhh ;)
          if (this._auth.getDomain() == '1')
            localStorage.setItem('filialeId_', this.io['Idmitt']);
          var d = moment((new DatePipe(this.io["DataIns"]))["locale"].substring(0, 10)).format('DD/MM/YYYY');
          this.dataInserimento = d;
          this.subtitle += this.dataInserimento;
        }
        else this.infoOrdine = false;

        let index = 0;
        for (let i of this._images) {
          if (index++ > 2) break;
          i.caption = i.caption.replace("l'ordine", "il prodotto");
          i.caption = i.caption.replace("ordine", "prodotto");
        }
        break;

      case 'flDetail':
        this.key = 'dettaglioOrdine';
        this.header = true;
        this.newOrder = false;

        this.back = "action/orders-add";
        this.backCaption = "Nuovo ordine";
        this.caption = "Nuovo ordine";

        this.columns = [];
        this.columns.push({ name: "Id", prop: "Id", flexGrow: 1, type: 'Int32' });

        this.columns.push({ name: "Classe", prop: "CustomClasse", flexGrow: 4 });
        this.columns.push({ name: "Famiglia", prop: "CustomFamiglia", flexGrow: 4 });
        this.columns.push({ name: "Sottofamiglia", prop: "CustomSottofamiglia", flexGrow: 4 });
        this.columns.push({ name: "Codice", prop: "CustomCodice", flexGrow: 4 });
        this.columns.push({ name: "Articolo", prop: "Articolo", flexGrow: 4 });

        this.columns.push({ name: "Costo Lordo", prop: "CostoLordo", flexGrow: 2, type: 'Currency', format: '#0.00' });
        this.columns.push({ name: "Imponibile scontato", prop: "CostoNettoScontato", flexGrow: 2, type: 'Currency', format: '#0.00' });

        this.columns.push({ name: "Costo per confezione", prop: "CostoPerConfezione", flexGrow: 2, type: 'Currency', format: '#0.00' });

        this.columns.push({ name: "Confezioni", prop: "Confezioni", flexGrow: 2, type: 'Int32' });
        this.columns.push({ name: "Totale", prop: "Totale", flexGrow: 3, type: 'Currency', format: '#0.00' });

        this.temp = [];
        this.rows = [];

        if (localStorage.getItem('sottoscorta') != undefined) {
          this.idcf = localStorage.getItem('sottoscortaCF');
          this.idf = this.mainId;
          var art = JSON.parse(localStorage.getItem('sottoscorta'));
          localStorage.removeItem('sottoscortaCF');
          this.caption += " - (ARTICOLI SOTTO SCORTA)";
          var wr = [];
          console.log('getArticoliFornitore 1');
          this._http.post(
            MySettings.environment.GetArticoliFornitori,
            {
              idnegozio: MySettings.environment.idNegozio,
              GUID: this._auth.getGUID(),
              DomainId: this._auth.getDomain(),
              ProfileId: this._auth.getProfile(),
              IdCategoriaFornitore: this.idcf,
              DomainContext: MySettings.environment.MyContext,
              Idfornitore: this.mainId
            }
          )
            .subscribe((res) => {
              console.log("ROWS GetArticoliFornitori B %o", res);
              for (let a of art['Table'])
                for (let prod of res['articoliFornitore']) {
                  if (prod['Codice'] == a['CODICE']) {
                    var row = prod;
                    wr.push(
                      {
                        Id: this.rows.length + 1,
                        Articolo: row.Descrizione,
                        CostoPerConfezione: row.CostoLordoConfezione,
                        Confezioni: a['QTADAORDINARE'],
                        Totale: row.CostoLordoConfezione,
                        Codice: row.BarCodice,

                        CostoLordo: row.CostoLordo,
                        CostoNettoScontato: row.CostoNettoScontato,

                        Idarticolo: row.Idarticolo,
                        IdUdm: row.idUDM,
                        ScalaUDM: row.scalaUDM,
                        Idprezzo: row.Idprezzo,
                        QtaContata: row.QtaContata,
                        //QtaAttesa:row.QtaAttesa,
                        IdcatGiacenza: row.IdcatGiacenza,

                        CustomCodice: row.BarCode,
                        CustomClasse: row.CategoriaSuperParent,
                        CustomFamiglia: row.CategoriaParent,
                        CustomSottofamiglia: row.Categoria,
                        CustomArticolo: row.DescrizioneEstesa,

                        BarCodeAggiunta: row.Barcode,
                        QtaAggiunta: 0
                      }
                    );
                    break;
                  }
                }

              this.rows = [...wr];
              this._images[3].enabled = true;
              this._images[4].enabled = true;
            });
        }

        this._images[0].enabled = true;

        this.click = 1;
        this.exception = true;
        this.loadingIndicator = false;
        this._images[0].caption = 'Aggiungi prodotto';

        let ind = 0;
        for (let i of this._images) {
          if (ind++ >= 3) break;
          i.caption = i.caption.replace("l'ordine", "il prodotto");
          i.caption = i.caption.replace("ordine", "prodotto");
        }

        this._images[2].visibile = false;

        if (sessionStorage.getItem('dup') != undefined) {
          console.log('DUPLICA ORDINE');
          //this._loaderRogges.show();
          var dup = +sessionStorage.getItem('dup');
          sessionStorage.removeItem('dup');
          var key = 'dettaglioOrdine';
          var url = MySettings.environment.GetDettaglioOrdine;
          var req = {
            idnegozio: MySettings.environment.idNegozio,
            GUID: this._auth.getGUID(),
            DomainId: this._auth.getDomain(),
            ProfileId: this._auth.getProfile(),
            iddocumento: dup
          };
          this.loadingIndicator = true;
          this._http.post(
            url,
            req
          )
            .subscribe((res) => {

              var rows = res[key];
              console.log("ordine originale %o ROWS %o", rows.length, rows);
              var totRighe = rows.length;
              var ind = 0;
              var indice = 0;
              var s = [];
              var tot = rows.length;
              var resext;
              this._loaderRogges.show();
              console.log('getArticoliFornitore 2');
              this._http.post(
                MySettings.environment.GetArticoliFornitori,
                {
                  idnegozio: MySettings.environment.idNegozio,
                  GUID: this._auth.getGUID(),
                  DomainId: this._auth.getDomain(),
                  ProfileId: this._auth.getProfile(),
                  IdCategoriaFornitore: localStorage.getItem('IdcategoriaFornitore'),
                  DomainContext: MySettings.environment.MyContext,
                  Idfornitore: this.mainId
                }
              )
                .subscribe((res) => {
                  console.log("ROWS GetArticoliFornitori A %o", res);
                  this.loadingIndicator = false;
                  resext = res;

                  if (resext == undefined) return;

                  var newr = [];
                  var prodErr = [];
                  //newr = rows;

                  for (let x of rows) {

                    // check duplicati
                    var find = false;
                    for (let prod of resext['articoliFornitore']) {
                      //console.log('FIRST PROD %o x %o', prod,x);
                      //if (prod['BarCode'] == x['BarCode']) {
                      if (prod['Idarticolo'] == x['Idarticolo']) {

                        //console.log('SECOND PROD %o x %o rows[indice] %o indice %o rows %o', prod, x, rows[indice], indice, rows);
                        //console.log(indtest++ + ") " + prod['BarCode'] + " == " + x['BarCode'] + " = " + (prod['BarCode'] == x['BarCode']));
                        //if (!find) {
                        find = true;
                        s.push({ code: prod['Idarticolo'], fromFornitore: prod });

                        x['Id'] = ++ind;
                        x['CustomCodice'] = rows[indice]['BarCode'];
                        x['Codice'] = rows[indice]['BarCode'];
                        x['CustomFamiglia'] = rows[indice]['CategoriaParent'];
                        x['CustomSottofamiglia'] = rows[indice]['Categoria'];
                        x['CustomClasse'] = rows[indice]['CategoriaSuperParent'];
                        x['Confezioni'] = rows[indice]['NumConfezioni'];
                        x['Articolo'] = rows[indice]['Articolo'];
                        //x['CostoPerConfezione'] = rows[indice]['CostoLordoConfezione'];
                        //x['CostoNettoScontato'] = rows[indice]['CostoNettoScontato'];
                        //x['CostoLordo'] = rows[indice]['CostoLordo'];
                        //x['Totale'] = (+rows[indice]['NumConfezioni'] * +rows[indice]['CostoLordoConfezione']);
                        x['Idprezzo'] = prod['Idprezzo'];
                        x['CostoPerConfezione'] = prod['CostoLordoConfezione'];
                        x['CostoNettoScontato'] = prod['CostoNettoConfezione'];
                        x['CostoLordo'] = (+rows[indice]['NumConfezioni'] * +prod['CostoLordoConfezione']);
                        x['Totale'] = (+rows[indice]['NumConfezioni'] * +prod['CostoNettoConfezione']);

                        x['ScalaUDM'] = (+rows[indice]['NumConfezioni'] * +rows[indice]['ScalaUdm']);
                        x['IdUdm'] = rows[indice]['Idudm'];
                        //indice++;
                        newr.push(x);
                        //console.log('SECOND PROD %o %o', newr.length, totRighe);
                        if (newr.length == totRighe)
                          break;
                        //}
                        //indice++;
                      }

                      //if (newr.length == )
                    }

                    if (!find)
                      prodErr.push(x);

                    indice++;
                  }

                  this.rows = this._tableService.rows = [...newr];// [...res[key]];
                  console.log('E1');
                  console.log('hide 11');
                  this._loaderRogges.hide();

                  if (s.length != rows.length) {
                    console.log('products with errors %o and duplicated %o', prodErr, s);
                    let data = {};
                    data['stato'] = 'open_dialog';
                    data['title'] = "Attenzione, anomalie durante la duplicazione dell'ordine.";
                    if (prodErr.length > 0) {
                      data['desc'] = "I seguenti codici prodotto non sono più disponibili presso il fornitore: </br>"
                      for (let a = 0; a < prodErr.length; a = a + 1) {
                        data['desc'] = data['desc'] + prodErr[a]['Idarticolo'] + '</br>';
                      }
                    }
                    data['options'] = [{ caption: 'Ok', action: 'true' }];
                    let dialogRef2 = this._dialog.open(YesNoDialogComponent, {
                      width: '875px',
                      data: data
                    });
                  }

                  this.ia3 = res['resultRiferimentoBolla'] == undefined || res['resultRiferimentoBolla'].Riferimento == undefined ? "n/d" : res['resultRiferimentoBolla'].Riferimento;
                  this.ia4 = res['resultNotaDocumento'] == undefined || res['resultNotaDocumento'].NotaDocumento == undefined ? 'Nessuna nota inserita' : res['resultNotaDocumento'].NotaDocumento;
                  this._images[0].enabled = this._images[4].enabled = this._images[3].enabled = true;
                });
            });
        }
        break;

      case 'orders-add-ad':
        this._router.navigate(['/pm/store/action/orders-add']);
        break;

      case 'orders-add':
        this.key = 'orders-add';
        console.log('------------SEARCHING FOR BUG');
        console.log('------------sessionStorage.getItem(dup) %o', sessionStorage.getItem('dup'));
        console.log('------------sessionStorage.getItem(isdup) %o', sessionStorage.getItem('isdup'));
        console.log('------------localStorage.getItem(filialeId) %o', localStorage.getItem('filialeId'));
        this.url = undefined;
        this.req = undefined;
        this.header = false;
        this.newOrder = true;
        this.clickable = false;
        this.selectable = false;

        this.back = "action/orders";
        this.backCaption = "Elenco ordini";

        for (let i of this._images) i.enabled = false;

        this.caption = "Nuovo ordine" + (sessionStorage.getItem('dup') != undefined ? ' duplicato - Seleziona il destinatario' : '');
        if (+this._auth.getDomain() == 1) {
          this.caption += "";
          //if (!((+this._auth.getDomain() != 1 && localStorage.getItem('filialeId') == undefined) || (localStorage.getItem('filialeId') !== undefined && localStorage.getItem('filialeId') !== null)))
          if (sessionStorage.getItem('isdup') != undefined)
            this.newOrder = false;
        }
        this.subtitle = undefined;
        if ((+this._auth.getDomain() != 1 && localStorage.getItem('filialeId') == undefined) || (localStorage.getItem('filialeId') !== undefined && localStorage.getItem('filialeId') !== null)) {
          console.log('NO search for filiale');
          this._tiles = this.loadTiles();
          localStorage.setItem('filialeId_', localStorage.getItem('filialeId'));
          localStorage.removeItem('filialeId');
          this.key = "filiali";

          if (+this._auth.getDomain() == 1) {
            this.caption = "NUOVO ORDINE" + (sessionStorage.getItem('dup') != undefined ? ' duplicato - Seleziona destinatario' : '');
            this.subtitle = localStorage.getItem('fName')
          }
        } else {
          console.log('search for filiale');
          this.subtitle = "Seleziona la filiale da utilizzare";
          this.url = MySettings.environment.GetElencoFiliali;
          this.req = {
            idnegozio: MySettings.environment.idNegozio,
            GUID: this._auth.getGUID(),
            DomainId: this._auth.getDomain(),
            ProfileId: this._auth.getProfile(),
            DomainContext: MySettings.environment.MyContext
          };
          this.key = 'filiali';
          this.clickable = true;
        }
        break;

      case 'orders-add-fornitore':
        this.myFId = this.click;
        localStorage.setItem('myFId', this.click);
        this.key = 'fornitori';
        this.back = "action/orders";
        this.backCaption = "Elenco ordini";
        this.caption = "Nuovo ordine";
        this.subtitle = "Selezione fornitori";
        if (+this._auth.getDomain() == 1) {
          this.subtitle += " - " + localStorage.getItem('fName')
        }
        this._tableService.rows = [];
        this.clickable = true;
        this.selectable = false;

        this.url = MySettings.environment.GetElencoFornitori;
        this.req = {
          idnegozio: MySettings.environment.idNegozio,
          GUID: this._auth.getGUID(),
          DomainId: this._auth.getDomain(),
          ProfileId: this._auth.getProfile(),
          IdCategoriaFornitore: this.click,
          DomainContext: MySettings.environment.MyContext
        };
        localStorage.setItem('fId', this.click);
        this.header = false;
        this.newOrder = true;
        break;

      case 'orders-add-laboratorio':
        this.key = 'fornitori';
        this.back = "action/orders";
        this.backCaption = "Elenco ordini";
        this.caption = "Nuovo ordine";
        this.subtitle = "Selezione laboratori";
        this._tableService.rows = [];
        this.clickable = true;
        this.myFId = this.click;
        localStorage.setItem('myFId', this.click);
        localStorage.setItem('myFName', localStorage.getItem('fName'));

        console.log('ALFA localstorage.geiItem(fname) %o idAction %o', localStorage.getItem('fName'), this.myFId);

        this.url = MySettings.environment.GetElencoFornitori;
        this.req = {
          idnegozio: MySettings.environment.idNegozio,
          GUID: this._auth.getGUID(),
          DomainId: this._auth.getDomain(),
          ProfileId: this._auth.getProfile(),
          IdCategoriaFornitore: this.click,
          CategoriaFornitore: "fornitore",
          DomainContext: MySettings.environment.MyContext
        };
        localStorage.setItem('fId', this.click);
        this.header = false;
        this.newOrder = true;
        break;
    }

    if (this.url != undefined)
      this._loaderRogges.show();
    console.log('check orders %o', this._auth.getProfile());
    //if (!((this._auth.getProfile() == '1' || this._auth.getProfile() == '6') && this.entryPoint == 'orders')) {
    if (!(this.entryPoint == 'orders' || this.entryPoint == 'entry-docs' || this.entryPoint == 'exit-docs-entry')) {
      this.fetch((data) => {
        //this._loaderRogges.hide();
        //console.log('FILTER DATA %o entryPoint %o',data,this.entryPoint);
        this.temp = [];

        if (this.entryPoint == 'inventoryDetail') {
          let ind2 = 0;
          this.lengthInv = 0;
          for (let r of data) {
            this.lengthInv++;
            this.temp.push({
              Id: ++ind2,
              Articolo: r.Articolo,
              QtaContata: r.QtaContata,
              QtaAttesa: r.QtaAttesa,
              IdcatGiacenza: r.IdCatGiacenza,
              Idarticolo: r.IdArticolo,
              BarCodeAggiunta: r.Barcode,
              QtaAggiunta: 0
            });
          }
        } else if (this.entryPoint == 'orderDetail' || this.entryPoint == 'entry-docs-detail' || this.entryPoint == 'exit-docs-detail' || this.entryPoint == 'exit-docs-add') {
          var ind = 0;
          for (let r of data) {
            this.temp.push({
              Id: ++ind,
              Articolo: r.Articolo,
              CostoPerConfezione: r.CostoLordoConfezione,
              Confezioni: r.Qta,
              CostoNettoScontato: r.CostoNettoScontato,
              CostoLordo: r.CostoLordo,
              Totale: r.CostoLordoConfezione * r.Qta,
              Idprezzo: r.Idprezzo,
              IdUdm: r.Idudm,
              Idarticolo: r.Idarticolo,
              ScalaUDM: r.ScalaUdm,
              Iddest: r.Iddest,
              Idvend: r.Idvend,
              MasterSlave: r.MasterSlave,
              CustomClasse: r.CategoriaSuperParent,
              CustomFamiglia: r.CategoriaParent,
              CustomSottofamiglia: r.Categoria,
              CustomArticolo: r.DescrizioneEstesa,
              CustomCodice: r.BarCode
            });

            if (this.entryPoint == 'orderDetail') this.tot += r.CostoLordoConfezione * r.Qta;
          }

          this.tot = +(this.tot.toFixed(2));

          if (data.length > 0) {
            this._images[3].enabled = this._images[4].enabled = true;
          }

          if (localStorage.getItem('notbozza')) {
            this._images[2].enabled = false;
            this._images[3].enabled = false;
            this._images[4].enabled = false;
            this._images[0].enabled = false;
            if (this.entryPoint == 'entry-docs-detail')// && (JSON.parse(localStorage.getItem('pz'))['IdstatoAvanzamento'] == 14))
            {
              this._images[4].enabled = true;
            }
          }
          if (this.entryPoint == 'entry-docs-detail')
            this._images[3].enabled = false;
          //console.log(data);

        } else {
          console.log('FILTER STATUS entrypoint %o filterMasterSlave %o', this.entryPoint, this.filterMasterSlave);

          this.temp = [];
          if (this.entryPoint == 'orders') {
            this.backup = data;
            if (this.filterMasterSlave.value == 'Solo padri') {
              for (let i = 0; i < data.length; i++) {
                if (!(data[i]['MasterSlave'] == 'S'))
                  this.temp.push(data[i]);
              }
              console.log('solo padri temp %o', this.temp);
            }
            else
              this.temp = data;
          }
          else {
            for (let i = 0; i < data.length; i++) {
              if (!(data[i]['MasterSlave'] == 'S'))
                this.temp.push(data[i]);
            }
          }

          // this.temp = data;
          console.log('HERE IS %o', this.temp);
          for (let r of data) {
            if (this.listaStati.indexOf(r.StatoAvanzamento) == -1)
              this.listaStati.push(r.StatoAvanzamento);
          }
          //this.temp.push(...data);

          if (this.entryPoint == 'exit-docs-entry-list') {
            //add button as forst columns
            console.log('SONDA UNO');
            let colcopy = this.columns;
            this.columns = [];
            this.columns.push({ name: "Scarico Ordine", prop: "_all", format: "", type: "Int32" });
            for (let a = 0; a < colcopy.length; a = a + 1) {
              if (!(colcopy[a].prop == '_all')) {
                this.columns.push(colcopy[a]);
              }
            }
            console.log('Columns %o', this.columns);
            this.subtitle = "Lista documenti da scaricare";
          }


        }
        var bi = 0;
        if (this.entryPoint == 'bolla-entrata-detail')
          for (let x of this.temp)
            x['NumRiga'] = ++bi;
        this.rows = [...this.temp]; // Refresh the data
        this.loadingIndicator = false;

        //sergiosick
        var tre = 1;
        if (this.rows.length > 0 && this.rows[0]['Id'] == undefined) {
          for (let x of this.rows) x['Id'] = tre++;
          this.rows = [...this.rows];
        }
      });
    }
    else {
      this.loadingIndicator = false;
      this._loaderRogges.hide();
    }
    var tt = this;
    if (this.entryPoint == 'flDetail' && sessionStorage.getItem('isdup') == undefined)
      if (this.entryPoint == 'flDetail' && localStorage.getItem('sottoscortaCF') == undefined) {
        console.log('pa 6a');

        //medici
        this.myFId = localStorage.getItem('myFId');
        console.log('BETA localstorage.geiItem(fname) %o idAction %o', localStorage.getItem('myFName'), this.myFId);
        var codcli = '';
        if (localStorage.getItem('myFName') != null)
          codcli = localStorage.getItem('myFName').substring(0, 3);
        //check codice clinica -> orrendo

        var n = +codcli;
        if (!MySettings.environment['production']) console.log('working debug for GetOperators codcli %o n %o', codcli, n);

        if (isNaN(n)) {
          //get from domainname
          var desc_domain = this._auth.getDescDomain();
          if (desc_domain != null && desc_domain.length > 0)
            codcli = desc_domain.substring(0, 3);
          if (!MySettings.environment['production']) console.log('working debug for GetOperators NEW codcli %o n %o', codcli);
        }

        var payload = {
          isInternalCode: true,
          codice_clinica: codcli
        }

        if (!MySettings.environment['production']) console.log('payload for GetOperators %o', payload);
        this.doctors = [];
        let self = this;
        this._loaderRogges.show();
        this._http.post(MySettings.environment['GetOperators'], payload).subscribe(
          r => {
            this._loaderRogges.hide();
            if (r['esito'] == true) {
              console.log('------------------ DOCTORS %o', r['obj']);
              for (let i = 0; i < r['obj'].length; i++) {
                self.doctors.push({ nome: r['obj'][i].nome });
              }
              //self.doctors.push({ nome: 'Carico Clinica' });
              self.performAction(tt._images[0]);
              console.log('pa 6');
            }
            else {
              alert('Errore nella ricerca dei medici associati alla clinica: ' + r['errCode']);
              //self.doctors.push({ nome: 'Carico Clinica' });
              self.performAction(tt._images[0]);
            }
          },
          error => {
            this._loaderRogges.hide();
            alert('Errore nella ricerca dei medici associati alla clinica: ' + error);
            //self.doctors.push({ nome: 'Carico Clinica' });
            self.performAction(tt._images[0]);
          }
        );
      }

    if (sessionStorage.getItem('isdup') != undefined) sessionStorage.removeItem('isdup')

    if (this.entryPoint != 'orders' && this.entryPoint != 'flDetail' && this.entryPoint != 'orderDetail' && this._auth.getDomain() == "1") {
      this._images[0].enabled = false; // fix algranati
      this._images[1].enabled = false;
      this._images[2].enabled = false;
      this._images[3].enabled = false;
      this._images[4].enabled = false;
    }

    this._images[1].visibile = false;

    for (let i of this._images)
      if (i.visibile == false) {
        i.enabled = false;
        i.visibile = true;
      }
    console.log('_images after callTable %o', this._images);
  }

  changeDoctor($event) {
    console.log('changeDoctor %o', $event);
  }

  clickReport(r) {
    localStorage.setItem('repoInfo', JSON.stringify(r));
    this.clickedTile = r;
    this._router.navigate(['/pm/store/action/reports/' + r['RCode']]);
  }

  chEPP(e) {
    sessionStorage.setItem('elementiPerPagina', "" + e.target.value);
    this.elementiPerPagina = +sessionStorage.getItem('elementiPerPagina');
  }

  checkParam(_p, f, oth = undefined) {
    console.log('checkParam _p %o f %o oth %o jsonHi %o', _p, f, oth, this.hidJSON);
    if (_p != undefined)
      for (let p of _p)
        if (p['value'] == undefined || p['value'] == '' || p.value == 'Invalid Date') {
          this._notifications.error(
            "Errore",
            "Compila tutti i parametri nella schermata per generare o visualizzare il report.",
            MySettings.environment.notificationOptions
          );
          return;
        }

    if (oth != undefined) {
      this.generateReport(_p);
    }
    else {
      /*
      let act = this.getDownloadReport(f);
      console.log('act on chechParams %o', act);
      this._http.post(
        act,
        {
          idnegozio: MySettings.environment.idNegozio,
          GUID: this._auth.getGUID(),
          DomainId: this._auth.getDomain(),
          ProfileId: this._auth.getProfile(),
          RCode: this.mainId,
          Params: _p
        }
      )
        .subscribe((res) => {
          console.log('res after checkParams %o',res);
        });
        */
      f.submit();
    }
  }

  generateReport(params, what = undefined) {
    console.log('generateReport %o %o', params, what);
    if (params == undefined && what == undefined && this._repoParamsBack != undefined) {
      if (this.subtitle.length > 45) this.subtitle = this.subtitle.substring(45);
      this.generateReport(this._repoParamsBack);
    }
    var inizio = true;
    if (params != null) //arghhhhhhhhhhh ;)
    {
      for (let p of params)
        if (p['PType'] == "DateTime") {
          p['value'] = new Date(this.getISODate(p['value']));
          inizio = !inizio;
        }
    }
    if (what != undefined) {
      //console.log(" XXXXXX ", params);
      for (let p of params)
        if (p.PType == 'N' || p.PType == 'Int32' || p.PType == 'Decimal' || p.PType == 'Currency' || p.PType == 'String')
          if (p.Table != null) {
            for (let de of p.Table)
              if (p['value'] == de[p['SubQueryValue']]) {
                p.descrValue = de[p['SubQueryDescr']];
                continue;
              }
          } else {
            p.descrValue = p['SubQueryDescr'];
          }
      this.hidJSON = JSON.stringify(
        {
          idnegozio: MySettings.environment.idNegozio,
          GUID: this._auth.getGUID(),
          DomainId: this._auth.getDomain(),
          ProfileId: this._auth.getProfile(),
          RCode: this.mainId,
          Params: params
        });

      return;
    }

    this.showReportParams = false;
    this.header = !true;
    this._params = [];
    this._reports = [];
    this.caption = "Reports";
    this.loadingIndicator = true;
    this._http.post(
      MySettings.environment.GetReportsData,
      {
        idnegozio: MySettings.environment.idNegozio,
        GUID: this._auth.getGUID(),
        DomainId: this._auth.getDomain(),
        ProfileId: this._auth.getProfile(),
        RCode: this.mainId,
        Params: params
      }
    )
      .subscribe((res) => {
        this.loadingIndicator = false;
        var ar = [];
        this.back = "/action/reports/";
        for (let r of res['Queries']) {
          var rr = r['Mapper']['columns'];
          var cols = Object.keys(rr);
          for (var xc of cols) {
            ar.push({ name: rr[xc].caption, prop: rr[xc].caption, flexGrow: 4 })
          }
          r['MapperX'] = ar;
          ar = [];
        }
        this._repoResp = res;
      });
  }

  getISODate(data) {
    //var x = moment.utc(data, moment.ISO_8601).toDate();
    return moment(data).format('YYYY-MM-DD');
  }

  clickBollaUscita(u) {
    console.log('CLICK BOLLA USCITA %o entrypoint %o filterStatoTime %o', u, this.entryPoint, this.filterStatoTime);

    this.infoAggiuntive = false;
    this.rows = [];
    this.columns = [];
    this.url = undefined;

    var idTipoDocumento = u.IdTipDoc;
    this.idTipoDocumentoUscita = u.IdTipDoc;
    sessionStorage.setItem('uscite_td', idTipoDocumento);
    sessionStorage.setItem('uscite', JSON.stringify(u));
    this.atterra = u;
    this.url = MySettings.environment.GetElencoBolleUscita;
    this.req = {
      idnegozio: MySettings.environment.idNegozio,
      GUID: this._auth.getGUID(),
      DomainId: this._auth.getDomain(),
      ProfileId: this._auth.getProfile(),
      IdTipologiaDocumento: idTipoDocumento
    };

    for (let a = 0; a < MySettings.environment.TimeValues.length; a = a + 1) {
      if (MySettings.environment.TimeValues[a].viewvalue == this.filterStatoTime.value) {
        if (!(MySettings.environment.TimeValues[a].value == 0)) {
          this.req.DataMax = new Date(Date.now());
          this.req.DataMin = new Date(Date.now() - (MySettings.environment.TimeValues[a].value * 30 * 24 * 60 * 60 * 1000));
        }
        else {
          this.req.DataMax = new Date(Date.now());
          this.req.DataMin = new Date(Date.now() - (24 * 30 * 24 * 60 * 60 * 1000));
        }
        this.req.DataMax.setHours(23);
        this.req.DataMax.setMinutes(59);
        this.req.DataMin.setHours(0);
        this.req.DataMin.setMinutes(0);

        this.req.DataMax = moment.utc({ year: this.req.DataMax.getFullYear(), month: this.req.DataMax.getMonth(), day: this.req.DataMax.getDate(), hour: this.req.DataMax.getHours(), minute: this.req.DataMax.getMinutes(), second: this.req.DataMax.getSeconds(), millisecond: this.req.DataMax.getMilliseconds() }).locale("it");
        this.req.DataMin = moment.utc({ year: this.req.DataMin.getFullYear(), month: this.req.DataMin.getMonth(), day: this.req.DataMin.getDate(), hour: this.req.DataMin.getHours(), minute: this.req.DataMin.getMinutes(), second: this.req.DataMin.getSeconds(), millisecond: this.req.DataMin.getMilliseconds() }).locale("it");
      }
    }

    this.loadingIndicator = true;
    this.key = 'ordini';
    this.insideBolleUscita = true;
    this.tipoBolle = false;
    this.back = "/action/exit-docs-entry";
    this.header = true;
    this.backCaption = "Torna indietro";
    this.selectable = true;
    this.caption = u['Documento'];
    this.pulisciR = (u['ServiceName'] == "");
    this.ia1 = "";

    this.acco = (u['IdTipDoc'] != 26) ? "Riferimento" : "Riferimento documento fornitore";
    this.ia2 = (u['IdTipDoc'] != 26) ? new Date() : null;
    this.subtitle = (u['ServiceName'] == '') ? '' : 'DESTINATARIO';

    this._images[0].visibile = true; this._images[0].caption = "Nuova bolla di uscita";
    this._images[5].visibile = true; this._images[5].caption = "Stampa la bolla di uscita selezionata";
    this._images[1].visibile = this._images[2].visibile = this._images[3].visibile = this._images[4].visibile = false;
    this._images[0].enabled = true; this._images[5].enabled = false;
    this._images[2].enabled = false;
    let cpimages = this._images;
    this._images = [];

    for (let a = 0; a < cpimages.length; a = a + 1) {
      this._images.push(cpimages[a]);
    }

    this._images.push({
      caption: "Lista ordini",
      src: "lista",
      enabled: true,
      action: "list",
      visibile: true
    });

    this.rows = this._tableService.rows = [...[]];

    this.loadingIndicator = false;

    /*
        this._http.post(
          this.url,
          this.req
        ).subscribe((res) => {
          //filter res
          console.log('res %o', res);
          this.loadingIndicator = false;
          if (res['esito'] == true) {
    
            let resTemp = [];
            for (let i = 0; i < res['ordini'].length; i++) {
              if (!(res['ordini'][i]['MasterSlave'] == 'S'))
                resTemp.push(res['ordini'][i]);
            }
            res['ordini'] = resTemp;
    
            var col = res['tableMap']['columns'];
            for (let k of Object.keys(col)) {
              let c = col[k];
              if (c.visible) {
                this.columns.push({
                  prop: k,
                  name: c.caption,
                  format: c.format,
                  type: c.type
                });
                this.listaCambiFIltrabili.push(c.caption);
              }
            };
    
            this.rows = [...res[this.key]];
          }
          else {
            window.alert('Attenzione: errore nel recupero delle informazioni da DB ' + res['errorMessage']);
          }
        });
    */
    if (this.entryPoint != 'orders' && this.entryPoint != 'flDetail' && this._auth.getDomain() == "1") {
      this._images[0].enabled = false;
      this._images[1].enabled = false;
      this._images[2].enabled = false;
      this._images[3].enabled = false;
      this._images[4].enabled = false;
    }
  }

  downloadReport(url, data) {
    this.hidJSON = JSON.stringify(data);
    document.getElementById('hidJSONButton').click();
  }

  pp() {
    console.log('pp');
  }

  getDownloadReport(h) {
    //console.log('getDownloadReport %o %o',h,MySettings.environment.GetReportsDataHandler.replace("{0}", h));
    return MySettings.environment.GetReportsDataHandler.replace("{0}", h);
  }

  calculateHeaderWidth() {
    let it = 0;
    for (let i of this._images)
      if (i.visibile)
        ++it;

    return 'calc(100% - ' + (((56 * it) + 0) + 'px') + ')';
  }

  loadTiles() {
    this.click = undefined;
    let tiles = [];
    let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    console.log('userInfo %o sessionStorage %o', userInfo, sessionStorage);
    if (userInfo.fornitoriTiles == undefined || userInfo.fornitoriTiles.length == 0)
      this._router.navigate(['/error/4dca90bb078b33d930f1e168a93b55c5365d20ae']);

    //if (MySettings.environment.MyContext == "DENTALPRO") userInfo.fornitoriTiles.push({ "CategoriaFornitore": "ORDINE CROSS", "IdCategoriaFornitore": -99, "Qta": -99 });

    for (let x of userInfo.fornitoriTiles)
      tiles.push({
        tile: x.CategoriaFornitore,
        id: x.IdCategoriaFornitore,
        enabled: true,
        src: x.CategoriaFornitore.toLowerCase(),
        srcHover: x.CategoriaFornitore.toLowerCase() + '_hover',
        action: x.CategoriaFornitore.toLowerCase()
      });

    return tiles;
  }

  checkPrescrizione(prescr: any) {
    if (prescr == null)
      return false;
    if (prescr == '')
      return false;
    return /^\d+$/.test(prescr);

  }

  ngOnInit() {
    this.goOn = true;

    /*
    this._images = MySettings.environment.images;
    this.sub = this._route.params.subscribe(params => {
      this.pparams = params;
      this.entryPoint = params['entryPoint'];
      let id = 0; let idD = 0;
      if (params['idInv'] !== undefined) {
        id = +params['idInv'];
        this.entryPoint = 'inventoryDetail';
        this.mainId = id;
      } else if (params['id'] !== undefined) {
        id = +params['id'];
        this.entryPoint = 'orderDetail';
        this.mainId = id;
      } else if (params['repoCode'] !== undefined) {
        id = params['repoCode'];
        this.entryPoint = 'reports';
        this.mainId = id;
        this.insideReports = true;
      } else if (params['ided'] !== undefined) {
        id = +params['ided'];
        this.entryPoint = 'bolla-entrata-detail';
        this.mainId = id;
      } else if (params['idD'] !== undefined) {
        idD = +params['idD'];
        this.mainId = idD;
        if (params['tile'] == 'entry-docs') {
          this.subtitle = "ORDINE #" + idD + " - " + localStorage.getItem('fName');
          this.entryPoint = 'entry-docs-detail';
          this.clickable = false;
          this.doubleClickable = true;
        } else if (params['tile'] == 'exit-docs') {
          this.subtitle = "ORDINE #" + idD + " - " + localStorage.getItem('fName');
          this.entryPoint = 'exit-docs-detail';
          this.clickable = false;
          this.doubleClickable = true;
        } else {
          if (params['idD'] == "cross")
            this.subtitle = "FORNITORI MULTIPLI";
          else
            this.subtitle = params['tile'] + " " + (this._auth.getUser()['ruolo'] == "Ufficio acquisti" && sessionStorage.getItem('dup') != undefined ? sessionStorage.getItem('myFi') + " - " + localStorage.getItem('fName') : localStorage.getItem('fName'));
          this.entryPoint = 'flDetail';
          this.clickable = false;
          this.selectable = true;
          this.doubleClickable = true;
        }
      }

      this._images[6].visibile = this._images[6].enabled = this._images[7].visibile = this._images[7].enabled = false;
      this.filters = false;
      this.callTable(id, idD);
      this._images[7].caption = "Crea un ordine sotto scorta";
    });
    */
    console.log('end init for images %o at entrypoint %o', this._images, this.entryPoint);
    if (this.entryPoint == 'exit-docs-entry') {
      console.log('add button for exit-docs-entry');
      for (let a = 0; a < this._images.length; a = a + 1) {
        this._images[a].enabled = false;
      }
    }

    let resreq = {
      idnegozio: MySettings.environment['idNegozio'],
      GUID: this._auth.getGUID(),
      DomainId: this._auth.getDomain(),
      ProfileId: this._auth.getProfile()
    };
    this._loaderRogges.show();
    this._http.post(
      MySettings.environment.GetBudgetResiduoClinica,
      resreq
    )
      .subscribe((res) => {
        this._loaderRogges.hide();
        if (res['esito'] == true)
          this.residuo = res['BudgetResiduo'];
      });
  }

  changeBack(src = "") {
    if (src != "") this.cbimg = "./assets/images/icone/" + src;
    else this.cbimg = "./assets/images/icone/icn_back.png";
  }

  hoverBox(id, ent) {
    if (this.entryPoint == 'reports') return;
    for (let i of this._tipologieBolle)
      i.img = './assets/images/tiles/v2/icn_uscite.png';
    this._tipologieBolle[id].img = !ent ? './assets/images/tiles/v2/icn_uscite.png' : './assets/images/tiles/v2/box_hover.png';
  }

  testDate(x) {
    //console.log(x)
  }

  test() {
    //console.log(this.hidJSON)
  }

  testParse(val) {
    var a = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var b = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
    var ind = 0;
    for (let x of a)
      if (val != undefined)
        val = val.replace(x, b[ind++]);

    return val;
  }

  getTot() {
    this.tot = 0;
    if (this.rows.length == 0) return this.tot.toFixed(2);
    for (let x of this.rows)
      this.tot += ((x == undefined) ? 0 : +x['Totale']);
    return this.tot.toFixed(2);
  }

  performAction(i) {
    if (i.src == 'lista')
      this.entryPoint = 'exit-docs-entry-list';
    //this.rows = [];
    //this.table.offset = 0;
    console.log('PERFORMACTION %o images %o entryPoint %o', i, this._images, this.entryPoint);
    let previous0 = this._images[0].enabled;
    let previous2 = this._images[2].enabled;
    let previous5 = this._images[5].enabled;
    let previous3 = this._images[3].enabled;
    let previous4 = this._images[4].enabled;
    let previous6 = false;
    if (this.isAdmin == true)
      previous6 = this._images[6].enabled;
    this._loaderRogges.show();
    if (i.action == 'delete' && this.goOn == false) {
      this._loaderRogges.hide();
      return;
    }
    if (i.enabled) {
      //this._loaderRogges.show();
      //disable all the "salva" function

      //this._images[3].enabled = false;
      //this._images[4].enabled = false;
      //this._images[6].enabled = false;
      //console.log('IDMITT %o localStorage %o', (+this._auth.getDomain() == 1) ? +localStorage.getItem('filialeId_') : this._auth.getDomain(), localStorage.getItem('filialeId_'));
      if (this.entryPoint == 'exit-docs-entry-list') {
        //new, in uscita gestione lista ordini
        this.url = MySettings.environment.GetElencoBolleEntrata;
        this.req = {
          idnegozio: MySettings.environment.idNegozio,
          GUID: this._auth.getGUID(),
          DomainId: this._auth.getDomain(),
          ProfileId: this._auth.getProfile()
          //withStock:true
        };
        console.log('callTable in exit-docs-entry-list');
        this.callTable(0, 0);
      }
      else {

        if (this.entryPoint == 'orderDetail' && this.io != undefined && this.io['StatoAvanzamento'].trim() != 'BOZZA') {

          this._images[1].enabled = false;
          return;
        }
        if (this.loadingIndicator || !i.enabled || !i.visibile) return;
        let act = i.action;

        if (act == 'sottoscorta' && this._images[7].enabled && this.sottoscortaJ != undefined) {
          localStorage.setItem('sottoscorta', JSON.stringify(this.sottoscortaJ));
          console.log('F1');
          console.log('hide 12');
          this._loaderRogges.hide();
          this._images[3].enabled = previous3;
          this._images[4].enabled = previous4;
          this._images[6].enabled = previous6;
          this._router.navigate(['/pm/store/action/orders-sottoscorta']);
        }
        else if ((act == 'draft' || act == 'confirm') && (this.entryPoint == 'flDetail' || this.entryPoint == 'orderDetail')) {

          this.search = undefined; this.focusLente(this.myprop);

          var stato = (act == 'draft') ? 14 : 15;
          var iddoc = (this.entryPoint == 'flDetail') ? 0 : this.mainId;
          var iddest = (this.entryPoint == 'flDetail') ? this.mainId : this.rows[0]['Iddest'];

          var notadocumento = this.ia4;
          if (this.myFId == '34')
            notadocumento = notadocumento + ' - ' + this.doctor;

          console.log('NOTA DOCUMENTO doctor %o nota %o parametri %o clinic %o', this.doctor, notadocumento, this.myFId, this._auth.getDomain());
          var objForControl = {};
          if (!(this._auth.getDomain() == '6696') && notadocumento.indexOf('object') > 0 && this.myFId == '34') {
            this._notifications.error(
              "Errore",
              "Il campo 'Medico' deve essere compilato!",
              MySettings.environment.notificationOptions
            );
            console.log('G1');
            this._images[3].enabled = previous3;
            this._images[4].enabled = previous4;
            this._images[6].enabled = previous6;
            console.log('hide 13');
            this._loaderRogges.hide();
            return;
          }

          if ((this.ia4 == undefined || this.ia4.trim() == '' || !this.checkPrescrizione(this.ia4)) && this.myFId == '34') {
            this._notifications.error(
              "Errore",
              "Il campo 'Prescrizione' è obbligatorio e deve essere numerico!",
              MySettings.environment.notificationOptions
            );
            console.log('G1');
            this._images[3].enabled = previous3;
            this._images[4].enabled = previous4;
            this._images[6].enabled = previous6;
            console.log('hide 13');
            this._loaderRogges.hide();
            return;
          }
          var riferimento = this.ia3;

          if (this.myFId == '34') {
            if (this.ia3 != null && this.ia3.length > 0)
              riferimento = this.ia3 + ' - ' + notadocumento;
            else
              riferimento = notadocumento;
          }

          var req = {
            "GUID": this._auth.getGUID(),
            "idnegozio": MySettings.environment.idNegozio,
            "DomainId": this._auth.getDomain(),
            "ProfileId": this._auth.getProfile(),
            "idDoc": iddoc,
            "idTipDoc": 38, // ordine fornitore
            //"idMitt": (+this._auth.getDomain() == 1) ? ((sessionStorage.getItem('dup') != undefined) ? +localStorage.getItem('filialeId') : +localStorage.getItem('filialeId_')) : this._auth.getDomain(),
            "idMitt": (this.caption == 'Dettaglio Ordine' && sessionStorage.getItem('dettaglioOrdine') != undefined) ? JSON.parse(sessionStorage.getItem('dettaglioOrdine'))['Idmitt'] : +localStorage.getItem('filialeId'),
            "idDest": iddest,
            "articoli": [],
            "Riferimento": riferimento,
            "DataDocumento": moment().format(),
            "NotaDocumento": notadocumento
          };

          //////
          if (req.idMitt == undefined || req.idMitt < 1) req.idMitt = (localStorage.getItem('filialeId_') == "null" || localStorage.getItem('filialeId_') == null) ? +localStorage.getItem('idFilialeNotNull') : +localStorage.getItem('filialeId_');

          if (req.idMitt == 0)
            req.idMitt = 1;

          var articoli = [];
          for (let a of this.rows) {
            var x = {
              "Idarticolo": a.Idarticolo,
              "IdUdm": a.IdUdm,
              "ScalaUDM": a.ScalaUDM,
              "qta": 0,
              "numConfezioni": a.Confezioni,
              "Idprezzo": a.Idprezzo,
              "idStatoAvanzamento": this.pparams.idD == "cross" ? 14 : stato
            };
            if (this.pparams.idD == "cross")
              x['idDest'] = a.Idfornitore;
            articoli.push(x);
          }

          if (this.pparams.idD == "cross")
            req.idMitt = this._auth.getDomain();

          req.articoli = articoli;

          if (this.requestBackorder != undefined) { req = this.requestBackorder; this.requestBackorder = undefined; } /////////////////////

          if ((this.ia3 == undefined || this.ia3.trim() == '') && this.myFId == '4') {
            this._notifications.error(
              "Errore",
              "Il campo 'Riferimento' deve essere compilato!",
              MySettings.environment.notificationOptions
            );
            console.log('G1');
            this._images[3].enabled = previous3;
            this._images[4].enabled = previous4;
            this._images[6].enabled = previous6;
            console.log('hide 13');
            this._loaderRogges.hide();
            return;
          }

          this._images[3].enabled = false;
          this._images[4].enabled = false;
          this._images[6].enabled = false;
          this._http.post(
            (this.pparams.idD == "cross" ? MySettings.environment.SaveOrdineCross : ((this.entryPoint == 'flDetail') ? MySettings.environment.SaveOrder : MySettings.environment.EditOrder)),
            req
          )
            .subscribe((res) => {
              console.log('hide 14');
              this._loaderRogges.hide();
              this._images[3].enabled = previous3;
              this._images[4].enabled = previous4;
              this._images[6].enabled = previous6;
              //$('#my-ngx').css('height', (window.innerHeight - 300) + 'px');
              //$('.datatable-body').css('height', (window.innerHeight - 300) + 'px');

              if (res['esito'] == true) {
                console.log('esito order cross %o', res);
                //modAR
                localStorage.removeItem('filialeId');
                if (this.entryPoint == 'flDetail') {
                  let data = {};
                  data['stato'] = 'confirm_ord';
                  data['title'] = "Conferma " + (act == 'draft' ? "salvataggio" : "creazione");
                  if (this.pparams.idD == "cross") {
                    var nu = res['listIddocumentoCross'].length;
                    var nu2 = (nu < 2) ? "L'ordine #<b>" + res['listIddocumentoCross'][0] + "</b> è stato inviato con successo!" : "Gli ordini sono stati creati con successo come bozze";// e i loro riferimenti sono: " + nu.join(", ") 
                    data['desc'] = nu2;
                  } else {
                    if (!(res['listIddocumentoCross'] == null)) {
                      var nu = res['listIddocumentoCross'].length;
                      if (nu > 1) {
                        var son = 0;
                        for (let i = 0; i < res['listIddocumentoCross'].length; i++) {
                          if (!(res['Iddocumento'] == res['listIddocumentoCross'][i]))
                            son = res['listIddocumentoCross'][i];
                        }
                        data['desc'] = "L'ordine padre #<b>" + res['Iddocumento'] + "</b> e figlio #<b>" + son + "</b> sono stati " + (act == 'draft' ? "correttamente salvati in bozza." : "inviati con successo!");
                      }
                      else
                        data['desc'] = "L'ordine #<b>" + res['Iddocumento'] + "</b> è stato " + (act == 'draft' ? "correttamente salvato in bozza." : "inviato con successo!");
                    }
                    else
                      data['desc'] = "L'ordine #<b>" + res['Iddocumento'] + "</b> è stato " + (act == 'draft' ? "correttamente salvato in bozza." : "inviato con successo!");
                  }
                  data['options'] = [{ caption: 'Ok', action: 'true' }];
                  let dialogRef = this._dialog.open(YesNoDialogComponent, {
                    width: 'auto',
                    //height: '330px',
                    data: data
                  });

                  var t = this;

                  dialogRef.afterClosed().subscribe(result => {
                    sessionStorage.removeItem('dup');
                    sessionStorage.removeItem('dupInfo');
                    this._router.navigate(['/pm/store/action/orders'])
                  });
                } else {
                  let data = {};
                  data['stato'] = 'confirm_ord';
                  data['title'] = "Conferma modifica";
                  data['desc'] = "L'ordine è stato modificato con successo!";
                  data['options'] = [{ caption: 'Ok', action: 'true' }];
                  let dialogRef = this._dialog.open(YesNoDialogComponent, {
                    width: 'auto',
                    data: data
                  });
                  dialogRef.afterClosed().subscribe(result => {
                    sessionStorage.removeItem('dup');
                    sessionStorage.removeItem('dupInfo');
                    this._router.navigate(['/pm/store/action/orders']);
                  });

                  var th = this;
                }
                this._images[3].enabled = this._images[4].enabled = false;
                this._tableService.rows = [];
                this.rows = [...this._tableService.rows];
                console.log('A');
                this._images[6].enabled = previous6;
                console.log('hide 15');
                this._loaderRogges.hide();
                return;
              } else {
                console.log('errore with res %o', res);

                if (res['isWebApiOrder'] != undefined && res['isWebApiOrder']) {
                  let dialogRef = this._dialog.open(BackOrderDialogComponent, {
                    width: '650px',
                    data: {
                      res: res
                    }
                  });

                  dialogRef.afterClosed().subscribe(result => {
                    if (isNaN(result) || result == 0) {
                      console.log('B');
                      console.log('hide 16');
                      this._loaderRogges.hide();
                      console.log('pa 1a');
                      this.performAction(this._images[0]);
                      console.log('pa 1');
                      return;
                    }
                    this.requestBackorder = res['requestNewOrder'];
                    console.log('C');
                    console.log('hide 17');
                    this._loaderRogges.hide();
                    console.log('pa 2a');
                    this.performAction(i);
                    console.log('pa 2');
                    return;
                  });

                  return;
                }
                else {
                  console.log('errore BackOrderMaxThresholdDialogComponent %o', res);
                  if (res['listMessaggiScortaMassima'] != null && res['listMessaggiScortaMassima'] != undefined && res['listMessaggiScortaMassima'].length > 0) {
                    let dialogRef = this._dialog.open(BackOrderMaxThresholdDialogComponent, {
                      width: 'auto',
                      data: {
                        res: res
                      }
                    });
                  }
                  else {
                    //if (res['errorMessage'] != undefined && res['errorMessage'] != "")
                    console.log('errore %o', res['errorMessage']);
                    this._loaderRogges.hide();
                    sessionStorage.setItem('err', res['errorMessage']);
                    this._notifications.error(
                      "Errore",
                      res['errorMessage'],
                      MySettings.environment.notificationOptions
                    );
                    console.log('D');
                    this._images[3].enabled = previous3;
                    this._images[4].enabled = previous4;
                    this._images[6].enabled = previous6;
                    console.log('hide 18');

                    /*
                    if (this.entryPoint == 'flDetail')
                      this._router.navigate(['/error/64152d21e5d95c63b92c29b284b7a3e8f0edc7e1']);
                    else
                      this._router.navigate(['/error/64152d21e5d95c63b92c29b284b7a3e8f0edc7e2']);
                    */
                  }
                }
              }

            });
          //.subscribe(val => []);
          //this._loaderRogges.hide();
          return;
        } else if ((act == 'confirm' || act == 'draft') && (this.entryPoint == 'inventory-add' || this.entryPoint == 'inventoryDetail')) {
          if (act == 'confirm' && (this.entryPoint == 'inventory-add' || this.entryPoint == 'inventoryDetail')) {
            var dnow = new Date().getHours();
            // if (dnow > 16 && (dnow < 22 || dnow == 22)) {
            console.log('---------------------------------------------------------------- DOMAIN %O', this._auth.getDescDomain());
            if (this._auth.getDescDomain() != null) { // && (this._auth.getDescDomain().indexOf('066') == 0 || this._auth.getDescDomain().indexOf('017') == 0 || this._auth.getDescDomain().indexOf('074') == 0 || this._auth.getDescDomain().indexOf('170') == 0)) {
              if (window.confirm("Attenzione: la conferma dell'inventario è una operazione che potrebbe durare diversi minuti, nei quali è necessario non chiudere la sessione di lavoro. Vuoi continuare?")) {
                this.search = undefined; this.focusLente(this.myprop);
                //this._loaderRogges.show();
                var arts = [];
                for (let x of this.rows)
                  arts.push({
                    "Idarticolo": x.Idarticolo,
                    "IdcatGiacenza": x.IdcatGiacenza,
                    "QtaContata": x.QtaContata,
                    "QtaAttesa": x.QtaAttesa
                  }
                  );
                var invreq = {
                  "GUID": this._auth.getGUID(),
                  "idnegozio": MySettings.environment.idNegozio,
                  "DomainId": this._auth.getDomain(),
                  "idDoc": (this.entryPoint == 'inventoryDetail') ? this.mainId : 0,
                  "articoliInventario": arts
                };
                this._images[3].enabled = false;
                this._images[4].enabled = false;
                this._images[6].enabled = false;
                this._images[0].enabled = false;
                this._images[2].enabled = false;
                this._images[5].enabled = false;

                this._http.post(
                  MySettings.environment.SaveInventarioDefinitivo, invreq)
                  .subscribe((res) => {
                    console.log('hide 19');
                    this._loaderRogges.hide();
                    let w = (this.entryPoint == 'inventoryDetail') ? 'modificato' : 'effettuato';
                    if (res['esito'] == true) {
                      console.log('E');
                      this._images[3].enabled = previous3;
                      this._images[4].enabled = previous4;
                      this._images[6].enabled = previous6;
                      this._images[0].enabled = previous0;
                      this._images[2].enabled = previous2;
                      this._images[5].enabled = previous5;
                      window.alert("Inventario " + w + " con successo. Il numero del documento è #" + res['IdDoc']);
                      /*
                      this._notifications.success(
                        "OK!",
                        "Inventario " + w + " con successo. Il numero del documento è #" + res['IdDoc'],
                        MySettings.environment.notificationOptions
                      );
                      */
                      var t = this;
                      this._router.navigate(['/pm/store/action/inventory']);
                      //return to main page
                      //setTimeout(function () { t._router.navigate(['/' + t.back]);}, 2500);
                    } else {
                      console.log('F');
                      this._loaderRogges.hide();
                      window.alert("Errore: C'è stato un errore nella compilazione dell'inventario: " + res['errorMessage']);
                      /*
                      this._notifications.error(
                        "Errore",
                        "C'è stato un errore nella compilazione dell'inventario: " + res['errorMessage'],
                        MySettings.environment.notificationOptions
                      );
                      */
                      this._router.navigate(['/pm/store/action/inventory']);
                    }
                  });
                console.log('G2');
              }
              else {
                this._loaderRogges.hide();
                return;
              }
            }
            else {
              // window.alert("ATTENZIONE: in via temporanea la conferma dell'inventario è possibile solo tra le 17.00 e le 22.00");
              window.alert("ATTENZIONE: non è possibile confermare l'inventario, la funzione è momentaneamente non disponibile.");
              this._loaderRogges.hide();
              return;
            }
          }
          else {
            console.log('act %o this.entryPoint %o', act, this.entryPoint);

            this.search = undefined; this.focusLente(this.myprop);
            //this._loaderRogges.show();
            var arts = [];
            var newLength = 0;
            for (let x of this.rows) {
              arts.push({
                "Idarticolo": x.Idarticolo,
                "IdcatGiacenza": x.IdcatGiacenza,
                "QtaContata": x.QtaContata,
                "QtaAttesa": x.QtaAttesa
              }
              );
              newLength++;
            }
            var invreq = {
              "GUID": this._auth.getGUID(),
              "idnegozio": MySettings.environment.idNegozio,
              "DomainId": this._auth.getDomain(),
              "idDoc": (this.entryPoint == 'inventoryDetail') ? this.mainId : 0,
              "articoliInventario": arts
            };
            this._images[3].enabled = false;
            this._images[4].enabled = false;
            this._images[6].enabled = false;
            this._images[0].enabled = false;
            this._images[2].enabled = false;
            this._images[5].enabled = false;
            if (newLength >= this.lengthInv) {
              this._http.post(
                (act == 'confirm') ? MySettings.environment.SaveInventarioDefinitivo : MySettings.environment.SaveInventarioProvvisorio,
                invreq
              )
                .subscribe((res) => {
                  console.log('hide 19');
                  this._loaderRogges.hide();
                  let w = (this.entryPoint == 'inventoryDetail') ? 'modificato' : 'effettuato';
                  if (res['esito'] == true) {
                    this.lengthInv = newLength;
                    console.log('E');
                    this._images[3].enabled = previous3;
                    this._images[4].enabled = previous4;
                    this._images[6].enabled = previous6;
                    this._images[0].enabled = previous0;
                    this._images[2].enabled = previous2;
                    this._images[5].enabled = previous5;
                    window.alert("Inventario " + w + " con successo. Il numero del documento è #" + res['IdDoc']);
                    /*
                     this._notifications.success(
                       "OK!",
                       "Inventario " + w + " con successo. Il numero del documento è #" + res['IdDoc'],
                       MySettings.environment.notificationOptions
                     );
                     */
                    var t = this;
                    this._router.navigate(['/pm/store/action/inventory']);
                    //setTimeout(function () { t._router.navigate(['/' + t.back]);}, 2500);
                  } else {
                    console.log('F');
                    this._loaderRogges.hide();
                    window.alert("Errore: C'è stato un errore nella compilazione dell'inventario: " + res['errorMessage']);
                    /*
                    this._notifications.error(
                      "Errore",
                      "C'è stato un errore nella compilazione dell'inventario: " + res['errorMessage'],
                      MySettings.environment.notificationOptions
                    );
                    */
                    this._router.navigate(['/pm/store/action/inventory']);
                  }
                });
              console.log('G');

              return;
            }
            else {
              if (window.confirm("Attenzione: stai salvando un inventario provvisorio che contiene meno prodotti contati rispetto all'ultimo salvataggio. Vuoi continuare?")) {
                this._http.post(
                  (act == 'confirm') ? MySettings.environment.SaveInventarioDefinitivo : MySettings.environment.SaveInventarioProvvisorio,
                  invreq
                )
                  .subscribe((res) => {
                    console.log('hide 19b');
                    this._loaderRogges.hide();
                    let w = (this.entryPoint == 'inventoryDetail') ? 'modificato' : 'effettuato';
                    if (res['esito'] == true) {
                      this.lengthInv = newLength;
                      console.log('E');
                      this._images[3].enabled = previous3;
                      this._images[4].enabled = previous4;
                      this._images[6].enabled = previous6;
                      this._images[0].enabled = previous0;
                      this._images[2].enabled = previous2;
                      this._images[5].enabled = previous5;
                      window.alert("Inventario " + w + " con successo. Il numero del documento è #" + res['IdDoc']);
                      /*
                       this._notifications.success(
                         "OK!",
                         "Inventario " + w + " con successo. Il numero del documento è #" + res['IdDoc'],
                         MySettings.environment.notificationOptions
                       );
                       */
                      var t = this;
                      this._router.navigate(['/pm/store/action/inventory']);
                      //setTimeout(function () { t._router.navigate(['/' + t.back]);}, 2500);
                    } else {
                      console.log('F');
                      this._loaderRogges.hide();
                      window.alert("Errore: C'è stato un errore nella compilazione dell'inventario: " + res['errorMessage']);
                      /*
                      this._notifications.error(
                        "Errore",
                        "C'è stato un errore nella compilazione dell'inventario: " + res['errorMessage'],
                        MySettings.environment.notificationOptions
                      );
                      */
                      this._router.navigate(['/pm/store/action/inventory']);
                    }
                  });
                console.log('G2');

                return;
              }
              else {
                this._loaderRogges.hide();
              }

            }
          }
        } else if (act == 'confirm' && (this.entryPoint == 'entry-docs-detail' || this.entryPoint == 'exit-docs-add' || this.entryPoint == 'exit-docs-entry')) {
          console.log('Arr');
          this.search = undefined;

          if (((new Date(this.ia2)) + "") == "Invalid Date") {
            console.log('H');
            this._images[3].enabled = previous3;
            this._images[4].enabled = previous4;
            this._images[6].enabled = previous6;
            console.log('hide 20');
            this._loaderRogges.hide();
            this._notifications.error(
              "Errore",
              "Il campo 'Data' deve essere compilato!",
              MySettings.environment.notificationOptions
            );

            return;
          }
          var p;
          let art = [];
          for (let a of this.rows)
            art.push({
              idvend: a['Idvend'],
              Idarticolo: a['Idarticolo'],
              IdUdm: a['IdUdm'],
              ScalaUDM: a['ScalaUDM'],
              numConfezioni: a['Confezioni'],
              Idprezzo: a['Idprezzo']
            });
          console.log('P %o localstorage', localStorage.getItem('p'));
          if (this.entryPoint == 'exit-docs-entry')
            p = { Iddoc: undefined, Idmitt: this._auth.getDomain(), Iddest: sessionStorage.getItem('uscite_destin'), IdTipologiaDocumento: sessionStorage.getItem('uscite_td') };
          else {
            if (Array.isArray(localStorage.getItem('p')['selected'])) {
              p = JSON.parse(localStorage.getItem('p'))['selected'][0];
            }
            else {
              p = JSON.parse(localStorage.getItem('p'))[0];
            }
          }
          console.log('P %o ', p);

          var em_req;
          if (this.entryPoint == 'exit-docs-entry') {
            //console.log('ARIANNA1');

            var u = JSON.parse(sessionStorage.getItem('uscite'));
            //if (this.isAdmin == true) {
            em_req = {
              idnegozio: MySettings.environment.idNegozio,
              GUID: this._auth.getGUID(),
              DomainId: this._auth.getDomain(),
              ProfileId: this._auth.getProfile(),
              IdTipologiaDocumento: sessionStorage.getItem('uscite_td'),
              idMitt: this._auth.getDomain(),
              idDest: (sessionStorage.getItem('uscite_dest') != undefined) ? sessionStorage.getItem('uscite_dest') : 2,
              articoli: art,
              Riferimento: this.ia1,
              DataDocumento: this.getISODate(this.ia2)
            };
            if (u['ServiceParameter'] == "") {
              em_req['idDest'] = 2;
            } else {
              var ue = JSON.parse(sessionStorage.getItem('uscite_r'));
              em_req['idDest'] = ue[u['ServiceParameter']];
            }
            //}
          } else {
            em_req = {
              idnegozio: MySettings.environment.idNegozio,
              GUID: this._auth.getGUID(),
              DomainId: this._auth.getDomain(),
              ProfileId: this._auth.getProfile(),
              idDoc: p['Iddoc'],
              idMitt: p['Idmitt'],
              idDest: p['Iddest'],
              articoli: art
            };

            em_req.DataMax = new Date(Date.now());
            em_req.DataMin = new Date(Date.now() - (24 * 30 * 12 * 60 * 60 * 1000));

            em_req.DataMax.setHours(23);
            em_req.DataMax.setMinutes(59);
            em_req.DataMin.setHours(0);
            em_req.DataMin.setMinutes(0);

            em_req.DataMax = moment.utc({ year: em_req.DataMax.getFullYear(), month: em_req.DataMax.getMonth(), day: em_req.DataMax.getDate(), hour: em_req.DataMax.getHours(), minute: em_req.DataMax.getMinutes(), second: em_req.DataMax.getSeconds(), millisecond: em_req.DataMax.getMilliseconds() }).locale("it");
            em_req.DataMin = moment.utc({ year: em_req.DataMin.getFullYear(), month: em_req.DataMin.getMonth(), day: em_req.DataMin.getDate(), hour: em_req.DataMin.getHours(), minute: em_req.DataMin.getMinutes(), second: em_req.DataMin.getSeconds(), millisecond: em_req.DataMin.getMilliseconds() }).locale("it");

            var temp = em_req.idMitt;
            em_req.idMitt = em_req.idDest;
            em_req.idDest = temp;
            em_req['Riferimento'] = this.ia1;
            em_req['DataDocumento'] = this.getISODate(this.ia2);
          }

          var eu = (this.entryPoint == 'entry-docs-detail') ? "entrata" : "uscita";

          this._http.post(
            (this.entryPoint == 'entry-docs-detail') ? MySettings.environment.EmettiBolla : MySettings.environment.EmettiBollaUscita,
            em_req
          )
            .subscribe((res) => {
              console.log('ARIANNA2');
              console.log('I');
              this._images[3].enabled = previous3;
              this._images[4].enabled = previous4;
              this._images[6].enabled = previous6;
              console.log('hide 21 entrypoint %o', this.entryPoint);
              this._loaderRogges.hide();
              if (!(this.entryPoint == 'entry-docs-detail'))
                this._loaderRogges.hide();
              if (res['esito'] == true) {
                this._notifications.success(
                  "OK!",
                  "La bolla di " + eu + " è stata emessa correttamente per il documento numero #" + res['Iddocumento'] + "!",
                  MySettings.environment.notificationOptions
                );
                var t = this;
                setTimeout(function () {
                  if (t.entryPoint == 'exit-docs-entry') t.clickBollaUscita(t.atterra); else t._router.navigate(['/pm/store/action/entry-docs-add']);
                }, 1500);
              } else {
                this._loaderRogges.hide();
                this._notifications.error(
                  "Errore",
                  "Errore di emissione della bolla di " + eu + " per il documento. Errore: " + res['errorMessage'],
                  MySettings.environment.notificationOptions
                );

                return; // fix
              }
            });

        } else if (act == 'duplicate' && this.entryPoint == 'orders') {
          //console.log('---------------------------------------------------------DIALOG DUPLICA ORDINE A');
          sessionStorage.setItem('dup', "" + this.selected[this.selected.length - 1]['Iddoc']);
          sessionStorage.setItem('isdup', "true");
          sessionStorage.setItem('myFi', this.selected[this.selected.length - 1]['Fornitore']);
          sessionStorage.removeItem('dupInfo');
          localStorage.setItem('IdcategoriaFornitore', this.selected[this.selected.length - 1]['IdcategoriaFornitore']);

          this.search = undefined; this.myprop = null; this.br = undefined;

          if (this._auth.getUser()['ruolo'] == "Ufficio acquisti") {
            //console.log('---------------------------------------------------------DIALOG DUPLICA ORDINE B');
            let data = {};
            data['stato'] = 'duplicate';
            data['title'] = "Duplicazione ordine";
            data['desc'] = "Stai per duplicare l'ordine #<b>" + this.selected[this.selected.length - 1]['Iddoc'] + "</b> con il destinatario di tipo <i>" + this.selected[this.selected.length - 1]['CategoriaFornitore'].toLowerCase() + " </i> e denominazione <b>" + this.selected[this.selected.length - 1]['Fornitore'] + "</b>.<br><br>Procedere con il " + this.selected[this.selected.length - 1]['CategoriaFornitore'].toLowerCase() + " selezionato o cambiare destinatario?";
            data['options'] = [{ caption: 'Annulla', action: 'cancel' }, { caption: 'Cambia', action: 'change' }, { caption: 'Procedi', action: 'proceed' }];
            console.log('L');
            this._images[3].enabled = previous3;
            this._images[4].enabled = previous4;
            this._images[6].enabled = previous6;
            console.log('hide 22');
            this._loaderRogges.hide();
            let dialogRef = this._dialog.open(YesNoDialogComponent, {
              width: 'auto',
              data: data
            });
            dialogRef.afterClosed().subscribe(result => {
              if (result == 'change') {
                //console.log('---------------------------------------------------------DIALOG DUPLICA ORDINE CHANGE setitem with %o',this.selected[this.selected.length - 1]['CategoriaFornitore'].toLowerCase() + '/' + this.selected[this.selected.length - 1]['Iddest']);
                sessionStorage.removeItem('dupInfo');
                sessionStorage.setItem('dupInfo', this.selected[this.selected.length - 1]['CategoriaFornitore'].toLowerCase() + '/' + this.selected[this.selected.length - 1]['Iddest']);
                this._router.navigate(['/pm/store/action/orders-add']);
              } else if (result == 'proceed') {
                localStorage.removeItem('filialeId_');
                localStorage.removeItem('fName');
                localStorage.setItem('filialeId_', this.selected[this.selected.length - 1]['Idmitt']);
                localStorage.setItem('fName', this.selected[this.selected.length - 1]['Fornitore']);

                this._router.navigate(['/pm/store/action/order/fornitore/' + this.selected[this.selected.length - 1]['Iddest']]);
              } else return;
            });
          } else {
            console.log('M');
            this._images[3].enabled = previous3;
            this._images[4].enabled = previous4;
            this._images[6].enabled = previous6;
            console.log('hide 23');
            this._loaderRogges.hide();
            localStorage.setItem('filialeId_', this.selected[this.selected.length - 1]['Idmitt']);
            localStorage.setItem('fName', this.selected[this.selected.length - 1]['Fornitore']);

            this._router.navigate(['/pm/store/action/order/fornitore/' + this.selected[this.selected.length - 1]['Iddest']]);
          }
        } else if (act == 'add' && (this.entryPoint == 'flDetail' || this.entryPoint == 'orderDetail' || this.entryPoint == 'exit-docs-add' || this.entryPoint == 'exit-docs-entry' || this.entryPoint == 'inventory-add' || this.entryPoint == 'inventoryDetail')) {
          var xx = 0; for (let x of this.rows) this.editing[xx++ + '-Confezioni'] = false; /// fix qta
          //console.log('ARIANNA3');
          sessionStorage.removeItem('dup');
          if (this.entryPoint == 'exit-docs-entry') {
            this._images[0].caption = "Aggiungi un prodotto";
            this._images[5].visibile = false;
            this._images[4].visibile = true;
            this._images[2].visibile = true;
            this._images[2].enabled = true;
            this._images[4].caption = "Salva e conferma la bolla";
            var u = JSON.parse(sessionStorage.getItem('uscite'));
            if (this.pulisciR) {
              this.pulisciR = false;
              this.rows = [...[]];
            }
            console.log('CHECK u %o', u);
            if (u == null || u['ServiceName'] == '') {
              this.clickable = false;
              this.infoAggiuntive = true;
              console.log('z1');
              this._images[3].enabled = previous3;
              this._images[4].enabled = previous4;
              this._images[6].enabled = previous6;
              console.log('hide 24');
              this._loaderRogges.hide();
              this.openUscitaAddProd();
            } else if (!this.bollaSave) {
              this.header = false;
              this.clickable = true;
              this.url = MySettings.environment.webApi + "/" + u['ServiceName'];
              console.log('URL pre hide 25 %o', this.url);
              this.req = {
                idnegozio: MySettings.environment.idNegozio,
                GUID: this._auth.getGUID(),
                DomainId: this._auth.getDomain(),
                ProfileId: this._auth.getProfile(),
                DomainContext: MySettings.environment.MyContext
              };
              this.loadingIndicator = true;

              console.log('N');
              if (this.table != null) {
                this.tableOffset = 0;
                this.table.offset = 0;
                this.table.bodyComponent.updateOffsetY(0);
              };
              this._images[3].enabled = previous3;
              this._images[4].enabled = previous4;
              this._images[6].enabled = previous6;
              console.log('hide 25');
              this._loaderRogges.hide();
              let self = this;
              this._http.post(
                this.url,
                this.req
              )
                .subscribe((res) => {
                  self.loadingIndicator = false;
                  let col = res['tableMap'].columns;
                  self.columns = [];
                  for (let k of Object.keys(col)) {
                    let c = col[k];
                    if (c.visible) {
                      self.columns.push({
                        prop: k,
                        name: c.caption,
                        format: c.format,
                        type: c.type
                      });
                    }
                    self.rows = res['fornitori'];

                    //if (this.entryPoint == 'exit-docs-entry') {
                    //$(function () {
                    //$('#my-ngx').css('height', (window.innerHeight - 450) + 'px');
                    //$('.datatable-body').css('height', (window.innerHeight - 450) + 'px');
                    //});
                    // }
                  };
                });
              this.bollaSave = true;
              return;
            }
          }

          let idf = (this.entryPoint == 'orderDetail') ? JSON.parse(sessionStorage.getItem('dettaglioOrdine'))['Iddest'] : this.mainId;
          let dialogRef;

          if (this.entryPoint == 'flDetail' && localStorage.getItem('sottoscorta') != undefined) {
            localStorage.removeItem('sottoscorta');
            console.log('hide 27');
            this._loaderRogges.hide();
            return;
          }

          if (this.entryPoint == 'inventory-add' || this.entryPoint == 'inventoryDetail') {
            //console.log(this.rows, this._tableService.rows, this.br);

            this.search = undefined;
            this.focusLente(this.myprop);
            this._tableService.hasStarted = true;
            console.log('O');
            this._images[3].enabled = previous3;
            this._images[4].enabled = previous4;
            this._images[6].enabled = previous6;
            console.log('hide 26');
            this._loaderRogges.hide();
            dialogRef = this._dialog.open(AddProductStoreDialogComponent, {
              width: '95%',
              //height: '770px',
              data: { dati: this.rows, chiudi: true, act: this.entryPoint }
            });
          }
          else if (this.entryPoint == 'exit-docs-entry') {
            console.log('P');
            this._images[3].enabled = previous3;
            this._images[4].enabled = previous4;
            this._images[6].enabled = previous6;
            console.log('hide 27');
            this._loaderRogges.hide();
            dialogRef = this._dialog.open(AddProductStoreDialogComponent, {
              width: '95%',
              //height: '770px',
              data: { bolleUscita: true, Idfornitore: 1, dati: this.rows, act: this.entryPoint }
            });
          }
          else {
            if (this.pparams.idD == "cross") idf = "cross";
            console.log('Q');
            this._images[3].enabled = previous3;
            this._images[4].enabled = previous4;
            this._images[6].enabled = previous6;
            console.log('hide 28');
            this._loaderRogges.hide();
            dialogRef = this._dialog.open(AddProductStoreDialogComponent, {
              width: '95%',
              //height: '770px',
              data: {
                idCategoriaFornitore: this.idcf == undefined ? localStorage.getItem('fId') : this.idcf,
                Idfornitore: idf + ((this.entryPoint == 'exit-docs-add') ? "-" : ""),
                bolleUscita: false,
                dati: this.rows,
                act: this.entryPoint
              }
            });
          }
          console.log('R');

          this._images[3].enabled = previous3;
          this._images[4].enabled = previous4;
          this._images[6].enabled = previous6;
          console.log('hide 1');
          this._loaderRogges.hide();

          //let self = this;
          dialogRef.afterClosed().subscribe(result => {
            console.log('result after close dialog %o', result);
            console.log('fit table!');
            this.focus();
            /*
            $(function () {
              $('#my-ngx').css('height', (window.innerHeight - 300) + 'px !important');
              $('.datatable-body').css('height', '200px');
            });
            */
            this.br = undefined;

            if ((this.entryPoint != 'inventoryDetail' && this.entryPoint != 'inventory-add') || ((this.entryPoint == 'inventory-add' || this.entryPoint == 'inventoryDetail') && this._tableService.hasStarted))
              this.rows = [...[]];
            if ((this.entryPoint == 'flDetail' || this.entryPoint == 'orderDetail') && !this._images[3].enabled) {
              this._images[3].enabled = true;
              this._images[4].enabled = true;
            }
            if (this.entryPoint == 'flDetail') {
              var conto = this.rows.length;
              this.rows = [...this._tableService.rows];
              // algranati fix
              if (this.rows.length > conto) {
                this.focus();
                this.removeOthers(this.rows[(this.rows.length - 1)]['Id'] + '-Confezioni');
                return;
              }
            } else {
              if (this.entryPoint == 'inventory-add') {

                if (result != undefined && !result) return;
                this.rows = [...this._tableService.rows];
                let ind = 0;
                for (let f of this.rows)
                  f.Id = ++ind;
                this.rows = [...this.rows];
                this._images[4].enabled = this._images[3].enabled = (this.rows.length > 0);

                this.selected = [];
                ind = 0;
                for (let i of this.rows) { this.editing[ind++ + "-Confezioni"] = false; i['Id'] = ind; }
                if (this.rows != null && this.rows.length > 0)
                  this.editing[this.rows[this.rows.length - 1]['Id'] + "-Confezioni"] = true;
                self.focus();
                return;
              }

              let f = 0; let fo = false; var mioid = 0;
              if (this.entryPoint == 'exit-docs-entry') {

                console.log('ALrows %o %o', this.rows, this._tableService.rows);
                for (let x of this._tableService.rows) {
                  if (x['Id'] != undefined) {
                    console.log('ALFA');
                    let ff = false;
                    for (let y of this.rows) {
                      if (x['Idarticolo'] == y['Idarticolo']) {
                        console.log('BETA');
                        ff = true; mioid = y['Id']; this.editing[mioid + "-Confezioni"] = true; break;
                      } else {
                        console.log('GAMMA');

                      }
                    }
                    if (!ff) {
                      console.log('ZETAZETA');
                      //x['Totale'] = NaN;
                      this.rows.push(x);

                    }

                    //this.focus();
                  }

                }
                //this.rows = [...this.rows];
                //var leftPos = $('.datatable-body').scrollLeft();
                //$('.datatable-body').animate({scrollLeft: leftPos + 200});
                // $('.datatable-body').animate({scrollLeft: leftPos - 4},10);
                //this.table.offset = 0;
                //this.table.bodyComponent.updateOffsetY(0);
                // this.rows = [...this.rows];
                //this.table.element.getElementsByTagName('datatable-body')[0].scrollTop = 1;
                // this.table.offset = 0;
                console.log('ROWS %o for exit-docs-entry images %o sessionStorage %o localStorage %o', this.rows, this._images, sessionStorage, localStorage);

                //self._tableService.rows = [];

                this._images[4].enabled = (this.rows.length > 0);
                this.selected = [];
                let ind = 0; let last = 0;
                for (let i of this.rows) { this.editing[i['Id'] + "-Confezioni"] = false; last = i['Id']; }
                this.editing[last + "-Confezioni"] = true;
                this.rows = [...this.rows];
                this._tableService.rows = [...this._tableService.rows];
                this.tableOffset = 0;
                console.log(' this.table.offset %o', this.table.offset);
                /*
                $(function () {
                  let e = document.getElementById("my-ngx");
                  let w = window.getComputedStyle(e).getPropertyValue('width');
                  console.log('-------------------------------------- W %o', w);
                  //$('#my-ngx').css('width', (window.innerWidth - 300) + 'px !important');
                  var leftPos = $('.datatable-body').scrollLeft();
                  $('.datatable-body').animate({ scrollLeft: leftPos + 2 }, 10);
                  $('.datatable-body').animate({ scrollLeft: leftPos - 2 }, 10);
                  //$(function () {
                  //  $('#my-ngx').css('width', w + ' !important');
                  //});
                });
                */
                //let e = document.getElementById("my-ngx");
                //let w = window.getComputedStyle(e).getPropertyValue('width');

                //$('#my-ngx').css('width', (window.innerWidth - 300) + 'px !important');

                var leftPos = $('.datatable-body').scrollLeft();
                console.log(' LEFTPOS %o', leftPos);
                $('.datatable-body').animate({ scrollLeft: leftPos + 2 }, 10);
                $('.datatable-body').animate({ scrollLeft: leftPos - 2 }, 10);
                let e = document.getElementById('my-ngx');
                let w = window.getComputedStyle(e).getPropertyValue('width');
                console.log('-------------------------------------- W %o', w);
                $('.datatable-body').css('width', '100px');
                $('.datatable-body').css('width', '866px');

                //$('.datatable-body').css('width', w);
                //$('.datatable-body').css('height', '400px');
                //$('.datatable-body').css('height', '362px');
                return;
              }
              else if (this._tableService.rows.length > 0)
                for (let x of this.rows)
                  if (x.Idarticolo == this._tableService.rows[0]['Idarticolo']) {
                    fo = true;
                    break;
                  }
                  else
                    f++;

              if (!fo) {
                if (this._tableService.rows.length == 0) return;
                for (let r of this._tableService.rows) {
                  r.Id = this.rows.length + 1;
                  this.rows.push(r);
                }
              }

              if (fo && this.entryPoint == 'orderDetail') {

                if (this.rows.length == 0) return;
                for (let r of this._tableService.rows) {
                  r.Id = this.rows.length + 1;
                  this.rows.push(r);
                }
              }

              if (fo != false && (this.entryPoint == 'inventory-add')) {
                if (this.rows.length == 0) return;
                this.rows[f]['QtaContata'] = +this.rows[f]['QtaContata'] + +this._tableService.rows[0]['QtaContata'];
              }
              this.rows = [...this.rows];
              if (this.entryPoint == 'exit-docs-add') this._images[4].enabled = (this.rows.length > 0);
              this._tableService.rows = [];
            }
            this.selected = [];
            let ind = 0;
            for (let i of this.rows) { this.editing[ind++ + "-Confezioni"] = false; i['Id'] = ind; }
            if (this.rows[this.rows.length - 1] != undefined && this.rows[this.rows.length - 1]['Id'] != undefined) this.editing[this.rows[this.rows.length - 1]['Id'] + "-Confezioni"] = true;
            this.focus();
          });
        } else if (act == 'edit' && this.entryPoint == 'flDetail') {
          let ind = 0;
          for (let r of this.rows) {
            if (r == this.selected[this.selected.length - 1]) break;
            ++ind;
          }
          this.editing[ind + '-Confezioni'] = true;
          this.selected = [];
          console.log('S');
          this._images[3].enabled = previous3;
          this._images[4].enabled = previous4;
          this._images[6].enabled = previous6;
          console.log('hide 2');
          this._loaderRogges.hide();
          this.focus();
        } else if (act == 'print') {
          console.log('T');
          this._images[3].enabled = previous3;
          this._images[4].enabled = previous4;
          this._images[6].enabled = previous6;
          console.log('hide 3');
          this._loaderRogges.hide();
          if (this.entryPoint == 'exit-docs-entry') {
            var ur = MySettings.environment.GetHandlerPDF;
            ur = ur.replace("{1}", this.id_print + "");
            ur = ur.replace("{0}", MySettings.environment.idNegozio + "");
            ur = ur.replace("{2}", this._auth.getDomain() == '1' ? '1' : '0');
            window.open(ur);
            // $('#my-ngx').css('height', (window.innerHeight - 450) + 'px');
            return;
          }
          if (this.entryPoint == 'inventory') {
            var url = MySettings.environment.GetHandlerInventario;
            url = url.replace("{0}", MySettings.environment.idNegozio + "");
            url = url.replace("{1}", this.selected[this.selected.length - 1].Iddoc + "");
            url = url.replace("{2}", this._auth.getDomain() == '1' ? '1' : '0');
            window.open(url);
            return;
          }
          if (this.entryPoint == "bolla-entrata-detail") {
            var url = MySettings.environment.GetHandlerPDF;
            url = url.replace("{0}", MySettings.environment.idNegozio + "");
            url = url.replace("{1}", this.rows[0]['Iddoc'] + "");
            url = url.replace("{2}", this._auth.getDomain() == '1' ? '1' : '0');
            window.open(url);
            return;
          }
          url = this.urlPDF;
          url = url.replace("{1}", this.selected[this.selected.length - 1]['Iddoc']);
          window.open(url);
        } else if (act == 'delete' && (this.entryPoint == 'flDetail' || this.entryPoint == 'orderDetail' || this.entryPoint == 'inventory-add' || this.entryPoint == 'inventoryDetail' || this.entryPoint == 'exit-docs-entry')) {
          let ind = 0;
          for (let r of this.rows) {
            if (r.Idarticolo == +this.selected[this.selected.length - 1]['Idarticolo']) break;
            ++ind;
          }
          console.log('U');
          this._images[3].enabled = previous3;
          this._images[4].enabled = previous4;
          this._images[6].enabled = previous6;
          console.log('hide 4');
          this._loaderRogges.hide();
          this.deleteRow(ind);
          if (this.entryPoint == 'orderDetail' || this.entryPoint == 'inventory-add' || this.entryPoint == 'inventoryDetail' || this.entryPoint == 'exit-docs-entry') {
            return;
          }
          this.selected = [];
          this._tableService.rows = [...this._tableService.rows];

          if (this._tableService.rows == undefined) this._tableService.rows = [];
          this.rows = [...this._tableService.rows];

          ///////////////
          var ix = 0;
          for (let id of this.rows) this.editing[ix++] = false;
        } else if (act == 'delete' && this.entryPoint == 'orders') {
          let data = {};
          if (this.selected[this.selected.length - 1]['IdstatoAvanzamento'] == 14 || this.selected[this.selected.length - 1]['IdstatoAvanzamento'] == 15)
            data['stato'] = 'conferma';
          else {
            data['stato'] = 'msg';
            data['options'] = [
              {
                caption: 'annulla',
                action: 'false'
              }
            ];
            data['title'] = "Impossibile proseguire";
            data['desc'] = "Impossibile procedere con l'eliminazione dell'ordine. Non è possibile cancellare e/o modificare l'ordine.";
          }
          console.log('Z2');
          this._images[3].enabled = previous3;
          this._images[4].enabled = previous4;
          this._images[6].enabled = previous6;
          console.log('hide 5');
          this._loaderRogges.hide();
          let dialogRef = this._dialog.open(YesNoDialogComponent, {
            width: 'auto',
            data: data
          });

          dialogRef.afterClosed().subscribe(result => {
            if (data['stato'] == 'conferma') {
              if (result == 'true') {
                let req = {
                  "idnegozio": MySettings.environment.idNegozio,
                  "GUID": this._auth.getGUID(),
                  "DomainId": this._auth.getDomain(),
                  "idDoc": this.selected[this.selected.length - 1]['Iddoc'],
                  "ProfileId": this._auth.getProfile()
                };
                this._http.post(
                  MySettings.environment.DeleteOrder,
                  req
                )
                  .subscribe((res) => {
                    if (res['esito'] == true) {
                      let ind = 0;
                      if (this.selected == undefined || this.selected[this.selected.length - 1] == undefined) return;
                      for (let x of this.rows)
                        if (x['Iddoc'] == this.selected[this.selected.length - 1]['Iddoc']) break; else ++ind;
                      this.deleteRowTableNormal(ind);
                      this._notifications.success(
                        "Ordine eliminato",
                        "L'ordine è stato eliminato con successo!",
                        MySettings.environment.notificationOptions
                      );
                    } else {
                      this._loaderRogges.hide();
                      localStorage.setItem('myErrMsg', res['errorMessage']);
                      this._router.navigate(['/error/e9d596e7807a846bc76a51e845fcc844f24dfdaa']);
                    }
                  });
              }
            }
            return;
          });
        } else if (act == 'delete' && this.entryPoint == 'inventory') {
          let data = {};
          if (this.selected[this.selected.length - 1]['TipoDocumento'] == 'INVENTARIO PROVVISORIO' || this.selected[this.selected.length - 1]['TipoDocumento'] == 'INVENTARIO CONTATI') {
            data['stato'] = 'conferma';
            data['oggetto'] = "l'inventario";
          }
          else {
            data['stato'] = 'msg';
            data['options'] = [
              {
                caption: 'annulla',
                action: 'false'
              }
            ];
            data['title'] = "Impossibile proseguire";
            data['desc'] = "Impossibile procedere con l'eliminazione dell'inventario. Non è possibile cancellare e/o modificare un inventario il cui stato è <b>diverso da <em>PROVVISORIO</em></b>.";
          }
          console.log('V');
          this._images[3].enabled = previous3;
          this._images[4].enabled = previous4;
          this._images[6].enabled = previous6;
          console.log('hide 6');
          this._loaderRogges.hide();
          let dialogRef = this._dialog.open(YesNoDialogComponent, {
            width: 'auto',
            data: data
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result != "false") {
              let req = {
                "idnegozio": MySettings.environment.idNegozio,
                "GUID": this._auth.getGUID(),
                "DomainId": this._auth.getDomain(),
                "idDoc": this.selected[this.selected.length - 1]['Iddoc']
              };
              if (result)
                this._http.post(
                  MySettings.environment.DeleteInventarioProvvisorio,
                  req
                )
                  .subscribe((res) => {
                    if (res['esito'] == true) {
                      let ind = 0;
                      if (this.selected == undefined || this.selected[this.selected.length - 1] == undefined) return;
                      for (let x of this.rows)
                        if (x['Iddoc'] == this.selected[this.selected.length - 1]['Iddoc']) break; else ++ind;
                      this.deleteRowTableNormal(ind);
                      this._notifications.success(
                        "Inventario eliminato",
                        "L'inventario è stato eliminato con successo!",
                        MySettings.environment.notificationOptions
                      );

                      this._images[0].enabled = true;
                      if (this.entryPoint == 'inventory') {
                        for (let r of this.rows)
                          if (r['TipoDocumento'].toUpperCase() == 'INVENTARIO PROVVISORIO' || r['TipoDocumento'].toUpperCase() == 'INVENTARIO CONTATI') {
                            this._images[0].enabled = false;
                            break;
                          }
                      }
                    } else {
                      this._notifications.error(
                        "KO",
                        "Errore durante l'eliminazione dell'inventario: ",
                        MySettings.environment.notificationOptions
                      );
                    }
                  });
            }
          });
        } else if (act == 'edit' && this.entryPoint == 'inventoryDetail') {
          this.editing[this.selectedEdit['Id'] + '-QtaContata'] = true;
          console.log('Z');
          this._images[3].enabled = previous3;
          this._images[4].enabled = previous4;
          this._images[6].enabled = previous6;
          console.log('hide 7');
          this._loaderRogges.hide();
          this.focus();
        } else {
          console.log('A1');
          this._images[3].enabled = previous3;
          this._images[4].enabled = previous4;
          this._images[6].enabled = previous6;
          console.log('hide 8');
          this._loaderRogges.hide();
          this._router.navigate(['/pm/store/action/' + this.entryPoint + '-' + act]);
        }

      }
      console.log('B1');
      if (this.table != null) {
        this.table.offset = 0;
        this.table.bodyComponent.updateOffsetY(0);
      };
      this._images[3].enabled = previous3;
      this._images[4].enabled = previous4;
      this._images[6].enabled = previous6;
      console.log('hide 9');
      //this._loaderRogges.hide();
    }
    else
      this._loaderRogges.hide();
    /*
    $(function () {
      $('#my-ngx').css('height', (window.innerHeight - 300) + 'px !important');
      $('.datatable-body').css('height', (window.innerHeight - 300) + 'px !important');
    });
    */
    //if (this.entryPoint == 'exit-docs-entry') {
    //  $(function () {
    // $('#my-ngx').css('height', (window.innerHeight - 450) + 'px');
    //$('.datatable-body').css('height', (window.innerHeight - 450) + 'px');
    //  });
    //}
  }

  downAll(row: any) {
    console.log('Down All of %o with tipoBolle %o', row, this.tipoBolle);
    if (this.disableSem == false) {
      if (row['MasterSlave'] == 'S') {
        window.alert('Attenzione: non è possibile fare lo scarico di questo ordine se non selezionando il suo ordine padre');
      }
      else {
        this._loaderRogges.show();
        this.disableSem = true;
        //let str = 'Sei sicuro di voler scaricare l\' intero ordine ' + row['IdDocPrec'] + '?';

        //if (window.confirm(str)) {
        this.url = MySettings.environment.GetDettaglioBolleEntrata;
        this.req = {
          idnegozio: MySettings.environment.idNegozio,
          GUID: this._auth.getGUID(),
          DomainId: this._auth.getDomain(),
          ProfileId: this._auth.getProfile(),
          iddocumento: row['Iddoc'],
          withStock: true
        };
        this._http.post(
          this.url,
          this.req
        )
          .subscribe((res) => {
            this._loaderRogges.hide();
            console.log('dettaglio Ordine %o', res);
            //open dialog download
            //screen.width;
            this.disableSem = false;
            let dialogRef = this._dialog.open(OrderDetailDownloadDialogComponent, {
              height: '90%',
              width: '90%',
              data: { isEditing: true, dati: res, chiudi: true, originalOrder: row, w: Math.round(window.innerWidth * 0.8), idTipoDocumentoUscita: this.idTipoDocumentoUscita }
            });

          });
      }
    }

  }

  openUscitaAddProd(id = undefined) {
    //document.getElementsByTagName('datatable-body')[0].scrollTop = 0;
    if (this.table != null) {
      this.table.offset = 0;
      this.table.bodyComponent.updateOffsetY(0);
    }
    console.log('openUscitaAddProd with id %o', id);
    if (id != undefined) {
      this.infoAggiuntive = true;
      sessionStorage.setItem('uscite_destin', id);
      this.rows = [...[]];
      console.log('pa 3a');
      this.performAction(this._images[0]);
      console.log('pa 3');

    } else {
      sessionStorage.removeItem('uscite_destin');
      //this.rows = [...[]];
    }

    this.header = true;

    this.columns = [];
    this.columns.push({ name: "Id", prop: "Id", flexGrow: 1, type: 'Int32' });
    this.columns.push({ name: "Classe", prop: "CustomClasse", flexGrow: 4 });
    this.columns.push({ name: "Famiglia", prop: "CustomFamiglia", flexGrow: 4 });
    this.columns.push({ name: "Sottofamiglia", prop: "CustomSottofamiglia", flexGrow: 4 });
    this.columns.push({ name: "Codice", prop: "CustomCodice", flexGrow: 4 });
    this.columns.push({ name: "Articolo", prop: "Articolo", flexGrow: 4 });
    this.columns.push({ name: "Confezioni", prop: "Confezioni", flexGrow: 2, type: 'Int32' });

    this.rows = [...this.rows];

  }

  goBack(act) {
    console.log('goBACK entryPoint %o act %o', this.entryPoint, act);
    if (this.entryPoint == 'exit-docs-entry-list') {
      console.log('back in list entrypoint');
      this.entryPoint = 'exit-docs-entry';
    }

    if (this.entryPoint == 'exit-docs-entry') {
      console.log('add button for exit-docs-entry');
      let cpimages = this._images;
      this._images = [];
      for (let a = 0; a < cpimages.length; a = a + 1) {
        if (!(cpimages[a].src == 'lista'))
          this._images.push(cpimages[a]);
      }
      console.log('images now %o', this._images);
      for (let a = 0; a < this._images.length; a = a + 1) {

        this._images[a].enabled = false;
      }
      //remove additional button
    }
    if (this.entryPoint == 'reports') {
      this._location.back();
      return;
    }
    this.br = undefined;
    if (this.insideBolleUscita || this.insideAlerts) {
      console.log('CALLTABLE GOBACK');
      this.callTable(0);
      return;
    }
    this.rows = [];
    this._tableService.rows = [];

    this._router.navigate(['/pm/store/' + act]);
  }

  generateBackReports(id) {
    this.mainId = this.reporequest['RCode'];
    if (this.reporequest['IsRoot'])
      this._router.navigate(['/pm/store/'])
    else
      this._router.navigate(['/pm/store/action/reports/' + this.mainId])
  }

  fetch(cb) {
    console.log("IN FETCH cb %o with url %o & req %o", cb, this.url, this.req);
    if (this.url == undefined) {
      //console.log("Impossibile caricare la tabella.");
      return;
    }
    this.loadingIndicator = true;

    this._http.post(
      this.url,
      this.req
    )
      .subscribe(res => {
        console.log("DATA read for TABLE comp %o entrypoint %o", res, this.entryPoint);

        if (res['esito'] == true) {
          //console.log("DATA read for TABLE %o entrypoint %o", res, this.entryPoint);
          //view only father
          if (this.entryPoint == 'exit-docs-entry' || this.entryPoint == 'entry-docs') {
            let resTemp = [];
            for (let i = 0; i < res['ordini'].length; i++) {
              if (!(res['ordini'][i]['MasterSlave'] == 'S'))
                resTemp.push(res['ordini'][i]);
            }

            res['ordini'] = resTemp;

          }
          //console.log("DATA read 2 for TABLE %o", res);
          let col;
          if (this.entryPoint != 'alerts') col = res['tableMap'].columns;
          this.columns = [];
          if (this.entryPoint == 'orderDetail') {
            this.rifObj = res['resultRiferimentoBolla'];
            this.notObj = res['resultNotaDocumento'];

            this.ia3 = this.rifObj == undefined || this.rifObj.Riferimento == undefined ? "n/d" : this.rifObj.Riferimento;
            this.ia4 = this.notObj == undefined || this.notObj.NotaDocumento == undefined ? 'Nessuna nota inserita' : this.notObj.NotaDocumento;
          }
          if (res['resultRiferimentoBolla'] != undefined) this.io['resultRiferimentoBolla'] = res['resultRiferimentoBolla'];
          if (this.entryPoint != 'orderDetail' && this.entryPoint != 'entry-docs-detail' && this.entryPoint != 'inventoryDetail' && this.entryPoint != 'alerts' && this.entryPoint != 'reports') {
            this.listaCambiFIltrabili = [];
            for (let k of Object.keys(col)) {
              let c = col[k];
              if (c.visible) {
                this.columns.push({
                  prop: k,
                  name: c.caption,
                  format: c.format,
                  type: c.type
                });
                this.listaCambiFIltrabili.push(c.caption);
              }
            };
            console.log('POPULATE LISTA CAMPI FILTRABILI %o', this.listaCambiFIltrabili);
            if (this.entryPoint == 'entry-docs' && this._auth.getDomain() != "1")
              this.columns.push({ name: "Dettaglio Bolla", prop: "__DettaglioBollaEntrata", flexGrow: 3, type: 'Button' });
          } else if (this.entryPoint == 'orderDetail' || this.entryPoint == 'entry-docs-detail' || this.entryPoint == 'exit-docs-add') {
            this.columns.push({ name: "", prop: "Id", flexGrow: 1, type: 'Int32' });

            this.columns.push({ name: "Classe", prop: "CustomClasse", flexGrow: 4 });
            this.columns.push({ name: "Famiglia", prop: "CustomFamiglia", flexGrow: 4 });
            this.columns.push({ name: "Sottofamiglia", prop: "CustomSottofamiglia", flexGrow: 4 });
            this.columns.push({ name: "Codice", prop: "CustomCodice", flexGrow: 4 });
            this.columns.push({ name: "Articolo", prop: "Articolo", flexGrow: 4 });

            this.columns.push({ name: "Costo Lordo", prop: "CostoLordo", flexGrow: 2, type: 'Currency', format: '#0.00' });
            if (this.isAdmin == true)
              this.columns.push({ name: "Imponibile scontato", prop: "CostoNettoScontato", flexGrow: 2, type: 'Currency', format: '#0.00' });

            this.columns.push({ name: "Costo per confezione", prop: "CostoPerConfezione", flexGrow: 2, type: 'Currency', format: '#0.00' });
            this.columns.push({ name: "Confezioni", prop: "Confezioni", flexGrow: 2, type: 'Int32' });
            this.columns.push({ name: "Totale", prop: "Totale", flexGrow: 3, type: 'Currency', format: '#0.00' });
          } else if (this.entryPoint == 'inventoryDetail') {
            this.columns = [];
            this.columns.push({ name: "", prop: "Id", flexGrow: 1, type: 'Int32' });
            this.columns.push({ name: "Articolo", prop: "Articolo", flexGrow: 4 });
            this.columns.push({ name: "Codice", prop: "BarCodeAggiunta", flexGrow: 2, type: 'Int32', editable: 0 });
            this.columns.push({ name: "Confezioni Attese", prop: "QtaAttesa", flexGrow: 2, type: 'Int32', editable: 0 });
            this.columns.push({ name: "Confezioni Contate", prop: "QtaContata", flexGrow: 2, type: 'Int32', editable: 1 });
            //this.columns.push({ name: "Aggiungi Confezioni", prop: "QtaAggiunta", flexGrow: 2, type: 'Int32', editable: 1 });
          } else if (this.entryPoint == 'alerts') {
            this.columns = [];
            this.columns.push({ name: "Codice", prop: "alertCode", flexGrow: 1, type: 'String' });
            this.columns.push({ name: "Messaggio di avvertimento", prop: "alertName", flexGrow: 4, type: 'String' });
            this.columns.push({ name: "Tipo", prop: "alertType", flexGrow: 2, type: 'String' });
            this.columns.push({ name: "Data", prop: "alertTime", flexGrow: 2, type: 'DateTime' });
          }

          if (this.entryPoint == 'orders') {
            this.columns.push({
              prop: "__DettaglioOrdini",
              name: "Dettaglio"
            });
            this.columns.push({
              prop: "__ReSend",
              name: "Reinvia e-mail"
            });
          }

          if (this.entryPoint == 'inventory' && this._auth.getDomain() != "1")
            this.columns.push({
              prop: "__DettaglioInventario",
              name: "Dettaglio"
            });

          if (this.entryPoint == 'inventory') {
            for (let r of res['inventari'])
              if (r['TipoDocumento'].toUpperCase() == 'INVENTARIO PROVVISORIO' || r['TipoDocumento'].toUpperCase() == 'INVENTARIO CONTATI') {
                this._images[0].enabled = false;
                break;
              }
          }

          if (res[this.key] == null || res[this.key] == undefined) {
            this._notifications.error(
              "Errore",
              "Errore nel recupero delle informazioni dal server.",
              MySettings.environment.notificationOptions
            );
            this.loadingIndicator = false;
          }
          else {
            console.log("CB %o resKey %o", cb, res[this.key]);
            cb(res[this.key]);
          }


          if (this.entryPoint == 'exit-docs-entry-list') {
            //add button as forst columns
            console.log('SONDA DUE');
            let colcopy = this.columns;
            this.columns = [];
            this.columns.push({ name: "Scarico Ordine", prop: "_all", format: "", type: "Int32" });
            for (let a = 0; a < colcopy.length; a = a + 1) {
              if (!(colcopy[a].prop == '_all')) {
                this.columns.push(colcopy[a]);
              }
            }
            console.log('Columns %o', this.columns);
            this.subtitle = "Lista documenti da scaricare";
          }

          console.log('C1');
          console.log('hide 10');
          this._loaderRogges.hide();
          console.log("OUT FETCH A %o B %o", this.rows, this.backup);
          if ((this.rows == null || this.rows.length == 0) && (this.backup != null && this.backup.length > 0))
            this.rows = this.backup;
        }
        else {
          this.loadingIndicator = false;
          window.alert('errore nella chiamata al DB\nurl: ' + this.url + '\nerrore :' + res['errorMessage']);
        }
      });

    // $(function () {
    //$('#my-ngx').css('height', (window.innerHeight - 300) + 'px');
    //$('.datatable-body').css('height', (window.innerHeight - 300) + 'px');
    // });
  }

  isCurrency(t, f) {
    return ((t == 'Currency') && f != '' && f !== undefined);
  }

  printBolla(id) {
    this.urlPDF = MySettings.environment.GetHandlerPDF.replace("{0}", "" + MySettings.environment.idNegozio);
    this.urlPDF = this.urlPDF.replace("{2}", this._auth.getDomain() == '1' ? '1' : '0');
    this.urlPDF = this.urlPDF.replace("{1}", "" + id);

    window.open(this.urlPDF);
  }

  openDettaglio(row) {
    if (this.entryPoint == 'orders')
      localStorage.setItem('fId', row['IdcategoriaFornitore']);
    if (this.entryPoint == 'orders' || this.entryPoint == "entry-docs") {
      sessionStorage.setItem('dettaglioOrdine', JSON.stringify(row));
    } else sessionStorage.removeItem('dettaglioOrdine');
    if (row['IdstatoAvanzamento'] != undefined && row['IdstatoAvanzamento'] != 14)
      localStorage.setItem('notbozza', "true");
    else
      localStorage.removeItem('notbozza');
    if (this.entryPoint == 'entry-docs')
      this._router.navigate(['/pm/store/action/entry-docs/' + row['Iddoc']]);
    else if (this.entryPoint == 'inventory') {
      localStorage.setItem('pz', JSON.stringify(row))
      this._router.navigate(['/pm/store/action/inventory/' + row['Iddoc']]);
    } else
      this._router.navigate(['/pm/store/action/order/' + row['Iddoc']]);
  }

  hover(tile) {
    if (this.click != undefined && this.click == tile.id) return;
    if (tile.src.indexOf('_hover') !== -1) {
      tile.src = tile.src.substring(0, tile.src.length - 6);
      tile.srcHover = tile.src + '_hover';
    } else {
      tile.srcHover = tile.srcHover.substring(0, tile.srcHover.length - 6);
      tile.src = tile.srcHover + '_hover';
    }
  }

  filterStatoType = "";
  applyFilter() {
    if (this.backup == undefined) this.backup = this.rows;
    if (this.filterStato.value == "tutti") {
      if (this.filterDataDa != undefined && this.filterDataA != undefined) {
        preR = this.backup.filter(x => new Date(x.DataIns) >= this.filterDataDa && new Date(x.DataIns) <= this.filterDataA);
      } else preR = this.backup;
      this.rows = [...preR];
      return;
    }
    var preR = this.backup.filter(x => x.StatoAvanzamento === this.filterStato.value.toUpperCase());
    if (this.filterDataDa != undefined && this.filterDataA != undefined) {
      preR = preR.filter(x => new Date(x.DataIns) >= this.filterDataDa && new Date(x.DataIns) <= this.filterDataA);
    }
    this.rows = [...preR];
  }

  applyMasterSlaveFilter() {

    console.log('rows %o backup %o', this.rows, this.backup);
    var p = [];
    if (this.rows != null && this.rows.length > 0) {
      if (this.filterMasterSlave.value == "Tutti") {
        if (this.backup != null)
          this.rows = this.backup;
        for (let i = 0; i < this.rows.length; i++) {
          p.push(this.rows[i]);
        }
      }
      else {
        this.backup = this.rows;
        for (let i = 0; i < this.rows.length; i++) {
          if (!(this.rows[i]['MasterSlave'] == 'S'))
            p.push(this.rows[i]);
        }
      }
      //console.log('rows %o p %o', this.rows, p);

      this.rows = [...p];
    }

  }

  getFilterName() {
    var val = +this.filterStatoCustomOpz.value;
    for (let x of this.listaCambiFIltrabiliOpzioni)
      if (x.val == val)
        return x.prop.toLowerCase();
  }

  applyTimeFilter(x = false) {
    this.rows = [];
    for (let a = 0; a < MySettings.environment.TimeValues.length; a = a + 1) {
      if (MySettings.environment.TimeValues[a].viewvalue == this.filterStatoTime.value) {

        if (!(MySettings.environment.TimeValues[a].value == 0)) {
          this.req.DataMax = new Date(Date.now());
          this.req.DataMin = new Date(Date.now() - (MySettings.environment.TimeValues[a].value * 30 * 24 * 60 * 60 * 1000));
        }
        else {
          this.req.DataMax = new Date(Date.now());
          this.req.DataMin = new Date(Date.now() - (36 * 30 * 24 * 60 * 60 * 1000));
        }
        this.req.DataMax.setHours(23);
        this.req.DataMax.setMinutes(59);
        this.req.DataMin.setHours(0);
        this.req.DataMin.setMinutes(0);

        this.req.DataMax = moment.utc({ year: this.req.DataMax.getFullYear(), month: this.req.DataMax.getMonth(), day: this.req.DataMax.getDate(), hour: this.req.DataMax.getHours(), minute: this.req.DataMax.getMinutes(), second: this.req.DataMax.getSeconds(), millisecond: this.req.DataMax.getMilliseconds() }).locale("it");
        this.req.DataMin = moment.utc({ year: this.req.DataMin.getFullYear(), month: this.req.DataMin.getMonth(), day: this.req.DataMin.getDate(), hour: this.req.DataMin.getHours(), minute: this.req.DataMin.getMinutes(), second: this.req.DataMin.getSeconds(), millisecond: this.req.DataMin.getMilliseconds() }).locale("it");

      }
    }
    console.log('filterStatoTime %o req %o', this.filterStatoTime.value, this.req);
    if (this.url != undefined) {
      console.log('FETCH after apply time filter');
      this._loaderRogges.show();
      this.fetch((data) => {
        //this._loaderRogges.hide();
        this.temp = [];
        if (this.entryPoint == 'inventoryDetail') {
          let ind2 = 0;
          for (let r of data) {
            this.temp.push({
              Id: ++ind2,
              Articolo: r.Articolo,
              QtaContata: r.QtaContata,
              QtaAttesa: r.QtaAttesa,
              IdcatGiacenza: r.IdCatGiacenza,
              Idarticolo: r.IdArticolo,
              BarCodeAggiunta: r.Barcode,
              QtaAggiunta: 0
            });
          }
        } else if (this.entryPoint == 'orderDetail' || this.entryPoint == 'entry-docs-detail' || this.entryPoint == 'exit-docs-detail' || this.entryPoint == 'exit-docs-add') {
          var ind = 0;
          for (let r of data) {
            this.temp.push({
              Id: ++ind,
              Articolo: r.Articolo,
              CostoPerConfezione: r.CostoLordoConfezione,
              Confezioni: r.Qta,
              CostoNettoScontato: r.CostoNettoScontato,
              CostoLordo: r.CostoLordo,
              Totale: r.CostoLordoConfezione * r.Qta,
              Idprezzo: r.Idprezzo,
              IdUdm: r.Idudm,
              Idarticolo: r.Idarticolo,
              ScalaUDM: r.ScalaUdm,
              Iddest: r.Iddest,
              Idvend: r.Idvend,

              CustomClasse: r.CategoriaSuperParent,
              CustomFamiglia: r.CategoriaParent,
              CustomSottofamiglia: r.Categoria,
              CustomArticolo: r.DescrizioneEstesa,
              CustomCodice: r.BarCode
            });

            if (this.entryPoint == 'orderDetail') this.tot += r.CostoLordoConfezione * r.Qta;
          }

          this.tot = +(this.tot.toFixed(2));

          if (data.length > 0) {
            this._images[3].enabled = this._images[4].enabled = true;
          }

          if (localStorage.getItem('notbozza')) {
            this._images[2].enabled = false;
            this._images[3].enabled = false;
            this._images[4].enabled = false;
            this._images[0].enabled = false;
            if (this.entryPoint == 'entry-docs-detail')// && (JSON.parse(localStorage.getItem('pz'))['IdstatoAvanzamento'] == 14))
              this._images[4].enabled = true;
          }

          //console.log(data);

        } else {
          this.temp = data;
          for (let r of data) {
            if (this.listaStati.indexOf(r.StatoAvanzamento) == -1)
              this.listaStati.push(r.StatoAvanzamento);
          }
          //this.temp.push(...data);
        }
        var bi = 0;
        if (this.entryPoint == 'bolla-entrata-detail')
          for (let x of this.temp)
            x['NumRiga'] = ++bi;
        this.rows = [...this.temp]; // Refresh the data
        this.loadingIndicator = false;


        if (this.entryPoint == 'orders') {
          this.backup = this.rows;
          this.rows = [];
          if (this.filterMasterSlave.value == 'Solo padri') {
            for (let i = 0; i < this.backup.length; i++) {
              if (!(this.backup[i]['MasterSlave'] == 'S'))
                this.rows.push(data[i]);
            }
          }
        }

        //sergiosick
        var tre = 1;
        if (this.rows.length > 0 && this.rows[0]['Id'] == undefined) {
          for (let x of this.rows) x['Id'] = tre++;
          this.rows = [...this.rows];
        }
      });
    }
  }

  applySecondFilter(x = false) {
    console.log('Apply SECOND FILTER %o', x);
    if (this._input3 != undefined) this._input3.nativeElement.value = "";
    if (x) {
      var val = +this.filterStatoCustomOpz.value;
      this.numericofiltro = 0;
      this.numericofiltro2 = 0;
      this.stringafiltro = "";
      if (this.backup != undefined) this.rows = [...this.backup];
      return;
    } else {
      this.numericofiltro = 0;
      this.numericofiltro2 = 0;
      this.stringafiltro = "";
      if (this.backup != undefined) this.rows = [...this.backup];

      if (this.filterStatoCustom.value == "tutticampi") {
        return;
      }
      this.applied = true;
      var type = "";
      for (let c of this.columns)
        if (c.name.toLowerCase() == this.filterStatoCustom.value.toLowerCase()) {
          type = c.type;
          this.filterStatoType = type;
          break;
        }
      if (this.filterStatoCustom.value.toLowerCase() == "stato avanzamento") { this.populateFilters([9]); return; }
      if (type == "Currency" || type == "Int32" || type == "Decimal" || type == "Float") { this.populateFilters([3, 1, 4, 5]); }
      else if (type == "DateTime") { this.populateFilters([3, 1, 4, 5]); }
      else this.populateFilters([1, 2]);
    }
  }

  filtroGranati(c, stringa = false) {
    console.log('FILTRO GRANATI c %o stringa %o filterStatoCustom %o', c, stringa, this.filterStatoCustom);
    if (this.backup == undefined) this.backup = this.rows;
    this.rows = this.backup;
    if (c.target.value == "") {
      this.numericofiltro = 0;
      this.numericofiltro2 = 0;
      this.stringafiltro = "";
      if (this.backup != undefined) this.rows = [...this.backup];
      return;
    }
    var val = +c.target.value;
    var xxx = "";
    for (let c of this.columns) if (c.name.toLowerCase() == this.filterStatoCustom.value.toLowerCase()) { xxx = c.prop; break; }
    var value = +this.filterStatoCustomOpz.value;
    if (value == 1) {
      // ==
      if (stringa)
        var preR = this.backup.filter(x => x[xxx] != undefined && x[xxx].toLowerCase() == this.stringafiltro.toLowerCase());
      else
        var preR = this.backup.filter(x => x[xxx] == val);
      console.log("== " + val);
    } else if (value == 3) {
      // interv
      var val1 = this.numericofiltro; var val2 = this.numericofiltro2;
      console.log("intervallo tra " + this.numericofiltro + " e " + this.numericofiltro2)
      var preR = this.backup.filter(x => x[xxx] != undefined && x[xxx] >= val1 && x[xxx] <= val2);
    } else if (value == 4) {
      // >=
      var preR = this.backup.filter(x => x[xxx] >= val);
      console.log(">= " + val);
    } else if (stringa && value == 2) {
      // like
      var preR = this.backup.filter(x => x[xxx] != undefined && x[xxx].toLowerCase().indexOf(this.stringafiltro.toLowerCase()) > -1);
      console.log("like " + val);
    } else if (value != 9) {
      // <=
      console.log("<= " + val);
      var preR = this.backup.filter(x => x[xxx] != undefined && x[xxx] <= val);
    }
    this.rows = [...preR];
  }

  populateFilters(arr) {
    console.log('Populate Filters %o', arr);
    var a = [];
    for (let x of arr)
      if (x == 1) a.push({ val: x, prop: "Uguale" });
      else if (x == 2) a.push({ val: x, prop: "Contiene" });
      else if (x == 3) a.push({ val: x, prop: "Intervallo valori" });
      else if (x == 4) a.push({ val: x, prop: "Maggiore uguale" });
      else if (x == 9) { var ind = 9; for (var st of this.listaStati) a.push({ val: x++, prop: st }); }
      else a.push({ val: x, prop: "Minore uguale" });
    this.listaCambiFIltrabiliOpzioni = a;
  }

  clickMe(tile) {
    console.log('CLICKME TILE %o', tile);
    this.click = tile.id;
    if (this.oldTile != undefined)
      this.hover(this.oldTile);
    this.oldTile = tile;

    this.br = undefined;
    this.myprop = undefined;

    if (tile.tile == "ORDINE CROSS")
      this._router.navigate(['/pm/store/action/order/fornitore/cross']);

    this.entryPoint = 'orders-add-' + tile.tile.toLowerCase();
    console.log('CALLTABLE CLICKME');
    this.callTable();
  }


  onClick(clicked) {
    //console.log('ON CLICK with goOn %o index %o', clicked, clicked['row']);
    if (this.goOn == true) {
      if (clicked.type == 'click' && this.entryPoint == 'orders' && clicked['cellIndex'] == 0) {

        //search for info
        if (clicked['row']['MasterSlave'] == 'S') {
          this._loaderRogges.show();
          this.idBridge = clicked['row']['Iddoc'];
          let self = this;
          this._http.post(
            MySettings.environment['GetDocumentiMasterSlave'],
            {
              idnegozio: MySettings.environment.idNegozio,
              Iddocumento: clicked['row']['Iddoc'],
              GUID: this._auth.getGUID(),
              DomainId: this._auth.getDomain(),
              ProfileId: this._auth.getProfile(),
              DomainContext: MySettings.environment.MyContext,
            }
          )
            .subscribe((res) => {
              this._loaderRogges.hide();
              if (res['esito'] == true) {
                let msg = 'selezionato ordine figlio n: ' + self.idBridge + '\nordine padre n: ' + res['idDocMaster'];
                window.alert(msg);
              }
              else
                window.alert('errore nel reperimento dati ordini padre/figlio :' + res['errorMessage']);
            });
        }
        if (clicked['row']['MasterSlave'] == 'M') {


          this._loaderRogges.show();
          this._http.post(
            MySettings.environment['GetDocumentiMasterSlave'],
            {
              Iddocumento: clicked['row']['Iddoc'],
              idnegozio: MySettings.environment.idNegozio,
              GUID: this._auth.getGUID(),
              DomainId: this._auth.getDomain(),
              ProfileId: this._auth.getProfile(),
              DomainContext: MySettings.environment.MyContext,
            }
          )
            .subscribe((res) => {
              console.log('response detail MASTER documento %o', res);
              this._loaderRogges.hide();
              if (res['esito'] == true) {
                let msg = 'selezionato ordine padre n: ' + res['idDocMaster'] + '\n';
                if (res['listIddocumentiSlave'].length == 1) {
                  msg = msg + 'ordine figlio n: ' + res['listIddocumentiSlave'][0];
                }
                else {
                  for (let ii = 0; ii < res['listIddocumentiSlave'].length; ii++) {
                    msg = 'ordine figlio n: ' + res['listIddocumentiSlave'][ii] + '\n';
                  }
                }

                window.alert(msg);

              }
              else
                window.alert('errore nel reperimento dati ordini padre/figlio: ' + res['errorMessage']);
            });
        }

      }

      if (this.doubleClickable && clicked.type == 'dblclick') {
        this.selected = [];
        return;
      }
      if (clicked.type == 'click' && this.clickable == false && this.entryPoint == 'exit-docs-entry') {
        this._images[5].enabled = true;
        this.id_print = clicked.row['Iddoc'];
        return;
      }
      if (clicked.type != 'click' || this.clickable == undefined || !this.clickable) return;
      if (this.entryPoint == 'orders-add' && +this._auth.getDomain() == 1) {
        localStorage.setItem('fName', clicked.row['Filiale']);
        localStorage.setItem('filialeId', clicked.row['Idfiliale']);
        if (this._auth.getUser()['ruolo'] == "Ufficio acquisti" && sessionStorage.getItem('dup') != undefined) {
          var x = sessionStorage.getItem('dupInfo');
          sessionStorage.removeItem('dupInfo');
          this._router.navigate(['/pm/store/action/order/' + x]);
        }
        else
          this._router.navigate(['/pm/store/action/orders-add-ad']);
        return;
      }
      localStorage.setItem('fName', clicked.row['Fornitore']);

      if (this.entryPoint == 'entry-docs-add') {
        this._router.navigate(['/pm/store/action/order/entry-docs/' + clicked.row['Iddoc']]);
      } else if (this.entryPoint == 'exit-docs-entry') {
        if (clicked.row['Idfornitore'] != undefined) {
          sessionStorage.setItem('uscite_r', JSON.stringify(clicked.row));
          this.subtitle = this.subtitle + " - " + clicked.row['Fornitore'];
          this.openUscitaAddProd(clicked.row['Idfornitore']);
        }
      } else if (this.insideBolleUscita) {
        sessionStorage.setItem('uscite_dest', clicked.row.Idfornitore);
        sessionStorage.setItem('uscite_name', clicked.row.Filiale);

        this._router.navigate(['/pm/store/action/exit-docs-add']);
      } else if (clicked.row.IdF != undefined) {
        localStorage.setItem('fId', clicked.row.IdF);
        localStorage.setItem('sottoscortaCF', clicked.row.IdCF);
        this._router.navigate(['/pm/store/action/order/fornitore/' + clicked.row['IdF']]);
      } else {
        localStorage.setItem('fId', this.click);
        this.search = undefined; this.myprop = null; this.br = undefined;
        localStorage.setItem('idFilialeNotNull', clicked.row['Idfiliale']);
        this._router.navigate(['/pm/store/action/order/fornitore/' + clicked.row['Idfornitore']]);
      }
    }
  }


  updateValue(event, row, cell, rowIndex, out = false) {
    var del = null;

    if (!(out || (event.charCode != undefined && event.charCode == 13))) return;
    for (let r of this.rows)
      if (rowIndex == r['Id']) {
        if (event.charCode != undefined && event.charCode == 13) this.editing[rowIndex + '-' + cell] = false;
        if (event.target.value == undefined || event.target.value == "") { event.target.value = "1"; this.focus(); }
        if (+event.target.value < 1 && this.entryPoint != 'inventory-add' && this.entryPoint != 'inventoryDetail' && this.entryPoint != 'entry-docs-detail') {
          del = r;
          break;
        }
        //console.log("Found: %o", r);
        r[cell] = +event.target.value;
        r['Totale'] = +event.target.value * r['CostoPerConfezione'];

        //ar add || this.entryPoint == 'exit-docs-entry'
        if (event.charCode != undefined && event.charCode == 13 && (this.entryPoint == 'inventoryDetail' || this.entryPoint == 'flDetail' || this.entryPoint == 'inventory-add' || this.entryPoint == 'orderDetail' || this.entryPoint == 'exit-docs-entry')) {
          this.search = undefined;
          this.focusLente(cell);
          console.log('pa 4a');
          this.performAction(this._images[0]);
          console.log('pa 4');
        }

        break;
      }

    if (del != null) {
      var ind = 0;
      for (let r of this.rows) if (r != row) ind++; else break;
      this.rows.splice(ind, 1);
    }
    if (out) this.editing[rowIndex + '-' + cell] = false;
    this.rows = [...this.rows];
  }


  updateValue2(event, cell, rowIndex, c = undefined) {
    var indice = 0;
    var vec = rowIndex;
    for (let r of this.rows)
      if (rowIndex != r['Id'])
        indice++;
      else
        break;

    rowIndex = indice;

    if (c != undefined && cell != 'QtaAggiunta') this.editing[rowIndex + '-' + cell] = false;
    if (+event.target.value < 0) {
      event.target.value = 0;
    }
    this.updated2 = true;
    if (cell == 'QtaAggiunta') {
      if (event.charCode != 13 && c == undefined) return;
      this.rows[rowIndex][cell] = 0;
      this.rows[rowIndex]['QtaContata'] = +this.rows[rowIndex]['QtaContata'] + +event.target.value;
      this.editing[rowIndex + '-QtaAggiunta'] = false;
      this.editing[vec + '-QtaAggiunta'] = false;
      this.rows[rowIndex]['QtaAggiunta'] = 0;
    }
    else {
      let inde = 0;
      this.rows[rowIndex][cell] = event.target.value;
    }
    this.rows = [...this.rows];
  }

  deleteRow(rowIndex) {

    if (this.entryPoint == 'orderDetail' || this.entryPoint == 'entry-docs-detail' || this.entryPoint == 'inventory-add' || this.entryPoint == 'inventoryDetail' || this.entryPoint == 'exit-docs-entry') {

      this.deleteRowTableNormal(rowIndex);

      this.selected = [];
      this._images[1].enabled = false;
      this._images[2].enabled = false;
      if (this.rows.length == 0) {
        this._images[3].enabled = false;
        this._images[4].enabled = false;
      } else {
        this._images[3].enabled = true;
        this._images[4].enabled = true;
      }

    }
    else {
      this._tableService.rows.splice(rowIndex, 1);

      if (!this._tableService.rows)
        this._tableService.rows = [];

      this.rows = [...this._tableService.rows];
      this.temp = [...this.rows];

      this.selected = [];
      this._images[1].enabled = false;
      this._images[2].enabled = false;
      if (this.rows.length == 0) {
        this._images[3].enabled = false;
        this._images[4].enabled = false;
      } else {
        this._images[3].enabled = true;
        this._images[4].enabled = true;
      }

    }
    if (this.entryPoint == 'entry-docs-detail')
      this._images[3].enabled = false;
  }

  deleteRowTableNormal(rowIndex) {
    this.rows.splice(rowIndex, 1);
    if (!this.rows)
      this.rows = [];
    this.rows = [...this.rows];
    this.temp = [...this.rows];
    this.selected = [];
    this._images[1].enabled = false;
    this._images[2].enabled = false;
    this._images[3].enabled = false;
    this._images[4].enabled = false;
  }

  onC(e) {
    console.log('onC %o %o', e, e['cellIndex']);
    /*
     for (var i = 0; i < selected.length; i++) {
       if (selected[i]['MasterSlave'] == 'S') {
         console.log('selection for detail  documento %o sel %o', selected[i], sel);
         this._images[5].enabled = true;
         this._images[6].enabled = false;
         check = false;
         if (selected.length == 1) {
           this._loaderRogges.show();
           this.idBridge = selected[i]['Iddoc'];
           let self = this;
           this._http.post(
             MySettings.environment['GetDocumentiMasterSlave'],
             {
               idnegozio: MySettings.environment.idNegozio,
               Iddocumento: selected[i]['Iddoc'],
               GUID: this._auth.getGUID(),
               DomainId: this._auth.getDomain(),
               ProfileId: this._auth.getProfile(),
               DomainContext: MySettings.environment.MyContext,
             }
           )
             .subscribe((res) => {
               this._loaderRogges.hide();
               if (res['esito'] == true) {
                 let msg = 'selezionato ordine figlio n: ' + self.idBridge + '\nordine padre n: ' + res['idDocMaster'];
                 window.alert(msg);
               }
               else
                 window.alert('errore nel reperimento dati ordini padre/figlio :' + res['errorMessage']);
             });
         }
       }
       if (selected[i]['MasterSlave'] == 'M') {
         console.log('selection for detail  documento %o sel %o', selected[i], sel);
         if (selected.length == 1) {
           this._loaderRogges.show();
           this._http.post(
             MySettings.environment['GetDocumentiMasterSlave'],
             {
               Iddocumento: selected[i]['Iddoc'],
               idnegozio: MySettings.environment.idNegozio,
               GUID: this._auth.getGUID(),
               DomainId: this._auth.getDomain(),
               ProfileId: this._auth.getProfile(),
               DomainContext: MySettings.environment.MyContext,
             }
           )
             .subscribe((res) => {
               console.log('response detail MASTER documento %o', res);
               this._loaderRogges.hide();
               if (res['esito'] == true) {
                 let msg = 'selezionato ordine padre n: ' + res['idDocMaster'] + '\n';
                 if (res['listIddocumentiSlave'].length == 1) {
                   msg = msg + 'ordine figlio n: ' + res['listIddocumentiSlave'][0];
                 }
                 else {
                   for (let ii = 0; ii < res['listIddocumentiSlave'].length; ii++) {
                     msg = 'ordine figlio n: ' + res['listIddocumentiSlave'][ii] + '\n';
                   }
                 }
 
                 window.alert(msg);
 
               }
               else
                 window.alert('errore nel reperimento dati ordini padre/figlio: ' + res['errorMessage']);
             });
         }
       }
     }
 */

  }

  onSelect(sel) {

    this.goOn = true;
    var go = true;

    let selected = [];
    if (sel != null)
      selected = sel['selected'];
    console.log('ON SELECT selected %o entrypoint %o', selected, this.entryPoint);

    if (!(selected == null) && selected.length > 0) {
      for (var i = 0; i < selected.length; i = i + 1) {
        if (selected[i]['MasterSlave'] == 'S')
          go = false;
      }
    }
    /*
    if (go == true && this.entryPoint == 'orders' && !(selected == null) && selected.length > 1) {
      window.alert('Attenzione: è possibile selezionare solo un ordine.');
      this.goOn = false;
      go = false;
    }
    */
    if (this.entryPoint == 'orders' && !(selected == null) && selected.length > 1) {
      window.alert('Attenzione: è possibile selezionare solo un ordine.');
      this.goOn = false;
      go = false;
      this.selected = [];
    }
    if (go == false && (this.entryPoint == 'entry-docs-add' || this.entryPoint == 'exit-docs-add')) {
      window.alert('Attenzione: hai selezionato un ordine secondario/slave.\nGli ordini secondari vengono caricati selezionando il rispettivo ordine primario/master');
      this.goOn = false;
    }
    else {

      console.log('OFFSET %o on this.table.bodyComponent %o', this.table.offset, this.table.bodyComponent);
      //document.getElementsByTagName('datatable-body')[0].scrollTop = 0;
      //this.tableOffset = 0;
      //this.table.offset = 0;
      //this.table.bodyComponent.updateOffsetY(0);
      console.log('OFFSET AFTER %o on this.table.bodyComponent %o', this.table.offset, this.table.bodyComponent);
      //this.table.offset = 0;
      //this.table.bodyComponent.updateOffsetY(0);


      console.log('SELECTED original %o deducted %o on this.entryPoint %o images %o', sel, selected, this.entryPoint, this._images);
      if (selected != undefined && selected[selected.length - 1] != undefined) this.selectedEdit = selected[selected.length - 1];
      if (this.entryPoint == 'orders-sottoscorta') {
        this.idfo = selected[selected.length - 1]['IdF'];
        return;
      }
      if (this.entryPoint == 'orderDetail' && this.io != undefined && this.io['StatoAvanzamento'] != 'BOZZA') { this._images[1].enabled = false; return; }
      if (this.entryPoint == 'inventory') localStorage.setItem('pz', JSON.stringify(selected[selected.length - 1]));
      if (this.entryPoint == 'entry-docs-add' || this.entryPoint == 'exit-docs-add') {


        localStorage.setItem('p', JSON.stringify(selected));
      }

      if (this.selectable == undefined || !this.selectable) { this.selected = []; return; }
      if (this.entryPoint == 'exit-docs-entry') {
        //this.performAction(this._images[0]);
        //this._images[3].enabled = true;
        //console.log('exit-docs-entry with %o',this._images);
        //this._images[2].enabled = false;

      } else
        if (this.entryPoint == 'flDetail') {
          if (this._images[1].visibile) this._images[1].enabled = true;
          if (this._images[2].visibile) this._images[2].enabled = true;
        } else if (this.entryPoint == 'inventory') {
          //if (this.entryPoint == 'inventoryDetail' && selected[selected.length - 1]['TipoDocumento'] != "INVENTARIO PROVVISORIO") this._images[0].enabled = false;
          this._images[1].enabled = this._images[3].enabled = this._images[4].enabled = this._images[2].enabled = selected[selected.length - 1]['TipoDocumento'] == "INVENTARIO PROVVISORIO" || selected[selected.length - 1]['TipoDocumento'] == "INVENTARIO CONTATI";
          this._images[5].enabled = true;

          this._images[1].enabled = this._images[3].enabled = this._images[4].enabled = false;

        } else if (this.entryPoint == 'inventoryDetail') {
          this._images[1].enabled = this._images[3].enabled = this._images[4].enabled = this._images[2].enabled = this.rows.length > 0 && ((JSON.parse(localStorage.getItem('pz'))['TipoDocumento']) == "INVENTARIO PROVVISORIO" || (JSON.parse(localStorage.getItem('pz'))['TipoDocumento']) == "INVENTARIO CONTATI");
        } else if (this.entryPoint == 'orderDetail') {
          var x;
          x = (selected == undefined || selected[selected.length - 1]['IdstatoAvanzamento'] == undefined) ? (JSON.parse(sessionStorage.getItem('dettaglioOrdine')))['IdstatoAvanzamento'] : selected[selected.length - 1]['IdstatoAvanzamento'];
          this._images[1].enabled = false;
          //this._images[1].enabled = this._images[3].enabled = this._images[4].enabled = this._images[2].enabled = (x == 14);
          this._images[3].enabled = this._images[4].enabled = this._images[2].enabled = (x == 14);
          this._images[5].enabled = true;
        } else if (this.entryPoint == 'orders' || this.entryPoint == 'entry-docs' || this.entryPoint == 'exit-docs') {
          let check = true;

          for (var i = 0; i < selected.length; i++) {
            if (selected[i]['MasterSlave'] == 'S') {
              console.log('selection for detail  documento %o sel %o', selected[i], sel);
              this._images[5].enabled = true;
              this._images[6].enabled = false;
              check = false;
              /*
              if (selected.length == 1) {
                this._loaderRogges.show();
                this.idBridge = selected[i]['Iddoc'];
                let self = this;
                this._http.post(
                  MySettings.environment['GetDocumentiMasterSlave'],
                  {
                    idnegozio: MySettings.environment.idNegozio,
                    Iddocumento: selected[i]['Iddoc'],
                    GUID: this._auth.getGUID(),
                    DomainId: this._auth.getDomain(),
                    ProfileId: this._auth.getProfile(),
                    DomainContext: MySettings.environment.MyContext,
                  }
                )
                  .subscribe((res) => {
                    this._loaderRogges.hide();
                    if (res['esito'] == true) {
                      let msg = 'selezionato ordine figlio n: ' + self.idBridge + '\nordine padre n: ' + res['idDocMaster'];
                      window.alert(msg);
                    }
                    else
                      window.alert('errore nel reperimento dati ordini padre/figlio :' + res['errorMessage']);
                  });
              }
              */
            }
            if (selected[i]['MasterSlave'] == 'M') {
              console.log('selection for detail  documento %o sel %o', selected[i], sel);
              /*
              if (selected.length == 1) {
                this._loaderRogges.show();
                this._http.post(
                  MySettings.environment['GetDocumentiMasterSlave'],
                  {
                    Iddocumento: selected[i]['Iddoc'],
                    idnegozio: MySettings.environment.idNegozio,
                    GUID: this._auth.getGUID(),
                    DomainId: this._auth.getDomain(),
                    ProfileId: this._auth.getProfile(),
                    DomainContext: MySettings.environment.MyContext,
                  }
                )
                  .subscribe((res) => {
                    console.log('response detail MASTER documento %o', res);
                    this._loaderRogges.hide();
                    if (res['esito'] == true) {
                      let msg = 'selezionato ordine padre n: ' + res['idDocMaster'] + '\n';
                      if (res['listIddocumentiSlave'].length == 1) {
                        msg = msg + 'ordine figlio n: ' + res['listIddocumentiSlave'][0];
                      }
                      else {
                        for (let ii = 0; ii < res['listIddocumentiSlave'].length; ii++) {
                          msg = 'ordine figlio n: ' + res['listIddocumentiSlave'][ii] + '\n';
                        }
                      }

                      window.alert(msg);

                    }
                    else
                      window.alert('errore nel reperimento dati ordini padre/figlio: ' + res['errorMessage']);
                  });
              }
              */
            }
          }

          if (check) {

            console.log('selected %o', selected);
            this._images[2].enabled = (selected[selected.length - 1]['IdstatoAvanzamento'] == 14 || selected[selected.length - 1]['IdstatoAvanzamento'] == 15);
            this._images[5].enabled = true; //print
            if (this.entryPoint == 'orders') {
              if (this.isAdmin == true)
                this._images[6].enabled = true;
              else
                this._images[6].enabled = false;
            }
            if (selected != null && selected.length > 0 && selected[selected.length - 1]['IdstatoAvanzamento'] != 14)
              localStorage.setItem('notbozza', "true");
            else
              localStorage.removeItem('notbozza');
            this.urlPDF = MySettings.environment.GetHandlerPDF.replace("{0}", "" + MySettings.environment.idNegozio);
            this.urlPDF = this.urlPDF.replace("{2}", this._auth.getDomain() == '1' ? '1' : '0');
          }
          else {
            this._images[2].enabled = false; //pulsante delete
          }
        } else if (this.entryPoint == 'inventory-add') {
          this._images[2].enabled = true;
          this._images[5].enabled = true;
        } else if (this.entryPoint == 'alerts') {
          if (selected[selected.length - 1].alertData != undefined && selected[selected.length - 1].alertData.length > 0) {

            this.rows = [...[]];
            this.columns = [...[]];

            var col = selected[selected.length - 1].tableMap.columns;
            for (let k of Object.keys(col)) {
              let c = col[k];
              if (c.visible)
                this.columns.push({
                  prop: k,
                  name: c.caption,
                  format: c.format,
                  type: c.type
                });
            };
            var rows = [];
            for (let r of selected[selected.length - 1].alertData)
              rows.push(r);
            this.rows = [...rows];

            this.back = "action/alerts";
            this.backCaption = "Torna agli avvisi";

            this.subtitle = "Avviso - " + selected[selected.length - 1]['alertName'];

            this.insideAlerts = true;

            // fix altezza tabella
            this.calculateTable(true);
            if (this.table != null) {
              this.tableOffset = 0;
              this.table.offset = 0;
              this.table.bodyComponent.updateOffsetY(0);
            }
          }

          return;
        }

      if (this.entryPoint == "exit-docs-entry") {
        //this._images[2].enabled = true;
        this._images[5].enabled = true;
        //this._tableService.rows = [];
        //this.performAction(this._images[0]); 
        return;
      }
      if (this.selected != null && this.selected.length > 0 && this.entryPoint != 'alerts' && selected != null) {
        this.selected.splice(0, this.selected[this.selected.length - 1].length);
        this.selected.push(...selected);
      }

      if (this.entryPoint != 'orders' && this.entryPoint != 'flDetail' && this.entryPoint != 'orderDetail' && this._auth.getDomain() == "1") {
        this._images[0].enabled = false;
        this._images[1].enabled = false;
        this._images[2].enabled = false;
        this._images[3].enabled = false;
        this._images[4].enabled = false;
      }

    }

  }

  removeOthers(name) {
    this.editing = [{}];
    this.editing[name] = true;
  }

  onActivate(event) {
    //console.log('Activate Event', event);
  }

  backupRows() {
    if (this.br == undefined)
      this.br = this.rows;
  }

  backupRowsReport(ind) {
    if (this.brR == undefined)
      this.brR = this._repoResp.Queries[ind].Table;
  }

  updateFilter(event, search) {
    //console.log('updateFilter %o',this.br);
    if (event.target.value == "" && this.br != null) { this.rows = [...this.br]; return; }
    const val = (event.target.value + "").toLowerCase();
    const temp = this.rows.filter(function (d) {
      return (d[search] + "").toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.rows = temp;
    //this.table.offset = 0;
  }

  updateFilterReport(event, search, ind) {
    let rows = this._repoResp.Queries[ind].Table;
    if (event.target.value == "") { this._repoResp.Queries[ind].Table = [...this.brR]; return; }
    const val = (event.target.value + "").toLowerCase();
    const tempR = rows.filter(function (d) {
      return (d[search] + "").toLowerCase().indexOf(val) !== -1 || !val;
    });

    this._repoResp.Queries[ind].Table = tempR;
    //this.table.offset = 0;
  }


  calculateWidthColumn(col): number {
    //console.log('calculateWidthColumn %o',col);
    if (col == null || col.length == 0) {
      return (100);
    }
    if (col == 'Scarico Ordine') {
      return (130);
    }
    var x = false;
    if (this.ca.indexOf(col) != -1) return this.ca2[this.ca.indexOf(col)]; else { this.ca.push(col); x = true; }
    document.getElementById('columnAdapter').innerHTML = col.toUpperCase();
    var d = getComputedStyle(document.getElementById('columnAdapter')).width;
    var dim = parseInt((d + "").substring(0, (d + "").length - 2));
    ////console.log(dim);
    dim = 55 + dim;
    ////console.log(col + ": " + dim);
    dim = (col == 'MESE' || col == 'ANNO') ? dim + 30 : dim;
    if (x) this.ca2.push(dim);
    return dim;
  }

  calculateTable(force = false) {
    return;

    /* AR */
    //$('#my-ngx').css('height', (window.innerHeight - 450) + 'px');

    /*

    if (this.expanded) return { "max-height": "100%", "height": "100%" };

    var mh = window.innerHeight - (document.getElementById('my-ngx').getBoundingClientRect()).top - 60 - (document.getElementById('infoAggiuntiveOrdine') != undefined ? document.getElementById('infoAggiuntiveOrdine').offsetHeight : 0) + "px";
    mh = mh.replace(' ', '');
    console.log('calculateTable for %o', mh);

    if (this.entryPoint == 'exit-docs-entry')
      return {
        "margin-top": "-48px",
        "max-height": mh,
        "height": mh
      }

    //$('.datatable-scroll').css('height', '100px!important');
    //console.log('calculate table %o entryPoint %o', mh, this.entryPoint);

    return { "max-height": mh, "height": mh };
    */

  }

  focusOutFunction(e) {
    //console.log(e);
  }

  expand(x) {
    this.expanded = x;
    $(function () {
      if (x) {
        $(".textarea").animate({ height: "80px" }, 500);
        $("#infoAggiuntiveOrdine,#infoOrdine").animate({ height: "96px" }, 500);
      } else {
        $(".textarea").animate({ height: "17px" }, 500);
        $("#infoAggiuntiveOrdine,#infoOrdine").animate({ height: (document.getElementById('infoOrdine') == undefined ? '28px' : "38px") }, 500);
      }
    })
  }

  reSend(row) {
    let data = {};
    data['stato'] = 'resend';
    data['title'] = "Conferma";
    data['desc'] = "Confermare il reinvio della e-mail relativa all'ordine <b>#" + row['Iddoc'] + "</b>?";
    data['options'] = [{ caption: 'annulla', action: 'false' }, { caption: 'sì', action: 'true' }];
    let dialogRef = this._dialog.open(YesNoDialogComponent, {
      width: 'auto',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'true') {
        this._http.post(
          MySettings.environment['ResendEmailOrdine'],
          {
            idnegozio: MySettings.environment.idNegozio,
            GUID: this._auth.getGUID(),
            DomainId: this._auth.getDomain(),
            ProfileId: this._auth.getProfile(),
            "mittente": "",
            "IdDoc": row['Iddoc']
          }
        )
          .subscribe((res) => {
            if (res['esito'] == true) {
              let data = {};
              data['stato'] = 'resend_msg';
              data['title'] = res['esito'] ? "Conferma invio" : "Errore nell'invio";
              data['desc'] = res['rispost'];
              data['options'] = [{ caption: 'chiudi', action: 'false' }, { caption: 'ok', action: 'true' }];
              let dialogRef2 = this._dialog.open(YesNoDialogComponent, {
                width: '875px',
                data: data
              });
            }
          });
      }
    });
  }

}
