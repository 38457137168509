import { MatDialog } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { ClinicheDatabase } from '../../entities/datibase/clinichedatabase-model';
import { AuthGuardService } from '../../services';
import { ClinichedatabaseService } from '../../services/clinichedatabase.service';
import { DBDialogClinicaFormComponent } from '../dialog/db-dialog-clinica-form.component';
import { DBDialogClinicaImportComponent } from '../dialog/db-dialog-clinica-import.component';
import { FormHeaderComponent, ICustomButton, IFormHeaderSearch, FormHeaderSearchEventsEnum } from '../../shared/form-header/form-header.component';

@Component({
  selector: 'app-clinichedatabase',
  templateUrl: './clinichedatabase.component.html',
  styleUrls: ['./clinichedatabase.component.css']
})
export class ClinichedatabaseComponent implements OnInit {
  columns: any[] = [];
  rows: ClinicheDatabase[] = [];
  currentRow: any;
  rowsBck: ClinicheDatabase[] = [];
  role: string = '';
  dm: string = '';
  usr: string = '';
  isAdmin: boolean = false;
  isCA: boolean = false;
  isDC: boolean = false;
  showb: boolean = false;
  isNew: boolean = false;
  customButtons: ICustomButton[] = [];
  limitRows: number;
  heightMainList: number;

  constructor(private auth: AuthGuardService, public dbService: ClinichedatabaseService, private dialog: MatDialog ) {

  }

  onEdit() {
    this.isNew = false;
    this.openDialogCDForm(this.currentRow);
  }

  onNew() {
    console.log('ONNEW')
    this.isNew = true;
    let nClinic = new ClinicheDatabase();
    nClinic.code_c = null;
    nClinic.legalentity = null;
    nClinic.nome = null;
    nClinic.porta_servizio_dentalcash = null;

    nClinic.indirizzo = null;
    nClinic.sede_legale = null;
    nClinic.partita_iva = null;
    nClinic.ammnistratore = null;

    nClinic.area_manager = null;
    nClinic.distretto = null;
    nClinic.riuniti = 0;
    nClinic.direttore_sanitario = null;

    nClinic.cluster = null;
    nClinic.regione = null;
    nClinic.mese_apertura = null;
    nClinic.data_apertura = null;
    nClinic.data_chiusura = null;
    nClinic.brand = null;
    nClinic.vintage = null;
    nClinic.provincia = null;
    nClinic.mall_street = null;

    nClinic.latitudine = null;
    nClinic.longitudine = null;
    nClinic.login_areamanager = null;
    nClinic.data_acquisizione = null;

    nClinic.email_clinic_manager = null;
    nClinic.login_districtmanager = null;
    nClinic.esclusione_followme = null;
    nClinic.email_affittuario = null;

    nClinic.data_prima_fattura = null;
    nClinic.company_navision = null;
    nClinic.data_inizio_dentalcash = null;
    nClinic.indicazioni = null;
    nClinic.email = null;
    nClinic.tel = null;
    nClinic.fax = null;
    nClinic.descrizione = null;
    nClinic.ip_privato = null;
    nClinic.Master_Host = null;
    nClinic.ex_listino = null;
    nClinic.iban = null;
    nClinic.codice_fiscale = null;
    nClinic.cbm = null;
    nClinic.login_cbm = null;
    this.openDialogCDForm(nClinic);
  }

  onSearch(searchFilter: IFormHeaderSearch) {

    this.showb = true;
    let filterValues = searchFilter.filter.split(" ").filter(a => !!a);
    console.log("onSearch %o filterValue %o", searchFilter, filterValues);

    this.rows = this.rowsBck.filter((item: ClinicheDatabase) => {
      for (let key in item) {
        //console.log('Key %o item %o', key, item[key]);
        if (!!item[key]) {

          if (key == 'code_c' || key == 'nome' || key == 'legalentity' || key == 'partita_iva' || key == 'amministratore' || key == 'amministratore dp' || key == 'area_manager' || key == 'direttore_sanitario' || key == 'login_areamanager' || key == 'brand') {
            if (item[key].toString().toLowerCase().indexOf(searchFilter.filter) >= 0)
              return true;
          }

        }
      }
      return false;
    });
    this.showb = false;

  }

  openDialogCDForm(clinica: ClinicheDatabase) {
    console.log("openDialogCDForm");

    let dialogRef = this.dialog.open(DBDialogClinicaFormComponent, {
      width: "90%",
      height: "auto",
      disableClose: true,
      data: { clinica: clinica, role: this.role, usr: this.usr, isNew: this.isNew }
    });
    dialogRef.afterClosed().subscribe((result: { clinica: ClinicheDatabase, saved: boolean, removed?: boolean }) => {
      console.log('result from dialog %o', result);
      if (result != null && result.saved) {
        let find = false;
        for (let a = 0; a < this.rows.length; a = a + 1) {
          if (this.rows[a].code_c == result.clinica['code_c']) {
            console.log('update %o', this.rows[a]);
            find = true;
            this.rows[a] = result.clinica;
            //this.invoices[a].flg_doc_emesso = true;
          }
        }

        if (find == false)
          this.rows.push(result.clinica);

        this.rows = [...this.rows];
      }
      this.currentRow = null;
    });

  }

  onChangeRow(row) {
    console.log("onChangeRow %o", row);
    this.currentRow = row;
  }

  onToggle(e: any) {
    console.log('onToggle');
  }

  exportExcel() {
    //if (this.isAdmin == true) {
    this.dbService.exportMailsCli(this.rows);
    //}
    //else {
    //  window.alert('Attenzione: il tuo ruolo non è abilitato a questa funzione.');
    //}
  }

  importExcel() {
    //open dialog
    let dialogRef = this.dialog.open(DBDialogClinicaImportComponent, {
      width: "50%",
      height: "auto",
      disableClose: true,
      data: { role: this.role, usr: this.usr }
    });
    dialogRef.afterClosed().subscribe(r => {
      this.showb = true;
      this.dbService.getCliniche().subscribe(res => {
      
        console.log('response of getCliniche  %o', res);
  
        let res2 = [];
        for (let a = 0; a < res.length; a = a + 1) {
          let r = res[a];
          if (r['login_areamanager'] != null && r['login_areamanager'].toLowerCase().indexOf('dentalpro') < 0) {
            r['login_areamanager'] = 'DENTALPRO\\' + r['login_areamanager'].toUpperCase();
          }
          if (r['login_districtmanager'] != null && r['login_districtmanager'].toLowerCase().indexOf('dentalpro') < 0) {
            r['login_districtmanager'] = 'DENTALPRO\\' + r['login_districtmanager'].toUpperCase();
          }
          if (!(r['code_c'] == null) && r['code_c'].trim().length > 0)
            res2.push(r);
        }
        this.rows = res2;
        this.rowsBck = res;
        this.showb = false;
      });
      
    });
  }

  ngOnInit() {
    this.limitRows = Math.floor((window.innerHeight - 269) / 31);
    this.heightMainList = window.innerHeight - 188;
    this.columns = ClinicheDatabase.getStructure();
    this.customButtons.push({
      matTooltipCode: 'DATIBASE.CLINIC.export',
      labelCode: 'DATIBASE.CLINIC.export',
      img: 'assets/img/excel2.png',
      class: 'custom-button',
      clickFunction: this.exportExcel.bind(this)
    });
    /*
    this.customButtons.push({
      matTooltipCode: 'DATIBASE.CLINIC.import',
      labelCode: 'DATIBASE.CLINIC.import',
      img: 'assets/img/ed.png',
      class: 'custom-button',
      clickFunction: this.importExcel.bind(this)
    });
    */
    this.rows = [];
    this.showb = true;
    let usr = this.auth.getUser();
    console.log('------------------------------------------ > usr read %o',usr);
    if (usr != null) {
      this.role = usr['ruolo'];
      this.dm = usr['clinica'];
      if (usr['nomeCompleto'] != null && usr['nomeCompleto'].length > 0) {
        this.usr = usr['nomeCompleto'];
      }
      else {
        if (usr['email'] != null && usr['email'].length > 0) {
          this.usr = usr['email'];
        }
      }
    }
    if (this.role != null && (this.role.toLowerCase() == 'amministratore' || this.role.toLowerCase() == 'amministratore dp' || this.role.toLowerCase() == 'hr medici')) {
      this.isDC = false;
      this.isAdmin = true;
      this.isCA = false;
    }
    if (this.role != null && (this.role.toLowerCase() == 'direzione commerciale')) {
      this.isDC = true;
      this.isAdmin = false;
      this.isCA = false;
    }
    if (this.role != null && (this.role.toLowerCase() == 'ciclo attivo')) {
      this.isDC = false;
      this.isAdmin = false;
      this.isCA = true;
    }
    this.dbService.getCliniche().subscribe(res => {
    //  this.dbService.getDirettoriSanitari().subscribe(resDs => {
     
      //manage column based on logged profile
    //  console.log('response of getCliniche  %o dir sanitari %o', res, resDs);

      let res2 = [];
      for (let a = 0; a < res.length; a = a + 1) {
        let r = res[a];
        if (r['login_areamanager'] != null && r['login_areamanager'].toLowerCase().indexOf('dentalpro') < 0) {
          r['login_areamanager'] = 'DENTALPRO\\' + r['login_areamanager'].toUpperCase();
        }
        if (r['login_districtmanager'] != null && r['login_districtmanager'].toLowerCase().indexOf('dentalpro') < 0) {
          r['login_districtmanager'] = 'DENTALPRO\\' + r['login_districtmanager'].toUpperCase();
        }
        /*
        r['id_direttore_sanitario'] = 0;
        r['direttore_sanitario'] = '';
        for(let  z = 0; z < resDs.length; z = z + 1)
        {
          if (resDs[z].cod_clinica == r['code_c'])
          {
            r['id_direttore_sanitario'] = resDs[z].id_direttore_sanitario;
            r['direttore_sanitario'] = resDs[z].direttore_sanitario;
            r['iscrizione_albo_odontoiatri'] = resDs[z].iscrizione_albo_odontoiatri;
            r['iscrizione_albo_odontoiatri_prov'] = resDs[z].iscrizione_albo_odontoiatri_prov;
          }
        }
        */
        if (!(r['code_c'] == null) && r['code_c'].trim().length > 0)
          res2.push(r);
      }
      this.rows = res2;
      this.rowsBck = res;
      this.showb = false;
    }, 
  //  err => {

   //   this.showb = false;
  //  })
 // }, 
    err => {

      this.showb = false;
    });
  }

}
