import { Component, OnInit } from '@angular/core';
import { RouterState, ActivatedRoute } from '@angular/router';

import * as $ from 'jquery';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  sub;
  error;
  title;
  descr;
  show;

  constructor(
    private _route: ActivatedRoute
  ) {
    /*
    console.log('error call');
    this.sub = this._route.params.subscribe (params => {
      this.error = params['errorCode'];
      let id = 0; let idD = 0;
      this.show = true;
      if (params['errorCode'] == undefined) {
        this.title = "404";
        this.descr = "Pagina <b>non</b> trovata. Torna indietro.";
      }
      else if (params['errorCode'] == "algranatiana") {
        this.show = false;
      }
      else if (params['errorCode'] == '4dca90bb078b33d930f1e168a93b55c5365d20ae') {
        this.title = "lista tile non trovate";
        this.descr = "<b>Errore</b>: il servizio non ha potuto recuperare le tile dei fornitori/laboratori per l'elenco ordini. Array vuoto.";
      } else if (params['errorCode'] == 'e9d596e7807a846bc76a51e845fcc844f24dfdaa') {
        this.title = "Errore nella eliminazione";
        this.descr = "C'è stato un <b>errore imprevisto</b> durante l'eliminazione dell'ordine";
        var und = localStorage.getItem("myErrMsg");
        if (und != undefined)
          this.descr = this.descr + ": <b>" + und + "</b>";
        else
          this.descr = this.descr + ".";
      } else if (params['errorCode'] == '64152d21e5d95c63b92c29b284b7a3e8f0edc7e1') {
        var mess;
        if (sessionStorage.getItem('err') != undefined) {
          mess = ": " + sessionStorage.getItem('err');
          sessionStorage.removeItem('err');
        }
        else
          mess = ".";
        
        this.title = "Errore nella creazione";
        this.descr = "C'è stato un <b>errore imprevisto</b> durante la creazione dell'ordine" + mess;
      } else if (params['errorCode'] == '64152d21e5d95c63b92c29b284b7a3e8f0edc7e2') {
        var mess;
        if (sessionStorage.getItem('err') != undefined) {
          mess = ": " + sessionStorage.getItem('err');
          sessionStorage.removeItem('err');
        }
        else
          mess = ".";
        
        this.title = "Errore nella modifica";
        this.descr = "C'è stato un <b>errore imprevisto</b> durante la modifica dell'ordine" + mess;
      }
    });

    $(function() {
      $('#dalgra').height($(window).height() - 220 - $('.footer').height());
    })
    */
  }

  ngOnInit() {
    console.log('error call');
    this.sub = this._route.params.subscribe (params => {
      this.error = params['errorCode'];
      let id = 0; let idD = 0;
      this.show = true;
      if (params['errorCode'] == undefined) {
        this.title = "404";
        this.descr = "Pagina <b>non</b> trovata. Torna indietro.";
      }
      else if (params['errorCode'] == "algranatiana") {
        this.show = false;
      }
      else if (params['errorCode'] == '4dca90bb078b33d930f1e168a93b55c5365d20ae') {
        this.title = "lista tile non trovate";
        this.descr = "<b>Errore</b>: il servizio non ha potuto recuperare le tile dei fornitori/laboratori per l'elenco ordini. Array vuoto.";
      } else if (params['errorCode'] == 'e9d596e7807a846bc76a51e845fcc844f24dfdaa') {
        this.title = "Errore nella eliminazione";
        this.descr = "C'è stato un <b>errore imprevisto</b> durante l'eliminazione dell'ordine";
        var und = localStorage.getItem("myErrMsg");
        if (und != undefined)
          this.descr = this.descr + ": <b>" + und + "</b>";
        else
          this.descr = this.descr + ".";
      } else if (params['errorCode'] == '64152d21e5d95c63b92c29b284b7a3e8f0edc7e1') {
        var mess;
        if (sessionStorage.getItem('err') != undefined) {
          mess = ": " + sessionStorage.getItem('err');
          sessionStorage.removeItem('err');
        }
        else
          mess = ".";
        
        this.title = "Errore nella creazione";
        this.descr = "C'è stato un <b>errore imprevisto</b> durante la creazione dell'ordine" + mess;
      } else if (params['errorCode'] == '64152d21e5d95c63b92c29b284b7a3e8f0edc7e2') {
        var mess;
        if (sessionStorage.getItem('err') != undefined) {
          mess = ": " + sessionStorage.getItem('err');
          sessionStorage.removeItem('err');
        }
        else
          mess = ".";
        
        this.title = "Errore nella modifica";
        this.descr = "C'è stato un <b>errore imprevisto</b> durante la modifica dell'ordine" + mess;
      }
    });

    $(function() {
      $('#dalgra').height($(window).height() - 220 - $('.footer').height());
    })
  }

}
