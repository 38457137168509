import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GceManagementService } from '../../../services/gce-management.service';
import { LoaderService } from '../../../loader/loader.service';
import { AlertDialogService } from '../../../services/alert-dialog.service';
import { Confirm } from "../../../dialogs/confirm-dialog/confirm-dialog.component";
import { GcePerformanceCalculationDetail } from '../../../entities/gce-management/gce-performance-calculation-detail';
import { GcePerformanceCalculationDetracts } from '../../../entities/gce-management/gce-performance-calculation-detracts';
import * as moment from "moment";
import "moment-timezone";
import { GceInvoice, GceClinic, GceInvoicePerson, GceInvoicePersonClinic } from '../../../entities/gce-management';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SecurityContext } from '@angular/core';
import { HttpClient } from '@angular/common/http';

const tz = "Europe/Rome";
const dateFormat = "YYYY-MM-DDTHH:mm:ss";

const dateFormatDate = "YYYY-MM-DD";
const dateFormatHour = "HH:mm";

@Component({
  selector: 'app-gce-dialog-invoice-form',
  templateUrl: './gce-dialog-invoice-form.component.html',
  styleUrls: ['./gce-dialog-invoice-form.component.css']
})
export class GceDialogInvoiceFormComponent implements OnInit {
  invoice: GceInvoice = null;
  oldinvoice: GceInvoice = null;
  role: string;
  submitted: boolean = false;
  form: FormGroup;
  isAdmin: boolean = false;
  usr: any;
  nerr: number = 0;
  nop: number = 0;
  isNew: boolean = false;
  clinics: any[] = [];
  medics: any[] = [];
  medicsAll: any[] = [];
  isPersonWithError: boolean = false;

  details: GcePerformanceCalculationDetail[];
  detracts: GcePerformanceCalculationDetracts[];

  t1: number = 0;
  t2: number = 0;
  t3: number = 0;
  t4: number = 0;
  t5: number = 0;
  t6: number = 0;
  t7: number = 0;

  t11_1: number = null;
  t11_2: number = null;
  t11_3: number = null;
  t12_1: number = null;
  t12_2: number = null;
  t12_3: number = null;
  t13_t: number = null;

  t14_1: number = null;
  t14_2: number = null;
  t14_3: number = null;
  t15_1: number = null;
  t15_2: number = null;
  t15_3: number = null;
  t16_1: number = null;
  t16_2: number = null;
  t16_3: number = null;
  t17_1: number = null;
  t17_2: number = null;
  t17_3: number = null;
  t18_1: number = null;
  t18_2: number = null;
  t18_3: number = null;
  t18A_2: number = null;
  t18A_3: number = null;
  t19_1: number = null;
  t19_2: number = null;
  t19_3: number = null;

  t20_3: number = null;
  t21_3: number = null;
  t110_t: number = null;

  constructor(public dialogRef: MatDialogRef<GceDialogInvoiceFormComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private gceService: GceManagementService, private loaderService: LoaderService, private alertDialog: AlertDialogService,
    private fb: FormBuilder, private _sanitizer: DomSanitizer, private http: HttpClient) {
    this.invoice = data.invoice;
    this.details = data.details;
    this.detracts = data.detracts;
    this.role = data.role;
    this.usr = data.usr;
    this.nerr = data.nerr;
    if (data.isNew == true)
      this.isNew = true;
    if (data.clinics != null)
      this.clinics = data.clinics;
    if (data.medics != null)
      this.medicsAll = data.medics;
    console.log('INVOICE ON FORM %o', data);
    if (data.invoice != null && data.invoice.person != null) {
      this.isPersonWithError = data.invoice.person.errore;
    }
    else {
      this.isPersonWithError = true;
    }
    if (this.role != null && (this.role.toLowerCase() == 'amministratore' || this.role.toLowerCase() == 'hr medici')) {
      this.isAdmin = true;
      this.nop = data.nop;
    }

  }

  ngOnInit() {
    console.log('INIT isAdmin %o invoice %o', this.isAdmin, this.invoice);
    this.createForm();
    this.rebuildForm();
  }

  createForm() {
    this.form = new FormGroup(this.fb.group({
      id: [''],
      mese: [''],
      idfatturazione: [''],
      cod_clinica: [''],
      tipo_record: [''],
      ts_calcolo: [''],
      flg_conferma_clinica: [''],
      ts_conferma_clinica: [''],
      user_conferma_clinica: [''],
      flg_conferma_hr: [''],
      user_conferma_hr: [''],
      ts_conferma_hr: [''],
      flg_doc_emesso: [''],
      ts_doc_emesso: [''],
      flg_gettone: [''],
      gg_disponibilta_calc: [null],
      gg_agenda_calc: [null],
      gg_gettone_calc: [null],
      gg_gettone_manuale: [null],
      importo_gettone_calc: [null],
      importo_gettone_manuale: [null],
      importo_direzione_calc: [null],
      importo_direzione__area_calc: [null],
      importo_direzione__area_manuale: [null],
      importo_direzione_manuale: [null],
      importo_fisso_extra_calc: [null],
      importo_prestazioni_calc: [null],
      importo_integr_minimo_calc: [null],
      importo_prestazioni_manuale: [null],
      importo_riporto_mese_succ: [null],
      nota_riporto_mese_succ: [''],
      importo_riporto_mese_prec: [null],
      tot_fattura_prestazione: [null],
      note: [''],
      flg_scarto_doc: [''],
      msg_scarto: [''],
      richiesta_annullo_nav: [''],
      flg_annullo_nav: [''],
      utente_richiesta_annullo_nav: [''],
      ts_annullo_nav: [''],
      importo_prest_mediche_manuale: [null],
      importo_prest_anastesista_manuale: [null],
      note_hr: [''],
      gg_gettone_manuale_hr: [null],
      importo_gettone_manuale_hr: [null],
      importo_direzione_manuale_hr: [null],
      importo_prestazioni_manuale_hr: [null],
      importo_direzione__area_manuale_hr: [null],
      importo_gettone_unitario_hr: [null],
      importo_gettone_unitario: [null],
      importo_prest_mediche_manuale_hr: [null],
      importo_prest_anastesista_manuale_hr: [null],
      importo_addebiti_lab_calc: [null],
      importo_addebiti_lab_manuale_hr: [null],
      importo_detrazioni_invisalign_manuale_hr: [null],
      importo_detrazioni_invisalign_manuale: [null]

    }).controls, { updateOn: 'blur' });
    this.form.valueChanges.subscribe(x => {
      console.log('form value changed %o', x);
    })
  }

  encode(data: any) {
    var str = String.fromCharCode.apply(null, data);
    return btoa(str).replace(/.{76}(?=.)/g, '$&\n');
  }

  bin2String(array: any) {
    var result = "";
    for (var i = 0; i < array.length; i++) {
      result += String.fromCharCode(parseInt(array[i], 2));
    }
    return result;
  }

  _arrayBufferToBase64(buffer: ArrayBuffer) {
    var binary;
    var len = buffer.byteLength;
    console.log('BUFFER %o BYTES %o LEN %o', buffer, len);
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(buffer[i]);
    }
    return window.btoa(binary);
  }

  rebuildForm() {
    console.log('REBUILD FORM invoice %o', this.invoice);
    let ts_calcolo = this.invoice.ts_calcolo;
    let ts_conferma_clinica = this.invoice.ts_conferma_clinica;
    let ts_conferma_hr = this.invoice.ts_conferma_hr;
    let ts_doc_emesso = this.invoice.ts_doc_emesso;
    let ts_annullo_nav = this.invoice.ts_annullo_nav;
    var d = new Date(Date.now());

    if (this.isAdmin == true) {
      if (this.invoice.flg_conferma_hr == null || this.invoice.flg_conferma_hr == false)
        ts_conferma_hr = moment(d).format(dateFormat);
    }
    else {
      if (this.invoice.flg_conferma_clinica == null || this.invoice.flg_conferma_clinica == false)
        ts_conferma_clinica = moment(d).format(dateFormat);
    }

    let my_importo_gettone_calc = Math.round(this.invoice.importo_gettone_calc * 100) / 100;
    let my_importo_gettone_manuale = Math.round(this.invoice.importo_gettone_manuale * 100) / 100;
    let my_importo_direzione_calc = Math.round(this.invoice.importo_direzione_calc * 100) / 100;
    let my_importo_direzione_manuale = Math.round(this.invoice.importo_direzione_manuale * 100) / 100;
    let my_importo_direzione__area_calc = Math.round(this.invoice.importo_direzione__area_calc * 100) / 100;
    let my_importo_fisso_extra_calc = Math.round(this.invoice.importo_fisso_extra_calc * 100) / 100;
    let my_importo_prestazioni_calc = Math.round(this.invoice.importo_prestazioni_calc * 100) / 100;
    let my_importo_integr_minimo_calc = Math.round(this.invoice.importo_integr_minimo_calc * 100) / 100;
    let my_importo_prestazioni_manuale = Math.round(this.invoice.importo_prestazioni_manuale * 100) / 100;
    let my_importo_riporto_mese_succ = Math.round(this.invoice.importo_riporto_mese_succ * 100) / 100;
    let my_importo_riporto_mese_prec = Math.round(this.invoice.importo_riporto_mese_prec * 100) / 100;
    let my_tot_fattura_prestazione = Math.round(this.invoice.tot_fattura_prestazione * 100) / 100;
    let my_importo_prest_mediche_manuale = Math.round(this.invoice.importo_prest_mediche_manuale * 100) / 100;
    let my_importo_prest_anastesista_manuale = Math.round(this.invoice.importo_prest_anastesista_manuale * 100) / 100;
    let my_importo_gettone_manuale_hr = Math.round(this.invoice.importo_gettone_manuale_hr * 100) / 100;
    let my_importo_direzione_manuale_hr = Math.round(this.invoice.importo_direzione_manuale_hr * 100) / 100;
    let my_importo_prestazioni_manuale_hr = Math.round(this.invoice.importo_prestazioni_manuale_hr * 100) / 100;
    let my_importo_direzione__area_manuale = Math.round(this.invoice.importo_direzione__area_manuale * 100) / 100;
    let my_importo_direzione__area_manuale_hr = Math.round(this.invoice.importo_direzione__area_manuale_hr * 100) / 100;
    let my_importo_gettone_unitario_hr = Math.round(this.invoice.importo_gettone_unitario_hr * 100) / 100;
    let my_importo_gettone_unitario = Math.round(this.invoice.importo_gettone_unitario * 100) / 100;
    let my_importo_prest_mediche_manuale_hr = Math.round(this.invoice.importo_prest_mediche_manuale_hr * 100) / 100;
    let my_importo_prest_anastesista_manuale_hr = Math.round(this.invoice.importo_prest_anastesista_manuale_hr * 100) / 100;
    let my_importo_addebiti_lab_calc = Math.round(this.invoice.importo_addebiti_lab_calc * 100) / 100;
    let my_importo_addebiti_lab_manuale_hr = Math.round(this.invoice.importo_addebiti_lab_manuale_hr * 100) / 100;
    let my_importo_detrazioni_invisalign_manuale_hr = Math.round(this.invoice.importo_detrazioni_invisalign_manuale_hr * 100) / 100;
    let my_importo_detrazioni_invisalign_manuale = Math.round(this.invoice.importo_detrazioni_invisalign_manuale * 100) / 100;

    if (this.invoice.importo_gettone_calc == null) my_importo_gettone_calc = null;
    if (this.invoice.importo_gettone_manuale == null) my_importo_gettone_manuale = null;
    if (this.invoice.importo_direzione_calc == null) my_importo_direzione_calc = null;
    if (this.invoice.importo_direzione_manuale == null) my_importo_direzione_manuale = null;
    if (this.invoice.importo_direzione__area_calc == null) my_importo_direzione__area_calc = null;
    if (this.invoice.importo_fisso_extra_calc == null) my_importo_fisso_extra_calc = null;
    if (this.invoice.importo_prestazioni_calc == null) my_importo_prestazioni_calc = null;
    if (this.invoice.importo_integr_minimo_calc == null) my_importo_integr_minimo_calc = null;
    if (this.invoice.importo_prestazioni_manuale == null) my_importo_prestazioni_manuale = null;
    if (this.invoice.importo_riporto_mese_succ == null) my_importo_riporto_mese_succ = null;

    if (this.invoice.importo_riporto_mese_prec == null) my_importo_riporto_mese_prec = null;
    if (this.invoice.tot_fattura_prestazione == null) my_tot_fattura_prestazione = null;

    if (this.invoice.importo_prest_mediche_manuale == null) my_importo_prest_mediche_manuale = null;
    if (this.invoice.importo_prest_anastesista_manuale == null) my_importo_prest_anastesista_manuale = null;

    if (this.invoice.importo_gettone_manuale_hr == null) my_importo_gettone_manuale_hr = null;
    if (this.invoice.importo_direzione_manuale_hr == null) my_importo_direzione_manuale_hr = null;
    if (this.invoice.importo_prestazioni_manuale_hr == null) my_importo_prestazioni_manuale_hr = null;
    if (this.invoice.importo_direzione__area_manuale == null) my_importo_direzione__area_manuale = null;
    if (this.invoice.importo_direzione__area_manuale_hr == null) my_importo_direzione__area_manuale_hr = null;
    if (this.invoice.importo_gettone_unitario_hr == null) my_importo_gettone_unitario_hr = null;
    if (this.invoice.importo_gettone_unitario == null) my_importo_gettone_unitario = null;
    if (this.invoice.importo_prest_mediche_manuale_hr == null) my_importo_prest_mediche_manuale_hr = null;
    if (this.invoice.importo_prest_anastesista_manuale_hr == null) my_importo_prest_anastesista_manuale_hr = null;
    if (this.invoice.importo_addebiti_lab_calc == null) my_importo_addebiti_lab_calc = null;
    if (this.invoice.importo_addebiti_lab_manuale_hr == null) my_importo_addebiti_lab_manuale_hr = null;
    if (this.invoice.importo_detrazioni_invisalign_manuale_hr == null) my_importo_detrazioni_invisalign_manuale_hr = null;
    if (this.invoice.importo_detrazioni_invisalign_manuale == null) my_importo_detrazioni_invisalign_manuale = null;
    let mynota_riporto_mese_succ = this.invoice.nota_riporto_mese_succ;
    if (mynota_riporto_mese_succ != null) {

      mynota_riporto_mese_succ = mynota_riporto_mese_succ.replace(/&#10;/g, '\n').replace(/&#9;/g, '\t').replace(/&#8364;/g, '€').replace(/&#34;/g, '"').replace(/&#232;/g, 'e\'').replace(/&#242;/g, 'o\'').replace(/&#224;/g, 'a\'').replace(/&#249;/g, 'u\'').replace(/&#236;/g, 'i\'');

    }

    let mynote = this.invoice.note;
    if (mynote != null) {
      mynote = mynote.replace(/&#10;/g, '\n').replace(/&#9;/g, '\t').replace(/&#8364;/g, '€').replace(/&#34;/g, '"').replace(/&#232;/g, 'e\'').replace(/&#242;/g, 'o\'').replace(/&#224;/g, 'a\'').replace(/&#249;/g, 'u\'').replace(/&#236;/g, 'i\'');

    }

    let mynote_hr = this.invoice.note_hr;
    if (mynote_hr != null) {

      mynote_hr = mynote_hr.replace(/&#10;/g, '\n').replace(/&#9;/g, '\t').replace(/&#8364;/g, '€').replace(/&#34;/g, '"').replace(/&#232;/g, 'e\'').replace(/&#242;/g, 'o\'').replace(/&#224;/g, 'a\'').replace(/&#249;/g, 'u\'').replace(/&#236;/g, 'i\'');
    }

    this.form.patchValue({
      id: this.invoice.id,
      mese: this.invoice.mese,
      idfatturazione: this.invoice.idfatturazione,
      cod_clinica: this.invoice.cod_clinica,
      tipo_record: this.invoice.tipo_record,
      ts_calcolo: this.invoice.ts_calcolo,
      flg_conferma_clinica: this.invoice.flg_conferma_clinica,
      ts_conferma_clinica: this.invoice.ts_conferma_clinica,
      user_conferma_clinica: this.invoice.user_conferma_clinica,
      flg_conferma_hr: this.invoice.flg_conferma_hr,
      user_conferma_hr: this.invoice.user_conferma_hr,
      ts_conferma_hr: this.invoice.ts_conferma_hr,
      flg_doc_emesso: this.invoice.flg_doc_emesso,
      ts_doc_emesso: this.invoice.ts_doc_emesso,
      flg_gettone: this.invoice.flg_gettone,
      gg_disponibilta_calc: this.invoice.gg_disponibilta_calc,
      gg_agenda_calc: this.invoice.gg_agenda_calc,
      gg_gettone_calc: this.invoice.gg_gettone_calc,
      gg_gettone_manuale: this.invoice.gg_gettone_manuale,
      gg_gettone_manuale_hr: this.invoice.gg_gettone_manuale_hr,
      importo_gettone_calc: my_importo_gettone_calc,
      importo_gettone_manuale: my_importo_gettone_manuale,
      importo_direzione_calc: my_importo_direzione_calc,
      importo_direzione_manuale: my_importo_direzione_manuale,
      importo_direzione__area_calc: my_importo_direzione__area_calc,
      importo_fisso_extra_calc: my_importo_fisso_extra_calc,
      importo_prestazioni_calc: my_importo_prestazioni_calc,
      importo_integr_minimo_calc: my_importo_integr_minimo_calc,
      importo_prestazioni_manuale: my_importo_prestazioni_manuale,
      importo_riporto_mese_succ: my_importo_riporto_mese_succ,

      nota_riporto_mese_succ: mynota_riporto_mese_succ,
      importo_riporto_mese_prec: my_importo_riporto_mese_prec,
      tot_fattura_prestazione: my_tot_fattura_prestazione,

      note: mynote,
      flg_scarto_doc: this.invoice.flg_scarto_doc,
      msg_scarto: this.invoice.msg_scarto,
      richiesta_annullo_nav: this.invoice.richiesta_annullo_nav,
      flg_annullo_nav: this.invoice.flg_annullo_nav,
      utente_richiesta_annullo_nav: this.invoice.utente_richiesta_annullo_nav,
      ts_annullo_nav: this.invoice.ts_annullo_nav,
      importo_prest_mediche_manuale: my_importo_prest_mediche_manuale,
      importo_prest_anastesista_manuale: my_importo_prest_anastesista_manuale,

      note_hr: mynote_hr,
      importo_gettone_manuale_hr: my_importo_gettone_manuale_hr,
      importo_direzione_manuale_hr: my_importo_direzione_manuale_hr,
      importo_prestazioni_manuale_hr: my_importo_prestazioni_manuale_hr,
      importo_direzione__area_manuale: my_importo_direzione__area_manuale,
      importo_direzione__area_manuale_hr: my_importo_direzione__area_manuale_hr,
      importo_gettone_unitario_hr: my_importo_gettone_unitario_hr,
      importo_gettone_unitario: my_importo_gettone_unitario,
      importo_prest_mediche_manuale_hr: my_importo_prest_mediche_manuale_hr,
      importo_prest_anastesista_manuale_hr: my_importo_prest_anastesista_manuale_hr,
      importo_addebiti_lab_calc: my_importo_addebiti_lab_calc,
      importo_addebiti_lab_manuale_hr: my_importo_addebiti_lab_manuale_hr,
      importo_detrazioni_invisalign_manuale_hr: my_importo_detrazioni_invisalign_manuale_hr,
      importo_detrazioni_invisalign_manuale: my_importo_detrazioni_invisalign_manuale
    });
    if (this.clinics.length == 1) {
      let e = {};
      e['value'] = this.invoice.cod_clinica;
      this.changeClinic(e);
    }

  }

  calcValuesForReport() {
    let hrm = false;
    if (!(this.invoice.importo_gettone_calc == null))
      this.invoice.importo_gettone_calc = Math.round(this.invoice.importo_gettone_calc * 100) / 100 || 0;
    if (!(this.invoice.importo_gettone_manuale == null))
      this.invoice.importo_gettone_manuale = Math.round(this.invoice.importo_gettone_manuale * 100) / 100 || 0;
    if (!(this.invoice.importo_direzione_calc == null))
      this.invoice.importo_direzione_calc = Math.round(this.invoice.importo_direzione_calc * 100) / 100 || 0;
    if (!(this.invoice.importo_direzione_manuale == null))
      this.invoice.importo_direzione_manuale = Math.round(this.invoice.importo_direzione_manuale * 100) / 100 || 0;
    if (!(this.invoice.importo_fisso_extra_calc == null))
      this.invoice.importo_fisso_extra_calc = Math.round(this.invoice.importo_fisso_extra_calc * 100) / 100 || 0;
    if (!(this.invoice.importo_prestazioni_calc == null))
      this.invoice.importo_prestazioni_calc = Math.round(this.invoice.importo_prestazioni_calc * 100) / 100 || 0;

    if (!(this.invoice.importo_integr_minimo_calc == null))
      this.invoice.importo_integr_minimo_calc = Math.round(this.invoice.importo_integr_minimo_calc * 100) / 100 || 0;
    if (!(this.invoice.importo_prestazioni_manuale == null))
      this.invoice.importo_prestazioni_manuale = Math.round(this.invoice.importo_prestazioni_manuale * 100) / 100 || 0;
    if (!(this.invoice.importo_riporto_mese_succ == null))
      this.invoice.importo_riporto_mese_succ = Math.round(this.invoice.importo_riporto_mese_succ * 100) / 100 || 0;

    if (!(this.invoice.importo_riporto_mese_prec == null))
      this.invoice.importo_riporto_mese_prec = Math.round(this.invoice.importo_riporto_mese_prec * 100) / 100 || 0;
    if (!(this.invoice.tot_fattura_prestazione == null))
      this.invoice.tot_fattura_prestazione = Math.round(this.invoice.tot_fattura_prestazione * 100) / 100 || 0;
    if (!(this.invoice.importo_prest_mediche_manuale == null))
      this.invoice.importo_prest_mediche_manuale = Math.round(this.invoice.importo_prest_mediche_manuale * 100) / 100 || 0;
    if (!(this.invoice.importo_prest_anastesista_manuale == null))
      this.invoice.importo_prest_anastesista_manuale = Math.round(this.invoice.importo_prest_anastesista_manuale * 100) / 100 || 0;

    if (!(this.invoice.importo_gettone_manuale_hr == null))
      this.invoice.importo_gettone_manuale_hr = Math.round(this.invoice.importo_gettone_manuale_hr * 100) / 100 || 0;
    if (!(this.invoice.importo_direzione_manuale_hr == null))
      this.invoice.importo_direzione_manuale_hr = Math.round(this.invoice.importo_direzione_manuale_hr * 100) / 100 || 0;
    if (!(this.invoice.importo_prestazioni_manuale_hr == null))
      this.invoice.importo_prestazioni_manuale_hr = Math.round(this.invoice.importo_prestazioni_manuale_hr * 100) / 100 || 0;

    if (!(this.invoice.importo_direzione__area_manuale_hr == null))
      this.invoice.importo_direzione__area_manuale_hr = Math.round(this.invoice.importo_direzione__area_manuale_hr * 100) / 100 || 0;
    if (!(this.invoice.importo_gettone_unitario_hr == null))
      this.invoice.importo_gettone_unitario_hr = Math.round(this.invoice.importo_gettone_unitario_hr * 100) / 100 || 0;
    if (!(this.invoice.importo_prest_mediche_manuale_hr == null))
      this.invoice.importo_prest_mediche_manuale_hr = Math.round(this.invoice.importo_prest_mediche_manuale_hr * 100) / 100 || 0;
    if (!(this.invoice.importo_prest_anastesista_manuale_hr == null))
      this.invoice.importo_prest_anastesista_manuale_hr = Math.round(this.invoice.importo_prest_anastesista_manuale_hr * 100) / 100 || 0;

    if (this.invoice.nota_riporto_mese_succ != null) {

      this.invoice.nota_riporto_mese_succ = this.invoice.nota_riporto_mese_succ.replace(/&#10;/g, '\n').replace(/&#9;/g, '\t').replace(/&#8364;/g, '€').replace(/&#34;/g, '"').replace(/&#232;/g, 'e\'').replace(/&#242;/g, 'o\'').replace(/&#224;/g, 'a\'').replace(/&#249;/g, 'u\'').replace(/&#236;/g, 'i\'');

    }

    if (this.invoice.note != null) {

      this.invoice.note = this.invoice.note.replace(/&#10;/g, '\n').replace(/&#9;/g, '\t').replace(/&#8364;/g, '€').replace(/&#34;/g, '"').replace(/&#232;/g, 'e\'').replace(/&#242;/g, 'o\'').replace(/&#224;/g, 'a\'').replace(/&#249;/g, 'u\'').replace(/&#236;/g, 'i\'');

    }

    if (this.invoice.note_hr != null) {

      this.invoice.note_hr = this.invoice.note_hr.replace(/&#10;/g, '\n').replace(/&#9;/g, '\t').replace(/&#8364;/g, '€').replace(/&#34;/g, '"').replace(/&#232;/g, 'e\'').replace(/&#242;/g, 'o\'').replace(/&#224;/g, 'a\'').replace(/&#249;/g, 'u\'').replace(/&#236;/g, 'i\'');

    }

    for (let a = 0; a < this.detracts.length; a = a + 1) {
      if (this.detracts[a]['importo_addebito_medico'] != null) {
        this.detracts[a]['importo_addebito_medico'] = Math.round(this.detracts[a]['importo_addebito_medico'] * 100) / 100;
      }
      if (this.detracts[a]['perc_medico'] != null) {
        this.detracts[a]['perc_medico'] = Math.round(this.detracts[a]['perc_medico'] * 100) / 100;
      }
      if (this.detracts[a]['importo_ordine'] != null) {
        this.detracts[a]['importo_ordine'] = Math.round(this.detracts[a]['importo_ordine'] * 100) / 100;
      }
    }

    console.log('invoice %o', this.invoice);
    if (this.invoice.gg_disponibilta_calc > 0)
      this.t1 = this.invoice.gg_disponibilta_calc;
    if (this.invoice.gg_agenda_calc > 0)
      this.t2 = this.invoice.gg_agenda_calc;
    if (this.invoice.gg_gettone_calc > 0)
      this.t3 = this.invoice.gg_gettone_calc;
    //GETTONI MAN
    if (this.invoice.gg_gettone_manuale != null)
      this.t3 = this.invoice.gg_gettone_manuale;
    //GETTONI HR
    if (this.invoice.gg_gettone_manuale_hr != null)
      this.t3 = this.invoice.gg_gettone_manuale_hr;

    if (this.invoice.importo_gettone_calc != null)
      this.t14_1 = this.invoice.importo_gettone_calc;
    if (this.invoice.importo_gettone_manuale != null)
      this.t14_2 = this.invoice.importo_gettone_manuale;
    if (this.invoice.importo_gettone_manuale_hr != null)
      this.t14_3 = this.invoice.importo_gettone_manuale_hr;

    if (this.invoice.importo_fisso_extra_calc > 0)
      this.t15_1 = this.invoice.importo_fisso_extra_calc;

    if (this.invoice.importo_integr_minimo_calc > 0)
      this.t19_1 = this.invoice.importo_integr_minimo_calc;

    if (this.invoice.importo_prestazioni_manuale > 0)
      this.t16_2 = this.invoice.importo_prestazioni_manuale;
    if (this.invoice.importo_prestazioni_manuale < 0)
      this.t16_2 = this.invoice.importo_prestazioni_manuale;

    if (this.invoice.importo_prest_mediche_manuale > 0)
      this.t16_2 = this.t16_2 + this.invoice.importo_prest_mediche_manuale;
    if (this.invoice.importo_prest_mediche_manuale < 0)
      this.t16_2 = this.t16_2 + this.invoice.importo_prest_mediche_manuale;
    if (this.invoice.importo_prest_anastesista_manuale > 0)
      this.t16_2 = this.t16_2 + this.invoice.importo_prest_anastesista_manuale;
    if (this.invoice.importo_prest_anastesista_manuale < 0)
      this.t16_2 = this.t16_2 + this.invoice.importo_prest_anastesista_manuale;

    if (this.invoice.importo_prestazioni_manuale_hr > 0)
      this.t16_3 = this.invoice.importo_prestazioni_manuale_hr;
    if (this.invoice.importo_prestazioni_manuale_hr < 0)
      this.t16_3 = this.invoice.importo_prestazioni_manuale_hr;

    if (this.invoice.importo_prest_mediche_manuale_hr > 0)
      this.t16_3 = this.t16_3 + this.invoice.importo_prest_mediche_manuale_hr;
    if (this.invoice.importo_prest_mediche_manuale_hr < 0)
      this.t16_3 = this.t16_3 + this.invoice.importo_prest_mediche_manuale_hr;
    if (this.invoice.importo_prest_anastesista_manuale_hr > 0)
      this.t16_3 = this.t16_3 + this.invoice.importo_prest_anastesista_manuale_hr;
    if (this.invoice.importo_prest_anastesista_manuale_hr < 0)
      this.t16_3 = this.t16_3 + this.invoice.importo_prest_anastesista_manuale_hr;

    if (this.invoice.importo_direzione_calc > 0)
      this.t11_1 = this.invoice.importo_direzione_calc;
    if (this.invoice.importo_direzione_manuale > 0)
      this.t11_2 = this.invoice.importo_direzione_manuale;
    if (this.invoice.importo_direzione_manuale_hr > 0)
      this.t11_3 = this.invoice.importo_direzione_manuale_hr;

    if (this.invoice.importo_direzione__area_calc > 0)
      this.t12_1 = this.invoice.importo_direzione__area_calc;
    if (this.invoice.importo_direzione__area_manuale > 0)
      this.t12_2 = this.invoice.importo_direzione__area_manuale;
    if (this.invoice.importo_direzione__area_manuale_hr > 0)
      this.t12_3 = this.invoice.importo_direzione__area_manuale_hr;

    let d11 = null;
    if (!(this.t11_1 == null))
      d11 = this.t11_1;
    if (!(this.t11_2 == null))
      d11 = this.t11_2;
    if (!(this.t11_3 == null))
      d11 = this.t11_3;

    let d12 = null;

    if (!(this.t12_1 == null))
      d12 = this.t12_1;
    if (!(this.t12_2 == null)) {
      d12 = this.t12_2;

    }
    if (!(this.t12_3 == null)) {
      d12 = this.t12_3;

    }
    this.t13_t = d11 + d12;

    for (let a = 0; a < this.details.length; a = a + 1) {
      console.log('DT %o imp %o', this.details[a], this.details[a]['importo_prest_base_calc_medico']);
      if (this.details[a]['importo_prest_base_calc_medico'] > 0) {
        //this.t4 = this.t4 + this.details[a]['importo_prest_base_calc_medico'];
        this.t5 = this.t5 + this.details[a]['importo_prest_base_calc_medico'];
      }
      
      /*
      if (this.details[a]['compenso_medico'] > 0) {
        this.t16_1 = this.t16_1 + this.details[a]['compenso_medico'];
      }


      if (this.details[a]['compenso_medico'] < 0) {
        this.t17_1 = this.t17_1 + this.details[a]['compenso_medico'];
      }
*/

if (this.details[a]['compenso_medico'] > 0 || this.details[a]['compenso_medico'] < 0) {
  this.t16_1 = this.t16_1 + this.details[a]['compenso_medico'];
}

      if (this.details[a]['importo_prest_lordo'] > 0) {
        //this.t4 = this.t4 + this.details[a]['importo_prest_base_calc_medico'];
        this.t4 = this.t4 + this.details[a]['importo_prest_lordo'];
      }

      if (this.details[a]['importo_prest_base_calc_medico'] < 0) {
        this.t6 = this.t6 + this.details[a]['importo_prest_base_calc_medico'];
        //Warning

      }

    }

    if (this.invoice.importo_addebiti_lab_calc > 0 && (!(this.t18_1 == 0) || this.invoice.importo_addebiti_lab_calc > this.t18_1)) {
      this.t18_1 = this.invoice.importo_addebiti_lab_calc;
    }
    let dinvisaling = 0;
    if (this.invoice.importo_detrazioni_invisalign_manuale > 0 || this.invoice.importo_detrazioni_invisalign_manuale < 0) {
      this.t18A_2 = this.invoice.importo_detrazioni_invisalign_manuale;
      if (this.t18A_2 > 0)
        this.t18A_2 = this.t18A_2 * -1;
      dinvisaling = this.t18A_2;
    }

    if ((this.invoice.importo_addebiti_lab_manuale_hr > 0 || this.invoice.importo_addebiti_lab_manuale_hr == 0) && (!(this.t18_3 == 0) || this.invoice.importo_addebiti_lab_manuale_hr > this.t18_3)) {
      this.t18_3 = this.invoice.importo_addebiti_lab_manuale_hr;
    }

    if (this.invoice.importo_detrazioni_invisalign_manuale_hr > 0 || this.invoice.importo_detrazioni_invisalign_manuale_hr == 0 || this.invoice.importo_detrazioni_invisalign_manuale_hr < 0) {
      this.t18A_3 = this.invoice.importo_detrazioni_invisalign_manuale_hr;
      if (this.t18A_3 > 0)
        this.t18A_3 = this.t18A_3 * -1;
      dinvisaling = this.t18A_3;
    }

    if (this.t18_1 > 0)
      this.t18_1 = this.t18_1 * -1;
    if (this.t18_2 > 0)
      this.t18_2 = this.t18_2 * -1;
    if (this.t18_3 > 0)
      this.t18_3 = this.t18_3 * -1;

    let p14 = null;
    if (!(this.t14_1 == null))
      p14 = this.t14_1;
    if (!(this.t14_2 == null))
      p14 = this.t14_2;
    if (!(this.t14_3 == null))
      p14 = this.t14_3;

    let p15 = null;

    if (!(this.t15_1 == null))
      p15 = this.t15_1;
    if (!(this.t15_2 == null)) {
      p15 = this.t15_2;

    }
    if (!(this.t15_3 == null)) {
      p15 = this.t15_3;

    }
    let p16 = null;
    if (!(this.t16_1 == null))
      p16 = this.t16_1;
    if (!(this.t16_2 == null)) {
      p16 = this.t16_2;
      hrm = true;
    }
    if (!(this.t16_3 == null)) {
      p16 = this.t16_3;
      hrm = true;
    }
    let p17 = null;
    if (!(this.t17_1 == null))
      p17 = this.t17_1;
    if (!(this.t17_2 == null))
      p17 = this.t17_2;
    if (!(this.t17_3 == null))
      p17 = this.t17_3;

    let p18 = null;
    if (!(this.t18_1 == null))
      p18 = this.t18_1;
    if (!(this.t18_2 == null))
      p18 = this.t18_2;
    if (!(this.t18_3 == null))
      p18 = this.t18_3;

    let p19 = null;
    if (!(this.t19_1 == null))
      p19 = this.t19_1;
    if (!(this.t19_2 == null))
      p19 = this.t19_2;
    if (!(this.t19_3 == null))
      p19 = this.t19_3;

    if (hrm == false)
      this.t110_t = p14 + p15 + p16 + p17 + p18 + p19 + dinvisaling;
    else
      this.t110_t = p14 + p15 + p16 + p18 + p19 + dinvisaling;

    for (let a = 0; a < this.detracts.length; a = a + 1) {
      if (this.detracts[a].importo_ordine > 0)
        this.t7 = this.t7 + this.detracts[a].importo_ordine;
    }
    //importi riporti
    if (this.invoice.importo_riporto_mese_prec > 0 || this.invoice.importo_riporto_mese_prec < 0) {
      this.t20_3 = this.invoice.importo_riporto_mese_prec;
      this.t110_t = this.t110_t + this.t20_3;
    }
    if (this.invoice.importo_riporto_mese_succ > 0 || this.invoice.importo_riporto_mese_succ < 0)
      this.t21_3 = this.invoice.importo_riporto_mese_succ;


  }
  onChangeNull($event) {
    console.log('onChange null %o', $event);
  }

  save() {
    this.submitted = true;
    let formData = this.form.getRawValue();

    if (formData['note_hr'] != null)
      formData['note_hr'] = this._sanitizer.sanitize(SecurityContext.HTML, formData['note_hr']);
    if (formData['note'] != null)
      formData['note'] = this._sanitizer.sanitize(SecurityContext.HTML, formData['note']);
    if (formData['nota_riporto_mese_succ'] != null)
      formData['nota_riporto_mese_succ'] = this._sanitizer.sanitize(SecurityContext.HTML, formData['nota_riporto_mese_succ']);
    console.log('ts_conferma_clinica BEFORE %o and %o', formData, this.form.value.importo_prestazioni_manuale);

    //check dates

    if (this.form.valid) {
      if (window.confirm('Sei sicuro di voler salvare la liquidazione?')) {
        var d = new Date(Date.now());
        this.loaderService.show();
        //get user info, parsing the profile
        //rec
        //hr
        if (this.isAdmin == true) {
          if (formData['flg_conferma_hr'] == true) {
            formData['user_conferma_hr'] = this.usr;
            if (formData['ts_conferma_hr'] == null) {
              formData['ts_conferma_hr'] = moment(d).format(dateFormat);
            }
            if (formData['flg_conferma_clinica'] == false) {
              formData['flg_conferma_clinica'] = true;
              formData['ts_conferma_clinica'] = moment(d).format(dateFormat);
              formData['user_conferma_clinica'] = this.usr;
            }
          }
          else {
            formData['user_conferma_hr'] = '';
            formData['flg_conferma_clinica'] = false;
          }
        }
        else {
          if (formData['flg_conferma_clinica'] == true) {
            formData['user_conferma_clinica'] = this.usr;
            if (formData['ts_conferma_clinica'] == null) {
              formData['ts_conferma_clinica'] = moment(d).format(dateFormat);
            }
          }
          else {
            formData['user_conferma_clinica'] = '';
          }
        }

        console.log('FORMDATA for update %o', formData);

        this.gceService.updateInvoice(formData).subscribe((result: GceInvoice) => {
          //this.loaderService.hide();
          this.submitted = false;
          result.person = this.invoice.person;
          result.clinic = this.invoice.clinic;
          this.invoice = result;
          //update 
          this.gceService.calculateBillingDoctor(formData['cod_clinica'], formData['idfatturazione'], this.gceService.dataRepoGceEnvironment.getToDate()).subscribe(r => {
            console.log('after recalculate save %o', r);
            this.loaderService.show();
            this.dialogRef.close({ saved: true, invoice: result });
          }, err => {
            this.loaderService.show();
          });
        }, err => {
          this.loaderService.show();
        });
      }
    }
  }

  newInvoice() {
    this.submitted = true;
    let formData = this.form.getRawValue();
    if (formData['note_hr'] != null)
      formData['note_hr'] = this._sanitizer.sanitize(SecurityContext.HTML, formData['note_hr']);
    if (formData['note'] != null)
      formData['note'] = this._sanitizer.sanitize(SecurityContext.HTML, formData['note']);
    if (formData['nota_riporto_mese_succ'] != null)
      formData['nota_riporto_mese_succ'] = this._sanitizer.sanitize(SecurityContext.HTML, formData['nota_riporto_mese_succ']);

    console.log('ts_conferma_clinica BEFORE %o', formData);
    //check dates
    if (formData['idfatturazione'] > 0 && (formData['cod_clinica'] != null && formData['cod_clinica'].length > 0)) {
      if (this.form.valid) {
        if (window.confirm('Sei sicuro di voler salvare la liquidazione?')) {
          var d = new Date(Date.now());
          this.loaderService.show();
          //get user info, parsing the profile
          //rec
          //hr
          if (this.isAdmin == true) {
            if (formData['flg_conferma_hr'] == true) {
              formData['user_conferma_hr'] = this.usr;
              if (formData['ts_conferma_hr'] == null) {
                formData['ts_conferma_hr'] = moment(d).format(dateFormat);
              }
              if (formData['flg_conferma_clinica'] == false) {
                formData['flg_conferma_clinica'] = true;
                formData['ts_conferma_clinica'] = moment(d).format(dateFormat);
                formData['user_conferma_clinica'] = this.usr;
              }
            }
            else {
              formData['user_conferma_hr'] = '';
              formData['flg_conferma_clinica'] = false;
            }
          }
          else {
            if (formData['flg_conferma_clinica'] == true) {
              formData['user_conferma_clinica'] = this.usr;
              if (formData['ts_conferma_clinica'] == null) {
                formData['ts_conferma_clinica'] = moment(d).format(dateFormat);
              }
            }
            else {
              formData['user_conferma_clinica'] = '';
            }
          }

          console.log('FORMDATA for update %o', formData);
          this.gceService.insertInvoice(formData).subscribe((result: GceInvoice) => {
            let inv = result;
            inv.clinic = this.invoice.clinic;
            inv.person = this.invoice.person;
            this.loaderService.hide();
            this.dialogRef.close({ saved: true, invoice: inv });
          }, err => {

          });
        }
      }
    }
    else {
      window.alert("E' necessario specificare Clinica e Medico per poter inserire una nuova liquidazione.")
    }
  }

  changeClinic(e) {
    console.log('changeClinic %o', e.value);
    //in medics metto i medici con clinica e.value
    this.gceService.getPersonByClinic(e.value).subscribe(m => {
      //console.log('response of getPersonByClinic for changeClinic %o', m);
      //prendo gli m e cerco in medics
      this.medics = [];
      for (let a = 0; a < m.length; a = a + 1) {
        //push idfatturazione in medics
        for (let b = 0; b < this.medicsAll.length; b = b + 1) {
          if (m[a].idfatturazione == this.medicsAll[b].idfatturazione)
            this.medics.push(this.medicsAll[b]);
        }
      }
      console.log('allmedics %o medics %o and response of getPersonByClinic for changeClinic %o', this.medicsAll, this.medics, m);

    });
  }

  resend() {
    this.submitted = true;
    let formData = this.form.getRawValue();
    if (formData['note_hr'] != null)
      formData['note_hr'] = this._sanitizer.sanitize(SecurityContext.HTML, formData['note_hr']);
    if (formData['note'] != null)
      formData['note'] = this._sanitizer.sanitize(SecurityContext.HTML, formData['note']);
    if (formData['nota_riporto_mese_succ'] != null)
      formData['nota_riporto_mese_succ'] = this._sanitizer.sanitize(SecurityContext.HTML, formData['nota_riporto_mese_succ']);
    if (this.form.valid) {
      if (formData['flg_conferma_clinica'] == false) {
      }
      else {
        if (window.confirm('Sei sicuro di voler annullare la conferma liquidazione fatta dalla clinica?')) {

          this.loaderService.show();

          formData['user_conferma_hr'] = this.usr;
          formData['ts_conferma_hr'] = '';
          formData['flg_conferma_hr'] = false;
          formData['flg_conferma_clinica'] = false;
          formData['ts_conferma_clinica'] = '';
          formData['user_conferma_clinica'] = this.usr;

          console.log('FORMDATA for RESEND %o', formData);
          this.gceService.updateInvoice(formData).subscribe((result: GceInvoice) => {
            this.loaderService.hide();
            this.dialogRef.close({ saved: true, invoice: result });
          }, err => {

          });
        }
      }
    }
  }


  confirmT() {
    console.log('TEMP CONFIRM');
    //this.invoice = d;
    //get report
    this.calcValuesForReport();
    //send email
    let t1 = null;
    if (!(this.t1 == null))
      t1 = Math.round(this.t1 * 100) / 100;
    let t2 = null;
    if (!(this.t2 == null))
      t2 = Math.round(this.t2 * 100) / 100;
    let t3 = null;
    if (!(this.t3 == null))
      t3 = Math.round(this.t3 * 100) / 100;
    let t4 = null;
    if (!(this.t4 == null))
      t4 = Math.round(this.t4 * 100) / 100;
    let t5 = null;
    if (!(this.t5 == null))
      t5 = Math.round(this.t5 * 100) / 100;
    let t6 = null;
    if (!(this.t6 == null))
      t6 = Math.round(this.t6 * 100) / 100;
    let t7 = null;
    if (!(this.t7 == null))
      t7 = Math.round(this.t7 * 100) / 100;
    let t11_1 = null;
    if (!(this.t11_1 == null))
      t11_1 = Math.round(this.t11_1 * 100) / 100;
    let t11_2 = null;
    if (!(this.t11_2 == null))
      t11_2 = Math.round(this.t11_2 * 100) / 100;
    let t11_3 = null;
    if (!(this.t11_3 == null))
      t11_3 = Math.round(this.t11_3 * 100) / 100;
    let t12_1 = null;
    if (!(this.t12_1 == null))
      t12_1 = Math.round(this.t12_1 * 100) / 100;
    let t12_2 = null;
    if (!(this.t12_2 == null))
      t12_2 = Math.round(this.t12_2 * 100) / 100;
    let t12_3 = null;
    if (!(this.t12_3 == null))
      t12_3 = Math.round(this.t12_3 * 100) / 100;
    let t13_t = null;
    if (!(this.t13_t == null))
      t13_t = Math.round(this.t13_t * 100) / 100;
    let t14_1 = null;
    if (!(this.t14_1 == null))
      t14_1 = Math.round(this.t14_1 * 100) / 100;
    let t14_2 = null;
    if (!(this.t14_2 == null))
      t14_2 = Math.round(this.t14_2 * 100) / 100;
    let t14_3 = null;
    if (!(this.t14_3 == null))
      t14_3 = Math.round(this.t14_3 * 100) / 100;
    let t15_1 = null;
    if (!(this.t15_1 == null))
      t15_1 = Math.round(this.t15_1 * 100) / 100;
    let t15_2 = null;
    if (!(this.t15_2 == null))
      t15_2 = Math.round(this.t15_2 * 100) / 100;
    let t15_3 = null;
    if (!(this.t15_3 == null))
      t15_3 = Math.round(this.t15_3 * 100) / 100;
    let t16_1 = null;
    if (!(this.t16_1 == null))
      t16_1 = Math.round(this.t16_1 * 100) / 100;
    let t16_2 = null;
    if (!(this.t16_2 == null))
      t16_2 = Math.round(this.t16_2 * 100) / 100;
    let t16_3 = null;
    if (!(this.t16_3 == null))
      t16_3 = Math.round(this.t16_3 * 100) / 100;
    let t17_1 = null;
    if (!(this.t17_1 == null))
      t17_1 = Math.round(this.t17_1 * 100) / 100;
    let t17_2 = null;
    if (!(this.t17_2 == null))
      t17_2 = Math.round(this.t17_2 * 100) / 100;
    let t17_3 = null;
    if (!(this.t17_3 == null))
      t17_3 = Math.round(this.t17_3 * 100) / 100;
    let t18_1 = null;
    if (!(this.t18_1 == null))
      t18_1 = Math.round(this.t18_1 * 100) / 100;
    let t18_2 = null;
    if (!(this.t18_2 == null))
      t18_2 = Math.round(this.t18_2 * 100) / 100;
    let t18_3 = null;
    if (!(this.t18_3 == null))
      t18_3 = Math.round(this.t18_3 * 100) / 100;
    let t18A_2 = null;
    if (!(this.t18A_2 == null))
      t18A_2 = Math.round(this.t18A_2 * 100) / 100;
    let t18A_3 = null;
    if (!(this.t18A_3 == null))
      t18A_3 = Math.round(this.t18A_3 * 100) / 100;
    let t19_1 = null;
    if (!(this.t19_1 == null))
      t19_1 = Math.round(this.t19_1 * 100) / 100;
    let t19_2 = null;
    if (!(this.t19_2 == null))
      t19_2 = Math.round(this.t19_2 * 100) / 100;
    let t19_3 = null;
    if (!(this.t19_3 == null))
      t19_3 = Math.round(this.t19_3 * 100) / 100;
    let t110_t = null;
    if (!(this.t110_t == null))
      t110_t = Math.round(this.t110_t * 100) / 100;
    let t20_3 = null;
    if (!(this.t20_3 == null))
      t20_3 = Math.round(this.t20_3 * 100) / 100;
    let t21_3 = null;
    if (!(this.t21_3 == null))
      t21_3 = Math.round(this.t21_3 * 100) / 100;
    let blb = this.gceService.exportPerformanceCalculationDetailDefToExcel(this.invoice,
      this.details,
      this.detracts,
      t1,
      t2,
      t3,
      t4,
      t5,
      t6,
      t7,
      t11_1,
      t11_2,
      t11_3,
      t12_1,
      t12_2,
      t12_3,
      t13_t,
      t14_1,
      t14_2,
      t14_3,
      t15_1,
      t15_2,
      t15_3,
      t16_1,
      t16_2,
      t16_3,
      t17_1,
      t17_2,
      t17_3,
      t18_1,
      t18_2,
      t18_3,
      t18A_2,
      t18A_3,
      t19_1,
      t19_2,
      t19_3,
      t110_t,
      t20_3,
      t21_3,
      false
    );
    let self = this;
    /*
        let payload = {
          email: 'andrea.rogges@gmail.com',
          subject: 'test subject',
          message: 'test message',
        };
    
    
        let formData: FormData = new FormData();
        formData.append('File', blb);
        formData.append('jsonString', JSON.stringify(payload));
    
        console.log('------------------------------------------> PAYLOAD %o', blb, formData);
    
        this.gceService.sendEmail(formData).subscribe(
          d => {
            console.log('------------------------------------------> MAIL SENT %o', d);
          }
        );
    */

    let fileReader = new FileReader();

    fileReader.onloadend = function () {
      // console.log('------------------------------------------> BLB', fileReader.result);
      let payload = {
        email: 'algranati@dentalpro.it',
        subject: 'Report liquidazione mensile',
        message: 'Gentile Dottore,\n in allegato trova il suo report per la clinica di ' + self.invoice.clinic.nome + '.\nEntro 24 h troverà la pre-fattura caricata a portale.\nCordiali Saluti',
        attachFile: btoa(fileReader.result.toString()).replace(/.{76}(?=.)/g, '$&\n')
        //attachFile: btoa(String.fromCharCode.apply(null, new Uint8Array(<ArrayBuffer>fileReader.result)).replace(/.{76}(?=.)/g, '$&\n'))
      };
      console.log('------------------------------------------> PAYLOAD %o', payload);
      self.http.post<any>('https://forecast.dentalpro.it/api/fmc_fatturazione_mese_send_mail/', payload).subscribe(data => {
        console.log('------------------------------------------> MAIL SENT %o', data);
      });

      //self.gceService.sendEmail(payload).subscribe(
      //  d => {
      //    console.log('------------------------------------------> MAIL SENT %o', d);
      //  }
      //);
    };
    fileReader.readAsBinaryString(blb);

  }

  confirm() {
    this.submitted = true;
    let formData = this.form.getRawValue();
    if (formData['note_hr'] != null)
      formData['note_hr'] = this._sanitizer.sanitize(SecurityContext.HTML, formData['note_hr']);
    if (formData['note'] != null)
      formData['note'] = this._sanitizer.sanitize(SecurityContext.HTML, formData['note']);
    if (formData['nota_riporto_mese_succ'] != null)
      formData['nota_riporto_mese_succ'] = this._sanitizer.sanitize(SecurityContext.HTML, formData['nota_riporto_mese_succ']);

    console.log('ts_conferma_clinica BEFORE %o', formData['ts_conferma_clinica']);
    //check dates
    if (this.form.valid) {

      const confirm: Confirm = {
        title: "GCE.GCEINVOICE.DIALOG.CONFIRMINVOICE.TITLE",
        message: "GCE.GCEINVOICE.DIALOG.CONFIRMINVOICE.MESSAGE",
        buttonOkText: "GCE.GCEINVOICE.DIALOG.CONFIRMINVOICE.BUTTON.OK",
        buttonCancelText: "GCE.GCEINVOICE.DIALOG.CONFIRMINVOICE.BUTTON.CANCEL",
        buttonOkStyle: { 'background-color': '#268B26' },
        buttonCancelStyle: { 'background-color': '#AA3939' },
        withTranslate: true,
        messageParams: [{ key: 'INVOICE_NUMBER', value: this.invoice.id }]
      };

      this.alertDialog.confirm(confirm).subscribe(result => {
        if (result) {
          if (this.isAdmin == true && this.isPersonWithError == true) {
            window.alert("Att.ne: il medico destinatario della liquidazione ha errori in anagrafica; è necessario risolverli prima di confermare la liquidazione.")
          }
          else {

            var d = new Date(Date.now());
            this.loaderService.show();
            if (this.isAdmin == true) {
              formData['user_conferma_hr'] = this.usr;
              formData['ts_conferma_hr'] = moment(d).format(dateFormat);
              formData['flg_conferma_hr'] = true;
              if (!(formData['flg_conferma_clinica'] == true)) {
                formData['flg_conferma_clinica'] = true;
                formData['ts_conferma_clinica'] = moment(d).format(dateFormat);
                formData['user_conferma_clinica'] = 'da HR -' + this.usr;
              }
            }
            else {
              formData['flg_conferma_clinica'] = true;
              formData['user_conferma_clinica'] = this.usr;
              formData['ts_conferma_clinica'] = moment(d).format(dateFormat);
            }

            console.log('FORMDATA for CONFIRM %o', formData);


            this.gceService.updateInvoice(formData).subscribe((result: GceInvoice) => {

              if (this.isAdmin == true) {
                //emit doc
                this.gceService.calculateBillingDoctor(this.invoice.cod_clinica, this.invoice.idfatturazione, this.gceService.dataRepoGceEnvironment.getToDate()).subscribe(r => {
                  console.log('response calculateBillingDoctor %o',r);
                  this.gceService.confirmInvoice(this.invoice).subscribe((d) => {
                    console.log('response confirmInvoice %o',d);
                    /*
                                        this.oldinvoice = this.invoice;
                    
                                        this.invoice = d;
                    
                                        this.invoice.person = this.oldinvoice.person;
                                        this.invoice.clinic = this.oldinvoice.clinic;
                                        */

                    console.log('after recalculate confirm ret update %o billing %o ret confirm', result, r, d);
                    if (!(this.invoice.person.email == null) && (this.invoice.person.email.length > 0)) {
                      //get report
                      this.calcValuesForReport();
                      //send email
                      let t1 = null;
                      if (!(this.t1 == null))
                        t1 = Math.round(this.t1 * 100) / 100;
                      let t2 = null;
                      if (!(this.t2 == null))
                        t2 = Math.round(this.t2 * 100) / 100;
                      let t3 = null;
                      if (!(this.t3 == null))
                        t3 = Math.round(this.t3 * 100) / 100;
                      let t4 = null;
                      if (!(this.t4 == null))
                        t4 = Math.round(this.t4 * 100) / 100;
                      let t5 = null;
                      if (!(this.t5 == null))
                        t5 = Math.round(this.t5 * 100) / 100;
                      let t6 = null;
                      if (!(this.t6 == null))
                        t6 = Math.round(this.t6 * 100) / 100;
                      let t7 = null;
                      if (!(this.t7 == null))
                        t7 = Math.round(this.t7 * 100) / 100;
                      let t11_1 = null;
                      if (!(this.t11_1 == null))
                        t11_1 = Math.round(this.t11_1 * 100) / 100;
                      let t11_2 = null;
                      if (!(this.t11_2 == null))
                        t11_2 = Math.round(this.t11_2 * 100) / 100;
                      let t11_3 = null;
                      if (!(this.t11_3 == null))
                        t11_3 = Math.round(this.t11_3 * 100) / 100;
                      let t12_1 = null;
                      if (!(this.t12_1 == null))
                        t12_1 = Math.round(this.t12_1 * 100) / 100;
                      let t12_2 = null;
                      if (!(this.t12_2 == null))
                        t12_2 = Math.round(this.t12_2 * 100) / 100;
                      let t12_3 = null;
                      if (!(this.t12_3 == null))
                        t12_3 = Math.round(this.t12_3 * 100) / 100;
                      let t13_t = null;
                      if (!(this.t13_t == null))
                        t13_t = Math.round(this.t13_t * 100) / 100;
                      let t14_1 = null;
                      if (!(this.t14_1 == null))
                        t14_1 = Math.round(this.t14_1 * 100) / 100;
                      let t14_2 = null;
                      if (!(this.t14_2 == null))
                        t14_2 = Math.round(this.t14_2 * 100) / 100;
                      let t14_3 = null;
                      if (!(this.t14_3 == null))
                        t14_3 = Math.round(this.t14_3 * 100) / 100;
                      let t15_1 = null;
                      if (!(this.t15_1 == null))
                        t15_1 = Math.round(this.t15_1 * 100) / 100;
                      let t15_2 = null;
                      if (!(this.t15_2 == null))
                        t15_2 = Math.round(this.t15_2 * 100) / 100;
                      let t15_3 = null;
                      if (!(this.t15_3 == null))
                        t15_3 = Math.round(this.t15_3 * 100) / 100;
                      let t16_1 = null;
                      if (!(this.t16_1 == null))
                        t16_1 = Math.round(this.t16_1 * 100) / 100;
                      let t16_2 = null;
                      if (!(this.t16_2 == null))
                        t16_2 = Math.round(this.t16_2 * 100) / 100;
                      let t16_3 = null;
                      if (!(this.t16_3 == null))
                        t16_3 = Math.round(this.t16_3 * 100) / 100;
                      let t17_1 = null;
                      if (!(this.t17_1 == null))
                        t17_1 = Math.round(this.t17_1 * 100) / 100;
                      let t17_2 = null;
                      if (!(this.t17_2 == null))
                        t17_2 = Math.round(this.t17_2 * 100) / 100;
                      let t17_3 = null;
                      if (!(this.t17_3 == null))
                        t17_3 = Math.round(this.t17_3 * 100) / 100;
                      let t18_1 = null;
                      if (!(this.t18_1 == null))
                        t18_1 = Math.round(this.t18_1 * 100) / 100;
                      let t18_2 = null;
                      if (!(this.t18_2 == null))
                        t18_2 = Math.round(this.t18_2 * 100) / 100;
                      let t18_3 = null;
                      if (!(this.t18_3 == null))
                        t18_3 = Math.round(this.t18_3 * 100) / 100;
                      let t18A_2 = null;
                      if (!(this.t18A_2 == null))
                        t18A_2 = Math.round(this.t18A_2 * 100) / 100;
                      let t18A_3 = null;
                      if (!(this.t18A_3 == null))
                        t18A_3 = Math.round(this.t18A_3 * 100) / 100;
                      let t19_1 = null;
                      if (!(this.t19_1 == null))
                        t19_1 = Math.round(this.t19_1 * 100) / 100;
                      let t19_2 = null;
                      if (!(this.t19_2 == null))
                        t19_2 = Math.round(this.t19_2 * 100) / 100;
                      let t19_3 = null;
                      if (!(this.t19_3 == null))
                        t19_3 = Math.round(this.t19_3 * 100) / 100;
                      let t110_t = null;
                      if (!(this.t110_t == null))
                        t110_t = Math.round(this.t110_t * 100) / 100;
                      let t20_3 = null;
                      if (!(this.t20_3 == null))
                        t20_3 = Math.round(this.t20_3 * 100) / 100;
                      let t21_3 = null;
                      if (!(this.t21_3 == null))
                        t21_3 = Math.round(this.t21_3 * 100) / 100;
                      let blb = this.gceService.exportPerformanceCalculationDetailDefToExcel(this.invoice,
                        this.details,
                        this.detracts,
                        t1,
                        t2,
                        t3,
                        t4,
                        t5,
                        t6,
                        t7,
                        t11_1,
                        t11_2,
                        t11_3,
                        t12_1,
                        t12_2,
                        t12_3,
                        t13_t,
                        t14_1,
                        t14_2,
                        t14_3,
                        t15_1,
                        t15_2,
                        t15_3,
                        t16_1,
                        t16_2,
                        t16_3,
                        t17_1,
                        t17_2,
                        t17_3,
                        t18_1,
                        t18_2,
                        t18_3,
                        t18A_2,
                        t18A_3,
                        t19_1,
                        t19_2,
                        t19_3,
                        t110_t,
                        t20_3,
                        t21_3,
                        false
                      );

                      this.dialogRef.close({ saved: true, invoice: result });
                      this.loaderService.hide();

                      //console.log('------------------------------------------> BLOB %o', blb);
                      /*
                      let self = this;

                      let fileReader = new FileReader();

                      fileReader.onloadend = function () {
                        // console.log('------------------------------------------> BLB', fileReader.result);
                        let payload = {
                          email: 'seveso@dentalpro.it',//self.oldinvoice.person.email,
                          subject: 'Report liquidazione mensile',
                          message: "<div>Gentile Dottore,<br> in allegato trova il suo report per la clinica di " + self.oldinvoice.clinic.nome + "<br>La pre-fattura è stata emessa; sarà visibile nel portale al massimo entro 24 h.<br>Cordiali Saluti<br><br><p><i>Questa e-mail e tutti i suoi allegati sono da intendersi confidenziali e riservati. Se non siete l'effettivo destinatario o avete ricevuto il messaggio per errore siete pregati di cancellarlo dal vostro sistema e di avvisare il mittente.</i></p><p><i>Siete altresì informati che qualsiasi utilizzo, copia, comunicazione, divulgazione dei contenuti del messaggio e dei suoi allegati, a qualunque titolo, è vietata dalla legge.</i></p><p><i>This email and any attachments are privileged and confidential. If you are not the intended recipient please delete it from your system and notify the sender.</i></p><p><i>You should not copy it or use it for any purpose nor disclose or distribute its contents to any other person. You should as well be aware that any use, copy, disclosure and distribution of the contents and the possible attachments of this email, at any title, is forbidden by the Law.</i></p></div>",
                          attachFile: btoa(fileReader.result.toString()).replace(/.{76}(?=.)/g, '$&\n')
                        };
                        console.log('------------------------------------------> PAYLOAD %o', payload);
                        self.http.post<any>('https://forecast.dentalpro.it/api/fmc_fatturazione_mese_send_mail/', payload).subscribe(data => {
                          //console.log('------------------------------------------> MAIL SENT %o', data);

                          self.dialogRef.close({ saved: true, invoice: d });
                          self.loaderService.hide();
                        },
                          err => {

                            self.dialogRef.close({ saved: true, invoice: d });
                            self.loaderService.hide();
                          });

                      };
                      fileReader.readAsBinaryString(blb);
                      */
                    }
                    else {
                      //no email
                      this.invoice = result;
                      this.dialogRef.close({ saved: true, invoice: result });
                      this.loaderService.hide();
                    }

                  },
                  err => {
                    console.log('ERROR CONFIRM INVOICE %o',err);
                    this.alertDialog.open({
                      message: 'GCE.GCEINVOICE.DIALOG.CONFIRMINVOICE.ERROR.MESSAGE',
                      isError: true,
                      withTranslate: true
                    });
                    this.loaderService.hide();
                  });

                  /*
                  this.alertDialog.open({
                    message: 'GCE.GCEINVOICE.DIALOG.CONFIRMEDINVOICE.MESSAGE',
                    isError: false,
                    withTranslate: true
                  });
                  */
                }, err => {
                  console.log('ERROR CONFIRM INVOICE %o',err);
                  this.alertDialog.open({
                    message: 'GCE.GCEINVOICE.DIALOG.CONFIRMINVOICE.ERROR.MESSAGE',
                    isError: true,
                    withTranslate: true
                  });
                  this.loaderService.hide();
                });

              }
              else {
                this.dialogRef.close({ saved: true, invoice: result });
                this.loaderService.hide();
              }
            }, err => {
              this.loaderService.hide();
            });

          }


        }
      });
    }
  }

  delete() {
    this.submitted = true;
    let formData = this.form.getRawValue();
    if (formData['note_hr'] != null)
      formData['note_hr'] = this._sanitizer.sanitize(SecurityContext.HTML, formData['note_hr']);
    if (formData['note'] != null)
      formData['note'] = this._sanitizer.sanitize(SecurityContext.HTML, formData['note']);
    if (formData['nota_riporto_mese_succ'] != null)
      formData['nota_riporto_mese_succ'] = this._sanitizer.sanitize(SecurityContext.HTML, formData['nota_riporto_mese_succ']);

    console.log('ts_conferma_clinica BEFORE %o', formData['ts_conferma_clinica']);
    //check dates
    if (this.form.valid && this.isAdmin == true) {

      const confirm: Confirm = {
        title: "GCE.GCEINVOICE.DIALOG.REMOVEINVOICE.TITLE",
        message: "GCE.GCEINVOICE.DIALOG.REMOVEINVOICE.MESSAGE",
        buttonOkText: "GCE.GCEINVOICE.DIALOG.CONFIRMINVOICE.BUTTON.OK",
        buttonCancelText: "GCE.GCEINVOICE.DIALOG.CONFIRMINVOICE.BUTTON.CANCEL",
        buttonOkStyle: { 'background-color': '#268B26' },
        buttonCancelStyle: { 'background-color': '#AA3939' },
        withTranslate: true,
        messageParams: [{ key: 'INVOICE_NUMBER', value: this.invoice.id }]
      };

      this.alertDialog.confirm(confirm).subscribe(result => {
        if (result) {
          var d = new Date(Date.now());
          this.loaderService.show();
          console.log('FORMDATA for REMOVE %o', formData);
          this.gceService.removeInvoice(formData).subscribe((result: GceInvoice) => {
            this.dialogRef.close({ saved: false, invoice: result, removed: true });
            this.loaderService.hide();
          }, err => {
            this.loaderService.hide();
          });
        }
      });
    }
  }

  nullinvoice() {
    this.submitted = true;
    let formData = this.form.getRawValue();
    if (formData['note_hr'] != null)
      formData['note_hr'] = this._sanitizer.sanitize(SecurityContext.HTML, formData['note_hr']);
    if (formData['note'] != null)
      formData['note'] = this._sanitizer.sanitize(SecurityContext.HTML, formData['note']);
    if (formData['nota_riporto_mese_succ'] != null)
      formData['nota_riporto_mese_succ'] = this._sanitizer.sanitize(SecurityContext.HTML, formData['nota_riporto_mese_succ']);
    console.log('ts_conferma_clinica BEFORE %o', formData['ts_conferma_clinica']);
    //check dates
    if (this.form.valid && this.isAdmin == true) {

      const confirm: Confirm = {
        title: "GCE.GCEINVOICE.DIALOG.DELETEINVOICE.TITLE",
        message: "GCE.GCEINVOICE.DIALOG.DELETEINVOICE.MESSAGE",
        buttonOkText: "GCE.GCEINVOICE.DIALOG.CONFIRMINVOICE.BUTTON.OK",
        buttonCancelText: "GCE.GCEINVOICE.DIALOG.CONFIRMINVOICE.BUTTON.CANCEL",
        buttonOkStyle: { 'background-color': '#268B26' },
        buttonCancelStyle: { 'background-color': '#AA3939' },
        withTranslate: true,
        messageParams: [{ key: 'INVOICE_NUMBER', value: this.invoice.id }]
      };

      this.alertDialog.confirm(confirm).subscribe(result => {
        if (result) {
          var d = new Date(Date.now());
          this.loaderService.show();
          formData['utente_richiesta_annullo_nav'] = this.usr;
          formData['ts_annullo_nav'] = moment(d).format(dateFormat);
          console.log('FORMDATA for CONFIRM %o', formData);
          this.gceService.updateInvoice(formData).subscribe((result: GceInvoice) => {


            //emit doc
            this.gceService.deleteInvoice(this.invoice).subscribe((d) => {
              this.dialogRef.close({ saved: true, invoice: d });
              /*
              this.alertDialog.open({
                message: 'GCE.GCEINVOICE.DIALOG.CONFIRMEDINVOICE.MESSAGE',
                isError: false,
                withTranslate: true
              });
              */
              this.loaderService.hide();
            }, err => {
              this.alertDialog.open({
                message: 'GCE.GCEINVOICE.DIALOG.CONFIRMINVOICE.ERROR.MESSAGE',
                isError: true,
                withTranslate: true
              });
              this.loaderService.hide();
            });


          }, err => {
            this.loaderService.hide();
          });
        }
      });
    }
  }

  cancel() {
    this.dialogRef.close({ saved: false });
  }

  meseChange(event) {

  }
}
