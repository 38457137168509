import { Directive, Input, HostListener, ElementRef } from '@angular/core';

const FOCUSABLES = ['input', 'select', 'textarea', 'button', 'object', 'mat-select', 'div'];
const FOCUSABLES_SELECTOR = FOCUSABLES.join(',');

@Directive({
  selector: '[tabindex]'
})
export class TabindexDirective {
  @Input('tabindex') tabindex: string;

  constructor() { }

  @HostListener('keydown', ['$event']) onInputChange(e){
    var code = e.keyCode || e.which;
    if(code===13){
      let next: HTMLElement = this.geFocusElement(e.srcElement);
      if (next) {
				e.preventDefault();
        next.focus();
			}
    }
  }

  private geFocusElement(elem: HTMLElement): HTMLElement {
    let tindex: number = parseInt(this.tabindex || '0');
    // console.log("%o, %o",this.tabindex, elem);
    let next: HTMLElement = this.getNextFocusableElement(elem, 'tabindex', tindex);
    if(!next){
      next = this.getNextFocusableElement(elem, 'ng-reflect-tabindex', tindex);
    }
    return next;
  }

	private getNextFocusableElement(elem: HTMLElement, attrName: string, tabindex: number): HTMLElement {
		let form = this.getFormElement(elem);
		let next = null;

		tabindex++;
		next = this.getElement(form, attrName, tabindex);

		while (next) {
			next = this.getFocusableElement(next);

			if (next) {
				return next;
			}

			tabindex++;
			next = this.getElement(form, attrName, tabindex);
		}

		return null;
	}

	private getFormElement(elem: HTMLElement): HTMLElement {
		let form: HTMLFormElement = elem ? (<HTMLInputElement>elem).form : null;
		return form;
	}

	private getElement(form: HTMLElement, attrName: string, tabindex: number): HTMLElement {
		let selector = `[${attrName}="${tabindex}"]`;
    //console.log("form", form);
		let elem = form ? <HTMLElement>form.querySelector(selector) : null;
		return elem;
	}

	private getFocusableElement(elem: HTMLElement): HTMLElement {
		let tagName = elem.tagName.toLowerCase();
		let focusable = FOCUSABLES.some(
			tagFocusable => tagFocusable === tagName
		);

		if (!focusable) {
			elem = <HTMLElement>elem.querySelector(FOCUSABLES_SELECTOR);
			focusable = !!elem;
		}

		if (focusable) {
			//TODO: verify if elem disabled, readonly, hidden, etc...
			// in which case focusable must be changed to false
		}

		if (focusable) {
			return elem;
		}
	}
}
