import { Component, OnInit, ViewChild } from '@angular/core';
import { SsoMonService } from '../services/sso-mon.service';
import { SsoMonLog } from '../entities/sso-mon/sso-mon-log';
import { DatatableComponent } from '../shared/datatable/datatable.component';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { AlertDialogService } from '../services/alert-dialog.service';
import { AuthGuardService } from '../services';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FormHeaderComponent, ICustomButton, IFormHeaderSearch, FormHeaderSearchEventsEnum } from '../shared/form-header/form-header.component';
import { LoaderService } from '../loader/loader.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as moment from 'moment';
const dateFormat = "YYYYMMDD";
const simpleDateFormat = "DD/MM";
const hoursFormat = "HH:mm:ss";
const longFormat = "YYYY-MM-DDTHH:mm:ss.SSS";

@Component({
  selector: 'app-sso-mon',
  templateUrl: './sso-mon.component.html',
  styleUrls: ['./sso-mon.component.css']
})
export class SsoMonComponent implements OnInit {
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  public viewChart = false;
  public lineChartData: Array<number> = [];
  public totalChartData: Array<number> = [];
  public errorChartData: Array<number> = [];

  public labelMFL: Array<any> = [
    {
      data: this.errorChartData,
      label: "errori",
      pointRadius: 0
    },
    {
      data: this.totalChartData,
      label: "totali",
      pointRadius: 0
    }
  ];
  // labels
  public lineChartLabels: Array<string> = [];

  public lineChartOptions: any = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          max: 80,
          min: 0,
        }
      }],
      xAxes: [{
        /*
        ticks: {
          callback: function (value) {
            //console.log('value ', value);
            if ((value.indexOf(":") > 0) || (value.indexOf("/") > 0)) {
              return value;
            }
            return null;
          }
        },
        */
      }],
    },
    plugins: {
      datalabels: {
        display: true,
        align: 'top',
        anchor: 'end',
        font: {
          family: 'FontAwesome',
          size: 12
        },
      },
      deferred: false
    },

  };

  _lineChartColors: Array<any> = [{
    backgroundColor: 'blue',
    borderColor: 'blue',
    //pointBackgroundColor: 'blue',
    // pointBorderColor: 'blue',
    //pointHoverBackgroundColor: 'blue',
    // pointHoverBorderColor: 'blue'
  }];

  public ChartType = 'line';

  public chartClicked(e: any): void {
    console.log(e);
  }
  public chartHovered(e: any): void {
    console.log(e);
  }
  sectInHours: number = 12;
  logs: any;
  columns: any[] = [];
  role: string = '';
  dm: string = '';
  usr: string = '';
  showb: boolean = false;
  isAdmin: boolean = false;
  limitRows: number;
  heightMainList: number;
  customButtons: ICustomButton[] = [];
  fromDate: string;// = this.gceService.dataRepoGceEnvironment.getFromDate();
  toDate: string;// = this.gceService.dataRepoGceEnvironment.getToDate();
  keyColumn: any = {};
  current: string;

  constructor(private dialog: MatDialog, private route: Router, private ssoMonService: SsoMonService,
    private translateService: TranslateService,
    private alertDialog: AlertDialogService, private auth: AuthGuardService, private loaderService: LoaderService, public http: HttpClient) {
    this.routeEvent(this.route);
    this.customButtons.push({
      matTooltipCode: 'SSOMON.REFRESH',
      labelCode: 'SSOMON.REFRESH',
      img: 'assets/img/invoice-confirm.png',
      class: 'custom-button',
      clickFunction: this.refresh.bind(this)
    });
    this.customButtons.push({
      matTooltipCode: 'SSOMON.EXCEL',
      labelCode: 'SSOMON.EXCEL',
      img: 'assets/img/excel2.png',
      class: 'custom-button',
      clickFunction: this.exportExcel.bind(this)
    });
  }

  setCurrent(h) {
    if (h && h.length > 2) {
      h = h.slice(2);
    }
    this.current = h.split("/")[0];
  }

  routeEvent(router: Router) {
    router.events.subscribe(e => {
      // console.log("first consolelog %o",e);
      if (e instanceof NavigationEnd) {
        // console.log(e);
        this.setCurrent(window.location.hash);
      }
    });
  }

  exportExcel() {
    // if (this.isAdmin == true) {
    this.ssoMonService.exportLogs(this.logs);
    //}
    //else {
    //  window.alert('Attenzione: il tuo ruolo non è abilitato a questa funzione.');
    //}
  }

  refresh() {
    console.log('refresh from %o to %o', this.fromDate, this.toDate);
    this.totalChartData = [];
    this.errorChartData = [];
    this.lineChartLabels = [];
   // var ds = 1 + parseInt(this.toDate.substr(6, 2)) - parseInt(this.fromDate.substr(6, 2));
    const ds = this.getDifferenceDate(this.fromDate, this.toDate);
    console.log('ds %o', ds);
    if (ds == 1) {
      this.ChartType = 'line';
      this.lineChartOptions.scales.yAxes[0].ticks.max = 80;
      this.lineChartLabels.push("00:00");
      this.lineChartLabels.push("00:05");
      this.lineChartLabels.push("00:10");
      this.lineChartLabels.push("00:15");
      this.lineChartLabels.push("00:20");
      this.lineChartLabels.push("00:25");
      this.lineChartLabels.push("00:30");
      this.lineChartLabels.push("00:35");
      this.lineChartLabels.push("00:40");
      this.lineChartLabels.push("00:45");
      this.lineChartLabels.push("00:50");
      this.lineChartLabels.push("00:55");
      this.lineChartLabels.push("01:00");
      this.lineChartLabels.push("01:05");
      this.lineChartLabels.push("01:10");
      this.lineChartLabels.push("01:15");
      this.lineChartLabels.push("01:20");
      this.lineChartLabels.push("01:25");
      this.lineChartLabels.push("01:30");
      this.lineChartLabels.push("01:35");
      this.lineChartLabels.push("01:40");
      this.lineChartLabels.push("01:45");
      this.lineChartLabels.push("01:50");
      this.lineChartLabels.push("01:55");
      this.lineChartLabels.push("02:00");
      this.lineChartLabels.push("02:05");
      this.lineChartLabels.push("02:10");
      this.lineChartLabels.push("02:15");
      this.lineChartLabels.push("02:20");
      this.lineChartLabels.push("02:25");
      this.lineChartLabels.push("02:30");
      this.lineChartLabels.push("02:35");
      this.lineChartLabels.push("02:40");
      this.lineChartLabels.push("02:45");
      this.lineChartLabels.push("02:50");
      this.lineChartLabels.push("02:55");
      this.lineChartLabels.push("03:00");
      this.lineChartLabels.push("03:05");
      this.lineChartLabels.push("03:10");
      this.lineChartLabels.push("03:15");
      this.lineChartLabels.push("03:20");
      this.lineChartLabels.push("03:25");
      this.lineChartLabels.push("03:30");
      this.lineChartLabels.push("03:35");
      this.lineChartLabels.push("03:40");
      this.lineChartLabels.push("03:45");
      this.lineChartLabels.push("03:50");
      this.lineChartLabels.push("03:55");
      this.lineChartLabels.push("04:00");
      this.lineChartLabels.push("04:05");
      this.lineChartLabels.push("04:10");
      this.lineChartLabels.push("04:15");
      this.lineChartLabels.push("04:20");
      this.lineChartLabels.push("04:25");
      this.lineChartLabels.push("04:30");
      this.lineChartLabels.push("04:35");
      this.lineChartLabels.push("04:40");
      this.lineChartLabels.push("04:45");
      this.lineChartLabels.push("04:50");
      this.lineChartLabels.push("04:55");
      this.lineChartLabels.push("05:00");
      this.lineChartLabels.push("05:05");
      this.lineChartLabels.push("05:10");
      this.lineChartLabels.push("05:15");
      this.lineChartLabels.push("05:20");
      this.lineChartLabels.push("05:25");
      this.lineChartLabels.push("05:30");
      this.lineChartLabels.push("05:35");
      this.lineChartLabels.push("05:40");
      this.lineChartLabels.push("05:45");
      this.lineChartLabels.push("05:50");
      this.lineChartLabels.push("05:55");
      this.lineChartLabels.push("06:00");
      this.lineChartLabels.push("06:05");
      this.lineChartLabels.push("06:10");
      this.lineChartLabels.push("06:15");
      this.lineChartLabels.push("06:20");
      this.lineChartLabels.push("06:25");
      this.lineChartLabels.push("06:30");
      this.lineChartLabels.push("06:35");
      this.lineChartLabels.push("06:40");
      this.lineChartLabels.push("06:45");
      this.lineChartLabels.push("06:50");
      this.lineChartLabels.push("06:55");
      this.lineChartLabels.push("07:00");
      this.lineChartLabels.push("07:05");
      this.lineChartLabels.push("07:10");
      this.lineChartLabels.push("07:15");
      this.lineChartLabels.push("07:20");
      this.lineChartLabels.push("07:25");
      this.lineChartLabels.push("07:30");
      this.lineChartLabels.push("07:35");
      this.lineChartLabels.push("07:40");
      this.lineChartLabels.push("07:45");
      this.lineChartLabels.push("07:50");
      this.lineChartLabels.push("07:55");
      this.lineChartLabels.push("08:00");
      this.lineChartLabels.push("08:05");
      this.lineChartLabels.push("08:10");
      this.lineChartLabels.push("08:15");
      this.lineChartLabels.push("08:20");
      this.lineChartLabels.push("08:25");
      this.lineChartLabels.push("08:30");
      this.lineChartLabels.push("08:35");
      this.lineChartLabels.push("08:40");
      this.lineChartLabels.push("08:45");
      this.lineChartLabels.push("08:50");
      this.lineChartLabels.push("08:55");
      this.lineChartLabels.push("09:00");
      this.lineChartLabels.push("09:05");
      this.lineChartLabels.push("09:10");
      this.lineChartLabels.push("09:15");
      this.lineChartLabels.push("09:20");
      this.lineChartLabels.push("09:25");
      this.lineChartLabels.push("09:30");
      this.lineChartLabels.push("09:35");
      this.lineChartLabels.push("09:40");
      this.lineChartLabels.push("09:45");
      this.lineChartLabels.push("09:50");
      this.lineChartLabels.push("09:55");
      this.lineChartLabels.push("10:00");
      this.lineChartLabels.push("10:05");
      this.lineChartLabels.push("10:10");
      this.lineChartLabels.push("10:15");
      this.lineChartLabels.push("10:20");
      this.lineChartLabels.push("10:25");
      this.lineChartLabels.push("10:30");
      this.lineChartLabels.push("10:35");
      this.lineChartLabels.push("10:40");
      this.lineChartLabels.push("10:45");
      this.lineChartLabels.push("10:50");
      this.lineChartLabels.push("10:55");
      this.lineChartLabels.push("11:00");
      this.lineChartLabels.push("11:05");
      this.lineChartLabels.push("11:10");
      this.lineChartLabels.push("11:15");
      this.lineChartLabels.push("11:20");
      this.lineChartLabels.push("11:25"); this.lineChartLabels.push("11:30"); this.lineChartLabels.push("11:35"); this.lineChartLabels.push("11:40"); this.lineChartLabels.push("11:45"); this.lineChartLabels.push("11:50"); this.lineChartLabels.push("11:55");
      this.lineChartLabels.push("12:00");
      this.lineChartLabels.push("12:05");
      this.lineChartLabels.push("12:10");
      this.lineChartLabels.push("12:15");
      this.lineChartLabels.push("12:20");
      this.lineChartLabels.push("12:25");
      this.lineChartLabels.push("12:30");
      this.lineChartLabels.push("12:35");
      this.lineChartLabels.push("12:40");
      this.lineChartLabels.push("12:45");
      this.lineChartLabels.push("12:50");
      this.lineChartLabels.push("12:55");
      this.lineChartLabels.push("13:00");
      this.lineChartLabels.push("13:05");
      this.lineChartLabels.push("13:10"); this.lineChartLabels.push("13:15"); this.lineChartLabels.push("13:20"); this.lineChartLabels.push("13:25"); this.lineChartLabels.push("13:30"); this.lineChartLabels.push("13:35"); this.lineChartLabels.push("13:40"); this.lineChartLabels.push("13:45"); this.lineChartLabels.push("13:50"); this.lineChartLabels.push("13:55");
      this.lineChartLabels.push("14:00");
      this.lineChartLabels.push("14:05");
      this.lineChartLabels.push("14:10");
      this.lineChartLabels.push("14:15");
      this.lineChartLabels.push("14:20"); this.lineChartLabels.push("14:25"); this.lineChartLabels.push("14:30"); this.lineChartLabels.push("14:35"); this.lineChartLabels.push("14:40"); this.lineChartLabels.push("14:45"); this.lineChartLabels.push("14:50"); this.lineChartLabels.push("14:55");
      this.lineChartLabels.push("15:00");
      this.lineChartLabels.push("15:05");
      this.lineChartLabels.push("15:10");
      this.lineChartLabels.push("15:15");
      this.lineChartLabels.push("15:20");
      this.lineChartLabels.push("15:25");
      this.lineChartLabels.push("15:30");
      this.lineChartLabels.push("15:35");
      this.lineChartLabels.push("15:40");
      this.lineChartLabels.push("15:45");
      this.lineChartLabels.push("15:50");
      this.lineChartLabels.push("15:55");
      this.lineChartLabels.push("16:00");
      this.lineChartLabels.push("16:05");
      this.lineChartLabels.push("16:10");
      this.lineChartLabels.push("16:15");
      this.lineChartLabels.push("16:20");
      this.lineChartLabels.push("16:25");
      this.lineChartLabels.push("16:30");
      this.lineChartLabels.push("16:35");
      this.lineChartLabels.push("16:40");
      this.lineChartLabels.push("16:45");
      this.lineChartLabels.push("16:50");
      this.lineChartLabels.push("16:55");
      this.lineChartLabels.push("17:00");
      this.lineChartLabels.push("17:05");
      this.lineChartLabels.push("17:10");
      this.lineChartLabels.push("17:15");
      this.lineChartLabels.push("17:20");
      this.lineChartLabels.push("17:25");
      this.lineChartLabels.push("17:30");
      this.lineChartLabels.push("17:35");
      this.lineChartLabels.push("17:40");
      this.lineChartLabels.push("17:45");
      this.lineChartLabels.push("17:50");
      this.lineChartLabels.push("17:55");
      this.lineChartLabels.push("18:00");
      this.lineChartLabels.push("18:05");
      this.lineChartLabels.push("18:10");
      this.lineChartLabels.push("18:15");
      this.lineChartLabels.push("18:20");
      this.lineChartLabels.push("18:25");
      this.lineChartLabels.push("18:30");
      this.lineChartLabels.push("18:35");
      this.lineChartLabels.push("18:40");
      this.lineChartLabels.push("18:45");
      this.lineChartLabels.push("18:50");
      this.lineChartLabels.push("18:55");
      this.lineChartLabels.push("19:00");
      this.lineChartLabels.push("19:05");
      this.lineChartLabels.push("19:10");
      this.lineChartLabels.push("19:15");
      this.lineChartLabels.push("19:20");
      this.lineChartLabels.push("19:25");
      this.lineChartLabels.push("19:30");
      this.lineChartLabels.push("19:35");
      this.lineChartLabels.push("19:40");
      this.lineChartLabels.push("19:45");
      this.lineChartLabels.push("19:50");
      this.lineChartLabels.push("19:55");
      this.lineChartLabels.push("20:00");
      this.lineChartLabels.push("20:05");
      this.lineChartLabels.push("20:10");
      this.lineChartLabels.push("20:15");
      this.lineChartLabels.push("20:20");
      this.lineChartLabels.push("20:25");
      this.lineChartLabels.push("20:30");
      this.lineChartLabels.push("20:35");
      this.lineChartLabels.push("20:40");
      this.lineChartLabels.push("20:45");
      this.lineChartLabels.push("20:50");
      this.lineChartLabels.push("20:55");
      this.lineChartLabels.push("21:00");
      this.lineChartLabels.push("21:05");
      this.lineChartLabels.push("21:10");
      this.lineChartLabels.push("21:15");
      this.lineChartLabels.push("21:20");
      this.lineChartLabels.push("21:25");
      this.lineChartLabels.push("21:30");
      this.lineChartLabels.push("21:35");
      this.lineChartLabels.push("21:40");
      this.lineChartLabels.push("21:45");
      this.lineChartLabels.push("21:50");
      this.lineChartLabels.push("21:55");
      this.lineChartLabels.push("22:00");
      this.lineChartLabels.push("22:05");
      this.lineChartLabels.push("22:10");
      this.lineChartLabels.push("22:15");
      this.lineChartLabels.push("22:20");
      this.lineChartLabels.push("22:25");
      this.lineChartLabels.push("22:30");
      this.lineChartLabels.push("22:35");
      this.lineChartLabels.push("22:40");
      this.lineChartLabels.push("22:45");
      this.lineChartLabels.push("22:50");
      this.lineChartLabels.push("22:55");
      this.lineChartLabels.push("23:00");
      this.lineChartLabels.push("23:05");
      this.lineChartLabels.push("23:10");
      this.lineChartLabels.push("23:15");
      this.lineChartLabels.push("23:20");
      this.lineChartLabels.push("23:25");
      this.lineChartLabels.push("23:30");
      this.lineChartLabels.push("23:35");
      this.lineChartLabels.push("23:40");
      this.lineChartLabels.push("23:45");
      this.lineChartLabels.push("23:50");
      this.lineChartLabels.push("23:55");

    }
    else {
      this.ChartType = 'bar';
      this.lineChartOptions.scales.yAxes[0].ticks.max = 2000;
    }

    var sects = 24 * this.sectInHours;
    this.viewChart = false;
    for (let a = 0; a < ds; a++) {
      if (ds == 1) {
        for (let b = 0; b < sects; b++) {
          this.totalChartData.push(0);
          this.errorChartData.push(0);
        }
      }
      else {
        this.totalChartData.push(0);
        this.errorChartData.push(0);
        let dateIndicator = moment(this.fromDate).format(simpleDateFormat);
        if (a > 0) {
          let dS = moment(this.fromDate).format(dateFormat);
          let dSup = new Date(parseInt(dS.substr(0, 4)), parseInt(dS.substr(4, 2)) - 1, parseInt(dS.substr(6, 2)));
          dSup.setDate(dSup.getDate() + a);
          dateIndicator = moment(dSup.toDateString()).format(simpleDateFormat);
        }
        this.lineChartLabels.push(dateIndicator);
      }
    }

    this.showb = true;
    this.ssoMonService.loadLogs(this.fromDate.replace('-', ''), this.toDate.replace('-', '')).subscribe(results => {
      //console.log('response of loadLogs %o', results);
      this.logs = [];
      for (var a = 0; a < results.length; a++) {
        if (!(results[a].level == null) && results[a].level.toLowerCase == 'error') {
          this.logs.push(results[a]);
        }
        if (!(results[a].message == null) && (results[a].message.indexOf('CODEUX') > 0 || results[a].message.indexOf('CODEERR') > 0)) {
          this.logs.push(results[a]);
        }
      }
      this.buildMatrix();

      this.labelMFL[0].data = this.errorChartData;
      this.labelMFL[1].data = this.totalChartData;

      this.viewChart = true;
      this.showb = false;
      console.log('refresh   %o', this.lineChartLabels);
      console.log('refresh   %o', this.totalChartData);
      console.log('refresh   %o', this.errorChartData);
    }, err => {
      this.showb = false;
    });
  }

  onChangeRow(e: any) {

  }

  onCustomActionClick(e: any) {
    console.log('on customActionClick');
  }

  onSearch(searchFilter: IFormHeaderSearch) {
    console.log("onSearch filterValue %o", searchFilter);
    this.fromDate = moment(searchFilter.fromDate).format(dateFormat);
    this.toDate = moment(searchFilter.toDate).format(dateFormat);
    console.log("onSearch filterValue fromDate %o toDate %o", this.fromDate, this.toDate);
  }

  onToggle(e: any) {
    console.log('onToggle');
  }

  getIndex(d: any) {
    var hd = moment(d).format(hoursFormat);
    var ds = parseInt(moment(d).format(dateFormat).substr(6, 2)) - parseInt(this.fromDate.substr(6, 2));
    //console.log("getIndexDS %o", ds);
    var hours = parseInt(hd.substr(0, 2));
    var minutes = Math.floor(parseInt(hd.substr(3, 2)) / 5);
    var index = (ds * 24 * this.sectInHours + hours * this.sectInHours + minutes);
    return (index);
  }

  getIndexDay(d: any) {
    //var ds = parseInt(moment(d).format(dateFormat).substr(6, 2)) - parseInt(this.fromDate.substr(6, 2));

    var ds = this.getDifferenceDate(this.fromDate,moment(d).format(dateFormat));

    return (ds);
  }

  getDifferenceDate(fdi: any, tdi: any) {
    const td = new Date();
    const fd = new Date();

   // console.log("getDifferenceDate td %o fd %o", fdi, tdi);
    td.setFullYear(parseInt(tdi.substr(0, 4)));
    td.setMonth(parseInt(tdi.substr(4, 2)) - 1);
    td.setDate(parseInt(tdi.substr(6, 2)));
    td.setHours(0);
    td.setMinutes(0);
    td.setSeconds(0);
    td.setMilliseconds(0);
    fd.setFullYear(parseInt(fdi.substr(0, 4)));
    fd.setMonth(parseInt(fdi.substr(4, 2)) - 1);
    fd.setDate(parseInt(fdi.substr(6, 2)));
    fd.setHours(0);
    fd.setMinutes(0);
    fd.setSeconds(0);
    fd.setMilliseconds(0);

    //console.log("getDifferenceDate td %o fd %o", td, fd);
    const tsDifference = td.getTime() - fd.getTime();
    return (1 + Math.floor(tsDifference / (1000 * 60 * 60 * 24)));
  }

  buildMatrix() {
    console.log("buildMatrix_this.logs %o", this.logs);
    if (!(this.logs == null)) {
      //var ds = 1 + parseInt(this.toDate.substr(6, 2)) - parseInt(this.fromDate.substr(6, 2));
      const ds = this.getDifferenceDate(this.fromDate, this.toDate);
      console.log("buildMatrix_ds %o", ds);
      if (ds == 1) {
        for (var a = 0; a < this.logs.length; a++) {
          //checkifinterested
          if (!(this.logs[a].message == null) && (this.logs[a].message.indexOf("CODEUX") > 0)) {
            var idx = this.getIndex(this.logs[a].timeStamp);
            //console.log("getIndexA %o di %o", idx,this.logs[a].timeStamp);
            this.totalChartData[idx] = this.totalChartData[idx] + 1;
          }
          if (!(this.logs[a].message == null) && (this.logs[a].message.indexOf("CODEERR") > 0)) {
            var idx = this.getIndex(this.logs[a].timeStamp);
            //console.log("getIndexB %o", idx);
            this.errorChartData[idx] = this.errorChartData[idx] + 1;
          }
        }
      }
      else {
        for (var a = 0; a < this.logs.length; a++) {
          //checkifinterested
          if (!(this.logs[a].message == null) && (this.logs[a].message.indexOf("CODEUX") > 0)) {
            var idx = this.getIndexDay(this.logs[a].timeStamp);
           // console.log("getIndexA %o di %o", idx, this.logs[a].timeStamp);
            this.totalChartData[idx] = this.totalChartData[idx] + 1;
          }
          if (!(this.logs[a].message == null) && (this.logs[a].message.indexOf("CODEERR") > 0)) {
            var idx = this.getIndexDay(this.logs[a].timeStamp);
            //console.log("getIndexB %o", idx);
            this.errorChartData[idx] = this.errorChartData[idx] + 1;
          }
        }
      }
    }
  }

  ngOnInit() {


    var sects = 24 * this.sectInHours;
    this.viewChart = false;
    for (let b = 0; b < sects; b++) {
      this.totalChartData.push(0);
      this.errorChartData.push(0);
    }

    this.lineChartLabels.push("00:00");
    this.lineChartLabels.push("00:05");
    this.lineChartLabels.push("00:10");
    this.lineChartLabels.push("00:15");
    this.lineChartLabels.push("00:20");
    this.lineChartLabels.push("00:25");
    this.lineChartLabels.push("00:30");
    this.lineChartLabels.push("00:35");
    this.lineChartLabels.push("00:40");
    this.lineChartLabels.push("00:45");
    this.lineChartLabels.push("00:50");
    this.lineChartLabels.push("00:55");
    this.lineChartLabels.push("01:00");
    this.lineChartLabels.push("01:05");
    this.lineChartLabels.push("01:10");
    this.lineChartLabels.push("01:15");
    this.lineChartLabels.push("01:20");
    this.lineChartLabels.push("01:25");
    this.lineChartLabels.push("01:30");
    this.lineChartLabels.push("01:35");
    this.lineChartLabels.push("01:40");
    this.lineChartLabels.push("01:45");
    this.lineChartLabels.push("01:50");
    this.lineChartLabels.push("01:55");
    this.lineChartLabels.push("02:00");
    this.lineChartLabels.push("02:05");
    this.lineChartLabels.push("02:10");
    this.lineChartLabels.push("02:15");
    this.lineChartLabels.push("02:20");
    this.lineChartLabels.push("02:25");
    this.lineChartLabels.push("02:30");
    this.lineChartLabels.push("02:35");
    this.lineChartLabels.push("02:40");
    this.lineChartLabels.push("02:45");
    this.lineChartLabels.push("02:50");
    this.lineChartLabels.push("02:55");
    this.lineChartLabels.push("03:00");
    this.lineChartLabels.push("03:05");
    this.lineChartLabels.push("03:10");
    this.lineChartLabels.push("03:15");
    this.lineChartLabels.push("03:20");
    this.lineChartLabels.push("03:25");
    this.lineChartLabels.push("03:30");
    this.lineChartLabels.push("03:35");
    this.lineChartLabels.push("03:40");
    this.lineChartLabels.push("03:45");
    this.lineChartLabels.push("03:50");
    this.lineChartLabels.push("03:55");
    this.lineChartLabels.push("04:00");
    this.lineChartLabels.push("04:05"); this.lineChartLabels.push("04:10"); this.lineChartLabels.push("04:15"); this.lineChartLabels.push("04:20"); this.lineChartLabels.push("04:25"); this.lineChartLabels.push("04:30"); this.lineChartLabels.push("04:35"); this.lineChartLabels.push("04:40"); this.lineChartLabels.push("04:45"); this.lineChartLabels.push("04:50"); this.lineChartLabels.push("04:55");
    this.lineChartLabels.push("05:00");
    this.lineChartLabels.push("05:05"); this.lineChartLabels.push("05:10"); this.lineChartLabels.push("05:15"); this.lineChartLabels.push("05:20"); this.lineChartLabels.push("05:25"); this.lineChartLabels.push("05:30"); this.lineChartLabels.push("05:35"); this.lineChartLabels.push("05:40"); this.lineChartLabels.push("05:45"); this.lineChartLabels.push("05:50"); this.lineChartLabels.push("05:55");
    this.lineChartLabels.push("06:00");
    this.lineChartLabels.push("06:05"); this.lineChartLabels.push("06:10"); this.lineChartLabels.push("06:15"); this.lineChartLabels.push("06:20"); this.lineChartLabels.push("06:25"); this.lineChartLabels.push("06:30"); this.lineChartLabels.push("06:35"); this.lineChartLabels.push("06:40"); this.lineChartLabels.push("06:45"); this.lineChartLabels.push("06:50"); this.lineChartLabels.push("06:55");
    this.lineChartLabels.push("07:00");
    this.lineChartLabels.push("07:05"); this.lineChartLabels.push("07:10"); this.lineChartLabels.push("07:15"); this.lineChartLabels.push("07:20"); this.lineChartLabels.push("07:25"); this.lineChartLabels.push("07:30"); this.lineChartLabels.push("07:35"); this.lineChartLabels.push("07:40"); this.lineChartLabels.push("07:45"); this.lineChartLabels.push("07:50"); this.lineChartLabels.push("07:55");
    this.lineChartLabels.push("08:00"); this.lineChartLabels.push("08:05"); this.lineChartLabels.push("08:10"); this.lineChartLabels.push("08:15"); this.lineChartLabels.push("08:20"); this.lineChartLabels.push("08:25"); this.lineChartLabels.push("08:30"); this.lineChartLabels.push("08:35"); this.lineChartLabels.push("08:40"); this.lineChartLabels.push("08:45"); this.lineChartLabels.push("08:50"); this.lineChartLabels.push("08:55");
    this.lineChartLabels.push("09:00"); this.lineChartLabels.push("09:05"); this.lineChartLabels.push("09:10"); this.lineChartLabels.push("09:15"); this.lineChartLabels.push("09:20"); this.lineChartLabels.push("09:25"); this.lineChartLabels.push("09:30"); this.lineChartLabels.push("09:35"); this.lineChartLabels.push("09:40"); this.lineChartLabels.push("09:45"); this.lineChartLabels.push("09:50"); this.lineChartLabels.push("09:55");
    this.lineChartLabels.push("10:00"); this.lineChartLabels.push("10:05"); this.lineChartLabels.push("10:10"); this.lineChartLabels.push("10:15"); this.lineChartLabels.push("10:20"); this.lineChartLabels.push("10:25"); this.lineChartLabels.push("10:30"); this.lineChartLabels.push("10:35"); this.lineChartLabels.push("10:40"); this.lineChartLabels.push("10:45"); this.lineChartLabels.push("10:50"); this.lineChartLabels.push("10:55");
    this.lineChartLabels.push("11:00"); this.lineChartLabels.push("11:05"); this.lineChartLabels.push("11:10"); this.lineChartLabels.push("11:15"); this.lineChartLabels.push("11:20"); this.lineChartLabels.push("11:25"); this.lineChartLabels.push("11:30"); this.lineChartLabels.push("11:35"); this.lineChartLabels.push("11:40"); this.lineChartLabels.push("11:45"); this.lineChartLabels.push("11:50"); this.lineChartLabels.push("11:55");
    this.lineChartLabels.push("12:00"); this.lineChartLabels.push("12:05"); this.lineChartLabels.push("12:10"); this.lineChartLabels.push("12:15"); this.lineChartLabels.push("12:20"); this.lineChartLabels.push("12:25"); this.lineChartLabels.push("12:30"); this.lineChartLabels.push("12:35"); this.lineChartLabels.push("12:40"); this.lineChartLabels.push("12:45"); this.lineChartLabels.push("12:50"); this.lineChartLabels.push("12:55");
    this.lineChartLabels.push("13:00"); this.lineChartLabels.push("13:05"); this.lineChartLabels.push("13:10"); this.lineChartLabels.push("13:15"); this.lineChartLabels.push("13:20"); this.lineChartLabels.push("13:25"); this.lineChartLabels.push("13:30"); this.lineChartLabels.push("13:35"); this.lineChartLabels.push("13:40"); this.lineChartLabels.push("13:45"); this.lineChartLabels.push("13:50"); this.lineChartLabels.push("13:55");
    this.lineChartLabels.push("14:00"); this.lineChartLabels.push("14:05"); this.lineChartLabels.push("14:10"); this.lineChartLabels.push("14:15"); this.lineChartLabels.push("14:20"); this.lineChartLabels.push("14:25"); this.lineChartLabels.push("14:30"); this.lineChartLabels.push("14:35"); this.lineChartLabels.push("14:40"); this.lineChartLabels.push("14:45"); this.lineChartLabels.push("14:50"); this.lineChartLabels.push("14:55");
    this.lineChartLabels.push("15:00"); this.lineChartLabels.push("15:05"); this.lineChartLabels.push("15:10"); this.lineChartLabels.push("15:15"); this.lineChartLabels.push("15:20"); this.lineChartLabels.push("15:25"); this.lineChartLabels.push("15:30"); this.lineChartLabels.push("15:35"); this.lineChartLabels.push("15:40"); this.lineChartLabels.push("15:45"); this.lineChartLabels.push("15:50"); this.lineChartLabels.push("15:55");
    this.lineChartLabels.push("16:00"); this.lineChartLabels.push("16:05"); this.lineChartLabels.push("16:10"); this.lineChartLabels.push("16:15"); this.lineChartLabels.push("16:20"); this.lineChartLabels.push("16:25"); this.lineChartLabels.push("16:30"); this.lineChartLabels.push("16:35"); this.lineChartLabels.push("16:40"); this.lineChartLabels.push("16:45"); this.lineChartLabels.push("16:50"); this.lineChartLabels.push("16:55");
    this.lineChartLabels.push("17:00"); this.lineChartLabels.push("17:05"); this.lineChartLabels.push("17:10"); this.lineChartLabels.push("17:15"); this.lineChartLabels.push("17:20"); this.lineChartLabels.push("17:25"); this.lineChartLabels.push("17:30"); this.lineChartLabels.push("17:35"); this.lineChartLabels.push("17:40"); this.lineChartLabels.push("17:45"); this.lineChartLabels.push("17:50"); this.lineChartLabels.push("17:55");
    this.lineChartLabels.push("18:00"); this.lineChartLabels.push("18:05"); this.lineChartLabels.push("18:10"); this.lineChartLabels.push("18:15"); this.lineChartLabels.push("18:20"); this.lineChartLabels.push("18:25"); this.lineChartLabels.push("18:30"); this.lineChartLabels.push("18:35"); this.lineChartLabels.push("18:40"); this.lineChartLabels.push("18:45"); this.lineChartLabels.push("18:50"); this.lineChartLabels.push("18:55");
    this.lineChartLabels.push("19:00"); this.lineChartLabels.push("19:05"); this.lineChartLabels.push("19:10"); this.lineChartLabels.push("19:15"); this.lineChartLabels.push("19:20"); this.lineChartLabels.push("19:25"); this.lineChartLabels.push("19:30"); this.lineChartLabels.push("19:35"); this.lineChartLabels.push("19:40"); this.lineChartLabels.push("19:45"); this.lineChartLabels.push("19:50"); this.lineChartLabels.push("19:55");
    this.lineChartLabels.push("20:00"); this.lineChartLabels.push("20:05"); this.lineChartLabels.push("20:10"); this.lineChartLabels.push("20:15"); this.lineChartLabels.push("20:20"); this.lineChartLabels.push("20:25"); this.lineChartLabels.push("20:30"); this.lineChartLabels.push("20:35"); this.lineChartLabels.push("20:40"); this.lineChartLabels.push("20:45"); this.lineChartLabels.push("20:50"); this.lineChartLabels.push("20:55");
    this.lineChartLabels.push("21:00"); this.lineChartLabels.push("21:05"); this.lineChartLabels.push("21:10"); this.lineChartLabels.push("21:15"); this.lineChartLabels.push("21:20"); this.lineChartLabels.push("21:25"); this.lineChartLabels.push("21:30"); this.lineChartLabels.push("21:35"); this.lineChartLabels.push("21:40"); this.lineChartLabels.push("21:45"); this.lineChartLabels.push("21:50"); this.lineChartLabels.push("21:55");
    this.lineChartLabels.push("22:00"); this.lineChartLabels.push("22:05"); this.lineChartLabels.push("22:10"); this.lineChartLabels.push("22:15"); this.lineChartLabels.push("22:20"); this.lineChartLabels.push("22:25"); this.lineChartLabels.push("22:30"); this.lineChartLabels.push("22:35"); this.lineChartLabels.push("22:40"); this.lineChartLabels.push("22:45"); this.lineChartLabels.push("22:50"); this.lineChartLabels.push("22:55");
    this.lineChartLabels.push("23:00"); this.lineChartLabels.push("23:05"); this.lineChartLabels.push("23:10"); this.lineChartLabels.push("23:15"); this.lineChartLabels.push("23:20"); this.lineChartLabels.push("23:25"); this.lineChartLabels.push("23:30"); this.lineChartLabels.push("23:35"); this.lineChartLabels.push("23:40"); this.lineChartLabels.push("23:45"); this.lineChartLabels.push("23:50"); this.lineChartLabels.push("23:55");

    var d = new Date(Date.now());
    d.setDate(1);
    d.setMonth(d.getMonth() - 1);
    let m = moment(d);
    this.fromDate = moment().format(dateFormat);
    this.toDate = moment().format(dateFormat);
    this.limitRows = Math.floor((window.innerHeight - 269) / 31); //269 340
    this.heightMainList = window.innerHeight - 188;
    this.columns = SsoMonLog.getStructure();
    this.showb = true;
    this.logs = [];
    let usr = this.auth.getUser();
    if (usr != null) {
      this.role = usr['ruolo'];
      this.dm = usr['clinica'];
      if (usr['nomeCompleto'] != null && usr['nomeCompleto'].length > 0) {
        this.usr = usr['nomeCompleto'];
      }
      else {
        if (usr['email'] != null && usr['email'].length > 0) {
          this.usr = usr['email'];
        }
      }
    }
    if (this.role != null && (this.role.toLowerCase() == 'amministratore' || this.role.toLowerCase() == 'hr medici' || this.role.toLowerCase() == 'direzione commerciale')) {
      this.isAdmin = true;
    }
    console.log('USER LOG %o', usr);

    this.ssoMonService.loadLogs(this.fromDate.replace('-', ''), this.toDate.replace('-', '')).subscribe(results => {
      console.log('response of loadLogs %o', results);
      this.logs = [];

      for (var a = 0; a < results.length; a++) {
        if (!(results[a].level == null) && results[a].level.toLowerCase == 'error') {
          this.logs.push(results[a]);
        }
        else {
          if (!(results[a].message == null) && (results[a].message.indexOf('CODEUX') > 0 || results[a].message.indexOf('CODEERR') > 0)) {
            this.logs.push(results[a]);
          }
        }
      }
      //this.logs = [...this.logs];
      this.showb = false;
      this.buildMatrix();
      //console.log('refresh   %o', this.lineChartLabels);
      //console.log('refresh   %o', this.totalChartData);
      //console.log('refresh   %o', this.errorChartData);
      this.labelMFL[0].data = this.errorChartData;
      this.labelMFL[1].data = this.totalChartData;
      this.viewChart = true;
    }, err => {

      this.showb = false;
    });



  }

}
