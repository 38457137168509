import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatButtonModule } from '@angular/material';

@Component({
  selector: 'app-dialog-consents',
  templateUrl: './dialog-consents.component.html',
  styleUrls: ['./dialog-consents.component.css']
})
export class DialogConsentsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogConsentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close(): void {
    this.dialogRef.close();
  }

  fDate(dt: any): string {
    console.log('date %o',dt)
    if (dt == null)
      return 'non disponibile';
    try {
      if (dt == 'nd')
        return 'non disponibile';
    }
    catch (error) {
      var dti = new Date(dt);

      return dti.getDate() + '-' + (dti.getMonth() + 1) + '-' + dti.getFullYear();
    }
    return 'non disponibile';
  }

  ngOnInit() {
  }

}
