import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '../../shared/datatable/datatable.component';
import { FormHeaderComponent, ICustomButton, IFormHeaderSearch, FormHeaderSearchEventsEnum } from '../../shared/form-header/form-header.component';
import { GceInvoice, GceInvoicePerson, GceClinic, GceLookUpOptions } from '../../entities/gce-management';
import { MatDialog } from '@angular/material';
import { GceManagementService } from '../../services/gce-management.service';

import { TranslateService } from '@ngx-translate/core';
import { AlertDialogService } from '../../services/alert-dialog.service';
import { Confirm } from "../../dialogs/confirm-dialog/confirm-dialog.component";
import { Alert } from "../../dialogs/alert-dialog/alert-dialog.component";
import * as moment from 'moment';
import { GceDialogInvoiceFormComponent } from '../dialogs/gce-dialog-invoice-form/gce-dialog-invoice-form.component';
import { GceDialogCalculateBillingComponent } from '../dialogs/gce-dialog-calculate-billing/gce-dialog-calculate-billing.component';
import { PermissionService } from '../../services/permission.service';
import { GcePerformanceCalculationDetail } from '../../entities/gce-management/gce-performance-calculation-detail';
import { GceDialogPerformanceCalculationDetailComponent } from '../dialogs/gce-dialog-performance-calculation-detail/gce-dialog-performance-calculation-detail.component';
import { AuthGuardService } from '../../services';
import { GcePerformanceCalculationDetractsEditing } from '../../entities/gce-management/gce-performance-calculation-detracts-editing';
import { IConditionalFormatting } from '../../directives/conditional-formatting.directive';

const dateFormatDate = "YYYY-MM-DD";

@Component({
  selector: 'app-gce-riconciliation',
  templateUrl: './gce-riconciliation.component.html',
  styleUrls: ['./gce-riconciliation.component.css']
})
export class GceRiconciliationComponent implements OnInit {
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild('formHeader', { static: false }) formHeader: FormHeaderComponent;
  columns: any[] = [];
  detracts: any;
  detractsBck: any;
  limitRows: number;
  heightMainList: number;
  currentRow: any;
  editing: boolean = false;
  keyColumn: any = {};
  customButtons: ICustomButton[] = [];
  conditionalFormattingConfig: IConditionalFormatting;
  role: string = '';
  usr: string = '';
  fromDate: string = this.gceService.dataRepoGceEnvironment.getFromDate();
  toDate: string = this.gceService.dataRepoGceEnvironment.getToDate();
  showb: boolean = false;
  writePermission: boolean;
  doctors: any[] = [];
  isAdmin: boolean = false;

  constructor(private dialog: MatDialog, private gceService: GceManagementService,
    private translateService: TranslateService,
    private alertDialog: AlertDialogService, private auth: AuthGuardService) {
    this.conditionalFormattingConfig = {
      formulas: [{
        field: 'touched',
        rule: '== true',
        classes: ['row-touched']
      }, {
        field: 'flg_riga_valida',
        rule: '== null',
        classes: ['row-danger']
      },
      {
        field: 'flg_conferma_hr',
        rule: '== true',
        classes: ['row-green']
      }]
    };
    this.writePermission = PermissionService.checkPermission("CGE_EFTT");
    this.detracts = [];
    this.detractsBck = [];

  }

  getClinicName(value) {
    if (value == null)
      return '';
    //console.log('get value %o from %o', value, options);
    if (this.gceService.dataRepoClinics != null && this.gceService.dataRepoClinics.length > 0) {
      for (let a = 0; a < this.gceService.dataRepoClinics.length; a = a + 1) {
        if (value == this.gceService.dataRepoClinics[a].code_c)
          return (this.gceService.dataRepoClinics[a].nome);
      }
    }
    return '';
  }

  getMedicName(value) {
    if (value == null)
      return '';
    //console.log('get value %o from %o', value, options);
    if (this.gceService.dataRepoMedics != null && this.gceService.dataRepoMedics.length > 0) {
      for (let a = 0; a < this.gceService.dataRepoMedics.length; a = a + 1) {
        if (value == this.gceService.dataRepoMedics[a].idfatturazione)
          return (this.gceService.dataRepoMedics[a].descrizione);
      }
    }
    return '';
  }

  ngOnInit() {
    this.showb = true;
    this.limitRows = Math.floor((window.innerHeight - 269) / 31); //269 340
    this.heightMainList = window.innerHeight - 188; //188 259
    this.columns = GcePerformanceCalculationDetractsEditing.getStructure();

    let usr = this.auth.getUser();

    if (usr != null) {
      this.role = usr['ruolo'];
      if (usr['nomeCompleto'] != null && usr['nomeCompleto'].length > 0) {
        this.usr = usr['nomeCompleto'];
      }
      else {
        if (usr['email'] != null && usr['email'].length > 0) {
          this.usr = usr['email'];
        }
      }
    }
    console.log('USER %o', usr);
    if (this.role != null && (this.role.toLowerCase() == 'amministratore' || this.role.toLowerCase() == 'hr medici')) {
      this.isAdmin = true;
    }
    this.gceService.getDetractsGeneric(this.fromDate, this.toDate).subscribe(ret => {
      //manage column based on logged profile
      this.gceService.loadEDentMedics().subscribe(mdev => {
        this.gceService.dataRepoEvMedics = mdev;
        this.gceService.loadMedics().subscribe(md => {
          this.gceService.dataRepoMedics = md;
          this.gceService.loadClinics().subscribe(cl => {
            this.gceService.dataRepoClinics = cl;
            console.log('MEDICS %o', md)
            this.doctors = md;
            //add "caricoClinica"
            /*
            this.doctors.push(
              {
                idfatturazione: 0,
                descrizione: "CARICO CLINICA",
                codice_xdent: 0,
                annullato: false,
                medico_portale: "CARICO CLINICA",
                regime_portale: "RF01",
                nome_nav: "CARICO CLINICA",
                errore: false,
                tipo_medico: 1,
                cessato: false
              }
            );
            */
            this.columns.forEach(column => {

              if (column.primaryKey) {
                this.keyColumn = column;
              }

              if (column.reference) {
                let lookUpOptions: GceLookUpOptions;
                //console.log('ENTITY RELATION %o', column.reference.entity);
                if (column.reference.entity == "GceInvoicePerson") {
                  lookUpOptions = this.gceService.getLookUpAndOptions(this.gceService.dataRepoInvoicePersons, column.reference);
                } else if (column.reference.entity == "GceClinic") {
                  lookUpOptions = this.gceService.getLookUpAndOptions(this.gceService.dataRepoClinics, column.reference);
                }
                else if (column.reference.entity == "GceMedics") {
                  let opt = [];
                  for (let o = 0; o < md.length; o++) {
                    opt.push({ key: md[o].idfatturazione, value: md[o].idfatturazione + ' - ' + md[o].descrizione });
                  }

                  lookUpOptions = { lookUp: {}, options: opt };
                }
                else if (column.reference.entity == "GceMedics2") {

                  let opt = [];
                  for (let o = 0; o < mdev.length; o++) {
                    //console.log('PUSH %o', { key: md[o].codice_xdent, value: md[o].codice_xdent + ' - ' + md[o].medico_portale });
                    //opt.push({ key: md[o].idfatturazione, value: md[o].idfatturazione + ' - ' + md[o].descrizione });
                    opt.push({ key: mdev[o].OperatoreId, value: mdev[o].OperatoreId + ' - ' + mdev[o].OperatoreDescrizione });
                  }

                  lookUpOptions = { lookUp: {}, options: opt };
                }
                else if (column.reference.entity == "GceClinics2") {
                  console.log('this.gceService.dataRepoClinics %o', cl);
                  let opt = [];
                  for (let o = 0; o < cl.length; o++) {
                    //console.log('PUSH CLINICS %o', { key: cl[o].code_c, value: cl[o].code_c + ' - ' + cl[o].nome });
                    opt.push({ key: cl[o].code_c, value: cl[o].code_c + ' - ' + cl[o].nome });
                  }

                  lookUpOptions = { lookUp: {}, options: opt };
                }
                if (lookUpOptions) {
                  column.lookUp = lookUpOptions.lookUp;
                  column.options = lookUpOptions.options;
                }
              }
            });
          });
        });
      });

      let colcp = [];
      this.columns.forEach(column => {
        let vis = false;
        if (column.roles != null) {
          column.roles.forEach(r => {
            if (r.toLowerCase() == this.role.toLowerCase())
              vis = true;
          });
        }
        else {
          vis = true;
        }
        if (vis == true) {
          colcp.push(column);
        }
      });

      this.columns = colcp;
      console.log("gce riconciliation ret %o columns %o", ret, this.columns);
      this.detracts = ret;
      for (let a = 0; a < this.detracts.length; a = a + 1) {
        if (this.detracts[a]['importo_addebito_medico'] > 0) {
          this.detracts[a]['importo_addebito_medico'] = Math.round(this.detracts[a]['importo_addebito_medico'] * 100) / 100;
        }
        if (this.detracts[a]['perc_medico'] > 0) {
          this.detracts[a]['perc_medico'] = Math.round(this.detracts[a]['perc_medico'] * 100) / 100;
        }
        if (this.detracts[a]['importo_ordine'] > 0) {
          this.detracts[a]['importo_ordine'] = Math.round(this.detracts[a]['importo_ordine'] * 100) / 100;
        }
      }
      this.detractsBck = this.detracts;
      //manage select list for doctors

      this.showb = false;
    }, err => {
      this.detractsBck = [];
      this.showb = false;
    });
  }

  onSearch(searchFilter: IFormHeaderSearch) {
    console.log('ON SEARCH %o', searchFilter);
    this.showb = true;
    /*
    if (searchFilter.event === FormHeaderSearchEventsEnum.INPUT_CHANGE) {
      //let filterValues = searchFilter.filter.split(" ").filter(a => !!a);
      //console.log("onSearch %o filterValue %o", searchFilter, filterValues);

      //this.detractsBck = this.detracts; 
      this.detracts = [];
      for (let a = 0; a < this.detractsBck.length; a = a + 1) {
        let item = this.detractsBck[a];
        let ad = false;
        for (let key in item) {
          //console.log('Key %o value %o', key, item[key]);
          if (!!item[key]) {
            if (item[key] != null && item[key] != undefined && item[key].toString().toLowerCase().indexOf(searchFilter.filter) >= 0) {
              //console.log('FIND %o', item);
              ad = true;
            }
          }
        }
        if (ad == true) {
          this.detracts.push(item);
        }
      }
      //console.log("DETRACTS AFTER SEARCH %o",this.detracts);

      this.showb = false;
    } 
    */
    if (searchFilter.event === FormHeaderSearchEventsEnum.INPUT_CHANGE) {
      let filterValues = searchFilter.filter.split(" ").filter(a => !!a);
      if (filterValues == null || filterValues.length == 0) {
        this.detracts = this.detractsBck;
        this.showb = false;
        return;
      }
      console.log("onSearch %o filterValue %o", searchFilter, filterValues);

      this.detracts = this.detractsBck.filter((item: GcePerformanceCalculationDetractsEditing) => {
        for (let key in item) {
          //console.log('Key %o', item[key]);
          if (!!item[key]) {
            /*
            if (!!(item[key] as GceInvoicePerson).idfatturazione) {
              //??

              if ((<GceInvoicePerson>item[key]).descrizione.split(" ").filter(val => {
                return filterValues.filter(fv => val.toLowerCase().indexOf(fv.toLowerCase()) != -1).length > 0;
              }).length >= filterValues.length) {
                return true;
              }
            }
            if (!!(item[key] as GceClinic).code_c) { //??
             
              if ((<GceClinic>item[key]).legalentity.split(" ").filter(val => {
                return filterValues.filter(fv => val.toLowerCase().indexOf(fv.toLowerCase()) != -1).length > 0;
              }).length >= filterValues.length) {
                return true;
              }
            } else {
              if (item[key] !== null && item[key] !== undefined && item[key].toString().toLowerCase().indexOf(searchFilter.filter) != -1) {
                console.log('FIND ?');
                return true;
              }
            }
            */
            //console.log('ITEM P %o KEY  %o medics %o clinics %o', key,item[key], this.gceService.dataRepoMedics, this.gceService.dataRepoClinics);
            //console.log('ITEM P %o KEY %o', key, item[key]);
            if (key == 'cod_clinica') {
              if (searchFilter.filter == item[key]) {
                return true;
              }
              let d = this.getClinicName(item[key]).trim();
              //console.log('CLINIC NAME of %o : %o and filter %o',item[key], d, searchFilter.filter);
              if (d != null && d.toString().toLowerCase().indexOf(searchFilter.filter) >= 0) {
                //console.log(' FIND CLINIC NAME of %o : %o and filter %o',item[key], d, searchFilter.filter);
                return true;
              }
            }

            if (key == 'idfatturazione') {
              //search in medics
              let d = this.getMedicName(item[key]);
              if (d != null && d.toString().toLowerCase().indexOf(searchFilter.filter) >= 0) {
                return true;
              }
            }

            if (key == 'num_ordine_evolution') {
              //search in ordine_evolution
              if (item[key] != null && item[key].toString().indexOf(searchFilter.filter) >= 0) {
                return true;
              }
            }

            if (key == 'idpaziente') {
              //search in ordine_evolution
              if (item[key] != null && item[key].toLowerCase().indexOf(searchFilter.filter) >= 0) {
                return true;
              }
            }
            /*
            if (!!(item[key] as GceInvoicePerson).idfatturazione) {
              console.log('ITEM P %o KEY  %o', key,item[key]);
              for (let key2 in item[key]) {
                if (item[key][key2] != null && key2 == 'medico_portale' && item[key][key2].toString().toLowerCase().indexOf(searchFilter.filter) >= 0) {
                  console.log('GCEInvoicePerson %o', item[key]);
                  return true;
                }
              }
            }
            if (!!(item[key] as GceClinic).code_c) {
              console.log('ITEM C %o KEY  %o', key,item[key]);
              for (let key2 in item[key]) {
                if (item[key][key2] != null && (key2 == 'code_c' || key2 == 'nome') && item[key][key2].toString().toLowerCase().indexOf(searchFilter.filter) >= 0) {
                  //console.log('GCEClinic %o', item[key]);
                  return true;
                }
              }
            }
            */
            //if (item[key] != null && item[key] != undefined && item[key].toString().toLowerCase().indexOf(searchFilter.filter) >= 0) {
            //  console.log('FIND %o', item);
            //  return true;
            //}
          }
        }
        return false;
      });
      this.showb = false;
    }
    else {
      if (searchFilter.event === FormHeaderSearchEventsEnum.FROM_CHANGE) {
        let mfd = moment(searchFilter.fromDate);
        let mtd = moment(searchFilter.toDate);
        this.fromDate = mfd.format(dateFormatDate);
        if (mtd.diff(mfd, 'days') > mfd.daysInMonth() || mtd.isBefore(mfd)) {
          this.toDate = mfd.endOf('month').format(dateFormatDate);
        }
      }
      if (searchFilter.event === FormHeaderSearchEventsEnum.TO_CHANGE) {
        let mfd = moment(searchFilter.fromDate);
        let mtd = moment(searchFilter.toDate);
        this.toDate = mtd.format(dateFormatDate);
        if (mtd.diff(mfd, 'days') > mtd.daysInMonth() || mtd.isBefore(mfd)) {
          this.fromDate = mtd.startOf('month').format(dateFormatDate);
        }
      }
      if (this.fromDate && this.toDate) {
        console.log("search %o %o", this.fromDate, this.toDate);
        this.gceService.getDetractsGeneric(this.fromDate, this.toDate).subscribe((invoices: any[]) => {
          this.detracts = invoices;
          this.detractsBck = [...this.detracts];
          this.showb = false;
        });
      }
    }

    this.table.offset = 0;
  }

  onToggle(selectedColumns) {
    console.log("onToggle %o %o", selectedColumns);
    this.columns.map(item => {
      item.show = selectedColumns.indexOf(item.columnDef) != -1;
    });
  }

  onChangeRow(row) {
    console.log("onChangeRow %o doctors %o", row, this.doctors);

    this.currentRow = row;
    this.editing = false;
    /*
        for (let i = 0; i < this.detracts.length; i++) {
          if (this.detracts[i]['IDVEND'] == row['IDVEND']) {
    
            for (let j = 0; j < this.doctors.length; j++) {
    
              console.log("check %o with %o", this.doctors[j]['medico_portale'], this.detracts[i]['nome_medico']);
              if (this.doctors[j]['medico_portale'] == this.detracts[i]['nome_medico']) {
                this.detracts[i]['medico_prescrizione'] = this.doctors[j]['codice_xdent'];
                return;
              }
            }
          }
        }
    */
  }

  onEdit(event) {
    console.log("onEdit %o", event);
  }

  onDblClickRow(event) {
    console.log("onDblClickRow %o", event);
  }

  onRestore(event) {
    console.log("onRestore %o", event);
    this.clear();
  }

  onDelete(invoice: GceInvoice) {
    console.log("onDelete %o", invoice);
  }

  onAction(event) {
    console.log("onAction %o", event);
  }

  onCloseForm(event) {
    console.log("onCloseForm %o", event);
    this.clear();
  }

  onOpenDialog(args) {
    console.log("onOpenDialog %o", args);

  }

  sv(row: any) {
    console.log('SV %o role %o usr %o', row, this.role, this.usr);

    if (row['flg_carico_clinica_conferma_hr'] == true) {
      window.alert("Non è più possibile modificare l'addebito caricato sulla clinica");
      return;
    }

    const confirm: Confirm = {
      title: "GCE.GCEINVOICE.DIALOG.CONFIRMDETRACT.TITLE",
      message: "GCE.GCEINVOICE.DIALOG.CONFIRMDETRACT.MESSAGE",
      buttonOkText: "GCE.GCEINVOICE.DIALOG.CONFIRMINVOICE.BUTTON.OK",
      buttonCancelText: "GCE.GCEINVOICE.DIALOG.CONFIRMINVOICE.BUTTON.CANCEL",
      buttonOkStyle: { 'background-color': '#268B26' },
      buttonCancelStyle: { 'background-color': '#AA3939' },
      withTranslate: true
      // messageParams: [{ key: 'INVOICE_NUMBER', value: invoice.id }]
    };


    if (row['num_prescrizione'] > 0 && row['idfatturazione'] > 0) {
      this.alertDialog.confirm(confirm).subscribe(result => {
        /*
                for (let j = 0; j < this.doctors.length; j++) {
                  if (this.doctors[j]['medico_portale'] == row['nome_medico']) {
                    row['medico_prescrizione'] = this.doctors[j]['codice_xdent'];
                  }
                }
        */

        if (result) {
          this.showb = true;
          if (this.isAdmin == true) {
            row['flg_conferma_hr'] = true;
          }

          this.gceService.updateDetract(row).subscribe((d) => {
            console.log('D from detract %o', d);
            /*
            this.alertDialog.open({
              message: 'GCE.GCEINVOICE.DIALOG.CONFIRMEDDETRACT.MESSAGE',
              isError: false,
              withTranslate: true
            });
            */
            //ok let's update (d) with current data
            this.detractsBck = [];
            for (let i = 0; i < this.detracts.length; i++) {
              // console.log('TEST %o WITH %o', this.detracts[i]['IDVEND'], d['IDVEND']);
              if (this.detracts[i]['IDVEND'] == d['IDVEND']) {
                //  console.log('------------------------------------------>OK update');
                this.detracts[i].touched = true;
                this.detracts[i].flg_riga_valida = false;
              }
              this.detractsBck.push(this.detracts[i]);
            }
            //this.table.init();
            this.showb = false;
          }, err => {
            this.alertDialog.open({
              message: 'GCE.GCEINVOICE.DIALOG.CONFIRMEDDETRACT.ERROR.MESSAGE',
              isError: true,
              withTranslate: true
            });
            this.showb = false;
          });

        }
      });
    }
    else {
      //prescrizione, medico, nome medico sono contenuti obbligatori per la modifica
      window.alert("E' necessario compilare numero prescrizione e medico assegnatario per poter correggere l'anomalia sulla detrazione.");
    }
  }

  onCustomActionClick(event) {
    console.log("onCustomActionClick %o", event);
    if (this[event.field.callback]) {
      this[event.field.callback](event.row);
    }
  }

  clear() {
    this.currentRow = null;
    this.editing = false;
  }

  confirmInvoice(invoice: GceInvoice) {
    console.log("confirmInvoice %o", invoice);
    const confirm: Confirm = {
      title: "GCE.GCEINVOICE.DIALOG.CONFIRMINVOICE.TITLE",
      message: "GCE.GCEINVOICE.DIALOG.CONFIRMINVOICE.MESSAGE",
      buttonOkText: "GCE.GCEINVOICE.DIALOG.CONFIRMINVOICE.BUTTON.OK",
      buttonCancelText: "GCE.GCEINVOICE.DIALOG.CONFIRMINVOICE.BUTTON.CANCEL",
      buttonOkStyle: { 'background-color': '#268B26' },
      buttonCancelStyle: { 'background-color': '#AA3939' },
      withTranslate: true,
      messageParams: [{ key: 'INVOICE_NUMBER', value: invoice.id }]
    };
    this.alertDialog.confirm(confirm).subscribe(result => {
      if (result) {
        this.gceService.confirmInvoice(invoice).subscribe((d) => {
          /*
          this.alertDialog.open({
            message: 'GCE.GCEINVOICE.DIALOG.CONFIRMEDINVOICE.MESSAGE',
            isError: false,
            withTranslate: true
          });
          */
        }, err => {
          this.alertDialog.open({
            message: 'GCE.GCEINVOICE.DIALOG.CONFIRMINVOICE.ERROR.MESSAGE',
            isError: true,
            withTranslate: true
          });
        });
      }
    });
  }
}
