import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
//import * as myGlobals from '../../environments/environment';
import { AuthGuardService } from '../services';
import { MessageMngtService } from '../services/messagemngt.service';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import * as MySettings from '../../environments/environment';
import { NewMessageDialogComponent } from '../order-management/shared/dialogs/newmsgs.dialog';
import { DialogMessageBroadcastComponent } from '../dialogs/dialog-message-broadcast/dialog-message-broadcast.component';
import { Router } from '@angular/router';
import { UserService } from '../services';

@Component({
  selector: 'app-messages-mngmt',
  templateUrl: './messages-mngmt.component.html',
  styleUrls: ['./messages-mngmt.component.css']
})
export class MessagesMngmtComponent implements OnInit {
  tableMessages
  _images
  caption
  subtitle
  backCaption
  back
  //rows
  selected = []
  columns
  cbimg = "./assets/images/icone/icn_back.png";
  ca = []; ca2 = [];
  dom;
  pro;
  usr;
  br;

  constructor(
    private _auth: AuthGuardService,
    private _http: HttpClient,
    private _dialog: MatDialog,
    private _router: Router,
    public _messageMngtService: MessageMngtService,
    public userService: UserService
  ) {
    this.tableMessages = MySettings.environment.tableMessages;
    this._images = MySettings.environment.imagesMM;

    this.caption = "GESTIONE MESSAGGI CLINICHE";
    this.subtitle = "Visualizza, modifica, cancella o crea i messaggi destinati alle cliniche.";
    this.backCaption = "Torna indietro";
    this.back = "store";

    this.columns = [];
    this.columns.push({ prop: "ID_MESSAGE", name: "Id", flexGrow: 1, type: 'Int32' });
    this.columns.push({ prop: "SENDER", name: "Mittente", flexGrow: 1, type: 'String' });
    this.columns.push({ prop: "SUBJECT", name: "Titolo", flexGrow: 1, type: 'String' });
    this.columns.push({ prop: "STARTDATE", name: "Data di inizio", flexGrow: 1, type: 'DateTime' });
    this.columns.push({ prop: "ENDDATE", name: "Data di fine", flexGrow: 1, type: 'DateTime' });
    this.columns.push({ prop: "AZIO1", name: "Visualizza", flexGrow: 1, type: 'String' });
    //this.columns.push({ prop: "AZIO2", name: "Cancella", flexGrow: 1, type: 'String' });
    this.columns.push({ prop: "AZIO3", name: "Attiva/Disattiva", flexGrow: 1, type: 'String' });


    //this.getAllMessages();
    if (_messageMngtService.messaggesRows != null && _messageMngtService.messaggesRows.length > 0) {
      //this.rows = _messageMngtService.messaggesRows;
    }
    else {
      _messageMngtService.getAllMessages().subscribe((res) => {
        _messageMngtService.messaggesRows = res;
      });
    }

    this._images[0].enabled = true;
    this._images[0].visible = true;
    this._images[0].caption = "Nuovo messaggio";
    this._images[0].action = "newMessage";

  }

  goBack(act) {
    // this.rows = [];
    console.log('goBACK entryPoint %o act %o', act);
    this._router.navigate(['/']);
  }

  performAction(act) {
    if (act.action == 'newMessage') {
      let dialogRef = this._dialog.open(NewMessageDialogComponent, {
        width: '820px',
        data: { pro: this.pro, dom: this.dom , usr: this.usr}
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log("result close NewMessageDialogComponent %o", result);
      });
    } else if (act.action == 'edit') {
      //alert("Modifica messaggio ID: " + this.selected[0]['ID_MESSAGE']);
    } else if (act.action == 'delete') {
      //alert("Elimina messaggio ID: " + this.selected[0]['ID_MESSAGE']);
      this.delete(this.selected[0]);
    }
  }

  visualizza(row: any) {
    console.log("view message %o", row);
    let heigth = 460 + 28 * row.Message_Clinics.length;
    if (heigth > 600)
      heigth = 600;
    const dialogRef = this._dialog.open(DialogMessageBroadcastComponent, {
     // height: heigth + 'px',
      data: { msg: row, dom: this.dom, pro: this.pro }
    });
  }

  delete(row: any) {
    console.log("view message %o", row);
    if (confirm("Sei sicuro di voler eliminare il messaggio " + row.SUBJECT)) {

      this._http.delete(
        MySettings.environment.DeleteMessage + row.ID_MESSAGE
      )
        .subscribe(res => {
          this._messageMngtService.getAllMessages().subscribe((res) => {
            this._messageMngtService.messaggesRows = res;
            $('#my-ngx').css('height', (window.innerHeight - 0) + 'px');
          });
          console.log('delete res %o', res)
        });
    }
  }

  onSelect(row) {
    this._images[1].enabled = false;
    this._images[1].visible = true;

    this._images[2].enabled = true;
    this._images[2].visible = true;
  }

  calculateWidthColumn(col): number {
    var x = false;
    if (this.ca.indexOf(col) != -1) return this.ca2[this.ca.indexOf(col)]; else { this.ca.push(col); x = true; }
    document.getElementById('columnAdapter').innerHTML = col.toUpperCase();
    var d = getComputedStyle(document.getElementById('columnAdapter')).width;
    var dim = parseInt((d + "").substring(0, (d + "").length - 2));
    ////console.log(dim);
    dim = 55 + dim;
    ////console.log(col + ": " + dim);
    dim = (col == 'MESE' || col == 'ANNO') ? dim + 30 : dim;
    if (x) this.ca2.push(dim);
    return dim;
  }

  /*
    getAllMessages () {
      this._http.get(
        myGlobals.environment['GetAllMessages']
      )
      .map((res) => {
        this.rows = res;
        $('#my-ngx').css('height', (window.innerHeight - 0) + 'px');
      })
      .subscribe(val => []);
    }
  */

  ngOnInit() {
    this._images = MySettings.environment.imagesMM;
    
    this._images[0].enabled = true;
    this._images[0].visible = true;
    this._images[0].caption = "Nuovo messaggio";
    this._images[0].action = "newMessage";

    //this.getAllMessages();
    if (this._messageMngtService.messaggesRows != null && this._messageMngtService.messaggesRows.length > 0) {
      //this.rows = _messageMngtService.messaggesRows;
    }
    else {
      this._messageMngtService.getAllMessages().subscribe((res) => {
        this._messageMngtService.messaggesRows = res;
        //$('#my-ngx').css('height', (window.innerHeight - 0) + 'px');
      });
    };

    this._http.get(
      MySettings.environment.GetAllDomains
    )
      .map((domains) => {
        this.dom = domains;
        this._http.get(
          MySettings.environment.GetAllProfiles
        )
          .map((profiles) => {
            this.pro = profiles;
            console.log("%o %o", this.dom, this.pro);
          })
          .subscribe(val => []);
      })
      .subscribe(val => []);
      this.userService.getCurrentUser().subscribe(f => {this.usr = f});
  }

  active(row: any) {
    console.log('active message for ' + MySettings.environment.ActiveMessage + row.ID_MESSAGE);
    this._http.get(
      MySettings.environment.ActiveMessage + row.ID_MESSAGE
    ).subscribe(
      res => {
        if (res)
          this._messageMngtService.activeMessage(row.ID_MESSAGE);
      });
  }

  disactive(row: any) {
    console.log('disactive message for ' + MySettings.environment.ActiveMessage + row.ID_MESSAGE);
    this._http.get(
      MySettings.environment.DisActiveMessage + row.ID_MESSAGE
    ).subscribe(
      res => {
        if (res)
          this._messageMngtService.disactiveMessage(row.ID_MESSAGE);
      });
  }

  changeBack(src = "") {
    if (src != "") this.cbimg = "./assets/images/icone/" + src;
    else this.cbimg = "./assets/images/icone/icn_back.png";
  }

  isCurrency(t, f) {
    return ((t == 'Currency') && f != '' && f !== undefined);
  }

  onClick(clicked) {

  }

  backupRows() {
    console.log('BACKUP ROWS');
    if (this.br == undefined)
      this.br = this._messageMngtService.messaggesRows;
  }

  updateFilter(event, search) {
    //console.log('updateFilter %o',this.br);
    if (event.target.value == "" && this.br != null) { this._messageMngtService.messaggesRows = [...this.br]; return; }
    const val = (event.target.value + "").toLowerCase();
    const temp = this._messageMngtService.messaggesRows.filter(function (d) {
      return (d[search] + "").toLowerCase().indexOf(val) !== -1 || !val;
    });

    this._messageMngtService.messaggesRows = temp;
    //this.table.offset = 0;
  }

  focusLente(id) {
    console.log('FOCUS LENTE');
    $(function () {
      $('#' + id).select();
      $('#' + id).focus();
    });
  }

  testParse(val) {
    var a = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var b = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
    var ind = 0;
    for (let x of a)
      if (val != undefined)
        val = val.replace(x, b[ind++]);

    return val;
  }

  hasClass(who, asc) {
    if (!asc && document.getElementsByClassName('sort-desc').length == 0) return true;
    if (asc && document.getElementsByClassName('sort-asc').length == 0) return true;
    if (!asc)
      for (let x of Array.prototype.slice.call(document.getElementsByClassName('sort-desc')))
        try {
          return !(who == x.children[0].children[0].children[0].children[1].children[1].innerHTML.trim());
        }
        catch (e) {
          return true;
        }
    if (asc)
      for (let x of Array.prototype.slice.call(document.getElementsByClassName('sort-asc')))
        try {
          return !(who == x.children[0].children[0].children[0].children[1].children[1].innerHTML.trim());
        } catch (e) {
          return true;
        }
    return true;
  }

}
