import { primaryKey, headerCode, inputType, required, length, order, pattern, editable, Describer, GceEntity, relation, visible, currency, roles } from './gce-management';
import { GceInvoicePerson } from './gce-invoice-person';
import { GceClinic } from './gce-clinic';
import { PermissionService } from '../../services/permission.service';

/**
 * GceInvoice -> table: fatturazione_mese
 */
export class GceInvoice extends GceEntity {
    @primaryKey()
    @headerCode('GCE.GCEINVOICE.ID')
    @inputType('number')
    @order(1)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    id: number;

    @headerCode('GCE.GCEINVOICE.MESE')
    @inputType('date')
    @order(2)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    mese: Date | string;

    @headerCode('GCE.GCEINVOICE.IDFATTURAZIONE')
    @inputType('select')
    @relation("GceInvoicePerson", { columnRel: 'idfatturazione', columnDescr: ['descrizione', 'piva'] })
    @order(3)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    idfatturazione: number;

    @headerCode('GCE.GCEINVOICE.COD_CLINICA')
    @inputType('select')
    @length(3)
    //@relation("GceClinic", { columnRel: 'code_c', columnDescr: ['legalentity', 'nome'] })
    @relation("GceClinic", { columnRel: 'code_c', columnDescr: ['nome'] })
    @order(4)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    cod_clinica: string;

    @headerCode('GCE.GCEINVOICE.TIPO_RECORD')
    @inputType('text')
    @visible(false)
    @length(255)
    @order(5)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    tipo_record: string;

    @headerCode('GCE.GCEINVOICE.TS_CALCOLO')
    @inputType('datetime')
    @editable(true)
    @order(6)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    ts_calcolo: Date | string;

    @headerCode('GCE.GCEINVOICE.FLG_CONFERMA_CLINICA')
    @inputType('boolean')
    @editable(true)
    @order(7)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    flg_conferma_clinica: boolean;

    @headerCode('GCE.GCEINVOICE.TS_CONFERMA_CLINICA')
    @inputType('date')
    @order(8)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    ts_conferma_clinica: Date | string;

    @headerCode('GCE.GCEINVOICE.USER_CONFERMA_CLINICA')
    @inputType('text')
    @editable(true)
    @order(9)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    user_conferma_clinica: string;

    @headerCode('GCE.GCEINVOICE.FLG_CONFERMA_HR')
    @inputType('boolean')
    @editable(true)
    @order(10)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    flg_conferma_hr: boolean;

    @headerCode('GCE.GCEINVOICE.TS_CONFERMA_HR')
    @inputType('date')
    @order(11)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    ts_conferma_hr: Date | string;

    @headerCode('GCE.GCEINVOICE.USER_CONFERMA_HR')
    @inputType('text')
    @editable(true)
    @order(12)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    user_conferma_hr: string;

    @headerCode('GCE.GCEINVOICE.FLG_DOC_EMESSO')
    @inputType('boolean')
    @editable(true)
    @order(13)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    flg_doc_emesso: boolean;

    @headerCode('GCE.GCEINVOICE.TS_DOC_EMESSO')
    @inputType('date')
    @order(14)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    ts_doc_emesso: Date | string;

    @headerCode('GCE.GCEINVOICE.FLG_GETTONE')
    @inputType('boolean')
    @editable(true)
    @order(15)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    flg_gettone: boolean;

    @headerCode('GCE.GCEINVOICE.GG_DISPONIBILTA_CALC')
    @inputType('number')
    @editable(true)
    @order(16)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    gg_disponibilta_calc: number;

    @headerCode('GCE.GCEINVOICE.GG_AGENDA_CALC')
    @inputType('number')
    @editable(true)
    @order(17)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    gg_agenda_calc: number;

    @headerCode('GCE.GCEINVOICE.GG_GETTONE_CALC')
    @inputType('number')
    @editable(true)
    @order(18)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    gg_gettone_calc: number;

    @headerCode('GCE.GCEINVOICE.GG_GETTONE_MANUALE')
    @inputType('number')
    @editable(true)
    @order(19)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    gg_gettone_manuale?: number;

    @headerCode('GCE.GCEINVOICE.gg_gettone_manuale_hr')
    @inputType('number')
    @editable(true)
    @order(20)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    gg_gettone_manuale_hr?: number;

    @headerCode('GCE.GCEINVOICE.IMPORTO_GETTONE_CALC')
    @inputType('number')
    @editable(true)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(21)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    importo_gettone_calc: number;

    @headerCode('GCE.GCEINVOICE.importo_gettone_unitario')
    @inputType('number')
    @editable(true)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(42)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    importo_gettone_unitario: number;

    @headerCode('GCE.GCEINVOICE.IMPORTO_GETTONE_MANUALE')
    @inputType('number')
    @editable(true)
    //@visible(false)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(22)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    importo_gettone_manuale?: number;


    @headerCode('GCE.GCEINVOICE.importo_gettone_manuale_hr')
    @inputType('number')
    @editable(true)
    //@visible(false)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(23)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    importo_gettone_manuale_hr?: number;


    @headerCode('GCE.GCEINVOICE.IMPORTO_DIREZIONE_CALC')
    @inputType('number')
    @editable(true)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(24)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    importo_direzione_calc: number;


    @headerCode('GCE.GCEINVOICE.IMPORTO_DIREZIONE_MANUALE')
    @inputType('number')
    @editable(true)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(25)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    importo_direzione_manuale?: number;

    @headerCode('GCE.GCEINVOICE.importo_direzione_manuale_hr')
    @inputType('number')
    @editable(true)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(26)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    importo_direzione_manuale_hr?: number;

    @headerCode('GCE.GCEINVOICE.importo_direzione__area_manuale_hr')
    @inputType('number')
    @editable(true)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(27)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    importo_direzione__area_manuale_hr?: number;

    @headerCode('GCE.GCEINVOICE.importo_direzione__area_calc')
    @inputType('number')
    @editable(true)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(28)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    importo_direzione__area_calc: number;

    @headerCode('GCE.GCEINVOICE.importo_direzione__area_manuale')
    @inputType('number')
    @editable(true)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(29)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    importo_direzione__area_manuale?: number;

    @headerCode('GCE.GCEINVOICE.IMPORTO_FISSO_EXTRA_CALC')
    @inputType('number')
    @editable(true)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(30)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    importo_fisso_extra_calc: number;

    @headerCode('GCE.GCEINVOICE.IMPORTO_PRESTAZIONI_CALC')
    @inputType('number')
    @editable(true)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(31)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    importo_prestazioni_calc: number;

    @headerCode('GCE.GCEINVOICE.IMPORTO_INTEGR_MINIMO_CALC')
    @inputType('number')
    @editable(true)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(32)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    importo_integr_minimo_calc: number;

    @headerCode('GCE.GCEINVOICE.IMPORTO_PRESTAZIONI_MANUALE')
    @inputType('number')
    @editable(true)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(33)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    importo_prestazioni_manuale?: number;

    @headerCode('GCE.GCEINVOICE.importo_prestazioni_manuale_hr')
    @inputType('number')
    @editable(true)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(34)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    importo_prestazioni_manuale_hr?: number;

    @headerCode('GCE.GCEINVOICE.IMPORTO_ADD_CALC')
    @inputType('number')
    @editable(true)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(35)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    importo_addebiti_lab_calc: number;

    @headerCode('GCE.GCEINVOICE.HR_IMPORTO_ADD')
    @inputType('number')
    @editable(true)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(36)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    importo_addebiti_lab_manuale_hr?: number;

    @headerCode('GCE.GCEINVOICE.importo_prest_mediche_manuale')
    @inputType('number')
    @editable(true)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(37)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    importo_prest_mediche_manuale?: number;

    @headerCode('GCE.GCEINVOICE.importo_prest_mediche_manuale_hr')
    @inputType('number')
    @editable(true)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(38)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    importo_prest_mediche_manuale_hr?: number;

    @headerCode('GCE.GCEINVOICE.importo_prest_anastesista_manuale')
    @inputType('number')
    @editable(true)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(39)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    importo_prest_anastesista_manuale?: number;

    @headerCode('GCE.GCEINVOICE.importo_prest_anastesista_manuale_hr')
    @inputType('number')
    @editable(true)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(40)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    importo_prest_anastesista_manuale_hr?: number;

    @headerCode('GCE.GCEINVOICE.note_hr')
    @inputType('text')
    @editable(true)
    @order(41)
    //@roles(['Amministratore','HR Medici'])
    note_hr: string;

    @headerCode('GCE.GCEINVOICE.importo_gettone_unitario_hr')
    @inputType('number')
    @editable(true)
    @visible(false)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(42)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    importo_gettone_unitario_hr?: number;

    @headerCode('GCE.GCEINVOICE.IMPORTO_RIPORTO_MESE_PREC')
    @inputType('number')
    @editable(true)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(43)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    importo_riporto_mese_prec?: number;

    @headerCode('GCE.GCEINVOICE.IMPORTO_RIPORTO_MESE_SUCC')
    @inputType('number')
    @editable(true)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(44)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    importo_riporto_mese_succ?: number;

    @headerCode('GCE.GCEINVOICE.NOTA_RIPORTO_MESE_SUCC')
    @inputType('text')
    @editable(true)
    @order(45)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    nota_riporto_mese_succ: string;

    @headerCode('GCE.GCEINVOICE.TOT_FATTURA_PRESTAZIONE')
    @inputType('number')
    @visible(false)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(46)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    tot_fattura_prestazione: number;

    @headerCode('GCE.GCEINVOICE.importo_minimo_garantito')
    @inputType('number')
    @editable(true)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(47)
    importo_minimo_garantito: number;

    @headerCode('GCE.GCEINVOICE.importo_detrazioni_invisalign_manuale')
    @inputType('number')
    @editable(true)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(48)
    importo_detrazioni_invisalign_manuale: number;

    @headerCode('GCE.GCEINVOICE.importo_detrazioni_invisalign_manuale_hr')
    @inputType('number')
    @editable(true)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(49)
    importo_detrazioni_invisalign_manuale_hr: number;

    @headerCode('GCE.GCEINVOICE.importo_fattura_prestazioni')
    @inputType('number')
    @editable(true)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(50)
    importo_fattura_prestazioni: number;

    @headerCode('GCE.GCEINVOICE.importo_fattura_direzione_sanitaria')
    @inputType('number')
    @editable(true)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(51)
    importo_fattura_direzione_sanitaria: number;

    @headerCode('GCE.GCEINVOICE.importo_fattura_direzione_area_clinica')
    @inputType('number')
    @editable(true)
    @pattern('00000.00')
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @order(52)
    importo_fattura_direzione_area_clinica: number;


    @headerCode('GCE.GCEINVOICE.NOTE')
    @inputType('text')
    @editable(true)
    @order(53)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    note: string;

    @headerCode('GCE.GCEINVOICE.FLG_SCARTO_DOC')
    @inputType('boolean')
    @editable(true)
    @order(54)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    flg_scarto_doc: boolean;

    @headerCode('GCE.GCEINVOICE.MSG_SCARTO')
    @inputType('text')
    @editable(true)
    @order(55)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    msg_scarto: string;

    @headerCode('GCE.GCEINVOICE.RICHIESTA_ANNULLO_NAV')
    @inputType('number')
    @editable(true)
    @order(56)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    richiesta_annullo_nav: number;

    @headerCode('GCE.GCEINVOICE.FLG_ANNULLO_NAV')
    @inputType('boolean')
    @editable(true)
    @order(57)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    flg_annullo_nav: boolean;

    @headerCode('GCE.GCEINVOICE.UTENTE_RICHIESTA_ANNULLO_NAV')
    @inputType('text')
    @editable(false)
    @order(58)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    utente_richiesta_annullo_nav: string;

    @headerCode('GCE.GCEINVOICE.TS_ANNULLO_NAV')
    @inputType('date')
    @editable(false)
    @order(59)
    //@roles(['Amministratore','HR Medici','Receptionist_Test'])
    ts_annullo_nav: Date | string;

    person?: GceInvoicePerson;
    clinic?: GceClinic;

    constructor();
    constructor(id: number, mese: Date | string, idfatturazione: number, cod_clinica: string, tipo_record: string, ts_calcolo: Date | string, flg_conferma_clinica: boolean, ts_conferma_clinica: Date | string, user_conferma_clinica: string, flg_conferma_hr: boolean, user_conferma_hr: string, ts_conferma_hr: Date | string, flg_doc_emesso: boolean, ts_doc_emesso: Date | string, flg_gettone: boolean, gg_disponibilta_calc: number, gg_agenda_calc: number, gg_gettone_calc: number, gg_gettone_manuale: number, importo_gettone_calc: number, importo_gettone_manuale: number, importo_direzione_calc: number, importo_direzione_manuale: number, importo_fisso_extra_calc: number, importo_prestazioni_calc: number, importo_integr_minimo_calc: number, importo_prestazioni_manuale: number, importo_riporto_mese_succ: number, nota_riporto_mese_succ: string, importo_riporto_mese_prec: number, tot_fattura_prestazione: number, note: string, flg_scarto_doc: boolean, msg_scarto: string, richiesta_annullo_nav: number, flg_annullo_nav: boolean, utente_richiesta_annullo_nav: string, ts_annullo_nav: Date);
    constructor(id?: number,
        mese?: Date | string,
        idfatturazione?: number,
        cod_clinica?: string,
        tipo_record?: string,
        ts_calcolo?: Date | string,
        flg_conferma_clinica?: boolean,
        ts_conferma_clinica?: Date | string,
        user_conferma_clinica?: string,
        flg_conferma_hr?: boolean,
        user_conferma_hr?: string,
        ts_conferma_hr?: Date | string,
        flg_doc_emesso?: boolean,
        ts_doc_emesso?: Date | string,
        flg_gettone?: boolean,
        gg_disponibilta_calc?: number,
        gg_agenda_calc?: number,
        gg_gettone_calc?: number,
        gg_gettone_manuale?: number,
        importo_gettone_calc?: number,
        importo_gettone_manuale?: number,
        importo_direzione_calc?: number,
        importo_direzione_manuale?: number,
        importo_fisso_extra_calc?: number,
        importo_prestazioni_calc?: number,
        importo_integr_minimo_calc?: number,
        importo_prestazioni_manuale?: number,
        importo_riporto_mese_succ?: number,
        nota_riporto_mese_succ?: string,
        importo_riporto_mese_prec?: number,
        tot_fattura_prestazione?: number,
        note?: string,
        flg_scarto_doc?: boolean,
        msg_scarto?: string,
        richiesta_annullo_nav?: number,
        flg_annullo_nav?: boolean,
        utente_richiesta_annullo_nav?: string,
        ts_annullo_nav?: Date | string,
        importo_prest_mediche_manuale?: number,
        importo_prest_anestesista_manuale?: number,
        note_hr?: string,
        gg_gettone_manuale_hr?: number,
        importo_gettone_manuale_hr?: number,
        importo_direzione_manuale_hr?: number,
        importo_prestazioni_manuale_hr?: number,
        importo_direzione__area_manuale_hr?: number,
        importo_direzione__area_calc?: number,
        importo_direzione__area_manuale?: number,
        importo_gettone_unitario_hr?: number,
        importo_gettone_unitario?: number,
        importo_prest_mediche_manuale_hr?: number,
        importo_prest_anastesista_manuale_hr?: number,
        importo_addebiti_lab_calc?: number,
        importo_addebiti_lab_manuale_hr?: number,
        importo_minimo_garantito?: number,
        importo_detrazioni_invisalign_manuale?: number,
        importo_detrazioni_invisalign_manuale_hr?: number,
        importo_fattura_prestazioni?: number,
        importo_fattura_direzione_sanitaria?: number,
        importo_fattura_direzione_area_clinica?: number
    ) {
        super();
        this.id = id || 0;
        this.mese = mese || new Date();
        this.idfatturazione = idfatturazione || 0;
        this.cod_clinica = cod_clinica || '';
        this.tipo_record = tipo_record || '';
        this.ts_calcolo = ts_calcolo || new Date();
        this.flg_conferma_clinica = flg_conferma_clinica || false;
        this.ts_conferma_clinica = ts_conferma_clinica || new Date();
        this.user_conferma_clinica = user_conferma_clinica || '';
        this.flg_conferma_hr = flg_conferma_hr || false;
        this.user_conferma_hr = user_conferma_hr || '';
        this.ts_conferma_hr = ts_conferma_hr || new Date();
        this.flg_doc_emesso = flg_doc_emesso || false;
        this.ts_doc_emesso = ts_doc_emesso || new Date();
        this.flg_gettone = flg_gettone || false;
        this.gg_disponibilta_calc = gg_disponibilta_calc;
        this.gg_agenda_calc = gg_agenda_calc;
        this.gg_gettone_calc = gg_gettone_calc;
        this.gg_gettone_manuale = gg_gettone_manuale;
        this.importo_gettone_calc = importo_gettone_calc;
        this.importo_gettone_manuale = importo_gettone_manuale;
        this.importo_direzione_calc = importo_direzione_calc;
        this.importo_direzione_manuale = importo_direzione_manuale;
        this.importo_fisso_extra_calc = importo_fisso_extra_calc;
        this.importo_prestazioni_calc = importo_prestazioni_calc;
        this.importo_integr_minimo_calc = importo_integr_minimo_calc;
        this.importo_prestazioni_manuale = importo_prestazioni_manuale;
        this.importo_riporto_mese_succ = importo_riporto_mese_succ;
        this.nota_riporto_mese_succ = nota_riporto_mese_succ || '';
        this.importo_riporto_mese_prec = importo_riporto_mese_prec;
        this.tot_fattura_prestazione = tot_fattura_prestazione;
        this.note = note || '';
        this.flg_scarto_doc = flg_scarto_doc || false;
        this.msg_scarto = msg_scarto || '';
        this.richiesta_annullo_nav = richiesta_annullo_nav;
        this.flg_annullo_nav = flg_annullo_nav || false;
        this.utente_richiesta_annullo_nav = utente_richiesta_annullo_nav || '';
        this.ts_annullo_nav = ts_annullo_nav || new Date();
        this.importo_prest_mediche_manuale = importo_prest_mediche_manuale;
        this.importo_prest_anastesista_manuale = importo_prest_anestesista_manuale;
        this.note_hr = note_hr || '';
        this.gg_gettone_manuale_hr = gg_gettone_manuale_hr;
        this.importo_gettone_manuale_hr = importo_gettone_manuale_hr;
        this.importo_direzione_manuale_hr = importo_direzione_manuale_hr;
        this.importo_prestazioni_manuale_hr = importo_prestazioni_manuale_hr;
        this.importo_direzione__area_manuale_hr = importo_direzione__area_manuale_hr;
        this.importo_direzione__area_calc = importo_direzione__area_calc;
        this.importo_direzione__area_manuale = importo_direzione__area_manuale;
        this.importo_gettone_unitario_hr = importo_gettone_unitario_hr;
        this.importo_gettone_unitario = importo_gettone_unitario;
        this.importo_prest_mediche_manuale_hr = importo_prest_mediche_manuale_hr;
        this.importo_prest_anastesista_manuale_hr = importo_prest_anastesista_manuale_hr;
        this.importo_addebiti_lab_calc = importo_addebiti_lab_calc;
        this.importo_addebiti_lab_manuale_hr = importo_addebiti_lab_manuale_hr;
        this.importo_minimo_garantito=importo_minimo_garantito;
        this.importo_detrazioni_invisalign_manuale=importo_detrazioni_invisalign_manuale;
        this.importo_detrazioni_invisalign_manuale_hr=importo_detrazioni_invisalign_manuale_hr;
        this.importo_fattura_prestazioni=importo_fattura_prestazioni;
        this.importo_fattura_direzione_sanitaria=importo_fattura_direzione_sanitaria;
        this.importo_fattura_direzione_area_clinica=importo_fattura_direzione_area_clinica;
    }

    static getStructure(): Array<any> {
        let structure: any[] = Describer.getStructure(new GceInvoice());
        structure.push(...this.getActions());
        return structure;
    }

    protected static getActions(): any[] {
        let actions: any[] = [];

        if (PermissionService.checkPermission("CGE_CNFT")) {
            let actionConfirmation = {
                action: undefined,
                callback: 'confirmInvoice',
                callbackOnForm: false,
                columnDef: 'actionConfirmation',
                disabled: false,
                header: 'GCE.GCEINVOICE.CONFIRMATION',
                dontShowHeader: true,
                length: 256,
                lookUp: {},
                primaryKey: false,
                reference: null,
                required: false,
                requiredOnNew: false,
                show: true,
                type: 'custom-action',
                values: null,
                visible: true,
                editable: false,
                visibleOnToggle: true,
                imageButton: null,
                imageTable: 'assets/img/invoice-confirm.png',
                order: 0
            };
            actions.push(actionConfirmation);

            actionConfirmation = {
                action: undefined,
                callback: 'sendReport',
                callbackOnForm: false,
                columnDef: 'actionConfirmation',
                disabled: false,
                header: 'GCE.GCEINVOICE.SENDREPORT',
                dontShowHeader: true,
                length: 256,
                lookUp: {},
                primaryKey: false,
                reference: null,
                required: false,
                requiredOnNew: false,
                show: true,
                type: 'custom-action',
                values: null,
                visible: true,
                editable: false,
                visibleOnToggle: true,
                imageButton: null,
                imageTable: 'assets/img/email2.png',
                order: 0
            };

            actions.push(actionConfirmation);
        }

        if (PermissionService.checkPermission("CGE_RPCD")) {
            let actionCalculationDetail = {
                action: undefined,
                callback: 'openDialogPerformanceCalculationDetail',
                callbackOnForm: false,
                columnDef: 'actionCalculationDetail',
                disabled: false,
                header: 'GCE.GCEINVOICE.CALCULATIONDETAIL',
                dontShowHeader: false,
                length: 256,
                lookUp: {},
                primaryKey: false,
                reference: null,
                required: false,
                requiredOnNew: false,
                show: true,
                type: 'custom-action',
                values: null,
                visible: true,
                editable: false,
                visibleOnToggle: false,
                imageButton: 'assets/img/calculator-white.png',
                imageTable: 'assets/img/calculator.png',
                tooltip: 'GCE.GCEINVOICE.CALCULATIONDETAIL.TOOLTIP',
                order: 2
            }
            actions.push(actionCalculationDetail);
        }
        return actions;
    }
}
