import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';

@Pipe({
  name: 'goliFormatPipe'
})
export class GoliFormatPipe implements PipeTransform {

  transform(value: any, type: any, format:any): any {
    let curr = (type == 'Currency') ? '€' : '';
    if ((type != 'Int32' && type != 'Decimal' && type != 'Currency') || format === undefined || format == '') return value;
    if (!value)
      return '0,00' + curr;

    value = parseFloat(value).toFixed(2);
    var parts = value.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",") + ' ' + curr;
  }

}
