import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class ScheduleTimeService {
  hour: any;
  minute: any;
  date: any;
  screen: any;
  weekDay: any;
  eventData: any;
  prices: any;
  organizer: any;
  formatDate: any;
  storeId: any;
  domainId: any;
  operatorSub: any;
  priceLists: any;
  properties: any;


  constructor() { }

  getDropTime(hour, minute, currentDate, screen, eventData, prices, weekDay, organizer, storeId, domainId, operatorSub, priceLists, properties) {

    this.hour = hour;
    this.minute = minute;
    this.date = currentDate;
    this.screen = screen;
    this.weekDay = moment(currentDate).isoWeekday();
    this.eventData = eventData;
    this.prices = prices;
    this.organizer = organizer;
    this.storeId = storeId;
    this.domainId = domainId;
    this.operatorSub = operatorSub;
    this.priceLists = priceLists || [];
    this.properties = properties || [];
    this.formatDate = moment(this.date).hour(+this.hour).minute(+this.minute).toISOString(true).split('.')[0];
  }

  setDropTime() {

    let gridLocation = {
      'storeId': this.storeId,
      'domainId': this.domainId,
      'operatorSub': this.operatorSub,
      'hour': this.hour,
      'minute': this.minute,
      'screen': this.screen,
      'weekDay' : this.weekDay,
      'eventData': this.eventData,
      'prices': this.prices,
      'organizer': this.organizer,
      'priceList': this.priceLists,
      'properties': this.properties,
      'performanceDate': this.formatDate
    };
    return gridLocation;
  }

}
