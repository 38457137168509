import { Component, NgModule, ViewContainerRef } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { MatDialogModule, MatButtonModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { TranslateModule, TranslateCompiler, TranslateService } from '@ngx-translate/core';
import * as mySettings from '../../global/settings';
import * as Us from '../../global/model/user-preferences/user-preferences.model';

@Component({
  selector: 'settingsDialog',
  templateUrl: './settings.dialog.html',
  styleUrls: ['./settings.dialog.scss'],
})
export class SettingsDialog {
  _translate;
  
  constructor(
    public dialogRef2: MatDialogRef<any>
  ) {
    let users;
    if (localStorage.getItem('users') != null) {
      users = JSON.parse(localStorage.getItem('users'));
      if (users.settings === undefined) {
        users.push({ settings: [{
          name: "printTickets",
          value: 1
        }] });
      } else {
        
      }
    }
  }

  closePopup(): void {
    this.dialogRef2.close();
  }

  getLanguages(available: boolean = undefined) {
    let x = [];
    if (available == undefined) return mySettings.getLanguages();
    for (let l of mySettings.getLanguages())
        if (l.available == available)
            x.push(l);
    
    return x;
  }
}