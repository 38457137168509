import { Component, OnInit, Input, SimpleChange, ViewChild, ElementRef, Output, EventEmitter, AfterViewInit, AfterViewChecked } from '@angular/core';
import { DayPilot, DayPilotSchedulerComponent } from 'daypilot-pro-angular';
import { GceManagementService } from '../../services/gce-management.service';
import { GceInvoicePersonClinic, GceInvoicePerson, GceClinic, GcePresence } from '../../entities/gce-management';
import * as moment from 'moment';
import { AuthGuardService } from '../../services';

const tz = "Europe/Rome";
const dateTimeFormat = "YYYY-MM-DDTHH:mm:ss";
const dateFormatDate = "YYYY-MM-DD";
const dateFormatHour = "HH:mm";
const dataViewFormat = "DD/MM/YYYY";

@Component({
  selector: 'app-gce-calendar',
  templateUrl: './gce-calendar.component.html',
  styleUrls: ['./gce-calendar.component.css']
})
export class GceCalendarComponent implements OnInit, AfterViewInit {
  @ViewChild("scheduler", { static: false }) scheduler: DayPilotSchedulerComponent;
  @ViewChild("container", { static: false }) container: ElementRef;
  @Input() fromDate: string;
  @Input() toDate: string;
  @Input() person: GceInvoicePerson;
  @Input() refresh: any = null;
  @Output() openPresence: EventEmitter<GcePresence> = new EventEmitter<GcePresence>();
  clinics: GceClinic[] = [];
  role: string = '';
  usr: string = '';
  dm: string = '';
  events: any[] = [];
  showb:boolean = false;
  timeHeaders = [
    {
      "groupBy": "Month"
    },
    {
      "groupBy": "Day",
      "format": "d"
    }
  ];

  config: any = {
    locale: "it-it",
    weekStarts: 1,
    scale: "Day",
    heightSpec: "Parent100Pct",
    cellWidth: 30,
    timeHeaders: this.timeHeaders,
    floatingEvents: false,
    days: DayPilot.Date.today().daysInMonth(),
    startDate: DayPilot.Date.today().firstDayOfMonth(),
    businessBeginsHour: 8,
    businessEndsHour: 24,
    eventHeight: 100,
    rowHeaderWidthAutoFit: false,
    rowHeaderWidth: 200,
    durationBarVisible: false,
    timeRangeSelectedHandling: "Enabled",
    allowEventOverlap: true,
    // drawBlankCells: false,
    allowMultiSelect: true,
    multiSelectRectangle: "Free",
    snapToGrid: false,
    useEventBoxes: "Never",
    separators: [{ color: "red", location: new DayPilot.Date() }],
    eventResizeHandling: "Disabled",
    onRectangleEventSelect: args => this.onRectangleEventSelect(args),
    eventMoveHandling: "Disabled",
    onEventMove: args => this.onEventMove(args),
    eventDeleteHandling: "Update",
    eventClickHandling: "Enabled",
    onEventClicked: args => this.onEventClicked(args),
    eventRightClickHandling: "Enabled",
    onEventRightClicked: args => this.onEventRightClicked(args),
    eventDoubleClickHandling: "Enabled",
    onEventDoubleClick: args => this.onEventDoubleClick(args),
    eventHoverHandling: "Bubble",
    bubble: new DayPilot.Bubble({
      animated: false,
      onLoad: args => this.onLoadEventBubble(args)
    }),
    onBeforeRowHeaderRender: args => this.onBeforeRowHeaderRender(args),
    onBeforeEventRender: args => this.onBeforeEventRender(args),
  };

  constructor(private gceService: GceManagementService, private auth: AuthGuardService) { }

  ngOnInit() {
    // let locale:any = 'it-it';
    // DayPilot.Locale.register(locale);
    // console.log("daypilot.locale ");
    let usr = this.auth.getUser();
    console.log('USER %o',usr);
    if (usr != null) {
      this.role = usr['ruolo'];
      this.dm = usr['clinica'];
      if (usr['nomeCompleto'] != null && usr['nomeCompleto'].length > 0)
      {
        this.usr = usr['nomeCompleto'];
      }
      else
      {
        if (usr['email'] != null && usr['email'].length > 0)
        {
          this.usr = usr['email'];
        }
      }
    }
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

    let from = new DayPilot.Date(moment(this.fromDate, dateFormatDate).tz(tz).toDate(), true);
    let to = new DayPilot.Date(moment(this.toDate, dateFormatDate).tz(tz).toDate(), true);
    console.log("fromDate %o toDate %o", from, to);
    this.config.startDate = from;
    this.config.days = new DayPilot.Duration(from, to).totalDays() + 1;
    if (changes['person'] || changes['refresh'] || changes['fromDate'] || changes['toDate']) {
      this.clinics = [];
      this.events = [];
      console.log("gce-calendar ngOnChanges %o", this.refresh);
      if (!!this.person) {
        this.loadClinics(this.person, this.dm, this.role);
        this.loadPresences(this.person, this.dm, this.role);
        this.setSchedulerCellWidth();
        this.scheduler.control.scrollTo(new DayPilot.Date(), "normal", "middle");
        setTimeout(() => {
          console.log("ngOnChanges %o %o", this.container.nativeElement.offsetHeight, this.container.nativeElement.offsetWidth);
          this.setSchedulerCellHeight();
        }, 500);
      }
    }

  }

  ngAfterViewInit() {
    console.log("ngAfterViewInit %o %o", this.container.nativeElement.offsetHeight, this.container.nativeElement.offsetWidth);
  }

  // ngAfterViewChecked(){
  //   console.log("ngAfterViewChecked %o %o", this.container.nativeElement.offsetHeight, this.container.nativeElement.offsetWidth);
  // }

  setSchedulerCellHeight() {
    let cellHeight = 100;
    if (this.clinics.length < 7) {
      let height = (this.container.nativeElement.offsetHeight === 0 ? 693 : this.container.nativeElement.offsetHeight) - 60;
      // this.config.height = height;
      cellHeight = (height - 4) / this.clinics.length;
    }
    this.config.eventHeight = cellHeight;
    this.scheduler.control.update();
  }

  setSchedulerCellWidth() {
    let width = this.container.nativeElement.offsetWidth - 210;
    let cellWidth = width / DayPilot.Date.today().daysInMonth();
    this.config.cellWidth = cellWidth;
    this.scheduler.control.update();
  }

  loadClinics(person: GceInvoicePerson, dm: string, role: string) {
    console.log('LOAD CLINICS FOR %o',person);
    this.gceService.loadClinicsForPerson(person, dm, role).subscribe(({ personClinics, clinics }) => {
      console.log('LOADED CLINICS %o',clinics);
      this.clinics = clinics;
      this.config.resources = this.clinics.map((clinic: GceClinic) => {
        return {
          name: `${clinic.code_c} <br> ${clinic.nome}`,
          id: clinic.code_c,
          clinic: clinic
        }
      });
      //this.setSchedulerCellHeight();
    }, err => {

    });
  }

  loadPresences(person: GceInvoicePerson, dm: string, role: string) {
    console.log('LOAD PRESENCE FOR %o',person);
    let from = moment(this.fromDate).format("YYYYMMDD");
    let to = moment(this.toDate).format("YYYYMMDD");
    this.gceService.getPresencesFromTo(person.idfatturazione, dm, role, from, to).subscribe((presences: GcePresence[]) => {
      console.log('LOADED PRESENCE %o',presences);
      this.setEvents(presences);
    });
  }

  setEvents(presences: GcePresence[]) {
    console.log("setEvents %o", presences);
    let newEvents = presences.filter(pr => {
      let id = `${pr.idfatturazione}${pr.cod_clinica}${moment(pr.data).format('YYYYMMDD')}`;
      return !this.events.find(e => e.id === id);
    }).map(pr => {
      return this.parseEvent(pr);
    });
    this.events = [];
    this.events.push(...newEvents);
    console.log(this.events);
    this.scheduler.control.update();
  }

  parseEvent(presence: GcePresence): any {
  
    let dalle = presence.disponibilita_dalle;
    let alle = presence.disponibilita_alle;
    if (dalle == null)
    {
      dalle = '08:00:00';
    }
    if (alle == null)
    {
      alle = '17:00:00';
    }
    let start = `${moment(presence.data).format('YYYY-MM-DD')}T${dalle}`;
    let end = `${moment(presence.data).format('YYYY-MM-DD')}T${alle}`;
    let df = moment(presence.data).format('YYYYMMDD');
    let event = {
      id: `${presence.idfatturazione}${presence.cod_clinica}${df}`,
      start: start,
      end: end,
      resource: presence.cod_clinica,
      text: `${dalle} - ${alle}`,
      presence: presence,
      selected: false
    }
    return event;
  }

  viewChange() {

  }

  onEventMove(args: any) {

  }

  onEventClicked(args: any) {

  }

  onEventDoubleClick(args: any) {
    console.log("onEventDoubleClick %o", args.e.data.presence);
    this.openPresence.emit(args.e.data.presence);
  }

  onEventRightClicked(args: any) {

  }

  onBeforeEventRender(args: any) {
    //console.log("onBeforeEventRender %o", args.data);
    let presence: GcePresence = args.data.presence;
    let dalle = presence.disponibilita_dalle;
    let alle = presence.disponibilita_alle;
    if (dalle == null)
    {
      dalle = '08:00:00(d)';
    }
    if (alle == null)
    {
      alle = '17:00:00(d)';
    }
    let firstLine = `<span>Dalle: ${dalle}</span>`;
    let secondLine = `<span>Alle: ${alle}</span>`; //was dalle?
    args.data.html = `<div class="gce-calendar-card"></div>`;
    args.data.deleteDisabled = true;
  }

  onBeforeRowHeaderRender(args: any) {

  }

  onLoadEventBubble(args: any) {
    let presence: GcePresence = args.source.data.presence;
    let person: GceInvoicePerson = this.gceService.getInvoicePersonById(presence.idfatturazione);
    let clinic: GceClinic = this.gceService.getClinicByCode(presence.cod_clinica);
    let dalle = presence.disponibilita_dalle;
    let alle = presence.disponibilita_alle;
    if (dalle == null)
    {
      dalle = '08:00:00';
    }
    if (alle == null)
    {
      alle = '17:00:00';
    }
    
    let a = moment(dalle, 'HH:mm:ss').format(dateFormatHour);
  
    let b = moment(alle, 'HH:mm:ss').format(dateFormatHour);
    
    let line1 = `<span>${person.descrizione}</span>`;
    let line2 = `<span>Clinica: ${clinic.code_c} - ${clinic.nome}</span>`;
    let line3 = `<span>Data: ${moment(presence.data).format(dataViewFormat)}</span>`;
    let line4 = `<span>Dalle: ${a} - Alle: ${b}</span>`;

    args.html = `<div class="gce-calendar-card gce-bubble">${line1}${line2}${line3}${line4}</div>`;
  }

  onRectangleEventSelect(args: any) {

  }
}
