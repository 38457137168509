import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { AuthGuardService } from './../../services/auth-guard.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from './../../loader';
import { MatDialog } from '@angular/material';
import * as $ from 'jquery';
import { environment } from '../../../environments/environment';
import { NotificationsService } from 'angular2-notifications';
import { YesNoDialogComponentS } from '../shared/dialogs/yes-no.dialog';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { OrderDetailDialogComponent } from '../shared/dialogs/order-detail.dialog';
import { ExcelService } from '../../services/excel.service';
import { DataRepositoryService } from '../../services/data-repository.service';
import {Subject} from "rxjs/Subject";

import * as moment from 'moment';

@Component({
  selector: 'app-order-invoiceable',
  templateUrl: './order-invoiceable.component.html',
  styleUrls: ['./order-invoiceable.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class OrderInvoiceableComponent implements OnInit {
  componentDestroyed$: Subject<boolean> = new Subject();
  rows = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  columns = [];
  temp = [];
  sub: any;
  listaCambiFIltrabili = [];
  listaCambiTime = [];
  listaCambiFIltrabiliOpzioni = [];
  key;
  url;
  dataInserimento;
  req;
  infoAggiuntive = false;
  entryPoint;
  sel;
  numericofiltro: number;
  numericofiltro2: number;
  sottoscortaJ;
  tableMessages;
  applied = false;
  tipoBolle = false;
  _tipologieBolle = [];
  _images;
  header;
  _tiles;
  newOrder;
  clickable;
  doubleClickable;
  exception;
  mainId;
  firstOpen = true;
  selected = [];
  selected2 = [];
  selectable;
  pparams;
  elementiPerPagina;
  filters = false;
  insideReports = false;
  hidJSON;
  backCaption;
  back;
  caption;
  subtitle;
  showReportParams;
  _params;
  filterDataDa;
  filterDataA;
  io = {};
  _repoParamsBack;
  infoOrdine = false;
  detB = false;
  excIndicator = false;
  reporequest;
  idcf; idf;
  forecast;
  stringafiltro;
  impostazioniForecast;
  clickedTile;
  _repoResp;
  _repoParams;
  _handlers = ['pdf', 'xls'];
  pulisciR = false;
  atterra = undefined;
  acco = "Documento accompagnatorio";
  cbimg = "./assets/images/icone/icn_back.png";
  eximg = "./assets/images/icone/icn_excel.png";
  ans;
  indGen = 0;
  ia1 = "";
  ia2;
  ia3 = "";
  ia4 = "";
  idfo = 0;
  bollaSave = false;
  requestBackorder;
  _reports;
  tot = 0;
  rifObj; notObj;
  backup = undefined;
  click;
  oldTile; editing = {};
  id_print = 0; updated = false;
  search; updated2;
  oldC;
  oldR;
  insideBolleUscita = false;
  insideAlerts = false;
  selectedEdit;
  startValueTime;
  br: any[];
  brR: any[];
  ca = []; ca2 = [];
  expanded = false;
  filterStato = new FormControl("tutti");
  filterStatoCustom = new FormControl("tutticampi");
  filterStatoTime;
  filterStatoCustomOpz = new FormControl("tutticampiopz");
  listaStati = [];

  @ViewChild(DatatableComponent, null) table: DatatableComponent;

  constructor(
    private _auth: AuthGuardService,
    private _http: HttpClient,
    private _route: ActivatedRoute,
    private _router: Router,
    private _dialog: MatDialog,
    private _notifications: NotificationsService,
    private _loaderRogges: LoaderService,
    private excelService: ExcelService,
    public dataRepo: DataRepositoryService
  ) {
    // this._renderer.addClass(document.body, 'withoutoverflow');

    this.columns = [];

    this.hidJSON = "";
    this.filterStatoTime = new FormControl(environment['DefaultTime'].viewvalue);
    this._images = environment['imagesS'][1];
    this.startValueTime = environment['DefaultTime'];
    this.listaCambiTime = environment['TimeValues'];
    this.numericofiltro = 0;
    this.numericofiltro2 = 0;
    this.stringafiltro = "";
    this.tableMessages = environment['tableMessages'];
    this.elementiPerPagina = 10;// +sessionStorage.getItem('elementiPerPagina');
    if (this.elementiPerPagina == 0) this.elementiPerPagina = 10;
    this.sub = this._route.params.subscribe(params => {
      this.pparams = params;
      this.entryPoint = params['entryPoint'];
      let id = 0; let idD = 0;

      this.clickable = true;
      this.selectable = true;
      this.doubleClickable = true;

      this.filters = false;
      //this.callTable();
      console.log('end constructor');
    });
  }

  @ViewChild('mi1',null) _input: ElementRef;
  @ViewChild('mi2',null) _input2: ElementRef;
  @ViewChild('mi3',null) _input3: ElementRef;
  _openCalendar(picker: MatDatepicker<Date>, x = null) {
    picker.open();
    setTimeout(() => x == null ? this._input.nativeElement.focus() : x == 2 ? this._input2.nativeElement.focus() : this._input3.nativeElement.focus());
  }
  _openCalendarDyn(picker: MatDatepicker<Date>, id) {
    picker.open();
    var _input = document.getElementById(id);
    setTimeout(() => _input.focus());
  }

  getReportImg(x) {
    return './assets/images/tiles/v2/' + (x ? 'icn_report_2.png' : 'icn_report_hover.png')
  }

  focus() {

    $(function () {
      $('#txtQ').select();
      $('#txtQ').focus();
    });

  }

  focusLente(id) {
    //console.log('FOCUS LENTE');
    $(function () {
      $('#' + id).select();
      $('#' + id).focus();
    });
    if (this.br != undefined)
      this.rows = [...this.br];
    this.editing = {};
  }

  focusLenteReport(id, ind) {
    //console.log('FOCUS LENTE REPORT');
    $(function () {
      $('#' + id).select();
      $('#' + id).focus();
    });
    if (this.brR != undefined) this._repoResp.Queries[ind].Table = [...this.brR];

  }


  hasClass(who, asc) {
    if (!asc && document.getElementsByClassName('sort-desc').length == 0) return true;
    if (asc && document.getElementsByClassName('sort-asc').length == 0) return true;
    if (!asc)
      for (let x of Array.prototype.slice.call(document.getElementsByClassName('sort-desc')))
        try {
          return !(who == x.children[0].children[0].children[0].children[1].children[1].innerHTML.trim());
        }
        catch (e) {
          return true;
        }
    if (asc)
      for (let x of Array.prototype.slice.call(document.getElementsByClassName('sort-asc')))
        try {
          return !(who == x.children[0].children[0].children[0].children[1].children[1].innerHTML.trim());
        } catch (e) {
          return true;
        }
    return true;
  }

  onSort(ev) {
    console.log('on SORT %o', ev);
  }

  sort(ev) {
    console.log('SORT %o', ev);
  }

  rebuildTable() {

    //console.log('this.table %o element %o %o', document.getElementsByClassName('myTable'), document.getElementsByClassName('myTable')[0].children[0].children[1], document.getElementsByTagName('datatable-body')[0]);
    //document.getElementsByClassName('myTable')[0].children[0].children[1].scrollTop = 0;
    //document.getElementsByTagName('datatable-body')[0].scrollTop = 0;
    //$("datatable-body").scrollTop(0);

    //$('#my-ngx').css('height', '500px !important')

    //let tempRows = this.rows;
    let cpRows = this.rows;
    this.rows = [];

    for (let c = 0; c < cpRows.length; c = c + 1) {
      this.rows.push(cpRows[c]);
    }

    console.log('rebuildTable for %o', this.rows);
    //this.selected = [];
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>, da = 1) {
    console.log('addEvent event %o da %o', event, da);
    this._loaderRogges.show();
    if (this.backup == undefined) this.backup = this.rows;
    if (event.value != null) {
      if (da == 3) {
        this.rows = this.backup;
        // >= o <=
        var interesse;
        if (this.filterStatoCustomOpz.value == "5")
          interesse = moment(event.value).add(23, 'h').add(59, 'm').toDate();
        else
          interesse = moment(event.value).add(0, 'h').add(0, 'm').toDate();
        if (this.backup == undefined) this.backup = this.rows;
        var campo = "";
        for (let x of this.columns)
          if (x.name.toLowerCase() == this.filterStatoCustom.value.toLowerCase()) {
            campo = x.prop;
            break;
          }
        var preR =
          this.filterStatoCustomOpz.value == "1" ?
            this.backup.filter(
              x => new Date(x[campo]) >= interesse && new Date(x[campo]) <= moment(event.value).add(23, 'h').add(59, 'm').toDate()
            )
            :
            this.filterStatoCustomOpz.value == "5" ?
              this.backup.filter(
                x => new Date(x[campo]) <= interesse
              )
              :
              this.backup.filter(
                x => new Date(x[campo]) >= interesse
              );
        //if (this.filterStato.value != "tutti")
        //preR = preR.filter(x => x.StatoAvanzamento === this.filterStato.value.toUpperCase());
        this.rows = [...preR];
        this._loaderRogges.hide();
        return;
      }
      if (da == 1) this.filterDataDa = event.value; else this.filterDataA = moment(event.value).add(23, 'h').add(59, 'm').toDate();
      console.log('filter DATA from %o to %o', this.filterDataDa, this.filterDataA);
      if (this.filterDataDa != undefined && this.filterDataA != undefined) {
        console.log('A');
        if (this.backup == undefined) {
          console.log('B');
          this.backup = this.rows;
        }

        var preR = this.backup.filter(x => new Date(x.DataDocumento) >= this.filterDataDa && new Date(x.DataDocumento) <= this.filterDataA);
        console.log('preR %o filterStato %o', preR, this.filterStato);
        if (this.filterStato.value != "tutti") {
          preR = preR.filter(x => x.StatoAvanzamento === this.filterStato.value.toUpperCase());
        }
        this.rows = [...preR];
      }
      else
        this.rows = [...this.backup];
    }
    else
      this.rows = [...this.backup];
    this._loaderRogges.hide();
  }

  callTable() {
    this.updated = false;

    this.impostazioniForecast = this.forecast = false;
    console.log('THIS.ENTRYPOINT %o envinronment %o', this.entryPoint, environment);

    this.key = 'ordini';
    this.url = environment['getListaOrdiniDaFatturare'];
    this.req = {
      idnegozio: environment['idNegozio'],
      GUID: this._auth.getGUID(),
      DomainId: this._auth.getDomain(), //'6850'
      ProfileId: this._auth.getProfile()
    };

    for (let a = 0; a < environment['TimeValues'].length; a = a + 1) {
      if (environment['TimeValues'][a].viewvalue == this.filterStatoTime.value) {
        if (!(environment['TimeValues'][a].value == 0)) {
          this.req.DataMax = new Date(Date.now());
          this.req.DataMin = new Date(Date.now() - (environment['TimeValues'][a].value * 30 * 24 * 60 * 60 * 1000));
        }
        else {
          this.req.DataMax = new Date(Date.now());
          this.req.DataMin = new Date(Date.now() - (24 * 30 * 24 * 60 * 60 * 1000));
        }
        this.req.DataMax.setHours(23);
        this.req.DataMax.setMinutes(59);
        this.req.DataMin.setHours(0);
        this.req.DataMin.setMinutes(0);

        this.req.DataMax = moment.utc({ year: this.req.DataMax.getFullYear(), month: this.req.DataMax.getMonth(), day: this.req.DataMax.getDate(), hour: this.req.DataMax.getHours(), minute: this.req.DataMax.getMinutes(), second: this.req.DataMax.getSeconds(), millisecond: this.req.DataMax.getMilliseconds() }).locale("it");
        this.req.DataMin = moment.utc({ year: this.req.DataMin.getFullYear(), month: this.req.DataMin.getMonth(), day: this.req.DataMin.getDate(), hour: this.req.DataMin.getHours(), minute: this.req.DataMin.getMinutes(), second: this.req.DataMin.getSeconds(), millisecond: this.req.DataMin.getMilliseconds() }).locale("it");
      }
    }
    console.log('filterStatoTime value %o req %o', this.filterStatoTime.value, this.req);

    this.back = "";
    this.backCaption = "Dashboard";
    this.caption = "Ordini fatturabili ";
    this.subtitle = undefined;
    this.header = true;
    this.newOrder = false;
    this.clickable = false;
    this.selectable = true;
    this.filters = true;

    sessionStorage.removeItem('dup');
    sessionStorage.removeItem('dupInfo');
    /*
    this._http.post(
      environment['GetReportsData'],
      {
        idnegozio: environment['idNegozio'],
        GUID: this._auth.getGUID(),
        DomainId: this._auth.getDomain(),
        ProfileId: this._auth.getProfile(),
        RCode: "CHECK_SOTTOSCORTA",
        Params: []
      }
    )
      .subscribe((res) => {
        console.log('CHECK SOTTOSCORTA %o', res);
      });
    */
    if (this.url != undefined)
      this._loaderRogges.show();
    this.fetch((data) => {
      console.log('FETCH DATA %o', data);

      //this._loaderRogges.hide();
      this.temp = [];

      //console.log('FILTER STATUS');
      for (let r of data) {
        if (this.listaStati.indexOf(r.StatoAvanzamento) == -1)
          this.listaStati.push(r.StatoAvanzamento);
        //if (r.StatoAvanzamento == 'MERCE RICEVUTA') {
        let t = r;
        t.checked = false;
        //check DataDocumento, only for this type of documents
        let myDate = new Date(t['DataDocumento']);
        //let boundDate = new Date('2019-03-31T00:00:00');
        //console.log('MY DATE %o',myDate);
        //if (myDate > boundDate)
          this.temp.push(t);
        // }
      }

      this.rows = [...this.temp]; // Refresh the data
      this.loadingIndicator = false;

    });

    console.log('_images after callTable %o', this._images);
  }

  getChecked(row: any) {
    //console.log('getCkecked %o',row);
    for (let a = 0; a < this.rows.length; a = a + 1) {
      if (this.rows[a]['Iddoc'] == row['Iddoc'] && this.rows[a]['checked'] == true) {
        //console.log('find %o value %o', row['Iddoc'], this.rows[a]['checked']);
        return true;
      }

    }
    return false;
  }

  exportExcel()
  {
    console.log('export %o',this.rows);
    this.excelService.exportGeneric(this.rows,'OrdiniFatturabiliDentalPro.xls');
  }

  selP(row) {
    console.log('selP %o', row);
    let ner = true;
    //check if it's checked :)

    for (let a = 0; a < this.rows.length; a = a + 1) {
      if (this.rows[a]['Iddoc'] == row['Iddoc']) {
        if (this.rows[a]['checked'] == true) {
          console.log('delete %o', row);
          this.rows[a]['checked'] = false;
          ner = false;
          let st = this.selected;
          this.selected = [];
          for (let b = 0; b < st.length; b = b + 1) {
            if (st[b]['Iddoc'] == row['Iddoc']) {

            }
            else {
              console.log('delete insert %o', st[b]);
              this.selected.push(st[b]);
            }
          }
        }
        else {
          this.rows[a]['checked'] = true;
          this.selected.push(row);
        }
      }
    }

    //CHECK IF MITTENTE IS THE SAME
    /*
    if ((ner == true) && this.selected != null && this.selected.length > 0) {
      if (row['Idmitt'] != this.selected[0]['Idmitt']) {
        window.alert('Attenzione: è possibile selezionare ordini appartenenti allo stesso Mittente');
        ner = false;
      }
    }
    */
    /*
    if (ner == true) {
      console.log('insert into selected after %o', row);
      this.selected.push(row);

      for (let a = 0; a < this.rows.length; a = a + 1) {
        if (this.rows[a]['Iddoc'] == row['Iddoc']) {
          this.rows[a]['checked'] = true;
        }

      }

    }
    */
    console.log('this.selected after %o', this.selected);
  }

  clickReport(r) {
    localStorage.setItem('repoInfo', JSON.stringify(r));
    this.clickedTile = r;
    this._router.navigate(['/pm/store/action/reports/' + r['RCode']]);
  }

  chEPP(e) {
    sessionStorage.setItem('elementiPerPagina', "" + e.target.value);
    this.elementiPerPagina = +sessionStorage.getItem('elementiPerPagina');
  }

  checkParam(_p, f, oth = undefined) {
    if (_p != undefined)
      for (let p of _p)
        if (p['value'] == undefined || p['value'] == '' || p.value == 'Invalid Date') {
          this._notifications.error(
            "Errore",
            "Compila tutti i parametri nella schermata per generare o visualizzare il report.",
            environment['notificationOptions']
          );
          return;
        }
  }

  getISODate(data) {
    return moment(data).format('YYYY-MM-DD');
  }

  downloadReport(url, data) {
    this.hidJSON = JSON.stringify(data);
    document.getElementById('hidJSONButton').click();
  }

  getDownloadReport(h) {
    return environment['GetReportsDataHandler'].replace("{0}", h);
  }

  loadTiles() {
    this.click = undefined;
    let tiles = [];
    let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));

    if (userInfo.fornitoriTiles == undefined || userInfo.fornitoriTiles.length == 0)
      this._router.navigate(['/error/4dca90bb078b33d930f1e168a93b55c5365d20ae']);

    if (environment['MyContext'] == "DENTALPRO") userInfo.fornitoriTiles.push({ "CategoriaFornitore": "ORDINE CROSS", "IdCategoriaFornitore": -99, "Qta": -99 });
    for (let x of userInfo.fornitoriTiles)
      tiles.push({
        tile: x.CategoriaFornitore,
        id: x.IdCategoriaFornitore,
        enabled: true,
        src: x.CategoriaFornitore.toLowerCase(),
        srcHover: x.CategoriaFornitore.toLowerCase() + '_hover',
        action: x.CategoriaFornitore.toLowerCase()
      });

    return tiles;
  }

  ngOnInit() {
    this.selected2 = [];
    this.callTable();
  }

  changeBack(src = "") {
    if (src != "") this.cbimg = "./assets/images/icone/" + src;
    else this.cbimg = "./assets/images/icone/icn_back.png";
  }

  hoverBox(id, ent) {
    if (this.entryPoint == 'reports') return;
    for (let i of this._tipologieBolle)
      i.img = './assets/images/tiles/v2/icn_uscite.png';
    this._tipologieBolle[id].img = !ent ? './assets/images/tiles/v2/icn_uscite.png' : './assets/images/tiles/v2/box_hover.png';
  }

  testDate(x) {
    //console.log(x)
  }

  test() {
    //console.log(this.hidJSON)
  }

  testParse(val) {
    var a = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var b = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
    var ind = 0;
    for (let x of a)
      if (val != undefined)
        val = val.replace(x, b[ind++]);

    return val;
  }

  getTot() {
    this.tot = 0;
    if (this.rows.length == 0) return this.tot.toFixed(2);
    for (let x of this.rows)
      this.tot += ((x == undefined) ? 0 : +x['Totale']);
    return this.tot.toFixed(2);
  }

  performAction(i) {

    console.log('PERFORMACTION %o', i);

    if (i.enabled) {
      this._loaderRogges.show();

      let act = i.action;
      if (act == 'view') {
        //view
        this.openDettaglio(this.selected[0]);
      }
      if (act == 'print') {
        //emit
        this.printDoc(this.selected[0]);
      }
      if (act == 'inv') {
        //emit
        this.invDoc();
      }
      if (act == 'exp') {
        //export
        this.excelService.exportData(this.rows, this.columns, 'ordini_fatturabili');
      }
      this._loaderRogges.hide();
    }

  }

  printDoc(doc: any) {
    console.log('print doc %o', doc);
    let urlPDF = environment['GetHandlerPDF'].replace('{0}', environment['idNegozio'].toString());
    urlPDF = urlPDF.replace('{2}', this._auth.getDomain() == '1' ? '1' : '0');
    urlPDF = urlPDF.replace('{1}', "" + doc.Iddoc);

    window.open(urlPDF);
  }

  invDoc() {
    //console.log('INV DOC %o', this.rows);
    let str = '';
    let rows = [];
    this.selected = [];
    for (let a = 0; a < this.rows.length; a = a + 1) {
      if (this.rows[a]['checked'] == true)
        this.selected.push(this.rows[a]);
    }
    /*
    for (let a = 0; a < this.rows.length; a = a + 1) {
      if (this.rows[a]['checked'] == true)
        rows.push(this.rows[a]);
    }

    if (rows == null || rows.length == 0) {
      window.alert('Nessun ordine selezionato');
      return;
    }
*/
    /*
    //check congruency for same clinic
    //removed!
    let idMittStart = rows[0]['Idmitt'];
    let idDestStart = rows[0]['Iddest'];
    
    for (let a = 0; a < rows.length; a = a + 1) {
      if (!(rows[a]['Idmitt'] == idMittStart))
      {
        window.alert('ATTENZIONE: è necessario selezionare ordini relativi al medesimo fornitore');
        return;
      }
      if (!(rows[a]['Iddest'] == idDestStart))
      {
        window.alert('ATTENZIONE: è necessario selezionare ordini relativi alla medesima clinica');
        return;
      }
    }
    */
    console.log('INV DOC %o and selected %o', this.rows, this.selected);
    if (this.selected.length == 1) {
      str = 'Sei sicuro di voler generare la prefattura relaviva all\'ordine ' + this.selected[0]['Iddoc'] + '?';
    }
    else {
      str = 'Sei sicuro di voler generare la prefattura relaviva agli ordini ' + this.selected[0]['Iddoc'];
      for (let a = 1; a < this.selected.length; a = a + 1) {
        str = str + ', ' + this.selected[a]['Iddoc'];
      }
      str = str + '?';
    }
    if (window.confirm(str)) {
      //ask for number and date of invoice and generate it!

     this.url = environment['emettiFattureFornitori'];
     //this.url ="https://webapidentalpro.dentalpro.it/webapi/api/backoffice/documents/emettiFattureNoteDiCreditoFornitori";
      this.req = {
        idnegozio: environment['idNegozio'],
        GUID: this._auth.getGUID(),
        DomainId: this._auth.getDomain(), //'6850'
        ProfileId: this._auth.getProfile(),
        idMitt: this.selected[0]['Idmitt'],
        idDest: this.selected[0]['Iddest'],
        listIdDoc: []
      };
      for (let a = 0; a < this.selected.length; a = a + 1) {
        this.req.listIdDoc.push(this.selected[a]['Iddoc']);
      }

      console.log('call %o with %o', this.url, this.req);
      this.loadingIndicator = true;
     
      this._http.post(
        this.url,
        this.req
      )
      .takeUntil(this.componentDestroyed$)
        .subscribe((res) => {
          console.log('RESULT %o', res);
          if (res['esito'] == true) {
            window.alert('Operazione eseguita con successo');
          }
          else {
            window.alert('ERRORE nell\'operazione');
          }
          this.loadingIndicator = false;
          //rebuild orders
          //this._router.navigate(['/suppliers']);
          this.callTable();
        });

    }

  }

  openUscitaAddProd(id = undefined) {
    //document.getElementsByTagName('datatable-body')[0].scrollTop = 0;
    if (this.table != null) {
      this.table.offset = 0;
      this.table.bodyComponent.updateOffsetY(0);
    }
    console.log('openUscitaAddProd with id %o', id);
    if (id != undefined) {
      this.infoAggiuntive = true;
      sessionStorage.setItem('uscite_destin', id);
      this.rows = [...[]];
      this.performAction(this._images[0]);

    } else {
      sessionStorage.removeItem('uscite_destin');
      //this.rows = [...[]];
    }
    this.header = true;
  }

  goBack(act) {
    console.log('goBACK entryPoint %o act %o', this.entryPoint, act);

    //this.br = undefined;

    this.rows = [];
    this._router.navigate(['/pm/suppliers']);
  }

  generateBackReports(id) {
    this.mainId = this.reporequest['RCode'];
    if (this.reporequest['IsRoot'])
      this._router.navigate(['/pm/store/'])
    else
      this._router.navigate(['/pm/store/action/reports/' + this.mainId])
  }

  fetch(cb) {
    console.log("IN FETCH cb %o", cb);
    if (this.url == undefined) {
      console.log("Impossibile caricare la tabella.");
      return;
    }
    this.loadingIndicator = true;

    this._http.post(
      this.url,
      this.req
    )
    .takeUntil(this.componentDestroyed$)
      .subscribe(res => {
        console.log("DATA read for TABLE %o", res);
        let col;
        col = res['tableMap'].columns;
        this.columns = [];

        if (res['resultRiferimentoBolla'] != undefined) this.io['resultRiferimentoBolla'] = res['resultRiferimentoBolla'];

        this.columns.push({
          prop: "__Check",
          name: "",
          flexGrow: 1,
          format: "",
          type: "Boolean",
          sortable: true
        });
        for (let k of Object.keys(col)) {
          let c = col[k];
          if (((k == 'Fornitore') || (k == 'CategoriaFornitore')) && (this.dataRepo.user.desc_profile != null) && (this.dataRepo.user.desc_profile.toLowerCase().indexOf('amministratore') >= 0 || this.dataRepo.user.desc_profile.toLowerCase().indexOf('acquisti') >= 0)) {
            this.columns.push({
              prop: k,
              name: c.caption,
              format: c.format,
              type: c.type,
              flexGrow: 2
            });
            this.listaCambiFIltrabili.push(c.caption);
          }
          if (c.visible) {
            if (!(k == 'Fornitore') && !(k == 'CategoriaFornitore')) {
              this.columns.push({
                prop: k,
                name: c.caption,
                format: c.format,
                type: c.type,
                flexGrow: 2
              });
              this.listaCambiFIltrabili.push(c.caption);
            }
          }
        };
        /*
        this.columns.push({
          prop: "__DettaglioOrdini",
          name: "Dettaglio",
          flexGrow: 2
        });
        this.columns.push({
          prop: "__ReSend",
          name: "Reinvia e-mail",
          flexGrow: 2
        });
        */
        console.log('COLUMNS %o', this.columns);

        if (res[this.key] == null || res[this.key] == undefined) {
          this._notifications.error(
            "Errore",
            "Errore nel recupero delle informazioni dal server.",
            environment['notificationOptions']
          );
          this.loadingIndicator = false;
        }
        else {
          let cp = [];

          //let boundDate = new Date('2019-03-31T00:00:00');
          //console.log('MY DATE %o',myDate);

          for (let a = 0; a < res[this.key].length; a = a + 1) {
            let myDate = new Date(res[this.key][a]['DataDocumento']);
            //if (myDate > boundDate)
              cp.push(res[this.key][a]);
          }

          cb(cp);
        }

        this._loaderRogges.hide();

      });

    // $(function () {
    //$('#my-ngx').css('height', (window.innerHeight - 300) + 'px');
    //$('.datatable-body').css('height', (window.innerHeight - 300) + 'px');
    // });
  }

  isCurrency(t, f) {
    return ((t == 'Currency') && f != '' && f !== undefined);
  }

  openDettaglio(row) {

    console.log('open dettaglio %o', row);
    let dialogRef;
    dialogRef = this._dialog.open(OrderDetailDialogComponent, {
      width: '80%',
      height: '80%',
      data: { dati: row, chiudi: true, bollaEntrata: true }
    });

  }

  hover(tile) {
    if (this.click != undefined && this.click == tile.id) return;
    if (tile.src.indexOf('_hover') !== -1) {
      tile.src = tile.src.substring(0, tile.src.length - 6);
      tile.srcHover = tile.src + '_hover';
    } else {
      tile.srcHover = tile.srcHover.substring(0, tile.srcHover.length - 6);
      tile.src = tile.srcHover + '_hover';
    }
  }

  filterStatoType = "";
  applyFilter() {
    if (this.backup == undefined) this.backup = this.rows;
    if (this.filterStato.value == "tutti") {
      if (this.filterDataDa != undefined && this.filterDataA != undefined) {
        preR = this.backup.filter(x => new Date(x.DataDocumento) >= this.filterDataDa && new Date(x.DataDocumento) <= this.filterDataA);
      } else preR = this.backup;
      this.rows = [...preR];
      return;
    }
    var preR = this.backup.filter(x => x.StatoAvanzamento === this.filterStato.value.toUpperCase());
    if (this.filterDataDa != undefined && this.filterDataA != undefined) {
      preR = preR.filter(x => new Date(x.DataDocumento) >= this.filterDataDa && new Date(x.DataDocumento) <= this.filterDataA);
    }
    this.rows = [...preR];
  }
  /*
  applyFilter() {
    if (this.backup == undefined) this.backup = this.rows;
    if (this.filterStato.value == "tutti") {
      if (this.filterDataDa != undefined && this.filterDataA != undefined) {
        preR = this.backup.filter(x => new Date(x.DataIns) >= this.filterDataDa && new Date(x.DataIns) <= this.filterDataA);
      } else preR = this.backup;
      this.rows = [...preR];
      return;
    }
    var preR = this.backup.filter(x => x.StatoAvanzamento === this.filterStato.value.toUpperCase());
    if (this.filterDataDa != undefined && this.filterDataA != undefined) {
      preR = preR.filter(x => new Date(x.DataIns) >= this.filterDataDa && new Date(x.DataIns) <= this.filterDataA);
    }
    this.rows = [...preR];
  }
  */
  getFilterName() {
    var val = +this.filterStatoCustomOpz.value;
    for (let x of this.listaCambiFIltrabiliOpzioni)
      if (x.val == val)
        return x.prop.toLowerCase();
  }

  applyTimeFilter(x = false) {
    console.log('filterStatoTime %o req %o', this.filterStatoTime, this.req);
    for (let a = 0; a < environment['TimeValues'].length; a = a + 1) {
      if (environment['TimeValues'][a].viewvalue == this.filterStatoTime.value) {
        if (!(environment['TimeValues'][a].value == 0)) {
          this.req.DataMax = new Date(Date.now());
          this.req.DataMin = new Date(Date.now() - (environment['TimeValues'][a].value * 30 * 24 * 60 * 60 * 1000));
        }
        else {
          this.req.DataMax = new Date(Date.now());
          this.req.DataMin = new Date(Date.now() - (24 * 30 * 24 * 60 * 60 * 1000));
        }
        this.req.DataMax.setHours(23);
        this.req.DataMax.setMinutes(59);
        this.req.DataMin.setHours(0);
        this.req.DataMin.setMinutes(0);

        this.req.DataMax = moment.utc({ year: this.req.DataMax.getFullYear(), month: this.req.DataMax.getMonth(), day: this.req.DataMax.getDate(), hour: this.req.DataMax.getHours(), minute: this.req.DataMax.getMinutes(), second: this.req.DataMax.getSeconds(), millisecond: this.req.DataMax.getMilliseconds() }).locale("it");
        this.req.DataMin = moment.utc({ year: this.req.DataMin.getFullYear(), month: this.req.DataMin.getMonth(), day: this.req.DataMin.getDate(), hour: this.req.DataMin.getHours(), minute: this.req.DataMin.getMinutes(), second: this.req.DataMin.getSeconds(), millisecond: this.req.DataMin.getMilliseconds() }).locale("it");

      }
    }

    if (this.url != undefined) {
      this._loaderRogges.show();
      this.fetch((data) => {
        //this._loaderRogges.hide();
        this.temp = [];

        for (let r of data) {
          if (this.listaStati.indexOf(r.StatoAvanzamento) == -1)
            this.listaStati.push(r.StatoAvanzamento);
          // if (r.StatoAvanzamento == 'MERCE RICEVUTA')
          let t = r;
          t.checked = false;
          this.temp.push(t);

        }

        var bi = 0;
        if (this.entryPoint == 'bolla-entrata-detail')
          for (let x of this.temp)
            x['NumRiga'] = ++bi;
        this.rows = [...this.temp]; // Refresh the data
        this.loadingIndicator = false;

        //sergiosick
        var tre = 1;
        if (this.rows.length > 0 && this.rows[0]['Id'] == undefined) {
          for (let x of this.rows) x['Id'] = tre++;
          this.rows = [...this.rows];
        }
      });
    }
  }

  applySecondFilter(x = false) {
    if (this._input3 != undefined) this._input3.nativeElement.value = "";
    if (x) {
      var val = +this.filterStatoCustomOpz.value;
      this.numericofiltro = 0;
      this.numericofiltro2 = 0;
      this.stringafiltro = "";
      if (this.backup != undefined) this.rows = [...this.backup];
      return;
    } else {
      this.numericofiltro = 0;
      this.numericofiltro2 = 0;
      this.stringafiltro = "";
      if (this.backup != undefined) this.rows = [...this.backup];

      if (this.filterStatoCustom.value == "tutticampi") {
        return;
      }
      this.applied = true;
      var type = "";
      for (let c of this.columns)
        if (c.name.toLowerCase() == this.filterStatoCustom.value.toLowerCase()) {
          type = c.type;
          this.filterStatoType = type;
          break;
        }
      if (this.filterStatoCustom.value.toLowerCase() == "stato avanzamento") { this.populateFilters([9]); return; }
      if (type == "Currency" || type == "Int32" || type == "Decimal" || type == "Float") { this.populateFilters([3, 1, 4, 5]); }
      else if (type == "DateTime") { this.populateFilters([3, 1, 4, 5]); }
      else this.populateFilters([1, 2]);
    }
  }

  filtroGranati(c, stringa = false) {
    console.log('Filtro c %o stringa %o', c, stringa);
    this._loaderRogges.show();
    if (this.backup == undefined) this.backup = this.rows;
    this.rows = this.backup;
    if (c.target.value == "") {
      this.numericofiltro = 0;
      this.numericofiltro2 = 0;
      this.stringafiltro = "";
      if (this.backup != undefined) this.rows = [...this.backup];
      this._loaderRogges.hide();
      return;
    }
    var val = +c.target.value;
    var xxx = "";
    for (let c of this.columns) if (c.name.toLowerCase() == this.filterStatoCustom.value.toLowerCase()) { xxx = c.prop; break; }
    var value = +this.filterStatoCustomOpz.value;
    if (value == 1) {
      // ==
      if (stringa)
        var preR = this.backup.filter(x => x[xxx] != undefined && x[xxx].toLowerCase() == this.stringafiltro.toLowerCase());
      else
        var preR = this.backup.filter(x => x[xxx] == val);
      console.log("== " + val);
    } else if (value == 3) {
      // interv
      var val1 = this.numericofiltro; var val2 = this.numericofiltro2;
      console.log("intervallo tra " + this.numericofiltro + " e " + this.numericofiltro2)
      var preR = this.backup.filter(x => x[xxx] != undefined && x[xxx] >= val1 && x[xxx] <= val2);
    } else if (value == 4) {
      // >=
      var preR = this.backup.filter(x => x[xxx] >= val);
      console.log(">= " + val);
    } else if (stringa && value == 2) {
      // like
      var preR = this.backup.filter(x => x[xxx] != undefined && x[xxx].toLowerCase().indexOf(this.stringafiltro.toLowerCase()) > -1);
      console.log("like " + val);
    } else if (value != 9) {
      // <=
      console.log("<= " + val);
      var preR = this.backup.filter(x => x[xxx] != undefined && x[xxx] <= val);
    }
    this.rows = [...preR];
    this._loaderRogges.hide();
  }

  populateFilters(arr) {
    var a = [];
    for (let x of arr)
      if (x == 1) a.push({ val: x, prop: "Uguale" });
      else if (x == 2) a.push({ val: x, prop: "Contiene" });
      else if (x == 3) a.push({ val: x, prop: "Intervallo valori" });
      else if (x == 4) a.push({ val: x, prop: "Maggiore uguale" });
      else if (x == 9) { var ind = 9; for (var st of this.listaStati) a.push({ val: x++, prop: st }); }
      else a.push({ val: x, prop: "Minore uguale" });
    this.listaCambiFIltrabiliOpzioni = a;
  }

  clickMe(tile) {
    //console.log('CLICKME TILE %o', tile);
    this.click = tile.id;
    if (this.oldTile != undefined)
      this.hover(this.oldTile);
    this.oldTile = tile;

    this.br = undefined;

    if (tile.tile == "ORDINE CROSS")
      this._router.navigate(['/pm/store/action/order/fornitore/cross']);

    this.entryPoint = 'orders-add-' + tile.tile.toLowerCase();
    this.callTable();
  }


  onClick(clicked) {
    //console.log('CLICK %o',clicked );
    /*
    if (this.doubleClickable && clicked.type == 'dblclick') {
      this.selected = [];
      return;
    }
    if (clicked.type == 'click' && this.clickable == false && this.entryPoint == 'exit-docs-entry') {
      console.log('CLICK');
      this.id_print = clicked.row['Iddoc'];
      return;
    }
    if (clicked.type != 'click' || this.clickable == undefined || !this.clickable) return;
    if (this.entryPoint == 'orders-add' && +this._auth.getDomain() == 1) {
      localStorage.setItem('fName', clicked.row['Filiale']);
      localStorage.setItem('filialeId', clicked.row['Idfiliale']);
      if (this._auth.getUser()['ruolo'] == "Ufficio acquisti" && sessionStorage.getItem('dup') != undefined) {
        var x = sessionStorage.getItem('dupInfo');
        sessionStorage.removeItem('dupInfo');
        this._router.navigate(['/pm/store/action/order/' + x]);
      }
      else
        this._router.navigate(['/pm/store/action/orders-add-ad']);
      return;
    }
    localStorage.setItem('fName', clicked.row['Fornitore']);

    if (this.entryPoint == 'entry-docs-add') {
      this._router.navigate(['/pm/store/action/order/entry-docs/' + clicked.row['Iddoc']]);
    } else if (this.entryPoint == 'exit-docs-entry') {
      if (clicked.row['Idfornitore'] != undefined) {
        sessionStorage.setItem('uscite_r', JSON.stringify(clicked.row));
        this.subtitle = this.subtitle + " - " + clicked.row['Fornitore'];
        this.openUscitaAddProd(clicked.row['Idfornitore']);
      }
    } else if (this.insideBolleUscita) {
      sessionStorage.setItem('uscite_dest', clicked.row.Idfornitore);
      sessionStorage.setItem('uscite_name', clicked.row.Filiale);

      this._router.navigate(['/pm/store/action/exit-docs-add']);
    } else if (clicked.row.IdF != undefined) {
      localStorage.setItem('fId', clicked.row.IdF);
      localStorage.setItem('sottoscortaCF', clicked.row.IdCF);
      this._router.navigate(['/pm/store/action/order/fornitore/' + clicked.row['IdF']]);
    } else {
      localStorage.setItem('fId', this.click);
      localStorage.setItem('idFilialeNotNull', clicked.row['Idfiliale']);
      this._router.navigate(['/pm/store/action/order/fornitore/' + clicked.row['Idfornitore']]);
    }
    */
  }


  updateValue(event, row, cell, rowIndex, out = false) {
    console.log('UPDATE VALUE');
    var del = null;

    if (!(out || (event.charCode != undefined && event.charCode == 13))) return;
    for (let r of this.rows)
      if (rowIndex == r['Id']) {
        if (event.charCode != undefined && event.charCode == 13) this.editing[rowIndex + '-' + cell] = false;
        if (event.target.value == undefined || event.target.value == "") { event.target.value = "1"; this.focus(); }
        if (+event.target.value < 1 && this.entryPoint != 'inventory-add' && this.entryPoint != 'inventoryDetail' && this.entryPoint != 'entry-docs-detail') {
          del = r;
          break;
        }
        //console.log("Found: %o", r);
        r[cell] = +event.target.value;
        r['Totale'] = +event.target.value * r['CostoPerConfezione'];

        //ar add || this.entryPoint == 'exit-docs-entry'
        if (event.charCode != undefined && event.charCode == 13 && (this.entryPoint == 'inventoryDetail' || this.entryPoint == 'flDetail' || this.entryPoint == 'inventory-add' || this.entryPoint == 'orderDetail' || this.entryPoint == 'exit-docs-entry')) {
          this.search = undefined;
          this.focusLente(cell);
          console.log('pa 4a');
          this.performAction(this._images[0]);
          console.log('pa 4');
        }

        break;
      }

    if (del != null) {
      var ind = 0;
      for (let r of this.rows) if (r != row) ind++; else break;
      this.rows.splice(ind, 1);
    }
    if (out) this.editing[rowIndex + '-' + cell] = false;
    this.rows = [...this.rows];
  }


  updateValue2(event, cell, rowIndex, c = undefined) {
    console.log('UPDATE VALUE2');
    var indice = 0;
    var vec = rowIndex;
    for (let r of this.rows)
      if (rowIndex != r['Id'])
        indice++;
      else
        break;

    rowIndex = indice;

    if (c != undefined && cell != 'QtaAggiunta') this.editing[rowIndex + '-' + cell] = false;
    if (+event.target.value < 0) {
      event.target.value = 0;
    }
    this.updated2 = true;
    if (cell == 'QtaAggiunta') {
      if (event.charCode != 13 && c == undefined) return;
      this.rows[rowIndex][cell] = 0;
      this.rows[rowIndex]['QtaContata'] = +this.rows[rowIndex]['QtaContata'] + +event.target.value;
      this.editing[rowIndex + '-QtaAggiunta'] = false;
      this.editing[vec + '-QtaAggiunta'] = false;
      this.rows[rowIndex]['QtaAggiunta'] = 0;
    }
    else {
      let inde = 0;
      this.rows[rowIndex][cell] = event.target.value;
    }
    this.rows = [...this.rows];
  }

  deleteRow(rowIndex) {

    if (this.entryPoint == 'orderDetail' || this.entryPoint == 'entry-docs-detail' || this.entryPoint == 'inventory-add' || this.entryPoint == 'inventoryDetail' || this.entryPoint == 'exit-docs-entry') {

      this.deleteRowTableNormal(rowIndex);

    }
    else {

      this.rows = [];
      this.temp = [...this.rows];

      this.selected = [];

    }
  }

  deleteRowTableNormal(rowIndex) {
    this.rows.splice(rowIndex, 1);
    if (!this.rows)
      this.rows = [];
    this.rows = [...this.rows];
    this.temp = [...this.rows];
    this.selected = [];

  }

  onSelect(sel) {
    console.log('SELECTED FIRST original %o deducted %o', sel, this.selected);
    for (let b = 0; b < this.rows.length; b = b + 1) {

      this.rows[b]['checked'] = false;

    }
    this.selected = [];
    if (sel != null)
      this.selected = sel['selected'];
    //document.getElementsByTagName('datatable-body')[0].scrollTop = 0;

    for (let a = 0; a < this.selected.length; a = a + 1) {
      for (let b = 0; b < this.rows.length; b = b + 1) {
        if (this.selected[a]['Iddoc'] == this.rows[b]['Iddoc']) {
          this.rows[b]['checked'] = true;
          console.log('find row %o', this.rows[b]);
        }
      }
    }

    console.log('SELECTED AFTER deducted %o', this.selected);

  }

  removeOthers(name) {
    this.editing = [{}];
    this.editing[name] = true;
  }

  onActivate(event) {
    //console.log('Activate Event', event);
  }

  backupRows() {
    if (this.br == undefined)
      this.br = this.rows;
  }

  backupRowsReport(ind) {
    if (this.brR == undefined)
      this.brR = this._repoResp.Queries[ind].Table;
  }

  updateFilter(event, search) {
    //console.log('updateFilter %o',this.br);
    if (event.target.value == "" && this.br != null) { this.rows = [...this.br]; return; }
    const val = (event.target.value + "").toLowerCase();
    const temp = this.rows.filter(function (d) {
      return (d[search] + "").toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.rows = temp;
    //this.table.offset = 0;
  }

  updateFilterReport(event, search, ind) {
    let rows = this._repoResp.Queries[ind].Table;
    if (event.target.value == "") { this._repoResp.Queries[ind].Table = [...this.brR]; return; }
    const val = (event.target.value + "").toLowerCase();
    const tempR = rows.filter(function (d) {
      return (d[search] + "").toLowerCase().indexOf(val) !== -1 || !val;
    });

    this._repoResp.Queries[ind].Table = tempR;
    //this.table.offset = 0;
  }


  calculateWidthColumn(c, cs): number {
    //calculate sectors
    let tot = 0;
    for (let a = 0; a < cs.length; a = a + 1) {
      tot = tot + cs[a].flexGrow;
    }

    var dt = getComputedStyle(document.getElementById('my-ngxA')).width;
    var dim = parseInt((dt + "").substring(0, (dt + "").length - 2));
    let sp = dim / tot;
    //console.log('tot %o dt %o dim %o return %o', tot, dt, dim, sp * c.flexGrow)
    return sp * c.flexGrow;
  }

  focusOutFunction(e) {
    //console.log(e);
  }

  expand(x) {
    this.expanded = x;
    $(function () {
      if (x) {
        $(".textarea").animate({ height: "80px" }, 500);
        $("#infoAggiuntiveOrdine,#infoOrdine").animate({ height: "96px" }, 500);
      } else {
        $(".textarea").animate({ height: "17px" }, 500);
        $("#infoAggiuntiveOrdine,#infoOrdine").animate({ height: (document.getElementById('infoOrdine') == undefined ? '28px' : "38px") }, 500);
      }
    })
  }

  reSend(row) {
    let data = {};
    data['stato'] = 'resend';
    data['title'] = "Conferma";
    data['desc'] = "Confermare il reinvio della e-mail relativa all'ordine <b>#" + row['Iddoc'] + "</b>?";
    data['options'] = [{ caption: 'annulla', action: 'false' }, { caption: 'sì', action: 'true' }];
    let dialogRef = this._dialog.open(YesNoDialogComponentS, {
      width: '80%',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'true') {
        this._http.post(
          environment['ResendEmailOrdine'],
          {
            idnegozio: environment['idNegozio'],
            GUID: this._auth.getGUID(),
            DomainId: this._auth.getDomain(),
            ProfileId: this._auth.getProfile(),
            "mittente": "",
            "IdDoc": row['Iddoc']
          }
        )
          .subscribe((res) => {
            if (res['esito'] == true) {
              let data = {};
              data['stato'] = 'resend_msg';
              data['title'] = res['esito'] ? "Conferma invio" : "Errore nell'invio";
              data['desc'] = res['rispost'];
              data['options'] = [{ caption: 'chiudi', action: 'false' }, { caption: 'ok', action: 'true' }];
              let dialogRef2 = this._dialog.open(YesNoDialogComponentS, {
                width: '875px',
                data: data
              });
            }
          });
      }
    });
  }

  ngDoCheck() {
    /*
    this.selected = [];
    for (let a = 0; a < this.rows.length; a = a + 1) {
      if (this.rows[a]['checked'] == true) {
        console.log('push %o', this.rows[a].Iddoc);
        this.selected.push(this.rows[a]);
      }
    }
    */
    for (let a = 0; a < this._images.length; a = a + 1) {
      if ((this.dataRepo != null) && (this.dataRepo.user != null) && (this.dataRepo.user.desc_profile != null) && (this.dataRepo.user.desc_profile.toLowerCase().indexOf('amministratore') >= 0 || this.dataRepo.user.desc_profile.toLowerCase().indexOf('acquisti') >= 0)) {
        if (this._images[a].action == 'exp')
          this._images[a]['enabled'] = true;
        else {
          if (this._images[a].action == 'print' && this.selected != null && this.selected.length > 0)
            this._images[a]['enabled'] = true;
          else
            this._images[a]['enabled'] = false;
        }
      }
      else {
        if (this.selected != null && this.selected.length > 0) {
          if (this.selected.length == 1) {
            if (a == 3) {
              this._images[a]['enabled'] = false;
            }
            else {
              this._images[a]['enabled'] = true;
            }
          }
          else {
            if (a == 2) {
              this._images[a]['enabled'] = true;
            }
            else {
              this._images[a]['enabled'] = false;
            }
          }
        }
        else {
          if (this._images[a].action == 'exp')
            this._images[a]['enabled'] = true;
          else
            this._images[a]['enabled'] = false;
        }
      }
    }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
    this.sub.unsubscribe();
  }
}