import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';



abstract class HttpCache {
  /**
   * Returns a cached response, if any, or null if not present.
   */
  abstract get(req: HttpRequest<any>): HttpResponse<any>|null;

  /**
   * Adds or updates the response in the cache.
   */
  abstract put(req: HttpRequest<any>, resp: HttpResponse<any>): void;

  /**
   * Removes all the cached requests in case of a post|delete request
   */
  abstract invalidateAll(): void;

}


@Injectable()
export class MyHttpCache implements HttpCache {

  cache: { [s: string]: HttpResponse<any> } = {};

  constructor() {}

  public get(req: HttpRequest<any>): HttpResponse<any>|null {
    if (this.cache[req.urlWithParams])
      return this.cache[req.urlWithParams];
    return null;
  }

  public put(req: HttpRequest<any>, resp: HttpResponse<any>): void {
    this.cache[req.urlWithParams] = resp;
    setTimeout(() => {
      //destroy this element in 60 seconds
      delete this.cache[req.urlWithParams];
    }, 60000);
  }

  public invalidateAll(): void {
    this.cache = {};
  }
}

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  constructor(private cache: MyHttpCache) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  	// Before doing anything, it's important to only cache GET requests.
    // Skip this interceptor if the request method isn't GET.

    if (req.method !== 'GET') {
      this.cache.invalidateAll();
      return next.handle(req);
    }

    // First, check the cache to see if this request exists.
    const cachedResponse = this.cache.get(req);
    if (cachedResponse) {
      // A cached response exists. Serve it instead of forwarding
      // the request to the next handler.
      // console.log("From cache: %o", cachedResponse);
      return Observable.of(cachedResponse);
    }

    // No cached response exists. Go to the network, and cache
    // the response when it arrives.
    return next.handle(req).do(event => {
      // Remember, there may be other events besides just the response.
      if (event instanceof HttpResponse) {
      	// Update the cache.
      	this.cache.put(req, event);
      }
    });
  }
}
