import { Component, NgModule, ViewContainerRef } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { MatDialogModule, MatButtonModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { BasketItem, Basket, VariantItem } from '../../global/model/basket/basket.model'

@Component({
  selector: 'yesNoDialog',
  templateUrl: './yesno.dialog.html',
  styleUrls: ['./yesno.dialog.scss'],
})
export class YesNoDialog {
  
  title;
  message;
  showCancel;

  constructor(
    public dialogRef: MatDialogRef<any>
  ) { }

  
}