import { Component, ViewEncapsulation } from '@angular/core';
import { CartService } from '../../services';
import { Cart } from '../meta-cart';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'cart-evo-comp',
  templateUrl: './cart-evo.component.html',
  styleUrls: ['./cart-evo.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
  ]
})
export class CartEvoComponent implements Cart {
  workingCurrency;
  constructor(
    public _cartService: CartService
  ) {
    this.workingCurrency = environment['workingCurrency'];
  }

  getTotCart(): number {
     return this._cartService.getTotal();
  }

}
