import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClinichedatabaseService } from '../../services/clinichedatabase.service';
import { LoaderService } from '../../loader/loader.service';
import { AlertDialogService } from '../../services/alert-dialog.service';
import { Confirm } from "../../dialogs/confirm-dialog/confirm-dialog.component";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import * as moment from 'moment';
import { ExcelService } from '../../services/excel.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

import "moment-timezone";

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SecurityContext } from '@angular/core';

import { ClinicheDatabase } from '../../entities/datibase/clinichedatabase-model';
import { windowWhen } from 'rxjs/operators';

const tz = "Europe/Rome";
const dateFormat = "YYYY-MM-DDTHH:mm:ss";

const dateFormatDate = "YYYY-MM-DD";
const dateFormatHour = "HH:mm";

@Component({
  selector: 'app-db-dialog-clinica-import',
  templateUrl: './db-dialog-clinica-import.component.html',
  styleUrls: ['./db-dialog-clinica-import.component.css']
})
export class DBDialogClinicaImportComponent implements OnInit {
  clinica: {};
  role: string;
  isAdmin: boolean = false;
  isCA: boolean = false;
  isDC: boolean = false;
  usr: any;
  willDownload = false;

  constructor(public dialogRef: MatDialogRef<DBDialogClinicaImportComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private dbService: ClinichedatabaseService, private loaderService: LoaderService, private alertDialog: AlertDialogService,
    private fb: FormBuilder, private _sanitizer: DomSanitizer, private http: HttpClient) {

    this.role = data.role;
    this.usr = data.usr;
    this.clinica = {};
    this.clinica['code_c'] = '';
    this.clinica['legalentity'] = '';
    this.clinica['nome'] = '';
    this.clinica['indirizzo'] = '';
    this.clinica['sede_legale'] = '';
    this.clinica['partita_iva'] = '';
    this.clinica['ammnistratore'] = '';
    this.clinica['area_manager'] = '';
    this.clinica['distretto'] = '';
    this.clinica['riuniti'] = 0;
    this.clinica['direttore_sanitario'] = '';
    this.clinica['id_direttore_sanitario'] = '';
    this.clinica['cluster'] = 0;
    this.clinica['regione'] = '';
    this.clinica['mese_apertura'] = new Date();
    this.clinica['data_apertura'] = new Date();
    this.clinica['data_chiusura'] = new Date();
    this.clinica['brand'] = '';
    this.clinica['vintage'] = 0;
    this.clinica['provincia'] = '';
    this.clinica['mall_street'] = '';
    this.clinica['latitudine'] = '';
    this.clinica['longitudine'] = '';
    this.clinica['login_areamanager'] = '';
    this.clinica['data_acquisizione'] = new Date();
    this.clinica['email_clinic_manager'] = '';
    this.clinica['login_districtmanager'] = '';
    this.clinica['esclusione_followme'] = false;
    this.clinica['email_affittuario'] = '';
    this.clinica['porta_servizio_dentalcash'] = 0;
    this.clinica['data_prima_fattura'] = new Date();
    this.clinica['company_navision'] = '';
    this.clinica['data_inizio_dentalcash'] = new Date();
    this.clinica['indicazioni'] = '';
    this.clinica['email'] = '';
    this.clinica['tel'] = '';
    this.clinica['fax'] = '';
    this.clinica['descrizione'] = '';
    this.clinica['ip_privato'] = '';
    this.clinica['Master_Host'] = '';
    this.clinica['website'] = '';
    this.clinica['sms_sender'] = '';
    this.clinica['mail_sender'] = '';
    this.clinica['comune'] = '';
    this.clinica['cap'] = '';
    this.clinica['ex_listino'] = '';
    this.clinica['iban'] = '';
    this.clinica['codice_fiscale'] = '';
    this.clinica['cbm'] = '';
    this.clinica['login_cbm'] = '';
    if (this.role != null && (this.role.toLowerCase() == 'amministratore' || this.role.toLowerCase() == 'hr medici')) {
      this.isDC = false;
      this.isAdmin = true;
      this.isCA = false;
    }
    if (this.role != null && (this.role.toLowerCase() == 'direzione commerciale')) {
      this.isDC = true;
      this.isAdmin = false;
      this.isCA = false;
    }
    if (this.role != null && (this.role.toLowerCase() == 'ciclo_attivo' || this.role.toLowerCase() == 'ciclo attivo')) {
      this.isDC = false;
      this.isAdmin = false;
      this.isCA = true;
    }

  }

  parseDateExcel(excelTimestamp){
    const secondsInDay = 24 * 60 * 60;
    const excelEpoch = new Date(1899, 11, 31);
    const excelEpochAsUnixTimestamp = excelEpoch.getTime();
    const missingLeapYearDay = secondsInDay * 1000;
    const delta = excelEpochAsUnixTimestamp - missingLeapYearDay;
    const excelTimestampAsUnixTimestamp = excelTimestamp * secondsInDay * 1000;
    const parsed = excelTimestampAsUnixTimestamp + delta;
    return isNaN(parsed) ? null : parsed;
  }

  setDownload(data) {
    this.willDownload = true;
    setTimeout(() => {
      const el = document.querySelector("#download");
      el.setAttribute("href", `data:text/json;charset=utf-8,${encodeURIComponent(data)}`);
    }, 1000)
  }

  onFileChange(ev) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    if (window.confirm('Vuoi sovrascrivere i dati presenti nel file Excel con quelli nel DB?')) {
      this.loaderService.show();
      reader.onload = (event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary' });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        const dataString = JSON.stringify(jsonData);
        document.getElementById('output').innerHTML = dataString.slice(0, 1000).concat("...");

        //this.setDownload(dataString);
        //var obj = JSON.parse(jsonData);
        let arrayCall = [];

        let jsonDataC = jsonData['dati cliniche'];
        console.log('jsondata %o', jsonDataC);
        for (var it in jsonDataC) {
          let clinica = {};
          //if (jsonDataC[it]['CODICE CLINICA'] == "004") {
          clinica['code_c'] = jsonDataC[it]['CODICE CLINICA'] || '';
          clinica['legalentity'] = jsonDataC[it]['LEGAL ENTITY'] || '';
          clinica['nome'] = jsonDataC[it]['NOME'] || '';
          clinica['indirizzo'] = jsonDataC[it]['INDIRIZZO'] || '';
          clinica['sede_legale'] = jsonDataC[it]['SEDE LEGALE'] || '';
          clinica['partita_iva'] = jsonDataC[it]['PARTITA IVA'] || '';
          clinica['ammnistratore'] = jsonDataC[it]['AMMINISTRATORE'] || '';
          clinica['area_manager'] = jsonDataC[it]['AREA MANAGER'] || '';
          clinica['distretto'] = jsonDataC[it]['DISTRETTO'] || '';
          clinica['riuniti'] = jsonDataC[it]['RIUNITI'] || 0;
         // clinica['direttore_sanitario'] = jsonDataC[it]['DIRETTORE SANITARIO'] || '';
         // clinica['id_direttore_sanitario'] = jsonDataC[it]['ID DIRETTORE SANITARIO'] || '';
          clinica['cluster'] = jsonDataC[it]['CLUSTER'] || 0;
          clinica['regione'] = jsonDataC[it]['REGIONE'] || '';
          clinica['mese_apertura'] = jsonDataC[it]['MESE APERTURA'] || new Date();
          clinica['data_apertura'] = jsonDataC[it]['DATA APERTURA'] || new Date();
          clinica['data_chiusura'] = jsonDataC[it]['DATA CHIUSURA'] || new Date();
          clinica['brand'] = jsonDataC[it]['BRAND'] || '';
          clinica['vintage'] = jsonDataC[it]['VINTAGE'] || 0;
          clinica['provincia'] = jsonDataC[it]['PROVINCIA'] || '';
          clinica['mall_street'] = jsonDataC[it]['MALL STREET'] || '';
          clinica['latitudine'] = jsonDataC[it]['LATITUDINE'] || '';
          clinica['longitudine'] = jsonDataC[it]['LONGITUDINE'] || '';
          clinica['login_areamanager'] = jsonDataC[it]['LOGIN AREA MANAGER'] || '';
          clinica['data_acquisizione'] = jsonDataC[it]['DATA ACQUISIZIONE'] || new Date();
          clinica['email_clinic_manager'] = jsonDataC[it]['EMAIL CLINIC MANAGER'] || '';
          clinica['login_districtmanager'] = jsonDataC[it]['LOGIN DISTRICT MANAGER'] || '';
          clinica['esclusione_followme'] = jsonDataC[it]['ESCLUSIONE FOLLOWME'] || false;
          clinica['email_affittuario'] = jsonDataC[it]['EMAIL AFFITTUARIO'] || '';
          clinica['porta_servizio_dentalcash'] = jsonDataC[it]['PORTA SERVIZIO DENTALCASH'] || 0;
          clinica['data_prima_fattura'] = jsonDataC[it]['DATA PRIMA FATTURA'] || new Date();
          clinica['company_navision'] = jsonDataC[it]['COMPANY NAVISION'] || '';
          clinica['data_inizio_dentalcash'] = jsonDataC[it]['DATA INIZIO DENTALCASH'] || new Date();
          clinica['indicazioni'] = jsonDataC[it]['INDICAZIONI'] || '';
          clinica['email'] = jsonDataC[it]['EMAIL'] || '';
          clinica['tel'] = jsonDataC[it]['TELEFONO'] || '';
          clinica['fax'] = jsonDataC[it]['FAX'] || '';
          clinica['descrizione'] = jsonDataC[it]['DESCRIZIONE'] || '';
          clinica['ip_privato'] = jsonDataC[it]['IP PRIVATO'] || '';
          clinica['Master_Host'] = jsonDataC[it]['MASTER HOST'] || '';
          clinica['website'] = jsonDataC[it]['WEBSITE'] || '';
          clinica['sms_sender'] = jsonDataC[it]['SMS SENDER'] || '';
          clinica['mail_sender'] = jsonDataC[it]['EMAIL SENDER'] || '';
          clinica['comune'] = jsonDataC[it]['COMUNE'] || '';
          clinica['cap'] = jsonDataC[it]['CAP'] || '';
          clinica['ex_listino'] = jsonDataC[it]['EX LISTINO'] || '';
          clinica['iban'] = jsonDataC[it]['IBAN'] || '';
          clinica['codice_fiscale'] = jsonDataC[it]['CODICE FISCALE'] || '';
          clinica['cbm'] = jsonDataC[it]['CBM'] || '';
          clinica['login_cbm'] = jsonDataC[it]['LOGIN CBM'] || '';
          if (!(clinica['data_apertura'] == null) && (typeof clinica['data_apertura'] === 'string' || clinica['data_apertura'] instanceof String)) {
            if (clinica['data_apertura'].trim().toLowerCase() == 'invalid date') {
              clinica['data_apertura'] = null;
            }
            else {
              clinica['data_apertura'] = moment(clinica['data_apertura'], 'DD-MM-YYYY').format(dateFormat);
            }
          }
          else {
            if (!(clinica['data_apertura'] == null) && (typeof clinica['data_apertura'] === 'number' || clinica['data_apertura'] instanceof Number)) {
              clinica['data_apertura'] = moment(new Date(this.parseDateExcel(clinica['data_apertura']))).format(dateFormat);
            }
          }
          if (!(clinica['mese_apertura'] == null) && (typeof clinica['mese_apertura'] === 'string' || clinica['mese_apertura'] instanceof String)) {
            if (clinica['mese_apertura'].trim().toLowerCase() == 'invalid date') {
              clinica['mese_apertura'] = null;
            }
            else {
              clinica['mese_apertura'] = moment(clinica['mese_apertura'], 'DD-MM-YYYY').format(dateFormat);
            }
          }
          else {
            if (!(clinica['mese_apertura'] == null) && (typeof clinica['mese_apertura'] === 'number' || clinica['mese_apertura'] instanceof Number)) {
              clinica['mese_apertura'] = moment(new Date(this.parseDateExcel(clinica['mese_apertura']))).format(dateFormat);
            }
          }

          if (!(clinica['data_chiusura'] == null) && (typeof clinica['data_chiusura'] === 'string' || clinica['data_chiusura'] instanceof String)) {

            if (clinica['data_chiusura'].trim().toLowerCase() == 'invalid date') {
              clinica['data_chiusura'] = null;
            }
            else {
              clinica['data_chiusura'] = moment(clinica['data_chiusura'], 'DD-MM-YYYY').format(dateFormat);
            }
          }
          else {
            if (!(clinica['data_chiusura'] == null) && (typeof clinica['data_chiusura'] === 'number' || clinica['data_chiusura'] instanceof Number)) {
              clinica['data_chiusura'] = moment(new Date(this.parseDateExcel(clinica['data_chiusura']))).format(dateFormat);
            }
          }

          if (!(clinica['data_acquisizione'] == null) && (typeof clinica['data_acquisizione'] === 'string' || clinica['data_acquisizione'] instanceof String)) {
            if (clinica['data_acquisizione'].trim().toLowerCase() == 'invalid date') {
              clinica['data_acquisizione'] = null;
            }
            else {
              clinica['data_acquisizione'] = moment(clinica['data_acquisizione'], 'DD-MM-YYYY').format(dateFormat);
            }
          }
          else {
            if (!(clinica['data_acquisizione'] == null) && (typeof clinica['data_acquisizione'] === 'number' || clinica['data_acquisizione'] instanceof Number)) {
              clinica['data_acquisizione'] = moment(new Date(this.parseDateExcel(clinica['data_acquisizione']))).format(dateFormat);
            }
          }

          if (!(clinica['data_prima_fattura'] == null) && (typeof clinica['data_prima_fattura'] === 'string' || clinica['data_prima_fattura'] instanceof String)) {
            if (clinica['data_prima_fattura'].trim().toLowerCase() == 'invalid date') {
              clinica['data_prima_fattura'] = null;
            }
            else {
              clinica['data_prima_fattura'] = moment(clinica['data_prima_fattura'], 'DD-MM-YYYY').format(dateFormat);
            }
          }
          else {
            if (!(clinica['data_prima_fattura'] == null) && (typeof clinica['data_prima_fattura'] === 'number' || clinica['data_prima_fattura'] instanceof Number)) {
              clinica['data_prima_fattura'] = moment(new Date(this.parseDateExcel(clinica['data_prima_fattura']))).format(dateFormat);
            }
          }

          if (!(clinica['data_inizio_dentalcash'] == null) && (typeof clinica['data_inizio_dentalcash'] === 'string' || clinica['data_inizio_dentalcash'] instanceof String)) {

            if (clinica['data_inizio_dentalcash'].trim().toLowerCase() == 'invalid date') {

              clinica['data_inizio_dentalcash'] = null;
            }
            else {

              clinica['data_inizio_dentalcash'] = moment(clinica['data_inizio_dentalcash'], 'DD-MM-YYYY').format(dateFormat);
            }
          }
          else {
            if (!(clinica['data_inizio_dentalcash'] == null) && (typeof clinica['data_inizio_dentalcash'] === 'number' || clinica['data_inizio_dentalcash'] instanceof Number)) {
              clinica['data_inizio_dentalcash'] = moment(new Date(this.parseDateExcel(clinica['data_inizio_dentalcash']))).format(dateFormat);
            }
          }

          //console.log('ITEM %o CLINICA %o date %o', jsonDataC[it], clinica, clinica['data_inizio_dentalcash'].trim().toLowerCase());
          console.log('jsondata %o', clinica);
          if (!(clinica['code_c'] == null) && clinica['code_c'].toString().trim().length > 0) {

           let ret = this.http.put(`${environment.gceAPI}/cliniche_db_unico/` + clinica['code_c'], clinica, { responseType: 'json' });
           //let ret = this.http.post(`${environment.gceAPI}/cliniche_db_unico/`, clinica, { responseType: 'json' });
            arrayCall.push(ret);
          }
          // }
        }
        Observable.forkJoin(arrayCall).subscribe(results => {
          this.loaderService.hide();
          window.alert('Import terminato');
          this.dialogRef.close();
          console.log('RESULTS %o', results);
        }, err => {
          this.loaderService.hide();
          window.alert('Errore nella procedura di importazione.');
          this.dialogRef.close();
        });

      }
      reader.readAsBinaryString(file);
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  ngOnInit() {

  }


}
