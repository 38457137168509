import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import { StartPageComponent } from './start-page/start-page.component';
import { UserWidgetComponent } from './user-widget/user-widget.component';
import { PageManagementComponent } from './page-management/page-management.component';
import { ActionComponent } from './order-management/action/action.component';
import { ErrorComponent } from './order-management/error/error.component';
import { ConcessionComponent } from './concession/concession.component';
import { ConcessionSystemComponent } from './concession-system/concession-system.component';
import { ForecastComponent } from './forecast/forecast.component';
import { MngforecastComponent } from './mngforecast/mngforecast.component';
import { CarotaggioComponent } from './carotaggio/carotaggio.component';
import { OrderManagementComponent } from './order-management/order-management.component';
import { MessagesMngmtComponent } from './messages-mngmt/messages-mngmt.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { OrderReceivedComponent } from './suppliers/order-received/order-received.component';
import { OrderInvoiceableComponent } from './suppliers/order-invoiceable/order-invoiceable.component';
import { InvoicesComponent } from './suppliers/invoices/invoices.component';
import { ImAffComponent } from './im-aff/im-aff.component';
import { SsoMonComponent } from './sso-mon/sso-mon.component';

const routes: Routes = [
  {
    path: '',
    component: StartPageComponent,
    pathMatch: 'full'
  },
  // {
  //   path: 'calendar',
  //   canActivate: [AuthGuardService],
  //   component: CalendarComponent
  // },
  // {
  //   path: 'counter',
  //   canActivate: [AuthGuardService],
  //   component: CounterComponent
  // },
  {
    path: 'pm/:controller',
    canActivate: [AuthGuardService],
    component: PageManagementComponent
  },
  /* inizio routing ordermanagement */
  {
    path: 'pm/store',
    canActivate: [AuthGuardService],
    component: OrderManagementComponent
  },
  {
    path: 'pm/store/action/:entryPoint',
    component: ActionComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'pm/store/action/order/:id',
    component: ActionComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'pm/store/action/entry-docs/:ided',
    component: ActionComponent,
    canActivate: [ AuthGuardService ]
  },
 // {
 //   path: 'pm/store/action/exit-docs-entry',
 //   component: ActionComponent,
 //   canActivate: [ AuthGuardService ],
 //   runGuardsAndResolvers: 'always',
 // },
  {
    path: 'pm/store/action/inventory/:idInv',
    component: ActionComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'pm/store/action/reports',
    component: ActionComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'pm/store/action/reports/:repoCode',
    component: ActionComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'pm/store/action/order/:tile/:idD',
    component: ActionComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'pm/store/error/:errorCode',
    component: ErrorComponent
  },
  /* fine routing ordermanagement */
  /* inizio routing forecast */
  {
    path: 'forecast',
    component: ForecastComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'sso-mon',
    canActivate: [ AuthGuardService ],
    component: SsoMonComponent
  },
  {
    path: 'mngfrcst',
    component: MngforecastComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'suppliers',
    component: SuppliersComponent,
    canActivate: [ AuthGuardService ],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'pm/suppliers/action/invoices',
    component: InvoicesComponent,
    canActivate: [ AuthGuardService ],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'pm/suppliers/action/invoiceable',
    component: OrderInvoiceableComponent,
    canActivate: [ AuthGuardService ],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'pm/suppliers/action/orders',
    component: OrderReceivedComponent,
    canActivate: [ AuthGuardService ],
    runGuardsAndResolvers: 'always'
  },
  /* fine routing forecast */
  /* inizio routing carotaggio */
  {
    path: 'crtg',
    component: CarotaggioComponent,
    canActivate: [ AuthGuardService ]
  },
  /* fine routing carotaggio */
  /* inizio routing messages management */
  {
    path: 'messages-mngmt',
    component: MessagesMngmtComponent,
    canActivate: [ AuthGuardService ]
  },
  /* fine routing messages management */
  /* inizio routing concession */
  // {
  //   path: 'pm/concession',
  //   component: ConcessionSystemComponent,
  //   canActivate: [ AuthGuardService ]
  // },
  {
    path: 'pm/concession',
    canActivate: [ AuthGuardService ],
    component: ConcessionSystemComponent
  },
  {
    path: 'mail_affittuari',
    component: ImAffComponent,
    canActivate: [ AuthGuardService ]
  },
  /* fine routing concession */
  {
    path: '**',
    redirectTo: ''
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
