import { primaryKey, editableOnNew, headerCode, inputType, required, length, order, pattern, editable, Describer, visible, GceEntity, textAlign } from './gce-management';

/**
 * GceMedicType -> table: fmc_tipimedico
 */
export class GceMedicType extends GceEntity {
    @primaryKey()
    @headerCode('GCE.GCEMEDICTYPE.TIPO_MEDICO')
    @inputType('number')
    @editable(false)
    @editableOnNew(false)
    @order(1)
    tipo_medico: number;

    @headerCode('GCE.GCEMEDICTYPE.DESCRIZIONE')
    @inputType('text')
    @required()
    @editable(true)
    @length(255)
    @order(2)
    descrizione: string;

    @headerCode('GCE.GCEMEDICTYPE.GRUPPO_ARTICOLO_NAV_PREST')
    @inputType('text')
    @required()
    @editable(true)
    @length(3)
    @order(3)
    gruppo_articolo_nav_prest: string;

    constructor();
    constructor(tipo_medico: number, descrizione: string, gruppo_articolo_nav_prest: string);
    constructor(tipo_medico?: number, descrizione?: string, gruppo_articolo_nav_prest?: string){
        super();
        this.tipo_medico = tipo_medico || 0;
        this.descrizione = descrizione || '';
        this.gruppo_articolo_nav_prest = gruppo_articolo_nav_prest || '';
    }

    static getStructure(): Array<any> {
        let structure: any[] = Describer.getStructure(new GceMedicType());
        structure.push(...this.getActions());
        return structure;
    }
    protected static getActions(): any[] {
        let actions: any[] = [];
        return actions;
    }
}