import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { HttpClient } from '@angular/common/http';

import { AuthGuardService } from '../../../services/auth-guard.service';
import { TableService } from '../services/table.service';

@Component({
  selector: 'msgs-dialog',
  templateUrl: './msgs.dialog.html'
})
export class MessagesDialogComponent implements OnInit {

  msgs;
  title;
  options;

  constructor(
    public _authService: AuthGuardService,
    public dialogRef: MatDialogRef<MessagesDialogComponent>,
    private _http: HttpClient,
    private _tableService: TableService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.options = data.options;
    this.msgs = data.msgs;
    this.title = "Hai <b>" + this.msgs.length + "</b> messagg" + (this.msgs.length > 1 ? "i" : "io");
  }

    ngOnInit() {
    }

    close (a) {
      this.dialogRef.close(a);
    }

}