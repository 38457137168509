import { Component, OnInit } from '@angular/core';
import { environment } from "../../environments/environment";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  year:number;
  companyName:string = environment['companyName'];
  companyWebPage:string = environment['companyWebPage'];
  version:string = environment['version'];

  constructor() {
    this.year = (new Date()).getFullYear();
    console.log("version: %o", this.version);
  }

  ngOnInit() {
  }

}
