import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {
  regex = /(^|\s)[a-z\u00E0-\u00FC]/g;

  private upperCase(str){
    return str.toUpperCase();
  }

  transform(value: any, onlyFirstWord: boolean=false): any {
    let newValue:string;
    if(onlyFirstWord){
      value = value.toLowerCase();
      newValue = `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
    }else{
      newValue = value.replace(this.regex, this.upperCase);
    }
    // console.log("capitalize %o %o" , value, newValue);
    return newValue;
  }
}
