import { Component, OnInit, Input, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
  confirm:Confirm = {
    title:"confirm",
    message:"",
    buttonOkText:"Ok",
    buttonCancelText:"Cancel",
    withTranslate:false,
    messageParams:undefined
  }

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private translate: TranslateService) {
    if(data.messageParams){
      this.confirm.messageParams = data.messageParams;
    }
    console.log('messageParams: %o', this.confirm.messageParams);
    if(data.withTranslate){
      this.translate.get(data.title).subscribe(data=>{
        this.confirm.title= data;
      });
      this.translate.get(data.message).subscribe(data=>{
        this.confirm.message= data;
        if(this.confirm.messageParams){
          this.confirm.messageParams.forEach(mp=>{
            console.log(mp, this.confirm.message);
            this.confirm.message = this.confirm.message.replace(`[${mp.key}]`, mp.value);
          });
        }
      });
      this.translate.get(data.buttonOkText).subscribe(data=>{
        this.confirm.buttonOkText= data;
      });
      this.translate.get(data.buttonCancelText).subscribe(data=>{
        this.confirm.buttonCancelText= data;
      });
      this.confirm.withTranslate = data.withTranslate;
    }else{
      this.confirm.title = data.title;
      this.confirm.message = data.message;
      if(this.confirm.messageParams){
        this.confirm.messageParams.forEach(mp=>{
          this.confirm.message = this.confirm.message.replace(`[${mp.key}]`, mp.value);
        });
      }
      this.confirm.buttonOkText = data.buttonOkText;
      this.confirm.buttonCancelText = data.buttonCancelText;
    }
    this.confirm.buttonOkStyle = data.buttonOkStyle;
    this.confirm.buttonCancelStyle = data.buttonCancelStyle;
  }

  ngOnInit() {
  }

  close(value:boolean) {
    this.dialogRef.close(value);
  }
}

export interface Confirm {
  title:string;
  message:string;
  buttonOkText:string;
  buttonCancelText:string;
  buttonOkStyle?:{};
  buttonCancelStyle?:{};
  withTranslate?:boolean;
  messageParams?:{key:string, value:any}[]
}
