export { GceClinic } from './gce-clinic';
export { GceEnvironment } from './gce-environment';
export { GceInvoicePersonClinic } from './gce-invoice-person-clinic';
export { GceInvoicePersonCompensation } from './gce-invoice-person-compensation';
export { GceInvoicePerson } from './gce-invoice-person';
export { GceInvoice } from './gce-invoice';
export { GceAnagraphic } from './gce-anagraphic';
export { GcePresence } from './gce-presence';
export { GceLookUpOptions } from './gce-management'; 
export { GceMedicType } from './gce-medic-type';
export { GceFiscalReg } from './gce-fiscal-reg';
export { GceProfiles } from './gce-profiles';
export { GceProvCodes } from './gce-prov-codes';
export { GcePerformanceCalculationDetracts } from './gce-performance-calculation-detracts';
export { GcePerformanceCalculationDetractsEditing } from './gce-performance-calculation-detracts-editing';
