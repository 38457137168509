import { Injectable } from '@angular/core';
import { ApiProviderWinticplusService } from './api-provider-winticplus.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs/Observable';
import { DataRepositoryService } from './data-repository.service';

@Injectable()
export class UserService {
  loading: boolean = false;
  isAuth: boolean = false;
  user: any;
  constructor(private api: ApiProviderWinticplusService, private oAuthService: OAuthService, private dataRepo: DataRepositoryService) {
  }

  logoff() {
    this.oAuthService.logOut();
  }

  login() {
    // console.log("user service");
    this.oAuthService.initImplicitFlow();
  }

  getAccessToken() {
    return this.oAuthService.getAccessToken();
  }

  getCurrentUser(): Observable<any> {
    // console.log("getCurrentUser()");
    this.loading = true;
    return Observable.create(observer => {
      this.oAuthService.loadDiscoveryDocument().then(() => {
        if (this.oAuthService.hasValidIdToken()) {
          const claims = this.oAuthService.getIdentityClaims();
          //console.log("claims %o token %o", claims ,this.oAuthService.getAccessToken());
          this.dataRepo.user = claims;
          if (!this.dataRepo.user['StoreId']) {
            this.dataRepo.user['StoreId'] = 1;
          }
          this.loading = false;
          observer.next(this.dataRepo.user);
        } else {
          this.loading = false;
        }
      }).catch((e) => {
        console.log("loadDiscoveryDocument %o", e);
      });
    });
  }

  getCurrentUserNoObs(): any {
    // console.log("getCurrentUserNoOBS()");
    if (this.oAuthService.hasValidIdToken()) {
      const claims = this.oAuthService.getIdentityClaims();
      //console.log("claims %o token %o", claims ,this.oAuthService.getAccessToken());
      this.dataRepo.user = claims;
      if (!this.dataRepo.user['StoreId']) {
        this.dataRepo.user['StoreId'] = 1;
      }
      return claims;
    }
    
    return null;
  }

  changePassword(): Observable<any> {
    return Observable.create(observer => {
      this.getCurrentUser().subscribe(user => {
        let query = {
          uid: user['sub']
        }
        // console.log("resetPassword user %o", user );
        this.api.makeAPICall("resetPassword", query).subscribe(response => {
          // console.log("reset password %o", response);
          observer.next(response);
        });
      });
    });
  }
}
