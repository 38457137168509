import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[withoutSpecialChars]'
})
export class TextWithoutSpecialCharsDirective {
  regexStr = '^[a-zA-Z0-9 ]*$';

  constructor(private el: ElementRef, private control : NgControl) { }

  @HostListener('keypress', ['$event']) onKeyPress(event){
    return new RegExp(this.regexStr).test(event.key);
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
   this.validateFields(event);
  }

 validateFields(event) {
   setTimeout(() => {
     // console.log("paste %o", this);
     //this.el.nativeElement.value = ;
     this.control.control.setValue(this.el.nativeElement.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/\s+$/,''))
     event.preventDefault();
   }, 0)
 }
}
