
import { Component, OnInit } from '@angular/core';
import { AuthGuardService } from './../services/auth-guard.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../services';

import { environment } from '../../environments/environment';
import * as $ from 'jquery';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.css']
})
export class SuppliersComponent implements OnInit {
  _tiles;
  _versTile;
  flickityEnabled;
  myUser;
  red = false;

  constructor(
    public _authService: AuthGuardService,
    private _router: Router,
    private _http: HttpClient,
    private _dialog: MatDialog,
    private _auth: AuthGuardService,
    public userService: UserService
  ) {
    this._tiles = environment['tilesSuppliers'];
    this._versTile = environment['versTile'];
    this.flickityEnabled = false;

    if (sessionStorage.getItem('elementiPerPagina') === undefined)
      sessionStorage.setItem('elementiPerPagina', "10");

    if (this._authService.isAuthenticated() && (sessionStorage.getItem('user') == undefined || sessionStorage.getItem('user') != this._authService.getUserMail())) {
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('userInfo');

      sessionStorage.setItem('user', this._authService.getUserMail());
      this.retrieveDashboardTiles();
    }

    if (sessionStorage.getItem('userInfo') == undefined || JSON.parse(sessionStorage.getItem('userInfo')).fornitoriTiles == undefined || JSON.parse(sessionStorage.getItem('userInfo')).fornitoriTiles.length == 0)
      this.retrieveDashboardTiles();

    if (sessionStorage.getItem('infoNegozio') == undefined || sessionStorage.getItem('infoNegozio') == "")
      this.retrieveInfo();

    this.dosomething();
    localStorage.removeItem('repoInfo');

    //this.getAllMessages();
  }

  retrieveDashboardTiles() {
    let cat;

    let req = {
      idnegozio: environment['idNegozio'],
      GUID: this._authService.getGUID(),
      DomainId: this._authService.getDomain(),
      ProfileId: this._authService.getProfile(),
      DomainContext: "DENTALPRO"
    };

    this._http.post(
      environment['GetCategorieFornitori'],
      req
    )
      .subscribe((res) => {
        cat = res['categorieFornitori'];
        sessionStorage.setItem('userInfo', JSON.stringify(
          {
            user: sessionStorage.getItem('user'),
            info: this._authService.getUser(),
            dashboardTiles: [],
            fornitoriTiles: cat
          }
        ));
      });
  }

  private retrieveInfo() {
    if (this._authService.isAuthenticated()) {
      let obj = {
        GUID: this._authService.getGUID(),
        idnegozio: environment['idNegozio']
      }

      this._http.post(
        environment['GetInfoWS'],
        obj
      )
        .subscribe((res) => {
          sessionStorage.setItem('infoNegozio', JSON.stringify(res));
        });
    }
  }

  ngOnInit() {
    $(function () {
      var first = "#f5f5e9";
      var second = "#ffffff";
    });

    this.userService.getCurrentUser().subscribe(f => {
      //console.log('INIT STORE %o', f);
      this.myUser = f;
    });
  }

  ngDoCheck()
  {
    //console.log('TILE able');
    if (this.myUser!=null)
    {
      if ((this.myUser['inventario'] != null) && (this.myUser['inventario'] == 'true')) {
        for (let a = 0; a < this._tiles.length; a = a + 1) {
          if (this._tiles[a].value == 'inventario')
          {
            this._tiles[a].enabled = true;
          }
        }
      }
    }
  }

  dosomething() {
    this.red = false;
    this._http.post(
      environment['GetElencoAlerts'],
      {
        idnegozio:environment['idNegozio'],
        GUID: this._auth.getGUID(),
        DomainId: this._auth.getDomain(),
        ProfileId: this._auth.getProfile(),
        ALERT_TYPE: "DENTAL"
      }
    )
      .map((res) => {
        if (res != undefined && res['esito'] != undefined && res['esito'] == true) {
          if (res['alerts'].length > 0) {
            this.red = true;
          }
        }
      })
      .subscribe(val => []);
  }

  getTileStyle(offs = 0) {
    if (window.innerWidth < 1500 || window.innerHeight < 550)
      return {
        width: (250 - offs) + 'px',
        height: (250 - offs) + 'px',
      };

    return {
      width: (parseInt('' + ((window.innerWidth * 14) / 100)) - offs) + 'px',
      height: (parseInt('' + ((window.innerWidth * 14) / 100)) - offs) + 'px',
    };
  }

  getPStyle() {
    if (window.innerWidth < 1500 || window.innerHeight < 550)
      return {
        'margin-top': '-9px',
        'font-size': '26px'
      };

  }

  getTileStyleBis() {
    if (window.innerWidth < 1500 || window.innerHeight < 550)
      return {
        'top': 'calc(50% - 29px)'
      };
    else
      return {
        'top': 'calc(50% - 65px)'
      };
  }

  doAction(action = "", x) {
    console.log('DO ACTION action %o x %o', action, x);
    if (action != "" && x)
      this._router.navigate(["/" + action]);
  }

}
