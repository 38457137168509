import { Component, NgModule, ViewContainerRef } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { MatDialogModule, MatButtonModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { BasketItem, Basket, VariantItem } from '../../global/model/basket/basket.model';
import { BasketService } from '../services/basket.service';

@Component({
  selector: 'variant2Dialog',
  templateUrl: './variants2.dialog.html',
  styleUrls: ['./variants2.dialog.scss'],
})
export class Variant2Dialog {
  variants;
  test;
  blocked: boolean = false;
  products;
  _translate;

  constructor(
    public dialogRef: MatDialogRef<any>,
    public bs: BasketService
  ) {  }

  closePopup(): void {
    this.dialogRef.close();
  }

  buildBG(bg) {
    return { 
        'background': bg.background,
        'border-radius': '20px',
        'background-size': (bg.background.indexOf('bg_box_cassa') === -1) ? '90px 90px' : '150px 150px',
    };
  }

  checkIfBlocked (item, category): boolean {
    if (item.selectedQuantity > 0) return false;
    if (this.variants.minVariant > this.calculateTotal(category.items) && this.variants.maxVariant > this.calculateTotal(undefined, false)) return false;
    if (this.variants.minVariant == 0 && this.variants.maxVariant != 0 && this.calculateTotal(category.items, false) >= this.variants.maxVariant) return true;
    if (this.variants.minVariant != 0)
      for (let c of this.variants.categories)
        if (this.calculateTotal(c.items) >= this.variants.minVariant)
          return true;

    return false;
  }

  variantClass(item, variant, category, i) {
    let notSelected: boolean = true, thisIs: boolean = false;
    for (let x of this.test[i].itemSelected)
      if (variant != undefined && variant == x) {
        notSelected = false;
        break;
      }

    return {
      'item': true,
      'specialvariants': this.variants.minVariant == 0
      //'blocked': this.test[i] != undefined && ((this.variants.minVariant == 0 && this.test[i].tot >= this.variants.maxVariant) || (this.variants.minVariant > 0 && this.test[i].tot >= this.variants.minVariant)) && notSelected
    };

  }

  blank () { return; }

  addVariantProduct2 (item, category, index, qty: number = 1) {
    if (!this.checkIfCanAddVariants(item, category, index, qty)) return;
    if (item.selectedQuantity + qty < -1 || item.selectedQuantity + qty > this.variants.maxVariant) return;
    item.selectedQuantity += qty;
  }

  checkIfCanAddVariants(item, category, index: number, qty: number) {
    if (qty == -1 && item.selectedQuantity > 0) return true;
    if (this.variants.minVariant == this.variants.maxVariant && this.variants.minVariant == 0) return true;
    if (this.variants.minVariant != 0 && (this.calculateTotal(undefined, false) >= this.variants.maxVariant || this.calculateTotal(category.items) >= this.variants.minVariant)) return false;
    if (qty == -1 && item.selectedQuantity == 0) return false;
    if (qty > 0 && this.calculateTotal (undefined, false) >= this.variants.maxVariant && this.calculateTotal (category.items) >= this.variants.minVariant) return false;
    return true;
  }

  calculateTotal (items, byCategory: boolean = true): number {
    let tot: number = 0;
    if (!byCategory) {
      for (let v of this.variants.categories)
        for (let i of v.items)
          if (i.selectedQuantity > 0)
            tot += i.selectedQuantity;
    }
    else {
      for (let i of items)
        if (i.selectedQuantity > 0)
          tot += i.selectedQuantity;
    }

    return tot;
  }

  isItemSelected(item): boolean {
    return (item.selectedQuantity > 0);
  }

  addVariantProduct(variant, category, index, qty: number = 1) {
    if ((this.variants.minVariant > 0 && this.test[index].tot >= this.variants.minVariant) || (this.variants.minVariant == 0 && this.test[index].tot >= this.variants.maxVariant)) {
      let i= -1;
      for (let ind = 0; ind < this.test[index].itemSelected.length; ind ++)
        if (this.test[index].itemSelected[ind] == variant) {
          i = ind;
          break;
        }
      
      if (variant != this.test[index].itemSelected[i]) {
        console.log("blocked!!!!");
        return;
      }
      this.test[index].tot = 0;
      this.test[index].itemSelected = this.test[index].itemSelected.filter(function(item) { 
        return item !== variant
      });
      --variant.selectedQuantity;
      console.log("deleted. tot = " + this.test[index].tot);
    } else {
      this.test[index].tot = 0;
      this.test[index].itemSelected.push(variant);
      ++variant.selectedQuantity;
      console.log("added. tot = " + this.test[index].tot);
    }

    for (let cat of category.items)
      this.test[index].tot += cat.selectedQuantity;
    category.blockedCategory = ((this.variants.minVariant > 0 && this.test[index].tot >= this.variants.minVariant) || (this.variants.minVariant == 0 && this.test[index].tot >= this.variants.maxVariant));
  }

  getVariantText(isTextButton: boolean = false): string {
    let v: number = (this.variants.minVariant == 0 ? this.variants.maxVariant : this.variants.minVariant);
    if (isTextButton) {
      let tVar: number = this.calculateTotal(undefined, false);
      let sv, con, vari;
      this._translate.get('ecr.ecr-d-var.1700').subscribe((res: string) => { sv = res; });
      this._translate.get('ecr.ecr-d-var.1701', { 'tVar': tVar }).subscribe((res: string) => { con = res; });
      this._translate.get(tVar == 1 ? 'ecr.ecr-d-var.1707' : 'ecr.ecr-d-var.1708').subscribe((res: string) => { vari = res; });
      return (this.variants.minVariant == 0 && tVar == 0 ? sv : con + vari);
    }

    let con1, con2, con3;
    this._translate.get('ecr.ecr-d-var.1702').subscribe((res: string) => { con1 = res; });
    this._translate.get('ecr.ecr-d-var.1703', { 'v': v }).subscribe((res: string) => { con2 = res; });
    let vari;
    this._translate.get(v == 1 ? 'ecr.ecr-d-var.1707' : 'ecr.ecr-d-var.1708').subscribe((res: string) => { vari = res; });
    this._translate.get('ecr.ecr-d-var.1704', { 'mv': this.variants.minVariant, 'Mv': this.variants.maxVariant, 'vari': vari }).subscribe((res: string) => { con3 = res; });

    if (this.variants.maxVariant == this.variants.minVariant && this.variants.minVariant == 0)
      return con1;
    else if (this.variants.minVariant == 0)
      return con2 + vari;
    else
      return con3;
  }

  canCloseDialog(): boolean {
    if (this.variants.minVariant == this.variants.maxVariant && this.variants.minVariant == 0) return true;
    if (this.variants.minVariant == 0 || (this.variants.minVariant == this.variants.maxVariant && this.variants.maxVariant == 0)) return true;
    if (this.variants.minVariant == 0 && this.calculateTotal(undefined, false) == this.variants.maxVariant) return true;
    if (this.variants.minVariant != 0 && this.calculateTotal(undefined, false) == this.variants.maxVariant) {
      let totG = 0;
      for (let c of this.variants.categories) {
        let totC = 0;
        totC = this.calculateTotal(c.items);
        if (totC > this.variants.minVariant) return false;
        totG += totC;
      }
      if (totG == this.variants.maxVariant) return true;
    }
  }

  confirmVariants () {
    if (!this.canCloseDialog()) return;
    let variants: VariantItem[] = [];
    let tot: number = 0;

    console.log(this.variants);
    for (let cat of this.variants.categories) {
      for (let ite of cat.items)
        if (ite.selectedQuantity != 0) {
          let _vI = new VariantItem ();
          _vI.Quantity = ite.selectedQuantity;
          _vI.Variant = ite.name;
          variants.push(_vI);
          ++tot;
        }

      if (tot == this.variants.maxVariant) break;
    }

    if (this.bs.addOrRemoveItemCartWS(this.variants.VariantItem, 1)) {
      this.bs.addtoBasket(this.variants.VariantItem.articolo, 1, variants);
      this.bs.payments.Total = this.bs.basket.Total;
      this.bs.payments.ToPay = this.bs.basket.Total;
      this.closePopup();
    }
    
  }
}