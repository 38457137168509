import { Component, OnInit, ViewChild } from '@angular/core';
import { ImaffManagementService } from '../services/imaff-management.service';
import { AffittuariMailMensile } from '../entities/im-aff/affittuari-mail-mensile';
import { DatatableComponent } from '../shared/datatable/datatable.component';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { AlertDialogService } from '../services/alert-dialog.service';
import { AuthGuardService } from '../services';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { GceManagementService } from '../services/gce-management.service';
import { GceClinic, GceLookUpOptions } from '../entities/gce-management';
import { FormHeaderComponent, ICustomButton, IFormHeaderSearch, FormHeaderSearchEventsEnum } from '../shared/form-header/form-header.component';
import { LoaderService } from '../loader/loader.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as moment from 'moment';
const dateFormat = "YYYY-MM-DD";
@Component({
  selector: 'app-im-aff',
  templateUrl: './im-aff.component.html',
  styleUrls: ['./im-aff.component.css']
})
export class ImAffComponent implements OnInit {
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  mails: AffittuariMailMensile[] = [];
  mailsBkp: AffittuariMailMensile[] = [];
  columns: any[] = [];
  role: string = '';
  dm: string = '';
  usr: string = '';
  showb: boolean = false;
  isAdmin: boolean = false;
  limitRows: number;
  heightMainList: number;
  customButtons: ICustomButton[] = [];
  fromDate: string;// = this.gceService.dataRepoGceEnvironment.getFromDate();
  toDate: string;// = this.gceService.dataRepoGceEnvironment.getToDate();
  keyColumn: any = {};
  current: string;

  constructor(private dialog: MatDialog, private route: Router, private imaffService: ImaffManagementService,
    private translateService: TranslateService,
    private alertDialog: AlertDialogService, private auth: AuthGuardService, public gceService: GceManagementService, private loaderService: LoaderService, public http: HttpClient) {
    this.routeEvent(this.route);
    this.customButtons.push({
      matTooltipCode: 'MAILAFF.BUTTONS.SEND.TOOLTIP',
      labelCode: 'MAILAFF.BUTTONS.SEND.LABEL',
      img: 'assets/img/email2.png',
      class: 'custom-button',
      clickFunction: this.sendMail.bind(this)
    });
    this.customButtons.push({
      matTooltipCode: 'GCE.GCEINVOICEPERSON.BUTTONS.EXPORTTOEXCEL.MONTHREPORT',
      labelCode: 'GCE.GCEINVOICEPERSON.BUTTONS.EXPORTTOEXCEL.MONTHREPORT',
      img: 'assets/img/excel2.png',
      class: 'custom-button',
      clickFunction: this.exportExcel.bind(this)
    });
  }

  setCurrent(h) {
    if (h && h.length > 2) {
      h = h.slice(2);
    }
    this.current = h.split("/")[0];
  }

  routeEvent(router: Router) {
    router.events.subscribe(e => {
      // console.log("first consolelog %o",e);
      if (e instanceof NavigationEnd) {
        // console.log(e);
        this.setCurrent(window.location.hash);
      }
    });
  }

  onSaveSingleV(v, http) {
    console.log('on save single value %o', v);
    //loader.show();


    let req = {
      email: v.mail_affittuario,
      cod_clinica: v.cod_clinica
    };

    http.post(`${environment.gceAPI}/affittuari_mail_mensili_update/`, req, { responseType: 'json' }).subscribe(d => {
      window.alert('Operazione effettuata');
      //loader.hide();
    });

  }

  sendMail(e: any) {
    
    if (this.isAdmin == true) {
      this.showb = true;
      if (confirm("Sei sicuro di voler inviare le Email con l'indicazione del giro di affari per il mese di lavoro selezionato?")) {
        this.gceService.sendEmailForAffittuari(this.fromDate, this.toDate).subscribe(d => {
          window.alert('Invio Email terminato');
          this.mails = d.mails;
          this.mailsBkp = this.mails;
          this.showb = false;
          this.setCurrent(window.location.hash);
        });
      }
      else {
        this.showb = false;
      }
    }
    else {
      window.alert('Attenzione: il tuo ruolo non è abilitato a questa funzione.');
    }
  }

  exportExcel() {
    if (this.isAdmin == true) {
      this.gceService.exportMailsAff(this.mails);
    }
    else {
      window.alert('Attenzione: il tuo ruolo non è abilitato a questa funzione.');
    }
  }

  onChangeRow(e: any) {

  }

  onCustomActionClick(e: any) {
    console.log('on customActionClick');
  }

  onSearch(searchFilter: IFormHeaderSearch) {
    if (searchFilter.event === FormHeaderSearchEventsEnum.INPUT_CHANGE) {
      this.showb = true;
      let filterValues = searchFilter.filter.split(" ").filter(a => !!a);
      console.log("onSearch %o filterValue %o", searchFilter, filterValues);

      this.mails = this.mailsBkp.filter((item: AffittuariMailMensile) => {
        for (let key in item) {
          //console.log('Key %o item %o', key, item[key]);
          if (!!item[key]) {

            if (key == 'id' || key == 'clinica' || key == 'mail_affittuario') {
              if (item[key].toString().toLowerCase().indexOf(searchFilter.filter) >= 0)
                return true;
            }

          }
        }
        return false;
      });
      this.showb = false;
    } else {


      if (searchFilter.event === FormHeaderSearchEventsEnum.FROM_CHANGE) {
        this.showb = true;
        let mfd = moment(searchFilter.fromDate);
        let mtd = moment(searchFilter.toDate);
        this.fromDate = mfd.format(dateFormat);
        if (mtd.diff(mfd, 'days') > mfd.daysInMonth() || mtd.isBefore(mfd)) {
          this.toDate = mfd.endOf('month').format(dateFormat);
        }
      }
      if (searchFilter.event === FormHeaderSearchEventsEnum.TO_CHANGE) {
        let mfd = moment(searchFilter.fromDate);
        let mtd = moment(searchFilter.toDate);
        this.toDate = mtd.format(dateFormat);
        if (mtd.diff(mfd, 'days') > mtd.daysInMonth() || mtd.isBefore(mfd)) {
          this.fromDate = mtd.startOf('month').format(dateFormat);
        }
      }
      if (this.fromDate && this.toDate) {
        this.gceService.loadDataForAffittuari(this.fromDate, this.toDate, this.role, this.dm).subscribe((dataResult: { clinics: GceClinic[], mails: AffittuariMailMensile[] }) => {
          //manage column based on logged profile
          console.log('response of loadDataForAffittuari tutti %o filtered %o', dataResult, dataResult.mails);
          this.mails = dataResult.mails;
          this.mailsBkp = this.mails;
          this.mails = [...this.mails];
          this.showb = false;
        }, err => {

          this.showb = false;
        });
      }
    }
  }

  onToggle(e: any) {
    console.log('onToggle');
  }

  ngOnInit() {

    var d = new Date(Date.now());
    d.setDate(1);
    d.setMonth(d.getMonth() - 1);
    let m = moment(d);

    this.fromDate = m.startOf('month').format(dateFormat);
    this.toDate = m.endOf('month').format(dateFormat);
    this.limitRows = Math.floor((window.innerHeight - 269) / 31); //269 340
    this.heightMainList = window.innerHeight - 188;
    this.columns = AffittuariMailMensile.getStructure();
    this.showb = true;
    this.mails = [];
    this.mailsBkp = [];
    let usr = this.auth.getUser();
    if (usr != null) {
      this.role = usr['ruolo'];
      this.dm = usr['clinica'];
      if (usr['nomeCompleto'] != null && usr['nomeCompleto'].length > 0) {
        this.usr = usr['nomeCompleto'];
      }
      else {
        if (usr['email'] != null && usr['email'].length > 0) {
          this.usr = usr['email'];
        }
      }
    }
    if (this.role != null && (this.role.toLowerCase() == 'amministratore' || this.role.toLowerCase() == 'hr medici' || this.role.toLowerCase() == 'direzione commerciale')) {
      this.isAdmin = true;
    }
    console.log('USER MAIL AFFITTUARI %o', usr);

    this.gceService.loadDataForAffittuari(this.fromDate, this.toDate, this.role, this.dm).subscribe((dataResult: { clinics: GceClinic[], mails: AffittuariMailMensile[] }) => {
      //manage column based on logged profile
      console.log('response of loadDataForAffittuari %o', dataResult);

      //get medics
      this.mails = dataResult.mails;
      this.mailsBkp = this.mails;
      this.mails = [...this.mails];
      this.showb = false;
    }, err => {

      this.showb = false;
    });



  }

}
