import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class SuppliersService {
  public localRows: any[];
  constructor(
    private _http: HttpClient
  ) {
    this.localRows = [];
  }

  addObj(obj: any) {
    this.localRows.push(obj);
  }

  remObj(obj: any) {
    let cp = this.localRows;
    this.localRows = [];
    for (let a = 0; a < cp.length; a = a + 1) {
      if (cp[a] == obj) {

      }
      else {
        this.localRows.push(cp[a]);
      }
    }
  }

  fetch(url, req) {
    console.log("IN FETCH");
    return this._http.post(
      url,
      req
    )
      .subscribe(res => {

      });
  }

}
