import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ViewChild, SimpleChange } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray, FormBuilder, AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs/Observable';
import { DialogCustomFieldComponent } from '../../data-identity/dialog-custom-field/dialog-custom-field.component';
import { AlertDialogService } from '../../services/alert-dialog.service';
import { Confirm } from "../../dialogs/confirm-dialog/confirm-dialog.component";
import { ApiProviderWinticplusService } from '../../services/api-provider-winticplus.service';
import { LoaderService } from '../../loader';
import { ActionsService } from '../../services/actions.service';

import 'rxjs/add/observable/of';
import 'rxjs/add/observable/range';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/observable/fromEvent';
import * as moment from "moment";
import "moment-timezone";

import { ProfileDomains, Domain } from '../../app.model';
import { environment } from '../../../environments/environment';
import { PercentageValidator } from '../../validators/percentage-validator';

const tz = "Europe/Rome";
const dateFormat = "YYYY-MM-DDTHH:mm:ss";

const dateFormatDate = "YYYY-MM-DD";
const dateFormatHour = "HH:mm";

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit, OnChanges {
  @Input() entity: string;
  @Input() data: any;
  @Input() fields: any[];
  @Input() isPatient:boolean;
  @Input() newRow: boolean = false;
  @Input() editing: boolean = false;
  @Input() actions: any[] = null;
  @Input() dataKeyId: any;
  @Input() viewSaveNew: boolean = true;
  @Input() viewSaveEdit: boolean = true;
  @Input() viewDelete: boolean = true;
  @Input() viewRestore: boolean = true;
  @Input() validator: (_: any, args) => {} = undefined;
  @Input() validatorSelf: any;
  @Input() keyHeader: string = undefined;
  @Input() headerText: string = undefined;

  @Output() save: EventEmitter<any> = new EventEmitter<any>();
  @Output() restore: EventEmitter<any> = new EventEmitter<any>();
  @Output() delete: EventEmitter<any> = new EventEmitter<any>();
  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  @Output() blurInput: EventEmitter<any> = new EventEmitter<any>();
  @Output() resetPassword: EventEmitter<any> = new EventEmitter<any>();
  @Output() resetMFA: EventEmitter<any> = new EventEmitter<any>();
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  @Output() customActionClick: EventEmitter<any> = new EventEmitter<any>();

  dataBkp: any;

  //primaryKeyDef:string;
  form: FormGroup;
  insertAction: object;
  deleteAction: object;
  updateAction: object;

  positionColorPicker: string = 'top';
  toggleColorPicker: boolean = false;

  submitted: boolean = false;

  private profiles: any[] = [];

  constructor(private fb: FormBuilder, public dialog: MatDialog,
    private alertDialog: AlertDialogService,
    private api: ApiProviderWinticplusService,
    private actionsService: ActionsService,
    private loaderService: LoaderService) {

  }

  init() {
    const div = document.getElementById("form");
    div.scrollTop = 0;
    this.dataBkp = this.data;

    console.log("init form %o data %o", this.fields, this.data);
    if (this.entity === 'winticplusaccount') {
      if (!(this.data == null) && !(this.data['subject'] == null)) {
        let self = this;
        this.loaderService.show();
        this.actionsService.getDetailUser(this.data['subject'],this.isPatient).subscribe(u => {
          console.log('----->RESPONSE %o', u);
          self.data = u;
          if (self.entity === 'winticplusaccount' && !self.newRow && !self.editing) {
            //callacountservice
            self.getProfilesAndDomains();
          }


          if (self.actions) {
            self.actions.forEach(action => {
              if (action.isInsertAction) {
                self.insertAction = action;
              } else if (action.isDeleteAction) {
                action.iconName = action.iconName.replace(" ", "_");
                self.deleteAction = action;
              } else if (action.isUpdateAction) {
                action.iconName = action.iconName.replace(" ", "_");
                self.updateAction = action;
              } // else skip
            });
          }

          self.createForm();
        });
      }
      else {
        if (this.entity === 'winticplusaccount' && !this.newRow && !this.editing) {
          //callacountservice
          this.getProfilesAndDomains();
        }

        if (this.actions) {
          this.actions.forEach(action => {
            if (action.isInsertAction) {
              this.insertAction = action;
            } else if (action.isDeleteAction) {
              action.iconName = action.iconName.replace(" ", "_");
              this.deleteAction = action;
            } else if (action.isUpdateAction) {
              action.iconName = action.iconName.replace(" ", "_");
              this.updateAction = action;
            } // else skip
          });
        }

        this.createForm();
      }

    }
    else {

      if (this.actions) {
        this.actions.forEach(action => {
          if (action.isInsertAction) {
            this.insertAction = action;
          } else if (action.isDeleteAction) {
            action.iconName = action.iconName.replace(" ", "_");
            this.deleteAction = action;
          } else if (action.isUpdateAction) {
            action.iconName = action.iconName.replace(" ", "_");
            this.updateAction = action;
          } // else skip
        });
      }

      this.createForm();

    }


    //console.log('INIT FORM %o fields %o', this.form, this.fields);
  }

  createForm() {
    let fieldsForm: any = {};
    this.fields.forEach(item => {
      if (item['type'] === 'action') return;
      if (item['type'] === 'dialog') return;
      if (!item['visible'] && !item['primaryKey']) return;
      if (this.newRow && this.entity === 'winticplusaccount' && !item['requiredOnNew']) return;
      if (item['subType'] === 'array') {
        let subitem = item['subColumns'][0];
        let arrayGroup = this.fb.array([]);
        if (this.data[item['columnDef']]) {
          this.data[item['columnDef']].forEach(ritem => {
            let validators = this.setRules(subitem);
            let control: any = {};
            control[subitem['columnDef']] = new FormControl(
              {
                value: ''
              }, validators
            );
            arrayGroup.push(this.fb.group(control));
          });

        } else {

          let validators = this.setRules(subitem);
          let control: any = {};
          control[subitem['columnDef']] = new FormControl(
            {
              value: ''
            }, validators
          );
          arrayGroup.push(this.fb.group(control));
        }

        fieldsForm[item['columnDef']] = arrayGroup;
      } else if (item['subColumns']) {
        let subGroup: any = {};
        item['subColumns'].forEach(subitem => {
          let validators = this.setRules(subitem);
          subGroup[subitem['columnDef']] = new FormControl(
            {
              value: ''
            }, validators
          );
        });
        fieldsForm[item['columnDef']] = new FormGroup(subGroup);

      } else if (item['type'] === 'datetime') {
        let validators = this.setRules(item);
        fieldsForm[`${item['columnDef']}_date`] = new FormControl(
          {
            value: ''
          }, validators
        );
        fieldsForm[`${item['columnDef']}_time`] = new FormControl(
          {
            value: ''
          }, validators
        );
        fieldsForm[item['columnDef']] = new FormControl({
          value: ''
        });
      } else if (item['type'] === 'percentage') {
        let validators = this.setRules(item);
        fieldsForm[item['columnDef']] = [0, validators];
      } else if (item['type'] === 'mont') {
        let validators = this.setRules(item);
        console.log('ITEM of MONT %o', item);
        fieldsForm['mm1'] = [false, validators];
        fieldsForm['m2'] = false;
        fieldsForm['m3'] = false;
        fieldsForm['m4'] = false;
        fieldsForm['m5'] = false;
        fieldsForm['m6'] = false;
        fieldsForm['m7'] = false;
        fieldsForm['m8'] = false;
        fieldsForm['m9'] = false;
        fieldsForm['m10'] = false;
        fieldsForm['m11'] = false;
        fieldsForm['m12'] = false;
        fieldsForm['mm1_1'] = [false, validators];
        fieldsForm['m2_1'] = false;
        fieldsForm['m3_1'] = false;
        fieldsForm['m4_1'] = false;
        fieldsForm['m5_1'] = false;
        fieldsForm['m6_1'] = false;
        fieldsForm['m7_1'] = false;
        fieldsForm['m8_1'] = false;
        fieldsForm['m9_1'] = false;
        fieldsForm['m10_1'] = false;
        fieldsForm['m11_1'] = false;
        fieldsForm['m12_1'] = false;
        fieldsForm[item['columnDef']] = ['', validators];
      }
      else {
        let validators = this.setRules(item);
        fieldsForm[item['columnDef']] = ['', validators];
      }
    });
    console.log('INIT FORM %o fields %o', fieldsForm);
    this.form = new FormGroup(this.fb.group(fieldsForm).controls, { updateOn: 'blur' });
    this.patchValue();
    console.log(this.form);
  }

  patchValue() {

    let fieldsValues: any = {};
    this.fields.forEach(item => {
      if (item['type'] === 'action') return;
      if (item['type'] === 'dialog') return;
      if (!item['visible'] && !item['primaryKey']) return;
      if (this.newRow && this.entity === 'winticplusaccount' && !item['requiredOnNew']) return;
      if (item['subType'] === 'array') {
        let subitem = item['subColumns'][0];
        let arrayValues: any[] = [];
        if (this.data[item['columnDef']]) {
          this.data[item['columnDef']].forEach(ritem => {
            let control: any = {};
            control[subitem['columnDef']] = this.setValue(ritem, subitem)
            arrayValues.push(control);
          });
          fieldsValues[item['columnDef']] = arrayValues;
        }
      } else if (item['subColumns']) {
        let subValues: any = {};
        item['subColumns'].forEach(subitem => {
          if (this.data[item['columnDef']]) {
            subValues[subitem['columnDef']] = this.setValue(this.data[item['columnDef']], subitem);
          }
        });
        fieldsValues[item['columnDef']] = subValues;
      } else if (item['type'] === 'datetime') {
        let theValue = this.setValue(this.data, item);
        console.log("form.component datetime %o", theValue);
        fieldsValues[`${item['columnDef']}_date`] = theValue.date;
        fieldsValues[`${item['columnDef']}_time`] = theValue.time;
        fieldsValues[item['columnDef']] = theValue.completeValue;
      } else if (item['type'] === 'mont') {
        console.log('PATCH VALUE ITEM TYPE MONTH data %o item %o val %o', this.data, item, this.data[item['columnDef']]);
        fieldsValues[item['columnDef']] = this.data[item['columnDef']];
        if (fieldsValues[item['columnDef']] == null) {
          fieldsValues[item['columnDef']] = '000000000000';
          fieldsValues['mm1'] = false;
          fieldsValues['m2'] = false;
          fieldsValues['m3'] = false;
          fieldsValues['m4'] = false;
          fieldsValues['m5'] = false;
          fieldsValues['m6'] = false;
          fieldsValues['m7'] = false;
          fieldsValues['m8'] = false;
          fieldsValues['m9'] = false;
          fieldsValues['m10'] = false;
          fieldsValues['m11'] = false;
          fieldsValues['m12'] = false;
        }
        else {
          //patch starting to values string
          let arrs = fieldsValues[item['columnDef']].split('');
          fieldsValues['mm1'] = !(arrs[0] == '0');
          fieldsValues['m2'] = !(arrs[1] == '0');
          fieldsValues['m3'] = !(arrs[2] == '0');
          fieldsValues['m4'] = !(arrs[3] == '0');
          fieldsValues['m5'] = !(arrs[4] == '0');
          fieldsValues['m6'] = !(arrs[5] == '0');
          fieldsValues['m7'] = !(arrs[6] == '0');
          fieldsValues['m8'] = !(arrs[7] == '0');
          fieldsValues['m9'] = !(arrs[8] == '0');
          fieldsValues['m10'] = !(arrs[9] == '0');
          fieldsValues['m11'] = !(arrs[10] == '0');
          fieldsValues['m12'] = !(arrs[11] == '0');
        }
      }
      else if (item['type'] === 'mont2') {
        console.log('PATCH VALUE ITEM TYPE MONTH 2 data %o item %o val %o', this.data, item, this.data[item['columnDef']]);
        fieldsValues[item['columnDef']] = this.data[item['columnDef']];
        if (fieldsValues[item['columnDef']] == null) {
          fieldsValues[item['columnDef']] = '000000000000';
          fieldsValues['mm1_1'] = false;
          fieldsValues['m2_1'] = false;
          fieldsValues['m3_1'] = false;
          fieldsValues['m4_1'] = false;
          fieldsValues['m5_1'] = false;
          fieldsValues['m6_1'] = false;
          fieldsValues['m7_1'] = false;
          fieldsValues['m8_1'] = false;
          fieldsValues['m9_1'] = false;
          fieldsValues['m10_1'] = false;
          fieldsValues['m11_1'] = false;
          fieldsValues['m12_1'] = false;
        }
        else {
          //patch starting to values string
          let arrs = fieldsValues[item['columnDef']].split('');
          fieldsValues['mm1_1'] = !(arrs[0] == '0');
          fieldsValues['m2_1'] = !(arrs[1] == '0');
          fieldsValues['m3_1'] = !(arrs[2] == '0');
          fieldsValues['m4_1'] = !(arrs[3] == '0');
          fieldsValues['m5_1'] = !(arrs[4] == '0');
          fieldsValues['m6_1'] = !(arrs[5] == '0');
          fieldsValues['m7_1'] = !(arrs[6] == '0');
          fieldsValues['m8_1'] = !(arrs[7] == '0');
          fieldsValues['m9_1'] = !(arrs[8] == '0');
          fieldsValues['m10_1'] = !(arrs[9] == '0');
          fieldsValues['m11_1'] = !(arrs[10] == '0');
          fieldsValues['m12_1'] = !(arrs[11] == '0');
        }
      }
      else {
        fieldsValues[item['columnDef']] = this.setValue(this.data, item);
      }
    });
    console.log('PATCH VALUE ITEMs data %o', fieldsValues);
    this.form.patchValue(fieldsValues);
  }

  setRules(item): any[] {
    let theRules = [];
    if (!item['visible']) return;
    if (item['required']) theRules.push(Validators.required);
    if (item['length']) theRules.push(Validators.maxLength(item['length']));
    if (item['type'] !== 'number' && item['type'] !== 'decimal' && item['type'] !== 'percentage') {
      if (item['pattern']) theRules.push(Validators.pattern(item['pattern']));
    }
    if (item['type'] === 'percentage') {
      theRules.push(PercentageValidator());
    }
    return theRules;
  }

  setValue(data, item): any {
    //console.log('SET VALUE data %o item %o', data, item);
    let theValue = null;
    if (data) {
      theValue = data[item['columnDef']];
    }

    if (theValue === null || theValue === undefined) theValue = "";
    if (!item['visible'] && item['primaryKey'] && theValue === "") theValue = 0;
    if (item['type'] === 'date') {
      if (theValue !== "") {
        theValue = new Date(theValue);
      } else {
        theValue = null;
      }
    }
    else if (item['type'] == 'time') {
      if (theValue !== "") {
        theValue = moment(theValue).format("HH:mm");
      } else {
        theValue = null;
      }
    }
    else if (item['type'] === 'datetime') {
      if (theValue !== "") {
        theValue = { date: new Date(`${moment(theValue).format("YYYY-MM-DD")} 00:00`), time: moment(theValue).format("HH:mm"), completeValue: theValue };
      } else {
        theValue = { date: null, time: null, completeValue: null };
      }
    }
    else if (item['type'] === 'array' && theValue === "") theValue = [];
    else if (item['type'] === 'boolean' && theValue === "") theValue = false;
    else if (item['type'] === 'select' && theValue === "") theValue = null;
    else if (item['type'] === 'percentage' && theValue === "") theValue = 0;
    // else if (item['type'] === 'number' && theValue === "") theValue = 0;
    // else if (item['type'] === 'decimal' && theValue === "") theValue = 0;

    return theValue;
  }

  ngOnInit() {
    if (this.data && this.fields) {
      this.init();
    }
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    // console.log("onChanges %o", changes);
    this.submitted = false;
    if (changes['data'] || changes['fields']) {
      this.init();
    }
    if (changes['editing']) {
      if (this.editing) {
        if (this.form)
          this.form.enable();
      } else {
        if (this.form)
          this.form.disable();
      }
    }
  }

  deleteRow() {
    const confirm: Confirm = {
      title: "FORM.CONFIRMDIALOG.DELETE.TITLE",
      message: "FORM.CONFIRMDIALOG.DELETE.MESSAGE",
      buttonOkText: "FORM.CONFIRMDIALOG.DELETE.BUTTONOKTEXT",
      buttonCancelText: "FORM.CONFIRMDIALOG.DELETE.BUTTONCANCELTEXT",
      buttonOkStyle: { 'background-color': '#268B26' },
      buttonCancelStyle: { 'background-color': '#AA3939' },
      withTranslate: true
    };
    this.alertDialog.confirm(confirm).subscribe(result => {
      if (result) {
        this.delete.emit(this.data);
      }
    });
  }

  restoreRow() {
    this.data = this.dataBkp;
    this.resetForm();
    this.restore.emit(this.data);
  }

  resetForm() {
    let control: AbstractControl = null;
    this.form.reset(this.data);
    this.form.markAsUntouched();
    Object.keys(this.form.controls).forEach((name) => {
      control = this.form.controls[name];
      control.setErrors(null);
    });
  }

  saveRow() {

    this.submitted = true;
    // this.form.setErrors(null);

    if (!(this.getSwitchValue('flg_pagam_dir_area_non_mensile') == null)) {
      let fieldsValues: any = {};
      if (this.getSwitchValue('flg_pagam_dir_area_non_mensile') == false) {

        fieldsValues['mesi_pagam_dir_area'] = '000000000000';
      }
      else {
        var v = [];
        if (this.getSwitchValue('mm1_1') == true) {
          v.push('1');
        }
        else {
          v.push('0');
        }
        if (this.getSwitchValue('m2_1') == true) {
          v.push('1');
        }
        else {
          v.push('0');
        }
        if (this.getSwitchValue('m3_1') == true) {
          v.push('1');
        }
        else {
          v.push('0');
        }
        if (this.getSwitchValue('m4_1') == true) {
          v.push('1');
        }
        else {
          v.push('0');
        }
        if (this.getSwitchValue('m5_1') == true) {
          v.push('1');
        }
        else {
          v.push('0');
        }
        if (this.getSwitchValue('m6_1') == true) {
          v.push('1');
        }
        else {
          v.push('0');
        }
        if (this.getSwitchValue('m7_1') == true) {
          v.push('1');
        }
        else {
          v.push('0');
        }
        if (this.getSwitchValue('m8_1') == true) {
          v.push('1');
        }
        else {
          v.push('0');
        }
        if (this.getSwitchValue('m9_1') == true) {
          v.push('1');
        }
        else {
          v.push('0');
        }
        if (this.getSwitchValue('m10_1') == true) {
          v.push('1');
        }
        else {
          v.push('0');
        }
        if (this.getSwitchValue('m11_1') == true) {
          v.push('1');
        }
        else {
          v.push('0');
        }
        if (this.getSwitchValue('m12_1') == true) {
          v.push('1');
        }
        else {
          v.push('0');
        }
        fieldsValues['mesi_pagam_dir_area'] = v.join('');
      }
      this.form.patchValue(fieldsValues);
    }

    if (!(this.getSwitchValue('flg_pagam_direzioni_non_mensile') == null)) {
      let fieldsValues: any = {};
      if (this.getSwitchValue('flg_pagam_direzioni_non_mensile') == false) {

        fieldsValues['mesi_pagam_direzioni'] = '000000000000';
      }
      else {
        var v = [];
        if (this.getSwitchValue('mm1') == true) {
          v.push('1');
        }
        else {
          v.push('0');
        }
        if (this.getSwitchValue('m2') == true) {
          v.push('1');
        }
        else {
          v.push('0');
        }
        if (this.getSwitchValue('m3') == true) {
          v.push('1');
        }
        else {
          v.push('0');
        }
        if (this.getSwitchValue('m4') == true) {
          v.push('1');
        }
        else {
          v.push('0');
        }
        if (this.getSwitchValue('m5') == true) {
          v.push('1');
        }
        else {
          v.push('0');
        }
        if (this.getSwitchValue('m6') == true) {
          v.push('1');
        }
        else {
          v.push('0');
        }
        if (this.getSwitchValue('m7') == true) {
          v.push('1');
        }
        else {
          v.push('0');
        }
        if (this.getSwitchValue('m8') == true) {
          v.push('1');
        }
        else {
          v.push('0');
        }
        if (this.getSwitchValue('m9') == true) {
          v.push('1');
        }
        else {
          v.push('0');
        }
        if (this.getSwitchValue('m10') == true) {
          v.push('1');
        }
        else {
          v.push('0');
        }
        if (this.getSwitchValue('m11') == true) {
          v.push('1');
        }
        else {
          v.push('0');
        }
        if (this.getSwitchValue('m12') == true) {
          v.push('1');
        }
        else {
          v.push('0');
        }
        fieldsValues['mesi_pagam_direzioni'] = v.join('');
      }
      this.form.patchValue(fieldsValues);
    }
    if (this.validator) {
      this.validator(this.validatorSelf, this.form);
    }

    console.log("saverow %o %o", this.form, this.form.valid);
    if (!this.form.valid) {
      this.alertDialog.open({
        title: "FORM.INVALIDSAVEDIALOG.TITLE",
        message: "FORM.INVALIDSAVEDIALOG.MESSAGE",
        isError: false,
        withTranslate: true
      });
    } else {
      this.save.emit({ data: this.data, form: this.form, editing: this.editing, newRow: this.newRow });
    }
  }

  resetPasswordRow() {
    this.resetPassword.emit(this.data);
  }

  resetMFARow() {
    this.resetMFA.emit(this.data);
  }

  onEditCustomColumn(field, data, editable: boolean = true): void {
    let height = 'auto';
    let haux = 0;
    if (field.subColumns.length < 7 && field.subType !== 'array') {
      height = (300 + haux + field.subColumns.length * 60) + "px";
    }
    let dialogRef = this.dialog.open(DialogCustomFieldComponent, {
      width: '670px',
      height: height,
      data: { row: this.data, form: this.form, column: field, newRow: false, editable: editable }
    });
    dialogRef.afterClosed().subscribe(data => {
      // console.log("data %o, editable %o", data, editable);
      if (editable) {
        if (data) {
          if (field.subType === 'array') {
            this.form.controls[field.columnDef] = data.form.controls[field.columnDef];
            this.form.value[field.columnDef] = data.form.controls[field.columnDef].getRawValue();
          } else {
            this.form.controls[field.columnDef] = data.form;
            this.form.value[field.columnDef] = data.form.getRawValue();
          }
          //console.log(row);
        }
      } else {
        //  delete data.$$form;
      }
    })
  }

  onAction(action) {
    console.log(action);
    this.submitted = true;
    if (this.form.valid) {
      this.action.emit({ data: this.data, form: this.form, action: action });
    }
  }

  //only for winticplusaccount
  private getProfilesAndDomains() {
    let queryProfiles = { resource: 'winticplusprofile' };
    this.api.makeAPICall('actionGetall', queryProfiles, false).subscribe(dataProfiles => {
      console.log("dataProfiles %o, this.data.idsActionProfile %o", dataProfiles, this.data.idsActionProfile);
      if (this.data != null && this.data.idsActionProfile != null) {
        let prds: ProfileDomains[] = this.data.idsActionProfile.map((prd) => { return prd.idProfile }).filter((value, index, array) => array.indexOf(value) === index && value > 0).map((prd) => { return { idProfile: prd } });
        // console.log(prds);

        prds.forEach((prd) => {
          let p = dataProfiles.filter((elem, index, self) => {
            return elem.idProfile === prd.idProfile;
          })[0];
          if (p) {
            prd.name = p.name;
            prd.description = p.description;
          }
          let dpds = this.data.idsActionProfile.filter((dpd) => dpd.idProfile === prd.idProfile && dpd.idDomain >= 0).map((dpd) => { return { idDomain: dpd.idDomain } });
          prd.domains = dpds;
        });

        let queryDomains = { resource: 'winticplusdomain' };
        this.api.makeAPICall('actionGetall', queryDomains, false).subscribe(dataDomains => {
          let domains = dataDomains;

          prds.forEach((prd) => {
            prd.domains.forEach((dmn) => {
              let d = dataDomains.filter((elem, index, self) => {
                return elem.idDomain === dmn.idDomain;
              })[0];
              if (d) {
                dmn.name = d.name;
                dmn.description = d.description;
              }
            });
          });

          this.loaderService.hide();
        });

        this.profiles = prds;
      }
      this.loaderService.hide();
      // console.log("profiles: %o", this.profiles);
    });
  }

  onEditArrayField(field, data) {
    // console.log("data %o, dataKeyId %o", data, this.dataKeyId);

    // let dialogRef = this.dialog.open(DialogSelectableColumnComponent, {
    //   width:'70%',
    //   height:'600px',
    //   data:{
    //     reference:field.reference,
    //     title:field.header,
    //     keyColumn:this.dataKeyId['keyId'],
    //     keyRowId:data[this.dataKeyId['keyId']['columnDef']]
    //   }
    // });
    // dialogRef.afterClosed().subscribe(data=>{
    //   if(data){
    //
    //     //console.log(data);
    //   }
    //
    // });
  }

  onBlurInput(field, value, form) {
    // this.blurInput.emit({field:field, value:value, form:form});
  }

  onRangePriceList(field, data) {
    // console.log("onRangePriceList field %o data %o", field, data);
    // let dialogRef = this.dialog.open(PriceListRangeComponent, {
    //   width:'40%',
    //   height:'600px',
    //   data:{
    //     reference:field.reference,
    //     title:field.header,
    //     keyColumn:this.dataKeyId['keyId'],
    //     keyRowId:data[this.dataKeyId['keyId']['columnDef']]
    //   }
    // });
    // dialogRef.afterClosed().subscribe(data=>{
    //   if(data){
    //
    //     //console.log(data);
    //   }
    //
    // });
  }

  closeForm() {
    this.close.emit();
  }

  onClickColor(event) {
    // console.log("onClickColor %o", event);
    let h = event.view.innerHeight / 2;
    let y = event.clientY;
    // console.log("onClickColor %o, %o", y, h);
    if (y >= h) {
      this.positionColorPicker = 'top';
    } else {
      this.positionColorPicker = 'bottom';
    }
    this.toggleColorPicker = !this.toggleColorPicker;
    // console.log("onClickColor %o", this.positionColorPicker);
  }

  toggleSwitchValue(columnDef: string) {
    let newVal = !this.form.get(columnDef).value;
    this.form.controls[columnDef].patchValue(newVal);
  }

  getSwitchValue(columnDef: string): boolean {
    //console.log('GETSWITCHVALUE %o', columnDef);
    if (this.form.get(columnDef) != null)
      return this.form.get(columnDef).value;
    else
      return false;

  }



  elementAdded(event, columnDef: string, subColumnDef: string) {
    let element = event.element;
    let elements = event.elements;
    let formArray = this.form.get(columnDef) as FormArray;
    let group: any = {};
    group[subColumnDef] = new FormControl(element[subColumnDef]);
    formArray.push(this.fb.group(group));
    this.form.patchValue(elements);
    //
    // console.log("element %o, form %o, columnDef %o, subColumnDef %o",element[subColumnDef], this.form, columnDef, subColumnDef);
  }

  dateTimeChange(event, field) {
    console.log("dateTimeChange event: %o field: %o", event, field, this.form.getRawValue());
    let sdate = this.form.controls[`${field.columnDef}_date`].value;
    let shour = this.form.controls[`${field.columnDef}_time`].value;
    let value = moment(`${moment.tz(sdate, tz).format(dateFormatDate)}T${shour}`).format(dateFormat);
    console.log("dateTimeChange sdate: %o shour: %o %o", sdate, shour, value);
    // this.form.controls[field.columnDef].patchValue();
  }
}
