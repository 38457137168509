import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { webTicMenu } from '../app.model';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css']
})
export class MenuItemComponent implements OnInit {
  @Input() menuitem: webTicMenu;
  @Input() active: boolean;
  @Input() auxIconPath:string=undefined;
  // @Output() selectedMenu: EventEmitter<webTicMenu> = new EventEmitter<webTicMenu>();
  constructor() { }

  ngOnInit() {
  }

  onSelectMenu(menuItem:webTicMenu) {
    // console.log('menuItem %o', menuItem);
    // this.selectedMenu.emit(menuItem);
    if (menuItem.action.indexOf('webpos') >= 0)
      window.location.href = menuItem.action;
    else
      window.location.hash = "/" + menuItem.action;
  }

  getPathIcon():string{
    if(this.auxIconPath){
      return `assets/img/menu/${this.auxIconPath}/${this.menuitem.image}`;
    }else{
      return `assets/img/menu/${this.menuitem.image}`;
    }
  }
}
