import { primaryKey, editableOnNew, headerCode, inputType, required, length, order, pattern, editable, Describer, visible, GceEntity, textAlign, relation, currency, roles } from './gce-management';

/**
 * GcePerformanceCalculationDetracts -> table: fmc_addebiti_laboratorio
 */
export class GcePerformanceCalculationDetracts extends GceEntity {

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETRACTS.IDVEND')
    @inputType('number')
    @visible(false)
    @order(0)
    IDVEND: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.IDFATTURAZIONE')
    @inputType('text')
    @visible(false)
    @order(2)
    idfatturazione: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.MESE')
    @inputType('date')
    @visible(false)
    @order(3)
    mese: Date | string;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.COD_CLINICA')
    @inputType('text')
    @visible(false)
    @order(16)
    cod_clinica: string;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETAIL.ID_CLINICA')
    @inputType('text')
    @length(255)
    @visible(false)
    @order(23)
    id_clinica: string;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETRACTS.DATA_CARICO')
    @inputType('date')
    @visible(true)
    @order(12)
    data_carico: Date | string;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETRACTS.DATA_ORDINE_EVOLUTION')
    @inputType('date')
    @visible(true)
    @order(13)
    data_ordine_evolution: Date | string;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETRACTS.IMPORTO_ORDINE')
    @inputType('number')
    @pattern('00000.00')
    @editable(true)
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @visible(true)
    @order(17)
    importo_ordine: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETRACTS.IMPORTO_ADDEBITO_MEDICO')
    @inputType('aeditablenumber')
    @pattern('00000.00')
    @editable(true)
    @currency({ isCurrency: true, currencyCode: 'EUR', display: 'symbol', digitsInfo: '2.2-2', locale: 'it' })
    @visible(true)
    @order(18)
    importo_addebito_medico: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETRACTS.PERC_MEDICO')
    @inputType('aeditablenumber')
    @pattern('00.00')
    @visible(true)
    @editable(true)
    @order(19)
    perc_medico: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETRACTS.NUM_ORDINE_EVOLUTION')
    @inputType('number')
    @visible(true)
    @order(10)
    num_ordine_evolution: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETRACTS.RIF_ORDINE')
    @inputType('text')
    @visible(true)
    @order(11)
    rif_ordine: string;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETRACTS.NUM_PRESCRIZIONE')
    @inputType('number')
    @visible(true)
    @order(6)
    num_prescrizione: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETRACTS.ID_PRESCRIZIONE')
    @inputType('number')
    @visible(false)
    @order(7)
    idprescrizione: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETRACTS.NOME_MEDICO')
    @inputType('textbyentity')
    @relation("GceMedics2", { columnRel: 'idfatturazione', columnDescr: ['descrizione'] })
    @visible(true)
    @order(9)
    medico_prescrizione: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETRACTS.ID_OPERATORE')
    @inputType('number')
    @visible(false)
    @editable(true)
    @order(21)
    idoperatore: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETRACTS.COD_GENERE')
    @inputType('number')
    @visible(false)
    @editable(true)
    @order(24)
    codgenere: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETRACTS.DES_ARTICOLO_EVOLUTION')
    @inputType('text')
    @visible(true)
    @order(15)
    des_articolo_evolution: string;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETRACTS.ID_ARTICOLO_EVOLUTION')
    @inputType('number')
    @visible(false)
    @order(14)
    idarticolo_evolution: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETRACTS.NOME_MEDICO')
    @inputType('text')
    @visible(false)
    @order(9)
    nome_medico: string;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETRACTS.ERRORE_PRESCRIZIONE')
    @inputType('text')
    @visible(true)
    @order(2)
    errore_prescrizione: string;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETRACTS.OK_CALCOLO')
    @inputType('boolean')
    @visible(false)
    @order(1)
    ok_calcolo: boolean;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETRACTS.QTA_ORDINE')
    @inputType('number')
    @visible(true)
    @editable(true)
    @order(20)
    qta_ordine: number;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETRACTS.ID_PAZIENTE')
    @inputType('text')
    @visible(true)
    @order(5)
    idpaziente: any;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETRACTS.TOUCHED')
    @inputType('boolean')
    @visible(true)
    @editable(true)
    @order(4)
    touched: boolean;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETRACTS.RIGA_VALIDA')
    @inputType('boolean')
    @visible(true)
    @order(1)
    flg_riga_valida?: boolean;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETRACTS.RICHIESTA_CONFERMA_VALIDA')
    @inputType('boolean')
    @visible(false)
    @order(1)
    flg_conferma_hr_richiesta?: boolean;

    @headerCode('GCE.GCEPERFORMANCECALCULATIONDETRACTS.CONFERMATO_HR')
    @inputType('boolean')
   // @roles(['Amministratore', 'HR Medici'])
    @order(1)
    flg_conferma_hr?: boolean;

    constructor(
        IDVEND?: number,
        idfatturazione?: number,
        mese?: Date,
        cod_clinica?: string,
        id_clinica?: string,
        data_carico?: Date,
        data_ordine_evolution?: Date,
        importo_ordine?: number,
        importo_addebito_medico?: number,
        perc_medico?: number,
        num_ordine_evolution?: number,
        rif_ordine?: string,
        num_prescrizione?: number,
        idprescrizione?: number,
        medico_prescrizione?: number,
        idoperatore?: number,
        codgenere?: number,
        des_articolo_evolution?: string,
        idarticolo_evolution?: number,
        nome_medico?: string,
        errore_prescrizione?: string,
        ok_calcolo?: boolean,
        qta_ordine?: number,
        idpaziente?: any,
        touched?: boolean,
        flg_riga_valida?: boolean,
        flg_conferma_hr_richiesta?: boolean,
        flg_conferma_hr?: boolean
    ) {
        super();

        this.IDVEND = IDVEND || 0;
        this.idfatturazione = idfatturazione;
        this.mese = mese || new Date();
        this.cod_clinica = cod_clinica || ''
        this.id_clinica = id_clinica || '';
        this.data_carico = data_carico || new Date();
        this.data_ordine_evolution = data_ordine_evolution || new Date();
        this.importo_ordine = importo_ordine;
        this.importo_addebito_medico = importo_addebito_medico;
        this.perc_medico = perc_medico;
        this.num_ordine_evolution = num_ordine_evolution || 0;
        this.rif_ordine = rif_ordine || '';
        this.num_prescrizione = num_prescrizione || 0;
        this.idprescrizione = idprescrizione || 0;
        this.medico_prescrizione = medico_prescrizione || 0;
        this.idoperatore = idoperatore || 0;
        this.codgenere = codgenere || 0;
        this.des_articolo_evolution = des_articolo_evolution || '';
        this.idarticolo_evolution = idarticolo_evolution || 0;
        this.nome_medico = nome_medico || '';
        this.errore_prescrizione = errore_prescrizione || '';
        this.ok_calcolo = ok_calcolo || false;
        this.qta_ordine = qta_ordine || 0;
        this.idpaziente = idpaziente || '';
        this.touched = touched || false;
        this.flg_riga_valida = flg_riga_valida|| false;
        this.flg_conferma_hr_richiesta = flg_conferma_hr_richiesta|| false;
        this.flg_conferma_hr = flg_conferma_hr|| false;

    }

    static getStructure(): Array<any> {
        let structure: any[] = Describer.getStructure(new GcePerformanceCalculationDetracts());
        structure.push(...this.getActions());
        return structure;
    }

    protected static getActions(): any[] {
        let actions: any[] = [];


        let actionCalculationDetail = {
            action: undefined,
            callback: 'sv',
            callbackOnForm: false,
            columnDef: 'actionCalculationDetail',
            disabled: false,
            header: 'GCE.GCEINVOICE.UPDATEREC',
            dontShowHeader: false,
            length: 256,
            lookUp: {},
            primaryKey: false,
            reference: null,
            required: false,
            requiredOnNew: false,
            show: true,
            type: 'custom-action',
            values: null,
            visible: true,
            editable: false,
            visibleOnToggle: false,
            imageButton: null,
            imageTable: 'assets/img/invoice-confirm.png',
            tooltip: 'GCE.GCEINVOICE.UPDATEREC',
            order: 1
        }
        actions.push(actionCalculationDetail);

        return actions;
    }
}