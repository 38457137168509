import { Component, Renderer2 } from '@angular/core';
import { JwksValidationHandler } from 'angular-oauth2-oidc';
import { OAuthService } from 'angular-oauth2-oidc';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from './loader';
import { environment } from '../environments/environment';
import { PreferencesService, Preferences } from './services/preferences.service';
import { MessagesDialogComponent } from './order-management/shared/dialogs/msgs.dialog';
import { UserService } from './services/user.service';
import { CartService } from './services/cart.service';
import { Title } from '@angular/platform-browser';
import { AuthGuardService } from './services/auth-guard.service';
import { MatDialog } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { ControllersService } from './services/controllers.service';
import localeIt from '@angular/common/locales/it';
import { AuthConfig } from 'angular-oauth2-oidc';
import { registerLocaleData } from '@angular/common';
import { AppConfig } from './app.config';
import { PermissionService } from './services/permission.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public title: any;

  constructor(
    public oauthService: OAuthService,
    public translate: TranslateService,
    private renderer: Renderer2,
    private loaderService: LoaderService,
    private prefServices: PreferencesService,
    public userService: UserService,
    public cartService: CartService,
    private titleService: Title,
    private _http: HttpClient,
    private _dialog: MatDialog,
    private _auth: AuthGuardService,
    private controllersService: ControllersService,
    private permissionService: PermissionService
  ) {
    console.log('current CONFIG in app constructor %o', AppConfig.settings);
    this.title = AppConfig.settings.clientId;
    this.titleService.setTitle(this.title);
    this.renderer.removeClass(document.body, 'withoutoverflow');
    this.renderer.removeClass(document.body, 'loading');
    this.loaderService.show();
    //console.log("environment %o", environment);
    // this language will be used as a fallback when a translation isn't found in the current language

    this.prefServices.load().subscribe((pref: Preferences) => {
      // console.log(pref);
      if (pref.language) {
        translate.setDefaultLang(pref.language);
        translate.use(pref.language);
      } else {
        translate.setDefaultLang(AppConfig.settings.defaultLocale);

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        translate.use(AppConfig.settings.defaultLocale);
      }
    });

    const authConfig: AuthConfig = {
      // Url of the Identity Provider
      issuer: 'https://identity.dentalpro.it',//AppConfig.settings.identityUrl,
      // URL of the SPA to redirect the user to after login
      redirectUri: window.location.origin + '/home',
      // The SPA's id. The SPA is registerd with this id at the auth-server
      clientId: AppConfig.settings.clientId,
      // set the scope for the permissions the client should request
      // The first three are defined by OIDC. The 4th is a usecase-specific one
      scope: AppConfig.settings.oAuthScope,
      postLogoutRedirectUri: window.location.origin
    };

    this.oauthService.configure(authConfig);
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();

    this.oauthService.setStorage(localStorage);
    
    let self = this;
   
    this.oauthService.loadDiscoveryDocument().then(() => {
      // This method just tries to parse the token(s) within the url when
      // the auth-server redirects the user back to the web-app
      // It dosn't send the user the the login page
      console.log('TRY LOGIN');
      this.oauthService.tryLogin();

    });

/*
    this.oauthService.loadDiscoveryDocument().then(() => {
      console.log('tryLogin');
      this.oauthService.tryLogin().then((parLogged: boolean) => {
        console.log('parLogged', parLogged);
        if (!parLogged) {
          this.oauthService.initLoginFlow();
        }
      }).catch((reason: any) => {
        console.log('reason', reason);
        this.oauthService.initLoginFlow();
      });
    });
*/

    this.userService.getCurrentUser().subscribe(f => {
      this.controllersService.loadData(f);
      this.permissionService.getPermissionByProfile().subscribe();
      console.log('current user in app constructor %o', f);
      self.cartService.initCart("Concession");
      let self2 = self;
      self._http.get(
        AppConfig.settings.GetAllMessagesPerClinic.replace("{0}", this._auth.getDomain()).replace("{1}", this._auth.getProfile())
      ).subscribe(res => {
        this.loaderService.hide();
        if (res['esito'] && res['obj'].length > 0) {
          var data = [];
          data['msgs'] = res['obj'];
          data['options'] = [{ caption: 'ok', action: 'true' }];
          let dialogRef = self2._dialog.open(MessagesDialogComponent, {
            data: data,
            panelClass: 'sizingForDialog'
          });
        }
      });
    });
    
  }

  ngOnInit() {
    registerLocaleData(localeIt, 'it-IT');
  }
}
