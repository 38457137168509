import { primaryKey, visible, headerCode, inputType, relation, length, order, currency, pattern, editable, Describer, GceEntity, roles } from '../gce-management/gce-management';

export class SsoMonLog extends GceEntity {

    @order(1)
    @headerCode('SSOMON.ID')
    @inputType('number')
    id: number;

    @order(5)
    @headerCode('SSOMON.MESSAGE')
    @inputType('string')
    @length(1024)
    message: string;

    @order(4)
    @headerCode('SSOMON.USER')
    @inputType('string')
    @length(256)
    usr: string;

    @order(3)
    @headerCode('SSOMON.TIMESTAMP')
    @inputType('datetime')
    timeStamp: Date;

    @order(2)
    @headerCode('SSOMON.LEVEL')
    @inputType('string')
    @length(128)
    level: string;


    constructor();
    constructor(id:number, message:string,usr:string,level:string,timeStamp: Date);
    constructor(id?:number, message?:string,usr?:string,level?:string,timeStamp?: Date) {
        super();

        this.id = id;
        this.message = message || '';
        this.usr = usr || '';
        this.level = level || '';
        this.timeStamp = timeStamp || new Date();

    }

    static getStructure(): Array<any> {
        let structure: any[] = Describer.getStructure(new SsoMonLog());
        structure.push(...this.getActions());
        return structure;
    }


    protected static getActions(): any[] {
        let actions: any[] = [];
        return actions;
    }
}