import 'hammerjs';
import 'hammer-timejs';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule, Injectable, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { AppConfig } from './app.config';
import { NgxCurrencyModule } from "ngx-currency";
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
// import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
// import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {MatTreeModule} from '@angular/material/tree';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  ActionsService, AlertDialogService, ApiProviderWinticplusService,
  AuthGuardService, CachingInterceptor,
  CartService,
  ClockService, CommonUtilsService, ControllersService,
  UserService,
  MyHttpCache,
  TokenInterceptor, DataRepositoryService,
  PermissionService

} from './services';
import { ScheduleTimeService } from './services/schedule-time.service';
import { } from './services/caching.interceptor';
import { CallApiComponent } from './call-api/call-api.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { WindowsRefService } from './services/windows-ref.service';
import { DevicemanagerService } from './services/devicemanager.service';
import { WebsocketService } from './services/websocket.service';
import { NgPipesModule } from 'ngx-pipes';
import { MessageMngtService } from './services/messagemngt.service';
import { QrCodeModule } from 'ng-qrcode';


import {
  MatButtonModule,
  MatCheckboxModule,
  MatInputModule,
  MatMenuModule,
  MatExpansionModule,
  MatIconModule,
  MatToolbarModule,
  MatListModule,
  MatAutocompleteModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatCardModule,
  MatTableModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatChipsModule,
  MatGridListModule,
  MatSlideToggleModule,
  MatRadioModule,
  MatSliderModule,
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltipDefaultOptions,
  MatStepperModule,
  MatDividerModule,
  DateAdapter,
  MatSortModule
} from '@angular/material';

import { MatDialogModule } from '@angular/material/dialog';

import { MatTooltipModule } from '@angular/material/tooltip';

import { MAT_DATE_LOCALE } from '@angular/material/core';
import { CdkTableModule } from '@angular/cdk/table';
import { Platform } from '@angular/cdk/platform';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { StartPageComponent } from './start-page/start-page.component';
import { UserWidgetComponent } from './user-widget/user-widget.component';
import {
  OAuthModule, UrlHelperService,
} from 'angular-oauth2-oidc';
import { ClockComponent } from './clock/clock.component';
import { ForecastComponent } from './forecast/forecast.component';
import { MngforecastComponent } from './mngforecast/mngforecast.component';
import { ForecastsService } from './services/forecasts.service';
import { DialogLoginRequiredComponent } from './dialogs/dialog-login-required/dialog-login-required.component';
import { DialogConsentsComponent } from './dialogs/dialog-consents/dialog-consents.component';
import { DialogMessageBroadcastComponent } from './dialogs/dialog-message-broadcast/dialog-message-broadcast.component';
import { SettingsCardComponent } from './settings-card/settings-card.component';
import { DialogToggleComponent } from './shared/dialog-toggle/dialog-toggle.component';
import { DataIdentityListComponent } from './data-identity/data-identity-list/data-identity-list.component';
import { DialogCustomFieldComponent } from './data-identity/dialog-custom-field/dialog-custom-field.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { AgmCoreModule } from '@agm/core';
import { ArrayToTextPipe } from './pipes/arraytotext.pipe';
import { AddressPipe } from './pipes/address.pipe';
import { AlertDialogComponent } from './dialogs/alert-dialog/alert-dialog.component';
import { LoaderComponent, LoaderService } from './loader';
import { LoadersComponent } from './loaders';
import { PageManagementComponent } from './page-management/page-management.component';
import { AssignProfileComponent } from './data-identity/assign-profile/assign-profile.component';
import { ColWidthPipe } from './pipes/col-width.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { PickerFilterPipe } from './pipes/picker-filter.pipe';
import { BlobToImagePipe } from './pipes/blob-to-image.pipe';
import { ImageDialogComponent } from './dialogs/image-dialog/image-dialog.component';
import { LengthColumnsPipe } from './pipes/length-columns.pipe';
import { PaginationPipe } from './pipes/pagination.pipe';
import { PaginationComponent } from './shared/pagination/pagination.component';
import { DatatableComponent } from './shared/datatable/datatable.component';
import { FormComponent } from './shared/form/form.component';
import { FormHeaderComponent } from './shared/form-header/form-header.component';
import { DisableControlDirective } from './directives/disable-control.directive';
import { DialogDataComponent } from './dialogs/dialog-data/dialog-data.component';
import * as hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';

import { ErrorComponent } from './order-management/error/error.component';
import { OrderManagementComponent } from './order-management/order-management.component';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { YesNoDialogComponent } from './order-management/shared/dialogs/yes-no.dialog';
import { YesNoDialogComponentS } from './suppliers/shared/dialogs/yes-no.dialog';
import { TableService } from './order-management/shared/services/table.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';

/* Store management start */
import { StoreFormatPipe } from './pipes/store-format.pipe';
import { AddProductStoreDialogComponent } from './order-management/shared/dialogs/add-product.dialog';
import { BackOrderDialogComponent } from './order-management/shared/dialogs/backorder.dialog';
import { BackOrderMaxThresholdDialogComponent } from './order-management/shared/dialogs/backorderthreshold.dialog';

/* Store management end */
import { TabindexDirective } from './directives/tabindex.directive';
import { NotificationComponent } from './order-management/notification/notification.component';
import { PreferencesService } from "./services/preferences.service";
import { SecureDomPipe } from './pipes/secure-dom.pipe';
import { GroupbyPipe } from './pipes/groupby.pipe';
import { CheckpresetPipe } from './pipes/checkpreset.pipe';
import { FormFieldChipComponent } from './shared/form-field-chip/form-field-chip.component';
import { FormFieldListComponent } from './shared/form-field-list/form-field-list.component';
import { ActionComponent } from './order-management/action/action.component';
import { BasketComponent } from './concession/basket/basket.component';
import { RightSideComponent } from './concession/right-side/right-side.component';
import { Variant2Dialog } from './concession/global/dialogs/variants2.dialog';
import { LayoutDialog } from './concession/global/dialogs/layout.dialog';
import { YesNoDialog } from './concession/global/dialogs/yesno.dialog';
import { LanguageDialog } from './concession/global/dialogs/language.dialog';
import { UpperCropped } from './concession/global/pipes/uppercropper.pipe';
import { ChangelogDialog } from './concession/global/dialogs/changelog.dialog';
import { OperPauseComponent } from './concession/oper-pause/oper-pause.component';
import { SettingsDialog } from './concession/global/dialogs/settings.dialog';
import { ConcessionComponent } from './concession/concession.component';
import { BasketService } from './concession/global/services/basket.service';
import { YesNoService } from './concession/global/dialogs/yesno.service';
import { PauseService } from './concession/global/services/pause.service';
import { InputDialogComponent } from './dialogs/input-dialog/input-dialog.component';
import { InputNumberComponent } from './shared/input-number/input-number.component';
import { PosConcessionService } from './concession-system/services/pos.service';
import { WebsocketConcessionService } from './concession-system/services/ws.service';
import { GoliFormatPipe } from './pipes/goliFormat.pipe';
import { CarotaggioComponent } from './carotaggio/carotaggio.component';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { TextWithoutSpecialCharsDirective } from './directives/text-without-special-chars.directive';
import { TextUpperCaseDirective } from './directives/text-upper-case.directive';
import { PrinterService } from './services/printer.service';
import { MessagesDialogComponent } from './order-management/shared/dialogs/msgs.dialog';
import { MessagesDialogComponentS } from './suppliers/shared/dialogs/msgs.dialog';
import { MessagesMngmtComponent } from './messages-mngmt/messages-mngmt.component';
import { NewMessageDialogComponent } from './order-management/shared/dialogs/newmsgs.dialog';

import { DayPilotModule } from 'daypilot-pro-angular';
import { DraggableToSchedulerDirective } from './directives/draggable-to-scheduler.directive';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { OrderReceivedComponent } from './suppliers/order-received/order-received.component';
import { OrderInvoiceableComponent } from './suppliers/order-invoiceable/order-invoiceable.component';
import { InvoicesComponent } from './suppliers/invoices/invoices.component';
import { OrderDetailDialogComponent } from './suppliers/shared/dialogs/order-detail.dialog';
import { OrderDetailDownloadDialogComponent } from './order-management/shared/dialogs/order-detail-download.dialog';
import { SuppliersService } from './services/suppliers.service';
import { FormFieldSelectSearchComponent } from './shared/form-field-select-search/form-field-select-search.component';
import { ColorPickerModule } from 'nxt-color-picker';

import { CustomDateAdapter } from './adapters/custom-date-adapter';
import { ConcessionSystemModule } from './concession-system/concession-system.module';
import { SharedModule } from './shared.module';
import { HttpMockRequestInterceptor } from './services/http-mock-request-interceptor';
import { GceManagementComponent } from './gce-management/gce-management.component';
import { GcePersonalDataComponent } from './gce-management/gce-personal-data/gce-personal-data.component';
import { GceReportsComponent } from './gce-management/gce-reports/gce-reports.component';
import { GceBadgeComponent } from './gce-management/gce-badge/gce-badge.component';
import { GceManagementService } from './services/gce-management.service';
import { GceDialogCompensationComponent } from './gce-management/dialogs/gce-dialog-compensation/gce-dialog-compensation.component';
import { GceDialogClinicAssociationComponent } from './gce-management/dialogs/gce-dialog-clinic-association/gce-dialog-clinic-association.component';
import { GceCalendarComponent } from './gce-management/gce-calendar/gce-calendar.component';
import { GceDialogBadgeFormComponent } from './gce-management/dialogs/gce-dialog-badge-form/gce-dialog-badge-form.component';
import { GceInvoicesComponent } from './gce-management/gce-invoices/gce-invoices.component';
import { GceRiconciliationComponent } from './gce-management/gce-riconciliation/gce-riconciliation.component';
import { GceDialogInvoiceFormComponent } from './gce-management/dialogs/gce-dialog-invoice-form/gce-dialog-invoice-form.component';
import { GceDialogCalculateBillingComponent } from './gce-management/dialogs/gce-dialog-calculate-billing/gce-dialog-calculate-billing.component';
import { ConditionalFormattingDirective } from './directives/conditional-formatting.directive';
import { GceDialogAnagraphicComponent } from './gce-management/dialogs/gce-dialog-anagraphic/gce-dialog-anagraphic.component';
import { GceDialogPerformanceCalculationDetailComponent } from './gce-management/dialogs/gce-dialog-performance-calculation-detail/gce-dialog-performance-calculation-detail.component';
import { ExcelService } from './services/excel.service';
import { ClinichedatabaseService } from './services/clinichedatabase.service';
import { GmaManagementComponent } from './gma-management/gma-management.component';
import { DatibaseComponent } from './datibase/datibase.component';
import { ClinichedatabaseComponent } from './datibase/clinichedatabase/clinichedatabase.component';
import { GmaStockCategorieComponent } from './gma-management/gma-stock-categorie/gma-stock-categorie.component';
import { MinOrdineCategorieComponent }  from './gma-management/minordine-categorie/minordine-categorie.component';
import { ImAffComponent } from './im-aff/im-aff.component';
import { DBDialogClinicaFormComponent } from './datibase/dialog/db-dialog-clinica-form.component';

import { DBDialogClinicaImportComponent } from './datibase/dialog/db-dialog-clinica-import.component';
import { IncentiveComponent } from './incentive/incentive.component';
import { TargetComponent } from './incentive/target/target.component';
import { BonusComponent } from './incentive/bonus/bonus.component';
import { AvanzamentiComponent } from './incentive/avanzamenti/avanzamenti.component';
import { MonthPickerComponent } from './month-picker/month-picker.component';
import { TargetImportComponent } from './incentive/dialog/target-import.component';
import { Target2ImportComponent } from './incentive/dialog/target2-import.component';
import { Target3ImportComponent } from './incentive/dialog/target3-import.component';
import { SsoMonComponent } from './sso-mon/sso-mon.component';
import { ChartsModule } from 'ng2-charts/ng2-charts';

/*
La classe 'HammerConfig' estende e sovrascrive HammerGestureConfig.
Su Angular le gesture touch di Hammer.js sono disabilitate di default ,ma la libreria viene comunque importata e fa parte dei core packages.
Per abilitare tali gesture (Pan, Pinch, Press, Rotate, Swipe e Tap) specificare nell' oggetto overrides della classe HammeConfig
il tipo di evento da abilitare (http://hammerjs.github.io/recognizer-swipe/ qui si trovano sotto la categoria recognizers).
In questo modo saranno disponibili in qualsiasi parte dell'applicazione in forma di eventEmitter, senza bisogno di importare nessun modulo:

<div ([tipo evento][direzione])="[metodo da applicare all'emissione dell'evento]"></div>
*/

@Injectable()
export class HammerConfig extends HammerGestureConfig {

  overrides = {
    'swipe': {
      direction: hammer.DIRECTION_ALL
    }
  }
}

// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http, './assets/i18n/', '.json');
// }

export const tooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 1000,
  hideDelay: 200,
  touchendHideDelay: 1000,
};

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

@NgModule({
  declarations: [
    AppComponent,
    CallApiComponent,
    HeaderComponent,
    FooterComponent,
    StartPageComponent,
    UserWidgetComponent,
    ClockComponent,
    //ConverterComponent,
    DialogLoginRequiredComponent,
    DialogConsentsComponent,
    DialogMessageBroadcastComponent,
    SettingsCardComponent,
    DialogToggleComponent,
    DataIdentityListComponent,
    DialogCustomFieldComponent,
    MenuItemComponent,
    ArrayToTextPipe,
    AddressPipe,
    GoliFormatPipe,
    AlertDialogComponent,
    LoaderComponent,
    PageManagementComponent,
    AssignProfileComponent,
    ColWidthPipe,
    AddProductStoreDialogComponent,
    SortPipe,
    StoreFormatPipe,
    PickerFilterPipe,
    BlobToImagePipe,
    ImageDialogComponent,
    LoaderComponent,
    LoadersComponent,
    LengthColumnsPipe,
    PaginationPipe,
    DatatableComponent,
    PaginationComponent,
    FormComponent,
    DisableControlDirective,
    FormHeaderComponent,
    DialogDataComponent,
    ConfirmDialogComponent,
    ErrorComponent,
    OrderManagementComponent,
    ForecastComponent,
    MngforecastComponent,
    CarotaggioComponent,
    AddProductStoreDialogComponent,
    BackOrderDialogComponent,
    BackOrderMaxThresholdDialogComponent,
    YesNoDialogComponent,
    YesNoDialogComponentS,
    OrderDetailDialogComponent,
    OrderDetailDownloadDialogComponent,
    MessagesDialogComponent,
    MessagesDialogComponentS,
    NewMessageDialogComponent,
    NotificationComponent,
    TabindexDirective,
    SecureDomPipe,
    GroupbyPipe,
    ActionComponent,
    CheckpresetPipe,
    // ProductsPipe,
    FormFieldChipComponent,
    Variant2Dialog,
    FormFieldListComponent,
    RightSideComponent,
    BasketComponent,
    Variant2Dialog,
    LayoutDialog,
    YesNoDialog,
    LanguageDialog,
    UpperCropped,
    ChangelogDialog,
    OperPauseComponent,
    SettingsDialog,
    ConcessionComponent,
    InputDialogComponent,
    InputNumberComponent,
    CapitalizePipe,
    TextWithoutSpecialCharsDirective,
    TextUpperCaseDirective,
    MessagesMngmtComponent,
    DraggableToSchedulerDirective,
    SuppliersComponent,
    OrderReceivedComponent,
    OrderInvoiceableComponent,
    InvoicesComponent,
    FormFieldSelectSearchComponent,
    GceManagementComponent,
    GcePersonalDataComponent,
    GceReportsComponent,
    GceBadgeComponent,
    GceDialogCompensationComponent,
    GceDialogClinicAssociationComponent,
    GceCalendarComponent,
    GceDialogBadgeFormComponent,
    GceInvoicesComponent,
    GceRiconciliationComponent,
    GceDialogInvoiceFormComponent,
    GceDialogCalculateBillingComponent,
    ConditionalFormattingDirective,
    GceDialogAnagraphicComponent,
    GceDialogPerformanceCalculationDetailComponent,
    GmaManagementComponent,
    GmaStockCategorieComponent,
    MinOrdineCategorieComponent,
    DatibaseComponent,
    ClinichedatabaseComponent,
    ImAffComponent,
    DBDialogClinicaFormComponent,
    DBDialogClinicaImportComponent,
    TargetImportComponent,
    Target2ImportComponent,
    Target3ImportComponent,
    IncentiveComponent,
    TargetComponent,
    BonusComponent,
    AvanzamentiComponent,
    MonthPickerComponent,
    SsoMonComponent
  ],
  imports: [
    AppRoutingModule,
    NgxCurrencyModule,
    BrowserAnimationsModule,
    BrowserModule,
    FlexLayoutModule,
    HttpClientModule,
    NgPipesModule,
    ReactiveFormsModule,
    OAuthModule.forRoot(),
    MatButtonModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatIconModule,
    MatToolbarModule,
    MatListModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    MatTableModule,
    MatMenuModule,
    MatSliderModule,
    CdkTableModule,
    MatPaginatorModule,
    //SlickModule.forRoot(),
    MatSelectModule,
    MatProgressBarModule,
    MatChipsModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatRadioModule,
    MatDividerModule,
    MatDialogModule,
    MatTreeModule,
    MatSortModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAD8BjWK0_D99WXF7Tt2ZnrDu6NhnJLYsE',
      libraries: ['places']
    }),
    NgxDatatableModule,
    SimpleNotificationsModule.forRoot(),
    MatStepperModule,
    InfiniteScrollModule,
    DayPilotModule,
    ColorPickerModule,
    QrCodeModule,
    DragDropModule,
    ConcessionSystemModule,
    SharedModule,
    ChartsModule
  ],
  providers: [AuthGuardService, ClockService, //ConverterComponent,
    ForecastsService, SuppliersService, CartService,
    WebsocketConcessionService, PosConcessionService, BasketService, PrinterService,
    ApiProviderWinticplusService, UserService, AlertDialogService, LoaderService, WindowsRefService, LoadersComponent,
    DevicemanagerService, WebsocketService, MessageMngtService, ExcelService,ClinichedatabaseService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpMockRequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CachingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }, MyHttpCache, ControllersService, ActionsService, ScheduleTimeService,
    {
      provide: LOCALE_ID, useValue: 'it-IT'
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'it-IT'
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig
    },
    UrlHelperService,
    PrinterService,
    TableService,
    BasketService,
    YesNoService,
    PauseService,
    PreferencesService,
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: tooltipDefaults
    },
    DataRepositoryService,
    CommonUtilsService,
    PermissionService,
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform]
    },
    GceManagementService,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AddProductStoreDialogComponent,
    Variant2Dialog,
    DialogLoginRequiredComponent,
    DialogToggleComponent,
    DialogCustomFieldComponent,
    DialogConsentsComponent,
    DialogMessageBroadcastComponent,
    BackOrderDialogComponent,
    BackOrderMaxThresholdDialogComponent,
    AlertDialogComponent,
    AssignProfileComponent,
    ImageDialogComponent,
    DialogDataComponent,
    ConfirmDialogComponent,
    YesNoDialogComponent,
    YesNoDialogComponentS,
    OrderDetailDialogComponent,
    OrderDetailDownloadDialogComponent,
    MessagesDialogComponent,
    MessagesDialogComponentS,
    NewMessageDialogComponent,
    InputDialogComponent,
    GceDialogCompensationComponent,
    GceDialogClinicAssociationComponent,
    GceDialogBadgeFormComponent,
    GceDialogInvoiceFormComponent,
    GceDialogCalculateBillingComponent,
    GceDialogAnagraphicComponent,
    GceDialogPerformanceCalculationDetailComponent,
    DBDialogClinicaFormComponent,
    DBDialogClinicaImportComponent,
    TargetImportComponent,
    Target2ImportComponent,
    Target3ImportComponent,
    MonthPickerComponent
  ]
})
export class AppModule { }
