import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import * as searchProducts from '../../../http_mockup_data/search_products.json';
import { environment } from '../../environments/environment';

const urls = [
    // {
    //     url: environment.SearchProduct,
    //     json: searchProducts
    // }
];

@Injectable()
export class HttpMockRequestInterceptor implements HttpInterceptor {
    constructor(private injector: Injector) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        for (const element of urls) {
            if (request.url === element.url) {
                return of(new HttpResponse({ status: 200, body: ((element.json) as any).default }));
            }
        }
        return next.handle(request);
    }
}
