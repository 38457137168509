import { Component, OnInit, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { UserService, ControllersService, ActionsService, DataRepositoryService } from '../services';
import { webTicMenu, webTicUser, webTicUserData, webTicUserProfiles, webTicUserOrder } from '../app.model';
import { Router, NavigationEnd } from '@angular/router';
import { LoaderService } from '../loader';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  menuItems: webTicMenu[] = [];
  labelCode: any = "";
  currentController: any = null;
  clientId: string;
  currentMenuItem: webTicMenu = undefined;

  constructor(public userService: UserService,
    private controllersService: ControllersService,
    private actionsService: ActionsService,
    private route: Router,
    private loaderService: LoaderService,
    public dataRepo: DataRepositoryService
  ) {
    this.clientId = environment['clientId'].toLowerCase();
  }

  @ViewChildren('allMenuItems') allMenuItems: QueryList<any>;

  ngAfterViewInit() {
    this.allMenuItems.changes.subscribe(t => {
      if (t['_results'].length > 0) {
        console.log("allMenuItems.changes() %o",t);
        //this.loaderService.hide();
      }
    });
  }

  setMenuItems() {

    let self = this;
    self.menuItems = [];
    const path = self.route.url.split("/")[1];
    // console.log('SET MENU ITEMS on path %o',path);
    this.controllersService.application.subscribe(application => {
      self.labelCode = application.labelCode;
    });

    // if(!path.startsWith('pm') && !path.startsWith('counter')){
    if (!path.startsWith('pm')) {
      // console.log('NO START PM ---- ');
      this.controllersService.controllers.subscribe(controllers => {
        // console.log('CONTROLLERS %o',controllers);
        this.currentController = null;
        self.menuItems = [];
        controllers.forEach(controller => {
          // let path = (controller.path==='counter'?'':'pm/')+controller.path;
          let path = (!(controller.path.indexOf('webpos') >= 0) ? 'pm/' : '') + controller.path;

          self.menuItems.push(new webTicMenu(
            undefined,
            controller.hcolor,
            controller.labelCode,
            controller.logoPath,
            path,
            true
          ));
        });
      });

    } else {
      // console.log('START PM ---- ');
      this.controllersService.currentController.subscribe(currentController => {
        //console.log("currentController header %o path %o on %o", currentController,currentController.path,self.route.url);
        if (currentController.path && (self.route.url.indexOf(currentController.path.toLowerCase().replace('/','')) >= 0 ) ) {
          this.currentController = currentController;
          self.labelCode = currentController.labelCode;
          if (!currentController.path.startsWith("counter") &&
            !currentController.path.startsWith("calendar") &&
            !currentController.path.startsWith("store") &&
            !currentController.path.startsWith("concession") &&
            !currentController.path.startsWith("suppliers") &&
            !currentController.path.startsWith("mail")&&
            !currentController.path.startsWith("sso-mon")) {
            this.actionsService.getActions(currentController).subscribe(actions => {
              console.log("-------------------------actions %o", actions);
              self.menuItems = [];
              actions.forEach(action => {
                self.menuItems.push(new webTicMenu(
                  undefined,
                  action.hcolor,
                  action.labelCode,
                  action.image,
                  action.path,
                  false,
                  action
                ));
              });
              // console.log("menuItems c %o", self.menuItems);

              this.actionsService.currentAction.subscribe(currentAction => {
                console.log("-------------------------------currentAction %o", currentAction);
                self.currentMenuItem = self.menuItems.find(mi => mi.actionObject.id === currentAction.id);
              });
            });
          }
        }
      });
    }
  }

  ngOnInit() {
    let self = this;
    this.allMenuItems = new QueryList<any>();

    this.route.events.subscribe((event) => {
      // console.log("ngOnInit route events %o %o", event, event.constructor === NavigationEnd);
      if (event.constructor === NavigationEnd) {

        self.currentController = null;
        self.setMenuItems();
      }
    });
  }

  onSelectMenu(menuItem) {
    window.location.hash = "/" + menuItem.action;
  }

  onSelectedMenu(menuItem: webTicMenu) {
    // console.log("onSelectedMenu %", menuItem);
  }
}
