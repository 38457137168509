import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDatepicker, MatDatepickerInputEvent } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { MessageMngtService } from '../../../services/messagemngt.service';
import { AuthGuardService } from '../../../services/auth-guard.service';
import { TableService } from '../services/table.service';
import * as MySettings from '../../../../environments/environment';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'new-msg-dialog',
  templateUrl: './newmsgs.dialog.html'
})
export class NewMessageDialogComponent implements OnInit {

  dom;
  pro;
  usr;
  messaggio; titolo; profilo; dominio;
  step2 = false;
  selectedDomains = [];

  constructor(
    public _authService: AuthGuardService,
    public dialogRef: MatDialogRef<NewMessageDialogComponent>,
    private _http: HttpClient,
    private _tableService: TableService,
    private _notifications: NotificationsService,
    private _messageMngtService: MessageMngtService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    //this.getAllData();
    console.log('data from dialog %o', data);
    this.dom = data.dom;
    this.pro = data.pro;
    this.usr = data.usr;
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  getClinicId(s: any) {
    console.log('clinic id filtered %o', s.substring(0, 3));
    return s.substring(0, 3);
  }

  start; end;
  post() {
    if (!this.step2) {
      if (this.messaggio == undefined || this.titolo == undefined || this.messaggio == '' || this.titolo == '' || this._input.nativeElement.value == undefined || this._input2.nativeElement.value == undefined || this._input.nativeElement.value == "" || this._input2.nativeElement.value == "") {
        this._notifications.error(
          "Errore",
          "Compila tutti i campi per passare al passaggio successivo.",
          MySettings.environment.notificationOptions
        );
        this.step2 = false;
        return;
      }

      this.start = this._input.nativeElement.value;
      this.end = this._input2.nativeElement.value;

      var x = this.start.split("/");
      //this.start = new Date(x[2] + "-" + (x[1] < 10 ? "0" + x[1] : x[1]) + "-" + (x[0] < 10 ? "0" + x[0] : x[0]));
      this.start = new Date(x[2], (x[1] - 1), x[0]);
      x = this.end.split("/");
      //this.end = new Date(x[2] + "-" + (x[1] < 10 ? "0" + x[1] : x[1]) + "-" + (x[0] < 10 ? "0" + x[0] : x[0]));
      this.end = new Date(x[2], (x[1] - 1), x[0], 23, 59, 59);

      this.step2 = true;
    } else {
      if (this.selectedDomains.length == 0) {
        this._notifications.error(
          "Errore",
          "Compila tutti i campi per inserire il messaggio.",
          MySettings.environment.notificationOptions
        );
        return;
      }

      var do1 = [];
      for (let x of this.selectedDomains) {
        console.log('post SINGLE CLINIC_DOMAIN %o', x);
        do1.push({
          "IDAMCLINIC": this.getClinicId(x.n),
          "IDCLINIC": x.d,
          "ID_MESSAGE": 0,
          "IDPROFILE": x.p,
          "ID": 0
        });
      }

      let payloadPost = {
        "ID_MESSAGE": 0,
        "SENDER": this.usr.name,
        "SUBJECT": this.titolo,
        "DESCRIPTION": this.messaggio,
        "STARTDATE": this.start,
        "ENDDATE": this.end,
        "Message_Clinics": do1,
        "ISACTIVE": 1
      };
      console.log('post %o with %o', MySettings.environment.PostMessage, payloadPost);
      this._http.post(
        MySettings.environment.PostMessage,
        payloadPost
      )
        .map((res) => {
          console.log('ESITO a %o', res);
          if (res['esito']) {
            this._notifications.success(
              "Conferma",
              "Messaggi programmati con successo.",
              MySettings.environment.notificationOptions
            );
            this._messageMngtService.getAllMessages().subscribe((res) => {
              this._messageMngtService.messaggesRows = res;
            });
            this.close();
            return;
          } else {
            this._notifications.error(
              "Errore",
              "Errore durante l'inserimento dei messaggi.",
              MySettings.environment.notificationOptions
            );
            return;
          }
        })
        .subscribe(val => []);
    }
  }

  intid = 1;
  aggiungi() {
    if (this.profilo == undefined || this.profilo == "" || this.profilo == "-1") {
      this._notifications.error(
        "Errore",
        "Compila tutti i campi per inserire il messaggio.",
        MySettings.environment.notificationOptions
      );
      return;
    }
    console.log('selectedDomain %o', this.dominio);

    for (let x of this.selectedDomains) {
      for (let y of this.dominio) {
        if (x.p == this.profilo && x.d == y) {
          this._notifications.warn(
            "Attenzione",
            "La clinica e il profilo sono già state selezionate.",
            MySettings.environment.notificationOptions
          );
          return;
        }
      }
    }

    for (let y of this.dominio) {

      if (y > 0)
        this.selectedDomains.push({ id: this.intid++, p: this.profilo, d: y, n: this.getName(this.profilo, y) });
      else
        this.selectedDomains.push({ id: this.intid++, p: this.profilo, d: "0", n: this.getName(this.profilo, 0) });

    }

    this.profilo = "-1";
    this.dominio = ["-1"];
  }

  elimina(id) {
    var pos = 0;
    for (let c of this.selectedDomains) {
      if (c.id == id) {
        break;
      }
      pos++;
    }

    this.selectedDomains.splice(pos, 1);
  }

  getHeigth()
  {
    return('300px');
  }

  getName(p, d) {
    //console.log('get Name with p %o d %o', p, d);
    var str = '';
    if (d == 0) {
      str = 'Tutte le cliniche';
    }
    else {
      for (let x of this.dom)
        if (x.idDomain == d) {
          str = x.name;
          break;
        }
    }
    for (let x of this.pro)
      if (x.idProfile == p) {
        str += " (" + x.name + ")";
        return str;
      }
  }

  @ViewChild('mi1', {static:false}) _input: ElementRef;
  @ViewChild('mi2', {static:false}) _input2: ElementRef;
  _openCalendar(picker: MatDatepicker<Date>, x = null) {
    picker.open();
    setTimeout(() => x == null || x == 1 ? this._input.nativeElement.focus() : this._input2.nativeElement.focus());
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>, da = 1) {

  }

}
