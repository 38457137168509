import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { LoaderState } from './loader-state';
import { LoaderService } from './loader.service';


@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  show = false;
  private subscription: Subscription;

  constructor(private loaderService: LoaderService) {

  }

  ngOnInit() {
    this.subscription = this.loaderService.loaderState
    .subscribe((state: LoaderState) => {
       setTimeout(() => this.show = state.show, 0);
    });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
