import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-loaders',
  templateUrl: './loaders.component.html',
  styleUrls: ['./loaders.component.css']
})
export class LoadersComponent implements OnInit {
  showb = false;

  constructor() {
  }

  show() {
    this.showb=true;
  }
  hide() {
    this.showb=false;
  }

  ngOnInit() {
  }
}
