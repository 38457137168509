import { PaymentMethodScontrino } from './payment-method';
import { listaArticoliScontrino } from './add-articolo-resp';

export class CompletePaymentTicket {
    idnegozio;
    GUID;
    web_box;
    web_operator;
    trackid;
    billType;
    sessionId;
    booked;
    idordine;
    listaModalitaPagamento: PaymentMethodScontrino[] = [];
    listaArticoli: listaArticoliScontrino[] = [];
    cliente: Cliente | string;
}

export class Cliente {
    idnaz;
    note;
    pin;
    indirizzo;
    email;
    cellulare;
    fax;
    idvaluta;
    enabled;
    nome;
    cognome;
    cap;
    cod_fisc;
    localita;
    partita_iva;
    rag_soc;
    civico;
    telefono;
    password;
    bangleID;
    prov;
}