import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IncentiveService } from '../../services/incentive.service';
import { AuthGuardService } from '../../services';
import { LoaderService } from '../../loader/loader.service';
import { MatTableDataSource } from '@angular/material/table';
import { AlertDialogService } from '../../services/alert-dialog.service';
import * as moment from 'moment';
import { AfterViewInit, Component, ViewChild, OnInit } from '@angular/core';
import { GceManagementService } from '../../services/gce-management.service';
import { ExcelService } from '../../services/excel.service';
import { MatSort, MatPaginator } from '@angular/material';
const dateFormatDate = "YYYY-MM-DD";
import { FormHeaderComponent, ICustomButton, IFormHeaderSearch, FormHeaderSearchEventsEnum } from '../../shared/form-header/form-header.component';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Rx';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Observer, merge } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataRowOutlet } from '@angular/cdk/table';

@Component({
  selector: 'app-avanzamenti',
  templateUrl: './avanzamenti.component.html',
  styleUrls: ['./avanzamenti.component.css', '../incentive.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])]
})
export class AvanzamentiComponent implements OnInit {

  displayedColumns = ['CodClinica', 'NomeClinica', 'Mese', 'totaleTarget', 'ConsFatturato', 'FattImp', 'EntrateSicureEvoDent', 'EntrateSicure', 'TotDilazioniDaInserire', 'TotDilazioniDaLiquidare', 'TotPresunto', 'ConsProduzione', 'ConsCheckUp', 'UltimoCalcoloDt', 'UltimoNumeroFattura', 'openAction', 'exportExcel', 'produzioni', 'pagamenti', 'appuntamenti'];
  displayedColumnsSlave = ['IdProfilo', 'Login', 'IdOperatore', 'TargetFatturato', 'ConsFatturatoOperatore', 'PrevisioneOperatore', 'PianiPagamentoOperatore', 'EntrateSicure', 'DilazioniDaInserire', 'DilazioniDaLiquidare', 'ForecastFatturatoOperatore', 'PreventivatoMassimoOperatore', 'PreventivatoTotaleOperatore', 'PreventivoPrimaAccettazioneOperatore', 'PreventivoVariazioniSuccessiveOperatore', 'ModificaDt', 'delete'];
  displayedColumnsAdditionalInfosAppuntamenti = ['AppuntamentiConfermatiDaOggi', 'AppuntamentiConfermatiFinoGiornoPrecedente', 'AppuntamentiConfermatiVicinanza', 'AppuntamentiDaConfermareDaOggi', 'AppuntamentiDaConfermareFinoGiornoPrecedente', 'AppuntamentiDaConfermareVicinanza'];
  displayedColumnsAdditionalInfosPreventivato = ['PreventivatoMassimo', 'PreventivatoTotale', 'PreventivoPrimaAccettazione', 'PreventivoVariazioniSuccessive'];
  displayedColumnsAdditionalInfosPreventivatoAppuntamenti = ['ProduzioneAppuntamentiConfermatiDaEseguire', 'ProduzioneAppuntamentiConfermatiNonEseguiti', 'ProduzioneAppuntamentiDaConfermareDaEseguire', 'ProduzioneAppuntamentiDaConfermareNonEseguiti'];
  dataSource: any;
  dataSourceSlave: any;
  columns: any[] = [];
  detracts: any;
  detractsBck: any;
  limitRows: number;
  heightMainList: number;
  currentRow: any;
  editing: boolean = false;
  keyColumn: any = {};
  customButtons: ICustomButton[] = [];
  role: string = '';
  dm: string = '';
  isAdmin: boolean = false;
  isAM: boolean = false;
  isDM: boolean = false;
  isCM: boolean = false;
  isViewer: boolean = true;
  fromDate: any;
  toDate: any;
  showb: boolean;
  usr: any;
  kwSearch: any;
  expandedElement: any;
  oldSelectedElement: any;
  expandedElement2: any;
  myTrackById: any;
  isReadOnly: boolean = false;

  @ViewChild(MatSort, null) sort: MatSort;
  @ViewChild(MatPaginator, null) paginator: MatPaginator;
  constructor(
    private incentiveService: IncentiveService,
    private alertDialog: AlertDialogService,
    private auth: AuthGuardService,
    private loaderService: LoaderService,
    private http: HttpClient,
    private gceService: GceManagementService,
    private excelService: ExcelService,
    private fb: FormBuilder) {
    this.fromDate = new Date();
    this.toDate = new Date();
    // this.dataSourceSlave = new MatTableDataSource();
    this.dataSource = new MatTableDataSource();
  }

  onSearch(e) {
    this.dataSource.filter = e.trim().toLowerCase();
  }

  clickD(row) {
    console.log('row %o', row);
  }


  clickPP(element) {
    console.log('clickPP');
    this.expandedElement = this.expandedElement === element ? null : element;

    if (this.oldSelectedElement != null && this.oldSelectedElement != element)
      this.oldSelectedElement.selected = false;

    if (element.selected == false) {
      element.selected = true;
      element.touched = true;
    }
    else {
      element.selected = false;

    }
    this.oldSelectedElement = element;
  }

  del(element2) {
    console.log('delete %o', element2);
    if (!(this.isAdmin == true)) {
      window.alert('Operazione non consentita.')
      return false;
    }


    if (window.confirm("Sei sicuro di voler cancellare la riga relativa all'utente " + element2.Login + " clinica " + element2.CodClinica + "?")) {
      let py = { RigaId: element2.RigaId };
      this.incentiveService.deletePrevisione(py).subscribe(
        x => {
          window.alert('Operazione effettuata. Ricarica la pagina per visualizzare le modifiche.');
        }
      );

    }
  }

  exportExcel(element: any) {
    console.log('export Excel for %o %o', element, element.Mese.replaceAll('-', ''));
    //check sessionStorage
    if (!(this.isAdmin == true) && !(this.isAM == true) && !(this.isDM == true)) {
      let le = sessionStorage.getItem('lastextractionA');
      console.log('sessionStorage %o', le);
      if (!(le == null)) {
        let delta = Date.now() - Number(le);
        if (delta < 1800000) {
          window.alert('Attenzione. E\' necessario intervallare le estrazioni di almeno trenta minuti');
          return;
        }
        else
          sessionStorage.setItem('lastextractionA', Date.now().toString());
      }
      else {
        sessionStorage.setItem('lastextractionA', Date.now().toString());
      }
    }
    this.loaderService.show();
    let pyld = {
      idClinica: element.CodClinica,
      month: element.Mese.replaceAll('-', '').substring(0, 8)
    }
    this.incentiveService.getFatture(pyld).subscribe(fts => {
      this.loaderService.hide();
      this.excelService.exportInvoices(fts, 'fatture_' + element.CodClinica + '_' + element.Mese.replace('-', '').substring(0, 8) + '.xsl');
      //console.log('fts of %o %o',pyld,fts);
    }, error => {
      this.loaderService.hide();
      window.alert('Errore export fatture. ' + error['message']);
    })

  }

  exportExcelP(element: any) {
    console.log('export Excel for %o %o', element, element.Mese.replaceAll('-', ''));
    if (!(this.isAdmin == true) && !(this.isAM == true) && !(this.isDM == true)) {
      let le = sessionStorage.getItem('lastextractionB');
      console.log('sessionStorage %o', le);
      if (!(le == null)) {
        let delta = Date.now() - Number(le);
        if (delta < 1800000) {
          window.alert('Attenzione. E\' necessario intervallare le estrazioni di almeno trenta minuti');
          return;
        }
        else
          sessionStorage.setItem('lastextractionB', Date.now().toString());
      }
      else {
        sessionStorage.setItem('lastextractionB', Date.now().toString());
      }
    }
    this.loaderService.show();
    let pyld = {
      idClinica: element.CodClinica,
      month: element.Mese.replaceAll('-', '').substring(0, 8)
    }
    this.incentiveService.getProduzioni(pyld).subscribe(fts => {
      this.loaderService.hide();
      this.excelService.exportProduzioni(fts, 'produzioni_' + element.CodClinica + '_' + element.Mese.replace('-', '').substring(0, 8) + '.xsl');
      //console.log('fts of %o %o',pyld,fts);
    }, error => {
      this.loaderService.hide();
      window.alert('Errore export fatture. ' + error['message']);
    })

  }

  exportExcelAppuntamenti(element: any) {
    console.log('export Excel for %o %o', element, element.Mese.replaceAll('-', ''));
    if (!(this.isAdmin == true) && !(this.isAM == true) && !(this.isDM == true)) {
      let le = sessionStorage.getItem('lastextractionD');
      console.log('sessionStorage %o', le);
      if (!(le == null)) {
        let delta = Date.now() - Number(le);
        if (delta < 1800000) {
          window.alert('Attenzione. E\' necessario intervallare le estrazioni di almeno trenta minuti');
          return;
        }
        else
          sessionStorage.setItem('lastextractionD', Date.now().toString());
      }
      else {
        sessionStorage.setItem('lastextractionD', Date.now().toString());
      }
    }
    this.loaderService.show();
    let d = new Date(Date.now());
    let dy = d.getDate().toString();
    let m = (d.getMonth() + 1).toString();
    if (dy.length == 1)
      dy = '0' + dy;
    if (m.length == 1)
      m = '0' + m;
    let pyld = {
      idClinica: element.CodClinica,
      month: d.getFullYear().toString() + m + dy//element.Mese.replaceAll('-', '').substring(0, 8)
    }
    this.incentiveService.getAppPassati(pyld).subscribe(fts => {
      this.loaderService.hide();
      this.excelService.exportAppuntamenti(fts, 'app_passati_' + element.CodClinica + '_' + d.getFullYear().toString() + m + dy + '.xsl');
    }, error => {
      this.loaderService.hide();
      window.alert('Errore export appuntamenti. ' + error['message']);
    })

  }


  exportExcelPianoPagamenti(element: any) {
    console.log('export Excel for %o %o', element, element.Mese.replaceAll('-', ''));
    if (!(this.isAdmin == true) && !(this.isAM == true) && !(this.isDM == true)) {
      let le = sessionStorage.getItem('lastextractionC');
      console.log('sessionStorage %o', le);
      if (!(le == null)) {
        let delta = Date.now() - Number(le);
        if (delta < 1800000) {
          window.alert('Attenzione. E\' necessario intervallare le estrazioni di almeno trenta minuti');
          return;
        }
        else
          sessionStorage.setItem('lastextractionC', Date.now().toString());
      }
      else {
        sessionStorage.setItem('lastextractionC', Date.now().toString());
      }
    }
    this.loaderService.show();
    let pyld = {
      idClinica: element.CodClinica,
      month: element.Mese.replaceAll('-', '').substring(0, 8)
    }
    this.incentiveService.getPianoPagamenti(pyld).subscribe(fts => {
      this.loaderService.hide();
      this.excelService.exportPianiPagamento(fts, 'pagamenti_' + element.CodClinica + '_' + element.Mese.replace('-', '').substring(0, 8) + '.xsl');
    }, error => {
      this.loaderService.hide();
      window.alert('Errore export panipagemento. ' + error['message']);
    })

  }

  changeOperatorRow(e: any, field: any, total: any) {
    // console.log('changeOperatorRow e %o field %o total %o', e, field, total);
    let totalPersons = 0;
    for (let a = 0; a < total.persons.length; a++) {
      if (total.persons[a].PrevisioneOperatore > 0 || total.persons[a].PrevisioneOperatore < 0)
        totalPersons = totalPersons + total.persons[a].PrevisioneOperatore;
    }

    if (totalPersons > total.ConsFatturato)
      total.valid = false;
    else
      total.valid = true;
  }

  changeSicurRow(e: any, field: any, total: any) {
    console.log('changeSicurRow e %o field %o total %o', e, field, total);
    let totaleEntrateSicureManuale = 0;
    let totaleEntrateSicureEvoDent = 0;
    for (let a = 0; a < total.persons.length; a++) {

      if (total.persons[a].PianiPagamentoOperatore > 0 || total.persons[a].PianiPagamentoOperatore < 0)
        totaleEntrateSicureEvoDent = totaleEntrateSicureEvoDent + total.persons[a].PianiPagamentoOperatore;
      if (total.persons[a].EntrateSicure > 0 || total.persons[a].EntrateSicure < 0)
        totaleEntrateSicureManuale = totaleEntrateSicureManuale + total.persons[a].EntrateSicure;

    }

    if (totaleEntrateSicureManuale > totaleEntrateSicureEvoDent)
      total.sicure = false;
    else
      total.sicure = true;
  }


  GetConsFatturatoExTutor(element: any) {
    let ret = element.ConsFatturato;

    for (let a = 0; a < element.persons.length; a++) {
      if (element.persons[a].IdOperatore > 0)
        ret = ret - element.persons[a].ConsFatturatoOperatore;
    }
    //console.log('confat %o',element)
    return ret;
  }

  export() {
    console.log('export %o', this.dataSource.data);
    let datasMaster: any[] = [];
    let datasSlave: any[] = [];
    this.dataSource.data.forEach(
      e => {
        let tp = e;
        e.persons.forEach(p => {
          datasSlave.push(p);
        });
        e.persons = null;
        datasMaster.push(e);
      });
    this.excelService.exportAsExcelFile2Sections(datasMaster, datasSlave, 'incentive_avanzamenti_' + this.fromDate.toLocaleString('default', { month: 'long' }));
  }

  init(from, to) {
    this.showb = true;
    let py = {
      from: from,
      to: to,
      desc_domain: this.dm,
      profile: this.role,
      usr: this.usr
    };

    console.log('fromDate %o toDate %o service %o', this.fromDate, this.toDate, this.gceService);
    this.http.post(`${environment.gceAPI}/incentive_all_risultato/`, py, { responseType: 'json' }).subscribe(res => {
      // this.http.post(`http://localhost:57526/api/incentive_all_risultato/`, py, { responseType: 'json' }).subscribe(res => {
      this.showb = false;
      //this.allData = res;
      console.log('response for getAvanzamento %o', res);
      let myDt = [];
      res['incentive_all_risultato_s'].forEach(element => {
        var toPush = element['incentive_risultato_clinica'];
        toPush.touched = false;
        toPush.valid = true;
        toPush.sicure = true;
        toPush.persons = element['incentive_risultato_operatores'];
        let totalPersons = 0;
        let totaleEntrateSicureEvoDent = 0;
        let totaleEntrateSicureManuale = 0;
        let totaleTarget = 0;
        let FattImp = 0;
        let EntrateSicureEvoDent = 0;
        let EntrateSicure = 0;
        let TotDilazioniDaInserire = 0;
        let TotDilazioniDaLiquidare = 0;
        let TotPresunto = 0;
        let TotPreventivatoMassimoOperatore = 0;

        let TotPreventivatoTotaleOperatore = 0;

        let TotPreventivoPrimaAccettazioneOperatore = 0;

        let TotPreventivoVariazioniSuccessiveOperatore = 0;

        for (let a = 0; a < toPush.persons.length; a++) {
          if (toPush.persons[a].PrevisioneOperatore > 0 || toPush.persons[a].PrevisioneOperatore < 0)
            totalPersons = totalPersons + toPush.persons[a].PrevisioneOperatore;
          if (toPush.persons[a].PianiPagamentoOperatore > 0 || toPush.persons[a].PianiPagamentoOperatore < 0)
            totaleEntrateSicureEvoDent = totaleEntrateSicureEvoDent + toPush.persons[a].PianiPagamentoOperatore;
          if (toPush.persons[a].EntrateSicure > 0 || toPush.persons[a].EntrateSicure < 0)
            totaleEntrateSicureManuale = totaleEntrateSicureManuale + toPush.persons[a].EntrateSicure;
          if (toPush.persons[a].TargetOperatore > 0 || toPush.persons[a].TargetOperatore < 0)
            totaleTarget = totaleTarget + toPush.persons[a].TargetOperatore;
          if (toPush.persons[a].PrevisioneOperatore > 0 || toPush.persons[a].PrevisioneOperatore < 0)
            FattImp = FattImp + toPush.persons[a].PrevisioneOperatore;
          if (toPush.persons[a].PianiPagamentoOperatore > 0 || toPush.persons[a].PianiPagamentoOperatore < 0)
            EntrateSicureEvoDent = EntrateSicureEvoDent + toPush.persons[a].PianiPagamentoOperatore;
          if (toPush.persons[a].EntrateSicure > 0 || toPush.persons[a].EntrateSicure < 0)
            EntrateSicure = EntrateSicure + toPush.persons[a].EntrateSicure;
          if (toPush.persons[a].DilazioniDaInserire > 0 || toPush.persons[a].DilazioniDaInserire < 0)
            TotDilazioniDaInserire = TotDilazioniDaInserire + toPush.persons[a].DilazioniDaInserire;
          if (toPush.persons[a].DilazioniDaLiquidare > 0 || toPush.persons[a].DilazioniDaLiquidare < 0)
            TotDilazioniDaLiquidare = TotDilazioniDaLiquidare + toPush.persons[a].DilazioniDaLiquidare;
          if (toPush.persons[a].Login == 'Ex Tutor') {
            let totPiani = 0;
            for (let b = 0; b < toPush.persons.length; b++) {
              if (!(toPush.persons[b].Login == 'Ex Tutor')) {
                if (toPush.persons[b].PreventivatoMassimoOperatore > 0 || toPush.persons[b].PreventivatoMassimoOperatore < 0)
                  TotPreventivatoMassimoOperatore = TotPreventivatoMassimoOperatore + toPush.persons[b].PreventivatoMassimoOperatore;
                if (toPush.persons[b].PreventivatoTotaleOperatore > 0 || toPush.persons[b].PreventivatoTotaleOperatore < 0)
                  TotPreventivatoTotaleOperatore = TotPreventivatoTotaleOperatore + toPush.persons[b].PreventivatoTotaleOperatore;
                if (toPush.persons[b].PreventivoPrimaAccettazioneOperatore > 0 || toPush.persons[b].PreventivoPrimaAccettazioneOperatore < 0)
                  TotPreventivoPrimaAccettazioneOperatore = TotPreventivoPrimaAccettazioneOperatore + toPush.persons[b].PreventivoPrimaAccettazioneOperatore;
                if (toPush.persons[b].PreventivoVariazioniSuccessiveOperatore > 0 || toPush.persons[b].PreventivoVariazioniSuccessiveOperatore < 0)
                  TotPreventivoVariazioniSuccessiveOperatore = TotPreventivoVariazioniSuccessiveOperatore + toPush.persons[b].PreventivoVariazioniSuccessiveOperatore;
                if (toPush.persons[b].PianiPagamentoOperatore > 0 || toPush.persons[b].PianiPagamentoOperatore < 0)
                  totPiani = totPiani + toPush.persons[b].PianiPagamentoOperatore;
              }
            }
            toPush.persons[a].PianiPagamentoOperatore = element['incentive_risultato_clinica']['PianiPagamento'] - totPiani;
            toPush.persons[a].PreventivatoMassimoOperatore = element['incentive_risultato_clinica']['PreventivatoMassimo'] - TotPreventivatoMassimoOperatore;
            toPush.persons[a].PreventivatoTotaleOperatore = element['incentive_risultato_clinica']['PreventivatoTotale'] - TotPreventivatoTotaleOperatore;
            toPush.persons[a].PreventivoPrimaAccettazioneOperatore = element['incentive_risultato_clinica']['PreventivoPrimaAccettazione'] - TotPreventivoPrimaAccettazioneOperatore;
            toPush.persons[a].PreventivoVariazioniSuccessiveOperatore = element['incentive_risultato_clinica']['PreventivoVariazioniSuccessive'] - TotPreventivoVariazioniSuccessiveOperatore;
          }

          TotPresunto = TotPresunto + this.getPresunto(toPush.persons[a]);
        }

        //console.log('control %o', toPush);
        toPush.totaleTarget = totaleTarget;
        toPush.FattImp = FattImp;
        toPush.EntrateSicureEvoDent = EntrateSicureEvoDent;
        toPush.EntrateSicure = EntrateSicure;
        toPush.TotDilazioniDaInserire = TotDilazioniDaInserire;
        toPush.TotDilazioniDaLiquidare = TotDilazioniDaLiquidare;
        toPush.TotPresunto = TotPresunto;

        if (totalPersons > toPush.ConsFatturato)
          toPush.valid = false;
        if (totaleEntrateSicureManuale > totaleEntrateSicureEvoDent)
          toPush.sicure = false;

        myDt.push(toPush);
      });
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.data = myDt;
      var close_date = new Date(this.incentiveService.dataRepoGceEnvironment.ultima_chiusura_incentive);

      if (this.fromDate.getMonth() >= close_date.getMonth() && this.fromDate.getFullYear() == close_date.getFullYear()) {
        this.isReadOnly = false;
      }
      else {
        this.isReadOnly = true;
      }
      this.dataSource.filterPredicate = (data: any, filterValue: string) => {
        console.log('DATA FOR FILTER %o %o', data, filterValue);
        if (data.CodClinica.trim().toLowerCase().indexOf(filterValue) >= 0)
          return true;
        else {
          if (data.NomeClinica.trim().toLowerCase().indexOf(filterValue) >= 0)
            return true;
          else
            return false;
        }
      }, err => {
        console.log('response for getAvanzamento %o', err);
        this.showb = false;
        window.alert('Errore nel recupero delle informazioni dal DB. ' + err['message']);
      }
    });
  }

  save() {
    //if (this.isReadOnly == false) {
    if (this.isViewer == true) {
      window.alert('Il tuo profilo non permette la modifica dei dati.');
    }
    else {
      var ms = "";
      var c = 0;
      var errData = false;

      this.dataSource.data.forEach(
        e => {
          if (e.touched == true) {
            ms = ms + " " + e.CodClinica;
            c++;
          }
          if (e.valid == false || e.sicure == false) {
            errData = true;
          }
        }
      );

      //  if (c > 0 && errData == false) {
      if (c > 0) {
        if (window.confirm("Sei sicuro di voler confermare i dati relativi alle cliniche" + ms + "?")) {
          this.showb = true;
          this.dataSource.data.forEach(
            e => {
              //if (e.touched == true && e.valid == true && e.sicure == true) {
              if (e.touched == true) {
                console.log('save element %o', e);
                //save record in 
                //this.http.post(`http://localhost:57526/api/update_avanzamenti/`, e, { responseType: 'json' }).subscribe(res => {
                this.http.post(`${environment.gceAPI}/update_avanzamenti/`, e, { responseType: 'json' }).subscribe(res => {
                  this.showb = false;
                }, err => {
                  console.log('response for  saveTarget %o', err);
                  this.showb = false;
                  window.alert('Errore nel salvataggio dei dati sul DB. ' + err['message']);
                });
              }
            }
          );
        }
      }
      else {
        if (c == 0)
          window.alert('Nessuna modifica da salvare.');
        else
          window.alert('Attenzione. I dati inseriti contengono errori.');
      }
      //}
      //else {
      //  window.alert('Il mese è chiuso, non è possibile salvare modifiche');
      //}
    }
  }

  fromDateChange(e) {
    //console.log('fromDateChange %o %o %o', e,this.fromDate,moment(this.fromDate));
    this.init(moment(this.fromDate).startOf('month').format('YYYYMMDD'), moment(this.fromDate).endOf('month').format('YYYYMMDD'));
  }

  ngOnInit() {
    //the Date from/to are first day of currentMonth and last day of currentMont

    console.log('AVANZAMENTI -> DATE TO CHECK %o', this.incentiveService.dataRepoGceEnvironment);

    let usr = this.auth.getUser();
    if (usr != null) {
      this.role = usr['ruolo'];
      this.dm = usr['clinica'];
      if (usr['nomeCompleto'] != null && usr['nomeCompleto'].length > 0) {
        this.usr = usr['nomeCompleto'];
      }
      else {
        if (usr['email'] != null && usr['email'].length > 0) {
          this.usr = usr['email'];
        }
      }
    }

    if (this.role != null) {
      if (this.role.toLowerCase().indexOf('amministratore') == 0 || this.role.toLowerCase().indexOf('hr medici') == 0 || this.role.toLowerCase().indexOf('direzione') == 0) {
        this.isAdmin = true;
        this.isAM = false;
        this.isCM = false;
        this.isDM = false;
        this.isViewer = false;
      }
      else if (this.role.toLowerCase().indexOf('area') == 0 && this.role.toLowerCase().indexOf('manager') > 0) {
        this.isAdmin = false;
        this.isAM = true;
        this.isCM = false;
        this.isDM = false;
        this.isViewer = false;
      }
      else if (this.role.toLowerCase().indexOf('district') == 0 && this.role.toLowerCase().indexOf('manager') > 0) {
        this.isAdmin = false;
        this.isAM = false;
        this.isCM = false;
        this.isDM = true;
        this.isViewer = false;
      }
      else if ((this.role.toLowerCase().indexOf('clinic') == 0 && this.role.toLowerCase().indexOf('manager') > 0)||(this.role.toLowerCase().indexOf('tutor') == 0||this.role.toLowerCase().indexOf('tutor') > 0)) {
        this.isAdmin = false;
        this.isAM = false;
        this.isCM = true;
        this.isDM = false;
        this.isViewer = false;
      }
      else {
        this.isAdmin = false;
        this.isAM = false;
        this.isCM = false;
        this.isDM = false;
        this.isViewer = true;
      }
    }
    else {
      this.isAdmin = false;
      this.isAM = false;
      this.isCM = false;
      this.isDM = false;
      this.isViewer = false;
    }
    this.init(moment().startOf('month').format('YYYYMMDD'), moment().endOf('month').format('YYYYMMDD'));
  }

  getPresunto(e: any) {
    let ret = 0;
    if (e.DilazioniDaLiquidare > 0 || e.DilazioniDaLiquidare < 0)
      ret = ret + e.DilazioniDaLiquidare;
    if (e.DilazioniDaInserire > 0 || e.DilazioniDaInserire < 0)
      ret = ret + e.DilazioniDaInserire;
    if (e.EntrateSicure > 0 || e.EntrateSicure < 0)
      ret = ret + e.EntrateSicure;
    if (e.PrevisioneOperatore > 0 || e.PrevisioneOperatore < 0)
      ret = ret + e.PrevisioneOperatore;
    return ret;
  }

}